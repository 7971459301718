import React, { useEffect, useState } from "react";
import { Link, Route, useHistory, useParams } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import {
  // Tooltip,
  Typography,
  IconButton,
  Backdrop,
  Portal,
  Card,
  useMediaQuery,
} from "@mui/material";
import { /*GetApp,*/ NavigateNext, NavigateBefore } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

import FileIcon from "../FileIcon";
import { removePathFromUrl } from "../../utility";
import { isNull } from "lodash";
import { useSelector } from "react-redux";
import { DefaultLoader } from "common/Loader";
import { useImageLoad } from "hooks";
import { getFileDimensions } from "utility";
import { jobDivisionFilesSelectors } from "features/Files/filesSlice";
import { ImageInteractionEditor, ImageInteractionMessages } from "components/ImageInteraction";
import "components/ImageInteraction/ImageInteraction.scss";
import { ImageZoom } from "components/Lightroom";
import Maximize from "common/Maximize";
import { useDebounce } from "hooks";

const getNextId = (photos, id) => {
  if (!Array.isArray(photos)) return null;
  const index = photos.findIndex((el) => el.paragraph_pid === id || el.id === id);

  if (index + 1 >= photos.length) {
    return null;
  } else {
    return (photos[index + 1]?.paragraph_pid ? photos[index + 1].paragraph_pid : photos[index + 1].id);
  }
};

const getPrevId = (photos, id) => {
  if (!Array.isArray(photos)) return null;
  const index = photos.findIndex((el) => el.paragraph_pid === id || el.id === id);

  if (index - 1 < 0) {
    return null;
  } else {
    return (photos[index - 1]?.paragraph_pid ? photos[index - 1].paragraph_pid : photos[index - 1].id);
  }
};

const getPhoto = (photos, id) => {
  if (!Array.isArray(photos)) return null;
  const index = photos.findIndex((el) => el?.paragraph_pid === id || el?.id === id);

  if (index < 0) {
    return null;
  } else {
    return photos[index];
  }
};

const PrivateFilePreview = ({ file, ...rest }) => {
  const token = useSelector((state) => state.auth.token);
  let url = file.uri.replace("private://", "");
  const [loading, image, width, height] = useImageLoad(`/system/files/${url}`, token);

  if (loading) return <DefaultLoader className="absolute inset-0 m-auto" />;

  if (!image) return <FileIcon file={file} />;

  return (
    <ImageZoom
      file={file}
      image={image}
      imageWidth={width}
      imageHeight={height}
      altText="Preview Image"
      // wrapperClass="absolute max-h-full inset-0 m-auto"
    />
  );
};

const PublicFilePreview = ({ url, ...rest }) => {
  const [loading, setLoading] = useState(false);
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  useEffect(() => {
    if (url) {
      setLoading(true);
      getFileDimensions(url)
        .then((res) => {
          setWidth(res.naturalWidth);
          setHeight(res.naturalHeight);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  }, [url]);

  if (loading) return <DefaultLoader className="absolute inset-0 m-auto" />;

  return (
    <ImageZoom
      image={url}
      imageWidth={width}
      imageHeight={height}
      altText="Preview Image"
      // wrapperClass="absolute max-h-full inset-0 m-auto"
    />
  );
};

const ComponentContent = ({ ids, parsedUrl, path, url, photos, useInteraction, openEdit }) => {
  const { id } = useParams();
  const history = useHistory();
  const photo = getPhoto(photos, id);
  const fileNode = useSelector((state) => jobDivisionFilesSelectors.selectById(state, photo?.nid));
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [refreshZoom, setRefreshZoom] = useState(false);
  const debouncedRefreshZoom = useDebounce(refreshZoom, 500);
  if(ids.indexOf(id) < 0){
    return null;
  }

  const nextId = getNextId(photos, id);
  const nextUrl = `${parsedUrl}/${path}/${nextId}`;
  const prevId = getPrevId(photos, id);
  const prevUrl = `${parsedUrl}/${path}/${prevId}`;


  const showButtons = (!isNull(prevId) || !isNull(nextId));

  // const imgClasses = `absolute top-0 bottom-0 left-0 right-0 m-auto max-w-[95%] max-h-[90%]`;

  let fileName = null;
  if(photo && photo.photo_filename){
    fileName = () =>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <FileIcon filename={photo.photo_filename} size="small" />
        <Typography>{fileNode ? fileNode.title : photo.photo_title}</Typography>
      </div>;
  }

  return (
    <Portal>
      <div
        style={{
          zIndex: 1300,
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Backdrop
          open={true}
          onClick={() => {
            history.push(parsedUrl)
          }}
        />
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            color: "white",
            display: "flex",
            alignItems: "center",
            background: "linear-gradient(rgba(0,0,0,1), rgba(0,0,0,0))",
            padding: "1rem",
            justifyContent: "space-between",
          }}
        >
          {/* {!useInteraction &&
            fileName()
          } */}
          {/* <div>
            <Tooltip title="Download">
              <IconButton size="small" style={{ color: "white" }} disableRipple>
                <GetApp />
              </IconButton>
            </Tooltip>
          </div> */}
        </div>
        <Card className="lightroom absolute top-0 bottom-0 left-0 right-0 m-auto w-[95%] h-[95%]" >
          <div
            className="w-full h-full bg-white p-2 flex flex-col items-stretch"
          >
            <div className="pb-2 flex items-center">
              {fileName()}
              <div className="flex-[1_0_auto]">
                {(showButtons && isMobile) && (
                  <>
                    <IconButton
                      color="secondary"
                      disabled={isNull(prevId)}
                      to={prevUrl}
                      component={Link}
                      className="icon-circle-button secondary ml-4 mr-2"
                    >
                      <NavigateBefore />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      disabled={isNull(nextId)}
                      to={nextUrl}
                      component={Link}
                      className="icon-circle-button secondary"
                    >
                      <NavigateNext />
                    </IconButton>
                  </>
                )}
              </div>
              <IconButton
                color="secondary"
                to={parsedUrl}
                component={Link}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="image-interaction-wrapper flex-[1_1_auto] rounded overflow-hidden relative">
            {useInteraction ? (
              <>
              <Maximize className="editor-wrapper"
                onMaximize={() => setRefreshZoom(true)}
                onMinimize={() => setRefreshZoom(false)}
              >
                <ImageInteractionEditor
                  fid={photo.id}
                  fileNode={fileNode}
                  routeUrl={url}
                  openEditOnLoad={openEdit}
                  maximizeRefresh={debouncedRefreshZoom}
                />
              </Maximize>
              <Maximize
                className="messages-wrapper"
                expandFrom="bottom"
              >
                <ImageInteractionMessages
                  nid={fileNode.id}
                />
              </Maximize>
              </>
            ) : (
              photo?.privateFile ? (
                <PrivateFilePreview
                  file={photo.privateFile}
                />
              ) : (
                (photo && photo._files && photo._files.photo && (
                  <PublicFilePreview
                    url={photo._files.photo}
                    // className={imgClasses}
                    // fileName={fileName}
                  />
                // <img src={photo._files.photo} alt="Preview" className={imgClasses} />
                ))
              )
            )}
            </div>
          </div>
        </Card>
        {(showButtons && !isMobile) && (
          <>
            <div
              style={{
                position: "absolute",
                left: 3,
                top: (!isMobile ? "50%" : "54%"),
                transform: "translateY(-50%, -50%)",
                background: "white",
                borderRadius: "50%",
                boxShadow: "0 0 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              <IconButton
                color="secondary"
                disabled={isNull(prevId)}
                to={prevUrl}
                component={Link}
                size={(!isMobile ? "large" : "small")}
              >
                <NavigateBefore />
              </IconButton>
            </div>
            <div
              style={{
                position: "absolute",
                right: 3,
                top: (!isMobile ? "50%" : "54%"),
                transform: "translateY(-50%, -50%)",
                background: "white",
                borderRadius: "50%",
                boxShadow: "0 0 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              <IconButton
                color="secondary"
                disabled={isNull(nextId)}
                to={nextUrl}
                component={Link}
                size={(!isMobile ? "large" : "small")}
              >
                <NavigateNext />
              </IconButton>
            </div>
          </>
        )}
      </div>
    </Portal>
  );
};

const Lightroom = ({ route, url, path, ids, useInteraction, openEdit, ...rest }) => {
  const [parsedUrl, setParsedUrl] = React.useState(url);

  useEffect(() => {
    if (url.indexOf(path) >= 0) {
      setParsedUrl(removePathFromUrl(url, path, true));
    }
  }, [url, path]);

  return (
    <Route path={`${parsedUrl}/${route}`}>
      {({ match }) => (
        <CSSTransition
          in={(match && match.isExact)}
          timeout={200}
          classNames="fade"
          // unmountOnExit
        >
          <ComponentContent
            ids={ids}
            parsedUrl={parsedUrl}
            path={path}
            url={url}
            useInteraction={useInteraction}
            openEdit={openEdit}
            {...rest}
          />
        </CSSTransition>
      )}
    </Route>
  );
};

Lightroom.propTypes = {};

export default Lightroom;
