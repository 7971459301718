import PropTypes from "prop-types";
import { hasPermission } from "../../utility";

const Role = ({ userRoles=[], intendedRoles=[], children }) =>
  hasPermission(userRoles, intendedRoles) ? children : null;

const { array } = PropTypes;
Role.propTypes = {
  userRoles: array.isRequired,
  intendedRoles: array.isRequired,
};

export default Role;
