import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Popover,
  Typography,
  FormHelperText,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { DefaultLoader } from "common/Loader";
import { Tune, Cancel, Search, ArrowForwardIos, } from "@mui/icons-material";
import Dialog from "common/Dialog";
import { AutocompleteField } from "common/Fields/AutocompleteFormField";

import chroma from "chroma-js";
import { size, toArray } from "lodash";
import {
  getGooglePlaceData,
  clearMapData,
} from "actions";
import constants from "components/constants";
import {
  fetchMembers,
  getMembersLoadingSelector,
  membersSelectors,
} from "features/Members/membersSlice";

let colorRange = chroma.scale(['#0000FF',constants.colorGreenAlt, constants.colorYellow,'#FF0000','#0000FF']).colors(16).slice(1, 15);

let mouseMoveListener = null;
let clickListener = null;
let mapListener = null;

const MapZipCodes = ({
  myMap,
  myMaps,
  mapData,
  floatLabelRef,
  org,
  toggleState,
  setToggleState,
  filterMenu,
  handleFilterOpen,
  anchorEl,
  setFilterMenu,
  launchSnapshot,
  markerOver,
  snapshotOpen,
  setMarkerClickOverride,
  markerClicked,
  setMarkerClicked,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user.data);
  const googlePlaceData = useSelector((state) => state.app.mapGooglePlaceData.data);
  const loading = useSelector((state) => state.app.mapGooglePlaceData.loading);
  const [enableZips, setEnableZips] = React.useState(true /*false*/); // eslint-disable-line
  const [coverageView, setCoverageView] = React.useState("covered");
  const [zipFilter, setZipFilter] = React.useState('');
  const [zipSearch, setZipSearch] = React.useState([]);
  const [zipSearchError, setZipSearchError] = React.useState('');
  const [zipSearchLoading, setZipSearchLoading] = React.useState(false);
  const [membersSearch, setMembersSearch] = React.useState([]);
  const [membersSearchLoading, setMembersSearchLoading] = React.useState(false);
  const [membersSearchError, setMembersSearchError] = React.useState('');
  const [memberSelectorOpen, setMemberSelectorOpen] = React.useState(false);
  const [memberSelectorOptions, setMemberSelectorOptions] = React.useState([]);
  const members = useSelector(membersSelectors.selectAll);
  const loadingMembers = useSelector(getMembersLoadingSelector);

  const getFilters = () => {
    return {
      ...(org ? { "filter[phx_client_nids]": org } : {}),
    }
  };

  const getMembers = useCallback(
    (query) => {
      if(query && query !== ''){
        const params = getFilters();
        return dispatch(
          fetchMembers({
            ...params,
            keywords: query,
          })
        );
      }
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if(myMap && size(googlePlaceData)){
      loadZipCodes()
    }
  }, [googlePlaceData?.data, coverageView, zipSearch, membersSearch, snapshotOpen, memberSelectorOpen, markerOver, markerClicked]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(toggleState.zipCodes){
      clearMembersSearch();
      const params = getFilters();
      dispatch(getGooglePlaceData(params));
    }
    else{
      dispatch(clearMapData("mapGooglePlaceData"));
    }
  }, [org]); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleZipCodes = (e) => {
    const toggle = !toggleState.zipCodes;
    if(toggle){
      if(!size(googlePlaceData)){
        const params = getFilters();
        dispatch(getGooglePlaceData(params));
      }
      else{
        loadZipCodes();
      }
    }
    else{
      unloadZipCodes();
    }

    setMarkerClickOverride(toggle);
    setToggleState({ ...toggleState, zipCodes: toggle });
  };

  const loadZipCodes = () => {
    if(!enableZips){
      return;
    }
    const featureLayer = myMap.getFeatureLayer(
      myMaps.FeatureType.POSTAL_CODE,
    );

    if(mouseMoveListener){
      mouseMoveListener.remove();
      floatLabelRef.current.style.display = "none";
    }
    mouseMoveListener = featureLayer.addListener('mousemove', handleMouseMove);

    if(mapListener){
      mapListener.remove();
      floatLabelRef.current.style.display = "none";
    }
    mapListener = myMap.addListener('mousemove', clearMouseMove);

    if(clickListener){
      clickListener.remove();
    }
    clickListener = featureLayer.addListener('click', handleMouseClick);

    featureLayer.style = styleZips;
  };

  const unloadZipCodes = () => {
    floatLabelRef.current.style.display = "none";
    lastInteractedFeatureIds = [];
    const featureLayer = myMap.getFeatureLayer(
      myMaps.FeatureType.POSTAL_CODE,
    );
    featureLayer.style = null;
    if(mouseMoveListener){
      mouseMoveListener.remove();
    }
    if(clickListener){
      clickListener.remove();
    }
  }

  let lastInteractedFeatureIds = [];

  const styleZips = (featureStyleFunctionOptions) => {
    const placeFeature = featureStyleFunctionOptions.feature;

    const placeId = placeFeature?.placeId;
    if(placeId && googlePlaceData?.data?.[placeId]){
      const [zip, nids] = googlePlaceData.data[placeId]; // eslint-disable-line

      if(!isActiveZip(zip, nids)){
        return null;
      }

      let mouseOver = false;
      if (lastInteractedFeatureIds.includes(placeId)) {
        mouseOver = true;
      }

      let fillOpacity = 0.5;
      let strokeOpacity = 0.7;
      let color = constants.colorBlue;
      if(nids === ''){
        color = constants.colorGrayDark;
      }
      else if(nids.indexOf(',') === -1){
        color = colorRange[Number(nids) % colorRange.length];
        fillOpacity = 0.4;
        strokeOpacity = 0.6;
      }
      if(mouseOver){
        fillOpacity += 0.2;
        strokeOpacity += 0.3;
      }

      return {
        fillColor: color,
        fillOpacity: fillOpacity,
        strokeColor: color,
        strokeOpacity: strokeOpacity,
      };
    }
  }

  const isActiveZip = (zip, nids) => {
  //  If member zip search is happening,
  //    then this is only active if it's one of the searched member's zips.
    if(size(membersSearch)){
      let _nids = nids.split(',')
      return Boolean(membersSearch.find((nid) => _nids.includes(nid)));
    }
  //  If zip search is happening,
  //    then this is only active if it's one of the zips being searched for.
  //  Otherwise, does the zip's coverage status matche the coverage view
    return (size(zipSearch) && zipSearch.includes(zip))
           || (!size(zipSearch) &&
                ((coverageView === 'covered' && nids !== '')
                || (coverageView === 'not_covered' && nids === '')
              ));
  }

  const handleMouseMove = (/* MouseEvent */ e) => {
    let feature = e.features[0];
    const placeId = feature.placeId;
    if (placeId && placeId !== '' && googlePlaceData?.data?.[placeId] && !lastInteractedFeatureIds.includes(placeId)) {
      const [zip, nids/*, names*/] = googlePlaceData.data[placeId];
      if(!isActiveZip(zip, nids)){
        floatLabelRef.current.style.display = "none";
      }
      else{
        lastInteractedFeatureIds = e.features.map(f => f.placeId);
        const featureLayer = myMap.getFeatureLayer(
          myMaps.FeatureType.POSTAL_CODE,
        );
        featureLayer.style = styleZips;
        const memberNids = nids.split(',');
        const memberQty = memberNids.length;
        let suffix = " (not covered)";
        if(memberQty){
          let names = memberNids.map((nid) => googlePlaceData.member_names[String(nid)]);
          if(memberQty === 1){
            suffix = ` (covered by ${names[0]})`;
          }
          else{
            suffix = ` (covered by ${memberQty} providers)`;
            if(memberQty > 4){
              names = names.slice(0, 4).map((name, i) => i === 3 ? `and ${memberQty - 3} more` : name);
            }
            suffix += '</div><div>' + names.join('</div><div>');
          }
        }
        floatLabelRef.current.innerHTML = `<div>${zip + suffix}</div>`;
        floatLabelRef.current.style.display = "block";
      }
    }
    let left = e.domEvent.pageX + 5;
    let top = e.domEvent.pageY + 5;
    const {height} = floatLabelRef.current.getBoundingClientRect();
    floatLabelRef.current.style.left = String(left) + "px";
    floatLabelRef.current.style.top = String(top) + "px";
    if(left + 300 > window.innerWidth){
      floatLabelRef.current.style.left = String(left - 350) + "px"; // Avoid the Google controls
    }
    if(top + height + 15 > window.innerHeight){
      floatLabelRef.current.style.top = String(top - height) + "px";
    }
  }

  const handleMouseClick = (/* MouseEvent */ e) => {
    if(markerOver || markerClicked){
      return;
    }

    let feature = e.features[0];
    const placeId = feature.placeId;
    if (placeId && placeId !== '' && googlePlaceData?.data?.[placeId]) {
      const [zip, nids, /*names*/] = googlePlaceData.data[placeId]; // eslint-disable-line
      if(nids !== ''){
        clearMouseMove();
        floatLabelRef.current.style.display = "none";
        mouseMoveListener.remove();
        clearMouseMove();
        const memberNids = nids.split(',');
        let names = memberNids.map((nid) => googlePlaceData.member_names[String(nid)]);
        if(memberNids.length === 1){
          launchSnapshot(nids, names[0], 'member', null, true);
        }
        else{
          const options = memberNids.map((nid, i) => {
            return {nid: nid, name: names[i]};
          });
          setMemberSelectorOptions(options);
          setMemberSelectorOpen(true);
        }
        // setPopUpOpen(true);
      }
    }
  }

  const clearMouseMove = () => {
    // If the map gets a mousemove, that means there are no feature layers
    // with listeners registered under the mouse, so we clear the last
    // interacted feature ids.
    if (lastInteractedFeatureIds?.length) {
      lastInteractedFeatureIds = [];
      floatLabelRef.current.style.display = "none";
      const featureLayer = myMap.getFeatureLayer(
        myMaps.FeatureType.POSTAL_CODE,
      );
      featureLayer.style = styleZips;
    }
  }

  const handleZipSearch = async () => {
    let searchZips = zipFilter.split(',');
    const invalidZips = searchZips.filter((zip) =>
      !Object.entries(googlePlaceData.data).find((entry) => entry[1][0] === zip)
    )
    let searchError = '';
    if(size(invalidZips)){
      searchError = `Invalid Zip Code${size(invalidZips) > 1 ? 's' : ''} (${invalidZips.join(', ')})`;
      searchZips = searchZips.filter((zip) => !invalidZips.find((_zip) => _zip === zip));
    }
    setZipSearchError(searchError);

    if(size(searchZips)){
      clearMembersSearch();
      setZipSearchLoading(true);
      const viewPorts = [];
      let bounds = new myMaps.LatLngBounds();
      for(const zip of searchZips){
        const place = Object.entries(googlePlaceData.data).find((entry) => entry[1][0] === zip)[1];
        if(place[2] !== ''){
          const viewport = JSON.parse(place[2]);
          bounds.extend(viewport.northeast);
          bounds.extend(viewport.southwest);
          viewPorts.push(viewport);
          if(size(viewPorts) === size(searchZips)){
            setZipSearchLoading(false);
            myMap.fitBounds(bounds, 100);
          }
        }
      }

      setZipSearch(searchZips)
    }
  }

  const clearZipSearch = () => {
    setZipSearchError('');
    setZipFilter('');
    setZipSearch([]);
    setZipSearchLoading(false);
  }

  const handleMembersSearch = async (_searchMembers) => {
    let searchMembers = _searchMembers;
    const invalidMembers = _searchMembers.filter((nid) =>
      !Object.entries(googlePlaceData.data).find((entry) => entry[1][1].split(',').includes(nid))
    )
    let searchError = '';
    if(size(invalidMembers)){
      const names = invalidMembers.map((nid) => googlePlaceData.member_names[String(nid)]);
      searchError = `Member${size(names) > 1 ? 's' : ''} "(${names.join('", "')})" ${size(names) > 1 ? 'are' : 'is'} not covering any zip codes.`;
      searchMembers = searchMembers.filter((nid) => !invalidMembers.find((_nid) => _nid === nid));
    }
    setMembersSearchError(searchError);

    if(size(searchMembers)){
      clearZipSearch();
      setMembersSearchLoading(true);
      const viewPorts = [];
      const allBounds = [];
      for(const nid of searchMembers){
        Object.entries(googlePlaceData.data).forEach((entry) => {
          const [zip, nids, bounds] = entry[1]; // eslint-disable-line
          if(bounds &&  bounds !== '' && nids.split(',').includes(nid)){
            allBounds.push(JSON.parse(bounds));
          }
        });
      }
      let bounds = new myMaps.LatLngBounds();
      for(const viewPort of allBounds){
        bounds.extend(viewPort.northeast);
        bounds.extend(viewPort.southwest);
        viewPorts.push(viewPort);
        if(size(viewPorts) === size(allBounds)){
          setMembersSearchLoading(false);
          setMarkerClicked(null);
          myMap.fitBounds(bounds, 100);
        }
      }
    }
    setMembersSearch(searchMembers)
  }

  const clearMembersSearch = () => {
    setMembersSearchError('');
    setMembersSearch([]);
    setMembersSearchLoading(false);
  }

  if(!user?._processed?.member_ops_access){
    return;
  }

  let clickMarkerHasZips = false;
  if(size(googlePlaceData) && markerClicked && toggleState.zipCodes){
    const covered = Object.entries(googlePlaceData.data).find((entry) => entry[1][1].split(',').includes(String(markerClicked?.nid)));
    clickMarkerHasZips = Boolean(size(covered));
  }

  return (
    <>
    <ListItem disabled={!enableZips || loading} dense button onClick={toggleZipCodes} >
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={toggleState.zipCodes}
          name="zipCodes"
          tabIndex={-1}
          disableRipple
          inputProps={{
            "aria-labelledby": "zip-visibility-toggle",
          }}
        />
      </ListItemIcon>
      <ListItemText id="zip-visibility-toggle">
        Zip Code Coverage
        {!enableZips && ` (zoom In further)`}
        {loading && <DefaultLoader inline />}
      </ListItemText>
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          aria-label="Zip Code settings"
          onClick={(e) => handleFilterOpen(e, "zip_codes")}
          size="large"
          disabled={!toggleState.zipCodes}
        >
          <Tune />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
    <Popover
      open={filterMenu === "zip_codes"}
      anchorEl={anchorEl}
      onClose={() => setFilterMenu(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <div style={{ width: "400px", padding: "1.25rem" }}>
        <Typography
          color="textSecondary"
          variant="subtitle1"
          gutterBottom
        >
          Zip Code Coverage Filters
        </Typography>
        <FormControl>
          <FormControlLabel
            label="Coverage View"
            labelPlacement="top"
            sx={{alignItems: "flex-start"}}
            disabled={Boolean(size(zipSearch))}
            control={
              <RadioGroup
                value={coverageView}
                onChange={(e) => setCoverageView(e.target.value)}
                required
                sx={{flexDirection: "row"}}
              >
                <FormControlLabel
                  key="covered"
                  value="covered"
                  control={<Radio />}
                  label={"Covered"}
                  disabled={Boolean(size(zipSearch))}
                />
                <FormControlLabel
                  key="not_covered"
                  value="not_covered"
                  control={<Radio />}
                  label={"Not Covered"}
                  disabled={Boolean(size(zipSearch))}
                />
              </RadioGroup>
            }
          />
        </FormControl>
        <FormControl sx={{ width: "100%" }} variant="standard">
          <InputLabel htmlFor="zip-code-search">Search Zip Codes</InputLabel>
          <Input
            id="zip-code-search"
            value={zipFilter}
            onChange={(e) => setZipFilter(e.target.value)}
            onKeyPress={(event) => {
              if(event.key === 'Enter'){
                handleZipSearch();
              }
            }}
            disabled={zipSearchLoading}
            endAdornment={
              <InputAdornment position="end">
                {zipFilter !== '' &&
                  <IconButton
                    aria-label="Search Zip Codes"
                    onClick={clearZipSearch}
                    size="small"
                  >
                    <Cancel />
                  </IconButton>
                }
                <IconButton
                  edge="end"
                  aria-label="Search Zip Codes"
                  onClick={handleZipSearch}
                  size="small"
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText id="zip-code-search-helper-text">
            {zipSearchError !== '' && <><span className="text-red">{zipSearchError}</span><br /></>}
            (Separate multiple zip codes with a comma)
          </FormHelperText>
        </FormControl>
        <AutocompleteField
          className="flex-1"
          variant="standard"
          label="Search Provider Coverage"
          size="small"
          disabled={membersSearchLoading}
          multiple
          options={toArray(members).map((obj) => ({
            name: obj.member_name,
            nid: obj.member_nid,
          }))}
          getOptionLabel={(option) => (option.name ? option.name : "")}
          loading={loadingMembers}
          fetchData={getMembers}
          freeSolo
          onChange={(e, value) => {
            if(!size(value)){
              clearMembersSearch();
            }
            else{
              handleMembersSearch(value.map((option) => option.nid));
            }
          }}
          inputProps={{
            onKeyDown: (e) => {
              if (e.key === 'Enter') {
                e.stopPropagation();
              }
            },
          }}
          setValue={membersSearch.map((nid) => ({
            name: googlePlaceData.member_names[nid],
            nid: nid,
          }))}
        />

        <FormHelperText>
          {membersSearchError !== '' && <span className="text-red">{membersSearchError}</span>}
        </FormHelperText>
      </div>
    </Popover>
    <Dialog
      open={memberSelectorOpen}
      onClose={() => setMemberSelectorOpen(false)}
      title="Select a Provider"
    >
     <List className="p-4 pb-1">
     {memberSelectorOptions.map((option, i) =>
        <ListItem
          key={i}
          className="p-4 mb-3 bg-gray-light hover:bg-gray cursor-pointer rounded"
          onClick={() => {
            launchSnapshot(
              option.nid,
              option.name,
              'member',
              () => {
                if(size(memberSelectorOptions)){
                  setMemberSelectorOpen(true);
                }
              },
              true
            );
            setMemberSelectorOpen(false);
          }}
        >
          <ListItemText className="flex-1">
            {option.name}
          </ListItemText>
          <ListItemIcon>
            <ArrowForwardIos />
          </ListItemIcon>
        </ListItem>
     )}
     </List>
    </Dialog>
    <Dialog
      open={Boolean(size(googlePlaceData) && markerClicked && toggleState.zipCodes)}
      onClose={() => setMarkerClicked(null)}
      title={markerClicked ? markerClicked?.title : ''}
      maxWidth="xs"
    >
      {membersSearchLoading ? (
        <div className="p-4 pb-1">
          <DefaultLoader />
        </div>
      ) : (
      <List className="p-4 pb-1">
        <ListItem
          className="p-4 mb-3 bg-gray-light hover:bg-gray cursor-pointer rounded"
          onClick={() => {
            launchSnapshot(
              markerClicked?.nid,
              markerClicked?.title,
              'member',
              null,
              true
            );
            setMarkerClicked(null);
          }}
        >
          <ListItemText className="flex-1">
            View Provider Details
          </ListItemText>
          <ListItemIcon>
            <ArrowForwardIos />
          </ListItemIcon>
        </ListItem>
        <ListItem
          className={`p-4 mb-3 rounded ${clickMarkerHasZips ? ' bg-gray-light hover:bg-gray cursor-pointer' : 'bg-gray-lighter text-gray-medium'}`}
          onClick={() => {
            if(clickMarkerHasZips){
              handleMembersSearch([String(markerClicked?.nid)]);
            }
          }}
        >
          <ListItemText className="flex-1">
            {clickMarkerHasZips ? (
              `View Provider Zip Code Coverage`
            ) : (
              `Provider has no Zip Code Assignment`
            )}
          </ListItemText>
          <ListItemIcon>
            <ArrowForwardIos className={!clickMarkerHasZips ? 'text-gray-medium' : ''} />
          </ListItemIcon>
        </ListItem>
      </List>
      )}
    </Dialog>
    </>
  );
};

MapZipCodes.propTypes = {};

export default MapZipCodes;
