import React from "react";
import GoogleMapReact from "google-map-react";
import { Typography } from "@mui/material";
import styled from "styled-components";
import querystring from "querystring";

import { LocationIcon } from "../../components/Icons";
import { Marker } from "../../components/Map";
import constants from "components/constants";
import config from "config";

const mapStyles = [
  {
    featureType: "all",
    elementType: "all",
    stylers: [
      {
        hue: constants.colorBlue,
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [
      {
        saturation: "0",
      },
      {
        lightness: "0",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
      {
        saturation: "-60",
      },
      {
        lightness: "-20",
      },
    ],
  },
];

const MapButton = styled.a`
  border-radius: 3px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: block;
  height: 75px;
  position: relative;
`;

const Overlay = styled.a`
  position: absolute;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0);
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  transition: background-color 0.25s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const Map = ({
  geo,
  color,
  maxHeight,
  address1,
  address2,
  city,
  state,
  zip,
  isButton,
  phxStyles,
}) => {
  const query = querystring.stringify({
    query: `${address1} ${address2} ${city} ${state}, ${zip}`,
  });

  let latitude = null, longitude = null;
  if(geo && typeof geo?.lat !== 'undefined' && typeof geo?.lng !== 'undefined'){
    latitude = Number(geo.lat);
    longitude = Number(geo.lng);
  }

  return isButton ? (
    <MapButton
      href={`https://www.google.com/maps/search/?api=1&${query}`}
      target="_blank"
    >
      <div style={{ width: "100%", height: maxHeight, position: "relative" }}>
        <Overlay></Overlay>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: config.google_api,
          }}
          center={{ lat: latitude, lng: longitude }}
          defaultZoom={14}
          options={{
            styles: phxStyles ? mapStyles : null,
            disableDefaultUI: true,
          }}
        >
          <Marker lat={latitude} lng={longitude} color={color}>
            <LocationIcon />
          </Marker>
        </GoogleMapReact>
      </div>
    </MapButton>
  ) : (
    <div style={{ width: "100%", height: maxHeight, position: "relative" }}>
      {address1 && (
        <div
          style={{
            background: "rgba(255,255,255,0.85)",
            padding: "0.5rem",
            position: "absolute",
            bottom: "0.5rem",
            left: "0.5rem",
            borderRadius: 3,
            zIndex: 1,
          }}
        >
          <Typography variant="body1">{address1}</Typography>
          <Typography variant="body2">{address2}</Typography>
          <Typography variant="body2">{`${city} ${state}, ${zip}`}</Typography>
        </div>
      )}
      <GoogleMapReact
        bootstrapURLKeys={{
          key: config.google_api,
        }}
        center={{ lat: latitude, lng: longitude }}
        defaultZoom={14}
        options={{
          styles: phxStyles ? mapStyles : null,
          disableDefaultUI: true,
        }}
      >
        <Marker lat={latitude} lng={longitude} color={color}>
          <LocationIcon />
        </Marker>
      </GoogleMapReact>
    </div>
  );
};

Map.propTypes = {};

export default Map;
