import React, { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card, { CardHeader } from "common/Card";
import {
  fetchMemberInsuranceAgents,
  getMemberDataSelector,
  getMemberInsuranceAgentsLoadingSelector,
  insuranceAgentsSelectors,
} from "../memberSlice";
import { Typography } from "@mui/material";
import { MuiTable } from "common/Table";
import { IconButtonDropdown } from "common/ButtonDropdown";
import { MoreVert } from "@mui/icons-material";
import DeleteAgent from "../forms/DeleteAgent";
import EditAgent from "../forms/EditAgent";

const Actions = ({ agent }) => {
  const dropdownRef = useRef();

  const handleClose = () => {
    if (dropdownRef.current) {
      dropdownRef.current.close();
    }
  };

  return (
    <IconButtonDropdown
      ref={dropdownRef}
      icon={MoreVert}
      tooltip="Actions"
      size="small"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <EditAgent id={agent.nid} handleClose={handleClose} />
      <DeleteAgent agent={agent} handleClose={handleClose} />
    </IconButtonDropdown>
  );
};

const Insurance = (props) => {
  const dispatch = useDispatch();
  const member = useSelector(getMemberDataSelector);
  const agents = useSelector(insuranceAgentsSelectors.selectAll);
  const loading = useSelector(getMemberInsuranceAgentsLoadingSelector);

  useEffect(() => {
    const promise = dispatch(fetchMemberInsuranceAgents(member.nid));

    return () => {
      promise.abort();
    };
  }, [dispatch, member]);

  const columns = useMemo(
    () => [
      { id: "agent", label: "Agent", minWidth: 50 },
      { id: "contact", label: "Contact", minWidth: 50 },
      { id: "categories", label: "Categories", minWidth: 200 },
      { id: "actions", label: "", width: 50 },
    ],
    []
  );

  const data = useMemo(
    () =>
      agents.map((agent) => ({
        agent: (
          <div>
            <Typography color="secondary">{agent.title}</Typography>
            <Typography color="textSecondary">
              {agent.field_member_ins_agent_agcy_name}
            </Typography>
          </div>
        ),
        contact: (
          <div>
            <Typography color="secondary">
              {agent.field_member_ins_agent_email}
            </Typography>
            <Typography color="textSecondary">
              {agent.field_member_ins_agent_phone}
            </Typography>
          </div>
        ),
        categories: agent.field_coverage_categories
          .map((category) => category.name)
          .join(", "),
        actions: <Actions agent={agent} />,
      })),
    [agents]
  );

  return (
    <Card>
      <CardHeader title="Insurance Agents" />
      <MuiTable
        rows={data}
        columns={columns}
        loading={loading}
        size="medium"
        stickyHeader
        flex
      />
    </Card>
  );
};

Insurance.propTypes = {};

export default Insurance;
