import React from "react";
import { CircularProgress } from "@mui/material";

const DefaultLoader = ({ propClass, style, inline, ...rest }) => {
  return (
    <div
      style={{
        height: !inline ? "200px" : "1rem",
        width: !inline ? "100%" : "auto",
        display: !inline ? "flex" : "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        ...style,
      }}
      className={propClass}
    >
      <CircularProgress color="secondary" {...rest} />
    </div>
  );
};

DefaultLoader.propTypes = {};

export default DefaultLoader;
