import React from "react";
import { useSelector } from "react-redux";
import { hasPermission } from "utility";
import Card, { CardHeader } from "common/Card";
// import { Grid } from "@mui/material";
import config from "config";
import LinkHeader from "common/LinkHeader";
import { getCustomerDataSelector } from "features/Customer/customerSlice";
import { FaUser } from "react-icons/fa";
import Button from "common/Button";
import CustomerWelcome from "./CustomerWelcome";
import CustomerResendInvite from "./CustomerResendInvite";
// import CustomerDiscussion from "./CustomerDiscussion";

const CustomerCommunication = ({ job }) => {
  const user = useSelector((state) => state.auth.user.data);
  const userProcessed = user._processed;
  const customer = useSelector(getCustomerDataSelector);
  const mbyIcon = `${process.env.PUBLIC_URL}/images/mby-icon.png`;

  const isAdmin = hasPermission(userProcessed.roles, [
    "admin",
    "phx_sub_admin",
    // "phx_client_admin",
  ]);

//  Determine if a user exists for the Customer, or if we need to resend the invite, since they haven't logged in yet.
  const custUsers = (customer?._meta?.cust_users ? customer?._meta?.cust_users : []);
  const custUsersNoLogin = (customer?._meta?.cust_users_no_login ? customer?._meta?.cust_users_no_login : []);
  let custUser = null;
  if(custUsers.length === 1){
    custUsers.forEach((user) => {
      custUsersNoLogin.forEach((userNoLogin) => {
        if(user.entity_id === userNoLogin.entity_id){
          custUser = userNoLogin;
          return;
        }
      });
      if(custUser){
        return;
      }
    });
  }

  return ( isAdmin && job?.field_mby_service_request_id &&
    <Card>
      <CardHeader
        leftActions={
          <LinkHeader
            to={`${config.mby_url}/node/${job.field_mby_service_request_id}`}
            target="_blank"
            size="small"
            className="text-base"
          >
            <img src={mbyIcon} alt="MyBackyard Logo Icon" className="w-6 h-6 inline-block mr-2 mb-[1px] align-bottom" />MyBackyard Service Request
          </LinkHeader>
        }
        rightActions={custUsers.length === 0 ? (
          <CustomerWelcome
            job={job}
            customer={customer}
            user={user}
          >
            <Button
              variant="contained"
              disableElevation
              color="tertiary"
              size="small"
              startIcon={<FaUser />}
            >
              Invite Customer to Helix
            </Button>
          </CustomerWelcome>
        ) : custUser && (
          <CustomerResendInvite
            job={job}
            customer={customer}
            custUser={custUser}
          >
            <Button
              variant="contained"
              disableElevation
              color="tertiary"
              size="small"
              startIcon={<FaUser />}
            >
              Resend Account Invite to Customer
            </Button>
          </CustomerResendInvite>
        )}
      />
      {/* {custUsers.length > 0 && (
        <Grid container spacing={3} className="p-5">
          <Grid item xxs={6}>
            <CustomerDiscussion job={job} />
          </Grid>
        </Grid>
      )} */}
    </Card>
  );
}

CustomerCommunication.propTypes = {};

export default CustomerCommunication;
