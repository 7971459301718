import React, { useEffect } from "react";
import { useSelector, connect } from "react-redux";
import { useLocation, useRouteMatch, Switch, Route } from "react-router-dom";

import RouteTabs from "components/RouteTabs";
import { getMemberNoteStatus } from "actions/taxonomies";
import Calls from "components/Connect/CRM/Calls";
import Tasks from "components/Connect/Tasks";
import Emails from "components/Connect/CRM/Emails";
import Documents from "features/Customer/components/Documents";
import Notes from "features/Customer/components/Notes";
import { memberNoteTypesSelectors } from "features/Taxonomies/taxonomiesSlice";

const ROUTES = [
  {
    label: "Calls",
    path: "calls",
    identifier: "customer-calls",
    default: true,
  },
  {
    label: "Emails",
    path: "emails",
    identifier: "customer-emails",
  },
  {
    label: "Tasks",
    path: "tasks",
    identifier: "customer-tasks",
  },
  {
    label: "Notes",
    path: "notes",
    identifier: "customer-notes",
  },
  {
    label: "Documents",
    path: "documents",
    identifier: "customer-documents",
  },
];

const CustomerCRM = ({
  customer,
  statuses=[],
  loadMemberNoteStatus,
  subPath,
  shortList,
}) => {
  const { pathname } = useLocation();
  let { url } = useRouteMatch();
  const baseURL = url;
//  Enforce that if this is in a default RouteTab, then the children will use the sub path.
//  ex. Makes sure that /customers/1234/connect links to /customers/1234/connect/contact-history/documents
//      and not /customers/1234/connect/documents (which loads a different component)
  if(subPath){
    url = url.replace('/' + subPath, '' ) + '/' + subPath;
  }
  const types = useSelector(memberNoteTypesSelectors.selectAll);

  useEffect(() => {
    loadMemberNoteStatus();
  }, [loadMemberNoteStatus]);

  return (
    <div>
      <RouteTabs
        nested={!shortList}
        pathname={pathname}
        url={url}
        routes={ROUTES}
        permissions={{}}
        // fillBkg={media.medium ? true : false}
        justify="flex-start"
        color={shortList ? "primaryInverse" : "primary"}
        textColor={shortList ? "primaryInverse" : null}
        className={shortList ? "bg-gradient-black" : null}
      />
      <Switch>
        <Route path={`${url}/emails`}>
          <Emails
            parentEntity={customer}
            shortList={shortList}
          />
        </Route>
        <Route path={`${url}/tasks`}>
          <Tasks
            parentEntity={customer}
          />
        </Route>
        <Route path={`${url}/documents`}>
          <Documents nid={customer.nid} />
        </Route>
        <Route path={`${url}/notes`}>
          <Notes nid={customer.nid} shortList={shortList} />
        </Route>
        <Route path={[`${baseURL}`, `${url}`, `${url}/calls`]}>
          <Calls
            parentEntity={customer}
            types={types}
            shortList={shortList}
          />
        </Route>
      </Switch>
    </div>
  );
};

CustomerCRM.propTypes = {};

const mapStateToProps = (state) => ({
  statuses: state.app.memberNoteStatus.data,
  loadingStatus: state.app.memberNoteStatus.loading,
});

const mapDispatchToProps = (dispatch) => ({
  loadMemberNoteStatus: () => dispatch(getMemberNoteStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerCRM);
