import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MenuItem, ListItemIcon, Typography, Grid } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useHistory } from "react-router-dom";

import { SelectFormField } from "common/Fields";
import { TextFormField } from "common/Fields";
import { DialogForm } from "common/Dialog";

import {
  fetchJobDivisions,
  getJobDataSelector,
  postJobDivision,
} from "../../jobSlice";
import {
  jobDivisionsStatusSelectors,
  jobDivisionTypesSelectors,
  sourceOfLossSelectors,
} from "features/Taxonomies/taxonomiesSlice";
import { unwrapResult } from "@reduxjs/toolkit";
// import { getMemberDataSelector } from "features/Member/memberSlice";
import MemberSelector from "features/Member/components/MemberSelector";
import { size, first } from "lodash";

const AddDivision = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const job = useSelector(getJobDataSelector);
  // const member = useSelector(getMemberDataSelector);
  const types = useSelector(jobDivisionTypesSelectors.selectAll);
  const sources = useSelector(sourceOfLossSelectors.selectAll);
  const statuses = useSelector(jobDivisionsStatusSelectors.selectAll);
  const user = useSelector((state) => state.auth.user.data);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async (data) => {
    try {
      const params = {
        field_job_division_type: [{ target_id: data.field_job_division_type }],
        field_jd_status: [{ target_id: data.field_jd_status }],
        field_reference_num: [{ value: data.field_reference_num }],
        field_cause_select: [{ value: data.field_cause_select }],
        field_cause: [{ value: data.field_cause }],
        field_jd_mf_caller: [{ value: data.field_jd_mf_caller }],
        field_jd_mf_caller_phone: [{ value: data.field_jd_mf_caller_phone }],
        // field_audit_file_categories: [{ value: "" }],
        field_assigned_member: [{ target_id: data.field_assigned_member.nid }],
      };

      const resultAction = await dispatch(
        postJobDivision({
          id: job.nid,
          params,
          meta: {},
        })
      );

      unwrapResult(resultAction);
      dispatch(fetchJobDivisions(job.nid));
      history.push(resultAction.payload.self);
    } catch (err) {
      return false;
    }
  };

  const onClose = () => {
    setOpen(false);
  };


  let defaultMember = {};
  if(size(job.field_member_owned)){
    defaultMember = {
      nid: job.field_member_owned.nid,
      name: job.field_member_owned.title,
    }
  }
  if(!defaultMember?.nid && user.roleType === 'member' && size(user?.field_members)){
    let firstMember = first(user.field_members);
    defaultMember = {
      nid: firstMember.nid,
      name: firstMember.title,
    };
  }

  return (
    <>
      <MenuItem onClick={handleOpen}>
        <ListItemIcon>
          <Add fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">Add Division</Typography>
      </MenuItem>
      <DialogForm
        open={open}
        onClose={onClose}
        maxWidth="xs"
        title="Add Division"
        initialValues={{
          field_assigned_member: defaultMember,
          field_job_division_type: "",
          field_reference_num: "",
          field_source_loss: "",
          field_jd_mf_caller: "",
          field_jd_mf_caller_phone: "",
          field_jd_status: "",
        }}
        disablePadding
        onSubmit={handleSubmit}
      >
        {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
          <div style={{ padding: "1.25rem" }}>
            <Grid container spacing={2}>
              <Grid item xxs={12}>
                <MemberSelector
                  name="field_assigned_member"
                  disabled={isSubmitting}
                  phxClientNid={job.field_phoenix_client.nid}
                  required
                />
              </Grid>
              <Grid item xxs={12}>
                <TextFormField
                  fullWidth
                  name="field_jd_mf_caller"
                  label="Caller"
                  disabled={isSubmitting}
                  autoComplete="off"
                  variant="filled"
                  size="small"
                />
              </Grid>
              <Grid item xxs={12}>
                <TextFormField
                  fullWidth
                  name="field_jd_mf_caller_phone"
                  label="Caller Phone"
                  disabled={isSubmitting}
                  autoComplete="off"
                  isPhone
                  variant="filled"
                  size="small"
                />
              </Grid>
              <Grid item xxs={12}>
                <SelectFormField
                  fullWidth
                  name="field_job_division_type"
                  label="Division Type"
                  required
                  size="small"
                  variant="filled"
                  disabled={isSubmitting}
                  options={types.map((type) => ({
                    label: type.name,
                    value: type.tid,
                  }))}
                />
              </Grid>
              <Grid item xxs={12}>
                <SelectFormField
                  fullWidth
                  name="field_source_loss"
                  label="Source"
                  size="small"
                  variant="filled"
                  disabled={isSubmitting}
                  options={sources.map((source) => ({
                    label: source.name,
                    value: source.tid,
                  }))}
                />
              </Grid>
              {values.source === "3393" && (
                <Grid item xxs={12}>
                  <TextFormField
                    fullWidth
                    name="source-other"
                    label="Source Other"
                    disabled={isSubmitting}
                    type="text"
                    multiline
                    size="small"
                    variant="filled"
                  />
                </Grid>
              )}
              <Grid item xxs={12}>
                <SelectFormField
                  fullWidth
                  name="field_jd_status"
                  label="Status"
                  required
                  disabled={isSubmitting}
                  size="small"
                  variant="filled"
                  options={statuses.map((status) => ({
                    label: status.name,
                    value: status.tid,
                  }))}
                />
              </Grid>
              <Grid item xxs={12}>
                <TextFormField
                  fullWidth
                  name="field_reference_num"
                  label="Reference Number"
                  disabled={isSubmitting}
                  size="small"
                  variant="filled"
                />
              </Grid>
            </Grid>
          </div>
        )}
      </DialogForm>
    </>
  );
};

AddDivision.propTypes = {};

export default AddDivision;
