import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Menu, MenuItem, Tooltip } from "@mui/material";
import { useRouteMatch, Route, useHistory } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import moment from "moment";
import styled from "styled-components";

import { IsNew, CellSubheading } from "components/Table/Styled";
import LinkHeader from "common/LinkHeader";
import Card, { CardHeader } from "common/Card";
import Status from "components/Status";
import { MuiTable } from "common/Table";
import { DivisionActions, Information } from "features/Divisions";
import TimeDisplayCell from "components/TimeDisplayCell";
import { getWeek } from "utility";

import {
  divisionsSelectors,
  getDivisionsLoadingSelector,
  getDivisionsPaginationSelector,
} from "../divisionsSlice";

const ActionStyles = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0;
  padding: 4px;
  border-radius: 2px;

  &:hover {
    background: var(--color-gray-light);
  }
`;

const JobStatus = ({ machine_name, name }) => {
  return (
    <Status type={machine_name} indicator>
      {name}
    </Status>
  );
};

const Actions = ({ filter, setFilter }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const userProcessed = useSelector((state) => state.auth.user.data._processed);
  const {weekStart, weekEnd} = getWeek(userProcessed.week_start);

  const options = useMemo(
    () => [
      {
        label: "Week",
        start: weekStart,
        end: weekEnd,
      },
      {
        label: "Month",
        start: moment().clone().startOf("month").format("YYYY-MM-DD"),
        end: moment().clone().endOf("month").format("YYYY-MM-DD"),
      },
      {
        label: "Year",
        start: moment().clone().startOf("year").format("YYYY-MM-DD"),
        end: moment().clone().endOf("year").format("YYYY-MM-DD"),
      },
    ],
    [weekStart, weekEnd]
  );

  const handleClose = (index) => (e) => {
    if (options[index]) {
      setFilter(options[index]);
    }
    setAnchorEl(null);
  };

  return (
    <>
      <ActionStyles onClick={(e) => setAnchorEl(e.currentTarget)}>
        <Typography style={{ marginRight: "0.5rem" }}>
          {filter.label}
        </Typography>
        {Boolean(anchorEl) ? <ExpandLess /> : <ExpandMore />}
      </ActionStyles>
      <Menu
        id="filter-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose(null)}
      >
        <MenuItem onClick={handleClose(0)}>Week</MenuItem>
        <MenuItem onClick={handleClose(1)}>Month</MenuItem>
        <MenuItem onClick={handleClose(2)}>Year</MenuItem>
      </Menu>
    </>
  );
};

const RecentDivisions = ({ nid, action }) => {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const history = useHistory();
  const clientNid = useSelector((state) => state.auth.current_client);
  const divisions = useSelector(divisionsSelectors.selectAll);
  const loading = useSelector(getDivisionsLoadingSelector);
  const pagination = useSelector(getDivisionsPaginationSelector);
  const userProcessed = useSelector((state) => state.auth.user.data._processed);
  const {weekStart, weekEnd} = getWeek(userProcessed.week_start);
  const [filter, setFilter] = useState({
    label: "Week",
    start: weekStart,
    end: weekEnd,
  });

  useEffect(() => {
    let params = {
      "filter[created][0][DATE BETWEEN][0]": filter.start,
      "filter[created][0][DATE BETWEEN][1]": filter.end,
    };

    if (clientNid) {
      params["filter[phx_client_nid]"] = clientNid;
    }

    const promise = dispatch(action({ id: nid, params }));

    return () => {
      promise.abort();
    };
  }, [dispatch, action, nid, clientNid, filter]);

  const columns = useMemo(
    () => [
      {
        id: "changed",
        label: (
          <Tooltip title="Last Updated">
            <IsNew isNew={false}></IsNew>
          </Tooltip>
        ),
        width: 5,
        hiddenProps: {
          only: ["xxs"],
        },
      },
      {
        id: "title",
        label: "Number",
        minWidth: 200,
        width: 200,
      },
      {
        id: "created",
        label: "Created",
        minWidth: 200,
        width: 200,
        hiddenProps: {
          only: ["xxs"],
        },
      },
      {
        id: "customer_name",
        label: "Customer",
        minWidth: 300,
        hiddenProps: {
          only: ["xxs"],
        },
      },
      {
        id: "status",
        label: "Status",
        minWidth: 250,
        hiddenProps: {
          only: ["xxs"],
        },
      },
      {
        id: "actions",
        label: "",
        width: 40,
      },
    ],
    []
  );

  const data = useMemo(
    () =>
      divisions.map((division) => {
        return {
          changed: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <IsNew isNew={Number(division.is_new) === 1}>
                <div hidden>{division.changed}</div>
              </IsNew>
              <TimeDisplayCell time={division.changed} style={{ marginLeft: "1rem" }} />
            </div>
          ),
          title: (
            <div>
              <LinkHeader
                to={division._paths.division}
                size="medium"
                color="primary"
              >
                {division.title}
              </LinkHeader>
              <CellSubheading>{division.job_division_type}</CellSubheading>
            </div>
          ),
          created: <TimeDisplayCell time={division.created} />,
          customer_name: (
            <div>
              <div>
                <LinkHeader
                  to={division._paths.customer}
                  size="medium"
                  noWrap
                  width={300}
                >
                  {division.customer_name}
                </LinkHeader>
              </div>
              <LinkHeader
                to={division._paths.customer_location}
                size="small"
                color="secondary"
                noWrap
                width={300}
              >
                {division.customer_location_name}
              </LinkHeader>
            </div>
          ),
          status: (
            <JobStatus
              machine_name={division.status}
              name={division.status_proper}
            />
          ),
          actions: <DivisionActions nid={division.division_nid} url={url} />,
        };
      }),
    [divisions, url]
  );

  return (
    <Card>
      <CardHeader
        title="Recent jobs"
        rightActions={<Actions filter={filter} setFilter={setFilter} />}
      />
      <MuiTable
        rows={data}
        columns={columns}
        loading={loading}
        size="medium"
        stickyHeader
        flex
        pagination={pagination}
      />
      <Route
        path={`${url}/:id`}
        render={() => {
          return <Information onClose={() => history.push(url)} />;
        }}
      />
    </Card>
  );
};

RecentDivisions.propTypes = {};

export default RecentDivisions;
