import React from "react";
import { Tooltip } from "@mui/material";
import { IsNew } from "../../components/Table/Styled";

export const jobsColumnsBase = [
  {
    id: "changed",
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <IsNew isNew={false}></IsNew>
        <div style={{ marginLeft: "1rem" }}>Last Updated</div>
      </div>
    ),
    width: 200,
    minWidth: 200,
    hiddenProps: {
      only: ["xxs"],
    },
  },
  {
    id: "icon",
    label: "",
    width: 20,
    hiddenProps: {
      only: ["xxs"],
    },
  },
  {
    id: "title",
    label: "Number",
    minWidth: 200,
    width: 200,
  },
  {
    id: "status",
    label: "Status",
    minWidth: 200,
    hiddenProps: {
      only: ["xxs"],
    },
  },
  {
    id: "milestone_progress",
    label: "Milestone Progress",
    minWidth: 75,
    hiddenProps: {
      only: ["xxs"],
    },
  },
  {
    id: "reference_number",
    label: "Reference",
    minWidth: 100,
    width: 100,
    hiddenProps: {
      only: ["xxs"],
    },
  },
  {
    id: "created",
    label: "Created",
    minWidth: 200,
    width: 200,
    hiddenProps: {
      only: ["xxs"],
    },
  },
  {
    id: "nte_is_set",
    label: "NTP",
    disablePadding: true,
    minWidth: 0,
    width: 20,
    hiddenProps: {
      only: ["xxs"],
    },
  },
  // {
  //   id: "category",
  //   label: "Cat",
  //   disablePadding: true,
  //   minWidth: 0,
  //   width: 20,
  //   hiddenProps: {
  //     only: ["xxs"],
  //   },
  // },
  {
    id: "customer_name",
    label: "Customer",
    minWidth: 300,
    hiddenProps: {
      only: ["xxs"],
    },
  },
  {
    id: "member_name",
    label: "Member",
    minWidth: 300,
    hiddenProps: {
      only: ["xxs"],
    },
  },
];

export const jobsColumns = [
  ...jobsColumnsBase,
  {
    id: "job_source",
    label: "Job Source",
    minWidth: 150,
    hiddenProps: {
      only: ["xxs"],
    },
  },
  {
    id: "progress",
    label: "",
    minWidth: 40,
    hiddenProps: {
      only: ["xxs"],
    },
  },
  {
    id: "actions",
    label: "",
    width: 40,
  },
];

export const memberJobsColumns = [
  {
    id: "changed",
    label: (
      <Tooltip title="Last Updated">
        <IsNew isNew={false}></IsNew>
      </Tooltip>
    ),
    width: 200,
    minWidth: 200,
  },
  { id: "title", label: "Number", minWidth: 200, width: 200 },
  { id: "customer_name", label: "Customer", minWidth: 300 },
  { id: "member_name", label: "Member", minWidth: 300 },
  {
    id: "estimator",
    label: "Estimator",
    minWidth: 200,
  },
  {
    id: "coordinator",
    label: "Coordinator",
    minWidth: 200,
  },
  {
    id: "created",
    label: "Created",
    minWidth: 150,
    width: 150,
  },
  {
    id: "status",
    label: "Status",
    minWidth: 200,
  },
];

export const customerJobsColumns = [
  {
    id: "changed",
    label: (
      <Tooltip title="Last Updated">
        <IsNew isNew={false}></IsNew>
      </Tooltip>
    ),
    width: 200,
    minWidth: 200,
  },
  { id: "title", label: "Number", minWidth: 200, width: 200 },
  { id: "customer_location_name", label: "Location", minWidth: 300 },
  { id: "member_name", label: "Member", minWidth: 300 },
  {
    id: "status",
    label: "Status",
    minWidth: 200,
  },
];
