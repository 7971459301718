import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import {
  Checkbox,
  FormHelperText,
  FormControl,
  FormControlLabel,
} from "@mui/material";

const CheckboxFormField = ({
  aria,
  label,
  emptyLabel,
  margin,
  disabled,
  name,
  helperText,
  wrapperStyle,
  wrapperClass,
  labelStyle,
  useValue,
  ignoreErrors,
  ...props
}) => {
  return (
    <Field name={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form,
        meta,
      }) => {
        const handleChange = (e) => {
          let value = e.target.checked;
          if(useValue){
            value = (e.target.checked ? e.target.value : false);
          }
          form.setFieldValue(name, value);
        };

        const errorText = !ignoreErrors && meta.error && meta.touched ? meta.error : "";
        return label || emptyLabel ? (
          <FormControl component="fieldset" error={!!errorText} margin={margin} style={wrapperStyle} className={wrapperClass}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={field.value}
                  onChange={handleChange}
                  name={field.name}
                  disabled={disabled}
                  {...props}
                />
              }
              label={label}
              disabled={disabled}
              style={labelStyle}
            />
            <FormHelperText error={!!errorText}>
              {errorText || helperText}
            </FormHelperText>
          </FormControl>
        ) : <></>;
      }}
    </Field>
  );
};

const { string, bool, oneOfType, object } = PropTypes;
CheckboxFormField.propTypes = {
  aria: string,
  label: oneOfType([string, object]),
  margin: string,
  disabled: bool,
};

export default CheckboxFormField;
