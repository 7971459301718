import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { GoDiffAdded } from "react-icons/go";

import { RadioFormField } from "common/Fields";
import { TextFormField } from "common/Fields";
import { SelectFormField } from "common/Fields";
import { editWorkareaSchema } from "schemas/forms";
import CreateGroup from "./CreateGroup";
import { DialogForm } from "common/Dialog";

import { postWorkArea, workAreaGroupsSelectors } from "../workAreasSlice";
import {
  jobDivisionTypesSelectors,
  getJobDivisionTypesLoadingSelector,
} from "features/Taxonomies/taxonomiesSlice";
import { getDivisionDataSelector } from "features/Job/jobSlice";
import { fetchJobProgress } from "features/JobProgress/jobProgressSlice";

const Create = ({ handleRefresh, nid, children }) => {
  const dispatch = useDispatch();
  const types = useSelector(jobDivisionTypesSelectors.selectAll);
  const loadingTypes = useSelector(getJobDivisionTypesLoadingSelector);
  const groups = useSelector(workAreaGroupsSelectors.selectAll);
  const division = useSelector(getDivisionDataSelector);

  const [openCreate, setOpenCreate] = useState(false);
  const [open, setOpen] = useState(false);

  const handleSubmit = async (
    data,
    setSubmitting,
    setMessage,
    successCallback,
    errorCallback
  ) => {
    setSubmitting(true);
    setMessage(null);
    const type = types.find((t) => t.tid === data.type);

    data.id = "temp";
    data.type_text = type?.name;
    dispatch(postWorkArea({ id: division.nid, params: data }))
      .then((resp) => {
        dispatch(fetchJobProgress(division.nid))
        handleRefresh();
        handleClose();
      });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {children ? (
        React.cloneElement(children, {
          onClick: handleOpen,
        })
      ) : (
        <ListItem button onClick={handleOpen} style={{ paddingLeft: "2rem" }}>
          <ListItemIcon>
            <GoDiffAdded />
          </ListItemIcon>
          <ListItemText primary="Add Area" />
        </ListItem>
      )}
      <DialogForm
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        title="Add Work Area"
        initialValues={{
          name: "",
          location: "interior",
          type: "",
          power: "y",
          demo: "n",
          safety: "n",
          "safety-issue": "",
          scope: "",
          sqft: 0,
          lf: 0,
          water: "n",
          "water-height": 0,
          general: "false",
          consumables: "",
        }}
        validationSchema={editWorkareaSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
          <>
            <RadioFormField
              name="general"
              aria="Is a general area"
              label="General Area"
              margin="normal"
              disabled={isSubmitting}
              style={{ flexDirection: "row" }}
              options={[
                {
                  value: "true",
                  label: "Yes",
                },
                {
                  value: "false",
                  label: "No",
                },
              ]}
            />
            <Grid item xxs={12}>
              <SelectFormField
                fullWidth
                name="group"
                label="Add to Group"
                margin="normal"
                disabled={isSubmitting}
                options={[
                  {
                    value: "_add-group",
                    label: "Add New Group",
                  },
                  ...groups.map((group) => ({
                    value: group.nid,
                    label: group.title,
                  })),
                ]}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value !== "_add-group") {
                    setFieldValue("group", e.target.value);
                  } else {
                    setOpenCreate(true);
                  }
                }}
                variant="filled"
                size="small"
              />
            </Grid>
            <TextFormField
              fullWidth
              htmlFor="name"
              name="name"
              label="Name"
              margin="normal"
              required
              disabled={isSubmitting}
              type="text"
              variant="filled"
              size="small"
            />
            <SelectFormField
              fullWidth
              name="type"
              label="Damage Type"
              margin="normal"
              disabled={isSubmitting}
              required
              options={
                !loadingTypes
                  ? types.map((option) => ({
                      value: option.tid,
                      label: option.name,
                    }))
                  : []
              }
              variant="filled"
              size="small"
            />
            {values.general === "false" && (
              <>
                <Grid container spacing={3}>
                  <Grid item xxs={6}>
                    <RadioFormField
                      name="location"
                      aria="location"
                      label="Location"
                      margin="normal"
                      disabled={isSubmitting}
                      options={[
                        { value: "exterior", label: "Exterior" },
                        { value: "interior", label: "Interior" },
                      ]}
                    />
                  </Grid>
                  <Grid item xxs={6}>
                    <RadioFormField
                      name="power"
                      aria="power"
                      label="Power Available"
                      margin="normal"
                      disabled={isSubmitting}
                      options={[
                        { value: "y", label: "Yes" },
                        { value: "n", label: "No" },
                      ]}
                    />
                  </Grid>
                  <Grid item xxs={6}>
                    <RadioFormField
                      name="demo"
                      aria="demo"
                      label="Demo Needed"
                      margin="normal"
                      disabled={isSubmitting}
                      options={[
                        { value: "y", label: "Yes" },
                        { value: "n", label: "No" },
                      ]}
                    />
                  </Grid>
                  <Grid item xxs={6}>
                    <RadioFormField
                      name="safety"
                      aria="safety"
                      label="Life Safety Issue"
                      margin="normal"
                      disabled={isSubmitting}
                      options={[
                        { value: "y", label: "Yes" },
                        { value: "n", label: "No" },
                      ]}
                    />
                  </Grid>
                </Grid>
                {values.safety === "y" && (
                  <TextFormField
                    fullWidth
                    htmlFor="safety-issue"
                    name="safety-issue"
                    label="Safety Issue"
                    margin="normal"
                    disabled={isSubmitting}
                    type="text"
                    multiline
                    variant="filled"
                    size="small"
                  />
                )}
              </>
            )}
            <TextFormField
              fullWidth
              htmlFor="scope"
              name="scope"
              label="Scope of Work"
              margin="normal"
              disabled={isSubmitting}
              required={true}
              type="text"
              multiline
              variant="filled"
              size="small"
            />
            {values.general === "false" && (
              <Grid container spacing={3}>
                <Grid item xxs={6}>
                  <TextFormField
                    fullWidth
                    htmlFor="sqft"
                    name="sqft"
                    label="Damage Area (SQFT)"
                    margin="normal"
                    disabled={isSubmitting}
                    type="number"
                    variant="filled"
                    size="small"
                    endAdornment={
                      <InputAdornment position="end">sqft</InputAdornment>
                    }
                  />
                </Grid>
                <Grid item xxs={6}>
                  <TextFormField
                    fullWidth
                    htmlFor="lf"
                    name="lf"
                    label="Damage Area (LF)"
                    margin="normal"
                    disabled={isSubmitting}
                    type="number"
                    variant="filled"
                    size="small"
                    endAdornment={
                      <InputAdornment position="end">lf</InputAdornment>
                    }
                  />
                </Grid>
                <Grid item xxs={6}>
                  <RadioFormField
                    name="water"
                    aria="water"
                    label="Water Present"
                    margin="normal"
                    disabled={isSubmitting}
                    options={[
                      { value: "y", label: "Yes" },
                      { value: "n", label: "No" },
                    ]}
                  />
                </Grid>
                {values.water === "y" && (
                  <Grid item xxs={6}>
                    <TextFormField
                      fullWidth
                      htmlFor="water-height"
                      name="water-height"
                      label="Height of Water"
                      margin="normal"
                      disabled={isSubmitting}
                      type="number"
                      variant="filled"
                      size="small"
                      endAdornment={
                        <InputAdornment position="end">in</InputAdornment>
                      }
                    />
                  </Grid>
                )}
                <Grid item xxs={12}>
                  <TextFormField
                    fullWidth
                    htmlFor="consumables"
                    name="consumables"
                    label="Consumables %"
                    margin="normal"
                    disabled={isSubmitting}
                    type="text"
                    multiline
                    variant="filled"
                    size="small"
                  />
                </Grid>
              </Grid>
            )}
            <CreateGroup
              open={openCreate}
              onClose={() => setOpenCreate(false)}
              setFieldValue={setFieldValue}
              nid={division.nid}
            />
          </>
        )}
      </DialogForm>
    </>
  );
};

Create.propTypes = {};

export default Create;
