import styled from "styled-components";
import constants from "../constants";

export const Container = styled.div`
  & .fc-body > tr > .fc-widget-content {
    border: none;
  }

  & .fc-head-container {
    border-left: none;
    border-right: none;
  }
`;

export const Toolbar = styled.div`
  background: ${constants.colorGrayLighter};
  padding: 1rem;
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const ToolbarActionsRight = styled.div`
  align-items: center;
  display: flex;

  & > .MuiButtonBase-root {
    margin-left: 1rem;
  }
`;

export const ToolbarActionsLeft = styled.div`
  align-items: center;
  display: flex;

  & > .MuiButtonBase-root {
    margin-right: 1rem;
  }
`;
