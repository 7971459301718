import {
  getPathFromLinksSelf,
  formatField,
  formatFieldByValue,
  // getUserFields,
  // getFileFields,
} from "../../utility";

export const statuses = {
  final: "Finalized",
  unfinal: "Unfinalized",
  void: "Void",
};

export const paidStatuses = {
  billed: "Billed",
  partial: "Partial Payment",
  full: "Paid in Full",
};

export const formatInvoiceLineItem = (d) => {
  return {
    self: getPathFromLinksSelf(d),
    type: "line_item",
    id: formatField(d, "id"),
    created: formatField(d, "created"),
    changed: formatField(d, "changed"),
    field_li_description: formatField(d, "field_li_description"),
    field_li_discount: formatField(d, "field_li_discount"),
    field_li_discount_percent: formatField(d, "field_li_discount_percent"),
    field_tm_terms: JSON.parse(formatFieldByValue(
      d,
      "field_tm_terms",
      "value"
    )),
    field_invoice_discount_details: JSON.parse(formatFieldByValue(
      d,
      "field_invoice_discount_details",
      "value"
    )),
    field_li_product_text: formatField(d, "field_li_product_text"),
    field_li_product_type: formatField(d, "field_li_product_type"),
    field_li_qty: formatField(d, "field_li_qty"),
    field_li_rate: formatField(d, "field_li_rate"),
    field_li_taxable: formatField(d, "field_li_taxable"),
    field_li_total: formatField(d, "field_li_total"),
  };
};

export const checkSeparateCategory = (cats, name) => {
  let match = false;
  const find = name.toLowerCase().replace(/\s/gi, '').replace(/(ies)/gi, 'y').replace(/s/gi, '');
  if(cats.find((cat) => {
    return cat.name.toLowerCase().replace(/\s/gi, '').replace(/(ies)/gi, 'y').replace(/s/gi, '') === find
  })){
    match = true;
  }
  return match;
};
