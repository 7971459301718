import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Tune/*, Layers*/ } from "@mui/icons-material";
import { size } from "lodash";
import styled from "styled-components";
import {
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Popover,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  // SvgIcon,
} from "@mui/material";

import { useDebounce } from "../../../hooks";
import constants from "components/constants";
import { Legend } from "../Styled";
import { formatCoords } from "../../../utility";
// import AddZone from "../AddZone";
// import MapZones from "../MapZones";
import config from "config";
import MapZipCodes from "../MapZipCodes";
import {
  memberAffiliationsSelectors,
} from "features/Taxonomies/taxonomiesSlice";

const jobMarker = `${process.env.PUBLIC_URL}/images/icons/icon-job-marker.svg`;
const customerMarker = `${process.env.PUBLIC_URL}/images/icons/icon-customer-marker.svg`;
const memberMarker = `${process.env.PUBLIC_URL}/images/icons/icon-member-marker.svg`;

const Container = styled.div`
  width: 300px;
  position: absolute;
  height: 60vh;
  background: white;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  border-radius: ${constants.borderRadius};
  overflow: hidden;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  z-index: 1;

  .tabs{ height: 5vh; }
  .layers{

    .legend{
      height: 6vh;
      padding: 1rem 1.25rem 0 1.25rem;
      border-top: 1px solid var(--color-gray);
      border-bottom: 1px solid var(--color-gray);
    }

    .list{
      height: 49vh;
      overflow: auto;
    }
  }

`;

// const Tab = styled.div`
//   flex: 1;
//   padding: 0.75rem;
//   display: flex;
//   align-items: center;
//   background: ${(props) => (props.active ? "white" : "rgba(255, 255, 255, 0)")};
//   cursor: pointer;
//   position: relative;
//   border-right: 1px solid ${constants.colorGrayLight};
//   color: ${constants.colorGrayDark};

//   &:last-child {
//     border-right: none;
//   }
// `;

// const Tabs = styled.div`
//   display: flex;
//   position: relative;
//   background: ${constants.colorGrayLighter};
// `;

// function ZoneIcon(props) {
//   return (
//     <SvgIcon {...props}>
//       <circle cx="8.66699" cy="2" r="2" />
//       <circle cx="16.667" cy="4.66663" r="2" />
//       <circle cx="22" cy="12.6666" r="2" />
//       <circle cx="15.333" cy="22" r="2" />
//       <circle cx="2" cy="22" r="2" />
//       <circle cx="3.33301" cy="11.3334" r="2" />
//       <path
//         fillRule="evenodd"
//         clipRule="evenodd"
//         d="M8.4359 1.396L16.9875 4.24654L22.6074 12.6764L15.5906 22.5H1.43359L2.84958 11.172L8.4359 1.396ZM8.89741 2.60392L3.81705 11.4945L2.56638 21.5H15.076L21.3925 12.6568L16.3458 5.08672L8.89741 2.60392Z"
//       />
//     </SvgIcon>
//   );
// }

// const clearPolygonsFromMap = (zones) => {
//   zones.forEach((zone) => {
//     zone.poly.setMap(null);
//   });
// };

// const refreshMapZones = (
//   map,
//   maps,
//   mapZones,
//   polygons,
//   setPolygons,
//   setToggles
// ) => {
//   clearPolygonsFromMap(polygons);
//   const polys = [];
//   const viewToggles = {};
//   mapZones.forEach((zone, i) => {
//     const id = i + 1;
//     const polygon = new maps.Polygon({
//       paths: zone.coords
//         ? zone.coords.map((coords) => ({
//             lat: coords.lat,
//             lng: coords.lon,
//           }))
//         : [],
//       strokeColor: zone.color,
//       strokeOpacity: 0.8,
//       strokeWeight: 2,
//       fillColor: zone.color,
//       fillOpacity: 0.35,
//       editable: false,
//       map,
//     });

//     viewToggles[id] = true;
//     polys.push({
//       id,
//       nid: zone.zone_nid,
//       name: zone.title,
//       visible: zone.author_vis_only,
//       poly: polygon,
//       status: 1,
//     });
//   });

//   setToggles({ ...viewToggles });
//   setPolygons([...polys]);
// };

const weatherOptions = {
  "ftemperatures--50_ftemperatures-max-text": "Temperatures",
  "radar": "Radar",
  "alerts": "Alerts",
  "tropical-cyclones_tropical-cyclones-names": "Tropical Cyclones",
  "lightning-strikes-5m-icons": "Lightning",
  "stormcells": "Storm Cells",
  "fires-obs-icons": "Fires",
}

const MapFilters = ({
  myMap,
  myMaps,
  openAddZone,
  setOpenAddZone,
  mapZones,
  members,
  reps,
  states,
  ...props
}) => {
  // const [tab, setTab] = React.useState(1);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterMenu, setFilterMenu] = React.useState(null);
  const [customerStateFilters, setCustomerStateFilters] = React.useState([]);
  const [locationStateFilters, setLocationStateFilters] = React.useState([]);
  const [memberStateFilters, setMemberStateFilters] = React.useState([]);
  const [showRadiuses, setShowRadiuses] = React.useState(false);
  const [radiuses, setRadiuses] = React.useState([]);
  const [jobStateFilters, setJobStateFilters] = React.useState([]);
  const [toggleCluster, setToggleCluster] = React.useState(false);
  const [locationSearch, setLocationSearch] = React.useState("");
  const debouncedLocationSearch = useDebounce(locationSearch, 250);
  const [customerSearch, setCustomerSearch] = React.useState("");
  const debouncedCustomerSearch = useDebounce(customerSearch, 250);
  const [memberSearch, setMemberSearch] = React.useState("");
  const debouncedMemberSearch = useDebounce(memberSearch, 250);
  const [jobSearch, setJobSearch] = React.useState("");
  const debouncedJobSearch = useDebounce(jobSearch, 250);
  const [customerReps, setCustomerReps] = React.useState([]);

  const affiliations = useSelector(memberAffiliationsSelectors.selectAll);
  const [affiliationFilters, setAffiliationFilters] = React.useState([]);
  // const [toggles, setToggles] = React.useState({});
  // const [polygons, setPolygons] = React.useState([]);
  const [jobsQuerystring, setJobsQuerystring] = React.useState({
    keywords: "",
    clustering_toggle: "off",
    ...(props.org ? { "filter[phx_client_nid]": props.org } : {}),
  });
  const [membersQuerystring, setMembersQuerystring] = React.useState({
    keywords: "",
    clustering_toggle: "off",
    ...(props.org ? { "filter[phx_client_nids][0]": props.org } : {}),
  });
  const [locationsQuerystring, setLocationsQuerystring] = React.useState({
    keywords: "",
    clustering_toggle: "off",
    ...(props.org ? { "filter[phx_client_nid]": props.org } : {}),
  });
  const [customersQuerystring, setCustomersQuerystring] = React.useState({
    keywords: "",
    clustering_toggle: "off",
    ...(props.org ? { "filter[phx_client_nid]": props.org } : {}),
  });
  const defaults = {
    mapJobs: false,
    mapCustomers: false,
    mapLocations: false,
    mapMembers: true,
    zipCodes: false,
    weather: false,
  };
  Object.entries(weatherOptions).forEach((entry) => defaults[`weather_${entry[0]}`] = false);

  const [state, setState] = React.useState(defaults);

  useEffect(() => {
    if (props.org) {
      setJobsQuerystring({
        ...jobsQuerystring,
        "filter[phx_client_nid]": props.org,
      });
      setAffiliationFilters([]);
      setMembersQuerystring({
        ...membersQuerystring,
        "filter[phx_client_nids][0]": props.org,
      });
      setLocationsQuerystring({
        ...locationsQuerystring,
        "filter[phx_client_nid]": props.org,
      });
      setCustomersQuerystring({
        ...customersQuerystring,
        "filter[phx_client_nid]": props.org,
      });
    } else {
      setJobsQuerystring({
        ...jobsQuerystring,
      });
      setMembersQuerystring({
        ...membersQuerystring,
      });
      setLocationsQuerystring({
        ...locationsQuerystring,
      });
      setCustomersQuerystring({
        ...customersQuerystring,
      });
    }
  }, [props.org]); // eslint-disable-line

  // useEffect(() => {
  //   if (size(mapZones) && myMap && myMaps) {
  //     refreshMapZones(
  //       myMap,
  //       myMaps,
  //       mapZones,
  //       polygons,
  //       setPolygons,
  //       setToggles
  //     );
  //   }
  // }, [mapZones, myMap, myMaps]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (state.mapCustomers) {
      props.loadCustomers(0, customersQuerystring);
    } else {
      props.onClear("mapCustomers");
    }
  }, [state.mapCustomers, customersQuerystring]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCustomersQuerystring({
      ...customersQuerystring,
      keywords: debouncedCustomerSearch,
    });
  }, [debouncedCustomerSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const filters = {};
    customerStateFilters.forEach((filter, i) => {
      filters[`filter[state][${i}]`] = filter;
    });

    customerReps.forEach((rep, i) => {
      filters[`filter[assigned_rep_uid][${i}]`] = rep;
    });

    setCustomersQuerystring({
      ...(props.org ? { "filter[phx_client_nid]": props.org } : {}),
      keywords: debouncedCustomerSearch,
      clustering_toggle: "off",
      ...filters,
    });
  }, [customerStateFilters, customerReps]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (state.mapJobs) {
      props.loadJobs(null, null, 0, jobsQuerystring);
    } else {
      props.onClear("mapJobs");
    }
  }, [state.mapJobs, jobsQuerystring]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const filters = {};
    jobStateFilters.forEach((filter, i) => {
      filters[`filter[state][${i}]`] = filter;
    });

    setJobsQuerystring({
      ...(props.org ? { "filter[phx_client_nid]": props.org } : {}),
      keywords: debouncedJobSearch,
      clustering_toggle: "off",
      ...filters,
    });
  }, [jobStateFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setJobsQuerystring({
      ...jobsQuerystring,
      keywords: debouncedJobSearch,
    });
  }, [debouncedJobSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (state.mapLocations) {
      props.loadLocations(0, locationsQuerystring);
    } else {
      props.onClear("mapCustomerLocations");
    }
  }, [state.mapLocations, locationsQuerystring]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (state.mapMembers) {
      props.loadMembers(0, 0, membersQuerystring);
    } else {
      props.onClear("mapMembers");
      clearMemberRadiuses();
      setShowRadiuses(false);
    }
  }, [state.mapMembers, membersQuerystring]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const filters = {};
    memberStateFilters.forEach((filter, i) => {
      filters[`filter[state][${i}]`] = filter;
    });
    affiliationFilters.forEach((filter, i) => {
      filters[`filter[affiliation_tid][${i}]`] = filter;
    });

    setMembersQuerystring({
      ...(props.org ? { "filter[phx_client_nids][0]": props.org } : {}),
      keywords: debouncedMemberSearch,
      clustering_toggle: "off",
      ...filters,
    });
  }, [memberStateFilters, affiliationFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setMembersQuerystring({
      ...membersQuerystring,
      keywords: debouncedMemberSearch,
    });
  }, [debouncedMemberSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (showRadiuses) {
      recalculateMemberRadiuses();
    }
  }, [members]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLocationsQuerystring({
      ...locationsQuerystring,
      keywords: debouncedLocationSearch,
    });
  }, [debouncedLocationSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const filters = {};
    locationStateFilters.forEach((filter, i) => {
      filters[`filter[state][${i}]`] = filter;
    });

    setLocationsQuerystring({
      ...(props.org ? { "filter[phx_client_nid]": props.org } : {}),
      keywords: debouncedLocationSearch,
      clustering_toggle: toggleCluster ? "on" : "off",
      ...filters,
    });
  }, [locationStateFilters, toggleCluster]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleToggle = (value) => () => {
    setState({ ...state, [value]: !state[value] });
  };

  const handleFilterOpen = (e, name) => {
    setFilterMenu(name);
    setAnchorEl(e.currentTarget);
  };

  const handleStateChange = (e, bundle) => {
    if (bundle === "customer") {
      setCustomerStateFilters(e.target.value);
    } else if (bundle === "location") {
      setLocationStateFilters(e.target.value);
    } else if (bundle === "member") {
      setMemberStateFilters(e.target.value);
    } else if (bundle === "job") {
      setJobStateFilters(e.target.value);
    }
  };

  const handleClusterToggle = () => {
    setToggleCluster(!toggleCluster);
  };

  const handleRepChange = (e) => {
    setCustomerReps(e.target.value);
  };

  // const handleTabChange = (event, newValue) => {
  //   setTab(newValue);
  // };

  const handleRadiusesToggle = () => {
    if (!showRadiuses) {
      addMemberRadiuses();
    } else {
      clearMemberRadiuses();
    }

    setShowRadiuses(!showRadiuses);
  };

  const addMemberRadiuses = () => {
    const data = [];
    members.forEach((m) => {
      const { coords } = m;
      const position = formatCoords(coords);

      const circle = new myMaps.Circle({
        strokeColor: constants.colorOrange,
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: constants.colorOrange,
        fillOpacity: 0.35,
        map: myMap,
        center: position,
        radius: 144841,
      });

      data.push(circle);
    });

    setRadiuses(data);
  };

  const clearMemberRadiuses = () => {
    for (let i = 0; i < radiuses.length; i += 1) {
      radiuses[i].setMap(null);
    }

    setRadiuses([]);
  };

  const recalculateMemberRadiuses = () => {
    for (let i = 0; i < radiuses.length; i += 1) {
      radiuses[i].setMap(null);
    }

    addMemberRadiuses();
  };

  const toggleWeatherLayer = (e, index) => {
    const newState =  {...state};
    if(!index){
      newState.weather = !state.weather;
    }
    else{
      newState[index] = !state[index];
    }
    const overlays = Object.entries(weatherOptions)
                      .filter((entry) => newState[`weather_${entry[0]}`])
                      .map((entry) => entry[0].replace(/--/g, ':').replace(/_/g, ','));
    if (newState.weather && size(overlays)) {
      const radar = new myMaps.ImageMapType({
        getTileUrl: function (coord, zoom) {
          return [
          //   "http://maps.aerisapi.com/${config.aeris_client_id}_${config.aeris_client_secret}/radar,tropical-cyclones,tropical-cyclones-names,lightning-strikes-5m-icons,stormcells,alerts/",
            `https://maps.aerisapi.com/${config.aeris_client_id}_${config.aeris_client_secret}/${overlays.join(',')}/`,
            zoom,
            "/",
            coord.x,
            "/",
            coord.y,
            "/current.png",
          ].join("");
        },
        tileSize: new myMaps.Size(256, 256),
      });
      // myMap.overlayMapTypes.clear();
      myMap.overlayMapTypes.setAt(0, radar);
    } else {
      myMap.overlayMapTypes.clear();
    }

    setState(newState);
  };

  // const handleCancelAddZone = () => {
  //   openAddZone.setMap(null);
  //   setOpenAddZone(null);
  // };

  const handleMenuToggle = () => {
    setOpenMenu(!openMenu);
  };

  return <>
    <div
      style={{
        position: "absolute",
        bottom: 15,
        left: 15,
        background: "white",
        borderRadius: "3px",
        boxShadow: "0 0 12px rgba(0, 0, 0, 0.1)",
      }}
    >
      <IconButton onClick={handleMenuToggle} size="large">
        <Tune />
      </IconButton>
    </div>
    <Container hidden={!openMenu}>
      {/* <Tabs className="tabs">
        <Tab active={tab === 1} onClick={(e) => handleTabChange(e, 1)}>
          <Layers color="inherit" />
          <Typography style={{ marginLeft: "2px" }}>Layers</Typography>
        </Tab>
        <Tab active={tab === 2} onClick={(e) => handleTabChange(e, 2)}>
          <ZoneIcon color="inherit" />
          <Typography style={{ marginLeft: "4px" }}>Zones</Typography>
        </Tab>
      </Tabs> */}
      <div className="layers" /*hidden={tab !== 1}*/ >
        <div className="legend">
          <Legend>
            <div>
              <img src={customerMarker} alt="Customer Map Marker" />
              <Typography variant="body2">Customers</Typography>
            </div>
            <div>
              <img src={memberMarker} alt="Member Map Marker" />
              <Typography variant="body2">Members</Typography>
            </div>
            <div>
              <img src={jobMarker} alt="Job Map Marker" />
              <Typography variant="body2">Jobs</Typography>
            </div>
          </Legend>
        </div>
        <List className="list">
          <ListItem dense button onClick={handleToggle("mapJobs")}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={state.mapJobs}
                name="mapJobs"
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": "job-visibility-toggle" }}
              />
            </ListItemIcon>
            <ListItemText id="job-visibility-toggle" primary="Jobs" />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="jobs settings"
                onClick={(e) => handleFilterOpen(e, "jobs")}
                size="large">
                <Tune />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <Popover
            open={filterMenu === "jobs"}
            anchorEl={anchorEl}
            onClose={() => setFilterMenu(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div style={{ width: "300px", padding: "1.25rem" }}>
              <Typography
                color="textSecondary"
                variant="subtitle1"
                gutterBottom
              >
                Filters
              </Typography>
              <TextField
                variant="filled"
                name="jobsearch"
                label="Search"
                value={jobSearch}
                onChange={(e) => setJobSearch(e.target.value)}
                fullWidth
                style={{ marginBottom: "1rem" }}
              />
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                margin="normal"
              >
                <InputLabel id="member-state-filter-label">State</InputLabel>
                <Select
                  labelId="job-state-filter-label"
                  id="job-state-filter"
                  label="State"
                  multiple
                  value={jobStateFilters}
                  onChange={(e) => handleStateChange(e, "job")}
                >
                  {states.map((state, i) => (
                    <MenuItem key={i} value={state.value}>
                      {state.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Popover>
          <ListItem dense button onClick={handleToggle("mapMembers")}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={state.mapMembers}
                name="mapMembers"
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": "member-visibility-toggle" }}
              />
            </ListItemIcon>
            <ListItemText id="member-visibility-toggle" primary="Members" />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="members settings"
                onClick={(e) => handleFilterOpen(e, "members")}
                size="large">
                <Tune />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <Popover
            open={filterMenu === "members"}
            anchorEl={anchorEl}
            onClose={() => setFilterMenu(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div style={{ width: "300px", padding: "1.25rem" }}>
              <Typography
                color="textSecondary"
                variant="subtitle1"
                gutterBottom
              >
                Filters
              </Typography>
              <TextField
                variant="filled"
                name="membersearch"
                label="Search"
                value={memberSearch}
                onChange={(e) => setMemberSearch(e.target.value)}
                fullWidth
                style={{ marginBottom: "1rem" }}
              />
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                margin="normal"
              >
                <InputLabel id="member-state-filter-label">State</InputLabel>
                <Select
                  labelId="member-state-filter-label"
                  id="member-state-filter"
                  label="State"
                  multiple
                  value={memberStateFilters}
                  onChange={(e) => handleStateChange(e, "member")}
                >
                  {states.map((state, i) => (
                    <MenuItem key={i} value={state.value}>
                      {state.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                margin="normal"
              >
                <InputLabel id="member-affiliation-filter-label">Affiliation</InputLabel>
                <Select
                  labelId="member-affiliation-filter-label"
                  id="member-affiliation-filter"
                  label="Affiliation"
                  multiple
                  value={affiliationFilters}
                  onChange={(e) => setAffiliationFilters(e.target.value)}
                >
                {affiliations.filter((aff) =>
                    !props.org || aff.phx_client_nids.split(',').includes(String(props.org))
                  )
                  .map((aff, i) => (
                    <MenuItem key={i} value={aff.tid}>
                      {aff.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showRadiuses}
                      onChange={handleRadiusesToggle}
                      name="memberRadiuses"
                      inputProps={{
                        "aria-labelledby": "member-radiuses-toggle",
                      }}
                    />
                  }
                  label="Show Member Radiuses"
                />
              </FormControl>
            </div>
          </Popover>
          <ListItem dense button onClick={handleToggle("mapCustomers")}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={state.mapCustomers}
                name="mapCustomers"
                tabIndex={-1}
                disableRipple
                inputProps={{
                  "aria-labelledby": "customer-visibility-toggle",
                }}
              />
            </ListItemIcon>
            <ListItemText
              id="customer-visibility-toggle"
              primary="Customers"
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="customers settings"
                onClick={(e) => handleFilterOpen(e, "customers")}
                size="large">
                <Tune />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <Popover
            open={filterMenu === "customers"}
            anchorEl={anchorEl}
            onClose={() => setFilterMenu(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div style={{ width: "300px", padding: "1.25rem" }}>
              <Typography
                color="textSecondary"
                variant="subtitle1"
                gutterBottom
              >
                Filters
              </Typography>
              <TextField
                variant="filled"
                name="customersearch"
                label="Search"
                value={customerSearch}
                onChange={(e) => setCustomerSearch(e.target.value)}
                fullWidth
                style={{ marginBottom: "1rem" }}
              />
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                margin="normal"
              >
                <InputLabel id="customer-state-filter-label">
                  State
                </InputLabel>
                <Select
                  labelId="customer-state-filter-label"
                  id="customer-state-filter"
                  label="State"
                  multiple
                  value={customerStateFilters}
                  onChange={(e) => handleStateChange(e, "customer")}
                >
                  {states.map((state, i) => (
                    <MenuItem key={i} value={state.value}>
                      {state.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                margin="normal"
              >
                <InputLabel id="customer-rep-filter-label">Rep</InputLabel>
                <Select
                  labelId="customer-rep-filter-label"
                  id="customer-rep-filter"
                  label="Rep"
                  multiple
                  value={customerReps}
                  onChange={handleRepChange}
                >
                  {reps.map((rep) => (
                    <MenuItem key={rep.uid} value={rep.uid}>
                      {rep.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Popover>
          <ListItem dense button onClick={handleToggle("mapLocations")}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={state.mapLocations}
                name="mapLocations"
                tabIndex={-1}
                disableRipple
                inputProps={{
                  "aria-labelledby": "location-visibility-toggle",
                }}
              />
            </ListItemIcon>
            <ListItemText
              id="location-visibility-toggle"
              primary="Customer Locations"
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="customer locations settings"
                onClick={(e) => handleFilterOpen(e, "locations")}
                size="large">
                <Tune />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <Popover
            open={filterMenu === "locations"}
            anchorEl={anchorEl}
            onClose={() => setFilterMenu(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div style={{ width: "300px", padding: "1.25rem" }}>
              <Typography
                color="textSecondary"
                variant="subtitle1"
                gutterBottom
              >
                Filters
              </Typography>
              <TextField
                variant="filled"
                name="locationsearch"
                label="Search"
                value={locationSearch}
                onChange={(e) => setLocationSearch(e.target.value)}
                fullWidth
                style={{ marginBottom: "1rem" }}
              />
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                margin="normal"
              >
                <InputLabel id="location-state-filter-label">
                  State
                </InputLabel>
                <Select
                  labelId="location-state-filter-label"
                  id="location-state-filter"
                  label="State"
                  multiple
                  value={locationStateFilters}
                  onChange={(e) => handleStateChange(e, "location")}
                >
                  {states.map((state, i) => (
                    <MenuItem key={i} value={state.value}>
                      {state.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={toggleCluster}
                      onChange={handleClusterToggle}
                      name="locationCluster"
                      inputProps={{
                        "aria-labelledby": "location-cluster-toggle",
                      }}
                    />
                  }
                  label="Cluster Locations"
                />
              </FormControl>
            </div>
          </Popover>
          <MapZipCodes
            myMap={myMap}
            myMaps={myMaps}
            filterMenu={filterMenu}
            handleFilterOpen={handleFilterOpen}
            anchorEl={anchorEl}
            setFilterMenu={setFilterMenu}
            toggleState={state}
            setToggleState={setState}
            {...props}
          />
          <ListItem dense button onClick={toggleWeatherLayer} style={{alignItems: "flex-start"}}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={state.weather}
                name="weather"
                tabIndex={-1}
                disableRipple
                inputProps={{
                  "aria-labelledby": "weather-visibility-toggle",
                }}
              />
            </ListItemIcon>
            <ListItemText id="weather-visibility-toggle" primary="Weather" />
          </ListItem>
          <List
            style={{
              paddingLeft: "1rem",
              display: state.weather ? "block" : "none",
            }}
          >
            {Object.entries(weatherOptions).map((entry) => {
              const [option, label] = entry;
              return (
                <ListItem key={option} dense button onClick={(e) => toggleWeatherLayer(e, `weather_${option}`)}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={state[`weather_${option}`]}
                      name={`weather_${option}`}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        "aria-labelledby": `weather_${option}-toggle`,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText id={`weather_${option}-toggle`} primary={label} />
                </ListItem>
              );
            })}
          </List>
        </List>
      </div>
      {/* <div hidden={tab !== 2}>
        <MapZones
          maps={myMaps}
          map={myMap}
          postZone={props.addZone}
          showSnackbar={props.showSnackbar}
          zones={polygons}
          loadZones={props.loadZones}
          toggles={toggles}
          setToggles={setToggles}
          refreshMapZones={() =>
            refreshMapZones(
              myMap,
              myMaps,
              mapZones,
              polygons,
              setPolygons,
              setToggles
            )
          }
          removeZone={props.removeZone}
        />
      </div> */}
    </Container>
    {/* <AddZone
      open={Boolean(openAddZone)}
      poly={openAddZone}
      onCancel={handleCancelAddZone}
      onClose={() => setOpenAddZone(null)}
      addZone={props.addZone}
      showSnackbar={props.showSnackbar}
      loadZones={() => props.loadZones()}
      org={props.org}
    /> */}
  </>;
};

MapFilters.propTypes = {};

export default MapFilters;
