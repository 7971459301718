import React, { useState } from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import {
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  Chip,
} from "@mui/material";
import { size as checkSize } from "lodash";
import { useDebounce } from "hooks";
import { IconButton } from "@mui/material";
import { Nullable } from "./FieldStyles";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
    minHeight: "16px",
  },
  chip: {
    margin: 2,
  },
}));

const SelectFormField = ({
  name,
  label,
  labelId,
  id,
  multiple,
  variant,
  options,
  required,
  renderValue,
  labelwidth,
  fullWidth,
  margin,
  disabled,
  autoFocus,
  notched,
  size,
  helperText,
  wrapperClassName,
  children,
  inputProps,
  nullable,
  handleChange,
  ...props
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const debouncedOpen = useDebounce(open, 250);

  return (
    <Field name={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form,
        meta,
      }) => {
        const _handleChange = (e) => {
          form.setFieldValue(name, e.target.value);
          if(handleChange){
            handleChange(e);
          }
        };

      // Focus is triggered even when closing; so, only open if it's been conclusively closed
        const handleFocus = (e) => {
          if(!debouncedOpen){
            setOpen(true);
          }
        };
      // The field can have focus while closed; so, we need a manual trigger to open it
        const handleClick = (e) => {
          if(!open && !debouncedOpen){
            setOpen(true);
          }
        };
        const handleClose = () => {
          setOpen(false);
        };
        const handleBlur = () => {
          form.setFieldTouched(name, true);
          setOpen(false);
        };
        const errorText = meta.error && meta.touched ? meta.error : "";

        return (
          <FormControl
            error={!!errorText}
            fullWidth={fullWidth}
            variant={variant}
            size="small"
            margin={margin}
            required={required}
            className={wrapperClassName}
          >
            {label && (
              <InputLabel
                required={required}
                id={labelId}
                color={props.color ? props.color : "primary"}
                disabled={disabled}
              >
                {label}
              </InputLabel>
            )}
            <Select
              labelId={labelId}
              id={id}
              label={label}
              multiple={multiple}
              displayEmpty
              variant={variant}
              value={(field.value || field.value === '') && (children || checkSize(options))
                  ? field.value
                  : multiple || Array.isArray(field.value) ? [] : ''}
              onChange={_handleChange}
              onFocus={handleFocus}
              onClick={handleClick}
              onClose={handleClose}
              onBlur={handleBlur}
              open={open}
              disabled={disabled}
              autoFocus={autoFocus}
              // defaultValue=""
              inputProps={{
                id: id,
                name: name,
                size: size,
                ...inputProps,
              }}
              // style={{ height: "45px" }}
              labelwidth={labelwidth}
              renderValue={
                renderValue
                  ? (selected) => renderValue(selected)
                  : multiple && checkSize(options) ? (selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip
                          size="small"
                          key={value}
                          label={options.find((opt) => opt.value === value)?.label || value}
                          className={classes.chip}
                          onDelete={(e) => {
                            const newVal = selected.filter((sel) => sel !== value);
                            // form.setFieldValue(name, newVal);
                            _handleChange({
                              ...e,
                              target: {
                                ...e.target,
                                value: newVal
                              }
                            })
                          }}
                        />
                      ))}
                    </div>
                  ) : null
              }
              {...props}
              endAdornment={
                <>
                {props?.endAdornment}
                {nullable
                  ? <IconButton
                      disabled={disabled}
                      onClick={(e) => {e.stopPropagation(); form.setFieldValue(name, null) }}
                      sx={{...(size === "small" && {marginRight: "1rem"})}}
                    >
                      <Nullable  />
                    </IconButton>
                  : null}
                </>
              }
            >
              {children
                ? children
                : options.map((option) => (
                    <MenuItem key={option.key ? option.key : option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
            </Select>
            {!!errorText && (
              <FormHelperText error={!!errorText} id={id}>
                {errorText}
              </FormHelperText>
            )}
            {!!helperText && !errorText && (
              <FormHelperText id={id}>{helperText}</FormHelperText>
            )}
          </FormControl>
        );
      }}
    </Field>
  );
};

const { string, bool, oneOf, array, number } = PropTypes;
SelectFormField.propTypes = {
  name: string,
  label: string,
  labelId: string,
  id: string,
  multiple: bool,
  variant: oneOf(["outlined", "standard", "filled"]),
  options: array,
  required: bool,
  labelwidth: number,
  fullWidth: bool,
};

export default SelectFormField;
