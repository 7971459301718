import React from "react";
import {
  // Tooltip,
  // Dialog,
  // DialogTitle,
  // DialogContent,
  // DialogActions,
  // IconButton,
  // Button,
  Typography,
} from "@mui/material";
import { SignatureFormField } from "common/Fields";
import { TextFormField, DateFormField } from "common/Fields";
import ButtonLoader from "common/ButtonLoader";

const TimeClockSignature = ({timeClock, isSubmitting, submitForm, setFieldValue, values, children}) => {
  return (
    <>
    {timeClock.service_status === 'awaiting_signature' &&
      <div className="signature-message">
        <Typography variant="h6" className="text-orange">Awaiting Signature</Typography>
        {!timeClock.read_only &&
          <Typography variant="body">Make any last adjustments to your times, and then sign below.<br />Once signed your time card cannot be changed.</Typography>
        }
      </div>
    }

    {children}

    {(timeClock.service_status === 'complete' && timeClock?.signature) &&
      <div className="signature-display">
        <div className="image">
          <Typography variant="subtitle2">Signature</Typography>
          <img src={timeClock.signature} alt="Signature" />
        </div>
        <div className="name">{timeClock.signature_name}</div>
        <div className="date">{timeClock.signature_date}</div>
      </div>
    }
    {!timeClock.read_only && timeClock.service_status === 'awaiting_signature' &&
      <div className="signature-body">
        <Typography variant="subtitle1">Please sign</Typography>
        <Typography variant="body">By signing below, I acknowledge that the time card information is accurate to the best of my knowledge.</Typography>
        <SignatureFormField name="signature" />
        <div className="signature-name-date">
          <TextFormField
            fullWidth
            htmlFor="signature_name"
            name="signature_name"
            label="Your Name"
            variant="filled"
            size="small"
            disabled={isSubmitting}
            required
          />
          <DateFormField
            fullWidth
            disableToolbar
            variant="inline"
            format="MM/DD/YYYY"
            id="signature_date"
            label="Date"
            name="signature_date"
            inputVariant="filled"
            size="small"
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            disabled={isSubmitting}
            required
          />
          <ButtonLoader
            variant="contained"
            color="secondary"
            type="submit"
            isSubmitting={isSubmitting}
            disabled={isSubmitting}
            disableElevation
            size="small"
            onClick={() => {
              submitForm('signature');
            }}
          >
            Submit Signature
          </ButtonLoader>
        </div>
      </div>
    }
    </>
  );
};

TimeClockSignature.propTypes = {};

export default TimeClockSignature;

export const timeClockValidateSignature = (errors, values, setSubMessage) => {
  if(values.submitting_button === 'signature'){
    if(!values.signature){
      errors.signature = "Please use your mouse or finger to write a signature in the box above.";
      setSubMessage({
        id: "negative",
        msg: errors.signature,
      });
    }
    if(values.signature_name === ''){
      errors.signature_name = "Please enter a your name";
      setSubMessage({
        id: "negative",
        msg: errors.signature_name,
      });
    }
    if(!values.signature_date || values.signature_date === ''){
      errors.signature_date = "Please enter today's date";
      setSubMessage({
        id: "negative",
        msg: errors.signature_date,
      });
    }
  }

  return errors;
}
