import React from "react";
import { Typography, Grid, Tooltip } from "@mui/material";
import styled from "styled-components";
import { theme } from "styles/muiTheme";
import { capitalizeWords } from "utility";


const omitFromDetails = [
  'tc_photo',
  'tc_geo',
];

const Styles = styled.div`
  .TMTermsLaborTermDetails{
    padding: 1.25rem;
    color: ${theme.palette.text.secondary};

    &__content{
      border: 1px solid;
      padding: 0.75rem 0.75rem 0;

      &__grid{
        // &:last-child{
        //   flex: 1 0 auto;
        //   max-width: none;
        // }

        padding-bottom: 0.75rem;

        .exclusions{ cursor: pointer; }

        .label{ font-weight: bold; }
        .value{ font-size: 0.9em; padding-left: 0.75em; }
        .read-more{
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 88%;
        }
      }
    }
  }
`;

const TMTermsLaborTermDetails = ({division, laborTypes}) => {
  const formatIndex = (index) => {
    let output = capitalizeWords(index, '_');
    if(index === 'travel'){
      output += " Hours";
    }
    if(index === 'after_hours' || index === 'overtime'){
      output += " Rate";
    }
    return output;
  }

  return (
    division?.field_tm_terms?.labor &&
    <Styles>
      <div className="TMTermsLaborTermDetails">
        <Typography
            variant="subtitle1"
          >
            Labor Terms
        </Typography>
        <Grid container className="TMTermsLaborTermDetails__content">
        {Object.entries(division.field_tm_terms.labor)
          .filter(([index, item]) => omitFromDetails.indexOf(index) === -1)
          .map(([index, item]) =>
          <Grid item xxs={12} md={4} key={index} className="TMTermsLaborTermDetails__content__grid">
            <span className="label">{formatIndex(index)}: </span>
            {item?.multiplier && `x${item.multiplier}`}
            {(item?.value && index === 'travel') && item.value}
            {(item?.value && index !== 'travel') && `$${item.value}`}
            {item?.percent && `${Number(item.percent) * 100}%`}
            {item?.labor_type_excludes && item.labor_type_excludes.length > 0 &&
              <Tooltip title={item.labor_type_excludes.map((i) => <div>{laborTypes[i].name}</div>)}>
                <div className="exclusions">
                  <span className="label value">Exclusions:</span>
                  <div className="value read-more">{item.labor_type_excludes.map((i) => laborTypes[i].name).join(', ')}</div>
                  {/* {item.labor_type_excludes.map(ltIndex => <div key={ltIndex} className="value">{laborTypes[ltIndex].name}</div>)} */}
                </div>
              </Tooltip>
            }
          </Grid>
        )}
        </Grid>
      </div>
    </Styles>
  );
};

TMTermsLaborTermDetails.propTypes = {};

export default TMTermsLaborTermDetails;
