import React from "react";
import styled from "styled-components";
import { Action } from "components/Personnel/Styled";
import Personnel from "features/Job/forms/Personnel";
import AddProjectManager from "features/Job/forms/AddProjectManager";
import Card, { CardContent, CardHeader } from "common/Card";
import { AiOutlineUserAdd } from "react-icons/ai";
import { FaUserEdit/*, FaUserSlash*/ } from "react-icons/fa";
import { IconButton } from "@mui/material";

import { Typography } from "@mui/material";
import UserItem from "common/UserItem";
import IconTextItem from "common/IconTextItem";
import { IoIosPerson } from "react-icons/io";
import { MdPersonAddAlt1 } from "react-icons/md";
import Crew from "features/Crew";
import { getDivisionDataSelector/*, getJobDataSelector*/ } from "../jobSlice";
import { useSelector } from "react-redux";
import { first, toArray } from "lodash";
import { personnelTypes } from "components/Personnel/utils";

// const types = [
//   { value: "estimator", name: "Estimator" },
//   { value: "pm", name: "Project Manager" },
//   { value: "supervisor", name: "Supervisor" },
//   { value: "coordinator", name: "Coordinator" },
//   { value: "accounting", name: "Accounting" },
//   { value: "marketing", name: "Marketing" },
//   { value: "foreman", name: "Foreman" },
//   {
//     value: "lead_technician",
//     name: "Lead Technician",
//   },
//   {
//     value: "mrp",
//     name: "Managed Repair Personnel",
//   },
// ];

const groupBy = (data, key) => {
  if(!data) return [];
  return data.reduce((acc, cur) => {
    acc[cur[key]] = acc[cur[key]] || [];
    acc[cur[key]].push(cur);
    return acc;
  }, []);
};


const IconTextItemStyled = styled(IconTextItem)`
  padding: 1rem;
  border-bottom: 1px solid var(--color-gray);
  margin-bottom: 0;

  .heading{
    display: flex;
    h6{
      flex: 1 0 auto;
    }

    .action{
      cursor: pointer;
      visibility: hidden;
      opacity: 0;
      transition: background-color 0.25s;
      color: var(--color-gray);
      height: 1.25rem;
      width: 1.25rem;
      margin-left: 0.5rem;
    }
  }

  &:hover .action{
    visibility: visible;
    opacity: 1;
  }
`;

const Users = ({ groups, type, reqs, editable }) => {

  // if(!editable && !groups?.[type.value]){
  //   return null;
  // }

  const isRequired = reqs && (reqs.includes(type.value) || reqs.includes(`field_jd_personnel::${type.value}`));

  if (!groups[type.value] && !isRequired) return null;

  let component = null;
  if(type.value === 'pm' && !groups?.[type.value]){
    component = <AddProjectManager />;
  }
  else{
    component = <Personnel key={type.value} personnelType={type} />;
  }

  return (
    <IconTextItemStyled icon={<IoIosPerson />}>
      <div className="heading">
        <Typography
          variant="subtitle2"
          style={{ textTransform: "uppercase" }}
          color="textSecondary"
        >
          {type.name}
        </Typography>
        {(editable && groups?.[type.value]) && (
          React.cloneElement(component, {
            children: (
              <FaUserEdit className="action" />
            ),
          })
        )}
      </div>
      {groups?.[type.value] ? (
        groups?.[type.value]?.map((user, i) => (
          <UserItem
            key={`${user.uid}-${i}`}
            user={{
              ...user,
              name: `${user.first_name} ${user.last_name}`,
              field_title_position: user.title_position,
              field_phone: user.phone,
              field_cell: user.cell,
            }}
          />
        ))
      ) : (
        editable ? (
          React.cloneElement(component, {
            children: (
              <Action>
                <MdPersonAddAlt1 />
                <Typography>{`Assign ${type.name}`}</Typography>
              </Action>
            ),
          })
        ) : (
          <Typography variant="body2">Not Assigned</Typography>
        )
      )}
    </IconTextItemStyled>
  );
};

const Styles = styled.div`
  .user {
    display: flex;
    position: relative;
    margin-bottom: 1.25rem;
  }

  .user-confirmation {
    position: absolute;
    right: 0;
    top: 0;
    color: var(--color-blue);

    & > button {
      margin-right: 0.5rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .user-details {
    margin-left: 1rem;
  }

  .actions {
    margin-top: 2rem;
  }
`;

const Teams = ({ groups, crewAssignedOnly }) => {
  const _groups = groupBy(groups, "type");

  const division = useSelector(getDivisionDataSelector);
  let personnel_fields = null;
  if(division?._processed?.milestone_requirements){
    const req = first(toArray(division?._processed?.milestone_requirements));
    if(req?.personnel_fields){
      personnel_fields = req.personnel_fields;
    }
  }
  const editable = (division?._processed?.job_access_type === 'admin' || division?._processed?.job_access_type === 'member');

  return (
    <Card>
      <CardHeader
        title="Personnel"
        rightActions={
          editable &&
          <Personnel>
            <IconButton>
              <AiOutlineUserAdd />
            </IconButton>
          </Personnel>
        }
      />
      <CardContent className="!p-0">
        <Styles>
          {personnelTypes.map((type) => (
            <Users groups={_groups} type={type} key={type.value} reqs={personnel_fields} editable={editable} />
          ))}
          <Crew assignedOnly={crewAssignedOnly} editable={editable}/>
        </Styles>
      </CardContent>
    </Card>
  );
};

Teams.propTypes = {};

export default Teams;
