import React, { useMemo, useCallback } from "react";
import Sticky from "react-sticky-el";
import moment from "moment";
import styled from "styled-components";
import constants from "components/constants";

import { CommentGroup } from "@phx/common/Comment";

export const DayMarker = styled.div`
  padding: 1.25rem 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${constants.breakpoints.md}) {
    padding: 0.25rem 0;
  }
`;

export const Day = styled.div`
  position: relative;
  background-color: var(--color-gray-background);
  color: var(--color-gray-dark);
  padding: 0.25rem 0.75rem;
  border-radius: 16px;
  transform: translateY(-3px);
  border: 1px solid var(--color-gray);
`;

const CommentBlock = ({
  comments,
  date,
  onDelete,
  onPatch,
  allowEditVisibility,
  client,
}) => {
  const groupCommentsByUser = useCallback((comments) => {
    const sortedComments = [...comments].sort((a, b) => {
      return Number(a.created) - Number(b.created);
    });

    return sortedComments.reduce((acc, value) => {
      let isSame = false;
      if (acc.length){
        const sameUser = Number(acc[acc.length - 1][0].uid) && Number(acc[acc.length - 1][0].uid) === Number(value.uid);
        const sameOwnership = !Number(acc[acc.length - 1][0].uid) && Number(acc[acc.length - 1][0].is_owner) === Number(value.is_owner);
        const sameName = !Number(acc[acc.length - 1][0].uid) && acc[acc.length - 1][0].first_name === value.first_name
                                                              && acc[acc.length - 1][0].last_name === value.last_name;
        if(sameUser || (sameOwnership && sameName)){
          isSame = true;
        }
      }

      if(isSame){
        acc[acc.length - 1].push(value);
      } else {
        acc.push([value]);
      }

      // if (
      //   acc.length &&
      //   ((Number(acc[acc.length - 1][0].uid) && Number(acc[acc.length - 1][0].uid) === Number(value.uid))
      //     || (!Number(acc[acc.length - 1][0].uid)
      //         && (Number(acc[acc.length - 1][0].is_owner) === Number(value.is_owner)
      //          || (acc[acc.length - 1][0].first_name === value.first_name
      //               && acc[acc.length - 1][0].last_name === value.last_name)
      //         )
      //       )
      //   && moment
      //     .unix(acc[acc.length - 1][0].created)
      //     .isSame(moment.unix(value.created), "hour")
      // ) {
      //   acc[acc.length - 1].push(value);
      // } else {
      //   acc.push([value]);
      // }

      return acc;
    }, []);
  }, []);

  const dateToFromNowDaily = useCallback((myDate) => {
    const fromNow = moment(myDate).fromNow();

    const today = moment();
    const incomingDate = moment(myDate);
    const diffDays = today.diff(incomingDate, "days");
    const yearStart = today.startOf("year");
    const isPrevYear = incomingDate.isBefore(yearStart);

    if (isPrevYear) {
      return moment(myDate).format("ddd, MMM Do YYYY");
    } else if (diffDays >= 1) {
      return moment(myDate).format("dddd, MMM Do");
    } else {
      return moment(myDate).calendar(null, {
        lastWeek: "[Last] dddd",
        lastDay: "[Yesterday]",
        sameDay: "[Today]",
        nextDay: "[Tomorrow]",
        nextWeek: "dddd",

        sameElse: function () {
          return "[" + fromNow + "]";
        },
      });
    }
  }, []);

  const groupedComments = useMemo(
    () => groupCommentsByUser(comments),
    [comments, groupCommentsByUser]
  );

  return (
    <div className={`block-${date}`}>
      <Sticky
        scrollElement=".scrollarea"
        boundaryElement={`.block-${date}`}
        stickyStyle={{ zIndex: 2 }}
      >
        <DayMarker>
          <Day>{dateToFromNowDaily(date)}</Day>
        </DayMarker>
      </Sticky>
      {groupedComments.map((group, i) => (
        <CommentGroup
          comments={group}
          key={i}
          onDelete={onDelete}
          onPatch={onPatch}
          allowEditVisibility={allowEditVisibility}
          client={client}
        />
      ))}
    </div>
  );
};

CommentBlock.propTypes = {};

export default CommentBlock;
