import React from "react";
import propTypes from "prop-types";
import { useSelector } from "react-redux";
import moment from "moment";
import { Tooltip, Typography } from "@mui/material";
import { BsInfoCircle } from "react-icons/bs";

import Avatar from "common/Avatar";
import {
  Wrapper,
  Container,
  Content,
  AvatarContainer,
  Header,
  Error,
} from "./Styled";

import { commentsSelectors } from "../../features/Comments/commentsSlice";

const { selectById } = commentsSelectors;

const Comment = ({ cid, invertColor, side, fullWidth }) => {
  const comment = useSelector((state) => selectById(state, cid));
  const currentUser = useSelector((state) => state.auth.user.data);

  const owner = side
    ? side
    : Number(comment?.uid) === Number(currentUser?.uid)
    ? "right"
    : "left";
  return (
    <Wrapper side={owner}>
      <Container side={owner} invertColor={invertColor} fullWidth={fullWidth}>
        {owner === "left" && (
          <AvatarContainer side={owner}>
            <Avatar
              title={`${comment.first_name} ${comment.last_name}`}
              link={"/"}
              size="small"
            />
          </AvatarContainer>
        )}
        <Content side={owner}>
          {comment.error && (
            <Error>
              <Tooltip title={comment.error}>
                <div>
                  <BsInfoCircle />
                </div>
              </Tooltip>
              <Typography color="textSecondary">
                There was an error adding this comment.
              </Typography>
            </Error>
          )}
          <Header side={owner}>
            <Typography
              style={{ cursor: "pointer" }}
            >{`${comment.first_name} ${comment.last_name}`}</Typography>
            <Typography>
              {moment.unix(comment.created).format("h:mm A")}
            </Typography>
          </Header>
          <div
            style={{ width: "100%", padding: "0.25rem 0" }}
            dangerouslySetInnerHTML={{ __html: comment.comment }}
          ></div>
        </Content>
        {owner === "right" && (
          <AvatarContainer side={owner}>
            <Avatar
              title={`${comment.first_name} ${comment.last_name}`}
              link={"/"}
              size="small"
            />
          </AvatarContainer>
        )}
      </Container>
    </Wrapper>
  );
};

const { oneOf } = propTypes;
Comment.propTypes = {
  side: oneOf(["left", "right"]),
};

export default Comment;
