import React, { useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import moment from "moment";

import { IsNew, CellSubheading } from "components/Table/Styled";
import LinkHeader from "common/LinkHeader";
import Card, { CardHeader } from "common/Card";
import Status from "components/Status";
import { MuiTable } from "common/Table";
import TimeDisplayCell from "components/TimeDisplayCell";

import {
  divisionsSelectors,
  fetchLocationJobs,
  getDivisionsLoadingSelector,
  getDivisionsPaginationSelector,
} from "features/Divisions/divisionsSlice";

const JobStatus = ({ machine_name, name }) => {
  return (
    <Status type={machine_name} indicator>
      {name}
    </Status>
  );
};

const WeeksJobs = ({ nid }) => {
  const dispatch = useDispatch();
  const clientNid = useSelector((state) => state.auth.current_client);
  const divisions = useSelector(divisionsSelectors.selectAll);
  const loading = useSelector(getDivisionsLoadingSelector);
  const pagination = useSelector(getDivisionsPaginationSelector);

  useEffect(() => {
    const now = moment();
    const monday = now.clone().weekday(1).format("YYYY-MM-DD");
    const friday = now.clone().weekday(5).format("YYYY-MM-DD");

    let params = {
      "filter[created][0][DATE BETWEEN][0]": monday,
      "filter[created][0][DATE BETWEEN][1]": friday,
    };

    if (clientNid) {
      params["filter[phx_client_nid]"] = clientNid;
    }

    if(nid){
      const promise = dispatch(fetchLocationJobs({ id: nid, params }));

      return () => {
        promise.abort();
      };
    }
  }, [dispatch, nid, clientNid]);

  const columns = useMemo(
    () => [
      {
        id: "changed",
        label: (
          <Tooltip title="Last Updated">
            <IsNew isNew={false}></IsNew>
          </Tooltip>
        ),
        width: 5,
        hiddenProps: {
          only: ["xxs"],
        },
      },
      {
        id: "title",
        label: "Number",
        minWidth: 200,
        width: 200,
      },
      {
        id: "created",
        label: "Created",
        minWidth: 200,
        width: 200,
        hiddenProps: {
          only: ["xxs"],
        },
      },
      {
        id: "customer_name",
        label: "Customer",
        minWidth: 300,
        hiddenProps: {
          only: ["xxs"],
        },
      },
      {
        id: "status",
        label: "Status",
        minWidth: 250,
        hiddenProps: {
          only: ["xxs"],
        },
      },
    ],
    []
  );

  const data = useMemo(
    () =>
      divisions.map((division) => {
        return {
          changed: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <IsNew isNew={Number(division.is_new) === 1}>
                <div hidden>{division.changed}</div>
              </IsNew>
              <TimeDisplayCell time={division.changed} style={{ marginLeft: "1rem" }} />
            </div>
          ),
          title: (
            <div>
              <LinkHeader
                to={division._paths.division}
                size="medium"
                color="primary"
              >
                {division.title}
              </LinkHeader>
              <CellSubheading>{division.job_division_type}</CellSubheading>
            </div>
          ),
          created: <TimeDisplayCell time={division.created} />,
          customer_name: (
            <div>
              <div>
                <LinkHeader
                  to={division._paths.customer}
                  size="medium"
                  noWrap
                  width={300}
                >
                  {division.customer_name}
                </LinkHeader>
              </div>
              <LinkHeader
                to={division._paths.customer_location}
                size="small"
                color="secondary"
                noWrap
                width={300}
              >
                {division.customer_location_name}
              </LinkHeader>
            </div>
          ),
          status: (
            <JobStatus
              machine_name={division.status}
              name={division.status_proper}
            />
          ),
        };
      }),
    [divisions]
  );

  return (
    <Card>
      <CardHeader title="This weeks jobs" />
      <MuiTable
        rows={data}
        columns={columns}
        loading={loading}
        size="medium"
        stickyHeader
        flex
        pagination={pagination}
        // sort={sort}
        // setSort={setSort}
        // order={order}
        // setOrder={setOrder}
        // handleChangePage={(e, newPage) => setPage(newPage)}
      />
    </Card>
  );
};

WeeksJobs.propTypes = {};

export default WeeksJobs;
