import styled from "styled-components";
import constants from "../constants";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: ${({ flexEnd }) => (flexEnd ? "flex-end" : "flex-start")};
`;

export const Icon = styled.div`
  margin-right: 20px;
  color: ${constants.colorSecondary};

  .MuiSvgIcon-root {
    padding-top: 3px;
    height: 2.25rem;
    width: 2.25rem;
  }
`;

export const Details = styled.div`
  color: ${constants.colorGrayDark};
  flex: 1;
`;

export const Heading = styled.div`
  font-size: 1.125rem;
  font-weight: 600;
`;

export const Subheading = styled.div`
  font-size: 1rem;
  font-weight: 300;
`;

export const ActionsHover = styled.div`
  display: flex;
  min-width: 200px;
  position: absolute;
  right: 50px;
  background: white;
  border-radius: ${constants.borderRadius};
  padding: 0 10px;
  top: 50%;
  transform: translateY(-50%);

  & > div {
    width: 250px;
    margin-left: 0.875rem;

    &:first-child {
      margin-left: 0;
    }
  }
`;

export const Actions = styled.div``;
