import React, { useEffect } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GetApp } from "@mui/icons-material";
import moment from "moment";

import Alert from "common/Alert";
import ReportSearch from "./ReportSearch";
import { CollapsibleTable, TableConfigure } from "../Table";
import LinkHeader from "common/LinkHeader";
import { exportBatch, getReportCustomerNotes, clear } from "../../actions";
import Paper from "../Paper";
import { ActionGroup, Actions } from "./Styled";
import constants from "components/constants";

const useStyles = makeStyles((theme) => ({
  button: {
    background: constants.gradientBlack,
    paddingRight: "10px",
    color: constants.colorWhite,
    "&:hover": {
      background: constants.gradientBlack,
      color: constants.colorWhite,
    },
  },
}));

const columns = [
  { id: "customer_name", label: "Customer Name", minWidth: 300 },
  { id: "created", label: "Created", width: 200 },
  { id: "subject", label: "Subject", width: 200 },
  { id: "note_status", label: "Status", minWidth: 200, width: 200 },
  { id: "note_type", label: "Type", width: 200 },
  { id: "user", label: "User", width: 200 },
];

const createData = (
  customer_name,
  created,
  subject,
  note_status,
  note_type,
  comment,
  first_name,
  last_name,
  paths
) => {
  return {
    customer_name: customer_name ? (
      <LinkHeader to={paths.customer} size="small" color="default">
        {customer_name}
      </LinkHeader>
    ) : null,
    created: (
      <div>
        <Typography variant="body1">
          {moment.unix(created).format("MMM Do, YYYY")}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {moment.unix(created).format("h:mm A")}
        </Typography>
      </div>
    ),
    subject,
    note_status,
    note_type,
    user:
      first_name && last_name && paths.user ? (
        <LinkHeader to={paths.user} size="small" color="default">
          {`${first_name} ${last_name}`}
        </LinkHeader>
      ) : null,
    collapsible: (
      <div>
        <Typography variant="subtitle1">Comment</Typography>
        <Typography variant="body1" style={{ maxWidth: "600px" }}>
          {comment}
        </Typography>
      </div>
    ),
  };
};

const ReportCustomerNotes = ({
  report=[],
  clear,
  pagination={ count: 0, current_page: 0, total_pages: 0 },
  loading,
  error,
  loadCustomerNotes,
  exportReport,
  org,
}) => {
  const classes = useStyles();
  const [hiddenColumns, setHiddenColumns] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");

  useEffect(() => {
    return () => {
      clear();
    };
  }, [clear]);

  const handleExportReport = async () => {
    let filterSettings = {};

    if (org) {
      filterSettings = {
        ...filterSettings,
        "filter[phx_client_nid]": org,
      };
    }
    await exportReport("/rest/report/customer_notes", {
      _export: "csv",
      keywords: searchTerm,
      ...filterSettings,
    });
  };

  const rows = React.useMemo(() => {
    if (loading) return [];
    return report.map((row) =>
      createData(
        row.customer_name,
        row.created,
        row.subject,
        row.note_status,
        row.note_type,
        row.comment,
        row.first_name,
        row.last_name,
        row._paths
      )
    );
  }, [report, loading]);

  const hideColumns = React.useMemo(() => {
    if (loading) return [];
    return hiddenColumns;
  }, [hiddenColumns, loading]);

  return (
    <div>
      <Helmet>
        <title>Customer Notes Report | Helix</title>
        <meta name="description" content="Reports" />
      </Helmet>
      {error && <Alert kind="negative">{error.message}</Alert>}
      <LinkHeader to="/reports" color="default" size="small" back>
        Back to reports
      </LinkHeader>
      <Paper margin="none" style={{ marginTop: "1rem" }}>
        <Actions>
          <Typography variant="h6">Customer Notes</Typography>
          <ActionGroup>
            <TableConfigure
              columns={columns}
              hideColumns={setHiddenColumns}
              currentColumns={hiddenColumns}
            />
            <Button
              className={classes.button}
              size="small"
              startIcon={<GetApp />}
              onClick={handleExportReport}
            >
              Export
            </Button>
          </ActionGroup>
        </Actions>
        <ReportSearch setQuery={setSearchTerm} />
        <CollapsibleTable
          clientFilter={"phx_client_nid"}
          rows={rows}
          columns={columns}
          stickyHeader
          loading={loading}
          pageRequest={loadCustomerNotes}
          hideColumns={hideColumns}
          search={searchTerm}
          originalOrderBy="changed"
          originalOrder="desc"
          pagination={pagination}
          size="small"
        />
      </Paper>
    </div>
  );
};

ReportCustomerNotes.propTypes = {};

const mapStateToProps = (state) => ({
  loading: state.app.report.loading,
  report: state.app.report.data.data,
  pagination: state.app.report.data.pagination,
  error: state.app.report.error,
});

const mapDispatchToProps = (dispatch) => ({
  loadCustomerNotes: (params) => dispatch(getReportCustomerNotes(params)),
  exportReport: (url, params) => dispatch(exportBatch(url, params)),
  clear: () => dispatch(clear("report")),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportCustomerNotes);
