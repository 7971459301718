import styled from "styled-components";
import constants from "../constants";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.25rem;
  width: 100%;
  background: ${({ fillBkg }) => (fillBkg ? constants.colorGrayLight : "none")};
  border-radius: 3px;
  height: ${({ large }) => (large ? "300px" : "auto")};
`;

export const Image = styled.img`
  width: 3.33rem;
  opacity: 0.5;
`;

export const Body = styled.p`
  text-align: center;
  font-size: 1.25rem;
  color: ${constants.colorGrayMedium};
  margin-bottom: 0;
`;
