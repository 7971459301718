import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1800px;
  width: 100%;
  padding: 40px 20px 80px 20px;
`;

export const Container = styled.div`
  padding: 1.25rem;
`;

export const Actions = styled.div`
  padding: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const Cols = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Col = styled.div`
  padding: 0 1rem;
  max-width: 500px;

  &:first-child {
    padding: 0 1rem 0 0;
  }

  &:last-child {
    padding: 0 0 0 1rem;
  }
`;

export const ActionGroup = styled.div`
  & > .MuiButtonBase-root {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const FilterMenu = styled.div`
  width: 300px;
  padding: 1.25rem;

  & .MuiInputAdornment-root {
    width: 25px;
  }

  & .MuiInputAdornment-positionEnd {
    margin-left: 0;
  }
`;

export const ButtonGroup = styled.div`
  padding-top: 1rem;

  & > .MuiButton-root {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;
