import { batchActions } from "../actions/batch";

const initialState = {
  batchProgress: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case batchActions.BATCH_START:
      return {
        ...state,
        batchProgress: {
          ...state.batchProgress,
        },
      };
    case batchActions.BATCH_OK:
      return {
        ...state,
        batchProgress: {
          ...state.batchProgress,
          [`${action.payload.batch_id}`]: {
            id: action.payload.batch_id,
            url: action.payload.batch_url,
            title: action.payload.title
              ? action.payload.title
              : state.batchProgress[action.payload.batch_id]?.title
              ? state.batchProgress[action.payload.batch_id]?.title
              : "Export",
            percentage: action.payload.percentage,
            fid: action.payload.fid,
            file: action.payload.file_url,
            filename: action.payload.filename,
            download_url: action.payload.download_url,
            complete: action.payload?.complete ? action.payload.complete : false,
          },
        },
      };
    case batchActions.BATCH_ERROR:
      return {
        ...state,
        batchProgress: {
          ...state.batchProgress,
          [action.payload.batch_id]: {
            ...state.batchProgress[action.payload.batch_id],
            id: action.payload.batch_id,
            error: action.payload.error,
          },
        },
      };
    case batchActions.BATCH_CLEAR:
      delete state.batchProgress[action.id];

      return {
        ...state,
        batchProgress: {
          ...state.batchProgress,
        },
      };
    default:
      return state;
  }
};

export default reducer;
