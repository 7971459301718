import React from "react";
import { Tooltip } from "@mui/material";

import buttonStyles from "../../../css/button.module.css";

const LinkSelect = ({ store, onClick }) => {
  return (
    <Tooltip title="Link">
      <div className={buttonStyles.buttonWrapper}>
        <button onClick={onClick} className={buttonStyles.button}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.7753 2.75068C13.907 1.65773 15.4226 1.05296 16.9958 1.06663C18.5689 1.0803 20.0738 1.71132 21.1863 2.82377C22.2987 3.93621 22.9297 5.44109 22.9434 7.01427C22.9571 8.58745 22.3523 10.1031 21.2593 11.2347L21.2472 11.2471L18.2473 14.2469C17.639 14.8554 16.907 15.326 16.1009 15.6267C15.2948 15.9274 14.4334 16.0512 13.5753 15.9897C12.7171 15.9283 11.8822 15.683 11.1272 15.2705C10.3721 14.858 9.71464 14.2879 9.19929 13.5989C8.86849 13.1567 8.95883 12.53 9.40108 12.1992C9.84333 11.8684 10.47 11.9587 10.8008 12.401C11.1444 12.8603 11.5827 13.2403 12.0861 13.5153C12.5894 13.7903 13.146 13.9539 13.7181 13.9949C14.2903 14.0358 14.8645 13.9533 15.4019 13.7528C15.9393 13.5523 16.4273 13.2386 16.8328 12.833L16.833 12.8329L19.8265 9.83934C20.5515 9.08557 20.9526 8.0777 20.9435 7.03165C20.9344 5.98286 20.5137 4.97961 19.772 4.23798C19.0304 3.49635 18.0272 3.07567 16.9784 3.06656C15.9319 3.05746 14.9236 3.45887 14.1697 4.1845L12.4551 5.88913C12.0634 6.27851 11.4303 6.27667 11.0409 5.88501C10.6515 5.49335 10.6534 4.86018 11.045 4.4708L12.765 2.7608L12.7753 2.75068Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.89935 8.37351C8.70546 8.07281 9.56681 7.94899 10.425 8.01045C11.2832 8.07191 12.1181 8.31721 12.8731 8.72972C13.6281 9.14223 14.2856 9.71229 14.801 10.4012C15.1318 10.8435 15.0414 11.4702 14.5992 11.801C14.1569 12.1318 13.5302 12.0415 13.1994 11.5992C12.8559 11.1399 12.4175 10.7599 11.9142 10.4849C11.4108 10.2098 10.8542 10.0463 10.2821 10.0053C9.71 9.96436 9.13577 10.0469 8.59836 10.2474C8.06095 10.4478 7.57294 10.7615 7.16743 11.1672L4.17379 14.1608C3.44875 14.9146 3.0477 15.9225 3.05679 16.9685C3.06591 18.0173 3.48658 19.0206 4.22821 19.7622C4.96985 20.5038 5.9731 20.9245 7.02188 20.9336C8.06793 20.9427 9.0758 20.5417 9.82957 19.8166L11.5331 18.1131C11.9236 17.7226 12.5568 17.7226 12.9473 18.1131C13.3378 18.5036 13.3378 19.1368 12.9473 19.5273L11.2373 21.2373L11.2249 21.2495C10.0933 22.3425 8.57768 22.9472 7.0045 22.9336C5.43132 22.9199 3.92645 22.2889 2.814 21.1764C1.70155 20.064 1.07054 18.5591 1.05687 16.9859C1.0432 15.4127 1.64796 13.8971 2.74091 12.7655L2.75309 12.7531L5.75296 9.75325C5.75301 9.7532 5.75292 9.75329 5.75296 9.75325C6.3612 9.14482 7.0933 8.67419 7.89935 8.37351Z"
              fill="black"
            />
          </svg>
        </button>
      </div>
    </Tooltip>
  );
};

LinkSelect.propTypes = {};

export default LinkSelect;
