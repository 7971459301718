import React from "react";
import { KeyBindingUtil } from "draft-js";
import linkifyIt from "linkify-it";
import tlds from "tlds";

import LinkSelect from "./components/LinkSelect";
import LinkForm from "./components/LinkForm";

const linkify = linkifyIt();
linkify.tlds(tlds);

export const linkStrategy = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
};

export const Link = ({ contentState, entityKey, children }) => {
  const { url } = contentState.getEntity(entityKey).getData();
  const links = linkify.match(url);
  const href = links && links[0] ? links[0].url : "";

  return (
    <a
      className="link"
      href={href}
      rel="noopener noreferrer"
      target="_blank"
      aria-label={href}
    >
      {children}
    </a>
  );
};

const store = {
  getEditorState: undefined,
  setEditorState: undefined,
};

const addLinkPluginPlugin = ({ formOpenFunction }) => {
  const DecoratedLinkSelect = (props) => (
    <LinkSelect {...props} store={store} />
  );

  const DecoratedLinkForm = (props) => <LinkForm {...props} store={store} />;

  return {
    initialize: ({ getEditorState, setEditorState }) => {
      store.getEditorState = getEditorState;
      store.setEditorState = setEditorState;
    },
    keyBindingFn(e, { getEditorState }) {
      const editorState = getEditorState();
      const selection = editorState.getSelection();

      if (
        !selection.isCollapsed() &&
        KeyBindingUtil.hasCommandModifier(e) &&
        e.which === 75
      ) {
        return "add-link";
      } else if (e.keyCode === 13 && e.shiftKey) {
        return "split-block";
      } else if (e.keyCode === 13) {
        return "myeditor-save";
      }
    },
    handleKeyCommand(
      command,
      editorState,
      eventTimeStamp,
      { getEditorState, setEditorState }
    ) {
      if (command !== "add-link") {
        return "not-handled";
      }
      formOpenFunction(true);
      return "handled";
    },

    LinkSelect: DecoratedLinkSelect,

    LinkForm: DecoratedLinkForm,

    decorators: [
      {
        strategy: linkStrategy,
        component: Link,
      },
    ],
  };
};

export default addLinkPluginPlugin;
