import React from "react";
import PropTypes from "prop-types";

import { Wrapper, LinkTag, ATag, Text, Icon } from "./Styled";

const sizes = {
  small: {
    fontSize: "0.75em",
    marginLeft: "0.125em",
    width: "0.75em",
    height: "0.75em",
  },
  medium: {
    fontSize: "1em",
    marginLeft: "0.25em",
    width: "1em",
    height: "1em",
  },
  large: {
    fontSize: "1.125em",
    marginLeft: "0.5em",
    width: "1.125em",
    height: "1.125em",
  },
};

const colors = {
  white: "white",
  primary: "var(--color-primary)",
  secondary: "var(--color-secondary)",
  default: "var(--color-black)",
};

const LinkHeader = ({
  to,
  children,
  color="default",
  size="large",
  noWrap=false,
  width,
  uppercase=false,
  back=false,
  disabled,
  target,
  onClick,
  ...rest
}) => {
  const output = (
    <>
    {back && <Icon sizes={sizes[size]} theme={{ reverse: back }} />}
    <Text
      sizes={sizes[size]}
      style={{ maxWidth: width }}
      noWrap={noWrap}
      uppercase={uppercase}
    >
      {children}
    </Text>
    {!back && <Icon sizes={sizes[size]} />}
    </>
  );

  return (
    <Wrapper {...rest}>
      {(disabled || !to || (to.indexOf("http://") === -1 && to.indexOf("https://") === -1)) ? (
        <LinkTag
          to={disabled ? null : (to ? to : "/")}
          onClick={onClick ? onClick : null}
          color={colors[color]}
          sizes={sizes[size]}
          theme={{ reverse: back }}
          style={{ pointerEvents: disabled ? "none" : "all" }}
          target={ target ? target : "_self" }
        >
          {output}
        </LinkTag>
      ) : (
        <ATag
          href={to}
          onClick={onClick ? onClick : null}
          color={colors[color]}
          sizes={sizes[size]}
          theme={{ reverse: back }}
          style={{ pointerEvents: disabled ? "none" : "all" }}
          target={ target ? target : "_self" }
        >
          {output}
        </ATag>
      )}
    </Wrapper>
  );
};

const { string, oneOf, bool } = PropTypes;
LinkHeader.propTypes = {
  to: string,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  size: oneOf(["small", "medium", "large"]),
  color: oneOf(["white", "primary", "secondary", "default"]),
  noWrap: bool,
  uppercase: bool,
  reverse: bool,
};

export default LinkHeader;
