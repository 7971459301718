import React, { useEffect, useRef } from "react";
import { Field } from "formik";
import SignatureCanvas from "react-signature-canvas";
import { Button, FormHelperText, Typography } from "@mui/material";

const sigIcon = `${process.env.PUBLIC_URL}/images/icons/icon-signature.svg`;

const SignatureFormField = ({ name, label, helperText, value }) => {
  const sigRef = useRef();

  useEffect(() => {
    if (sigRef.current && value) {
      sigRef.current.fromDataURL(value);
    }
  }, [sigRef]); // eslint-disable-line

  return (
    <Field name={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form,
        meta,
      }) => {
        const clearSignature = () => {
          if (sigRef.current) {
            sigRef.current.clear();
            form.setFieldValue(name, null);
          }
        };

        const convertToDataURL = () => {
          if (sigRef.current) {
            const url = sigRef.current.toDataURL();
            form.setFieldValue(name, url);
          }
        };

        const errorText = meta.error && meta.touched ? meta.error : "";
        return (
          <div className="signature-form-field-wrapper">
            {label && (
              <Typography style={{ marginTop: "1rem" }} variant="body1">
                {label}
              </Typography>
            )}
            <div style={{ position: "relative", margin: "1rem 0" }}>
              <SignatureCanvas
                penColor="#000000"
                canvasProps={{
                  style: {
                    border: `1px solid var(--color-gray)`,
                    width: "100%",
                    height: "150px",
                    display: "block",
                    borderRadius: 4,
                  },
                }}
                ref={sigRef}
                onEnd={convertToDataURL}
              />
              <div
                style={{
                  borderBottom: `1px solid var(--color-gray-medium)`,
                  left: 20,
                  right: 20,
                  bottom: 40,
                  position: "absolute",
                  pointerEvents: "none",
                }}
              >
                <img alt="signature" src={sigIcon} />
              </div>
              {!!errorText && (
                <FormHelperText error={!!errorText}>{errorText}</FormHelperText>
              )}
              {!!helperText && !errorText && (
                <FormHelperText>{helperText}</FormHelperText>
              )}
            </div>
            <Button onClick={clearSignature} disabled={!Boolean(field.value)}>
              Clear
            </Button>
          </div>
        );
      }}
    </Field>
  );
};

SignatureFormField.propTypes = {};

export default SignatureFormField;
