import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { first } from "lodash";
import { Grid, Typography, /*Collapse,*/ InputAdornment } from "@mui/material";
// import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
// import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import constants from "components/constants";

import { TextFormField } from "common/Fields";
import { CheckboxFormField } from "common/Fields";

import {
  defaultTMTermsSelectors,
} from "components/TMTerms/TMTermsSlice";
import {
  formatEquipmentDiscountLine,
  getExcludedLaborTypes,
} from "components/TMTerms/utils";
import TMTermsMarkupComponent from "./TMTermsMarkupComponent";

const tmTypeStyle = {
  subHeading: {paddingBottom: 0},
  subBody: {borderBottom: '1px solid ' + constants.colorGray, padding: '0.75rem', marginBottom: '0.5rem'},
  input: {paddingTop: 0, paddingBottom: 0, paddingRight: '0.5rem',},
};

let styles = {
  tmTerms: {
    heading: {paddingBottom: 0},
    subHeading: {paddingBottom: 0},
    subBody: {border: '1px solid ' + constants.colorGray, borderRadius: constants.borderRadius, padding: '0.75rem',},
    noPadding: {padding: '0'},
    subScript: {padding: 0, paddingRight: '0.5rem', textAlign: 'right',},
    input: {paddingTop: 0, paddingBottom: 0, paddingRight: '0.5rem',},
    subCheckBoxes: {display: 'flex', flexWrap: 'wrap', paddingLeft: '1rem',},
    subCheckBox: {
      flex: '1 0 33%',
      label: {fontSize: '0.9rem'},
    },
    laborTermsWrapper: { textAlign: 'center'},
    laborTermItem: { textAlign: 'left',  padding: '0.25rem', alignSelf: 'start'},
    equipment: tmTypeStyle,
    labor: tmTypeStyle,
  }
};

const laborTermTypes = {
  after_hours:'After Hours',
  overtime:'Overtime',
  travel:'Travel Time',
  per_diem:'Per Diem',
  ppe:'PPE',
  small_tools_charge:'Small Tools Charge',
  lodging:'Lodging',
  markup:'Markup',
};

const TMTermsFormComponent = ({
  isSubmitting,
  setFieldValue,
  existingTerms,
  resourceCategories,
  laborTypes,
  smallDialog,
  // isDivisionEdit,
}) => {
  const defaultTerms = first(useSelector(defaultTMTermsSelectors.selectAll));
  const [tmTermValues, setTMTermValues] = useState((existingTerms ? existingTerms : {}));
  const [existingTermValues, setExistingTermValues] = useState((existingTerms ? existingTerms : {}));
  const [existingResourceCategories, setExistingResourceCategories] = useState((resourceCategories ? resourceCategories : []));
  const [selectedResourceCategories, setSelectedResourceCategories] = useState(
    (existingTerms?.equipment?.resource_categories ? existingTerms?.equipment?.resource_categories :
      (resourceCategories ? resourceCategories.map((obj) => { return obj.value }) : [])
    )
  );
  const checkedDefault = (existingTerms
    ? {
      weekly: Boolean(existingTerms?.equipment?.weekly),
      monthly: Boolean(existingTerms?.equipment?.monthly),
      equipment_markup: Boolean(existingTerms?.equipment?.markup),
      after_hours: Boolean(existingTerms?.labor?.after_hours),
      overtime: Boolean(existingTerms?.labor?.overtime),
      travel: Boolean(existingTerms?.labor?.travel),
      per_diem: Boolean(existingTerms?.labor?.per_diem),
      ppe: Boolean(existingTerms?.labor?.ppe),
      small_tools_charge: Boolean(existingTerms?.labor?.small_tools_charge),
      lodging: Boolean(existingTerms?.labor?.lodging),
      labor_markup: Boolean(existingTerms?.labor?.markup),
      tc_photo: Boolean(existingTerms?.labor?.tc_photo),
      tc_geo: Boolean(existingTerms?.labor?.tc_geo),
    }
    : {});
  const [checked, setChecked] = useState(checkedDefault);
  const [laborTerms, setLaborTerms] = useState((existingTerms && existingTerms?.labor ? existingTerms.labor : {}));
  if(existingTerms && JSON.stringify(existingTerms) !== JSON.stringify(existingTermValues)){
    setExistingTermValues({...existingTerms});
    setTMTermValues({...existingTerms});
    setChecked(checkedDefault);
    setLaborTerms((existingTerms?.labor ? {...existingTerms.labor} : {}));
  }
  if(resourceCategories && JSON.stringify(resourceCategories) !== JSON.stringify(existingResourceCategories)){
    setExistingResourceCategories([...resourceCategories]);
    setSelectedResourceCategories(resourceCategories.map((obj) => { return obj.value }));
  }


  const parseLaborTypeExcludes = ((laborTypes, existingLaborTerms) => {
    let selectedExcludes = {};
    if(laborTypes ){
      for(let laborTypeIndex in laborTypes){
        if(existingLaborTerms){
          Object.keys(existingLaborTerms).forEach((laborTermType) => {
            if(existingLaborTerms[laborTermType]?.labor_type_excludes && existingLaborTerms[laborTermType].labor_type_excludes.indexOf(Number(laborTypeIndex)) !== -1){
              if(!selectedExcludes?.[laborTypeIndex]){
                selectedExcludes[laborTypeIndex] = {};
              }
              selectedExcludes[laborTypeIndex][laborTermType] = true;
            }
          });
        }
        if(selectedExcludes?.[laborTypeIndex] && Object.keys(selectedExcludes[laborTypeIndex]).length === Object.keys(existingLaborTerms).length){
          selectedExcludes[laborTypeIndex] = {};
        }
      };
    }
    return selectedExcludes;
  });

  const [existingLaborTypeExcludes, setExistingLaborTypeExcludes] = useState((laborTypes ? laborTypes : []));
  const [selectedLaborTypeExcludes, setSelectedLaborTypeExcludes] = useState(parseLaborTypeExcludes(laborTypes, existingTerms?.labor));
  if(laborTypes && JSON.stringify(laborTypes) !== JSON.stringify(existingLaborTypeExcludes)){
    setExistingLaborTypeExcludes([...laborTypes]);
    setSelectedLaborTypeExcludes({});
  }
  // const [showLaborTypeExcludes, setShowLaborTypeExcludes] = React.useState(false);

  const setInputChange = useCallback((value, type, subType, part) => {
    setTMTermValues({
        ...tmTermValues,
        [type]: {
          ...tmTermValues?.[type],
          [subType]: {
            ...tmTermValues?.[type]?.[subType],
            [part]: value
          }
        }
    });
  }, [tmTermValues]);

  useEffect(() => {
  //  Set the state to the default terms once they're ready
    if(!existingTerms && Object.keys(tmTermValues).length === 0 && defaultTerms){
      setTMTermValues(defaultTerms);
    }
    if(!setFieldValue){
      return;
    }

    let isSet = false;
    let tempData = {
      equipment: {resource_categories: []},
      labor: {},
    };
    if(selectedResourceCategories && selectedResourceCategories.length > 0){
      tempData.equipment.resource_categories = [...selectedResourceCategories];
      isSet = true;
    }

    if(checked.weekly){
      tempData.equipment.weekly = {};
      tempData.equipment.weekly.threshold = Number(tmTermValues?.equipment?.weekly?.threshold);
      tempData.equipment.weekly.start = Number(tmTermValues?.equipment?.weekly?.start);
      tempData.equipment.weekly.percent = Number(tmTermValues?.equipment?.weekly?.percent);
      isSet = true;
    }
    if(checked.monthly){
      tempData.equipment.monthly = {};
      tempData.equipment.monthly.threshold = Number(tmTermValues?.equipment?.monthly?.threshold);
      tempData.equipment.monthly.start = Number(tmTermValues?.equipment?.monthly?.start);
      tempData.equipment.monthly.percent = Number(tmTermValues?.equipment?.monthly?.percent);
      isSet = true;
    }

    ['equipment', 'labor'].forEach((muType) => {
      if(checked[`${muType}_markup`]){
        if(tmTermValues[muType]?.markup?.type === 'percent' || (!tmTermValues[muType]?.markup?.type && tmTermValues[muType]?.markup?.percent)){
          if(Number(tmTermValues[muType]?.markup?.percent)){
            tempData[muType].markup = {};
            tempData[muType].markup.percent = Number(tmTermValues[muType]?.markup?.percent);
          }
        }
        else if(Number(tmTermValues[muType]?.markup?.value)){
          tempData[muType].markup = {};
          tempData[muType].markup.value = Number(tmTermValues[muType]?.markup?.value);
        }
      //  See: modules/custom/phoenix_job/sub_modules/phoenix_tm_terms/src/Controller/TMTermsController.php:562  checkMarkupShowInvoice()
        if(tempData[muType]?.markup /*&& tmTermValues[muType]?.markup?.invoice_show*/){
          tempData[muType].markup.invoice_show = true;
          // tempData[muType].markup.invoice_show = Boolean(tmTermValues[muType]?.markup?.invoice_show);
        }
        isSet = true;
      }
    });
    Object.keys(laborTermTypes).forEach((termType) => {
      const checkType = (termType === 'markup' ? 'labor_markup' : termType);
      if(checked[checkType]){
        if(termType !== 'markup'){
          tempData.labor[termType] = {};
          switch(termType){
            case 'after_hours':
            case 'overtime':
            default:
              tempData.labor[termType].multiplier = Number(tmTermValues?.labor?.[termType]?.multiplier);
            break;

            case 'travel':
            case 'per_diem':
            case 'ppe':
            case 'lodging':
              tempData.labor[termType].value = Number(tmTermValues?.labor?.[termType]?.value);
            break;

            case 'small_tools_charge':
              tempData.labor[termType].percent = Number(tmTermValues?.labor?.[termType]?.percent);
            break;
          }
        }
        else if(!tempData.labor[termType]){
          return;
        }
        let excludes = [];
        Object.entries(selectedLaborTypeExcludes).forEach(([laborTypeIndex, termTypes]) => {
          if(Object.keys(termTypes).length === 0 || termTypes?.[termType]){
            excludes.push(Number(laborTypeIndex));
          }
        });
        if(excludes.length > 0){
          tempData.labor[termType].labor_type_excludes = [...excludes];
        }
        isSet = true;
      }
    });
    if(checked.tc_photo){
      tempData.labor.tc_photo = true;
    }
    if(checked.tc_geo){
      tempData.labor.tc_geo = true;
    }

    if(isSet){
      //setFieldValue("field_tm_terms", [{value: JSON.stringify(tempData)}]);
      setFieldValue("field_tm_terms", {...tempData});
    }
    else{
      setFieldValue("field_tm_terms", null);
    }
  }, [
    defaultTerms,
    tmTermValues,
    selectedResourceCategories,
    checked,
    laborTerms,
    setFieldValue,
    existingTerms,
    selectedLaborTypeExcludes,
  ]);

  const colBreak = (laborTypes ? Math.floor(laborTypes.length) / 2 : 0);
  let columnedLaborTypes = {};
  if(laborTypes){
    let inc = 0;
    Object.entries(laborTypes).forEach(([index, obj]) => {
      if(!columnedLaborTypes?.[inc]){
        columnedLaborTypes[inc] = {};
      }
      columnedLaborTypes[inc][index] = obj;
      if(index > 0 && index % colBreak === 0){
        inc++;
      }
    });
  }

  if(existingTerms){
    styles.tmTerms.subHeading = {};
  }

  return (
    <>
    <Grid item xxs={12} style={styles.tmTerms.heading}>
      <Typography variant="subtitle1" >Equipment Discount Terms</Typography>
    </Grid>
    <Grid item xxs={12}>
      <Grid container style={styles.tmTerms.subBody}>
        {resourceCategories && resourceCategories.length > 0 && (
          <Grid item xxs={12}>
            <Typography
              variant="subtitle2"
              color="textSecondary"
            >
              Apply to the Following Equipment Categories
            </Typography>
            <Grid container style={{paddingBottom: '1rem'}}>
            {resourceCategories.map((obj) => (
              <Grid item xxs={12} md={4} key={obj.value}>
                <CheckboxFormField
                  name="resource_categories"
                  style={{...styles.tmTerms.input, alignSelf: "start"}}
                  checked={selectedResourceCategories.indexOf(obj.value) !== -1}
                  onChange={(e) => {
                    if(e.target.checked){
                      setSelectedResourceCategories([...selectedResourceCategories, Number(e.target.value)]);
                    } else {
                      setSelectedResourceCategories(
                        selectedResourceCategories.filter(chase => chase !== Number(e.target.value))
                      )
                    }
                  }}
                  value={obj.value}
                  label={obj.label}
                />
              </Grid>
            ))}
            </Grid>
          </Grid>
        )}
        <Grid item xxs={12} style={styles.tmTerms.subHeading}>
          <Typography
            variant="subtitle2"
            color="textSecondary"
          >
            Weekly
          </Typography>
        </Grid>
        <Grid item xxs={1} style={styles.tmTerms.input}>
          <CheckboxFormField
            name="field_tm_terms.equipment.weekly.toggle"
            checked={checked.weekly}
            value={checked.weekly}
            onChange={() => {
              setChecked({...checked, weekly:!checked.weekly});
            }}
            emptyLabel={true}
          />
        </Grid>
        <Grid item xxs={4} style={styles.tmTerms.input}>
          <TextFormField
            fullWidth
            name="field_tm_terms.equipment.weekly.threshold"
            label="Threshold"
            required={(checked.weekly ? true : false)}
            variant="outlined"
            size="small"
            value={(checked.weekly && tmTermValues?.equipment?.weekly?.threshold ? tmTermValues.equipment.weekly.threshold : '')}
            disabled={isSubmitting || !checked.weekly}
            onChange={(e) => {setInputChange(e.target.value, 'equipment', 'weekly', 'threshold')}}
          />
        </Grid>
        <Grid item xxs={3} style={styles.tmTerms.input}>
          <TextFormField
            fullWidth
            name="field_tm_terms.equipment.weekly.start"
            label="Start"
            required={(checked.weekly ? true : false)}
            variant="outlined"
            size="small"
            value={(checked.weekly && tmTermValues?.equipment?.weekly?.start ? tmTermValues.equipment.weekly.start : '')}
            disabled={isSubmitting || !checked.weekly}
            onChange={(e) => {setInputChange(e.target.value, 'equipment', 'weekly', 'start')}}
          />
        </Grid>
        <Grid item xxs={4} style={styles.tmTerms.input}>
          <TextFormField
            fullWidth
            name="field_tm_terms.equipment.weekly.percent"
            label="Percent Off"
            required={(checked.weekly ? true : false)}
            variant="outlined"
            size="small"
            value={(checked.weekly && tmTermValues?.equipment?.weekly?.percent ? tmTermValues.equipment.weekly.percent : '')}
            disabled={isSubmitting || !checked.weekly}
            onChange={(e) => {setInputChange(e.target.value, 'equipment', 'weekly', 'percent')}}
          />
        </Grid>
        <Grid item xxs={12} style={styles.tmTerms.subScript}>
          <Typography
            variant="body2"
            color="textSecondary"
          >
            &nbsp;{ checked.weekly && tmTermValues?.equipment?.weekly && (
              `(${formatEquipmentDiscountLine(tmTermValues?.equipment?.weekly, true)})`
            )}
          </Typography>
        </Grid>
        <Grid item xxs={12} style={styles.tmTerms.subHeading} >
          <Typography
            variant="subtitle2"
            color="textSecondary"
          >
            Monthly
          </Typography>
        </Grid>
        <Grid item xxs={1} style={styles.tmTerms.input}>
          <CheckboxFormField
            name="field_tm_terms.equipment.monthly.toggle"
            checked={checked.monthly}
            value={checked.monthly}
            onChange={() => {
              setChecked({...checked, monthly:!checked.monthly});
            }}
            emptyLabel={true}
          />
        </Grid>
        <Grid item xxs={4} style={styles.tmTerms.input}>
          <TextFormField
            fullWidth
            name="field_tm_terms.equipment.monthly.threshold"
            label="Threshold"
            required={(checked.monthly ? true : false)}
            variant="outlined"
            size="small"
            value={(checked.monthly && tmTermValues?.equipment?.monthly?.threshold ? tmTermValues.equipment.monthly.threshold : '')}
            disabled={isSubmitting || !checked.monthly}
            onChange={(e) => {setInputChange(e.target.value, 'equipment', 'monthly', 'threshold')}}
          />
        </Grid>
        <Grid item xxs={3} style={styles.tmTerms.input}>
          <TextFormField
            fullWidth
            name="field_tm_terms.equipment.monthly.start"
            label="Start"
            required={(checked.monthly ? true : false)}
            variant="outlined"
            size="small"
            value={(checked.monthly && tmTermValues?.equipment?.monthly?.start ? tmTermValues.equipment.monthly.start : '')}
            disabled={isSubmitting || !checked.monthly}
            onChange={(e) => {setInputChange(e.target.value, 'equipment', 'monthly', 'start')}}
          />
        </Grid>
        <Grid item xxs={4} style={styles.tmTerms.input}>
          <TextFormField
            fullWidth
            name="field_tm_terms.equipment.monthly.percent"
            label="Percent Off"
            required={(checked.monthly ? true : false)}
            variant="outlined"
            size="small"
            value={(checked.monthly && tmTermValues?.equipment?.monthly?.percent ? tmTermValues.equipment.monthly.percent : '')}
            disabled={isSubmitting || !checked.monthly}
            onChange={(e) => {setInputChange(e.target.value, 'equipment', 'monthly', 'percent')}}
          />
        </Grid>
        <Grid item xxs={12} style={styles.tmTerms.subScript}>
          <Typography
            variant="body2"
            color="textSecondary"
          >
            &nbsp;{ checked.monthly && tmTermValues?.equipment?.monthly && (
              `(${formatEquipmentDiscountLine(tmTermValues?.equipment?.monthly, true)})`
            )}
          </Typography>
        </Grid>
        <TMTermsMarkupComponent
          smallDialog={smallDialog}
          tmType="equipment"
          styleProps={styles.tmTerms}
          checked={checked}
          selectedLaborTypeExcludes={selectedLaborTypeExcludes}
          laborTypes={laborTypes}
          setChecked={setChecked}
          setInputChange={setInputChange}
          defaultTerms={defaultTerms}
          values={tmTermValues}
          setTMTermValues={setTMTermValues}
          isSubmitting={isSubmitting}
        />
      </Grid>
      <Grid item xxs={12} style={styles.tmTerms.heading}>
        <Typography variant="subtitle1" >Labor Terms</Typography>
      </Grid>
      <Grid item xxs={12}>
        <Grid container style={{...styles.tmTerms.subBody}}>
          {/* {isDivisionEdit && (
            <Grid item xxs={12} style={styles.tmTerms.subHeading}>
              <CheckboxFormField
                name={`update_existing_ds_items`}
                disabled={isSubmitting}
                label="Update Existing Labor Items?"
              />
            </Grid>
          )} */}
          {laborTypes && laborTypes.length > 0 && (
            <>
            <Grid item xxs={12} style={styles.tmTerms.subHeading}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                style={{margin: "0.5em 0 0.5em"}}
              >
                Labor Exclusions
                {getExcludedLaborTypes(selectedLaborTypeExcludes, laborTypes)}
              </Typography>
            </Grid>
            {/* <Grid item xxs={12} >
              <Typography
                variant="subtitle2"
                color="textSecondary"
                style={{cursor: "pointer"}}
                onClick={() => setShowLaborTypeExcludes(!showLaborTypeExcludes)}
              >
                Labor Exclusions {!showLaborTypeExcludes
                  ? <ArrowDropDownIcon style={{verticalAlign: 'middle'}} />
                  : <ArrowDropUpIcon style={{verticalAlign: 'middle'}} />
                }
                {getExcludedLaborTypes(selectedLaborTypeExcludes, laborTypes)}
              </Typography>
            </Grid> */}
            <Grid item xxs={12}>
              {/* <Collapse in={showLaborTypeExcludes}> */}
                <Grid container style={{paddingBottom: '1rem'}}>
                {Object.entries(columnedLaborTypes).map(([colIndex, indexes]) => (
                  <Grid item xxs={12} md={6} key={`${colIndex}-col-index`} style={{wordBreak: "break-word"}}>
                    {Object.entries(indexes).map(([index, obj]) => (
                      <>
                      <CheckboxFormField
                        key={`${colIndex}-${index}-labor-excludes`}
                        name={`${index}_labor_type_excludes`}
                        wrapperStyle={{display: 'block', marginBottom: "0",}}
                        style={{
                          ...styles.tmTerms.labor.input,
                          alignSelf: "start",
                          paddingRight: "0.06rem",
                          paddingLeft: "0.5rem",
                        }}
                        checked={(selectedLaborTypeExcludes?.[index] ? true : false)}
                        disabled={isSubmitting}
                        onChange={(e) => {
                          if(e.target.checked){
                            if(!selectedLaborTypeExcludes?.[index]){
                              setSelectedLaborTypeExcludes({...selectedLaborTypeExcludes, [Number(e.target.value)]:{}});
                            }
                          } else {
                            let tempSel = {...selectedLaborTypeExcludes};
                            if(tempSel?.[index]){
                              delete tempSel[index];
                            }
                            setSelectedLaborTypeExcludes({...tempSel});
                          }
                        }}
                        value={index}
                        label={obj.name}
                      />
                      {selectedLaborTypeExcludes?.[index] && (
                        <div className="checkboxes" style={{...styles.tmTerms.subCheckBoxes, marginBottom: "1rem",}}>
                        {Object.entries(laborTermTypes).map(([type, label]) => (
                          <CheckboxFormField
                            key={`${colIndex}-${index}-${type}-labor-excludes`}
                            name={`${index}_${type}_labor_type_excludes`}
                            wrapperStyle={{
                              ...styles.tmTerms.subCheckBox,
                              flex: "0 1 auto", margin: "0 0.75rem 0 0",
                            }}
                            style={{
                              ...styles.tmTerms.labor.input,
                              alignSelf: "start",
                              paddingRight: "0.06rem",
                              paddingLeft: "0",
                            }}
                            checked={(selectedLaborTypeExcludes?.[index]?.[type] ? true : false)}
                            disabled={isSubmitting}
                            onChange={(e) => {
                              const [index, type] = e.target.value.split('-');
                              if(e.target.checked){
                                if(!selectedLaborTypeExcludes?.[index]?.[type]){
                                  setSelectedLaborTypeExcludes({
                                    ...selectedLaborTypeExcludes,
                                    [index]:{
                                      ...selectedLaborTypeExcludes[index],
                                      [type]: true,
                                    }
                                  });
                                }
                              } else {
                                let tempSel = {...selectedLaborTypeExcludes};
                                if(tempSel?.[index]?.[type]){
                                  delete tempSel[index][type];
                                }
                                setSelectedLaborTypeExcludes({...tempSel});
                              }
                            }}
                            value={`${index}-${type}`}
                            label={<Typography style={{...styles.tmTerms.subCheckBox.label}}>{label}</Typography>}
                          />
                        ))}
                        </div>
                      )}
                    </>
                  ))}
                  </Grid>
                ))}
                </Grid>
              {/* </Collapse> */}
            </Grid>
            </>
          )}
          <Grid item xxs={12}>
            <Grid container style={{...styles.tmTerms.laborTermsWrapper}}>
            {Object.entries(laborTermTypes)
            .filter(([termType, label]) => termType !== 'markup')
            .map(([termType, label]) => {
              let valueIndex = 'multiplier';
              let subBody = {...styles.tmTerms.labor.subBody, borderBottom: 'none'};
              let inputLabel = "Amount";
              switch(termType){
                case 'after_hours':
                case 'overtime':
                default:
                  inputLabel = "Multiplier";
                break;

                case 'per_diem':
                case 'ppe':
                case 'lodging':
                  valueIndex = 'value';
                break;

                case 'travel':
                  valueIndex = 'value';
                  inputLabel = "Hours";
                break;

                case 'small_tools_charge':
                  valueIndex = 'percent';
                  subBody.borderBottom = 'none';
                  inputLabel = "Percent";
                break;
              }
              return (
                <Grid item xxs={12} md={6} key={`${termType}-controls`} style={{...styles.tmTerms.laborTermItem, alignSelf:"stretch"}}>
                  <div style={{...styles.tmTerms.subBody, paddingTop: 0, paddingBottom: 0, height: "100%"}}>
                    <Grid container>
                      <Grid item xxs={12} style={styles.tmTerms.subHeading}>
                        <Typography
                          variant="subtitle2"
                          color="textSecondary"
                          style={{margin: "0.5em 0 0.5em"}}
                        >
                          {label}
                          {checked?.[termType] && (
                            <Grid item xxs={12}>{getExcludedLaborTypes(selectedLaborTypeExcludes, laborTypes, termType, 'excludes')}</Grid>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xxs={(!smallDialog ? 1 : 2)} style={styles.tmTerms.labor.input}>
                        <CheckboxFormField
                          name={`field_tm_terms.labor.${termType}.toggle`}
                          checked={(checked?.[termType] ? true : false)}
                          value={(checked?.[termType] ? true : false)}
                          onChange={() => {
                            const newChecked = !checked?.[termType];
                            setChecked({...checked, [termType]: newChecked});
                            if(newChecked && defaultTerms?.labor?.[termType]?.[valueIndex]){
                              setInputChange(defaultTerms.labor[termType][valueIndex], 'labor', termType, valueIndex)
                            }
                          }}
                          emptyLabel={true}
                        />
                      </Grid>
                      <Grid item xxs={9} style={styles.tmTerms.labor.input}>
                        <TextFormField
                          fullWidth
                          name={`field_tm_terms.labor.${termType}.${valueIndex}`}
                          label={inputLabel}
                          required={(checked?.[termType] ? true : false)}
                          variant="outlined"
                          size="small"
                          value={(checked?.[termType] &&
                            tmTermValues?.labor?.[termType]?.[valueIndex]
                              ? (valueIndex === 'percent' ? Number(tmTermValues?.labor[termType][valueIndex]) * 100 : tmTermValues?.labor[termType][valueIndex])
                              : ''
                          )}
                          disabled={isSubmitting || !checked?.[termType]}
                          onChange={(e) => {
                            let value = e.target.value;
                            if(valueIndex === 'percent'){
                              value = Number(value) / 100;
                            }
                            setInputChange(value, 'labor', termType, valueIndex)
                          }}
                          startAdornment={
                            (termType !== 'travel' &&
                              (valueIndex === 'value'
                                ? <InputAdornment position="start">$</InputAdornment>
                                : (valueIndex === 'multiplier' ? <InputAdornment position="start">x</InputAdornment> : null)
                              )
                            )
                          }
                          endAdornment={
                            (valueIndex === 'percent' ? <InputAdornment position="end">%</InputAdornment> : null)
                          }
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              )}
            )}

            <TMTermsMarkupComponent
              smallDialog={smallDialog}
              tmType="labor"
              styleProps={styles.tmTerms}
              checked={checked}
              selectedLaborTypeExcludes={selectedLaborTypeExcludes}
              laborTypes={laborTypes}
              setChecked={setChecked}
              setInputChange={setInputChange}
              defaultTerms={defaultTerms}
              values={tmTermValues}
              setTMTermValues={setTMTermValues}
              isSubmitting={isSubmitting}
            />
            <Grid item xxs={12} md={6} style={{...styles.tmTerms.laborTermItem}}>
              <Grid container style={{...styles.tmTerms.subBody, paddingTop: 0, paddingBottom: 0}}>
                <Grid item xxs={12} style={styles.tmTerms.subHeading}>
                  <CheckboxFormField
                    name={`field_tm_terms.labor.tc_photo`}
                    label="Require Photo on Time Cards?"
                    checked={(checked?.tc_photo ? true : false)}
                    value={(checked?.tc_photo ? true : false)}
                    onChange={() => {
                      const newChecked = !checked?.tc_photo;
                      setChecked({...checked, tc_photo: newChecked});
                      if(newChecked && defaultTerms?.labor?.tc_photo){
                        setInputChange(defaultTerms.labor.tc_photo, 'labor', "tc_photo", "toggle")
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xxs={12} md={6} style={{...styles.tmTerms.laborTermItem}}>
              <Grid container style={{...styles.tmTerms.subBody, paddingTop: 0, paddingBottom: 0}}>
                <Grid item xxs={12} style={styles.tmTerms.subHeading}>
                <CheckboxFormField
                  name={`field_tm_terms.labor.tc_geo`}
                  label="Require Geo Location on Time Cards?"
                  checked={(checked?.tc_geo ? true : false)}
                  value={(checked?.tc_geo ? true : false)}
                  onChange={() => {
                    const newChecked = !checked?.tc_geo;
                    setChecked({...checked, tc_geo: newChecked});
                    if(newChecked && defaultTerms?.labor?.tc_geo){
                      setInputChange(defaultTerms.labor.tc_geo, 'labor', "tc_geo", "toggle")
                    }
                  }}
                />
                </Grid>
              </Grid>
            </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </>
  );
};

TMTermsFormComponent.propTypes = {};

export default TMTermsFormComponent;
