import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Information from "features/Location/components/Information";
import {
  fetchLocation,
  getLocationDataSelector,
  getLocationLoadingSelector,
} from "features/Location/locationSlice";
import { DefaultLoader } from "common/Loader";

const LocationSnapshot = ({ nid }) => {
  const dispatch = useDispatch();
  const location = useSelector(getLocationDataSelector);
  const loading = useSelector(getLocationLoadingSelector);

  useEffect(() => {
    const promise = dispatch(fetchLocation(nid));

    return () => {
      promise.abort();
    };
  }, [dispatch, nid]);

  return (
    <div>
      {loading ? <DefaultLoader /> : <Information location={location} />}
    </div>
  );
};

export default LocationSnapshot;
