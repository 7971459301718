import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Typography, MenuItem, ListItemIcon } from "@mui/material";
import { BsFilePost } from "react-icons/bs";

import { jobDivisionsSelectors } from "../jobSlice";

const { selectById } = jobDivisionsSelectors;

const DivisionMenuItem = ({ nid }) => {
  const division = useSelector((state) => selectById(state, nid));

  return (
    <MenuItem component={Link} to={division.path}>
      <ListItemIcon>
        <BsFilePost />
      </ListItemIcon>
      <Typography variant="inherit">{division.title}</Typography>
    </MenuItem>
  );
};

DivisionMenuItem.propTypes = {};

export default DivisionMenuItem;
