import * as Yup from "yup";

export const editDivisionSchema = Yup.object().shape({
  type: Yup.string().required("Required"),
  status: Yup.string().required("Required")
});

export const editScanSchema = Yup.object().shape({
  scan: Yup.string()
    .required("Required")
    .url("Must be a url ex https://www.google.com")
});
