import React, { useEffect } from "react";
import { useSelector, connect } from "react-redux";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { DialogContent, Button } from "@mui/material";

import Dialog from "../Dialog";
import config from "../../config";
import { SelectFormField } from "common/Fields";
import { TextFormField } from "common/Fields";
import ButtonLoader from "common/ButtonLoader";
import Alert from "common/Alert";
import { FormContainer, FormActions } from "./Styled";
import { formatError, formatErrorResponseString } from "../../utility";
import { FormSkeleton } from "../Skeletons";
import { getMemberDataSelector } from "features/Member/memberSlice";
import { clientsSelectors } from "features/Clients/clientsSlice";
import { ClientSelectorField } from "components/ClientSelector/ClientSelectorField";
import {
  prepClientSelectorField,
  prepClientSelectorFieldData,
  validateClientSelectorField,
} from "components/ClientSelector/utils";
import { postMemberComment } from "../../actions/comment";
import { patchMemberComment } from "../../actions";
import {
  getMemberNoteStatus,
  getMemberNoteSubjects,
} from "../../actions/taxonomies";
import { memberNoteTypesSelectors } from "features/Taxonomies/taxonomiesSlice";
import { specialMemberNoteTypes } from "./utils";

const checkIfSubjectTaxonomy = (subjects, comment) => {
  if (!comment) return false;
  let isTaxonomy = false;
  for (let index in subjects) {
    if (subjects[index].name === comment.subject) {
      isTaxonomy = true;
    }
  }

  return isTaxonomy;
};

const MemberNoteAddEdit = ({
  onClose,
  open,
  nid,
  showSnackbar,
  loadMemberNoteStatus,
  loadMemberNoteSubjects,
  statuses,
  loadingStatus,
  subjects,
  loadingSubjects,
  addComment,
  updateComment,
  comment,
  loadNotes,
  typeTid,
}) => {
  const noteTypes = useSelector(memberNoteTypesSelectors.selectAll);
  const loading = loadingStatus || loadingSubjects;
  const [message, setMessage] = React.useState(null);
  const member = useSelector(getMemberDataSelector);
  const clients = useSelector(clientsSelectors.selectAll);

  const typeName = (typeTid && noteTypes ? noteTypes.find(( type ) => type.tid === typeTid)?.name : "");
  const omitExtraInfo = (specialMemberNoteTypes.find(( type ) => type === typeName)?.length > 0);
  const defaultStatus = (omitExtraInfo ? statuses.find(( status ) => status.name === 'Notes')?.tid : '');

  const dropDownNoteTypes = (!omitExtraInfo ? noteTypes.filter(( type ) => !specialMemberNoteTypes.includes(type.name)) : noteTypes);

  useEffect(() => {
    if (open) {
      loadMemberNoteStatus();
      loadMemberNoteSubjects();
    }
  }, [loadMemberNoteStatus, loadMemberNoteSubjects, open]);

  const handleClose = () => {
    setMessage(null);
    onClose();
  };

  const initialValues = {
    subject: (omitExtraInfo ? "(No subject)" : ""),
    "subject-other": "",
    type: (typeTid ? typeTid : ""),
    status: defaultStatus,
    comment: "",
  };

  if(comment){
    initialValues.subject = checkIfSubjectTaxonomy(subjects, comment) && comment
      ? comment.subject
      : "Other";
    initialValues["subject-other"] = comment.subject;
    initialValues.type = comment.note_type_tid;
    initialValues.status = (comment?.note_status_tid ? comment.note_status_tid : 0);
    initialValues.comment = comment.comment;
  }
  const availableClients = prepClientSelectorField(comment, member, clients, initialValues);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xs"
      onClose={handleClose}
      title={`${!comment ? `Add` : `Update`} Note`}
    >
      {loading ? (
        <FormSkeleton />
      ) : (
        <>
          <DialogContent>
            <Formik
              initialValues={initialValues}
              // validationSchema={editDivisionSchema}
              validate={(values, props) => {
                setMessage(null);
                const errors = {};

                validateClientSelectorField(availableClients, values, errors, setMessage);

                return errors;
              }}
              onSubmit={async (data, { setSubmitting, setSuccess }) => {
                setSubmitting(true);

                const params = {
                  _links: {
                    type: {
                      href: `${config.api_url}/rest/type/comment/member_note`,
                    },
                  },
                  entity_id: [
                    {
                      target_id: nid,
                      revision_id: null,
                    },
                  ],
                  entity_type: [{ value: "node" }],
                  field_name: [
                    {
                      value: "field_member_notes",
                    },
                  ],
                  field_member_note_comment: [{ value: data.comment }],
                  field_member_note_type: [{ target_id: data.type }],
                  field_member_note_status: [{ target_id: data.status }],
                };

                if (data.subject === "Other") {
                  params.subject = [{ value: data["subject-other"] }];
                } else {
                  params.subject = [{ value: data.subject }];
                }

                prepClientSelectorFieldData(availableClients, params, data);

                let response = [];
                if(!comment){
                  response = await addComment(params);
                }
                else{
                  response = await updateComment(comment.cid, params);
                }
                if (response.status === 201 || response.status === 200) {
                  await loadNotes();
                  showSnackbar({
                    msg: "Successfully added note.",
                    kind: "positive",
                  });
                  onClose();
                } else {
                  const errorMessage = formatError(response);
                  const newMessage = formatErrorResponseString(
                    errorMessage.msg
                  );
                  errorMessage.msg = newMessage;
                  setMessage(errorMessage);
                }
              }}
            >
              {({ values, errors, isSubmitting, setFieldValue }) => (
                <FormContainer>
                  {message && <Alert kind={message.id}>{message.msg}</Alert>}
                  {!omitExtraInfo && (
                    <>
                    <SelectFormField
                      fullWidth
                      name="subject"
                      label="Subject"
                      margin="normal"
                      required
                      disabled={isSubmitting}
                      options={subjects.map((subject) => ({
                        label: subject.name,
                        value: subject.name,
                      }))}
                    />
                    {values.subject === "Other" && (
                      <TextFormField
                        fullWidth
                        htmlFor="subject-other"
                        name="subject-other"
                        label="Subject Other"
                        margin="normal"
                        required
                        disabled={isSubmitting}
                        labelwidth={70}
                      />
                    )}
                    <SelectFormField
                      fullWidth
                      name="type"
                      label="Communication Type"
                      margin="normal"
                      required
                      disabled={isSubmitting}
                      options={dropDownNoteTypes.map((type) => ({
                        label: type.name,
                        value: type.tid,
                      }))}
                    />
                    <SelectFormField
                      fullWidth
                      name="status"
                      label="Status"
                      margin="normal"
                      required
                      disabled={isSubmitting}
                      options={statuses.map((status) => ({
                        label: status.name,
                        value: status.tid,
                      }))}
                    />
                    </>
                  )}
                  <ClientSelectorField
                    clients={availableClients}
                    disabled={isSubmitting}
                  />
                  <TextFormField
                    fullWidth
                    htmlFor="comment"
                    name="comment"
                    label={!omitExtraInfo ? "Comment" : "Note"}
                    margin="normal"
                    disabled={isSubmitting}
                    labelwidth={70}
                    multiline
                  />
                  <FormActions>
                    <Button size="small" onClick={onClose} component="a">
                      Cancel
                    </Button>
                    <ButtonLoader
                      variant="contained"
                      color="primary"
                      type="submit"
                      isSubmitting={isSubmitting}
                      disableElevation
                      size="small"
                    >
                      {!comment ? `Create` : `Update`}
                    </ButtonLoader>
                  </FormActions>
                </FormContainer>
              )}
            </Formik>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

const { func, bool } = PropTypes;
MemberNoteAddEdit.propTypes = {
  onClose: func,
  open: bool,
};

const mapStateToProps = (state) => ({
  statuses: state.app.memberNoteStatus.data,
  loadingStatus: state.app.memberNoteStatus.loading,
  subjects: state.app.memberNoteSubjects.data,
  loadingSubjects: state.app.memberNoteSubjects.loading,
});

const mapDispatchToProps = (dispatch) => ({
  loadMemberNoteStatus: () => dispatch(getMemberNoteStatus()),
  loadMemberNoteSubjects: () => dispatch(getMemberNoteSubjects()),
  addComment: (params) => dispatch(postMemberComment(params)),
  updateComment: (nid, params) => dispatch(patchMemberComment(nid, params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberNoteAddEdit);
