import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { MenuItem, ListItemIcon, Typography } from "@mui/material";
import { FaUserTimes } from "react-icons/fa";

import ConfirmationDialog from "components/ConfirmationDialog";
import { deleteCrew } from "../crewSlice";

const Delete = ({ crew, handleClose }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleDelete = () => {
    dispatch(deleteCrew({ id: crew.id, crew }));
    setOpen(false);
  };

  const onClose = () => {
    setOpen(false);
    handleClose();
  };

  return (
    <>
      <MenuItem onClick={() => setOpen(true)}>
        <ListItemIcon>
          <FaUserTimes />
        </ListItemIcon>
        <Typography variant="inherit">Remove</Typography>
      </MenuItem>
      <ConfirmationDialog
        open={open}
        onClose={onClose}
        onSubmit={handleDelete}
        title="Are you sure?"
        body="Are you sure you want to delete this item?"
        submitButton="Delete"
      />
    </>
  );
};

Delete.propTypes = {};

export default Delete;
