import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { size, isNull } from "lodash";
import {
  getIsAuthenticatedSelector,
} from "../../features/Auth/authSlice";
import * as PusherPushNotifications from "@pusher/push-notifications-web";
// import * as serviceWorkerRegistration from "assets/pusher-service-worker.js";
// import * as serviceWorkerRegistration from "serviceWorker";
import {  addToast } from "features/Toast/toastSlice";
import conf from "config";
import { simpleBrowserInfo } from "utility";
import moment from "moment";
import { hubEnvironmentSelectors } from "features/Hub/hubSlice.js";

const baseUrl = conf.helix_api_url;

const BrowserNotifications = (  ) => {
  const dispatch = useDispatch();
  const token = useSelector(
    (state) => state.auth.user?.data?._processed?.phx_offsite_api_token
  );
  const isAuthenticated = useSelector(getIsAuthenticatedSelector);
  const helixAPICurrent = useSelector((state) => state.user.helixAPICurrent.data);
  // const clients = useSelector((state) => state.auth.user.data.field_clients);
  const userWSToken = useSelector((state) => state.auth.user.data.field_web_socket_token);
  const webNotificationsEnabled = useSelector((state) => state.auth.user.data?.field_user_settings?.enable_web_notifications);
  const [browserNotificationPrompt, setBrowserNotificationPrompt] = useState(localStorage.getItem("browser_notification_prompt"));
  const envs = useSelector(hubEnvironmentSelectors.selectAll);
  // const [beamConnected, setBeamConnected] = useState(false);

  useEffect(() =>  {
    if(!window.beamsClient){
      window.beamsClient = new PusherPushNotifications.Client({
        instanceId: conf.pusher_beam_key,
        // serviceWorkerRegistration: registration,
      });
      if(window.beamsClient.error){
        console.log(window.beamsClient.error);
        return;
      }
    }

    if (token && isAuthenticated && size(helixAPICurrent) > 0){
      async function _finishFunction() { // a wrapping function so that async works within a useEffect hook
        if(window.beamsClient.error){
          return;
        }
        const browser = simpleBrowserInfo();
        let isFireFox = browser.type === "firefox";
        let isSafari = browser.type === "safari";
      //  02/14/24 - Safari 17.3.1 has a bug where it cannot create an icon with the pushPackage generated at Pusher; so, disabling for that version of Safari.
        let safariErrorVersion = false;
        if(isSafari){
          if(browser.versionParts[0] === 17 && browser.versionParts[1] === 3 && browser.versionParts[1] === 1){
            safariErrorVersion = true;
          }
        }

        const browserPermissionStatus = await window.beamsClient.getRegistrationState();
      //  If already granted notification permission, then just start the connection process
        if(browserPermissionStatus === "PERMISSION_GRANTED_REGISTERED_WITH_BEAMS"
           || browserPermissionStatus === "PERMISSION_GRANTED_NOT_REGISTERED_WITH_BEAMS"){
          handleBeamConnection();
        }
      //  If permission is denied, then do nothing
        else if(browserPermissionStatus !== "PERMISSION_DENIED"){
        //  If user is on Safari and Firefox, prompt for permission, but not if they've already dismissed the prompt
          if((isFireFox || (isSafari && !safariErrorVersion)) && isNull(browserNotificationPrompt)){
            const testBlock = false/*isSafari*/; //  For testing
            if(!testBlock && webNotificationsEnabled) {
              dispatch(
                addToast({
                  show: true,
                  kind: "warning", // "warning" persists message
                  message: {
                    type: 'browser_notifications_prompt',
                    data: {
                      onAnswer: handleAnswer,
                    },
                    grouping_id: 'browser_notifications_prompt',
                  },
                  formatter: "notification",
                })
              );
            }
          }
          else if(!isSafari){
            handleBeamConnection();
          }
        }
      }

      _finishFunction();
    }
  }, [browserNotificationPrompt, token, isAuthenticated, helixAPICurrent, webNotificationsEnabled, userWSToken, envs, /*clients,*/ dispatch]); //  eslint-disable-line react-hooks/exhaustive-deps

  const handleBeamConnection = async (browserPermissionStatus) => {
    try{
      if(webNotificationsEnabled) {
        const uid = String(helixAPICurrent.uid);
        // let deviceIDToken = null;

        const connectBeam = () => {
        //  The Pusher Beam status is unreliable and remains registered after stopping; so, track registration with a local variable.
        //    - Also, refresh the token at laravel once a day.
          let connect = false;
          let userToken = localStorage.getItem("pusher_beam_user_token");
          if(isNull(userToken)){
            userToken = {
              timestamp: moment().format(),
              token: '',
            };
          }
          else{
            userToken = JSON.parse(userToken);
          }
          if(userToken.token !== userWSToken || moment(userToken.timestamp).isBefore(moment().subtract(1, 'day'))){
            connect = true;
            userToken.token = userWSToken;
            userToken.timestamp = moment().format();
          }

        //    - Also, refresh the token if the PHX Client web socket tokens change
          // const clientTokens = localStorage.getItem("pusher_beam_client_tokens");
          const envTokens = localStorage.getItem("pusher_beam_environment_tokens");
          // const wsTokens = JSON.stringify(clients.map((client) => client.field_web_socket_token));
          const wsTokens = JSON.stringify(envs.map((env) => env.wsToken));
          // if(isNull(clientTokens) || clientTokens !== wsTokens){
          if(isNull(envTokens) || envTokens !== wsTokens){
            connect = true;
          }

          if(connect){
            window.beamsClient.start()
              .then(() => window.beamsClient.getDeviceId())
              .then(deviceId => {
                // deviceIDToken = deviceId;
                // localStorage.setItem("pusher_beam_device_id", deviceId);

                const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
                  url: `${baseUrl}/api/notifications/pusher/beams/auth`,
                  queryParams: {
                    user_uid: uid, // URL query params your auth endpoint needs
                    // device_id: deviceId,
                  },
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                  credentials: 'include',
                });
                window.beamsClient.setUserId(uid, beamsTokenProvider);
              })
              .then(e => {
                // window.beamsClient.addDeviceInterest(`notifications.${uid}.${deviceIDToken}`);
                window.beamsClient.addDeviceInterest(`notifications.${uid}.${userToken.token}`);
                // clients.forEach((client) => {
                //   window.beamsClient.addDeviceInterest(`notifications.client.${client.nid}.${client.field_web_socket_token}`);
                // });
                envs.forEach((env) => {
                  window.beamsClient.addDeviceInterest(`notifications.environment.${env.nid}.${env.wsToken}`);
                });
              })
              .then(() => {
                // setBeamConnected(true);
                localStorage.setItem("pusher_beam_user_token", JSON.stringify(userToken));
                // localStorage.setItem("pusher_beam_client_tokens", wsTokens);
                localStorage.setItem("pusher_beam_environment_tokens", wsTokens);
                console.log('Successfully registered and subscribed!');
              })
              .catch(e => console.error('Could not authenticate with Pusher Beam:', e));
          // })
          }
        };

      //  Stop the beams client if the user is not the same
        await window.beamsClient.start()
          .then(() =>
            window.beamsClient.getUserId().then((userId) => {
              // Check if the Beams user matches the user that is currently logged in
              if (userId && userId !== uid) {
              // Unregister for notifications
                localStorage.removeItem("pusher_beam_registered");
                // window.beamsClient.clearAllState();
                window.beamsClient.stop().then(() => {
                  connectBeam();
                  console.log('Disconnected old user from Pusher Beam.')
                });
              }
              else{
                connectBeam();
              }
            })
          );
      }
      else{
      //  If we switched-off the web notifications, then disconnect the beams client (if connected)
        window.beamsClient
          .getUserId()
            .then((userId) => {
              if (userId) {
                logoutPusherBeams();
              }
            });
      }
    }
    catch(e){
      console.error(e);
    }
  }

  const handleAnswer = (answer) => {
    localStorage.setItem("browser_notification_prompt", String(answer));
    setBrowserNotificationPrompt(answer);

    if(answer){
    // Must remain here and not placed inside a hook so that Safari recognizes this as a user gesture
      handleBeamConnection();
    }
  }

  return (<></>);
};

export const logoutPusherBeams = () => {
  if(window.beamsClient){
    // window.beamsClient.clearAllState();
    window.beamsClient.stop().then((e) =>{
      localStorage.removeItem("pusher_beam_registered");
      console.log('Disconnected from Pusher Beam.')
    })
    .catch(e => console.error('Could not disconnect from Beams:', e));
  }
}

BrowserNotifications.propTypes = {};

export default BrowserNotifications;

