import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMember,
  fetchMemberByNid,
  getMemberLoadingSelector,
} from "features/Member/memberSlice";
import Information from "features/Member/components/Information";
import { DefaultLoader } from "common/Loader";

const MemberSnapshot = ({ nid }) => {
  const dispatch = useDispatch();
  const loading = useSelector(getMemberLoadingSelector);

  useEffect(() => {
    const promise = dispatch(fetchMemberByNid(nid));

    return () => {
      promise.abort();
      dispatch(clearMember());
    };
  }, [dispatch, nid]);

  return (
    <div>
      {loading ? <DefaultLoader /> : <Information onNavigate={() => {}} inSnapshot/>}
    </div>
  );
};

export default MemberSnapshot;
