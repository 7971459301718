import React, { useEffect } from "react";
import { compose } from "redux";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Typography, Button, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GetApp } from "@mui/icons-material";
import { FilterList } from "@mui/icons-material";
import { Autocomplete } from "@mui/material";

import { withPermissions } from "../../providers";
import Alert from "common/Alert";
import ReportSearch from "./ReportSearch";
import Table, { TableConfigure } from "../Table";
import LinkHeader from "common/LinkHeader";
import { exportBatch, getReportHpnSelect, clear } from "../../actions";
import Paper from "../Paper";
import { formatFilters } from "../../utility";
import { Actions, ActionGroup, FilterMenu, ButtonGroup } from "./Styled";
import ButtonDropdown from "common/ButtonDropdown";
import constants from "components/constants";

const useStyles = makeStyles((theme) => ({
  button: {
    background: constants.gradientBlack,
    paddingRight: "10px",
    color: constants.colorWhite,
    "&:hover": {
      background: constants.gradientBlack,
      color: constants.colorWhite,
    },
  },
}));

const columnSettings = {
  customer_name: { minWidth: 400 },
  invoice_title: { minWidth: 200 },
  ds_item_line_number: { minWidth: 200 },
  invoice_date: { minWidth: 200 },
  category: { minWidth: 200 },
  description: { minWidth: 200 },
  quantity: { minWidth: 200 },
  entry_type: { minWidth: 200 },
  rate_total: { minWidth: 200 },
  invoice_net: { minWidth: 200 },
  state_tax: { minWidth: 200 },
  other_tax: { minWidth: 200 },
  invoice_gross: { minWidth: 200 },
  customer_location_name: { minWidth: 200 },
  customer_address: { minWidth: 200 },
  division_title: { minWidth: 200 },
  invoice_in_reference_to: { minWidth: 200 },
};

const createColumns = (columns) =>
  columns.map((column) => {
    column.id = column.index;
    column = {
      ...column,
      notSortable: !column.sortable,
      ...columnSettings[column.index],
    };
    return column;
  });

const createData = (
  customer_name,
  invoice_title,
  ds_item_line_number,
  invoice_date,
  category,
  description,
  quantity,
  entry_type,
  rate_total,
  invoice_net,
  state_tax,
  other_tax,
  invoice_gross,
  customer_location_name,
  customer_address,
  division_title,
  invoice_in_reference_to
) => {
  return {
    customer_name,
    invoice_title,
    ds_item_line_number,
    invoice_date,
    category,
    description,
    quantity,
    entry_type,
    rate_total,
    invoice_net,
    state_tax,
    other_tax,
    invoice_gross,
    customer_location_name,
    customer_address,
    division_title,
    invoice_in_reference_to,
  };
};

const initialFormData = {
  status_proper: null,
  category: [],
};

const ReportHpnSelect = ({
  report=[],
  clear,
  pagination={
    count: 0,
    current_page: 0,
    total_pages: 0,
    display_columns: [],
  },
  loading,
  error,
  loadReport,
  exportReport,
  org,
}) => {
  const classes = useStyles();
  const [hiddenColumns, setHiddenColumns] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filters, setFilters] = React.useState({});
  const [formData, setFormData] = React.useState(initialFormData);

  useEffect(() => {
    return () => {
      clear();
    };
  }, [clear]);

  const handleExportReport = async () => {
    let filterSettings = formatFilters(filters);

    if (org) {
      filterSettings = {
        ...filterSettings,
        "filter[phx_client_nid]": org,
      };
    }

    await exportReport("/rest/report/jobs/hpn-select", {
      _export: "csv",
      keywords: searchTerm,
      ...filterSettings,
    });
  };

  const columns = React.useMemo(() => {
    if (loading) return [];
    const temp = createColumns(pagination.display_columns);
    return temp;
  }, [pagination, loading]);

  const rows = React.useMemo(() => {
    if (loading) return [];
    return report.map((row) =>
      createData(
        row.customer_name,
        row.invoice_title,
        row.ds_item_line_number,
        row.invoice_date,
        row.category,
        row.description,
        row.quantity,
        row.entry_type,
        row.rate_total,
        row.invoice_net,
        row.state_tax,
        row.other_tax,
        row.invoice_gross,
        row.customer_location_name,
        row.customer_address,
        row.division_title,
        row.invoice_in_reference_to
      )
    );
  }, [report, loading]);

  const hideColumns = React.useMemo(() => {
    if (loading) return [];
    return hiddenColumns;
  }, [hiddenColumns, loading]);

  const handleChange = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const applyFilters = () => {
    setFilters({ ...formData });
  };

  const resetFilters = () => {
    setFilters({});
    setFormData({
      ...initialFormData,
    });
  };

  return (
    <div>
      <Helmet>
        <title>HPN Select Report | Helix</title>
        <meta name="description" content="Reports" />
      </Helmet>
      {error && <Alert kind="negative">{error.message}</Alert>}
      <LinkHeader to="/reports" color="default" size="small" back>
        Back to reports
      </LinkHeader>
      <Paper margin="none" style={{ marginTop: "1rem" }}>
        <Actions>
          <Typography variant="h6">HPN Select</Typography>
          <ActionGroup>
            <TableConfigure
              columns={columns}
              hideColumns={setHiddenColumns}
              currentColumns={hiddenColumns}
            />
            <ButtonDropdown
              icon={FilterList}
              label="Filters"
              style={{ marginLeft: "1rem" }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              color="tertiary"
            >
              <FilterMenu>
                <Autocomplete
                  options={[
                    {
                      label: "Equipment",
                      value: "ds_equipment",
                    },
                    {
                      label: "Expense",
                      value: "ds_expense",
                    },
                    {
                      label: "Service",
                      value: "ds_service",
                    },
                  ]}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Product"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "category");
                  }}
                  value={formData.category}
                />
                <ButtonGroup>
                  <Button disableElevation size="small" onClick={resetFilters}>
                    Reset
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    size="small"
                    style={{ marginRight: "1rem" }}
                    onClick={applyFilters}
                  >
                    Apply
                  </Button>
                </ButtonGroup>
              </FilterMenu>
            </ButtonDropdown>
            <Button
              className={classes.button}
              size="small"
              startIcon={<GetApp />}
              onClick={handleExportReport}
            >
              Export
            </Button>
          </ActionGroup>
        </Actions>
        <ReportSearch setQuery={setSearchTerm} />
        <Table
          clientFilter={"phx_client_nid"}
          columns={columns}
          rows={rows}
          stickyHeader
          loading={loading}
          hideColumns={hideColumns}
          pageRequest={loadReport}
          originalOrderBy="access"
          originalOrder="desc"
          search={searchTerm}
          filters={filters}
          pagination={pagination}
          size="small"
        />
      </Paper>
    </div>
  );
};

ReportHpnSelect.propTypes = {};

const mapStateToProps = (state) => ({
  loading: state.app.report.loading,
  report: state.app.report.data.data,
  pagination: state.app.report.data.pagination,
  error: state.app.report.error,
});

const mapDispatchToProps = (dispatch) => ({
  loadReport: (params) => dispatch(getReportHpnSelect(params)),
  exportReport: (url, params) => dispatch(exportBatch(url, params)),
  clear: () => dispatch(clear("report")),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions
)(ReportHpnSelect);
