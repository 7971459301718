import React, { useRef, /*useCallback*/ } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { MdLaunch } from "react-icons/md";
// import { RiEdit2Fill } from "react-icons/ri";

import { IconButtonDropdown } from "common/ButtonDropdown";
// // import EditD2DDivision from "../forms/D2D/EditDivision_cya";
// import EditD2DDivision from "../../Job/forms/EditDivision";
// import EditDKIDivision from "../forms/DKI/EditDivision_cya";
// import EditD2DLocation from "../forms/D2D/EditLocation_cya";

import { divisionsSelectors } from "../divisionsSlice";

const DivisionActions = ({ nid, url }) => {
  const dropdownRef = useRef();
  const item = useSelector((state) =>
    divisionsSelectors.selectById(state, nid)
  );

  const handleCloseDropdown = () => {
    if (dropdownRef.current) {
      dropdownRef.current.close();
    }
  };

  // const getEditDivisionComponent = useCallback(() => {
  //   switch (item.job_division_process_type) {
  //     case "general":
  //     case "mf":
  //       const divisionName = item._paths.division.replace('/job/', '');
  //       return (
  //         <EditD2DDivision divisionName={divisionName} handleClose={handleCloseDropdown} >
  //           <MenuItem button>
  //             <ListItemIcon>
  //               <RiEdit2Fill />
  //             </ListItemIcon>
  //             <ListItemText primary="Division" />
  //           </MenuItem>
  //         </EditD2DDivision>
  //       );
  //     case "dki":
  //       return <EditDKIDivision nid={nid} handleClose={handleCloseDropdown} />;
  //     default:
  //       return null;
  //   }
  // }, [item, nid]);

  // const getEditLocationComponent = useCallback(() => {
  //   switch (item.job_division_process_type) {
  //     case "general":
  //     case "mf":
  //       return <EditD2DLocation nid={nid} handleClose={handleCloseDropdown} />;
  //     case "dki":
  //       return null;
  //     default:
  //       return null;
  //   }
  // }, [item, nid]);

  return (
    <IconButtonDropdown
      ref={dropdownRef}
      icon={MoreVert}
      tooltip="Actions"
      size="small"
      disabled={item.saving}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <MenuItem
        component={Link}
        to={`${url}/${nid}`}
        onClick={handleCloseDropdown}
      >
        <ListItemIcon>
          <MdLaunch />
        </ListItemIcon>
        <ListItemText primary="Snapshot" />
      </MenuItem>
      {/* {getEditDivisionComponent()}
      {getEditLocationComponent()} */}
    </IconButtonDropdown>
  );
};

DivisionActions.propTypes = {};

export default DivisionActions;
