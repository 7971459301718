import React from "react";

import { Card, Value, Label } from "./Styled";

const CollapsibleCard = ({ collapse, value, label, onClick, children }) => {
  return (
    <Card
      shouldCollapse={collapse}
      onClick={onClick ? onClick : null}
      style={{ cursor: onClick ? "pointer" : "default" }}
    >
      <Value shouldCollapse={collapse}>{value}</Value>
      <Label shouldCollapse={collapse}>
        {children}
        {collapse ? "" : label}
      </Label>
    </Card>
  );
};

CollapsibleCard.propTypes = {};

export default CollapsibleCard;
