import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import querystring from "querystring";
import { removeCurrencyFormatting } from "utility";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import {
  formatJobDivisionFile,
  formatJobDivisionFileListingItem,
  formatCustomerFile,
  formatCustomerFileListingItem,
  b64toBlob,
  downloadOctet,
} from "./utils";
import fileSaver from "../../utility/fileSaver";
import { setAlert } from "features/Alert/alertSlice";
import { formatField } from "../../utility";
import moment from "moment";

const getQueryParams = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : querystring.stringify(params)
  }`;
};

const namespace = "files";

export const fetchJobDivisionFiles = createAsyncThunk(
  `${namespace}/fetchJobDivisionFiles`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const queryParams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/files/${id}${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      const jdFiles = response.data.data;
      const mappedFiles = jdFiles.map((file) =>
        formatJobDivisionFileListingItem(file)
      );
      const files = mappedFiles
        .reduce((prev, curr) => [...prev, curr.field_file], [])
        .filter((f) => f);

      return {
        jobDivisionFiles: mappedFiles,
        files,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchBase64 = createAsyncThunk(
  `${namespace}/fetchBase64`,
  async ({ id, image_style }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/files/encode/${id}/${image_style}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMoreJobDivisionFiles = createAsyncThunk(
  `${namespace}/fetchMoreJobDivisionFiles`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const queryParams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/files/${id}${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      const jdFiles = response.data.data;
      const mappedFiles = jdFiles.map((file) =>
        formatJobDivisionFileListingItem(file)
      );
      const files = mappedFiles
        .reduce((prev, curr) => [...prev, curr.field_file], [])
        .filter((f) => f);

      return {
        jobDivisionFiles: mappedFiles,
        files,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchJobDivisionPhotos = createAsyncThunk(
  `${namespace}/fetchJobDivisionPhotos`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const queryParams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/files/${id}/true${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      const jdFiles = response.data.data;

      return {
        jobDivisionFiles: jdFiles,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMoreJobDivisionPhotos = createAsyncThunk(
  `${namespace}/fetchMoreJobDivisionPhotos`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const queryParams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/files/${id}/true${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      const jdFiles = response.data.data;

      return {
        jobDivisionFiles: jdFiles,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchJobDivisionInvoiceFiles = createAsyncThunk(
  `${namespace}/fetchJobDivisionInvoiceFiles`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const queryParams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/files/${id}${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        files: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMoreJobDivisionInvoiceFiles = createAsyncThunk(
  `${namespace}/fetchMoreJobDivisionInvoiceFiles`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const queryParams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/files/${id}${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        files: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCustomerFiles = createAsyncThunk(
  `${namespace}/fetchCustomerFiles`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const queryParams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/customer/files/${id}${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      const jdFiles = response.data.data;
      const mappedFiles = jdFiles.map((file) =>
        formatCustomerFileListingItem(file)
      );
      const files = mappedFiles
        .reduce((prev, curr) => [...prev, curr.field_file], [])
        .filter((f) => f);

      return {
        customerFiles: mappedFiles,
        files,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMoreCustomerFiles = createAsyncThunk(
  `${namespace}/fetchMoreCustomerFiles`,
  async ({ id, params }, { getState, signal, rejectWithValue }) => {
    try {
      const queryParams = getQueryParams(params);
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/customer/files/${id}${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      const jdFiles = response.data.data;
      const mappedFiles = jdFiles.map((file) =>
        formatCustomerFileListingItem(file)
      );
      const files = mappedFiles
        .reduce((prev, curr) => [...prev, curr.field_file], [])
        .filter((f) => f);

      return {
        customerFiles: mappedFiles,
        files,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postCustomerFile = createAsyncThunk(
  `${namespace}/postCustomerFile`,
  async ({ id, params }, { getState, rejectWithValue, dispatch }) => {
    try {
      const postParams = {
        _links: {
          type: {
            href: `${config.api_url}/rest/type/paragraph/customer_documents`,
          },
        },
        _meta: {
          parent_entity: "node",
          parent_field: "field_customer_documents",
          parent_id: id,
        },
        field_cd_file: [{ target_id: params.fid }],
        field_cd_category: [{ target_id: params.category }],
        field_cd_visibility: [],
      };

      if (params.phx_client)
        postParams.field_cd_visibility.push({ value: "phx_client" });
      if (params.customer)
        postParams.field_cd_visibility.push({ value: "customer" });
      if (params.member)
        postParams.field_cd_visibility.push({ value: "member" });

      const response = await axios.post(
        `${config.api_url}/entity/paragraph`,
        postParams,
        tokenConfig(getState)
      );

      return formatCustomerFile(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postJobDivisionFile = createAsyncThunk(
  `${namespace}/postJobDivisionFile`,
  async ({ id, params }, { getState, rejectWithValue, dispatch }) => {
    try {
      const postParams = {
        _links: {
          type: {
            // href: `${config.api_url}/rest/type/paragraph/job_division_file`,
            href: `${config.api_url}/rest/type/node/general_file`,
          },
        },
        title: [{ value: (params?.title ? params.title : 'temp') }],
        // _meta: {
        //   parent_entity: "node",
        //   parent_field: "field_jd_files",
        //   parent_id: id,
        // },
        field_file: [{ target_id: params.fid }],
        field_parent_ref: [{ target_id: id }],
        field_file_category: [{ target_id: params.category }],
        field_visibility: [],
      };

      if (params.field_addl_ref) {
        postParams.field_addl_ref = [{ target_id: params.field_addl_ref }];
      }
      if (params.reference) {
        postParams.field_addl_ref = [{ target_id: params.reference }];
      }
      if (params.amount) {
        postParams.field_gf_amount = [{ value: removeCurrencyFormatting(params.amount) }];
      }
      if (params.date) {
        postParams.field_gf_date = [
          { value: moment(params.date).format("YYYY-MM-DD") },
        ];
      }
      if (params.time) {
        postParams.field_gf_photo_time = [{ value: params.time }];
      }
      if (params.phx_client)
        postParams.field_visibility.push({ value: "phx_client" });
      if (params.customer)
        postParams.field_visibility.push({ value: "customer" });
      if (params.member) postParams.field_visibility.push({ value: "member" });
      if (params.mby_agent) postParams.field_visibility.push({ value: "mby_agent" });

      const response = await axios.post(
        `${config.api_url}/node`,
        postParams,
        tokenConfig(getState)
      );

      return formatJobDivisionFile(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const prepForJobDivisionFilePatch = (params) => {
  let patchParams = {};

  if(!params?.nids){
    patchParams = {
      _links: {
        type: {
          // href: `${config.api_url}/rest/type/paragraph/job_division_file`,
          href: `${config.api_url}/rest/type/node/general_file`,
        },
      },
      field_visibility: [],
    };
  }
  else{
    patchParams = {
      nids: params.nids,
      division_nid: params.division_nid,
    };
    if (params.phx_client || params.customer || params.member || params.mby_agent){
      patchParams.field_visibility = [];
    }
  }
  patchParams = {
    ...patchParams,
  };

  if (params.time && params.time !== '') {
    patchParams.field_gf_photo_time = [{ value: params.time }];
  }
  if (params.reference_nid) {
    patchParams.field_addl_ref = [{ target_id: params.reference_nid }];
  }
  if (params.field_file_category && params?.field_file_category !== '') {
    patchParams.field_file_category = [
      { target_id: params.field_file_category },
    ];
  }
  if (params?.category && params.category !== '') {
    patchParams.field_file_category = [
      { target_id: params.category },
    ];
  }
  if (params.field_gf_amount) {
    patchParams.field_gf_amount = [{ value: params.field_gf_amount }];
  }
  if (params.field_gf_date) {
    patchParams.field_gf_date = [{ value: params.field_gf_date }];
  }
  if (params.phx_client)
    patchParams.field_visibility.push({ value: "phx_client" });
  if (params.customer)
    patchParams.field_visibility.push({ value: "customer" });
  if (params.member) patchParams.field_visibility.push({ value: "member" });
  if (params.mby_agent) patchParams.field_visibility.push({ value: "mby_agent" });

  return patchParams;
}

export const patchJobDivisionFile = createAsyncThunk(
  `${namespace}/patchJobDivisionFile`,
  async ({ id, params }, { getState, rejectWithValue, signal }) => {
    const source = axios.CancelToken.source();

    signal.addEventListener("abort", () => {
      source.cancel();
    });

    try {
      const patchParams = prepForJobDivisionFilePatch(params);

      const response = await axios.patch(
        `${config.api_url}/node/${id}`,
        patchParams,
        { ...tokenConfig(getState), cancelToken: source.token }
      );

      return formatJobDivisionFile(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchFileEnhancement = createAsyncThunk(
  `${namespace}/patchFileEnhancement`,
  async ({ nid, params }, { getState, rejectWithValue, signal }) => {
    const source = axios.CancelToken.source();

    signal.addEventListener("abort", () => {
      source.cancel();
    });

    try {
      const response = await axios.patch(
        `${config.api_url}/rest/files/save-enhancement/${nid}`,
        params,
        { ...tokenConfig(getState), cancelToken: source.token }
      );

      return formatJobDivisionFile(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchFileEnhancementReset = createAsyncThunk(
  `${namespace}/patchFileEnhancementReset`,
  async ({ nid }, { getState, rejectWithValue, signal }) => {
    const source = axios.CancelToken.source();

    signal.addEventListener("abort", () => {
      source.cancel();
    });

    try {
      const response = await axios.patch(
        `${config.api_url}/rest/files/reset-enhancement/${nid}`,
        {},
        { ...tokenConfig(getState), cancelToken: source.token }
      );

      return formatJobDivisionFile(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchMultipleJobDivisionFiles = createAsyncThunk(
  `${namespace}/patchMultipleJobDivisionFiles`,
  async ({ params }, { getState, rejectWithValue, signal }) => {
    const source = axios.CancelToken.source();

    signal.addEventListener("abort", () => {
      source.cancel();
    });

    try {
      const patchParams = prepForJobDivisionFilePatch(params);

      await axios.patch(
        `${config.api_url}/rest/job/files/update`,
        patchParams,
        { ...tokenConfig(getState), cancelToken: source.token }
      );

      return {
        params: params,
        patchParams: patchParams,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchJobDivisionFilePublished = createAsyncThunk(
  `${namespace}/patchJobDivisionFilePublished`,
  async ({ id, published }, { getState, rejectWithValue, signal }) => {
    const source = axios.CancelToken.source();

    signal.addEventListener("abort", () => {
      source.cancel();
    });

    try {
      const patchParams = {
        _links: {
          type: {
            // href: `${config.api_url}/rest/type/paragraph/job_division_file`,
            href: `${config.api_url}/rest/type/node/general_file`,
          },
        },
        field_published: [{ value: Number(published) }],
      };

      const response = await axios.patch(
        `${config.api_url}/node/${id}`,
        patchParams,
        { ...tokenConfig(getState), cancelToken: source.token }
      );

      return formatJobDivisionFile(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchCustomerFile = createAsyncThunk(
  `${namespace}/patchCustomerFile`,
  async ({ id, params }, { getState, rejectWithValue, signal, dispatch }) => {
    const source = axios.CancelToken.source();

    signal.addEventListener("abort", () => {
      source.cancel();
    });

    try {
      const patchParams = {
        _links: {
          type: {
            href: `${config.api_url}/rest/type/paragraph/customer_documents`,
          },
        },
        field_cd_visibility: [],
      };

      if (params.field_cd_category) {
        patchParams.field_cd_category = [
          { target_id: params.field_cd_category },
        ];
      }
      if (params.phx_client)
        patchParams.field_cd_visibility.push({ value: "phx_client" });
      if (params.customer)
        patchParams.field_cd_visibility.push({ value: "customer" });
      if (params.member)
        patchParams.field_cd_visibility.push({ value: "member" });

      const response = await axios.patch(
        `${config.api_url}/entity/paragraph/${id}`,
        patchParams,
        { ...tokenConfig(getState), cancelToken: source.token }
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: `Successfully updated file`,
        })
      );

      return formatCustomerFile(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error updating file: ${error.response.data?.message}`,
        })
      );

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteJobDivisionFile = createAsyncThunk(
  `${namespace}/deleteJobDivisionFile`,
  async ({ id, file }, { getState, rejectWithValue, signal }) => {
    const source = axios.CancelToken.source();

    signal.addEventListener("abort", () => {
      source.cancel();
    });

    try {
      const patchParams = {
        toggle: false
      };

      await axios.patch(
        `${config.api_url}/rest/entity/publish/node/${id}`,
        patchParams,
        { ...tokenConfig(getState), cancelToken: source.token }
      );

      return id;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
  // async ({ id, file }, { getState, rejectWithValue, dispatch }) => {
  //   try {
  //     await axios.delete(
  //       `${config.api_url}/node/${id}`,
  //       tokenConfig(getState)
  //     );

  //     return id;
  //   } catch (err) {
  //     let error = err; // cast the error for access
  //     if (!error.response) {
  //       throw err;
  //     }
  //     // We got validation errors, let's return those so we can reference in our component and set form errors
  //     return rejectWithValue(error.response.data);
  //   }
  // }
);

export const deleteCustomerFile = createAsyncThunk(
  `${namespace}/deleteCustomerFile`,
  async ({ id, file }, { getState, rejectWithValue, dispatch }) => {
    try {
      await axios.delete(
        `${config.api_url}/entity/paragraph/${id}`,
        tokenConfig(getState)
      );

      return id;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const downloadFile = createAsyncThunk(
  `${namespace}/downloadFile`,
  async ({ id, filename, token }, { getState, rejectWithValue }) => {
    try {
      if (token) {
        const access_token = getState().auth.token;
        downloadOctet(
          `${config.api_url}/rest/file/${id}/download?token=${token}&oauth_token=${access_token}`,
          filename
        );
      } else {
        const response = await axios.get(`${config.api_url}/file/${id}`, {
          ...tokenConfig(getState),
          responseType: "arrayBuffer",
          timeout: 30000,
        });

        const blob = b64toBlob(response.data.data[0].value);
        await fileSaver(blob, filename);
      }
      return id;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadBase64 = createAsyncThunk(
  `${namespace}/uploadBase64`,
  async ({ data, entity, bundle, field, fileName }, { getState, rejectWithValue }) => {
    try {
      const block = data.split(";");
      const contentType = block[0].split(":")[1];
      const realData = block[1].split(",")[1];

      const blob = b64toBlob(realData, contentType);

      const params = {
        ...tokenConfig(getState),
      };

      params.headers["Content-Type"] = "application/octet-stream";
      params.headers[
        "Content-Disposition"
      ] = `file; filename="${fileName ? fileName : 'uploaded-image.png'}"`;

      const response = await axios.post(
        `${config.api_url}/file/upload/${entity}/${bundle}/${field}`,
        blob,
        params
      );

      return formatField(response, "fid");
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const filesAdapter = createEntityAdapter({
  selectId: (file) => file.fid,
});

const jobDivisionFilesAdapter = createEntityAdapter({
  selectId: (file) => file.id,
  sortComparer: (a, b) => a.id - b.id,
});

const jobDivisionPhotosAdapter = createEntityAdapter({
  selectId: (file) => file.fid,
  sortComparer: (a, b) => a.fid - b.fid,
});

const customerFilesAdapter = createEntityAdapter({
  selectId: (file) => file.id,
  sortComparer: (a, b) => a.id - b.id,
});

const filesSlice = createSlice({
  name: namespace,
  initialState: filesAdapter.getInitialState({
    jobDivisionFiles: jobDivisionFilesAdapter.getInitialState({
      loading: false,
      pagination: { count: 0, current_page: 0, total_pages: 0 },
    }),
    jobDivisionPhotos: jobDivisionPhotosAdapter.getInitialState({
      loading: false,
      pagination: { count: 0, current_page: 0, total_pages: 0 },
    }),
    jobDivisionInvoiceFiles: jobDivisionPhotosAdapter.getInitialState({
      loading: false,
      pagination: { count: 0, current_page: 0, total_pages: 0 },
    }),
    customerFiles: customerFilesAdapter.getInitialState({
      loading: false,
      pagination: { count: 0, current_page: 0, total_pages: 0 },
    }),
  }),
  reducers: {
    setManyFiles: filesAdapter.addMany,
  },
  extraReducers: {
    [fetchJobDivisionFiles.pending](state) {
      state.jobDivisionFiles.loading = true;
      state.error = null;
    },
    [fetchJobDivisionFiles.fulfilled](
      state,
      { payload: { jobDivisionFiles, files, pagination } }
    ) {
      state.jobDivisionFiles.loading = false;
      state.jobDivisionFiles.pagination = pagination;
      filesAdapter.setAll(state, files);
      jobDivisionFilesAdapter.setAll(state.jobDivisionFiles, jobDivisionFiles);
    },
    [fetchJobDivisionFiles.rejected](state, action) {
      state.jobDivisionFiles.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchMoreJobDivisionFiles.pending](state) {
      state.error = null;
    },
    [fetchMoreJobDivisionFiles.fulfilled](
      state,
      { payload: { jobDivisionFiles, files, pagination } }
    ) {
      state.jobDivisionFiles.pagination = pagination;
      filesAdapter.addMany(state, files);
      jobDivisionFilesAdapter.addMany(state.jobDivisionFiles, jobDivisionFiles);
    },
    [fetchMoreJobDivisionFiles.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchJobDivisionPhotos.pending](state) {
      state.jobDivisionPhotos.loading = true;
      state.error = null;
    },
    [fetchJobDivisionPhotos.fulfilled](
      state,
      { payload: { jobDivisionFiles, pagination } }
    ) {
      state.jobDivisionPhotos.loading = false;
      state.jobDivisionPhotos.pagination = pagination;
      jobDivisionPhotosAdapter.setAll(
        state.jobDivisionPhotos,
        jobDivisionFiles
      );
    },
    [fetchJobDivisionPhotos.rejected](state, action) {
      state.jobDivisionPhotos.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchJobDivisionInvoiceFiles.pending](state) {
      state.jobDivisionInvoiceFiles.loading = true;
      state.error = null;
    },
    [fetchJobDivisionInvoiceFiles.fulfilled](
      state,
      { payload: { files, pagination } }
    ) {
      state.jobDivisionInvoiceFiles.loading = false;
      state.jobDivisionInvoiceFiles.pagination = pagination;
      jobDivisionPhotosAdapter.setAll(state.jobDivisionInvoiceFiles, files);
    },
    [fetchJobDivisionInvoiceFiles.rejected](state, action) {
      state.jobDivisionInvoiceFiles.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchMoreJobDivisionInvoiceFiles.pending](state) {
      state.error = null;
    },
    [fetchMoreJobDivisionInvoiceFiles.fulfilled](
      state,
      { payload: { files, pagination } }
    ) {
      state.jobDivisionInvoiceFiles.pagination = pagination;
      jobDivisionPhotosAdapter.addMany(state.jobDivisionInvoiceFiles, files);
    },
    [fetchMoreJobDivisionInvoiceFiles.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchMoreJobDivisionPhotos.pending](state) {
      state.error = null;
    },
    [fetchMoreJobDivisionPhotos.fulfilled](
      state,
      { payload: { jobDivisionFiles, pagination } }
    ) {
      state.jobDivisionPhotos.pagination = pagination;
      jobDivisionPhotosAdapter.addMany(
        state.jobDivisionPhotos,
        jobDivisionFiles
      );
    },
    [fetchMoreJobDivisionPhotos.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchCustomerFiles.pending](state) {
      state.customerFiles.loading = true;
      state.error = null;
    },
    [fetchCustomerFiles.fulfilled](
      state,
      { payload: { customerFiles, files, pagination } }
    ) {
      state.customerFiles.loading = false;
      state.customerFiles.pagination = pagination;
      filesAdapter.setAll(state, files);
      customerFilesAdapter.setAll(state.customerFiles, customerFiles);
    },
    [fetchCustomerFiles.rejected](state, action) {
      state.customerFiles.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchMoreCustomerFiles.pending](state) {
      state.error = null;
    },
    [fetchMoreCustomerFiles.fulfilled](
      state,
      { payload: { customerFiles, files, pagination } }
    ) {
      state.customerFiles.pagination = pagination;
      filesAdapter.addMany(state, files);
      customerFiles.addMany(state.customerFiles, customerFiles);
    },
    [fetchMoreCustomerFiles.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [deleteJobDivisionFile.pending](state, action) {
      const { id } = action.meta.arg;
      jobDivisionFilesAdapter.removeOne(state.jobDivisionFiles, id);
    },
    [deleteJobDivisionFile.fulfilled](state, { payload: id }) {},
    [deleteJobDivisionFile.rejected](state, action) {
      const { file } = action.meta.arg;
      jobDivisionFilesAdapter.addOne(state.jobDivisionFiles, file);

      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [deleteCustomerFile.pending](state, action) {
      const { id } = action.meta.arg;
      customerFilesAdapter.removeOne(state.customerFiles, id);
    },
    [deleteCustomerFile.fulfilled](state, { payload: id }) {},
    [deleteCustomerFile.rejected](state, action) {
      const { file } = action.meta.arg;
      customerFilesAdapter.addOne(state.customerFiles, file);

      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [postCustomerFile.pending](state, action) {},
    [postCustomerFile.fulfilled](state, { payload: file }) {
      filesAdapter.addOne(state, file.field_file);
      customerFilesAdapter.addOne(state.customerFiles, file);
    },
    [postCustomerFile.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [postJobDivisionFile.pending](state, action) {},
    [postJobDivisionFile.fulfilled](state, { payload: file }) {
      file.id = file.nid;
      filesAdapter.addOne(state, file.field_file);
      jobDivisionFilesAdapter.addOne(state.jobDivisionFiles, {
        ...file,
        file_created: moment().format("X"),
      });
    },
    [postJobDivisionFile.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [patchJobDivisionFile.pending](state, action) {
      const { id, params } = action.meta.arg;
      const visible = [];
      if (params.phx_client) visible.push("phx_client");
      if (params.member) visible.push("member");
      if (params.customer) visible.push("customer");
      jobDivisionFilesAdapter.updateOne(state.jobDivisionFiles, {
        id,
        changes: {
          field_file_category: {
            tid: Number(params.field_file_category),
            name: params.field_file_category_name,
          },
          field_gf_photo_time: params.photo_time,
          field_addl_ref: params.reference_nid,
          field_gf_amount: params.field_gf_amount,
          field_gf_date: params.field_gf_date,
          field_visibility: visible,
        },
      });
    },
    [patchJobDivisionFile.fulfilled](state, { meta, payload: file }) {
      const { id } = meta.arg;
      jobDivisionFilesAdapter.updateOne(state.jobDivisionFiles, {
        id,
        changes: {
          field_file_category: file.field_file_category,
          field_gf_photo_time: file.field_gf_photo_time,
          field_addl_ref: file.field_addl_ref,
          field_gf_amount: file.field_gf_amount,
          field_gf_date: file.field_gf_date,
          field_visibility: file.field_visibility,
        },
      });
    },
    [patchJobDivisionFile.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [patchFileEnhancement.pending](state, action) {
      // const { id, params } = action.meta.arg;
      // const visible = [];
      // if (params.phx_client) visible.push("phx_client");
      // if (params.member) visible.push("member");
      // if (params.customer) visible.push("customer");
      // jobDivisionFilesAdapter.updateOne(state.jobDivisionFiles, {
      //   id,
      //   changes: {
      //     field_file_category: {
      //       tid: Number(params.field_file_category),
      //       name: params.field_file_category_name,
      //     },
      //     field_gf_photo_time: params.photo_time,
      //     field_addl_ref: params.reference_nid,
      //     field_gf_amount: params.field_gf_amount,
      //     field_gf_date: params.field_gf_date,
      //     field_visibility: visible,
      //   },
      // });
    },
    [patchFileEnhancement.fulfilled](state, { meta, payload: file }) {
      const { nid } = meta.arg;
      const id = String(nid);
      const _file = {
        ...file.field_file,
        fid: String(file.field_file.fid),
      }
      filesAdapter.addOne(state, _file);
      jobDivisionFilesAdapter.updateOne(state.jobDivisionFiles, {
        id,
        changes: {
          field_file: _file,
          is_enhanced: true,
        },
      });
    },
    [patchFileEnhancement.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [patchFileEnhancementReset.pending](state, action) {
      // const { id, params } = action.meta.arg;
      // const visible = [];
      // if (params.phx_client) visible.push("phx_client");
      // if (params.member) visible.push("member");
      // if (params.customer) visible.push("customer");
      // jobDivisionFilesAdapter.updateOne(state.jobDivisionFiles, {
      //   id,
      //   changes: {
      //     field_file_category: {
      //       tid: Number(params.field_file_category),
      //       name: params.field_file_category_name,
      //     },
      //     field_gf_photo_time: params.photo_time,
      //     field_addl_ref: params.reference_nid,
      //     field_gf_amount: params.field_gf_amount,
      //     field_gf_date: params.field_gf_date,
      //     field_visibility: visible,
      //   },
      // });
    },
    [patchFileEnhancementReset.fulfilled](state, { meta, payload: file }) {
      const { nid } = meta.arg;
      const id = String(nid);
      const _file = {
        ...file.field_file,
        fid: String(file.field_file.fid),
      }
      filesAdapter.addOne(state, _file);
      jobDivisionFilesAdapter.updateOne(state.jobDivisionFiles, {
        id,
        changes: {
          field_file: _file,
          is_enhanced: false,
        },
      });
    },
    [patchFileEnhancementReset.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [patchMultipleJobDivisionFiles.pending](state, action) {
      // const { params } = action.meta.arg;
      // const visible = [];
      // if (params.phx_client) visible.push("phx_client");
      // if (params.member) visible.push("member");
      // if (params.customer) visible.push("customer");
      // jobDivisionFilesAdapter.updateOne(state.jobDivisionFiles, {
      //   id,
      //   changes: {
      //     field_file_category: {
      //       tid: Number(params.field_file_category),
      //       name: params.field_file_category_name,
      //     },
      //     field_gf_photo_time: params.photo_time,
      //     field_addl_ref: params.reference_nid,
      //     field_gf_amount: params.field_gf_amount,
      //     field_gf_date: params.field_gf_date,
      //     field_visibility: visible,
      //   },
      // });
    },
    [patchMultipleJobDivisionFiles.fulfilled](state, { meta, payload: _params }) {
      // const { params } = meta.arg;
      const params = _params.params;
      const patchParams = _params.patchParams;
      const settings = formatJobDivisionFile(patchParams);
      patchParams?.nids.forEach((nid) => {
        const id = Number(nid);
        const changes = {};
        if(params.field_file_category){
          changes.field_file_category = {
            tid: params.field_file_category,
            name: params.field_file_category_name,
          };
        }
        if(settings.field_gf_photo_time){
          changes.field_gf_photo_time = settings.field_gf_photo_time;
        }
        if(settings.field_addl_ref){
          changes.field_addl_ref = settings.field_addl_ref;
        }
        if(settings.field_gf_amount){
          changes.field_gf_amount = settings.field_gf_amount;
        }
        if(settings.field_gf_date){
          changes.field_gf_date = settings.field_gf_date;
        }
        if(settings.field_visibility){
          changes.field_visibility = settings.field_visibility;
        }
        jobDivisionFilesAdapter.updateOne(state.jobDivisionFiles, {
          id,
          changes: changes,
        });
      });
    },
    [patchMultipleJobDivisionFiles.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [patchJobDivisionFilePublished.pending](state, action) {
      const { id, published } = action.meta.arg;
      jobDivisionFilesAdapter.updateOne(state.jobDivisionFiles, {
        id,
        changes: {
          field_published: published,
        },
      });
    },
    [patchJobDivisionFilePublished.fulfilled](state, { meta, payload: file }) {
      const { id } = meta.arg;
      jobDivisionFilesAdapter.updateOne(state.jobDivisionFiles, {
        id,
        changes: {
          field_published: file.field_published,
        },
      });
    },
    [patchJobDivisionFilePublished.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [patchCustomerFile.pending](state, action) {
      const { id, params } = action.meta.arg;
      customerFilesAdapter.updateOne(state.customerFiles, {
        id,
        changes: {
          field_cd_category: {
            tid: params.field_cd_category,
            name: params.field_cd_category_name,
          },
        },
      });
    },
    [patchCustomerFile.fulfilled](state, { meta, payload: file }) {
      const { id } = meta.arg;
      customerFilesAdapter.updateOne(state.customerFiles, {
        id,
        changes: {
          field_cd_category: file.field_cd_category,
        },
      });
    },
    [patchCustomerFile.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
  },
});

export const filesSelectors = filesAdapter.getSelectors((state) => state.files);

export const jobDivisionFilesSelectors = jobDivisionFilesAdapter.getSelectors(
  (state) => state.files.jobDivisionFiles
);

export const jobDivisionPhotosSelectors = jobDivisionPhotosAdapter.getSelectors(
  (state) => state.files.jobDivisionPhotos
);

export const jobDivisionInvoiceFilesSelectors =
  jobDivisionPhotosAdapter.getSelectors(
    (state) => state.files.jobDivisionInvoiceFiles
  );

export const customerFilesSelectors = customerFilesAdapter.getSelectors(
  (state) => state.files.customerFiles
);

// Custom selectors
const selectSelf = (state) => state;
export const getJobDivisionFilesLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.files.jobDivisionFiles.loading
);

export const getJobDivisionFilesPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.files.jobDivisionFiles.pagination
);

export const getJobDivisionPhotosLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.files.jobDivisionPhotos.loading
);

export const getJobDivisionInvoiceFilesLoadingSelector =
  createDraftSafeSelector(
    selectSelf,
    (state) => state.files.jobDivisionInvoiceFiles.loading
  );

export const getJobDivisionInvoiceFilesPaginationSelector =
  createDraftSafeSelector(
    selectSelf,
    (state) => state.files.jobDivisionInvoiceFiles.pagination
  );

export const getJobDivisionPhotosPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.files.jobDivisionPhotos.pagination
);

export const getCustomerFilesLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.files.customerFiles.loading
);

export const getCustomerFilesPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.files.customerFiles.pagination
);

export const getFilesErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.files.error
);

export const { setManyFiles } = filesSlice.actions;

export default filesSlice.reducer;
