import styled from "styled-components";
import { Form } from "formik";
import constants from "components/constants";

export const Container = styled.div`
  padding: 1.25rem 1.25rem 0 1.25rem;
`;

export const Header = styled.header`
  display: flex;
  margin-bottom: 4rem;

  & > div {
    margin-right: 0.75rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const HeaderDetails = styled.div`
  padding: 1.25rem;
  display: flex;

  & > div {
    margin-right: 2rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Card = styled.div`
  padding: 1.25rem;
`;

export const FormContainer = styled(Form)`
  padding: 1.25rem;
`;

export const FormControlGroup = styled.div`
  display: flex;
  align-items: flex-end;

  & > .MuiFormControl-root {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const PopupForm = styled.div`
  padding: 1rem;
`;

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ResourceItems = styled.div``;

export const ResourceItem = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${constants.colorGrayLight};
  margin: 0 -1.25rem;
  padding: 0 1.25rem;

  &:nth-child(even) {
    background: ${constants.colorGrayLightest};
  }

  &:last-child {
    border-bottom: none;
  }

  & > .MuiTypography-root {
    flex: 1;
  }
`;

export const FormActions = styled.div`
  padding-top: 2rem;
`;

export const TableContainer = styled.div`
  margin: 0 -1.25rem;
`;

export const Search = styled.div`
  margin-bottom: 1.25rem;
`;

export const TabContainer = styled.div`
  margin-bottom: 1.25rem;
`;

export const BatchRow = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  align-items: center;
  padding: 1rem;
  background: ${constants.colorGrayLightest};
  border-radius: ${constants.borderRadius};
  border: 1px solid
    ${({ status }) =>
      status === "positive"
        ? constants.colorGreen
        : status === "negative"
        ? constants.colorRed
        : constants.colorGray};
  margin-bottom: 0.5rem;

  & .MuiOutlinedInput-root {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: white;
  }
`;

export const BatchCell = styled.div`
  border: 1px solid ${constants.colorGray};
  flex: 1;
`;

export const BatchContainer = styled.div``;

export const EquipmentBatchHeader = styled.div`
  background: ${constants.colorSecondary};
  color: white;
  padding-left: 1.25rem;
  margin-top: 1.25rem;
`;

export const EquipmentBatchLabel = styled.div`
  width: 100%;
  height: 100%;
  border-bottom: 1px solid ${constants.colorBlack};
  border-left: 1px solid ${constants.colorBlack};
  border-right: 1px solid ${constants.colorBlack};
  padding: 0.25rem;
`;

export const EquipmentBatchField = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid ${constants.colorBlack};
  // border-left: 1px solid ${constants.colorBlack};
  border-right: 1px solid ${constants.colorBlack};

  & .increment {
    position: absolute;
    right: 2px;
    top: 5px;
    color: ${constants.colorGrayMedium};
    font-size: 10px;
  }

  & input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    border-radius: 0;
  }

  & input:focus {
    outline: none;
  }
`;
