import {
  formatFieldByValue,
  getPathFromLinksSelf,
  formatField,
  getUserFields,
  getFileFields,
} from "../../utility";

export const calcTotal = (cost, markup) => {
  let percent = parseFloat(markup) / 100;
  if (isNaN(percent)) percent = 0;
  if (isNaN(cost)) cost = 0;
  cost = Number(cost);
  percent = Number(percent);
  cost += cost * percent;
  return Number(cost).toFixed(2);
};

export const formatExpense = (d) => {
  return {
    self: getPathFromLinksSelf(d),
    type: "ds_expense",
    nid: formatField(d, "nid"),
    title: formatField(d, "title"),
    created: formatField(d, "created"),
    changed: formatField(d, "changed"),
    path: formatFieldByValue(d, "path", "alias"),
    field_ds_date: formatField(d, "field_ds_date"),
    field_ds_user: getUserFields(d, "field_ds_user"),
    field_ds_description: formatField(d, "field_ds_description"),
    field_ds_exclude_invoice: formatField(d, "field_ds_exclude_invoice"),
    field_ds_expense_cost: formatField(d, "field_ds_expense_cost"),
    field_ds_total: formatField(d, "field_ds_total"),
    field_ds_expense_type_text: formatField(d, "field_ds_expense_type_text"),
    field_ds_percent_markup: formatField(d, "field_ds_percent_markup"),
    field_ds_reimburse: formatField(d, "field_ds_reimburse"),
    field_ds_description_show: formatField(d, "field_ds_description_show"),
    field_ds_taxable: formatField(d, "field_ds_taxable"),
    field_ds_show_descript_invoice: formatField(
      d,
      "field_ds_show_descript_invoice"
    ),
    field_ds_receipt: getFileFields(d, "field_ds_receipt"),
    field_visibility: formatField(d, "field_visibility"),
  };
};
