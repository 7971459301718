import React from "react";
import { Link } from "react-router-dom";
import { List, ListItem, ListItemText } from "@mui/material";
import { withPermissions } from "../../providers";

import Paper from "../Paper";
import { ROUTES } from "./utils";

const filterOutRoutesByPermissons = (routes, permissions) => {
  if (permissions) {
    const data = [];
    for (let index in routes) {
      const alias = routes[index].alias;
      if (permissions[alias] !== false) {
        data.push(routes[index]);
      }
    }

    return data;
  } else {
    return routes;
  }
};

const ReportsList = ({ roles, permissions }) => {
  const routes = filterOutRoutesByPermissons(ROUTES, permissions);

  return (
    <Paper margin="none">
      <List>
        {routes.map((route) => (
          <ListItem button component={Link} to={route.path} key={route.alias}>
            <ListItemText primary={route.label} />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

ReportsList.propTypes = {};

export default withPermissions(ReportsList);
