
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Tooltip,
} from "@mui/material";
import DailySheet from "features/DailySheet";
import ServiceAddEdit from "features/Service/forms/ServiceAddEdit";
import { getDivisionDataSelector, } from "features/Job/jobSlice";
import { TrashIcon } from "@heroicons/react/outline";
import ConfirmationDialog from "components/ConfirmationDialog";
import { deleteDailysheet, } from "features/DailySheets/dailysheetsSlice";


const TimeClockAdminButtons = ({
  hash,
  nid,
  showEditButtons=true,
  handleRefresh,
  deleteRefresh,
  isSmall,
}) => {
  const dispatch = useDispatch();
  const division = useSelector(getDivisionDataSelector);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteSubmitting, setDeleteSubmitting] = useState(false);
  const timeClockManager = division?._processed?.time_clock_manage;

  const handleDelete = async () => {
    setDeleteSubmitting(true);
    dispatch(deleteDailysheet({
      id: nid,
      type: 'labor',
    })).then((resp) => {
      setOpenDelete(false);
      setDeleteSubmitting(false);
      if(deleteRefresh){
        deleteRefresh();
      }
      else{
        handleRefresh();
      }
    });
  };

  if(!timeClockManager){
    return <></>;
  }

  return (
    <div
      className={`admin-buttons ${isSmall ? 'small' : ''}`}
      onClick={(e) => e.stopPropagation()}
    >
      <DailySheet nid={nid} typeLabel="Labor Entry" />
      {showEditButtons &&
        <>
        <ServiceAddEdit
          nid={nid}
          handleRefresh={handleRefresh}
        />
        <Tooltip title="Delete Time Card">
          <div
            className={`edit-icon large delete`}
            onClick={(e) => {
              e.stopPropagation();
              setOpenDelete(true);
            }}
          >
            <TrashIcon />
          </div>
        </Tooltip>
        </>
      }
      <ConfirmationDialog
        open={openDelete}
        onClose={() => {
          setOpenDelete(false);
        }}
        onSubmit={handleDelete}
        title="Are you sure?"
        body="Are you sure you want to delete this time card?"
        isSubmitting={deleteSubmitting}
        submitButton="Delete"
      />
    </div>
  );
};

TimeClockAdminButtons.propTypes = {};

export default TimeClockAdminButtons;
