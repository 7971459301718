import React, { useCallback, useEffect } from "react";
import ReactDOM from "react-dom";
import SimpleBar from "simplebar-react";
import PropTypes from "prop-types";
import { IconButton, Button } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import makeStyles from '@mui/styles/makeStyles';

import Status from "../Status";

import "./Snapshot.scss";

const useStyles = makeStyles((theme) => ({
  close: {
    color: "white",
  },
}));

const Snapshot = ({
  onClose,
  title,
  status,
  children,
  open,
  editable,
  actions: Actions,
  disablePadding,
  xl,
  disableOverlay,
}) => {
  const classes = useStyles();
  const [editing, setEditing] = React.useState(false);
  const escFunction = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const handleClick = () => {
    setEditing(true);
  };

  return ReactDOM.createPortal(
    <div className="snapshot__wrapper">
      <div className={xl ? "snapshot snapshot--xl" : "snapshot"}>
        <div
          className={
            xl ? "snapshot__actions snapshot__actions--xl" : "snapshot__actions"
          }
        >
          {status && <Status type={status} size="small" />}
          <div className="snapshot__actions-title">{title}</div>
          {editable && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<EditIcon />}
              onClick={handleClick}
              disableElevation
            >
              Edit
            </Button>
            // <>
            //   {!editing ? (
            //     <Button
            //       variant="contained"
            //       color="primary"
            //       size="small"
            //       startIcon={<EditIcon />}
            //       onClick={handleClick}
            //       disableElevation
            //     >
            //       Edit
            //     </Button>
            //   ) : (
            //     <Button
            //       variant="contained"
            //       color="default"
            //       size="small"
            //       disableElevation
            //       onClick={() => setEditing(false)}
            //     >
            //       Cancel
            //     </Button>
            //   )}
            // </>
          )}
          {Actions && <Actions />}
          <IconButton className={classes.close} onClick={onClose} size="large">
            <ClearIcon />
          </IconButton>
        </div>
        <SimpleBar style={{ maxHeight: "100%" }}>
          <div
            className={
              disablePadding
                ? "snapshot__content snapshot__content--disable"
                : "snapshot__content"
            }
          >
            {React.cloneElement(children, {
              editing: editing,
              doneEditing: setEditing,
            })}
          </div>
        </SimpleBar>
      </div>
      <div
        className="snapshot__background"
        style={{
          background: disableOverlay ? "transparent" : "rgba(0, 0, 0, 0.7)",
        }}
        onClick={onClose}
      ></div>
    </div>,
    document.body
  );
};

const { func, string, bool } = PropTypes;
Snapshot.propTypes = {
  onClose: func.isRequired,
  title: string,
  status: string,
  open: bool,
};

export default Snapshot;
