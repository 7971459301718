import React from "react";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import {
  Portal,
  DialogContent,
  DialogActions,
  CircularProgress,
  Typography,
} from "@mui/material";

import Alert from "common/Alert";
import Dialog from "./Dialog";
import Button from "common/Button";
import ButtonLoader from "common/ButtonLoader";
import { DefaultLoader } from "common/Loader";
import { snackbarEnter } from "../../actions/snackbarActions";

const DialogForm = ({
  open,
  onClose,
  title,
  maxWidth,
  onSubmit,
  fullWidth,
  progress,
  initialValues,
  actions,
  disablePadding,
  children,
  disableActions,
  disableOverlay,
  hideBackdrop,
  loading,
  fullHeight,
  stepper,
  submitText,
  enableCloseOnBackdropClick,
  overlayProgress,
  fillScreen,
  noWhite,
  overrideDirty,
  directSubmit,
  leftAction,
  paperProps,
  ...props
}) => {
  const dispatch = useDispatch();
  const [message, setMessage] = React.useState(null);
  const [container, setContainer] = React.useState(null);

  const handleRef = React.useCallback(
    (instance) => setContainer(instance),
    [setContainer]
  );

  const handleClose = () => {
    setMessage(null);
    onClose();
  };

  const handleSuccess = (msg) => {
    if (msg) {
      dispatch(
        snackbarEnter({
          msg,
          kind: "positive",
        })
      );
      handleClose();
    } else {
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      onClose={handleClose}
      title={title}
      fullWidth={fullWidth}
      progress={progress}
      disableOverlay={disableOverlay}
      hideBackdrop={hideBackdrop}
      fullHeight={fullHeight}
      fillScreen={fillScreen}
      noWhite={noWhite}
      enableCloseOnBackdropClick={enableCloseOnBackdropClick}
      paperProps={paperProps}
    >
      {stepper}
      <DialogContent style={{ padding: disablePadding ? "0" : "8px 18px" }}>
        {loading ? (
          <div>
            <DefaultLoader />
          </div>
        ) : (
          <Formik
            initialValues={initialValues}
            onSubmit={
              (!directSubmit
                ? async (
                    data,
                    { setSubmitting, setFieldValue, setFieldError, resetForm }
                  ) => {
                    await onSubmit(
                      data,
                      setSubmitting,
                      setMessage,
                      handleSuccess,
                      setFieldValue,
                      setFieldError,
                      resetForm
                    );
                  }
                : (
                    data,
                    { setSubmitting, setFieldValue, setFieldError, resetForm }
                  ) => {
                    onSubmit(
                      data,
                      setSubmitting,
                      setMessage,
                      handleSuccess,
                      setFieldValue,
                      setFieldError,
                      resetForm
                    );
                  }
              )
            }
            {...props}
          >
            {({
              values,
              errors,
              isSubmitting,
              validateField,
              setFieldValue,
              submitForm,
              dirty,
              ...rest
            }) => (
              <Form>
                {overlayProgress?.show && (
                  <div
                    style={{
                      position: "absolute",
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                      background: "rgba(245, 242, 247, 0.5)",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 2,
                    }}
                  >
                    <CircularProgress />
                    <Typography variant="h6" style={{ marginTop: "2rem" }}>
                      {overlayProgress.message}
                    </Typography>
                  </div>
                )}
                {message && <Alert kind={message.id}>{message.msg}</Alert>}
                {children({
                  values,
                  errors,
                  isSubmitting,
                  validateField,
                  setFieldValue,
                })}
                <Portal container={container}>
                  <>
                    {leftAction &&
                      <div style={{ flex: "1 0 auto" }} >
                        {leftAction({
                          values,
                          errors,
                          isSubmitting,
                          validateField,
                          setFieldValue,
                        })}
                      </div>
                    }
                    <Button
                      sx={{ marginRight: "1rem" }}
                      disabled={isSubmitting || disableActions}
                      disableElevation
                      size="small"
                      onClick={handleClose}
                      color="error"
                    >
                      Cancel
                    </Button>
                    {actions}
                    <ButtonLoader
                      variant="contained"
                      color="secondary"
                      type="submit"
                      isSubmitting={isSubmitting}
                      disabled={disableActions || (!dirty && !overrideDirty)}
                      disableElevation
                      size="small"
                      onClick={submitForm}
                    >
                      {submitText ? submitText : "Submit"}
                    </ButtonLoader>
                  </>
                </Portal>
              </Form>
            )}
          </Formik>
        )}
      </DialogContent>
      {!loading && (
        <DialogActions
          // sx={{ padding: "1.25rem" }}
          ref={handleRef}
        ></DialogActions>
      )}
    </Dialog>
  );
};

DialogForm.propTypes = {};

export default DialogForm;
