import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import querystring from "querystring";
import { TiLocation } from "react-icons/ti";
// import { CgLoadbar } from "react-icons/cg";
import constants from "components/constants";

import Map from "../Map";
import MapStreetView from "../MapStreetView";
import Button from "common/Button";
// import config from "config";

import {
  Container,
  MapOverlayContainer,
  MapOverlay,
  MapAddress,
  // MapScrollButton,
  AddressContainer,
  Address,
} from "./Styled";

const MapBanner = ({
  onScroll,
  address1,
  address2,
  city,
  state,
  zip,
  geo,
  children,
  compact,
}) => {
  const innerDiv = useRef();
  const containerDiv = useRef();
  const [streetViewLoaded, setStreetViewLoaded] = React.useState(false);
  const browser = useSelector((state) => state.browser);
  let maxHeight = children ? 400 : 350;
  if(compact){
    maxHeight = children ? 250 : 200;
  }
  else{
    if(browser.height < browser.tall){
      maxHeight = children ? 325 : 275;
    }
    if(browser.height < browser.mediumHeight){
      maxHeight = children ? 250 : 200;
    }
  }
  const mobile = browser.is.small || browser.lessThan.small;

  // const handleScrollTo = () => {
  //   const topPos = innerDiv.current.offsetTop;
  //   containerDiv.current.scrollTop = topPos - 10;
  // };

  const addressSearch = `${address1} ${address2} ${city} ${state}, ${zip}`;
  const mapQuery = querystring.stringify({
    query: addressSearch,
  });
  // const streetview = `https://maps.googleapis.com/maps/api/streetview?size=125x100&location=${geo.lat},${geo.lng}&fov=100&pitch=0&key=${config.google_api}`;

  const mapURL = `https://www.google.com/maps/search/?api=1&${mapQuery}`;

  const linkAttr = {};
  if(compact){
    linkAttr.onClick = () => {
      const win = window.open( mapURL, "_blank" );
      if (win != null) {
        win.focus();
      }
    };
    linkAttr.className = "cursor-pointer";
  }

  return (
    <>
    <Container
      onScroll={onScroll}
      ref={containerDiv}
      className={`${compact ? 'compact' : ''}`}
    >
      <MapOverlayContainer >
        <MapOverlay {...linkAttr}>
          {!compact &&
          <MapAddress>
            {/* {(compact || !streetViewLoaded) &&
              <img src={streetview} alt="streetview" />
            } */}
            <AddressContainer>
              <Address>
                <Typography variant="subtitle1" color="textPrimary">
                  {address1}
                </Typography>
                <Typography variant="body2">{address2}</Typography>
                <Typography variant="body2">
                  {city} {state}, {zip}
                </Typography>
              </Address>
              <Button
                color="primary"
                disableElevation
                size="small"
                variant="outlined"
                style={{ marginTop: "1rem" }}
                startIcon={<TiLocation />}
                component="a"
                href={mapURL}
                target="_blank"
              >
                Directions
              </Button>
            </AddressContainer>
          </MapAddress>
          }
        </MapOverlay>
        {/* <MapScrollButton onClick={handleScrollTo}>
          <CgLoadbar />
        </MapScrollButton> */}
        <div
          style={{
            background: children ? "var(--color-gray-background)" : "white",
            // background: "white",
            padding: children ? "0 1.25rem 4rem 1.25rem" : "0 0 2rem 0",
            borderRadius: children ? "18px 18px 0 0" : "0",
            marginTop: children ? "2rem" : "1.5rem",
            marginBottom: children ? '0' : '-1rem',
            position: "relative",
            zIndex: 0,
          }}
          ref={innerDiv}
        >
          {children}
        </div>
      </MapOverlayContainer>
      <div style={{ position: "absolute", top: 0, left: 0, width: (!streetViewLoaded || compact || mobile ? '100%' : '50%') }}>
        <Map
          geo={geo}
          color={constants.colorBlue}
          maxHeight={maxHeight}
          phxStyles
        />
      </div>
      {/* {!compact && */}
        <div
          style={{ position: "absolute", top: 0, right: 0, width: (!streetViewLoaded || compact || mobile ? '0' : '50%') }}
        >
          <MapStreetView
            geo={geo}
            address={addressSearch}
            setLoaded={setStreetViewLoaded}
            maxHeight={maxHeight}
            compact={compact || mobile}
          />
        </div>
      {/* } */}
     </Container>
      </>
  );
};

MapBanner.propTypes = {};

export default MapBanner;
