import React from "react";
import { connect, useSelector } from "react-redux";
import Helmet from "react-helmet";
import { Typography, Button, TextField, FormControlLabel, Checkbox } from "@mui/material";
import { FilterList } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { GetApp } from "@mui/icons-material";
import { Autocomplete,  } from "@mui/material";

import Alert from "common/Alert";
import ButtonDropdown from "common/ButtonDropdown";
import ReportSearch from "./ReportSearch";
import Table, {TableConfigure } from "../Table";
import LinkHeader from "common/LinkHeader";
import { exportBatch, clear } from "../../actions";
import Paper from "../Paper";
import {
  Actions,
  ActionGroup,
  FilterMenu,
  ButtonGroup,
} from "./Styled";
import { formatFilters } from "../../utility";
import {
  statesSelectors,
  countiesSelectors,
} from "../../features/Taxonomies/taxonomiesSlice";
import { getReportMemberCoverageGaps } from "actions/reports";
// import { formatPhoneNumber } from "utility";
import ReadMore from "common/ReadMore";
import { createColumns } from "components/Table/utils";
import constants from "components/constants";

const useStyles = makeStyles((theme) => ({
  button: {
    background: constants.gradientBlack,
    paddingRight: "10px",
    color: constants.colorWhite,
    "&:hover": {
      background: constants.gradientBlack,
      color: constants.colorWhite,
    },
  },
}));

const columnSettings = {
  county: { minWidth: 150 },
  state:  { minWidth: 150 },
  zips: { minWidth: 400 },
  nearest_member_name: { minWidth: 400 },
};

const createData = (
  county,
  state,
  zips,
  row,
  paths
) => {
  const _zips = zips ? zips.replace(/,/g, " ") : '';
  return {
    county,
    state,
    zips: <ReadMore children={_zips} />,
    nearest_member_name: row.nearest_member_name ? (
      <LinkHeader to={paths.nearest_member} size="small" color="default">
        <div>{row.nearest_member_name} - {`(${Math.round(row.nearest_member_distance, 2)} miles away)`}</div>
        <div>{row.nearest_member_status}</div>
      </LinkHeader>
    ) : null,
  };
};

const initialFormData = {
  county: [],
  state: [],
  zips: '',
  has_nearest_member: 0,
};
const defaultSort = {
  order: "state",
  sort: "asc"
};

const ReportMemberCoverageGaps = ({
  loadCounties,
  report=[],
  exportReport,
  clear,
  statuses=[],
  regions=[],
  affiliations=[],
  loading,
  pagination={
    count: 0,
    current_page: 0,
    total_pages: 0,
    display_columns: [],
  },
  error,
  org,
}) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filters, setFilters] = React.useState({});
  const [formData, setFormData] = React.useState(initialFormData);
  const [hiddenColumns, setHiddenColumns] = React.useState([]);
  const states = useSelector(statesSelectors.selectAll);
  const counties = useSelector(countiesSelectors.selectAll);

  const handleExportReport = async () => {
    if(!filters.order){
      Object.assign(filters, defaultSort);
    }
    let filterSettings = formatFilters(filters);

    if (org) {
      filterSettings = {
        ...filterSettings,
        "member_phx_client_nids": org,
      };
    }

    await exportReport("/rest/report/member/coverage/gaps", {
      _export: "csv",
      keywords: searchTerm,
      ...filterSettings,
    });
  };

  const columns = React.useMemo(() => {
    if (loading) return [];
    const temp = createColumns(pagination.display_columns, columnSettings);
    return temp;
  }, [pagination, loading]);

  const rows = React.useMemo(() => {
    if (loading) return [];
    return report.map((row) =>
      createData(
        row.county,
        row.state,
        row.zips,
        row,
        row._paths
      )
    );
  }, [report, loading]);

  const hideColumns = React.useMemo(() => {
    if (loading) return [];
    return hiddenColumns;
  }, [hiddenColumns, loading]);

  const handleChange = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const applyFilters = () => {
    const filters = { ...formData };
    if(formData.zips !== ''){
      const zips = formData?.zips.trim()
                    .replace(/,/g, ' ').split(' ').filter((zip) => zip !== '');
      filters.zips = zips;
    }
    filters.has_nearest_member = null;
    if(formData.has_nearest_member){
      filters.has_nearest_member = [1];
    }
    setFilters(filters);
  };

  const resetFilters = () => {
    setFilters({});
    setFormData({
      ...initialFormData,
    });
  };

  return (
    <div>
      <Helmet>
        <title>Member Coverage Gaps | Helix</title>
        <meta name="description" content="Reports" />
      </Helmet>
      {error && <Alert kind="negative">{error.message}</Alert>}
      <LinkHeader to="/reports" color="default" size="small" back>
        Back to reports
      </LinkHeader>
      <Paper margin="none" style={{ marginTop: "1rem" }}>
        <Actions>
          <Typography variant="h6">Member Coverage Gaps</Typography>
          <ActionGroup>
            <TableConfigure
              columns={columns}
              hideColumns={setHiddenColumns}
              currentColumns={hiddenColumns}
            />
            <ButtonDropdown
              icon={FilterList}
              label="Filters"
              style={{ marginLeft: "1rem" }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              color="tertiary"
            >
              <FilterMenu>
                <Autocomplete
                  options={states.map((state) => ({
                    label: state.label,
                    value: state.value,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "state");
                  }}
                  value={formData?.state}
                />
                <Autocomplete
                  options={counties.map((county) => ({
                    label: `${county.name}, ${county.state_code}`,
                    value: county.tid,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Counties"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "county", true);
                  }}
                  value={formData.county}
                />
                <TextField
                  label="Zip Codes"
                  variant="outlined"
                  margin="normal"
                  size="small"
                  fullWidth
                  onChange={(e) => {
                    handleChange(e.target.value, "zips", true);
                  }}
                  value={formData.zips}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.has_nearest_member}
                      name="has_nearest_member"
                      color="secondary"
                      className="align-middle"
                      onChange={(e, value) => {
                        handleChange(e.target.checked, "has_nearest_member", true);
                      }}
                    />
                  }
                  label="Has a Member Nearby"
                />

                <ButtonGroup>
                  <Button
                    color="default"
                    disableElevation
                    size="small"
                    onClick={resetFilters}
                  >
                    Reset
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    size="small"
                    style={{ marginRight: "1rem" }}
                    onClick={applyFilters}
                  >
                    Apply
                  </Button>
                </ButtonGroup>
              </FilterMenu>
            </ButtonDropdown>
            <Button
              className={classes.button}
              size="small"
              startIcon={<GetApp />}
              onClick={handleExportReport}
            >
              Export
            </Button>
          </ActionGroup>
        </Actions>
        <ReportSearch setQuery={setSearchTerm} />
        <Table
          clientFilter={"member_phx_client_nids"}
          columns={columns}
          rows={rows}
          stickyHeader
          loading={loading}
          hideColumns={hideColumns}
          pageRequest={loadCounties}
          originalOrderBy={defaultSort.order}
          originalOrder={defaultSort.sort}
          filters={filters}
          pagination={pagination}
          search={searchTerm}
          size="small"
        />
      </Paper>
    </div>
  );
};

ReportMemberCoverageGaps.propTypes = {};

const mapStateToProps = (state) => ({
  loading: state.app.report.loading,
  report: state.app.report.data.data,
  pagination: state.app.report.data.pagination,
  error: state.app.report.error,
  statuses: state.app.memberStatus.data,
  regions: state.app.regions.data,
  affiliations: state.app.affiliations.data,
  states: state.app.states.data,
  services: state.app.jobDivisionTypes.data,
});

const mapDispatchToProps = (dispatch) => ({
  loadCounties: (params) => dispatch(getReportMemberCoverageGaps(params)),
  exportReport: (url, params) => dispatch(exportBatch(url, params)),
  clear: () => dispatch(clear("report")),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportMemberCoverageGaps);
