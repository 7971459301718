import React from "react";
import PropTypes from "prop-types";
import { Button as MuiButton } from "@mui/material";

const styles = {
  button: {
    backgroundColor: "var(--color-blue) !important",
    paddingRight: "10px",
    color: "white",
    "&:hover": {
      backgroundColor: "var(--color-blue) !important",
      color: "white",
    },
  },
  buttonPrimary: {
    backgroundColor: "var(--color-primary) !important",
    "&:hover": {
      backgroundColor: "var(--color-primary) !important",
    },
  },
  buttonSecondary: {
    backgroundColor: "var(--color-secondary) !important",
    "&:hover": {
      backgroundColor: "var(--color-blue-dark) !important",
    },
  },
  default: {
    backgroundColor: "var(--color-gray-lighter) !important",
    color: "var(--color-gray-dark) !important",
    "&:hover": {
      backgroundColor: "var(--color-gray-medium) !important",
    },
  },
  buttonDark: {
    backgroundColor: "var(--color-gray-dark) !important",
    paddingRight: "10px",

    color: "white",
    "&:hover": {
      backgroundColor: "var(--color-black)",
      color: "white",
    },
  },
  disabled: {
    backgroundColor: "var(--color-gray-lighter) !important",
  },
};

const Button = ({ children, color="inherit", ...props }) => {
  const colorType = color || "default";

  return (
    <MuiButton
      sx={
        props.disabled
          ? styles.disabled
          : props.variant === "contained" && colorType === "tertiary"
          ? styles.button
          : props.variant === "contained" && colorType === "dark"
          ? styles.buttonDark
          : props.variant === "contained" && colorType === "primary"
          ? styles.buttonPrimary
          : props.variant === "contained" && colorType === "secondary"
          ? styles.buttonSecondary
          : props.variant === "contained"
          ? styles.default
          : null
      }
      color={
        colorType !== "tertiary" && colorType !== "dark" ? colorType : "default"
      }
      {...props}
    >
      {children}
    </MuiButton>
  );
};

const { string } = PropTypes;
Button.propTypes = {
  color: string,
};

export default Button;
