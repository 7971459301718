import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Formik } from "formik";
import { useDropzone } from "react-dropzone";
import { size, toArray } from "lodash";
import { Typography, Button, Fade } from "@mui/material";

import Dialog from "../Dialog";
import { FormSkeleton } from "../Skeletons";
import config from "../../config";
import { MemberFile, FileAddMessage } from "../File";
import Alert from "common/Alert";
import { formatField } from "../../utility";
import ButtonLoader from "common/ButtonLoader";
import { EditForm, FormActions, DropZone } from "./Styled";
import {
  uploadFile,
  uploadFileSetup,
  uploadClear,
} from "../../actions/files/fileActions";
import { postMemberFile } from "../../actions/paragraphs";
import { patchMember, getMemberFileCategories } from "../../actions";
import { getMemberDataSelector } from "features/Member/memberSlice";
import { clientsSelectors } from "features/Clients/clientsSlice";
import {
  prepClientSelectorField,
  prepClientSelectorFieldData,
  validateClientSelectorField,
} from "components/ClientSelector/utils";

const uploadIllustration = `${process.env.PUBLIC_URL}/images/icons/icon-upload--illustration.svg`;

const MemberDocsAdd = ({
  open,
  onClose,
  addFile,
  setUploadFile,
  // fileProgress,
  clearFiles,
  links,
  nid,
  updateMember,
  loadFileCategories,
  loadingCategories,
  categories=[],
  addMemberFile,
  files,
  showSnackbar,
  refresh,
  omitExtraInfo,
}) => {
  const [message, setMessage] = React.useState(null);
  const [buttonText, setButtonText] = React.useState("Add");
  const member = useSelector(getMemberDataSelector);
  const clients = useSelector(clientsSelectors.selectAll);
  // let availableClients = filterMemberClients(member?.field_phoenix_clients, clients);
  const [formData, setFormData] = React.useState({});
  const fileProgress = useSelector((state) => state.app.fileUpload.fileProgress);


  const availableClients = prepClientSelectorField(null, member, clients);

  useEffect(() => {
    if (open) {
      loadFileCategories();
    }
  }, [open, loadFileCategories]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let items = {};
    for (let id in fileProgress) {
      items[id] = {
        category:
          categories[0] && categories[0].children && categories[0].children[0]
            ? categories[0].children[0].tid
            : !size(categories[0].children) ? categories[0].tid : "",
        expiration: null,
      };
      availableClients.forEach((client) => {
        items[id][`phx_client_nid_${client.nid}`] = true;
      });
    }

    setFormData({ ...formData, ...items });
  }, [fileProgress]); // eslint-disable-line react-hooks/exhaustive-deps

  const onDrop = (acceptedFiles) => {
    setUploadFile(acceptedFiles);
  };

  const handleExit = () => {
    setMessage(null);
    setButtonText("Add");
    clearFiles();
    if(!size(fileProgress)){
      onClose();
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openFile,
  } = useDropzone({
    onDrop,
    noClick: true,
    accept:
      ".jpg,.jpeg,.gif,.png,.txt,.doc,.docx,.xls,.xlsx,.pdf,.ppt,.pps,.odt,.ods,.odp,.zip,.tmproject,.esx,.bdata,.xml,.webloc",
  });

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      onClose={onClose}
      title="Add File"
      TransitionProps={{
        onExited: handleExit,
      }}
    >
      {loadingCategories ? (
        <FormSkeleton />
      ) : (
        <Formik
          initialValues={{}}
          validate={(values, props) => {
            setMessage(null);
            const errors = {};

            // let checked = false;
            Object.entries(formData).forEach(([id, value]) => {
              validateClientSelectorField(availableClients, value, errors, setMessage);
              // availableClients.forEach((client) => {
              //   //  Ignore if only one client, we'll set that later
              //   if(availableClients.length === 1 || value[`phx_client_nid_${client.nid}`]){
              //     checked = true;
              //   }
              // });
            });
            // if(!checked){
            //   errors['phx_client_nid_'] = '';
            //   setMessage({
            //     id: "negative",
            //     msg: 'Please select at least one client',
            //   });
            // }
            return errors;
          }}
          onSubmit={async (data, { setSubmitting, setSuccess }) => {
            setSubmitting(true);
            setMessage(null);
            setButtonText("Add");

            const fileToUpload = toArray(fileProgress).filter(
              (file) => file.progress === 0
            );

            const responses = await addFile(
              fileToUpload,
              "paragraph",
              "member_doc",
              "field_file"
            );

            let successfulUploads = 0;
            for (let i = 0; i < responses.length; i += 1) {
              const response = responses[i];
              if (response.status === 201) {
                const params = {
                  _links: {
                    type: {
                      href: `${config.api_url}/rest/type/paragraph/member_doc`,
                    },
                  },
                  _meta: {
                    parent_entity: "node",
                    parent_field: "field_member_docs",
                    parent_id: nid,
                  },
                  field_member_doc_category: [
                    { target_id: formData[i + 1].category },
                  ],
                  field_file: [{ target_id: formatField(response, "fid") }],
                  field_member_doc_expiration: [
                    { value: formData[i + 1].expiration },
                  ],
                };
                prepClientSelectorFieldData(availableClients, params, formData[i + 1]);
                // availableClients.forEach((client) => {
                //   //  Force the file to be assigned to the user's client if they only have one client
                //   if(availableClients.length === 1 || formData[i + 1][`phx_client_nid_${client.nid}`]){
                //     if(!params.field_phoenix_clients){
                //       params.field_phoenix_clients = [];
                //     }
                //     params.field_phoenix_clients.push({'target_id': client.nid});
                //   }
                // });
                await addMemberFile(params);

                successfulUploads += 1;
              } else {
                setMessage({
                  id: "negative",
                  msg: "There was an error uploading a file.",
                });
                setButtonText("Retry");
              }
            }

            // const response = await updateMember(nid, params);
            if (successfulUploads === size(responses)) {
              await refresh();
              showSnackbar({
                msg: "Files added successfully.",
                kind: "positive",
              });
              clearFiles();
              onClose();
            } else if (successfulUploads !== size(responses)) {
              setMessage({
                id: "negative",
                msg: "There was an error uploading a file.",
              });
            } else {
              setMessage({
                id: "negative",
                msg: "There was an error uploading a file.",
              });
            }
          }}
        >
          {({ values, errors, isSubmitting, setFieldValue }) => (
            <EditForm {...getRootProps()}>
              <input {...getInputProps()} />
              {message && <Alert kind={message.id}>{message.msg}</Alert>}
              <Fade in={isDragActive}>
                <DropZone>
                  <img src={uploadIllustration} alt="Upload" />
                  <Typography variant="h6">Drop files to upload.</Typography>
                </DropZone>
              </Fade>
              <div>
                {size(fileProgress) ? (
                  toArray(fileProgress).map((file) => (
                    <MemberFile
                      key={file.id}
                      file={file}
                      isSubmitting={isSubmitting}
                      options={categories}
                      formData={formData}
                      setFormData={setFormData}
                      availableClients={availableClients}
                      omitExtraInfo={omitExtraInfo}
                    />
                  ))
                ) : (
                  <FileAddMessage openFile={openFile} />
                )}
              </div>
              <FormActions>
                <Button
                  disableElevation
                  onClick={handleExit}
                  size="small"
                  component="a"
                >
                  Cancel
                </Button>
                <ButtonLoader
                  variant="contained"
                  color="primary"
                  type="submit"
                  isSubmitting={isSubmitting}
                  disableElevation
                  size="small"
                >
                  {buttonText}
                </ButtonLoader>
              </FormActions>
            </EditForm>
          )}
        </Formik>
      )}
    </Dialog>
  );
};

MemberDocsAdd.propTypes = {};

const mapStateToProps = (state) => ({
  // fileProgress: state.app.fileUpload.fileProgress,
  // categories: state.app.memberFileCategories.data,
  loadingCategories: state.app.memberFileCategories.loading,
});

const mapDispatchtoProps = (dispatch) => ({
  addFile: (path, filename, entity, bundle, field) =>
    dispatch(uploadFile(path, filename, entity, bundle, field)),
  setUploadFile: (files) => dispatch(uploadFileSetup(files)),
  clearFiles: () => dispatch(uploadClear()),
  updateMember: (nid, params) => dispatch(patchMember(nid, params)),
  loadFileCategories: () => dispatch(getMemberFileCategories()),
  addMemberFile: (params) => dispatch(postMemberFile(params)),
});

export default connect(mapStateToProps, mapDispatchtoProps)(MemberDocsAdd);
