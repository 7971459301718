import React from "react";
import PropTypes from "prop-types";
import { Typography, Popover } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import classNames from "classnames";

import "./LinkDropdown.scss";

const LinkDropdown = ({ label, id, children, loading, color, open, setOpen, ...rest }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if(setOpen){
      setOpen(event.currentTarget);
    }
    else{
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    if(setOpen){
      setOpen(false);
    }
    else{
      setAnchorEl(null);
    }
  };

  const classes = classNames("link-dropdown", {
    "link-dropdown--open": Boolean(anchorEl),
  });

  return (
    <div className={classes}>
      <Typography
        variant="h6"
        className={`link-dropdown__title ${rest.className}`}
        aria-controls={id}
        aria-haspopup="true"
        color={loading ? "textSecondary" : color ? color : "textPrimary"}
        onClick={loading ? null : handleClick}
        {...rest}
      >
        {label}
        <ExpandMore />
      </Typography>
      <Popover
        id={id}
        open={open || Boolean(anchorEl)}
        anchorEl={open ? open : anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {children}
      </Popover>
    </div>
  );
};

const { string, element, oneOfType, array } = PropTypes;
LinkDropdown.propTypes = {
  label: string,
  id: string,
  children: oneOfType([element, array]),
};

export default LinkDropdown;
