import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Item = styled.div`
  margin-right: 0.875rem;

  &:last-child {
    margin-right: 0;
  }

  .MuiButtonBase-root {
    background-color: ${({ theme }) => theme};

    &:hover {
      background-color: ${({ theme }) => theme};
    }
  }
`;
