import styled from "styled-components";

export const Action = styled.div`
  height: 64px;
  margin-top: 0.25em;
  margin-left: 0.5em;
  border: 1px solid var(--color-gray-lighter);
  background-color: var(--color-gray-light);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-gray-dark);
  cursor: pointer;
  transition: background-color 0.25s;

  &:hover {
    background-color: var(--color-gray-lighter);
    border: 1px solid var(--color-gray-medium);
  }
`;
