import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  DialogContent,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import moment from "moment";
import { BiCalendarPlus } from "react-icons/bi";

import { DateFormField } from "common/Fields";
import { DialogForm } from "common/Dialog";

import { getDivisionDataSelector, patchJobDivision } from "../jobSlice";
import Guard from "common/Guard";

const FileFollowUpDate = ({ children }) => {
  const dispatch = useDispatch();
  const division = useSelector(getDivisionDataSelector);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = (data) => {
    dispatch(
      patchJobDivision({
        id: division.nid,
        params: {
          field_file_followup_date: [
            { value: moment(data.field_file_followup_date).format() },
          ],
        },
        division,
      })
    );

    onClose();
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Guard intendedRoles={["phx_sub_admin", "admin"]}>
      {children ? (
        React.cloneElement(children, {
          onClick: handleOpen,
        })
      ) : (
        <ListItem button onClick={handleOpen}>
          <ListItemIcon>
            <BiCalendarPlus />
          </ListItemIcon>
          <ListItemText primary="File Follow Up" />
        </ListItem>
      )}
      <DialogForm
        open={open}
        onClose={onClose}
        maxWidth="xs"
        title="Follow Up"
        initialValues={{
          field_file_followup_date: division.field_file_followup_date
            ? moment(division.field_file_followup_date)
            : moment(),
        }}
        disablePadding
        overrideDirty
        onSubmit={handleSubmit}
      >
        {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
          <DialogContent>
            <DateFormField
              fullWidth
              disableToolbar
              variant="inline"
              format="MM/DD/YYYY"
              id="field_file_followup_date"
              label="Date"
              margin="normal"
              size="small"
              name="field_file_followup_date"
              inputVariant="filled"
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </DialogContent>
        )}
      </DialogForm>
    </Guard>
  );
};

FileFollowUpDate.propTypes = {};

export default FileFollowUpDate;
