import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";
import { addLinkEntities } from "./plugins/LinkifyPlugin";
import { addVideoLinkEntities } from "./plugins/VideoPlugin";
// import { addImgurLinkEntities } from "./plugins/ImgurPlugin";
import { size, toArray } from "lodash";

export const YOUTUBEMATCH_URL =
  /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
export const VIMEOMATCH_URL =
  /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/; // eslint-disable-line no-useless-escape
export const IMGURMATCH_URL = /https?:\/\/imgur\.com\/a\/(.*?)(?:[#\/].*|$)/; // eslint-disable-line no-useless-escape

export function isYoutube(url) {
  return YOUTUBEMATCH_URL.test(url);
}
export function isVimeo(url) {
  return VIMEOMATCH_URL.test(url);
}
export function isImgur(url) {
  return IMGURMATCH_URL.test(url);
}

export function getYoutubeSrc(url) {
  const id = url.match(YOUTUBEMATCH_URL) && url.match(YOUTUBEMATCH_URL)[1];
  return {
    srcID: id,
    srcType: "youtube",
    url,
  };
}
export function getVimeoSrc(url) {
  const id = url.match(VIMEOMATCH_URL) && url.match(VIMEOMATCH_URL)[3];
  return {
    srcID: id,
    srcType: "vimeo",
    url,
  };
}
export function getImgurSrc(url) {
  const id = url.match(IMGURMATCH_URL) && url.match(IMGURMATCH_URL)[1];
  return {
    srcID: id,
    srcType: "imgur",
    url,
  };
}

const customEntityTransform = (entity, text) => {
  // if (entity.type === "draft-js-video-plugin-video") {
  // return `<iframe frameborder="0" allowfullscreen src="${entity.data.src}" style="width: 100%;max-width:350px;height:250px;" />`;
  if (entity.type === "draft-js-imgur-plugin-imgur") {
    return `<blockquote class="imgur-embed-pub" lang="en" data-id="a/${entity.data.id}"  ><a href="//imgur.com/a/${entity.data.id}">Podium Card Reader</a></blockquote><script async src="//s.imgur.com/min/embed.js" charset="utf-8"></script>`;
  } else if (entity.type === "mention") {
    if (entity.data.mention.link === "job") {
      return `<span class="mention" data-id="job_division-${entity.data.mention.nid}">@${text}</span>`;
    } else {
      return `<a href="${entity.data.mention.link}" target="_blank" style="text-decoration: none;"><span class="mention" data-id="user-${entity.data.mention.uid}">@${text}</span></a>`;
    }
  } else {
    return;
  }
};

export const getContentAsHTML = (editorState) => {
  // const parsedImgurEditorState = addImgurLinkEntities(editorState);
  const parsedVideoEditorState = addVideoLinkEntities(editorState);
  const parsedLinkEditorState = addLinkEntities(parsedVideoEditorState);
  const contentState = parsedLinkEditorState.getCurrentContent();
  const rawContentState = convertToRaw(contentState);

  const html = draftToHtml(rawContentState, null, null, customEntityTransform);

  return html;
};

export const getMentionUids = (data) => {
  if (size(toArray(data)) > 0) {
    const mentions = toArray(data).filter((d) => d.type === "mention");

    return mentions.map((mention) => mention.data.mention.uid);
  } else {
    return [];
  }
};

export const getMentionEntityData = (editorState) => {
  const contentState = editorState.getCurrentContent();
  const rawContentState = convertToRaw(contentState);

  return getMentionUids(rawContentState.entityMap);
};
