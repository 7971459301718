import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Formik } from "formik";
import { Button, MenuItem, ListSubheader, Typography } from "@mui/material";
import moment from "moment";
import { size, toArray } from "lodash";
import { useDropzone } from "react-dropzone";

import { WorkareaFile } from "../File";
import FileIcon from "../FileIcon";
import { DateFormField } from "common/Fields";
import { SelectFormField } from "common/Fields";
import Dialog from "../Dialog";
import { FormSkeleton } from "../Skeletons";
import Alert from "common/Alert";
import {
  formatField,
  formatError,
  formatErrorResponseString,
  getTaxonomyFields,
  formatFileSize,
} from "../../utility";
import ButtonLoader from "common/ButtonLoader";
import { EditForm, FormActions } from "./Styled";
import { getMemberFileCategories } from "../../actions";
import { patchMemberFile } from "../../actions/paragraphs";
import { getMemberDataSelector } from "features/Member/memberSlice";
import { clientsSelectors } from "features/Clients/clientsSlice";
import {
  uploadFileSetup,
  uploadFile,
  uploadClear,
} from "../../actions/files/fileActions";
import { ClientSelectorField } from "components/ClientSelector/ClientSelectorField";
import {
  prepClientSelectorField,
  prepClientSelectorFieldData,
  validateClientSelectorField,
} from "components/ClientSelector/utils";

const MemberDocsEdit = ({
  open,
  onClose,
  fileProgress,
  loadFileCategories,
  loadingCategories,
  categories=[],
  file,
  showSnackbar,
  updateFile,
  refresh,
  setUploadFile,
  addFile,
  clearFiles,
  omitExtraInfo,
}) => {
  const [message, setMessage] = React.useState(null);
  const [formData, setFormData] = React.useState({});
  const [removedFile, setRemovedFile] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);
  const member = useSelector(getMemberDataSelector);
  const clients = useSelector(clientsSelectors.selectAll);
  const uploadedFileAmount = size(fileProgress);
  const onDrop = (acceptedFiles) => {
    setUploadFile(acceptedFiles);
  };

  const {
    getRootProps,
    getInputProps,
    open: openFile,
  } = useDropzone({
    accept:
      ".jpg,.jpeg,.gif,.png,.txt,.doc,.docx,.xls,.xlsx,.pdf,.ppt,.pps,.odt,.ods,.odp,.zip,.tmproject,.esx,.bdata,.xml,.webloc",
    multiple: false,
    onDrop,
    noClick: true,
  });

  // let availableClients = filterMemberClients(member?.field_phoenix_clients, clients);

  useEffect(() => {
    return () => {
      clearFiles();
    };
  }, [clearFiles]);

  useEffect(() => {
    const fileToUpload = toArray(fileProgress).filter(
      (file) => file.progress === 0
    );

    if (open) {
      asyncAddFile();
    }

    async function asyncAddFile() {
      setIsUploading(true);
      await addFile(fileToUpload, "paragraph", "member_doc", "field_file");
      setIsUploading(false);
    }
  }, [uploadedFileAmount]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (open) {
      loadFileCategories();
    }
  }, [open, loadFileCategories]);

  useEffect(() => {
    let items = {};
    for (let id in fileProgress) {
      items[id] = {
        category: categories[0] ? categories[0].tid : "",
        expiration: null,
      };
    }

    setFormData({ ...formData, ...items });
  }, [fileProgress]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleExit = () => {
    setMessage(null);
    setRemovedFile(false);
    clearFiles();
  };

  const expirationDate = formatField(file, "field_member_doc_expiration");
  const initialValues = {
    category: file ? getTaxonomyFields(file, "field_member_doc_category").tid : '',
    expiration: expirationDate ? moment(expirationDate) : '',
  };
  // const fileClients = (file?.field_phoenix_clients ? getPhoenixClients(file.field_phoenix_clients) : []);
  // availableClients.forEach((client) => {
  //   let clientNid = false;
  //   fileClients.forEach((fileClient) => {
  //     if (fileClient.nid === client.nid) {
  //       clientNid = true;
  //       return false;
  //     }
  //   });
  //   initialValues[`phx_client_nid_${client.nid}`] = clientNid;
  // });
  const availableClients = prepClientSelectorField(file, member, clients, initialValues);

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      onClose={() => {
        handleExit();
        onClose();
      }}
      title="Edit File"
    >
      {loadingCategories ? (
        <FormSkeleton />
      ) : (
        <Formik
          initialValues={initialValues}
          validate={(values, props) => {
            setMessage(null);
            const errors = {};

            validateClientSelectorField(availableClients, values, errors, setMessage);
            // let checked = false;
            // availableClients.forEach((client) => {
            //   //  Ignore if only one client, we'll set that later
            //   if(availableClients.length === 1 || values[`phx_client_nid_${client.nid}`]){
            //     checked = true;
            //   }
            // });
            // if(!checked){
            //   errors['phx_client_nid_'] = '';
            //   setMessage({
            //     id: "negative",
            //     msg: 'Please select at least one client',
            //   });
            // }
            return errors;
          }}
          onSubmit={async (data, { setSubmitting, setSuccess }) => {
            setSubmitting(true);
            setMessage(null);

            const params = {
              _links: {
                type: file._links.type,
              },
              field_file:
                toArray(fileProgress) &&
                toArray(fileProgress)[0] &&
                toArray(fileProgress)[0].response &&
                toArray(fileProgress)[0].response.data &&
                toArray(fileProgress)[0].response.data.fid &&
                toArray(fileProgress)[0].response.data.fid[0] &&
                toArray(fileProgress)[0].response.data.fid[0].value
                  ? [
                      {
                        target_id:
                          toArray(fileProgress)[0].response.data.fid[0].value,
                      },
                    ]
                  : removedFile
                  ? []
                  : file && file.field_file && file.field_file[0]
                  ? [
                      {
                        target_id: formatField(file.field_file[0], "fid"),
                      },
                    ]
                  : [],
              field_member_doc_expiration: [
                {
                  value: data.expiration
                    ? moment(data.expiration).format("YYYY-MM-DD")
                    : null,
                },
              ],
              field_member_doc_category: [{ target_id: data.category }],
            };

            prepClientSelectorFieldData(availableClients, params, data);
            // availableClients.forEach((client) => {
            //   //  Force the file to be assigned to the user's client if they only have one client
            //   if(availableClients.length === 1 || data[`phx_client_nid_${client.nid}`]){
            //     if(!params.field_phoenix_clients){
            //       params.field_phoenix_clients = [];
            //     }
            //     params.field_phoenix_clients.push({'target_id': client.nid});
            //   }
            // });

            const response = await updateFile(formatField(file, "id"), params);
            if (response.status === 200) {
              await refresh();
              showSnackbar({
                msg: "File updated successfully.",
                kind: "positive",
              });
              handleExit();
              onClose();
            } else {
              const errorMessage = formatError(response);
              const newMessage = formatErrorResponseString(errorMessage.msg);
              errorMessage.msg = newMessage;
              setMessage(errorMessage);
            }
          }}
        >
          {({ values, errors, isSubmitting, setFieldValue }) => (
            <EditForm {...getRootProps()}>
              <input {...getInputProps()} />
              {message && <Alert kind={message.id}>{message.msg}</Alert>}
              {uploadedFileAmount ? (
                <WorkareaFile file={toArray(fileProgress)[0]} />
              ) : removedFile ? (
                <Button onClick={openFile} variant="contained" disableElevation>
                  Add File
                </Button>
              ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FileIcon
                    filename={
                      file && file.field_file && file.field_file[0]
                        ? formatField(file.field_file[0], "filename")
                        : null
                    }
                  />
                  <div style={{ flex: 1 }}>
                    <Typography
                      variant="subtitle1"
                      noWrap
                      style={{ maxWidth: "215px", lineHeight: "1rem" }}
                    >
                      {file && file.field_file && file.field_file[0]
                        ? formatField(file.field_file[0], "filename")
                        : null}
                    </Typography>
                    <Typography variant="caption">
                      {file && file.field_file && file.field_file[0]
                        ? formatFileSize(
                            formatField(file.field_file[0], "filesize")
                          )
                        : null}
                      {" | "}
                      {moment(formatField(file, "created")).format(
                        "MMM Do, YYYY h:mm A"
                      )}
                    </Typography>
                  </div>
                  <Button onClick={() => setRemovedFile(true)}>Remove</Button>
                </div>
              )}
              {!omitExtraInfo &&
                <>
                <SelectFormField
                  fullWidth
                  name="category"
                  label="Category"
                  margin="normal"
                  required
                  disabled={isSubmitting}
                  autoFocus
                >
                  {categories.map((category) =>
                    category.children.length > 0 ? (
                      [
                      <ListSubheader>{category.name}</ListSubheader>,
                      category.children.map((option) => (
                        <MenuItem key={option.tid} value={option.tid}>
                          {option.name}
                        </MenuItem>
                      ))
                      ]
                    ) : (
                      [
                      <MenuItem key={category.tid} value={category.tid}>
                        {category.name}
                      </MenuItem>
                      ]
                    )
                  )}
                </SelectFormField>
                <DateFormField
                  fullWidth
                  clearable="true"
                  disableToolbar
                  variant="inline"
                  format="MM/DD/YYYY"
                  id="expiration"
                  label="Expires"
                  margin="normal"
                  name="expiration"
                  KeyboardButtonProps={{
                    "aria-label": "change expiration date",
                  }}
                />
                </>
              }
              <ClientSelectorField
                clients={availableClients}
                disabled={isSubmitting}
              />
              {/* {availableClients.length > 1 && (
                availableClients.map((client) => (
                  <CheckboxFormField
                    name={`phx_client_nid_${client.nid}`}
                    ignoreErrors={true}
                    key={client.nid}
                    value={client.nid}
                    label={client.title}
                  />
                ))
              )} */}
              <FormActions>
                <Button
                  disableElevation
                  onClick={() => {
                    handleExit();
                    onClose();
                  }}
                  size="small"
                  component="a"
                >
                  Cancel
                </Button>
                <ButtonLoader
                  variant="contained"
                  color="primary"
                  type="submit"
                  isSubmitting={isSubmitting}
                  disabled={isUploading}
                  disableElevation
                  size="small"
                >
                  Edit
                </ButtonLoader>
              </FormActions>
            </EditForm>
          )}
        </Formik>
      )}
    </Dialog>
  );
};

MemberDocsEdit.propTypes = {};

const mapStateToProps = (state) => ({
  fileProgress: state.app.fileUpload.fileProgress,
  // categories: state.app.memberFileCategories.data,
  loadingCategories: state.app.memberFileCategories.loading,
});

const mapDispatchtoProps = (dispatch) => ({
  updateFile: (id, params) => dispatch(patchMemberFile(id, params)),
  loadFileCategories: () => dispatch(getMemberFileCategories()),
  setUploadFile: (files, field) => dispatch(uploadFileSetup(files, field)),
  clearFiles: () => dispatch(uploadClear()),
  addFile: (path, filename, entity, bundle, field) =>
    dispatch(uploadFile(path, filename, entity, bundle, field)),
});

export default connect(mapStateToProps, mapDispatchtoProps)(MemberDocsEdit);
