import React, { useEffect } from "react";
import { Tooltip, Collapse, } from "@mui/material";
import { useWindowSize } from "hooks";
import { pxNum } from "utility";
import constants from "components/constants";
import MapBanner from "common/MapBanner";
import { BsMap, BsCaretDown, BsCaretUp } from "react-icons/bs";

const TimeClockCustomer = ({timeClock, isSubmitting, setFieldValue, values}) => {
  const windowResized = useWindowSize(true);
  const [autoExpandMap, setAutoExpandMap] = React.useState(false);
  const [expandMap, setExpandMap] = React.useState(false);

  useEffect(() => {
    if(pxNum(constants.breakpoints.short) < window.innerHeight){
      setAutoExpandMap(true);
    }
    else{
      setAutoExpandMap(false);
    }
  }, [windowResized]); // eslint-disable-line react-hooks/exhaustive-deps

  let customerInfo = null;
  if(timeClock.division_customer){
    customerInfo = timeClock.division_customer;
    if(timeClock.division_customer_location && timeClock.division_customer_location !== timeClock.division_customer){
      customerInfo += ' - ' + timeClock.division_customer_location;
    }
    if(timeClock.division_customer_location_address){
      customerInfo += ' - ' + timeClock.division_customer_location_address_string;
    }
  }

  return (
    (customerInfo && timeClock.division_customer_location_address) &&
      <>
      <div className="customer-header">
        <p className="info">{customerInfo}</p>
        {!autoExpandMap &&
          <Tooltip title={!expandMap ? "View Job Location on a Map" : "Close the Map"} >
            <div
              className="expander"
              onClick={() => setExpandMap(!expandMap)}
            >
              <BsMap className="icon" />
              {!expandMap ? (
                <BsCaretDown className="icon" />
              ) : (
                <BsCaretUp className="icon" />
              )}
            </div>
          </Tooltip>
        }
      </div>

      <Collapse
        in={autoExpandMap || expandMap}
        className="map-collapser"
      >
        <MapBanner
          geo={{
            lat: timeClock?.division_customer_location_address_coords?.lat,
            lng: timeClock?.division_customer_location_address_coords?.lon,
          }}
          address1={timeClock?.division_customer_location_address?.address_line1}
          address2={timeClock?.division_customer_location_address?.address_line2}
          city={timeClock?.division_customer_location_address?.locality}
          state={timeClock?.division_customer_location_address?.administrative_area}
          zip={timeClock?.division_customer_location_address?.postal_code}
          compact
        />
      </Collapse>
      </>
  );
};

TimeClockCustomer.propTypes = {};

export default TimeClockCustomer;
