import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import constants from "../constants";

import { Item } from "./Styled";

const NestedTab = ({ children, active, value, onClick, ...props }) => {
  const bkg =
    active === value ? constants.colorSecondaryFaded : "rgba(0,0,0,0)";
  return (
    <Item theme={bkg}>
      <Button
        color={active === value ? "secondary" : "default"}
        onClick={(event) => {
          onClick(event, value);
        }}
        {...props}
      >
        {children}
      </Button>
    </Item>
  );
};

const { string, number, func } = PropTypes;
NestedTab.propTypes = {
  children: string,
  active: number,
  value: number,
  onClick: func,
};

export default NestedTab;
