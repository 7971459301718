import moment from "moment";

export const getTypeLabel = (type) => {
  switch (type) {
    case "text":
      return "Short Answer";
    case "select":
      return "Dropdown";
    case "checkbox":
      return "Checkbox";
    case "signature":
      return "Signature";
    case "date":
      return "Date";
    case "datetime":
      return "Date & Time";
    case "time":
      return "Time";
    case "paragraph":
      return "Paragraph";
    default:
      return type;
  }
};

export const previewData = () => {
  return {
    division: {
      title: "22-1234-WTR",
      field_loss_date: "1/1/2022",
      field_start_date: "1/1/2022",
      field_completion_date: "1/1/2022",
      field_eta_datetime: "1/1/2022",
      field_job_division_type: "Water",
      field_source_loss: "Freeze",
      field_cause: "Sewage",
    },
    member: {
      title: "Helix Member",
      field_street_address: {
        address_line1: "555 Market Ave",
        address_line2: "Suite 2",
        locality: "Canton",
        administrative_area: "OH",
        postal_code: "44702",
        country_code: "US",
      },
      field_phone: "330.867.5309",
    },
    customer: {
      title: "Helix Customer",
      field_street_address: {
        address_line1: "555 Market Ave",
        address_line2: "Suite 2",
        locality: "Canton",
        administrative_area: "OH",
        postal_code: "44702",
        country_code: "US",
      },
      field_phone: "330.867.5309",
    },
    location: {
      title: "Helix Location",
      field_street_address: {
        address_line1: "555 Market Ave",
        address_line2: "Suite 2",
        locality: "Canton",
        administrative_area: "OH",
        postal_code: "44702",
        country_code: "US",
      },
    },
  };
};

export const formatStreetAddress = (address) => {
  if (!address) return "";

  return `${address.address_line1}, ${address.address_line2}\n${address.locality} ${address.administrative_area}, ${address.postal_code}`;
};

const formatDate = (value) => {
  if (!value) return "##-##-####";
  else {
    return moment(value).format("MM-DD-YYYY");
  }
};

const formatDateTime = (value) => {
  if (!value) return "##-##-#### ##:##";
  else {
    return moment(value).format("MM-DD-YYYY hh:mm a");
  }
};

const hashTagsValues = (division, member, customer, location) => {
  return {
    "#jobname": division?.title,
    "#lossdate": formatDate(division?.field_loss_date),
    "#startdate": formatDate(division?.field_start_date),
    "#completiondate": formatDate(division?.field_completion_date),
    "#eta": formatDateTime(division?.field_eta_datetime),
    "#division": division?.field_job_division_type?.name,
    "#source": division?.field_source_loss?.name,
    "#cause": division?.field_cause ? division?.field_cause : "Undefined",
    "#membername": member?.title,
    "#memberaddress": formatStreetAddress(member?.field_street_address),
    "#memberphone": member?.field_phone,
    "#customername": customer?.title,
    "#customeraddress": formatStreetAddress(customer?.field_street_address),
    "#customerphone": customer?.field_phone,
    "#locationname": location?.title,
    "#locationaddress": formatStreetAddress(location?.field_street_address),
  };
};

export const replaceHashtags = (division, member, customer, location, str) => {
  const string = str;
  if (string && typeof string === "string") {
    const regex = /#+([a-zA-Z0-9_]+)/gi;
    return string.replace(regex, (value) => {
      const newValue = hashTagsValues(division, member, customer, location)[
        value
      ];
      return `${newValue}`;
    });
  } else {
    return `${string}`;
  }
};

const getNumColumns = (value) => {
  if (value.field.column_size === 6) {
    return 2;
  } else {
    return 3;
  }
};

const getValue = (value) => {
  if (value.field.type.name === "signature") {
    if(value.value){
      return {
        image: value.value,
        width: 200,
      };
    }
  } else if (value.field.type.name === "paragraph") {
    return {
      text: "",
    };
  } else {
    return {
      text: value.value,
    };
  }
};

const getBorder = (value) => {
  if (value.field.type.name === "signature") {
    return {
      canvas: [
        { type: "line", x1: 0, y1: -10, x2: 250, y2: -10, lineWidth: 1 }, //Bottom line
      ],
    };
  } else {
    return null;
  }
};

const getLabel = (value) => {
  return {
    text: value.label,
    bold: true,
    margin: [0, 0, 0, 10],
  };
};

const formatValues = (values) => {
  let rows = [];
  let columns = [];
  let colsize = null;
  [...values]
    .sort((a, b) => a.field.order - b.field.order)
    .forEach((value, index) => {
      const formattedValue = getValue(value);
      if(formattedValue){
        if (value.field.column_size === 12) {
          rows.push([
            getLabel(value),
            formattedValue,
            getBorder(value),
            {
              text: value.description !== "null" ? value.description : "",
              margin: [0, 0, 0, 20],
            },
          ]);
        } else {
          if (!colsize) {
            colsize = getNumColumns(value);
          }
          if (columns.length < colsize - 1 && values.length - 1 !== index) {
            columns.push([
              getLabel(value),
              formattedValue,
              getBorder(value),
              { text: value.description !== "null" ? value.description : "" },
            ]);
          } else {
            columns.push([
              getLabel(value),
              formattedValue,
              getBorder(value),
              { text: value.description !== "null" ? value.description : "" },
            ]);
            rows.push({
              margin: [0, 0, 0, 20],
              columns,
            });
            colsize = getNumColumns(value);
            columns = [];
          }
        }
      }
    });

  return rows;
};

const getHeaderLogo = (logo) => {
  if (!logo) return null;

  const image = `data:${logo.mime_type};base64,${logo.data}`;

  return {
    image,
    width: 100,
    margin: [0, 0, 0, 10],
  };
};

const getAddress = (address, phone) => {
  if (!address?.address_line1) return {};

  return {
    style: "tableNormal",
    layout: "noBorders",
    table: {
      widths: ["*"],
      body: [
        [
          {
            text: address?.address_line1,
            alignment: "right",
          },
        ],
        [
          {
            text: address?.address_line2,
            alignment: "right",
          },
        ],
        [
          {
            text: `${address?.locality} ${address?.administrative_area}, ${address?.postal_code}`,
            alignment: "right",
          },
        ],
        [
          {
            text: phone?.number,
            alignment: "right",
          },
        ],
      ],
    },
  };
};

const docDefinition = (fields) => {
  const { name, description, address, phone, values, logo } = fields;

  return {
    pageMargins: [20, 30, 20, 30],
    pageSize: "LETTER",
    background: [
      {
        svg: `<svg width="612" height="792" viewBox="0 0 612 792" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_5_3)">
        <rect width="612" height="792" fill="white"/>
        <g opacity="0.1">
        <path d="M-574.953 -98.4861L361.361 884.98C391.476 916.109 432.2 933.711 474.736 933.986H891.469L-45.9783 -50.6554C-60.9992 -66.4143 -78.912 -78.8985 -98.6615 -87.3724C-118.411 -95.8464 -139.597 -100.139 -160.973 -99.9966L-574.953 -98.4861Z" fill="#979797"/>
        <path d="M736.955 -99.1575L-195.309 886.827C-210.095 902.048 -227.673 914.048 -247.005 922.118C-266.338 930.189 -287.034 934.167 -307.874 933.818H-666.138L248.31 -27.1597C278.598 -59.0469 335.609 -99.1575 379.501 -98.8219L736.955 -99.1575Z" fill="#636363"/>
        <path d="M-786.64 -99.1573L150.484 884.813C165.33 900.324 182.99 912.64 202.447 921.05C221.905 929.461 242.776 933.8 263.859 933.818H565.922L-370.23 -50.6553C-385.195 -66.294 -403.047 -78.6485 -422.721 -86.9816C-442.395 -95.3147 -463.487 -99.4557 -484.739 -99.1573H-786.64Z" fill="#C7C7C7"/>
        </g>
        </g>
        <defs>
        <clipPath id="clip0_5_3">
        <rect width="612" height="792" fill="white"/>
        </clipPath>
        </defs>
        </svg>`,
        width: 612,
      },
    ],
    content: [
      {
        columns: [getHeaderLogo(logo), getAddress(address, phone)],
      },
      {
        style: "formHeading",
        layout: "noBorders",
        table: {
          widths: ["*"],
          body: [
            [
              {
                text: name,
              },
            ],
            [
              {
                text: description,
                fontSize: 12,
              },
            ],
          ],
        },
      },
      ...formatValues(values),
    ],
    styles: {
      formHeading: {
        fontSize: 18,
        bold: true,
        color: "black",
        margin: [0, 40, 0, 20],
      },
      tableNormal: {
        fontSize: 10,
        margin: [0, 5, 0, 5],
      },
      tableDivider: {
        fontSize: 10,
        margin: [0, 5, 0, 0],
      },
      tableGrid: {
        fontSize: 8,
        margin: [0, 5, 0, 5],
      },
    },
  };
};

export const handlePDFGeneration = (
  fields,
  name,
  openInOtherTab,
  showMarkup
) => {
  const doc = docDefinition(fields);

  import("pdfmake/build/pdfmake.min")
    .then((pdfMake) => {
      import("pdfmake/build/vfs_fonts")
        .then((pdfFonts) => {
          if (openInOtherTab) {
            pdfMake.createPdf(doc, null, null, pdfFonts.pdfMake.vfs).open();
          } else {
            pdfMake
              .createPdf(doc, null, null, pdfFonts.pdfMake.vfs)
              .download(name);
          }
        })
        .catch((err) => console.error(err));
    })
    .catch((error) => console.error(error));
};
