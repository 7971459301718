import React from "react";
import { useSelector } from "react-redux";

import Avatar from "common/Avatar";

import { usersSelectors } from "../jobSlice";

const { selectById } = usersSelectors;

const AvatarItem = ({ uid }) => {
  const user = useSelector((state) => selectById(state, uid));

  return (
    <Avatar
      title={`${user?.field_first_name} ${user?.field_last_name}`}
      link={user?.profile_pic}
      size="small"
    />
  );
};

AvatarItem.propTypes = {};

export default AvatarItem;
