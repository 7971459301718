import moment from "moment";
import { getTodaysDay } from "../../utility";

export const getTodaysHours = (rawHours, day) => {
  let today = getTodaysDay();
  if (day) today = day;
  let hours = [];
  if (rawHours) hours = getHours(rawHours);
  let now = null;
  hours.forEach((hour) => {
    if (hour.day === today) now = hour.hours;
  });

  return { today, time: now };
};

export const formatHours = (h, d) => {
  if (!h || !d) return null;
  const openDate = moment(h[`${d}_open`], "HH:mm");
  const closeDate = moment(h[`${d}_close`], "HH:mm");

  const open = openDate.isValid() ? openDate.format("hh:mma") : "";
  const close = closeDate.isValid() ? closeDate.format("hh:mma") : "";
  const closed = h[`${d}_closed`];
  const allDay = h[`${d}_24hours`];

  if (Number(closed) === 1) return "Closed";
  if (Number(allDay) === 1) return "Open 24 Hours";
  if (!open || !open.length || !close || !close.length) return null;

  return `${open} - ${close}`;
};

export const getHours = (h) => {
  if (!h) return [];

  const _hours = [
    { day: "monday", hours: formatHours(h, "monday") },
    { day: "tuesday", hours: formatHours(h, "tuesday") },
    { day: "wednesday", hours: formatHours(h, "wednesday") },
    { day: "thursday", hours: formatHours(h, "thursday") },
    { day: "friday", hours: formatHours(h, "friday") },
    { day: "saturday", hours: formatHours(h, "saturday") },
    { day: "sunday", hours: formatHours(h, "sunday") },
  ];

  const hours = [];
  _hours.forEach((hour) => {
    if (hour.hours) hours.push(hour);
  });
  return hours;
};
