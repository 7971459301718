//TODO: add overrides for expense types

import React, { useState, useEffect, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { unwrapResult } from "@reduxjs/toolkit"
import { first, toArray } from "lodash"
import {
    Tooltip,
    IconButton,
    useMediaQuery,
    Typography,
    InputAdornment,
    ListItem,
    ListItemText,
    MenuItem,
    ListItemIcon,
    FormGroup,
} from "@mui/material"
import { BorderColor } from "@mui/icons-material";
import moment from "moment"
import { AiOutlineFileAdd } from "react-icons/ai"

import { expenseValidationSchema } from "schemas/forms/dailysheets"
import { DialogForm } from "common/Dialog"
import { AutocompleteFormField } from "common/Fields"
import { SelectFormField } from "common/Fields"
import { CheckboxFormField } from "common/Fields"
import { DateFormField } from "common/Fields"
import { TextFormField } from "common/Fields"
import { SingleUploader } from "common/Uploader"
import { formatMoney, generateTempUUID, isDivisionStatusPast } from "utility"

import {
  fetchMemberUsers,
  memberUsersSelector,
  getMemberUsersLoadingSelector,
} from "features/Users/usersSlice"
import { postExpense, patchExpense } from "features/DailySheets/dailysheetsSlice"
import { getMemberDataSelector } from "features/Job/jobSlice"
import { getDivisionDataSelector } from "features/Job/jobSlice"
import { calcTotal } from "../utils"
import { expenseTypesSelectors } from "features/PriceList/priceListSlice"
import { fetchDivisionSnapshot } from "features/Job/jobSlice"
import {
    fetchDailysheet,
    getDailySheetDataSelector,
    getDailySheetLoadingSelector,
  } from "features/DailySheet/dailySheetSlice"
import Guard from "common/Guard"

const ExpenseAddEdit = ({ nid, row, isEdit=false, ...props}) => {
  const dispatch = useDispatch()
  const [uploading, setUploading] = useState(false)
  const [receiptId, setReceiptId] = useState(null)
  const [open, setOpen] = useState(false)
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const user = useSelector((state) => state.auth.user.data)
  const member = useSelector(getMemberDataSelector)
  const users = useSelector(memberUsersSelector.selectAll)
  const loading = useSelector(getMemberUsersLoadingSelector)
  const division = useSelector(getDivisionDataSelector)
  const types = useSelector(expenseTypesSelectors.selectAll)
  const loadingSheet = useSelector(getDailySheetLoadingSelector)
  const sheet = useSelector(getDailySheetDataSelector)
  const divisionStatuses = useSelector((state) => state.taxonomies.jobDivisionStatus.entities)

  useEffect(() => {
    let promise
    if (open && nid) {
      promise = dispatch(fetchDailysheet(nid))
    }

    return () => {
      if (promise) {
        promise.abort()
      }
    }
  }, [dispatch, nid, open])

  useEffect(() => {
    if (!loadingSheet && sheet.field_ds_receipt) {
      setReceiptId(first(sheet.field_ds_receipt).fid)
    }
  }, [loadingSheet, sheet])

  const getUsers = useCallback(
    (query) => {
      return dispatch(
        fetchMemberUsers({
          id: member?.nid,
          params: {
            keywords: query,
          },
        })
      )
    },
    [member, dispatch]
  )

  const handleSubmit = async (
    data,
    setSubmitting,
    setMessage,
    successCallback,
    errorCallback
  ) => {
    data.division = division.title
    const params = {
      user: data.user,
      type: data.type,
      date: moment(data.date).format("YYYY-MM-DD"),
      cost: data.cost.trim(),
      markup: data.markup,
      description: data.description,
      receipt: receiptId,
      exclude: data.exclude,
      reimburse: data.reimburse,
      show: data.show,
      division: division.title,
      total: calcTotal(data.cost, data.markup),
      daily_sheet_nid: isEdit ? nid : generateTempUUID(),
      field_ds_taxable: data.field_ds_taxable,
      ...(typeof data?.field_visibility != 'undefined') && { field_visibility: data.field_visibility ? 'phx_client' : null },
    }
    setSubmitting(true)
    if(isEdit) {
      dispatch(patchExpense({ id: nid, params, row }))
        .then(() => {
          if(props?.handleRefresh){
            props.handleRefresh();
          }
          handleOnClose();
      });
    } else {
      try {
        const resultAction = await dispatch(
          postExpense({ id: division.nid, params })
        )
        unwrapResult(resultAction)
        setReceiptId(null)
        successCallback("Added Expense")
        if(props?.fromSummaryCard){
          dispatch(fetchDivisionSnapshot(division.nid))
        }
        if(props?.handleRefresh){
          props.handleRefresh()
        }
      } catch (err) {
        console.error("Failed to save: ", err)
        setReceiptId(null)
      }
    }
  }

  const initialValues = {
    user: isEdit ? first(sheet.field_ds_user) : { uid: user.uid, name: user.name },
    type: isEdit ? sheet.field_ds_expense_type_text : null,
    date: isEdit ? moment(sheet.field_ds_date) : moment(),
    cost: isEdit ? sheet.field_ds_expense_cost : "",
    markup: isEdit ? sheet.field_ds_percent_markup : "",
    description: isEdit ? sheet.field_ds_description : "",
    receipt: isEdit ? sheet.field_ds_receipt : [],
    exclude: isEdit ? sheet.field_ds_exclude_invoice : false,
    reimburse: isEdit ? sheet.field_ds_reimburse : false,
    show: isEdit ? sheet.field_ds_description_show : false,
    field_ds_taxable: isEdit ? sheet.field_ds_taxable : true,
    ...(typeof sheet.field_visibility != 'undefined' || user.roleType === 'phx_client') && { field_visibility: 
      isEdit ? sheet?.field_visibility : isDivisionStatusPast(division, divisionStatuses, 'review')},
  }

  const handleUploadStart = () => {
    setUploading(true)
    setReceiptId(null)
  }

  const handleUploadSuccess = (fid) => {
    setUploading(false)
    setReceiptId(fid)
  }

  const handleUploadFail = () => {
    setUploading(false)
    setReceiptId(null)
  }

  const handleRemoveFile = () => {
    setReceiptId(null)
  }

  const handleOnClose = () => {
    setReceiptId(null)
    setOpen(false)
  }

  return (
    <>
        {(isMobile && isEdit) ? (
            <MenuItem onClick={() => setOpen(true)}>
            <ListItemIcon>
                <BorderColor />
            </ListItemIcon>
            <Typography variant="inherit">Edit</Typography>
            </MenuItem>
        ) : isEdit ? (
            <Tooltip title="Edit">
            <IconButton
                size="small"
                onClick={() => setOpen(true)}
                style={{ marginRight: "0.5rem" }}
            >
                <BorderColor />
            </IconButton>
            </Tooltip>
        ) : (
            <ListItem
                button
                style={{ paddingLeft: "2rem" }}
                onClick={() => setOpen(true)}
                className={props?.className}>
                <ListItemIcon>
                    <AiOutlineFileAdd />
                </ListItemIcon>
                <ListItemText primary="Expense" />
            </ListItem>
        )}
      <DialogForm
        open={open}
        onClose={handleOnClose}
        maxWidth="xs"
        title={ isEdit ? "Edit Expense" : "Add Expense"}
        disableActions={uploading}
        initialValues={initialValues}
        validationSchema={expenseValidationSchema}
        onSubmit={handleSubmit}
        loading={loadingSheet}
        overrideDirty
      >
        {({ values, isSubmitting }) => (
          <>
            <Typography variant="subtitle1">
              Total: {formatMoney(calcTotal(values.cost, values.markup))}
            </Typography>
            <AutocompleteFormField
              name="user"
              label="User"
              margin="normal"
              disabled={isSubmitting}
              options={toArray(users).map((user) => ({
                name: user.name,
                uid: user.uid,
              }))}
              loading={loading}
              fetchData={getUsers}
              variant="filled"
              size="small"
            />
            <SelectFormField
              fullWidth
              name="type"
              label="Expense Type"
              margin="normal"
              required
              disabled={isSubmitting}
              options={types.map((type) => ({
                value: type.name,
                label: type.name,
              }))}
              variant="filled"
              size="small"
            />
            <DateFormField
              fullWidth
              disableToolbar
              variant="inline"
              format="MM/DD/YYYY"
              id="date"
              label="Date"
              margin="normal"
              name="date"
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              inputVariant="filled"
              size="small"
            />
            <TextFormField
              fullWidth
              htmlFor="cost"
              name="cost"
              label="Expense Cost"
              margin="normal"
              disabled={isSubmitting}
              labelwidth={70}
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              variant="filled"
              size="small"
            />
            <TextFormField
              fullWidth
              htmlFor="markup"
              name="markup"
              label="Percent Markup"
              margin="normal"
              disabled={isSubmitting}
              labelwidth={70}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              variant="filled"
              size="small"
            />
            <FormGroup style={{ marginTop: "2rem" }}>
              <CheckboxFormField name="exclude" label="Exclude from invoice" />
              <CheckboxFormField name="reimburse" label="Reimburse" />
              <CheckboxFormField
                name="show"
                label="Show private description on the invoice"
              />
              <CheckboxFormField name="field_ds_taxable" label="Taxable" />
              <Guard customOverrideName="hasAdminAndJobSource">
                <CheckboxFormField name="field_visibility" label="Do not share with provider" />
              </Guard>
            </FormGroup>
            <TextFormField
              fullWidth
              htmlFor="description"
              name="description"
              label="Description"
              margin="normal"
              disabled={isSubmitting}
              multiline
              labelwidth={70}
              variant="filled"
              size="small"
            />
            <SingleUploader
              entity="node"
              bundle="ds_expense"
              field="field_ds_receipt"
              onUploadSuccess={handleUploadSuccess}
              onUploadStart={handleUploadStart}
              onUploadFail={handleUploadFail}
              onRemove={handleRemoveFile}
              existingFile={isEdit ? sheet.field_ds_receipt : false}
            />
          </>
        )}
      </DialogForm>
    </>
  )
}

ExpenseAddEdit.propTypes = {}

export default ExpenseAddEdit
