import React, { useRef } from "react";
import { connect } from "react-redux";
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import { Add } from "@mui/icons-material";
import moment from "moment";
import styled from "styled-components";

import Button from "common/Button";
import { removePathFromUrl, getUtcOffset } from "../../../utility";
import Calendar from "../../Calendar";
import { getCRMTasks } from "../../../actions";
import TaskAdd from "../../Task/TaskRouteAdd";
import Task from "../../Task/TaskRoute";
import constants from "components/constants";
const offSet = getUtcOffset(); // Remember, do not use this for times, (date only)

export const Chips = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.33rem;

  & > .MuiChip-root {
    margin-right: 0.33rem;
    margin-bottom: 0.33rem;

    &:hover {
      text-decoration: none;
    }
  }
`;

const getFillColor = (event) => {
  const complete = event.completed_uid ? true : false;
  const priority = event.priority === "High";
  const allDay = event.all_day === "1";

  if (!allDay) {
    return "transparent";
  } else {
    if (complete) {
      return constants.colorGreen;
    } else if (priority) {
      return "#F4511E";
    } else {
      return constants.colorBlueAlt;
    }
  }
};

const getTextColor = (event) => {
  const complete = event.completed_uid ? true : false;
  const priority = event.priority === "High";
  const allDay = event.all_day === "1";

  if (allDay) {
    return "white";
  } else {
    if (complete) {
      return "#55a105";
    } else if (priority) {
      return "#F4511E";
    } else {
      return constants.colorBlueAlt;
    }
  }
};

const formatDatesForCalendar = (data) => {
  return data.map((event) => {
    const allDay = event.all_day === "1";
    return {
      title: event.title,
      start: moment.unix(event.start_time).utcOffset(offSet).format(),
      end: moment.unix(event.end_time).utcOffset(offSet).format(),
      allDay,
      rendering: "",
      backgroundColor: getFillColor(event),
      borderColor: getFillColor(event),
      textColor: getTextColor(event),
      description: event.body_value,
      extendedProps: {
        reminderText: event.reminder_text_proper,
        priority: event.priority,
        assignedUsers: [],
        job: "",
        interpretation: event.interpretation,
        nid: event.entity_id,
        delta: event.recur_delta,
      },
    };
  });
};

const Tasks = ({ parentEntity, loadTasks }) => {
  const { url } = useRouteMatch();
  const { nid } = parentEntity;
  const cal = useRef();
  const history = useHistory();
  const today = moment().format("YYYY-MM-DD");
  const [currentDelta, setCurrentDelta] = React.useState(null);

  const handleGetEvents = async (info, successCallback, failureCallback) => {
    const startDate = moment(info.start).format("Y-MM-DD");
    const endDate = moment(info.end).format("Y-MM-DD");

    const response = await loadTasks("calendar", startDate, endDate, {
      "filter[parent_nid]": nid,
    });
    if (response.status === 200) {
      successCallback(formatDatesForCalendar(response.data.data));
    } else {
      failureCallback(response);
    }
  };

  const handleEventClick = (info) => {
    const nid = info.event.extendedProps.nid;
    const delta = info.event.extendedProps.delta;
    const parsedUrl = removePathFromUrl(url, "t", true);

    setCurrentDelta(delta);

    if (nid) {
      history.push(`${parsedUrl}/t/${nid}`);
    }
  };

  const handleSelect = (info) => {
    const start = info.startStr;
    const end = moment(info.endStr).subtract(1, "days").format("YYYY-MM-DD");
    const parsedUrl = removePathFromUrl(url, "add", true);

    history.push(`${parsedUrl}/add/${start}/${end}`);
  };

  const handleRefetchEvents = () => {
    if (cal.current) {
      cal.current.getApi().refetchEvents();
    }
  };

  return (
    <div>
      <Calendar
        eventClick={handleEventClick}
        select={handleSelect}
        onEventRequest={handleGetEvents}
        setRef={cal}
        actions={() => (
          <>
            <Button
              size="small"
              color="tertiary"
              startIcon={<Add />}
              component={Link}
              to={`${url}/add/${today}/${today}`}
            >
              Task
            </Button>
          </>
        )}
      />
      <Task
        url={url}
        handleRefetchEvents={handleRefetchEvents}
        delta={currentDelta}
      />
      <TaskAdd
        url={url}
        handleRefetchEvents={handleRefetchEvents}
        member={nid}
      />
    </div>
  );
};

Tasks.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
  loadTasks: (view, start, end, nid) =>
    dispatch(getCRMTasks(view, start, end, 1, nid)),
});

export default connect(null, mapDispatchToProps)(Tasks);
