import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, useHistory, useParams } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import {
  Tooltip,
  Typography,
  IconButton,
  Backdrop,
  Portal,
  CircularProgress,
} from "@mui/material";
import { Close, GetApp } from "@mui/icons-material";

import config from "../../config";
import FileIcon from "../FileIcon";
import { removePathFromUrl, formatFile } from "../../utility";
import { Container } from "./Styled";
import { clear, getFile } from "../../actions";
import { downloadFile } from "../../actions/files/fileActions";

const ComponentContent = ({
  url,
  loadFile,
  src,
  loading,
  download,
  clearData,
}) => {
  const { id } = useParams();
  const history = useHistory();
  const { data, filemime, filename } = formatFile(src);
  const imageSrc = `data:${filemime};base64,${data}`;

  useEffect(() => {
    if (id) {
      loadFile(id);
    }

    return () => {
      clearData("file");
    };
  }, [id, loadFile, clearData]);

  const handleDownload = () => {
    download(`${config.api_url}/file/${id}`, filename, "base64");
  };

  return (
    <Portal>
      <div
        style={{
          zIndex: 1300,
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            color: "white",
            display: "flex",
            alignItems: "center",
            background: "linear-gradient(rgba(0,0,0,1), rgba(0,0,0,0))",
            padding: "1rem",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {filename && (
              <>
                <FileIcon filename={filename} size="small" />
                <Typography>{filename}</Typography>
              </>
            )}
          </div>
          <div>
            <Tooltip title="Download">
              <span>
                <IconButton
                  size="small"
                  style={{ color: "white" }}
                  disableRipple
                  disabled={loading}
                  onClick={handleDownload}
                >
                  <GetApp />
                </IconButton>
                <IconButton
                  size="small"
                  style={{ color: "white" }}
                  disableRipple
                  onClick={() => {
                    history.push(url);
                  }}
                >
                  <Close />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        </div>
        <Container>
          {loading ? (
            <CircularProgress />
          ) : (
            <img src={imageSrc} alt="Preview" />
          )}
        </Container>
        <Backdrop
          open={true}
          onClick={() => {
            history.push(url);
          }}
        />
      </div>
    </Portal>
  );
};

const LightroomRoute = ({ route, url, path, ids, ...rest }) => {
  const [parsedUrl, setParsedUrl] = React.useState(url);

  useEffect(() => {
    if (url.indexOf(path) >= 0) {
      setParsedUrl(removePathFromUrl(url, path, true));
    }
  }, [url, path]);

  return (
    <Route path={`${parsedUrl}/${route}`}>
      {({ match }) => (
        <CSSTransition
          in={match != null}
          timeout={200}
          classNames="fade"
          unmountOnExit
        >
          <ComponentContent
            ids={ids}
            parsedUrl={parsedUrl}
            path={path}
            url={url}
            {...rest}
          />
        </CSSTransition>
      )}
    </Route>
  );
};

LightroomRoute.propTypes = {};

const mapStateToProps = (state) => ({
  src: state.app.file,
  loading: state.app.file.loading,
});

const mapDispatchToProps = (dispatch) => ({
  loadFile: (fid) => dispatch(getFile(fid)),
  download: (path, name, responseType) =>
    dispatch(downloadFile(path, name, responseType)),
  clearData: (reducer) => dispatch(clear(reducer)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LightroomRoute);
