/* eslint-disable no-param-reassign */
import React, { useRef, useEffect } from "react";
import { useSelector, connect } from "react-redux";
import { compose as reduxCompose } from "redux";
import compose from "recompose/compose";
import defaultProps from "recompose/defaultProps";
import withState from "recompose/withState";
import withHandlers from "recompose/withHandlers";
import { coords } from "./utils";
import { CanvasHoverMap } from "./controls/Map";
import setObservableConfig from "recompose/setObservableConfig";
import { Observable } from "rxjs";
// import mapStyle from "./mapStyle";
import { TooltipMarker } from "./controls/Markers";
import {
  getMapJobs,
  getMapCustomerLocations,
  getMapMembers,
  getMapCustomers,
  clearMapData,
  getPHXClientReps,
  postZone,
  getMapZones,
  deleteZone,
} from "../../actions";
import { snackbarEnter } from "../../actions/snackbarActions";
import { formatCoords } from "../../utility";
import { TooltipName } from "./controls/Markers/Styled";
import MapSnapshot from "./MapSnapshot";
import { statesSelectors } from "../../features/Taxonomies/taxonomiesSlice";
import constants from "components/constants";
import { size } from "lodash";
import config from "config";
import styled from "styled-components";

const FloatLabel = styled.div`
  position: fixed;
  z-index: 9999;
  max-width: 300px;
  margin: 0.5rem;
  padding: 0.25rem;
  background-color: var(--color-gray-lightest);
  border-radius: var(--border-radius);
  box-shadow: var(--drop-shadow);

  div{
    padding: 0 0 0.25rem;
    border-bottom: 1px solid var(--color-gray);
    margin-bottom: 0.25rem;

    &+ div{
      padding-left: 1rem;
      font-size: 0.9em;
    }

    &:last-child{
      border-bottom: none;
    }
  }
`;

function roundedPoly(ctx, points, radiusAll) {
  let i;
  let x;
  let y;
  let len;
  let p1;
  let p2;
  let p3;
  let v1;
  let v2;
  let sinA;
  let sinA90;
  let radDirection;
  let drawDirection;
  let angle;
  let halfAngle;
  let cRadius;
  let lenOut;
  let radius;
  // convert 2 points into vector form, polar form, and normalised
  let asVec = function (p, pp, v) {
    v.x = pp.x - p.x;
    v.y = pp.y - p.y;
    v.len = Math.sqrt(v.x * v.x + v.y * v.y);
    v.nx = v.x / v.len;
    v.ny = v.y / v.len;
    v.ang = Math.atan2(v.ny, v.nx);
  };
  radius = radiusAll;
  v1 = {};
  v2 = {};
  len = points.length;
  p1 = points[len - 1];
  for (i = 0; i < len; i++) {
    p2 = points[i % len];
    p3 = points[(i + 1) % len];

    asVec(p2, p1, v1);
    asVec(p2, p3, v2);
    sinA = v1.nx * v2.ny - v1.ny * v2.nx;
    sinA90 = v1.nx * v2.nx - v1.ny * -v2.ny;
    angle = Math.asin(sinA < -1 ? -1 : sinA > 1 ? 1 : sinA);

    radDirection = 1;
    drawDirection = false;
    if (sinA90 < 0) {
      if (angle < 0) {
        angle = Math.PI + angle;
      } else {
        angle = Math.PI - angle;
        radDirection = -1;
        drawDirection = true;
      }
    } else {
      if (angle > 0) {
        radDirection = -1;
        drawDirection = true;
      }
    }
    if (p2.radius !== undefined) {
      radius = p2.radius;
    } else {
      radius = radiusAll;
    }

    halfAngle = angle / 2;

    lenOut = Math.abs((Math.cos(halfAngle) * radius) / Math.sin(halfAngle));

    if (lenOut > Math.min(v1.len / 2, v2.len / 2)) {
      lenOut = Math.min(v1.len / 2, v2.len / 2);
      cRadius = Math.abs((lenOut * Math.sin(halfAngle)) / Math.cos(halfAngle));
    } else {
      cRadius = radius;
    }

    x = p2.x + v2.nx * lenOut;
    y = p2.y + v2.ny * lenOut;

    x += -v2.ny * cRadius * radDirection;
    y += v2.nx * cRadius * radDirection;

    ctx.arc(
      x,
      y,
      cRadius,
      v1.ang + (Math.PI / 2) * radDirection,
      v2.ang - (Math.PI / 2) * radDirection,
      drawDirection
    );

    p1 = p2;
    p2 = p3;
  }
  ctx.closePath();
}

setObservableConfig({
  fromESObservable: Observable.from,
});

const getBundleType = (points) => {
  if (points && points[0] && points[0].title) {
    return "division";
  } else if (points && points[0] && points[0].customer_location_name) {
    return "customer_location";
  } else if (points && points[0] && points[0].customer_name) {
    return "customer";
  } else if (points && points[0] && points[0].member_name) {
    if (points[0].member_icon_type === "member") {
      return "member";
    } else if (points[0].member_icon_type === "affiliate") {
      return "affiliate";
    } else if (points[0].member_icon_type === "other") {
      return "other";
    } else if (points[0].member_icon_type === "parent") {
      return "parent";
    } else if (points[0].member_icon_type === "child") {
      return "child";
    }
    return "member";
  }

  return null;
};

const getMarkerIcon = (points, iconFiles) => {
  if (points && Number(points[0].member_icon_type) > 0 && size(iconFiles)) {
    let fileData = null;
    iconFiles.forEach((file) => {
      if(Number(file.fid) === Number(points[0].member_icon_type)){
        fileData = file.data;
      }
    })
    if(fileData){
      return fileData;
    }
  }

  return null;
};

const getColor = (points, iconSettings) => {
  if(iconSettings && iconSettings?.color){
    return iconSettings?.color;
  }
  if (points && points[0] && points[0].title) {
    return constants.colorTeal;
  } else if (points && points[0] && points[0].customer_location_name) {
    return constants.colorBlue;
  } else if (points && points[0] && points[0].customer_name) {
    return constants.colorBlueDark;
  } else if (points && points[0] && points[0].member_name) {
    if (points[0].member_icon_type === "member") {
      return "#E03127";
    } else if (points[0].member_icon_type === "affiliate") {
      return constants.colorBlueDarkFade;
    } else if (points[0].member_icon_type === "other") {
      return constants.colorBlueLighter;
    } else if (points[0].member_icon_type === "parent") {
      return "#E03127";
    } else if (points[0].member_icon_type === "child") {
      return "#E03127";
    }
  }

  return constants.colorBlueLight;
};

const getName = (points) => {
  if (points && points[0] && points[0].title) {
    return points[0].title;
  } else if (points && points[0] && points[0].customer_location_name) {
    return points[0].customer_location_name;
  } else if (points && points[0] && points[0].customer_name) {
    return points[0].customer_name;
  } else if (points && points[0] && points[0].member_name) {
    return points[0].member_name;
  }

  return "";
};

const getNid = (points) => {
  if (points && points[0] && points[0].division_nid) {
    return points[0].division_nid;
  } else if (points && points[0] && points[0].customer_location_nid) {
    return points[0].customer_location_nid;
  } else if (points && points[0] && points[0].customer_nid) {
    return points[0].customer_nid;
  } else if (points && points[0] && points[0].member_nid) {
    return points[0].member_nid;
  }

  return "";
};

const getParent = (points) => {
  if (
    points &&
    points[0] &&
    points[0].customer_location_name &&
    points[0].customer_name
  ) {
    return points[0].customer_name;
  }

  return null;
};

const getMarkers = (l, c, m, j, iconFiles) => {
  const d = [...l, ...c, ...m, ...j];

  return d.map((data, i) => {
    const { coords, points, cluster_qty } = data;
    let iconSettings = null;
    if (points && points[0]?.member_icon_type && points[0].member_icon_type.indexOf('{"') > -1) {
      iconSettings = JSON.parse(points[0].member_icon_type);
    }

    const position = formatCoords(coords);
    const name = getName(points);
    const color = getColor(points, iconSettings);
    const bundle = getBundleType(points);
    const nid = getNid(points);
    const icon = getMarkerIcon(points, iconFiles);
    const parent = getParent(points);

    return {
      id: nid,
      lat: position.lat,
      lng: position.lng,
      name,
      color,
      bundle,
      cluster: cluster_qty,
      parent,
      icon,
      iconSettings,
    };
  });
};

export const Map = ({
  theme,
  style,
  options,
  markerHoverDistance,
  renderMarkers,
  renderMarker,
  bootstrapURLKeys,
  mapParams: { zoom, center, bounds },
  onMapParamsChange,
  setMapParams,
  loadJobs,
  jobs,
  loadLocations,
  locations,
  loadMembers,
  members,
  loadCustomers,
  customers,
  clearData,
  loadReps,
  reps,
  loadZones,
  addZone,
  showSnackbar,
  mapZones,
  removeZone,
  memberIconFiles,
}) => {
  const floatLabelRef = useRef();
  const states = useSelector(statesSelectors.selectAll);
  const org = useSelector((state) => state.auth.current_client);
  const [snapshotOpen, setSnapshotOpen] = React.useState(false);
  const [snapshot, setSnapshot] = React.useState({
    title: "",
    status: "",
    nid: "",
    bundle: null,
    onClose: null,
  });
  const [markerOver, setMarkerOver] = React.useState(false);
  const [markerClickOverride, setMarkerClickOverride] = React.useState(false);
  const [markerClicked, setMarkerClicked] = React.useState(null);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      let lat = position.coords.latitude;
      let lng = position.coords.longitude;
      setMapParams({ bounds, zoom, center: { lat, lng } });
    });

    return () => {
      clearData("mapJobs");
      clearData("mapMembers");
      clearData("mapCustomers");
      clearData("mapCustomerLocations");
      clearData("zones");
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (org) {
      loadJobs(null, null, 0, {
        clustering_toggle: "off",
        "filter[phx_client_nid]": org,
      });
      loadLocations(0, {
        clustering_toggle: "off",
        "filter[phx_client_nid]": org,
      });
      loadMembers(0, 0, {
        clustering_toggle: "off",
        "filter[phx_client_nids][0]": org,
      });
      loadCustomers(0, {
        clustering_toggle: "off",
        "filter[phx_client_nid]": org,
      });
    }
    loadZones();
    loadReps();
  }, [
    loadJobs,
    loadLocations,
    loadMembers,
    loadCustomers,
    loadZones,
    loadReps,
    org,
  ]);

  const launchSnapshot = (id, name, bundle, onClose, ignoreOverride) => {
    const info = {
      title: name,
      nid: id,
      status: "",
      bundle,
      onClose,
    };
    if(!markerClickOverride || ignoreOverride){
      setSnapshotOpen(true);
      setSnapshot(info);
    }
    else{
      // info.onClose = () => {
      //   setMarkerClicked(null);
      //   if(onClose){
      //     onClose();
      //   }
      // }
      setMarkerClicked(info);
    }
  };

  const closeSnapshot = () => {
    setSnapshotOpen(false);
    if(snapshot.onClose){
      snapshot.onClose();
    }
  };

  const onMarkerMouseEnter = (e) => {
    setMarkerOver(true);
  };
  const onMarkerMouseLeave = (e) => {
    if(!snapshotOpen){
      setMarkerOver(false);
    }
    else{
      setTimeout(() => setMarkerOver(false), 500);
    }
  };

  return (
    <>
      <CanvasHoverMap
        // flex: 1 here
        style={style}
        // google map options https://developers.google.com/maps/documentation/javascript/controls#ControlOptions
        options={options}
        // see CanvasMap onMouseMove, distance at which algorithm decides that marker is hovered
        markerHoverDistance={markerHoverDistance}
        // google-map-react props
        center={center}
        zoom={zoom}
        onChange={onMapParamsChange}
        // canvas markers, and render functions
        markers={getMarkers(locations, customers, members, jobs, memberIconFiles)}
        // render markers at canvas
        renderMarkers={renderMarkers}
        // render hovered marker as is
        renderMarker={renderMarker}
        // to force redraw just pass a new empty object to refresh for example
        // refresh={selected}
        bootstrapURLKeys={bootstrapURLKeys}
        loadJobs={loadJobs}
        loadLocations={loadLocations}
        loadMembers={loadMembers}
        loadCustomers={loadCustomers}
        onClear={clearData}
        states={states}
        members={members}
        reps={reps}
        launchSnapshot={launchSnapshot}
        snapshotOpen={snapshotOpen}
        parentOnMarkerMouseEnter={onMarkerMouseEnter}
        parentOnMarkerMouseLeave={onMarkerMouseLeave}
        markerOver={markerOver}
        setMarkerClickOverride={setMarkerClickOverride}
        markerClicked={markerClicked}
        setMarkerClicked={setMarkerClicked}
        loadZones={loadZones}
        addZone={addZone}
        showSnackbar={showSnackbar}
        mapZones={mapZones}
        removeZone={removeZone}
        org={org}
        floatLabelRef={floatLabelRef}
      />
      <MapSnapshot
        snapshotOpen={snapshotOpen}
        snapshot={snapshot}
        closeSnapshot={closeSnapshot}
      />
      <FloatLabel ref={floatLabelRef} />
    </>
  );
};

// console.log(JSON.stringify([JSON.stringify(mapStyle)]));

export const mapHOC = compose(
  defaultProps({
    theme: {
      component: "",
      tooltip: {
        display: "none",
      },
      tooltipAlways: "hint--always",
      tooltipHidden: "hint--hidden",
    },
    options: {
      mapId: "90e30a0644c558bb",
      // styles: mapStyle,
      scrollwheel: true,
      zoomControl: true,
      zoomControlOptions: {
        position: 0,
      },
      minZoom: 3,
      zoom: 6,
      maxZoom: 18,
      disableDoubleClickZoom: true,
      clickableIcons: false,
    },
    style: {
      width: "inherit",
      height: "inherit",
    },
    bootstrapURLKeys: {
      key: config.google_api,
      libraries: ["drawing", "places"],
    },
    hoverDistance: 15,
    markerHoverDistance: 15,
    jobs: [],
    locations: [],
    members: [],
    customers: [],
    states: [],
    reps: [],
    mapZones: [],
    addZone: () => {},
    loadZones: () => {},
    showSnackbar: () => {},
    removeZone: () => {},
  }),
  withState("mapParams", "setMapParams", { center: coords, zoom: 6 }),
  withHandlers({
    onMapParamsChange:
      ({ setMapParams }) =>
      ({ center, zoom, bounds }) => {
        setMapParams({ center, zoom, bounds });
      },
    renderMarker:
      ({ theme }) =>
      (marker, launchSnapshot, onMarkerMouseEnter, onMarkerMouseLeave) =>
        (
          <TooltipMarker
            /* key is needed to play initial anim in some cases */
            key={marker.id}
            theme={theme}
            themeNamespace={"tooltipMarker"}
            // active={fa}
            initialScale={1}
            defaultScale={1}
            hoveredScale={1.3}
            onMouseClick={() =>
              launchSnapshot(marker.id, marker.name, marker.bundle)
            }
            onMouseEnter={onMarkerMouseEnter}
            onMouseLeave={onMarkerMouseLeave}
            tooltipContent={
              <div>
                <TooltipName>{marker.name}</TooltipName>
                {marker.parent && <TooltipName>{marker.parent}</TooltipName>}
              </div>
            }
            paddingOffset={60} // used for tooltip position
            tooltipContentHeight={10} // no need to be exact, used for tooltip position
            tooltipContentWidth={100} // no need to be exact, used for tooltip position
            color={marker.color}
            cluster={marker.cluster}
            bundle={marker.bundle}
            icon={marker.icon}
            iconSettings={marker.iconSettings}
            {...marker}
          />
        ),
    // be sure in current implementation markers is tile markers, not all markers.
    // tiling is used as it allows some perf optimizations not used here
    renderMarkers:
      () =>
      ({ ctx, markers, tileSize }) => {
        ctx.clearRect(0, 0, tileSize, tileSize);
        // const radius = 5;
        markers.forEach(({ /* id, */ x, y, color, cluster, bundle, icon, iconSettings }) => {
          let radius = cluster > 1 && bundle === "customer_location" ? 15 : 5;
          if(iconSettings && iconSettings.type === 'circle'){
            radius = 7;
          }

          if(icon ){
            let newImage = new Image();
            newImage.src = icon

            // When it loads
            newImage.onload = () => {
                // Draw the image onto the context
                ctx.drawImage(newImage, x - 16, y - 16, 32, 32);
            }
          }
          else if((iconSettings && iconSettings.type === 'circle')
            || bundle === "division"
            || bundle === "customer_location"
            || bundle === "customer"
          ) {
            ctx.fillStyle = "rgba(0, 0, 0, 0.1)";
            ctx.beginPath();
            ctx.arc(x, y, radius + 3, 0, Math.PI * 2, true);
            ctx.closePath();
            ctx.fill();

            ctx.fillStyle = "white";
            ctx.beginPath();
            ctx.arc(x, y, radius + 2, 0, Math.PI * 2, true);
            ctx.closePath();
            ctx.fill();

            ctx.fillStyle = color;
            ctx.beginPath();
            ctx.arc(x, y, radius, 0, Math.PI * 2, true);
            ctx.closePath();
            ctx.fill();
          }
          // just circles here but can be images, use id or other marker props to render
          else {
            if (bundle === "member" || bundle === "parent") {
              ctx.strokeStyle = "rgba(0, 0, 0,1)";
              ctx.beginPath();
              ctx.arc(x + 1, y + 1, radius + 7, 0, Math.PI * 2, true);
              ctx.closePath();
              ctx.stroke();
            }

            ctx.fillStyle = "rgba(0, 0, 0, 0.1)";
            ctx.beginPath();
            roundedPoly(
              ctx,
              [
                { x: x + radius + 4, y: y + radius + 4 },
                { x: x + radius + 4, y: y - (radius + 4) },
                { x: x - (radius + 4), y: y - (radius + 4) },
                { x: x - (radius + 4), y: y + radius + 4 },
              ],
              3
            );
            ctx.fill();

            ctx.fillStyle = "white";
            ctx.beginPath();
            roundedPoly(
              ctx,
              [
                { x: x + radius + 2, y: y + radius + 2 },
                { x: x + radius + 2, y: y - (radius + 2) },
                { x: x - (radius + 2), y: y - (radius + 2) },
                { x: x - (radius + 2), y: y + radius + 4 },
              ],
              2
            );
            ctx.fill();

            ctx.fillStyle = color;
            ctx.beginPath();
            roundedPoly(
              ctx,
              [
                { x: x + radius + 1, y: y + radius + 1 },
                { x: x + radius + 1, y: y - (radius + 1) },
                { x: x - (radius + 1), y: y - (radius + 1) },
                { x: x - (radius + 1), y: y + radius + 1 },
              ],
              2
            );
            ctx.fill();
          }

          if (cluster > 1 && bundle === "customer_location") {
            ctx.fillStyle = "white";
            ctx.textAlign = "center";
            ctx.font = "12px Arial";
            ctx.fillText(cluster, x, y + 4);
          }
        });
      },
  })
);

const mapStateToProps = (state) => ({
  jobs: state.app.mapJobs.data.data,
  locations: state.app.mapCustomerLocations.data.data,
  members: state.app.mapMembers.data.data,
  customers: state.app.mapCustomers.data.data,
  reps: state.app.phxClientReps.data.data,
  mapZones: state.app.zones.data.data,
  memberIconFiles: state.app.mapMembers.data?.meta?.map_icons,
});

const mapDispatchToProps = (dispatch) => ({
  loadJobs: (start, end, archived, params) =>
    dispatch(getMapJobs(start, end, archived, params)),
  loadLocations: (zoom, archived, bounding, params) =>
    dispatch(getMapCustomerLocations(zoom, archived, bounding, params)),
  loadMembers: (affiliate, zoom, archived, params) =>
    dispatch(getMapMembers(affiliate, zoom, archived, params)),
  loadCustomers: (zoom, archived, bounding, params) =>
    dispatch(getMapCustomers(zoom, archived, bounding, params)),
  clearData: (type) => dispatch(clearMapData(type)),
  loadReps: () => dispatch(getPHXClientReps()),
  addZone: (params) => dispatch(postZone(params)),
  loadZones: (params) => dispatch(getMapZones(params)),
  showSnackbar: (data) => dispatch(snackbarEnter(data)),
  removeZone: (nid, params) => dispatch(deleteZone(nid, params)),
});

export default reduxCompose(
  connect(mapStateToProps, mapDispatchToProps),
  mapHOC
)(Map);
