import React from "react";
import "chartjs-plugin-annotation";
import "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import { Typography } from "@mui/material";
import constants from "components/constants";

import { ChartSkeleton } from "../Skeletons";
import { Loader } from "./Styled";

const analyticsIcon = `${process.env.PUBLIC_URL}/images/icons/icon-analytics--large.svg`;

const BarChart = ({
  chartData,
  onElementsClick,
  showLegend=true,
  loading,
  customTooltipTitle,
  customTooltipLabel,
  customTooltipFooter,
}) => {
  const gradientBarChartConfigurationWithGrid = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    layout: {
      padding: {
        top: 20,
        bottom: 10,
        right: 20,
        left: 10,
      },
    },
    tooltips: {
      backgroundColor: constants.colorGrayLightest,
      titleFontColor: constants.colorGrayDarker,
      bodyFontColor: constants.colorGrayDark,
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest",
      callbacks: {
        title: customTooltipTitle
          ? (tooltipItems) => customTooltipTitle(tooltipItems)
          : function (tooltipItems) {
              return tooltipItems[0].label;
            },
        label: customTooltipLabel
          ? (tooltipItem) => customTooltipLabel(tooltipItem)
          : function (tooltipItem) {
              return tooltipItem.yLabel;
            },
        footer: customTooltipFooter
          ? (tooltipItems) => customTooltipFooter(tooltipItems)
          : function (tooltipItems) {
              return null;
            },
      },
      footerFontStyle: "normal",
      footerFontColor: constants.colorGrayDark,
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          gridLines: {
            drawBorder: false,
            color : constants.colorBlueLight + "1a",
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            fontColor: constants.colorGrayMedium,
          },
        },
      ],

      xAxes: [
        {
          gridLines: {
            drawBorder: false,
            color : constants.colorBlueLight + "1a",
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 10,
            fontColor: constants.colorGrayMedium,
            display: showLegend,
          },
        },
      ],
    },
  };

  const data = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

    gradientStroke.addColorStop(1, constants.colorBlueDarker + "33");
    gradientStroke.addColorStop(0.8, constants.colorBlueDarker + "1a");
    gradientStroke.addColorStop(0, constants.colorBlueDark + "00");

    return {
      labels: chartData.labels,
      datasets: [
        {
          label: "Jobs",
          fill: true,
          backgroundColor: gradientStroke,
          borderColor: constants.colorBlue,
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: constants.colorBlue,
          pointBorderColor: constants.colorBlueDarker + "00",
          pointHoverBackgroundColor: constants.colorBlue,
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          minBarLength: 2,
          data: chartData.data,
          datalabels: {
            display: "auto",
            labels: {
              title: {
                color: constants.colorBlueDark,
                font: {
                  weight: "bold",
                },
              },
            },
            formatter: function (value, context) {
              return "";
            },
          },
        },
      ],
    };
  };

  return loading ? (
    <Loader>
      <ChartSkeleton />
    </Loader>
  ) : !chartData.data.length ? (
    <Loader>
      <img src={analyticsIcon} alt="No Data to display" />
      <Typography variant="subtitle1" color="textSecondary">
        No Data to Display
      </Typography>
    </Loader>
  ) : (
    <Bar
      data={data}
      options={gradientBarChartConfigurationWithGrid}
      onElementsClick={onElementsClick ? onElementsClick : null}
    />
  );
};

BarChart.propTypes = {};

export default BarChart;
