import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Hidden, Grid } from "@mui/material";
import Card from "common/Card";

import DivisionHeaderLabel from "./DivisionHeaderLabel";
import Member from "../../components/Member";
import TodaysTasks from "../../components/TodaysTasks";
import CollapsibleHeader from "common/CollapsibleHeader";
import MapBanner from "common/MapBanner";
import CustomerInfo from "./CustomerInfo";
import JobDates from "../../components/JobDates";
import Teams from "../../components/Teams";
// import NextAction from "./NextAction";
import MilestonesNextAction from "features/JobProgress/components/MilestonesNextAction";
import Insurance from "../../components/Insurance";
import AssignUsers from "../../components/AssignUsers";
import Logs from "features/Logs";
import { fetchJobLogs } from "features/Logs/logsSlice";
import { TimeCardsUserSummary } from "components/TimeClock";

const Information = ({ job, division, location, onNavigate }) => {
  const browser = useSelector((state) => state.browser);
  const [shouldCollapse, setShouldCollapse] = useState(false);

  useEffect(() => {
    onNavigate(false);

    return () => {
      onNavigate(true);
    };
  }, [onNavigate]);

  const handleOnScroll = (e) => {
    const parent = e.target.parentElement.offsetHeight;
    const container = e.target.offsetHeight;

    if (parent - container > 160) {
      if (e.target.scrollTop > 100) {
        setShouldCollapse(true);
      } else {
        setShouldCollapse(false);
      }
    }

    if (shouldCollapse && e.target.scrollTop < 10) {
      setShouldCollapse(false);
    }
  };

  return (
    <div className="flex-1 overflow-auto body-w-footer-height" onScroll={handleOnScroll}>
      <CollapsibleHeader collapse={shouldCollapse}>
        <DivisionHeaderLabel
          collapse={shouldCollapse}
          typeTid={division.field_job_division_type?.tid}
          path={division.path}
          title={division.title}
        />
        {/* <Hidden mdDown> */}
          <div className="flex-[1_0_auto] grid-md:flex-initial max-w-full" >
            <Member />
          </div>
        {/* </Hidden> */}
      </CollapsibleHeader>
      <Hidden smUp implementation="css" className="p-2 bg-gray-light">
        <Card className="flex items-center justify-end p-2">
          <div className="mr-4">
            <AssignUsers nid={division.nid} />
          </div>
          <div className="mr-4">
            <Logs action={fetchJobLogs} id={division.nid} />
          </div>
        </Card>
      </Hidden>
      <MapBanner
        geo={{
          lat: location?.field_street_address_geo?.lat,
          lng: location?.field_street_address_geo?.lon,
        }}
        onScroll={handleOnScroll}
        address1={location.field_street_address?.address_line1}
        address2={location.field_street_address?.address_line2}
        city={location.field_street_address?.locality}
        state={location.field_street_address?.administrative_area}
        zip={location.field_street_address?.postal_code}
      >
        <Grid container spacing={3}>
          <Grid item xxs={12} sm={6} md={8} xl={9}>
            {browser.lessThan.medium && <MilestonesNextAction />}
            <CustomerInfo job={job} />
            <JobDates division={division} />
            {browser.greaterThan.small && <Insurance division={division}/>}
          </Grid>
          <Grid item xxs={12} sm={6} md={4} xl={3}>
            {browser.greaterThan.small && <MilestonesNextAction />}
            <TodaysTasks nid={division.nid} />
            <Teams groups={division.field_jd_personnel} />
            <TimeCardsUserSummary />
            {browser.lessThan.medium && <Insurance division={division} />}
          </Grid>
        </Grid>
      </MapBanner>
    </div>
  );
};

Information.propTypes = {};

export default Information;
