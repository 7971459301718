import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { FaUserEdit } from "react-icons/fa";

import { TextFormField } from "common/Fields";
import { DialogForm } from "common/Dialog";

import {
  insuranceAgentsSelectors,
  patchMemberInsuranceAgent,
} from "../memberSlice";
import { insuranceCoverageCategoriesSelectors } from "features/Taxonomies/taxonomiesSlice";
import AutocompleteFormField from "components/AutocompleteFormField";
import { unwrapResult } from "@reduxjs/toolkit";

const EditAgent = ({ id }) => {
  const dispatch = useDispatch();
  const agent = useSelector((state) =>
    insuranceAgentsSelectors.selectById(state, id)
  );
  const categories = useSelector(
    insuranceCoverageCategoriesSelectors.selectAll
  );

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async (
    data,
    setSubmitting,
    setMessage,
    successCallback,
    errorCallback
  ) => {
    setSubmitting(true);
    const params = {
      title: [{ value: data.title }],
      field_member_ins_agent_email: [{ value: data.email }],
      field_member_ins_agent_phone: [{ value: data.phone }],
      field_member_ins_agent_agcy_name: [{ value: data.agency }],
      field_coverage_categories: data.categories.map((category) => ({
        target_id: category.tid,
      })),
    };

    try {
      const resultAction = await dispatch(
        patchMemberInsuranceAgent({
          id: agent.nid,
          params,
        })
      );
      setSubmitting(true);
      unwrapResult(resultAction);

      onClose();
    } catch (err) {
      setMessage(err);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListItem button onClick={handleOpen}>
        <ListItemIcon>
          <FaUserEdit />
        </ListItemIcon>
        <ListItemText primary="Edit" />
      </ListItem>
      <DialogForm
        open={open}
        onClose={onClose}
        maxWidth="xs"
        title="Add Agent"
        initialValues={{
          existing: { name: "Manual Entry", nid: "manual" },
          title: agent.title,
          email: agent.field_member_ins_agent_email,
          phone: agent.field_member_ins_agent_phone,
          agency: agent.field_member_ins_agent_agcy_name,
          categories: agent.field_coverage_categories,
        }}
        disablePadding
        onSubmit={handleSubmit}
        disableOverlay
      >
        {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
          <div style={{ padding: "1.25rem" }}>
            <TextFormField
              fullWidth
              name="title"
              label="Agent Name"
              margin="normal"
              disabled={isSubmitting}
              variant="filled"
              size="small"
            />
            <TextFormField
              fullWidth
              name="email"
              label="Email"
              margin="normal"
              disabled={isSubmitting}
              variant="filled"
              size="small"
            />
            <TextFormField
              fullWidth
              name="phone"
              label="Phone"
              margin="normal"
              disabled={isSubmitting}
              isPhone
              variant="filled"
              size="small"
            />
            <AutocompleteFormField
              multiple
              name="categories"
              label="Categories"
              margin="normal"
              variant="filled"
              size="small"
              disabled={isSubmitting}
              options={categories.map((category) => ({
                tid: category.tid,
                name: category.name,
              }))}
            />
            <TextFormField
              fullWidth
              name="agency"
              label="Agency Name"
              margin="normal"
              disabled={isSubmitting}
              variant="filled"
              size="small"
            />
          </div>
        )}
      </DialogForm>
    </>
  );
};

EditAgent.propTypes = {};

export default EditAgent;
