import React, { useRef, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditorState, ContentState } from "draft-js";
import { first, size } from "lodash";
import { GoCommentDiscussion } from "react-icons/go";

import Alert from "common/Alert";
import Comments from "features/Comments";
import { DefaultLoader } from "common/Loader";
import TextEditor, {
  getContentAsHTML,
} from "common/TextEditor";
import Empty from "common/Empty";

import {
  fetchGeneralFileComments,
  fetchGeneralFilePublicComments,
  postGeneralFileComment,
  postGeneralFilePublicComment,
  commentsSelectors,
  getCommentsLoadingSelector,
  getCommentsErrorSelector,
  fetchMoreGeneralFileComments,
  fetchMoreGeneralFilePublicComments,
  deleteGeneralFileComment,
  patchGeneralFileComment,
} from "features/Comments/commentsSlice";
import { fetchUsers, usersSelectors } from "features/Users/usersSlice";
import { getDivisionDataSelector, getJobDataSelector } from "features/Job/jobSlice";
import { getLocalStorage } from "utility";

const ImageInteractionMessages = ({ nid, hash }) => {
  const dispatch = useDispatch();
  const scrollableNodeRef = useRef();
  const job = useSelector(getJobDataSelector);
  const division = useSelector(getDivisionDataSelector);
  const allCommentIds = useSelector(commentsSelectors.selectIds);
  const allComments = useSelector(commentsSelectors.selectAll);
  const loading = useSelector(getCommentsLoadingSelector);
  const availableUsers = useSelector(usersSelectors.selectAll);
  const error = useSelector(getCommentsErrorSelector);
  const currentUser = useSelector((state) => state.auth.user.data);

  const sortedIds = [...allCommentIds].sort((a, b) => b - a);

  const [page, setPage] = useState(0);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [submitting, setSubmitting] = useState(false);

  const getUsers = useCallback(
    (nid, params) => {
      return dispatch(
        fetchUsers({
          id: nid,
          assignedOnly: 0,
          params,
        })
      );
    },
    [dispatch]
  );

  const handleLoad = useCallback(() => {
    setPage(0);
    if(!hash){
      dispatch(fetchGeneralFileComments({ id: nid, params: {} }));
    }
    else{
      dispatch(fetchGeneralFilePublicComments({ hash: hash, params: {} }));
    }
  }, [dispatch, nid, hash]);

  useEffect(() => {
    let promise;
    if (nid) {
      if(!hash){
        promise = dispatch(fetchGeneralFileComments({ id: nid, params: {} }));
      }
      else{
        promise = dispatch(fetchGeneralFilePublicComments({ hash: hash, params: {} }));
      }
    }

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [nid, hash, dispatch]);

  const handleLoadMore = useCallback(async () => {
    const params = {
      page: page + 1,
    };

    if(!hash){
      await dispatch(
        fetchMoreGeneralFileComments({
          id: nid,
          params,
        })
      );
    }
    else {
      await dispatch(
        fetchMoreGeneralFilePublicComments({
          hash: hash,
          params,
        })
      );
    }

    setPage(page + 1);
  }, [dispatch, nid, hash, page]);

  const handleScrollToMessage = useCallback(() => {
    scrollableNodeRef.current.scrollTop = 0;
  }, [scrollableNodeRef]);

  const handleCreateComment = (files, handleResetUploads, recaptchaToken) => {
    setSubmitting(true);
    const html = getContentAsHTML(editorState);
    if (!editorState.getCurrentContent().hasText()) return;

    const params = {
      id: `${Number(first(sortedIds)) + 1}`,
      field_comment_long: html,
    };

    const afterPost = (response) => {
      if(!response?.error) {
        handleScrollToMessage();
        const newState = EditorState.moveFocusToEnd(
          EditorState.push(
            editorState,
            ContentState.createFromText(""),
            "remove-range"
          )
        );
        setEditorState(EditorState.moveFocusToEnd(newState));
        handleResetUploads();
      }
      setSubmitting(false);
    };

    if(!hash){
      params.user = currentUser;
      dispatch(postGeneralFileComment({ id: nid, params }))
        .then(afterPost);
    }
    else{
      params.commenter = getLocalStorage('public_commenter');
      params.recaptcha_token = recaptchaToken;
      dispatch(postGeneralFilePublicComment({ id: nid, hash: hash, params }))
        .then(afterPost);
    }
  };

  const handleDelete = useCallback(
    (comment) => {
      dispatch(
        deleteGeneralFileComment({ id: comment.cid, division: division.nid, comment })
      );
    },
    [dispatch, division]
  );

  const handleEdit = useCallback(
    (params, comment) => {
      dispatch(patchGeneralFileComment({ id: comment.cid, params, comment }));
    },
    [dispatch]
  );

  return (
    <div className="messages-frame h-full p-2 flex flex-col justify-between bg-gray">
      {error && (
        <Alert
          kind="negative"
          margin="none"
          disableElevation
          disableRoundedCorners
        >
          {error}
        </Alert>
      )}
      <div
        className="discussion-container scrollarea flex-[1_1_auto] overflow-auto flex flex-col-reverse relative px-0 grid-lg:px-5"
        id="scrollableDiv"
        ref={scrollableNodeRef}
      >
        {loading ? (
          <DefaultLoader />
        ) : size(allComments) > 0 ? (
          <Comments
            onLoadMore={handleLoadMore}
            page={page}
            onRefresh={handleLoad}
            onDelete={handleDelete}
            onPatch={handleEdit}
            allowEditVisibility
            client={job?.field_phoenix_client?.title}
          />
        ) : (
          <Empty message="There are no comments added yet">
            <GoCommentDiscussion />
          </Empty>
        )}
      </div>
      <div className="pb-0 grid-sm:pb-4">
        <TextEditor
          isSubmitting={submitting}
          disableTools={submitting}
          placeholder={`Add Comment`}
          editorState={editorState}
          setEditorState={setEditorState}
          handleCreateComment={handleCreateComment}
          nid={division.nid}
          hash={hash}
          loadUsers={getUsers}
          availableUsers={availableUsers}
          // refreshComments={handleLoad}
          defaultMentionOptions={[
            {
              name: `Job`,
              link: "job",
              avatar: null,
              uid: "job",
            },
          ]}
          simpleToolbar
        />
      </div>
    </div>
  );
};

ImageInteractionMessages.propTypes = {};

export default ImageInteractionMessages;
