import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Popper, DialogContent, Typography } from "@mui/material";
import moment from "moment";
import styled from "styled-components";
import { BiCalendarPlus } from "react-icons/bi";

import AutocompleteFormField from "components/AutocompleteFormField";
import { DateFormField } from "common/Fields";
import { times } from "utility";
import { DialogForm } from "common/Dialog";

import { getDivisionDataSelector, patchJobDivision } from "../jobSlice";

const round = (date, duration, method) => {
  return moment(Math[method](+date / +duration) * +duration);
};

const CustomPopper = function (props) {
  return <Popper {...props} style={{ width: 150 }} placement="bottom-start" />;
};

const Action = styled.div`
  height: 64px;
  border: 1px solid var(--color-gray-lighter);
  background-color: var(--color-gray-light);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-gray-dark);
  cursor: pointer;
  transition: background-color 0.25s;

  &:hover {
    background-color: var(--color-gray-lighter);
    border: 1px solid var(--color-gray-medium);
  }
`;

const AddDateRange = ({ title, buttonText, nid, field }) => {
  const dispatch = useDispatch();
  const division = useSelector(getDivisionDataSelector);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = (data) => {
    let value = null;
    let start = null;
    if(data.date && data.from.value){
      start = moment(
        moment(data.date).format("YYYY-MM-DD") + "T" + data.from.value
      ).format();
      value = { value: start };
    }
    let end = null;
    if(data.date && data.to.value){
      end = moment(
        moment(data.date).format("YYYY-MM-DD") + "T" + data.to.value
      ).format();
      if(!value){
        value = { end_value: end };
      }
      else{
        value = {
          ...value,
          end_value: end
        };
      }
    }
    if(value){
      value = [value];
    }

    dispatch(
      patchJobDivision({
        id: nid,
        params: { [field]: value },
        division,
      })
    );

    onClose();
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Action onClick={handleOpen}>
        <BiCalendarPlus />
        <Typography>{buttonText}</Typography>
      </Action>
      <DialogForm
        open={open}
        onClose={onClose}
        maxWidth="xs"
        title={title}
        initialValues={{
          date: moment(),
          from: {
            name: round(
              moment(),
              moment.duration(15, "minutes"),
              "ceil"
            ).format("h:mm A"),
            value: round(
              moment(),
              moment.duration(15, "minutes"),
              "ceil"
            ).format("HH:mm"),
          },
          to: {
            name: round(
              moment(),
              moment.duration(15, "minutes"),
              "ceil"
            ).format("h:mm A"),
            value: round(
              moment(),
              moment.duration(15, "minutes"),
              "ceil"
            ).format("HH:mm"),
          },
        }}
        disablePadding
        onSubmit={handleSubmit}
        overrideDirty
      >
        {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xxs={4}>
                <DateFormField
                  fullWidth
                  disableToolbar
                  variant="inline"
                  format="MM/DD/YYYY"
                  id="date"
                  label="Arrival Date"
                  margin="normal"
                  name="arrival"
                  KeyboardButtonProps={{
                    "aria-label": "change arrival date",
                  }}
                />
              </Grid>
              <Grid item xxs={4}>
                <AutocompleteFormField
                  name="from"
                  margin="normal"
                  label="From"
                  disabled={isSubmitting}
                  disableClearable
                  options={times}
                  PopperComponent={CustomPopper}
                />
              </Grid>
              <Grid item xxs={4}>
                <AutocompleteFormField
                  name="to"
                  margin="normal"
                  label="To"
                  disabled={isSubmitting}
                  disableClearable
                  PopperComponent={CustomPopper}
                  options={times}
                />
              </Grid>
            </Grid>
          </DialogContent>
        )}
      </DialogForm>
    </>
  );
};

AddDateRange.propTypes = {};

export default AddDateRange;
