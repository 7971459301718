import React from "react";
import { useSelector, connect } from "react-redux";
import moment from "moment";

import { RouteDialog } from "../../Dialog";
import config from "../../../config";
import { postNode } from "../../../actions/paragraphs";
import { DialogForm } from "../../Form";
import { TextFormField } from "common/Fields";
import { DateFormField } from "common/Fields";
import { SelectFormField } from "common/Fields";

const getFields = (types, statuses) => {
  return [
    {
      label: "Subject",
      name: "field_crmc_subject",
      multiline: false,
      type: "text",
    },
    {
      label: "Date",
      name: "field_crmc_time",
      multiline: false,
      type: "date",
    },
    {
      label: "Status",
      name: "field_crmc_status",
      multiline: false,
      type: "select",
      options: statuses.map((status) => ({
        label: status.name,
        value: status.name,
      })),
    },
    {
      label: "Type of call",
      name: "field_crmc_call_type",
      multiline: false,
      type: "select",
      options: types.map((type) => ({
        label: type.name,
        value: type.name,
      })),
    },
    {
      label: "Comments",
      name: "field_crmc_notes",
      multiline: true,
      type: "text",
    },
  ];
};

const Form = ({ url, nid, addParagraph, loadCRMCalls, statuses, types }) => {
  const user = useSelector((state) => state.auth.user.data);
  const { uid } = user;
  const fields = getFields(statuses, types);
  const handleSubmit = async (
    data,
    setSubmitting,
    setMessage,
    successCallback,
    errorCallback
  ) => {
    const params = {
      _links: {
        type: {
          href: `${config.api_url}/rest/type/node/crm_call`,
        },
      },
      // _meta: {
      //   parent_entity: "node",
      //   parent_field: "field_crm_calls",
      //   parent_id: nid,
      // },
      field_crmc_user: [{ target_id: uid }],
      title: [{ value: data.field_crmc_subject }],
      field_crmc_parent_node: [{ target_id: nid }],
      field_crmc_time: [{ value: moment(data.field_crmc_time).format() }],
      field_crmc_status: [{ value: data.field_crmc_status }],
      field_crmc_call_type: [{ value: data.field_crmc_call_type }],
      field_crmc_notes: [{ value: data.field_crmc_notes }],
    };

    const response = await addParagraph(params);
    if (response.status === 201) {
      await loadCRMCalls();
      successCallback("Call added successfully.");
    } else {
      errorCallback(setMessage, response, []);
    }
  };

  return (
    <DialogForm
      submitText="Add"
      url={url}
      path="add"
      initialValues={{
        field_crmc_subject: "",
        field_crmc_time: moment(),
        field_crmc_status: "",
        field_crmc_call_type: "",
        field_crmc_notes: "",
      }}
      onSubmit={handleSubmit}
    >
      {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
        <div>
          {fields.map((f) => (
            <React.Fragment key={f.name}>
              {f.type === "text" ? (
                <TextFormField
                  fullWidth
                  htmlFor={f.name}
                  name={f.name}
                  label={f.label}
                  margin="normal"
                  disabled={isSubmitting}
                  type="text"
                  multiline={f.multiline}
                  labelwidth={70}
                />
              ) : f.type === "select" ? (
                <SelectFormField
                  fullWidth
                  name={f.name}
                  label={f.label}
                  margin="normal"
                  size="small"
                  disabled={isSubmitting}
                  options={f.options}
                  multiple={f.multiple}
                />
              ) : (
                <DateFormField
                  fullWidth
                  disableToolbar
                  margin="normal"
                  variant="inline"
                  format="MM/DD/YYYY"
                  id={f.name}
                  label={f.label}
                  name={f.name}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </DialogForm>
  );
};

const CallsForm = ({ url, ...rest }) => {
  return (
    <RouteDialog
      route="add"
      path="add"
      url={url}
      maxWidth="xs"
      title="Add Call"
    >
      <Form url={url} {...rest} />
    </RouteDialog>
  );
};

CallsForm.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
  addParagraph: (params) => dispatch(postNode("node", params)),
});

export default connect(null, mapDispatchToProps)(CallsForm);
