import React, { useState } from "react";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
// import {ReadMoreWrapper} from "./Styled";

const ReadMore = ({ children }) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  if(!children
    || (typeof children === 'string' && children.length <= 150)
    || (Array.isArray(children) && children.length <= 3)){
    return children;
  }

  return (
    <>
    {/* <ReadMoreWrapper> */}
      {isReadMore ? (
        <>
        {typeof children === 'string' ? children.slice(0, 150) : children.slice(0, 4)}...
        <ExpandMore onClick={toggleReadMore} className="cursor-pointer" />
        </>
      ) : (
        <>
        {children}
        <ExpandLess onClick={toggleReadMore} className="cursor-pointer" />
        </>
      )}
    {/* </ReadMoreWrapper> */}
    </>
  );
};

export default ReadMore;
