import React from "react";
import { Field } from "formik";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { TextField } from "@mui/material";
import { IconButton } from "@mui/material";
import { Nullable } from "./FieldStyles";

const TimeFormField = ({ name, style, datePicker, nullable, ...props }) => {
  return (
    <Field name={name} style={style}>
      {({
        field, // { name, value, onChange, onBlur }
        form,
        meta,
      }) => {
        const handleDateChange = (value) => {
          if (!value) {
            form.setFieldValue(name, null);
          } else {
            form.setFieldValue(name, value);
          }
        };

        const handleBlur = () => form.setFieldTouched(name, true);
        const errorText = meta.error && meta.touched ? meta.error : "";
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              value={field.value}
              onChange={handleDateChange}
              onBlur={handleBlur}
              error={!!errorText}
              helperText={errorText}
              renderInput={(params) => {
                if(nullable){
                  params.InputProps.style = {
                    ...params.InputProps?.style,
                    paddingRight: 0,
                  };
                }
                return (
                  <TextField
                    {...params}
                    size="small"
                    fullWidth
                    variant={props?.inputVariant}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment:
                        <>
                        {params.InputProps.endAdornment}
                        {nullable ? <IconButton onClick={() => form.setFieldValue(name, null)}><Nullable  /></IconButton> : null}
                        </>
                    }}
                  />);
                }}
              {...props}
            />
          </LocalizationProvider>
        );
      }}
    </Field>
  );
};

TimeFormField.propTypes = {};

export default TimeFormField;
