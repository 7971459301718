export const complianceColumns = [
  {
    id: "member_name",
    label: "Provider",
    minWidth: 200,
  },
  {
    id: "start_date",
    label: "Start Date",
    minWidth: 200,
  },
  { id: "compliance_change_reason", label: "Change Reason", minWidth: 200 },
  { id: "lift_method", label: "Lift Method", minWidth: 150 },
  { id: "lift_date", label: "Lift Date", minWidth: 200 },
  { id: "compliance_status", label: "Compliance Status", minWidth: 200 },
  {
    id: "state",
    label: "State",
    width: 100,
  },
  {
    id: "region",
    label: "Region",
    width: 150,
  },
  { id: "description", label: "Description", minWidth: 300, notSortable: true },
  {
    id: "actions",
    label: "",
    width: 500,
    notSortable: true,
  },
];

export const memberComplianceColumns = [
  {
    id: "start_date",
    label: "Start Date",
    minWidth: 200,
  },
  { id: "compliance_change_reason", label: "Change Reason", minWidth: 200 },
  { id: "lift_method", label: "Lift Method", minWidth: 150 },
  { id: "lift_date", label: "Lift Date", minWidth: 200 },
  { id: "compliance_status", label: "Compliance Status", minWidth: 200 },
  { id: "description", label: "Description", minWidth: 300 },
  {
    id: "actions",
    label: "",
    width: 500,
    notSortable: true,
  },
];
