import React from "react";
import PropTypes from "prop-types";
import { Avatar as MuiAvatar, Tooltip } from "@mui/material";
import chroma from "chroma-js";

import { stringToColor } from "../../utility";
import { IoPerson } from "react-icons/io5";

const sizes = {
  small: { width: "30px", height: "30px", fontSize: "0.875rem" },
  normal: {},
  large: { width: "100px", height: "100px", fontSize: "2rem" },
};

const Avatar = ({ title, link, size="normal", outline, variant="circle", type="user", styles, tooltip, ...rest }) => {
  let style = {
    background: "var(--icon-radial)",
    color: "var(--color-gray-lightest)",
    border: outline ? "2px solid var(--color-white)" : "none",
    boxShadow: outline ? "0 0 0 2px var(--color-gray-dark)" : "none",
    ...sizes[size],
  };
  if(type === 'org') {
    style.background = stringToColor(title);
    const bkgIsDark = chroma(style.background).get("lab.l") < 80 ? true : false;
    style.color = bkgIsDark ? "var(--color-white)" : chroma(style.background).darken(5);
  }
  else if(type !== 'manual'){
    style.alignItems = 'flex-start';
  }

  if(styles){
    style = {...style, ...styles};
  }

  const avatar = (
    type === 'user' ? (
      <MuiAvatar
        alt={title}
        style={style}
        variant={variant}
        src={link ? link : null}
        {...rest}
      >
        <IoPerson className="w-[85%] h-auto mt-[21%]" />
      </MuiAvatar>
    ) : (
      <MuiAvatar
        alt={title ? title.trim() : ''}
        style={style}
        variant={variant}
        src={link ? link : null}
        {...rest}
      />
    )
  );
  if(tooltip){
    return (<Tooltip title={title}>{avatar}</Tooltip>);
  }
  else{
    return avatar;
  }
};

const { string, oneOf } = PropTypes;
Avatar.propTypes = {
  title: string,
  link: string,
  size: oneOf(["large", "small", "normal"]),
  variant: oneOf(["circle", "circular", "rounded", "square"]),
  type: string,
};

export default Avatar;
