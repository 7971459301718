import React from "react";
import styled from "styled-components";

const Styles = styled.div`
  display: flex;
  align-items: flex-start;

  & > .MuiFormControl-root {
    @media only screen and (max-width: 1090px) {
      width: unset;
      flex: 1;
    }
  }

  & > div .MuiFilledInput-root {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 0.25rem;
    width: ${({ inputWidth }) => (inputWidth ? `${inputWidth}px` : "unset")};
    flex: unset;

    @media only screen and (max-width: 1090px) {
      width: unset;
      flex: 1;
    }
  }

  & > div:first-child .MuiFilledInput-root {
    border-top-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 0;
  }

  & > div:last-child .MuiFilledInput-root {
    border-top-left-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 4px;
    margin-right: 0;
  }
`;

const FormControlGroup = ({ inputWidth, children }) => {
  return <Styles inputWidth={inputWidth}>{children}</Styles>;
};

FormControlGroup.propTypes = {};

export default FormControlGroup;
