import React from "react";
import { connect, useSelector } from "react-redux";
import Helmet from "react-helmet";
import { Typography, Button, TextField, Grid } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FilterList } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { GetApp } from "@mui/icons-material";
import { Autocomplete } from "@mui/material";
import moment from "moment";

import Alert from "common/Alert";
import ButtonDropdown from "common/ButtonDropdown";
import ReportSearch from "./ReportSearch";
import { CollapsibleTable, TableConfigure } from "../Table";
import LinkHeader from "common/LinkHeader";
import { exportBatch, clear } from "../../actions";
import { getRegions } from "../../actions/taxonomies";
import Paper from "../Paper";
import {
  Actions,
  ActionGroup,
  FilterMenu,
  ButtonGroup,
  Col,
  Cols,
} from "./Styled";
import { formatFilters, removeColumns } from "../../utility";
import {
  regionsSelectors,
  memberAffiliationsSelectors,
  statesSelectors,
  jobDivisionTypesSelectors,
  memberStatusSelectors,
} from "../../features/Taxonomies/taxonomiesSlice";
import { getReportMemberProximity } from "actions/reports";
import constants from "components/constants";

const useStyles = makeStyles((theme) => ({
  button: {
    background: constants.gradientBlack,
    paddingRight: "10px",
    color: constants.colorWhite,
    "&:hover": {
      background: constants.gradientBlack,
      color: constants.colorWhite,
    },
  },
}));

const columnSettings = {
  title: { minWidth: 200 },
  member_name: { minWidth: 400 },
  office_id: { minWidth: 150 },
  member_affiliation: { minWidth: 150 },
  date_received: { minWidth: 200 },
  date_assigned: { minWidth: 200 },
  customer_name: { minWidth: 300 },
  customer_location_name: { minWidth: 300 },
  location_city: { minWidth: 150 },
  location_state: { minWidth: 150 },
  location_postal_code: { minWidth: 150 },
  location_address_line1: { minWidth: 200 },
  job_division_member_status: { minWidth: 150 },
  member_status: { minWidth: 150 },
  member_city: { minWidth: 150 },
  member_state: { minWidth: 150 },
  member_postal_code: { minWidth: 150 },
  member_address_line1: { minWidth: 200 },
  member_proximity: { minWidth: 150 },
  other_member_proximities: { minWidth: 150 },
};

const createColumns = (columns) =>
  columns.map((column) => {
    column.id = column.index;
    column = {
      ...column,
      notSortable: !column.sortable,
      ...columnSettings[column.index],
    };
    return column;
  });

const createData = (
  title,
  member_name,
  office_id,
  member_affiliation,
  date_received,
  date_assigned,
  customer_name,
  customer_location_name,
  location_city,
  location_state,
  location_postal_code,
  location_address_line1,
  job_division_member_status,
  member_status,
  member_city,
  member_state,
  member_postal_code,
  member_address_line1,
  member_proximity,
  other_member_proximities,
  paths
) => {
  return {
    title: title ? (
      <LinkHeader to={paths.division} size="small" color="default">
        {title}
      </LinkHeader>
    ) : null,
    member_name: member_name ? (
      <LinkHeader to={paths.member} size="small" color="default">
        {member_name}
      </LinkHeader>
    ) : null,
    office_id,
    member_affiliation,
    date_received: (
      <Typography variant="body1">
        {date_received
          ? moment.unix(date_received).format("MMM Do, YYYY")
          : "--"}
      </Typography>
    ),
    date_assigned: (
      <Typography variant="body1">
        {date_assigned
          ? moment.unix(date_assigned).format("MMM Do, YYYY")
          : "--"}
      </Typography>
    ),
    customer_name: customer_name ? (
      <LinkHeader to={paths.customer} size="small" color="default">
        {customer_name}
      </LinkHeader>
    ) : null,
    customer_location_name: customer_location_name ? (
      <LinkHeader to={paths.customer_location} size="small" color="default">
        {customer_location_name}
      </LinkHeader>
    ) : null,
    location_city,
    location_state,
    location_postal_code,
    location_address_line1,
    job_division_member_status,
    member_status,
    member_city,
    member_state,
    member_postal_code,
    member_address_line1,
    member_proximity,
    collapsible: (
      <Cols>
        <Col>
          <Typography variant="subtitle1">Other Members in vicinity</Typography>
          {other_member_proximities?.map((mem) => (
            <div>
              <LinkHeader to={mem.path} size="small" color="default">
                {`${mem.name} (${Math.round(mem.proximity)} mi)`}
              </LinkHeader>
            </div>
          ))}
        </Col>
      </Cols>
    ),
  };
};

const initialFormData = {
  status: [],
  region_tid: [],
  affiliation_tid: [],
  state: [],
  division_type_tids: [],
  store_type: [],
  date_received: {
    type: "date-range",
    value: { from: null, through: null },
  },
};

const ReportMemberProximity = ({
  loadMembers,
  report=[],
  exportReport,
  clear,
  loading,
  pagination={
    count: 0,
    current_page: 0,
    total_pages: 0,
    display_columns: [],
  },
  error,
  org,
}) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filters, setFilters] = React.useState({});
  const [formData, setFormData] = React.useState(initialFormData);
  const [hiddenColumns, setHiddenColumns] = React.useState([]);
  const regions = useSelector(regionsSelectors.selectAll);
  const affiliations = useSelector(memberAffiliationsSelectors.selectAll);
  const states = useSelector(statesSelectors.selectAll);
  const services = useSelector(jobDivisionTypesSelectors.selectAll);
  const statuses = useSelector(memberStatusSelectors.selectAll);

  const handleExportReport = async () => {
    let filterSettings = formatFilters(filters);

    if (org) {
      filterSettings = {
        ...filterSettings,
        "filter[phx_client_nids]": org,
      };
    }

    await exportReport("/rest/report/job/member-proximity", {
      _export: "csv",
      keywords: searchTerm,
      ...filterSettings,
    });
  };

  const columns = React.useMemo(() => {
    if (loading) return [];
    const temp = createColumns(pagination.display_columns);
    removeColumns(["other_member_proximities"], temp);
    return temp;
  }, [pagination, loading]);

  const rows = React.useMemo(() => {
    if (loading) return [];
    return report.map((row) =>
      createData(
        row.title,
        row.member_name,
        row.office_id,
        row.member_affiliation,
        row.date_received,
        row.date_assigned,
        row.customer_name,
        row.customer_location_name,
        row.location_city,
        row.location_state,
        row.location_postal_code,
        row.location_address_line1,
        row.job_division_member_status,
        row.member_status,
        row.member_city,
        row.member_state,
        row.member_postal_code,
        row.member_address_line1,
        row.member_proximity,
        row.other_member_proximities,
        row._paths
      )
    );
  }, [report, loading]);

  const hideColumns = React.useMemo(() => {
    if (loading) return [];
    return hiddenColumns;
  }, [hiddenColumns, loading]);

  const handleChange = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateRangeChange = (value, name, index) => {
    const dateValue = formData[name].value;
    dateValue[index] = value;
    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value: { ...dateValue },
      },
    });
  };

  const applyFilters = () => {
    setFilters({ ...formData });
  };

  const resetFilters = () => {
    setFilters({});
    setFormData({
      ...initialFormData,
      date_received: {
        type: "date-range",
        value: { from: null, through: null },
      },
    });
  };

  return (
    <div>
      <Helmet>
        <title>Members Report | Phoenix Solutions</title>
        <meta name="description" content="Reports" />
      </Helmet>
      {error && <Alert kind="negative">{error.message}</Alert>}
      <LinkHeader to="/reports" color="default" size="small" back>
        Back to reports
      </LinkHeader>
      <Paper margin="none" style={{ marginTop: "1rem" }}>
        <Actions>
          <Typography variant="h6">Members</Typography>
          <ActionGroup>
            <TableConfigure
              columns={columns}
              hideColumns={setHiddenColumns}
              currentColumns={hiddenColumns}
            />
            <ButtonDropdown
              icon={FilterList}
              label="Filters"
              style={{ marginLeft: "1rem" }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              color="tertiary"
            >
              <FilterMenu>
                <Autocomplete
                  options={regions.map((region) => ({
                    label: region.name,
                    value: region.tid,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="HQ Region"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "region_tid");
                  }}
                  value={formData.region_tid}
                />
                <Autocomplete
                  options={affiliations.map((affiliation) => ({
                    label: affiliation.name,
                    value: affiliation.tid,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Affiliations"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "affiliation_tid");
                  }}
                  value={formData.affiliation_tid}
                />
                <Autocomplete
                  options={[
                    {
                      label: "Primary",
                      value: "Primary",
                    },
                    {
                      label: "Branch",
                      value: "Branch",
                    },
                    {
                      label: "County Agreement",
                      value: "County Agreement",
                    },
                  ]}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Store Type"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "store_type");
                  }}
                  value={formData.store_type}
                />
                <Autocomplete
                  options={states.map((state) => ({
                    label: state.label,
                    value: state.value,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "state");
                  }}
                  value={formData.state}
                />
                <Autocomplete
                  options={services.map((service) => ({
                    label: service.name,
                    value: service.tid,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Services"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "division_type_tids");
                  }}
                  value={formData.division_type_tids}
                />
                <Autocomplete
                  options={statuses.map((status) => ({
                    label: status.label,
                    value: status.machine_name,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Status"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "status");
                  }}
                  value={formData.status}
                />
                <Typography variant="body1" style={{ marginTop: "0.5rem" }}>
                  Date Received
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.date_received.value.from}
                        onChange={(value) =>
                          handleDateRangeChange(value, "date_received", "from")
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="From"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        KeyboardButtonProps={{
                          "aria-label": "change start date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.date_received.value.through}
                        onChange={(value) =>
                          handleDateRangeChange(
                            value,
                            "date_received",
                            "through"
                          )
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="Through"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        KeyboardButtonProps={{
                          "aria-label": "change start date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <ButtonGroup>
                  <Button
                    color="default"
                    disableElevation
                    size="small"
                    onClick={resetFilters}
                  >
                    Reset
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    size="small"
                    style={{ marginRight: "1rem" }}
                    onClick={applyFilters}
                  >
                    Apply
                  </Button>
                </ButtonGroup>
              </FilterMenu>
            </ButtonDropdown>
            <Button
              className={classes.button}
              size="small"
              startIcon={<GetApp />}
              onClick={handleExportReport}
            >
              Export
            </Button>
          </ActionGroup>
        </Actions>
        <ReportSearch setQuery={setSearchTerm} />
        <CollapsibleTable
          clientFilter={"phx_client_nids"}
          columns={columns}
          rows={rows}
          stickyHeader
          loading={loading}
          hideColumns={hideColumns}
          pageRequest={loadMembers}
          originalOrderBy="member_name"
          originalOrder="asc"
          filters={filters}
          pagination={pagination}
          search={searchTerm}
          size="small"
        />
      </Paper>
    </div>
  );
};

ReportMemberProximity.propTypes = {};

const mapStateToProps = (state) => ({
  loading: state.app.report.loading,
  report: state.app.report.data.data,
  pagination: state.app.report.data.pagination,
  error: state.app.report.error,
  statuses: state.app.memberStatus.data,
  regions: state.app.regions.data,
  affiliations: state.app.affiliations.data,
  states: state.app.states.data,
  services: state.app.jobDivisionTypes.data,
});

const mapDispatchToProps = (dispatch) => ({
  loadMembers: (params) => dispatch(getReportMemberProximity(params)),
  loadRegions: () => dispatch(getRegions()),
  exportReport: (url, params) => dispatch(exportBatch(url, params)),
  clear: () => dispatch(clear("report")),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportMemberProximity);
