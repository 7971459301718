import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";

import { IsNew, CellSubheading, CellHeading } from "components/Table/Styled";
import LinkHeader from "common/LinkHeader";
import { jobsColumnsBase } from "schemas/tables/jobs";
import { MuiTable, FilterMenu, SelectColumnFilter } from "common/Table";
import { useDebounce } from "hooks";
import Status from "components/Status";
import CollapsibleIcon from "common/CollapsibleIcon";
import { getDivisionTypeIndicator } from "features/Job/utils";
import TimeDisplayCell from "components/TimeDisplayCell";

import {
  divisionsSelectors,
  fetchLocationJobs,
  getDivisionsLoadingSelector,
  getDivisionsPaginationSelector,
} from "features/Divisions/divisionsSlice";
import {
  jobDivisionsStatusSelectors,
  jobDivisionTypesSelectors,
} from "features/Taxonomies/taxonomiesSlice";

const JobStatus = ({ machine_name, name }) => {
  return (
    <Status type={machine_name} indicator>
      {name}
    </Status>
  );
};

const formatFilterArray = (arr, field) => {
  const filters = {};
  arr.forEach((filter, i) => {
    filters[`filter[${field}][${i}]`] = filter.value;
  });

  return filters;
};

const Jobs = ({ nid }) => {
  const dispatch = useDispatch();
  const divisions = useSelector(divisionsSelectors.selectAll);
  const loading = useSelector(getDivisionsLoadingSelector);
  const pagination = useSelector(getDivisionsPaginationSelector);
  const clientNid = useSelector((state) => state.auth.current_client);
  const types = useSelector(jobDivisionTypesSelectors.selectAll);
  const statuses = useSelector(jobDivisionsStatusSelectors.selectAll);

  const [query, setQuery] = useState("");
  const searchQuery = useDebounce(query, 250);
  const [order, setOrder] = useState("changed");
  const [sort, setSort] = useState("desc");
  const [page, setPage] = useState(0);
  const [type, setType] = useState([]);
  const [status, setStatus] = useState([]);
  const [category, setCategory] = useState([]);
  const [ntp, setNtp] = useState([]);

  useEffect(() => {
    let params = {
      keywords: searchQuery,
      page,
      order,
      sort,
      ...formatFilterArray(type, "job_division_type_tid"),
      ...formatFilterArray(status, "status_tid"),
      ...formatFilterArray(category, "category"),
      ...formatFilterArray(ntp, "nte_is_set"),
    };

    if (clientNid) {
      params["filter[phx_client_nid]"] = clientNid;
    }

    if(nid){
      const promise = dispatch(fetchLocationJobs({ id: nid, params }));

      return () => {
        promise.abort();
      };
    }
  }, [
    dispatch,
    searchQuery,
    order,
    sort,
    clientNid,
    type,
    status,
    category,
    ntp,
    page,
    nid,
  ]);

  const data = useMemo(
    () =>
      divisions.map((division) => {
        const { icon: TypeIcon, color } = getDivisionTypeIndicator(
          division.job_division_type
        );
        return {
          changed: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <IsNew isNew={Number(division.is_new) === 1}>
                <div hidden>{division.changed}</div>
              </IsNew>
              <TimeDisplayCell time={division.changed} style={{ marginLeft: "1rem" }} />
            </div>
          ),
          icon: (
            <CollapsibleIcon
              collapse={true}
              color={color}
              tooltip={division.job_division_type}
            >
              {<TypeIcon />}
            </CollapsibleIcon>
          ),
          title: (
            <div>
              <LinkHeader
                to={division._paths.division}
                size="medium"
                color="primary"
              >
                {division.title}
              </LinkHeader>
              <CellSubheading>{division.job_division_type}</CellSubheading>
            </div>
          ),
          reference_number: (
            <Tooltip
              title={division.reference_number ? division.reference_number : ""}
            >
              <CellHeading noWrap style={{ width: 100 }}>
                {division.reference_number}
              </CellHeading>
            </Tooltip>
          ),
          created: <TimeDisplayCell time={division.created} />,
          nte_is_set: (
            <CellHeading
              uppercase
              style={{
                marginTop: "5px",
                color:
                  division.nte_is_set === "Yes"
                    ? "var(--color-green)"
                    : "var(--color-red)",
              }}
            >
              {division.nte_is_set === "Yes" ? (
                <AssignmentTurnedInIcon />
              ) : (
                <AssignmentLateIcon />
              )}
            </CellHeading>
          ),
          category: <CellHeading uppercase>{division.category}</CellHeading>,
          customer_name: (
            <div>
              <div>
                <LinkHeader
                  to={division._paths.customer}
                  size="medium"
                  noWrap
                  width={300}
                >
                  {division.customer_name}
                </LinkHeader>
              </div>
              <LinkHeader
                to={division._paths.customer_location}
                size="small"
                color="secondary"
                noWrap
                width={300}
              >
                {division.customer_location_name}
              </LinkHeader>
            </div>
          ),
          member_name: (
            <LinkHeader
              to={division._paths.member}
              size="medium"
              color="default"
              noWrap
              width={300}
            >
              {division.member_name}
            </LinkHeader>
          ),
          status: (
            <JobStatus
              machine_name={division.status}
              name={division.status_proper}
            />
          ),
        };
      }),
    [divisions]
  );

  return (
    <div style={{ flex: 1, overflow: "auto" }}>
      <FilterMenu
        search={{
          query,
          setQuery,
        }}
        filters={[
          {
            component: <SelectColumnFilter />,
            props: {
              filterValue: type,
              setFilter: setType,
              label: "Job Type",
              id: "job-type",
              filterOptions: types.map((obj) => ({
                label: obj.name,
                value: obj.tid,
              })),
              multiple: true,
            },
          },
          {
            component: <SelectColumnFilter />,
            props: {
              filterValue: status,
              setFilter: setStatus,
              label: "Status",
              id: "job-status",
              filterOptions: statuses.map((obj) => ({
                label: obj.name,
                value: obj.tid,
              })),
              multiple: true,
            },
          },
          {
            component: <SelectColumnFilter />,
            props: {
              filterValue: category,
              setFilter: setCategory,
              label: "Category",
              id: "job-category",
              filterOptions: [
                {
                  label: "CS",
                  value: "cs",
                },
                {
                  label: "G2",
                  value: "g2",
                },
              ],
              multiple: true,
            },
          },
          {
            component: <SelectColumnFilter />,
            props: {
              filterValue: ntp,
              setFilter: setNtp,
              label: "NTP",
              id: "job-ntp",
              filterOptions: [
                {
                  label: "Yes",
                  value: "yes",
                },
                {
                  label: "No",
                  value: "no",
                },
              ],
              multiple: true,
            },
          },
        ]}
      />
      <MuiTable
        rows={data}
        columns={jobsColumnsBase}
        loading={loading}
        size="medium"
        stickyHeader
        flex
        pagination={pagination}
        sort={sort}
        setSort={setSort}
        order={order}
        setOrder={setOrder}
        handleChangePage={(e, newPage) => setPage(newPage)}
      />
    </div>
  );
};

Jobs.propTypes = {};

export default Jobs;
