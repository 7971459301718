import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AutocompleteFormField } from "common/Fields";
import { toArray, size, first } from "lodash";
import {
  fetchMembers,
  getMembersLoadingSelector,
  membersSelectors,
} from "features/Members/membersSlice";

const MemberSelector = ({ phxClientNid, ...props }) => {
  const dispatch = useDispatch();
  const members = useSelector(membersSelectors.selectAll);
  const loadingMembers = useSelector(getMembersLoadingSelector);
  const user = useSelector((state) => state.auth.user.data);

  const getMembers = useCallback(
    (query) => {
      return dispatch(
        fetchMembers({
          keywords: query,
          "filter[phx_client_nids]": phxClientNid,
        })
      );
    },
    [dispatch, phxClientNid]
  );


  let defaultMember = {};
  if(user.roleType === 'member' && size(user?.field_members)){
    let firstMember = first(user.field_members);
    defaultMember = {
      nid: firstMember.nid,
      name: firstMember.title,
    };
  }

  return (
    <AutocompleteFormField
      variant="outlined"
      label="Provider"
      size="small"
      options={toArray(members).map((obj) => ({
        name: obj.member_name,
        nid: obj.member_nid,
      }))}
      getOptionLabel={(option) => (option.name ? option.name : "")}
      loading={loadingMembers}
      fetchData={getMembers}
      defaultValue={defaultMember}
      {...props}
    />
  );
};

MemberSelector.propTypes = {};

export default MemberSelector;
