import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import advertisementsAPI from "./advertisementsAPI";

const namespace = "advertisements";

export const fetchAdvertisements = createAsyncThunk(
  "advertisements/fetchAdvertisements",
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await advertisementsAPI.getAdvertisements({
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAdvertisementsAdmin = createAsyncThunk(
  "advertisements/fetchAdvertisementsAdmin",
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await advertisementsAPI.getAdminAdvertisements({
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postAdvertisement = createAsyncThunk(
  "advertisements/postAdvertisement",
  async (params, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await advertisementsAPI.addAdvertisement({
        params,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchAdvertisement = createAsyncThunk(
  "advertisements/patchAdvertisement",
  async ({ id, params }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await advertisementsAPI.updateAdvertisement({
        id,
        params,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchAddAdmin = createAsyncThunk(
  "advertisements/patchAddAdmin",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await advertisementsAPI.addAdmin({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchRemoveAdmin = createAsyncThunk(
  "advertisements/patchRemoveAdmin",
  async ({ id, params, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      const response = await advertisementsAPI.removeAdmin({
        id,
        params,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return response.data;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteAdvertisement = createAsyncThunk(
  "advertisements/deleteAdvertisement",
  async ({ id, config }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token =
        getState().auth.user?.data?._processed?.phx_offsite_api_token;
      await advertisementsAPI.deleteAdvertisement({
        id,
        config: config
          ? config
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
      });

      return id;
    } catch (err) {
      const error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const advertisementsAdapter = createEntityAdapter({
  selectId: (result) => result.id,
  sortComparer: (a, b) => b.id - a.id,
});

const advertisementsSlice = createSlice({
  name: namespace,
  initialState: advertisementsAdapter.getInitialState({
    loading: true,
    error: null,
  }),
  reducers: {
    resetAdvertisements: (state, action) => {
      state.loading = true;
      state.error = null;
      advertisementsAdapter.removeAll(state);
    },
  },
  extraReducers: {
    [fetchAdvertisements.pending](state, action) {
      state.loading = true;
      state.error = null;
      advertisementsAdapter.removeAll(state);
    },
    [fetchAdvertisements.fulfilled](state, { payload: advertisements }) {
      state.loading = false;
      state.error = null;
      advertisementsAdapter.setAll(state, advertisements);
    },
    [fetchAdvertisements.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchAdvertisementsAdmin.pending](state, action) {
      state.loading = true;
      state.error = null;
      advertisementsAdapter.removeAll(state);
    },
    [fetchAdvertisementsAdmin.fulfilled](state, { payload: advertisements }) {
      state.loading = false;
      state.error = null;
      advertisementsAdapter.setAll(state, advertisements);
    },
    [fetchAdvertisementsAdmin.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [deleteAdvertisement.fulfilled](state, { payload: id }) {
      advertisementsAdapter.removeOne(state, id);
    },
    [postAdvertisement.fulfilled](state, { payload: advertisement }) {
      advertisementsAdapter.addOne(state, advertisement);
    },
    [patchAddAdmin.fulfilled](state, { payload: advertisement }) {
      advertisementsAdapter.updateOne(state, {
        id: advertisement.id,
        changes: advertisement,
      });
    },
    [patchAddAdmin.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [patchRemoveAdmin.fulfilled](state, { payload: advertisement }) {
      advertisementsAdapter.updateOne(state, {
        id: advertisement.id,
        changes: advertisement,
      });
    },
    [patchRemoveAdmin.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [patchAdvertisement.fulfilled](state, { payload: advertisement }) {
      advertisementsAdapter.updateOne(state, {
        id: advertisement.id,
        changes: advertisement,
      });
      state.error = null;
    },
    [patchAdvertisement.rejected](state, action) {
      state.loading = false;
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
  },
});

export const advertisementsSelectors = advertisementsAdapter.getSelectors(
  (state) => state.advertisements
);

export const { resetAdvertisements } = advertisementsSlice.actions;

export default advertisementsSlice.reducer;
