import styled from "styled-components";
import constants from "../constants";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: ${constants.breakpoints.md};
    max-height: calc(var(--vh, 1vh) * 100);
  }
`;
