import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";

import Avatar from "common/Avatar";
import {
  Container,
  Actions,
  Content,
  Heading,
  Clients,
  Subject,
  Body,
  Subtext,
} from "./Styled";

const formatSubText = (date, changed, status, type) => {
  return `${moment.unix(date).format("MM/DD/YY - h:mm A")}${
    status ? ` | Status: ${status}` : ""
  }${type ? `| Type: ${type}` : ""}${
    changed && date !== changed ? ` (edited)` : ""
  }`;
};

const MemberNote = ({
  editable,
  name,
  timestamp,
  changed,
  status,
  type,
  subject,
  clients,
  body,
  avatar,
  handleEdit,
}) => {
  return (
    <Container>
      <Avatar title={name} link={avatar} />
      <Content>
        {editable && (
          <Actions>
            <IconButton size="small" onClick={handleEdit}>
              <Edit />
            </IconButton>
          </Actions>
        )}
        <Heading>
          {name}
          <Subtext>{formatSubText(timestamp, changed, status, type)}</Subtext>
        </Heading>
        <>
          {subject && subject !== "(No subject)" && (
            <Subject>{subject}</Subject>
          )}
          {(clients && clients !== "") && (
            <Clients>({clients})</Clients>
          )}
          <Body>
            {body &&
              body.split("\n").map((paragraph, i) => (
                <React.Fragment key={i}>
                  {paragraph}
                  <br />
                </React.Fragment>
              ))}
          </Body>
        </>
      </Content>
    </Container>
  );
};

MemberNote.propTypes = {};

const mapStateToProps = (state) => ({
  token: state.auth.token,
});

export default connect(mapStateToProps)(MemberNote);
