import React, { useRef, useEffect, useState }  from "react";
import "./MapStreetView.scss";
import { FiMaximize2, } from "react-icons/fi";
import Dialog from "common/Dialog";
import { Tooltip } from "@mui/material";
import config from "config";

/**
 * Created a Street View component manually instead of using a plugin.
 * This was to prevent Google API from being loaded multiple times; however,
 * this also became handy to customize how the streetview loaded
 * and to make sure it was pointed to the right place
 */

const MapStreetView = ({
  geo,
  maxHeight,
  address,
  setLoaded,
  compact,
  inDialog,
}) => {
  const mapRef = useRef();
  const [streetView, setStreetView] = useState(null);
  const [geoCoords, setGeoCoords] = useState('');
  const [showExpander, setShowExpander] = useState(false);
  const [open, setOpen] = useState(false);
  const imgBase = `https://maps.googleapis.com/maps/api/streetview?size=125x100&fov=100&pitch=0&key=${config.google_api}`;
  const [streetViewImg, setStreetViewImg] = useState(`${imgBase}&location=${geo.lat},${geo.lng}`);

  useEffect(() => {
    const _geoCoords = JSON.stringify(geo);
    if (window?.google && (streetView == null || geoCoords !== _geoCoords)) {
      setGeoCoords(_geoCoords);

      let location = null;
      if(geo && typeof geo?.lat !== 'undefined' && typeof geo?.lng !== 'undefined'){
        location = {lat: Number(geo.lat), lng: Number(geo.lng)};
      }
      else{
        console.log("Invalid geo coordinates");
        return;
      }

      const streetViewPanoramaOptions = {
        position: location,
        pov: {heading: 100, pitch: 0},
        zoom: 1,
        visible: false,
      }
      if(!inDialog){
        streetViewPanoramaOptions.linksControl = false;
        streetViewPanoramaOptions.panControl = false;
        streetViewPanoramaOptions.addressControl = false;
        streetViewPanoramaOptions.fullscreenControl = false;
        streetViewPanoramaOptions.enableCloseButton = false;
        streetViewPanoramaOptions.zoomControl = false;
      }

      const _streetView = new window.google.maps.StreetViewPanorama(
        mapRef.current,
        streetViewPanoramaOptions
      );

    //  Need to get the directions coordinates in order for streetview to show the correct location
      var directionsService = new window.google.maps.DirectionsService();
      var request = {
        origin: address,
        destination: address,
        travelMode: window.google.maps.TravelMode.DRIVING
      };
      directionsService.route(request, function(response, status) {
        if (status === window.google.maps.DirectionsStatus.OK) {
          var cameraLatLng = response.routes[0].legs[0].steps[0].start_location;
          var angle = window.google.maps.geometry.spherical.computeHeading(cameraLatLng, location);

        //  Use Street View Service to determine if the location has Street View
          const sv = new window.google.maps.StreetViewService();
          sv.getPanorama({
            location: cameraLatLng,
            radius: 50,
          })
            .then((data) => {
              _streetView.setPano(data.data.location.pano);
              _streetView.setPov({heading: angle, pitch: 0});
              _streetView.setVisible(true);
              setStreetViewImg(`${imgBase}&location=${data.data.location.latLng.lat()},${data.data.location.latLng.lng()}`);

              setShowExpander(true);
              if(inDialog){
                new window.google.maps.Marker({
                  position: location,
                  map: _streetView,
                });
              }

              if(setLoaded){
                setLoaded(true);
              }
            })
            .catch((e) => {
              console.error("Street View data not found for this location.");
              _streetView.setVisible(false);
              setShowExpander(false);
              if(setLoaded){
                setLoaded(false);
              }
            });
        }
      });

      setStreetView(_streetView);
    }
  }, [window.google, geo]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
    <>
    <div className={`map-street-view ${compact ? 'mobile' : ''}`} style={{ height: (maxHeight ? maxHeight : "100%") }} >
      <div
        ref={mapRef}
        className="pano"
        style={{
				  height: '100%'
			  }}
      />
      {(!inDialog && showExpander) &&
        <div className="maximize" onClick={() => setOpen(true)}>
          {!compact ? (
            <Tooltip title="Expand Street View to fullscreen">
              <span>
              <FiMaximize2 className="icon"/>
              </span>
            </Tooltip>
          ) : (
            <Tooltip title="View in Google Street View">
              <div className="photo">
                <img src={streetViewImg} alt="streetview"/>
              </div>
            </Tooltip>
          )}
        </div>
      }
    </div>
    {!inDialog &&
      <Dialog
        fillScreen
        open={open}
        onClose={() => setOpen(false)}
        title="Street View"
      >
        <MapStreetView
          geo={geo}
          address={address}
          inDialog={true}
        />
      </Dialog>
    }
    </>
  );
};

MapStreetView.propTypes = {};

export default MapStreetView;
