// Base for all other markers
// Can be used as standalone marker
import React from "react";
import omitBy from "lodash/omitBy";
import isString from "lodash/isString";
import isNumber from "lodash/isNumber";
import compose from "recompose/compose";
import defaultProps from "recompose/defaultProps";
import mapProps from "recompose/mapProps";
import withPropsOnChange from "recompose/withPropsOnChange";
import pure from "recompose/pure";
import { Motion, spring } from "react-motion";
import cx from "classnames";

export const baseMarker = ({
  theme,
  animated,
  content,
  children,
  defaultMotionStyle,
  motionStyle,
  defaultScale,
  onMouseEnter,
  onMouseLeave,
  onMouseClick,
  color,
  cluster,
  bundle,
  icon,
  iconSettings,
}) => {
  const isClustered = cluster > 1;
  let iconType = 'circle';
  if(iconSettings){
    iconType = iconSettings.type;
  }
  else if(icon){
    iconType = 'icon';
  }
  else if(bundle === "member" ||
    bundle === "affiliate" ||
    bundle === "parent" ||
    bundle === "child" ||
    bundle === "other"){
    iconType = 'circled_square';
  }

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={isClustered ? null : onMouseClick}
    >
      {animated && !isClustered ? (
        <Motion defaultStyle={defaultMotionStyle} style={motionStyle}>
          {({ scale }) =>
            iconType === 'circled_square' ? (
              <svg
                width="26px"
                height="26px"
                style={{
                  transform: `translate3D(0,0,0) scale(${scale + 0.1}, ${
                    scale + 0.1
                  })`,
                  transformOrigin: "6px 8px",
                  position: "absolute",
                  zIndex: 1,
                  top: "-7px",
                  left: "-8px",
                }}
              >
                <path
                  d="M11.3-0.4H1.1c-0.9,0-1.6,0.7-1.6,1.6v10.2c0,0.9,0.7,1.6,1.6,1.6h10.2c0.9,0,1.6-0.7,1.6-1.6V1.2 C13,0.3,12.2-0.4,11.3-0.4z"
                  fill={color}
                  stroke="black"
                  strokeWidth={2}
                />
                <path
                  d="M11.3-0.4H1.1c-0.9,0-1.6,0.7-1.6,1.6v10.2c0,0.9,0.7,1.6,1.6,1.6h10.2c0.9,0,1.6-0.7,1.6-1.6V1.2 C13,0.3,12.2-0.4,11.3-0.4z"
                  fill={color}
                  stroke="white"
                  strokeWidth={2}
                />
              </svg>
            ) : (
              <div
                style={{
                  transform: `translate3D(0,0,0) scale(${scale}, ${scale})`,
                  position: "absolute",
                  zIndex: 1,
                  top: isClustered ? "-16px" : "-7px",
                  left: isClustered ? "-16px" : "-7px",
                  display: "flex",
                  width: isClustered ? "32px" : "14px",
                  height: isClustered ? "32px" : "14px",
                  margin: 0,
                  padding: 0,
                  transformOrigin: isClustered ? "16px 16px" : "7px 7px",
                  textDecoration: "inherit",
                  border: "2px solid #fff",
                  borderRadius: "50%",
                  backgroundColor: color,
                  boxShadow: "0 0 3px #999",
                  speak: "none",
                }}
              >
                {isClustered && (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white",
                      fontSize: "12px",
                    }}
                  >
                    {cluster}
                  </div>
                )}
                {icon && (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white",
                      fontSize: "12px",
                    }}
                  >
                    <img src={icon} alt="Map Icon" style={{width: "32px", height: "32px", cursor: "pointer"}} />
                  </div>
                )}
                {content}
              </div>
            )
          }
        </Motion>
      ) : (
        <div
          style={{
            transform: `translate3D(0,0,0) scale(${defaultScale}, ${defaultScale})`,
            position: "absolute",
            zIndex: 1,
            top: isClustered ? "-16px" : "-7px",
            left: isClustered ? "-16px" : "-7px",
            display: "flex",
            width: isClustered ? "32px" : "14px",
            height: isClustered ? "32px" : "14px",
            margin: 0,
            padding: 0,
            transformOrigin: isClustered ? "-16px -16px" : "-7px -7px",
            textDecoration: "inherit",
            border: "2px solid #fff",
            borderRadius: "50%",
            backgroundColor: color,
            boxShadow: "0 0 3px #999",
            speak: "none",
          }}
        >
          {isClustered && (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontSize: "12px",
              }}
            >
              {cluster}
            </div>
          )}
          {icon && (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontSize: "12px",
              }}
            >
              <img src={icon} alt="Map Icon"  style={{width: "32px", height: "32px", cursor: "pointer"}}/>
            </div>
          )}
          {content}
        </div>
      )}
      {/* marker content out of animation */}
      {!isClustered && children}
    </div>
  );
};

export const baseMarkerHOC = compose(
  //   themr("baseMarker", baseMarkerStyles),
  defaultProps({
    // content can be element,
    // content is inside animated marker part children are not
    content: "",
    active: true,
    animated: true,
    // for most situations you should override theme
    // hover is set by parent component
    hover: false,
    // animation constants
    initialScale: 0.5, // scale on first occurence
    defaultScale: 1,
    hoveredScale: 1.1,
    stiffness: 420,
    damping: 7,
    precision: 0.05,
    hoveredDumping: 25,
    onMouseEnter: () => {},
    onMouseLeave: () => {},
    onMouseClick: () => {},
    color: "#a07ebd",
  }),
  // transform string and number content to React Element
  withPropsOnChange(["content"], ({ content, theme }) => ({
    content:
      isString(content) || isNumber(content) ? (
        <div className={theme.content}>{content}</div>
      ) : (
        content
      ),
  })),
  withPropsOnChange(
    ["initialScale"],
    ({ initialScale, defaultScale, $prerender }) => ({
      initialScale,
      defaultMotionStyle: { scale: $prerender ? defaultScale : initialScale },
    })
  ),
  withPropsOnChange(
    ["hover", "externalHover", "theme", "active", "isNew"],
    ({
      hover,
      theme,
      hoveredScale,
      defaultScale,
      isNew,
      stiffness,
      damping,
      hoveredDumping,
      precision,
      active,
      externalHover,
    }) => ({
      motionStyle: {
        scale: spring(hover || externalHover ? hoveredScale : defaultScale, {
          stiffness,
          damping: hover || externalHover ? hoveredDumping : damping,
          precision,
        }),
      },
      theme: {
        ...theme,
        marker: cx(theme.marker, {
          [theme.hover]: hover || externalHover,
          [theme.active]: active,
          [theme.isNew]: isNew,
        }),
      },
    })
  )
);

export default compose(
  baseMarkerHOC,
  // optimization so not exposed as baseMarkerHOC
  // it's on child markers controls to optimize
  mapProps((props) => omitBy(props, (v, k) => k.startsWith("$"))),
  pure
)(baseMarker);
