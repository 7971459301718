import React, { useCallback, useMemo, useState } from "react";
import propTypes from "prop-types";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  ListItemIcon,
  useMediaQuery,
  MenuItem,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import { BsInfoCircle } from "react-icons/bs";
import { size, first } from "lodash";
import styled from "styled-components";
import { EditorState } from "draft-js";
import { MdKeyboardReturn } from "react-icons/md";
import { RiChatSettingsLine, RiEdit2Fill } from "react-icons/ri";
import constants from "components/constants";

import Avatar from "common/Avatar";
import { Error } from "./Styled";
import Delete from "./forms/Delete";

import { commentsSelectors } from "../../features/Comments/commentsSlice";
import { IconButtonDropdown } from "common/ButtonDropdown";
import { MoreVert } from "@mui/icons-material";
import TextEditor, { ActionButton, getContentAsHTML } from "../TextEditor";
import Button from "common/Button";
import Previews from "./components/Previews";
import { getIsAuthenticatedSelector } from "features/Auth/authSlice";

const { selectById } = commentsSelectors;

const visibleTos = {
  phx_client: "Client",
  member: "Member",
  customer: "Customer",
};

const Container = styled.div`
  position: relative;
  margin: 0.5rem 0;
  // background: white;
  // border-radius: 6px;
  width: 100%;
  // padding: 1rem 0;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
  height: 30px;
  justify-content: flex-start;
  margin-left: 10px;
  margin-right: 10px;

  & p:first-of-type {
    color: var(--color-black);
    font-weight: 600;
    margin: 0 0.5rem;
  }

  & p:last-of-type {
    font-size: 0.75rem;
    // margin-left: 0.25rem;
  }

  @media (max-width: ${constants.breakpoints.md}) {
    margin-left: 0;
    margin-right: 0;
  }
`;

const LeftContent = styled.div`
  width: 55px;
  height: 30px;
  padding: 0 6px;

  & > p {
    opacity: 0;
    transition: opacity 0.25s;
  }
`;

const Actions = styled.div`
  position: absolute;
  top: -15px;
  right: 20px;
  height: 30px;
  background: var(--color-gray-background);
  border: 1px solid var(--color-gray-lighter);
  border-radius: 15px;
  opacity: 0;
  transition: opacity 0.25s;
`;

const CommentVisibility = styled.div`
  color: var(--color-gray-dark);
  margin-bottom: 0.25rem;
  font-size: 12px;
  opacity: 0;
  height: 0;
  transition: opacity 0.25s, height 0.25s;
`;

const CommentRow = styled.div`
  display: flex;
  padding: 0;
  transition: background-color 0.25s;

  &:hover {
    // background-color: var(--color-gray-background);

    & > ${LeftContent} > p {
      opacity: 1;
    }

    & ${Actions} {
      opacity: 1;
    }

    & ${CommentVisibility} {
      height: 13px;
      opacity: 1;
    }
  }
`;

const CommentSubject = styled.div`
  color: var(--color-gray-medium);
  margin-bottom: 0.5rem;
  font-size: 12px;
`;

const CommentFooter = styled.div`
  color: var(--color-gray-medium);
  margin-top: 0.25rem;
  font-size: 12px;
`;

const CommentBody = styled.div`
  background: white;
  margin-bottom: 6px;
  padding: 1rem 1rem;
  border-radius: 6px;
  position: relative;

  p {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }

  @media (max-width: ${constants.breakpoints.md}) {
    font-size: 0.85rem;
    padding: 0.5rem;
  }
`;

const User = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  @media (max-width: ${constants.breakpoints.md}) {
    margin-bottom: 0.25rem;
  }
`;

const Content = styled.div``;

const Visibility = ({ visibility, setVisibility, client }) => {
  const roleType = useSelector((state) => state.auth.user.data.roleType);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheck = (e) => {
    setVisibility({ ...visibility, [e.target.name]: e.target.checked });
  };

  return (
    <>
      <ActionButton handleClick={handleOpen}>
        <RiChatSettingsLine />
      </ActionButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <div
          style={{ padding: "1.25rem", width: 300 }}
          onClick={(e) => e.stopPropagation()}
        >
          <Typography variant="subtitle1">Visibility</Typography>
          {roleType !== "phx_client" && (
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    name="phx_client"
                    checked={visibility.phx_client}
                    onChange={handleCheck}
                  />
                }
                label={`${client} Admin`}
              />
            </div>
          )}
          {roleType !== "member" && (
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    name="member"
                    size="small"
                    checked={visibility.member}
                    onChange={handleCheck}
                  />
                }
                label="Member"
              />
            </div>
          )}
          {roleType !== "customer" && (
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    name="customer"
                    size="small"
                    checked={visibility.customer}
                    onChange={handleCheck}
                  />
                }
                label="Customer"
              />
            </div>
          )}
          {roleType !== "mby_agent" && (
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    name="mby_agent"
                    size="small"
                    checked={visibility.mby_agent}
                    onChange={handleCheck}
                  />
                }
                label="Agent"
              />
            </div>
          )}
        </div>
      </Popover>
    </>
  );
};

const Edit = ({ comment, onClose, onPatch, allowEditVisibility, client }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [visibility, setVisibility] = useState({
    phx_client: comment?.comment_visible_to
      ? comment.comment_visible_to.includes("phx_client")
      : true,
    member: comment.comment_visible_to
      ? comment.comment_visible_to.includes("member")
      : true,
    customer: comment.comment_visible_to
      ? comment.comment_visible_to.includes("customer")
      : true,
    mby_agent: comment.comment_visible_to
      ? comment.comment_visible_to.includes("mby_agent")
      : true,
  });

  const formatVisibleTos = useCallback((a) => {
    const visibleTo = [];
    for (let obj in a) {
      if (a[obj]) visibleTo.push(obj);
    }

    return visibleTo;
  }, []);

  const handleEditComment = () => {
    const html = getContentAsHTML(editorState);
    if (!editorState.getCurrentContent().hasText()) return;

    const params = {
      field_comment_long: html,
    };

    if (allowEditVisibility) {
      params.field_comment_visible_to = formatVisibleTos(visibility);
    }

    if (!allowEditVisibility) {
      delete params.field_comment_visible_to;
    }

    onPatch(params, comment);
    onClose();
  };

  return (
    <>
      <TextEditor
        uploadedFileAmount={0}
        editing={true}
        fileProgress={{}}
        removeFile={() => {}}
        openFile={null}
        // loadUsers={loadUsers}
        // nid={nid}
        clearData={() => {}}
        placeholder={`Editing`}
        currentContent={comment.comment}
        editorState={editorState}
        setEditorState={setEditorState}
        actions={
          allowEditVisibility ? (
            <Visibility visibility={visibility} setVisibility={setVisibility} client={client} />
          ) : null
        }
      />
      <div style={{ marginTop: "0.5rem" }}>
        <Button
          size="small"
          style={{ marginRight: "1rem" }}
          variant="contained"
          disableElevation
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          color="tertiary"
          size="small"
          disableElevation
          variant="contained"
          startIcon={<MdKeyboardReturn />}
          onClick={handleEditComment}
        >
          Save Changes
        </Button>
      </div>
    </>
  );
};

const Body = ({
  id,
  isFirst,
  isLast,
  owner,
  onDelete,
  onPatch,
  allowEditVisibility,
  client,
}) => {
  const comment = useSelector((state) => selectById(state, id));
  const [editing, setEditing] = useState(false);
  const isAuthenticated = useSelector(getIsAuthenticatedSelector);

  const formattedVisibility = useMemo(() => {
    if (comment.comment_visible_to) {
      const roles = comment.comment_visible_to.split(",").map((role) => {
        let temp = role.replace(/mby_/g, "");
        if (temp === "phx_client" && client) temp = `${client} Admin`;
        return temp.charAt(0).toUpperCase() + temp.slice(1);
      });
      return `Visible to: ${roles.join(", ")}`;
    }
    return null;
  }, [comment, client]);

  const formattedSubject = useMemo(() => {
    const subject = [];

    if (comment.subject) subject.push(comment.subject);
    if (comment.note_type) subject.push(`| ${comment.note_type}`);
    if (comment.note_status) subject.push(`| ${comment.note_status}`);

    if (size(subject) > 0) {
      return subject.join(" ");
    }

    return null;
  }, [comment]);

  return (
    <React.Fragment>
      <CommentRow
        id={`comment-${id}`}
        style={{ flexDirection: owner ? "row-reverse" : "row" }}
      >
        <LeftContent>
          <Typography color="textSecondary" align="right">
            {moment.unix(comment.created).format("h:mm")}
          </Typography>
        </LeftContent>
        <div style={{ flex: 1, maxWidth: "90%" }}>
          <CommentBody
            style={{
              background:
                comment?.comment_type === "activity_log"
                  ? "var(--color-gray)"
                  : "white",
              opacity: comment.processing ? 0.5 : 1,
              borderTopRightRadius: isFirst && !owner ? "16px" : "3px",
              borderTopLeftRadius: isFirst && owner ? "16px" : "3px",
              borderBottomRightRadius: isLast ? "16px" : "3px",
              borderBottomLeftRadius: isLast ? "16px" : "3px",
            }}
          >
            {comment?.comment_type === "activity_log" && (
              <Typography variant="caption" color="secondary">
                Automated Message
              </Typography>
            )}
            {(owner && isAuthenticated) && (
              <Actions>
                <IconButtonDropdown
                  icon={MoreVert}
                  tooltip="Actions"
                  size="small"
                  className="py-1 px-4"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  {comment?.comment_visible_to && (
                    <>
                      <div style={{ padding: "1rem" }}>
                        <Typography variant="subtitle1">Visibility</Typography>
                        {comment?.comment_visible_to
                          ?.split(",")
                          ?.map((str, i) => (
                            <Typography key={i}>{visibleTos[str]}</Typography>
                          ))}
                      </div>
                      <Divider style={{ marginBottom: "0.5rem" }} />
                    </>
                  )}
                  <MenuItem onClick={() => setEditing(true)}>
                    <ListItemIcon color="error">
                      <RiEdit2Fill />
                    </ListItemIcon>
                    <Typography variant="inherit">Edit</Typography>
                  </MenuItem>
                  <Delete comment={comment} onDelete={onDelete} />
                </IconButtonDropdown>
              </Actions>
            )}
            {formattedSubject && (
              <CommentSubject>{formattedSubject}</CommentSubject>
            )}
            {comment.comment_visible_to && (
              <CommentVisibility>{formattedVisibility}</CommentVisibility>
            )}
            {editing ? (
              <Edit
                comment={comment}
                onClose={() => setEditing(false)}
                onPatch={onPatch}
                allowEditVisibility={allowEditVisibility}
                client={client}
              />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: comment.comment }}></div>
            )}
            {comment.notify_emails && (
              <CommentFooter>{comment.notify_emails}</CommentFooter>
            )}
            <Previews data={comment.comment} />
            {comment.error && (
              <Error>
                <Tooltip title={comment.error}>
                  <div>
                    <BsInfoCircle />
                  </div>
                </Tooltip>
                <Typography color="textSecondary">
                  There was an error adding this comment.
                </Typography>
              </Error>
            )}
          </CommentBody>
        </div>
      </CommentRow>
    </React.Fragment>
  );
};

const CommentGroup = ({
  comments,
  onDelete,
  onPatch,
  allowEditVisibility,
  client,
}) => {
  const currentUser = useSelector((state) => state.auth.user.data);
  const comment = first(comments);

  const owner = (Number(comment?.is_owner) || Number(comment?.uid) === Number(currentUser?.uid));
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Container>
      <User style={{ flexDirection: owner ? "row-reverse" : "row" }}>
        <Avatar
          title={owner ? "You" : `${comment.first_name} ${comment.last_name}`}
          link={comment._files?.profile_pic}
          size={(isMobile ? "small" : "normal")}
        />
        <Header style={{ flexDirection: owner ? "row-reverse" : "row" }}>
          <Typography>
            {owner ? "You" : `${comment.first_name} ${comment.last_name}`}
          </Typography>
          <Typography color="textSecondary">
            {moment.unix(comment.created).format("h:mm A")}
          </Typography>
        </Header>
      </User>
      <Content>
        {comments
          .sort((a, b) => a.created - b.created)
          .map((c, i) => (
            <Body
              key={c.cid}
              id={c.cid}
              isFirst={i === 0}
              isLast={i === size(comments) - 1}
              owner={owner}
              onDelete={onDelete}
              onPatch={onPatch}
              allowEditVisibility={allowEditVisibility}
              client={client}
            />
          ))}
      </Content>
    </Container>
  );
};

const { oneOf } = propTypes;
CommentGroup.propTypes = {
  side: oneOf(["left", "right"]),
};

export default CommentGroup;
