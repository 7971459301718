import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { size } from "lodash";

import {
  commentsSelectors,
  getCommentsPaginationSelector,
} from "./commentsSlice";
import { CommentBlock } from "common/Comment";

const celebrateIcon = `${process.env.PUBLIC_URL}/images/icons/icon-celebrate.svg`;

const Comments = ({
  onLoadMore,
  onRefresh,
  page,
  onDelete,
  onPatch,
  onFilter,
  allowEditVisibility,
  client,
  descendingOrder,
  scrollerHeight,
}) => {
  const allComments = useSelector(commentsSelectors.selectAll);
  const pagination = useSelector(getCommentsPaginationSelector);

  const groupCommentsByDay = useCallback((comments) => {
    return comments.reduce((acc, value) => {
      const currentDay = moment.unix(value.created).format("YYYY-MM-DD");
      if (acc[currentDay]) {
        acc[currentDay].push(value);
      } else {
        acc[currentDay] = [value];
      }

      return acc;
    }, {});
  }, []);

  const blocks = useMemo(
    () =>
      groupCommentsByDay(allComments.filter(onFilter ? onFilter : () => true)),
    [allComments, groupCommentsByDay, onFilter]
  );

  return (
    <div className="scrollarea">
    <InfiniteScroll
      dataLength={size(allComments.filter(onFilter ? onFilter : () => true))}
      next={onLoadMore}
      hasMore={page + 1 < pagination.total_pages}
      // style={{ display: "flex", flexDirection: (!descendingOrder ? "column-reverse" : 'column') }}
      loader={
        <div
          style={{
            padding: "1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={24} />
        </div>
      }
      endMessage={
        <div
          style={{
            padding: "1rem",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            color: "var(--color-gray)",
          }}
          className="end-message"
        >
          <img src={celebrateIcon} alt="finished" />
          <p>You've made it to the end</p>
        </div>
      }
      // scrollableTarget="scrollableDiv"
      height={scrollerHeight}
      inverse={!descendingOrder}
      pullDownToRefresh
      refreshFunction={onRefresh}
    >
      {Object.keys(blocks)
        .sort((a, b) => {
          if(!descendingOrder){
            return (new Date(b) - new Date(a))
          }
          else{
            return (new Date(a) - new Date(b))
          }
        })
        .map((key) => (
          <CommentBlock
            key={key}
            date={key}
            comments={blocks[key]}
            onDelete={onDelete}
            onPatch={onPatch}
            allowEditVisibility={allowEditVisibility}
            client={client}
          />
        ))}
    </InfiniteScroll>
    </div>
  );
};

Comments.propTypes = {};

export default Comments;
