import styled from "styled-components";
import { Form } from "formik";
import { Chip } from "@mui/material";
import constants from "../constants";
import { mediaUp } from "../mixins";

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1400px;
  width: 100%;
  padding: 40px 20px;
`;

export const Container = styled.div`
  padding: 1.25rem;
`;

export const Card = styled.div`
  background: white;
  border-radius: ${constants.borderRadius};
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  padding: 1.25rem;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${mediaUp.sm`
    flex-direction: row;
  `}
`;

export const CardValue = styled.div`
  line-height: 1;
  font-size: 2.5rem;
  font-weight: 900;
  color: ${constants.colorTertiary};
  height: 4rem;
  width: 4rem;
  border: 4px solid ${constants.colorTertiary};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

export const CardLabel = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  text-transform: uppercase;

  ${mediaUp.sm`
    margin-left: 20px;
    flex: 1;
  `}
`;

export const StyledChip = styled(Chip)`
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
`;

export const Section = styled.section`
  margin-bottom: 1.25rem;
  background: ${({ filled }) => (filled ? constants.colorGrayLighter : "none")};
  border-radius: ${constants.borderRadius};
`;

export const ActionBar = styled.div`
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const Actions = styled.div`
  > a {
    margin-right: 1rem;
  }
`;

export const Hours = styled.div`
  display: flex;
  order: -1;

  > h6 {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  ${mediaUp.sm`
    order: 1;
  `}
`;

export const County = styled.div`
  padding: 1rem 0;
  font-weight: ${({ owned }) => (owned ? 600 : 300)};
  font-size: 1.25rem;
`;

export const Filters = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 100%;

  ${mediaUp.sm`
    flex: 1;
  `}
`;

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: flex-end;
`;

export const AvatarField = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const PopupForm = styled.div`
  padding: 1.25rem;
`;

export const FormActions = styled.div`
  padding-top: 2rem;
  padding-bottom: 1.25rem;

  & > .MuiButtonBase-root {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const AvatarThumbnail = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  object-position: 50% 50%;
`;

export const FormContainer = styled(Form)`
  padding-top: 1.25rem;
`;

export const DocActions = styled.div`
  padding: 1.25rem;
`;

export const DropZone = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.75);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > img {
    margin-bottom: 1rem;
  }
`;

export const EditForm = styled(Form)`
  padding: 1.25rem 1.25rem 0 1.25rem;
  position: relative;
  outline: none !important;
`;

export const DrawerContainer = styled.div`
  width: 300px;
  padding: 1.25rem;

  & > div,
  button {
    margin-bottom: 0.5rem;
    margin-right: 0;
  }
`;
