import withStyles from '@mui/styles/withStyles';
import { Badge } from "@mui/material";

const DivisionsBadge = withStyles((theme) => ({
  badge: {
    right: -6,
    top: 5,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 6px",
    backgroundColor: "var(--color-blue)",
    color: "white",
  },
}))(Badge);

DivisionsBadge.propTypes = {};

export default DivisionsBadge;
