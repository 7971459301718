import React/*, { useState }*/ from "react";
import { Divider, Grid, Typography } from "@mui/material";
// import { Ri24HoursLine } from "react-icons/ri";
import { CgNotes } from "react-icons/cg";
import {
  MdPhotoSizeSelectSmall,
  MdPhone,
  // MdArrowDropDown,
} from "react-icons/md";
import { ImUser } from "react-icons/im";
import styled from "styled-components";

// import Header from "./Header";
import CollapsibleHeader from "common/CollapsibleHeader";
import IconTextItem from "common/IconTextItem";
import Card, { CardHeader, CardContent } from "common/Card";
import MapBanner from "common/MapBanner";
import Avatar from "common/Avatar";
import LinkHeader from "common/LinkHeader";
// import { getTodaysHours } from "../utils";
// import { getHours } from "utility";
import WeeksJobs from "./WeeksJobs";
import Role from "components/Role";
import { useSelector } from "react-redux";
import Hours from "common/Hours";
// import ActiveJobs from "./ActiveJobs";

const Details = styled.div`
  margin-left: 1.25rem;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  display: flex;

  .location-name {
    & > div {
      display: block;
    }
  }
`;

const Information = ({ location }) => {
  const user = useSelector((state) => state.auth.user.data);
  // const [showHoursDetails, setShowHoursDetails] = useState(false);
  const isSmall = useSelector((state) => state.browser.lessThan.medium);

  // const { today, time } = getTodaysHours(location.field_hours);
  // const hours = getHours(location.field_hours);
  return (
    <div style={{ flex: 1, overflow: "auto" }}>
      <CollapsibleHeader collapse={false}>
        <Avatar
          title={location.title}
          link={location?.field_logo?.link}
          size="large"
          outline
          type="org"
        />
        <Details>
          <div className="location-name">
            <LinkHeader to={location.path} color="secondary" size="medium">
              {location.title}
            </LinkHeader>
            {isSmall && location?._processed?.customer_alias && (
              <LinkHeader
                to={location._processed.customer_alias}
                color="default"
                size="small"
              >
                {location._processed.customer_name}
              </LinkHeader>
            )}
          </div>
          {!isSmall && location?._processed?.customer_alias && (
            <div>
              <Typography variant="subtitle1" color="textSecondary">
                Customer
              </Typography>
              <LinkHeader
                to={location._processed.customer_alias}
                color="secondary"
                size="medium"
              >
                {location._processed.customer_name}
              </LinkHeader>
            </div>
          )}
        </Details>
      </CollapsibleHeader>
      <MapBanner
        geo={{
          lat: location?.field_street_address_geo?.lat,
          lng: location?.field_street_address_geo?.lon,
        }}
        address1={location.field_street_address?.address_line1}
        address2={location.field_street_address?.address_line2}
        city={location.field_street_address?.locality}
        state={location.field_street_address?.administrative_area}
        zip={location.field_street_address?.postal_code}
      >
        <div>
          <Grid container spacing={3}>
            <Grid item xxs={12} sm={6} md={8} xl={9}>
              <WeeksJobs nid={location.nid} />
            </Grid>
            <Grid item xxs={12} sm={6} md={4} xl={3}>
              <Card>
                <CardHeader title="Details" />
                <CardContent>
                  {location.field_square_feet && (
                    <IconTextItem icon={<MdPhotoSizeSelectSmall />}>
                      <Typography
                        variant="subtitle2"
                        style={{ textTransform: "uppercase" }}
                        color="textSecondary"
                      >
                        Location Size
                      </Typography>
                      <Typography variant="body1">
                        {location.field_square_feet} ft&sup2;
                      </Typography>
                    </IconTextItem>
                  )}
                  {location.field_phone && (
                    <IconTextItem icon={<MdPhone />}>
                      <Typography
                        variant="subtitle2"
                        style={{ textTransform: "uppercase" }}
                        color="textSecondary"
                      >
                        Phone
                      </Typography>
                      <Typography variant="body1">
                        {location.field_phone}
                      </Typography>
                    </IconTextItem>
                  )}
                  {location.field_phone_after_hours && (
                    <IconTextItem icon={<MdPhone />}>
                      <Typography
                        variant="subtitle2"
                        style={{ textTransform: "uppercase" }}
                        color="textSecondary"
                      >
                        After Hours
                      </Typography>
                      <Typography variant="body1">
                        {location.field_phone_after_hours}
                      </Typography>
                    </IconTextItem>
                  )}
                  {location.field_hours && (
                    <Hours data={location.field_hours} />
                  )}
                  <Role
                    userRoles={user?._processed?.roles}
                    intendedRoles={[
                      "admin",
                      "phx_sub_admin",
                      "phx_client_admin",
                      "operations",
                      "accounting",
                      "compliance_admin",
                    ]}
                  >
                    <>
                      <Divider style={{ margin: "1rem 0" }} />
                      {location.field_preferred_member?.title && (
                        <IconTextItem icon={<ImUser />}>
                          <Typography
                            variant="subtitle2"
                            style={{ textTransform: "uppercase" }}
                            color="textSecondary"
                          >
                            Primary Preferred Provider
                          </Typography>
                          <LinkHeader
                            to={location.field_preferred_member.self}
                            color="secondary"
                            size="small"
                          >
                            {location.field_preferred_member.title}
                          </LinkHeader>
                        </IconTextItem>
                      )}
                      {location.field_preferred_member_2?.title && (
                        <IconTextItem icon={<ImUser />}>
                          <Typography
                            variant="subtitle2"
                            style={{ textTransform: "uppercase" }}
                            color="textSecondary"
                          >
                            Secondary Preferred Provider
                          </Typography>
                          <LinkHeader
                            to={location.field_preferred_member_2.self}
                            color="secondary"
                            size="small"
                          >
                            {location.field_preferred_member_2.title}
                          </LinkHeader>
                        </IconTextItem>
                      )}
                      {location.field_preferred_member_3?.title && (
                        <IconTextItem icon={<ImUser />}>
                          <Typography
                            variant="subtitle2"
                            style={{ textTransform: "uppercase" }}
                            color="textSecondary"
                          >
                            Third Preferred Provider
                          </Typography>
                          <LinkHeader
                            to={location.field_preferred_member_3.self}
                            color="secondary"
                            size="small"
                          >
                            {location.field_preferred_member_3.title}
                          </LinkHeader>
                        </IconTextItem>
                      )}
                      {location.field_preferred_member_4?.title && (
                        <IconTextItem icon={<ImUser />}>
                          <Typography
                            variant="subtitle2"
                            style={{ textTransform: "uppercase" }}
                            color="textSecondary"
                          >
                            Fourth Preferred Provider
                          </Typography>
                          <LinkHeader
                            to={location.field_preferred_member_4.self}
                            color="secondary"
                            size="small"
                          >
                            {location.field_preferred_member_4.title}
                          </LinkHeader>
                        </IconTextItem>
                      )}
                    </>
                  </Role>
                {location.field_note && (
                  <>
                  <Divider style={{ margin: "1rem 0" }} />
                  <IconTextItem icon={<CgNotes />}>
                    <Typography
                      variant="subtitle2"
                      style={{ textTransform: "uppercase" }}
                      color="textSecondary"
                    >
                      Special Instructions
                    </Typography>
                    <Typography variant="body1">
                      {location.field_note}
                    </Typography>
                  </IconTextItem>
                  </>
                )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </MapBanner>
    </div>
  );
};

Information.propTypes = {};

export default Information;
