import React from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

import { TextFormField } from "common/Fields";
import { DialogForm } from "common/Dialog";

import { postWorkAreaGroup } from "../workAreasSlice";

const CreateGroup = ({ open, onClose, nid, setFieldValue }) => {
  const dispatch = useDispatch();

  const handleSubmit = async (
    data,
    setSubmitting,
    setMessage,
    successCallback,
    errorCallback
  ) => {
    setSubmitting(true);
    setMessage(null);
    try {
      setSubmitting(true);
      const resultAction = await dispatch(
        postWorkAreaGroup({ id: nid, params: data })
      );
      unwrapResult(resultAction);

      setFieldValue("group", resultAction.payload.nid);
      successCallback("Added Group");
    } catch (err) {
      setMessage(err);
    }
  };

  return (
    <DialogForm
      open={open}
      onClose={onClose}
      maxWidth="xs"
      title="Add Group"
      initialValues={{
        name: "",
      }}
      onSubmit={handleSubmit}
      disableOverlay
      hideBackdrop
    >
      {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
        <>
          <TextFormField
            fullWidth
            name="name"
            label="Name"
            margin="normal"
            variant="filled"
            size="small"
            disabled={isSubmitting}
            type="text"
            labelwidth={40}
          />
        </>
      )}
    </DialogForm>
  );
};

CreateGroup.propTypes = {};

export default CreateGroup;
