import React from "react";
import buttonStyles from "./css/button.module.css";

const ActionButton = ({ children, handleClick }) => {
  const onClick = (e) => {
    e.preventDefault();
    handleClick(e);
  };

  return (
    <div className={buttonStyles.buttonWrapper}>
      <button onClick={onClick} className={buttonStyles.button}>
        {children}
      </button>
    </div>
  );
};

export default ActionButton;
