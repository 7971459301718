import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DialogContent, Typography } from "@mui/material";
import moment from "moment";
import styled from "styled-components";
import { BiCalendarPlus } from "react-icons/bi";

import { DateFormField } from "common/Fields";
import { DialogForm } from "common/Dialog";

import { getDivisionDataSelector, patchJobDivision } from "../jobSlice";

const Action = styled.div`
  height: 64px;
  border: 1px solid var(--color-gray-lighter);
  background-color: var(--color-gray-light);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-gray-dark);
  cursor: pointer;
  transition: background-color 0.25s;

  &:hover {
    background-color: var(--color-gray-lighter);
    border: 1px solid var(--color-gray-medium);
  }
`;

const AddDate = ({
  title,
  buttonText,
  nid,
  field,
  format,
  initialValue,
  children,
}) => {
  const dispatch = useDispatch();
  const division = useSelector(getDivisionDataSelector);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = (data) => {
    let value = null;
    if(data[field] && data[field] !== ""){
      value = [{ value: moment(data[field]).format(format) }];
    }
    dispatch(
      patchJobDivision({
        id: nid,
        params: { [field]: value },
        division,
      })
    );

    onClose();
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      {children ? (
        React.cloneElement(children, {
          onClick: handleOpen,
        })
      ) : (
        <Action onClick={handleOpen}>
          <BiCalendarPlus />
          <Typography>{buttonText}</Typography>
        </Action>
      )}
      <DialogForm
        open={open}
        onClose={onClose}
        maxWidth="xs"
        title={title}
        initialValues={{
          [field]: initialValue ? moment(initialValue) : moment(),
        }}
        disablePadding
        onSubmit={handleSubmit}
        overrideDirty
      >
        {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
          <DialogContent>
            <DateFormField
              fullWidth
              disableToolbar
              variant="inline"
              format="MM/DD/YYYY"
              id={field}
              label="Date"
              margin="normal"
              size="small"
              name={field}
              inputVariant="filled"
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </DialogContent>
        )}
      </DialogForm>
    </>
  );
};

AddDate.propTypes = {};

export default AddDate;
