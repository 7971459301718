import React from "react";
import { useSelector } from "react-redux";
import { Typography, Collapse } from "@mui/material";
import { size } from "lodash";

import DivisionMenuItem from "../../components/DivisionMenuItem";
import DivisionsBadge from "../../components/DivisionsBadge";
import LinkDropdown from "common/LinkDropdown";
import CollapsibleIcon from "common/CollapsibleIcon";
import { getDivisionTypeIndicator } from "../../utils";
import AddDivision from "../forms/AddDivision";

import { jobDivisionTypesSelectors } from "features/Taxonomies/taxonomiesSlice";
import { getDivisionDataSelector, jobDivisionsSelectors } from "../../jobSlice";

const DivisionHeaderLabel = ({ collapse, typeTid, path, title }) => {
  const divisions = useSelector(jobDivisionsSelectors.selectIds);
  const division = useSelector(getDivisionDataSelector);
  const type = useSelector((state) =>
    jobDivisionTypesSelectors.selectById(state, typeTid)
  );

  const { icon: TypeIcon, color } = getDivisionTypeIndicator(type?.name);

  return (
    <>
      <CollapsibleIcon collapse={collapse} color={color} tooltip={type?.name}>
        {<TypeIcon />}
      </CollapsibleIcon>
      <div style={{ marginLeft: "1rem", flex: "1 0 auto" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {(size(divisions) > 1 || division?._processed?.job_create_permission) ? (
            <DivisionsBadge badgeContent={size(divisions)}>
              <LinkDropdown
                label={division.title}
                loading={false}
                color="secondary"
                style={{ fontWeight: 600 }}
              >
                {divisions.map((divisionId) => (
                  <DivisionMenuItem key={divisionId} nid={divisionId} />
                ))}
                {division?._processed?.job_create_permission && <AddDivision />}
              </LinkDropdown>
            </DivisionsBadge>
          ) : (
            <Typography
              color="secondary"
              variant="h6"
              style={{ fontWeight: 600 }}
            >
              {division.title}
            </Typography>
          )}
        </div>
        {/* <LinkHeader to={path} size="large" color="secondary" noWrap width={300}>
          {title}
        </LinkHeader> */}
        <Collapse in={!collapse}>
          <Typography variant="subtitle1">{type?.name}</Typography>
        </Collapse>
      </div>
    </>
  );
};

DivisionHeaderLabel.propTypes = {};

export default DivisionHeaderLabel;
