import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Tooltip } from "@mui/material";
import constants from "components/constants";

import { getPercentage, getProgressColor } from "../../utility";
import "./Visualizations.scss";

const getNumValidated = data => {
  const validated = [];
  data.forEach(day => {
    if (day.validated) {
      validated.push(day);
    }
  });

  return validated.length;
};

const CompletionTracker = ({ data=[{}] }) => {
  const obtained = getNumValidated(data);
  const total = data.length;
  const percentage = getPercentage(obtained, total);
  const color = getProgressColor(percentage, constants.colorRed, constants.colorGreen);

  return data.length <= 10 ? (
    <div className="comp-tracker">
      {data.map((day, index) => {
        const classes = classNames(
          "comp-tracker__item",
          { "comp-tracker__item--validated": day.validated },
          { "comp-tracker__item--invalid": !day.validated }
        );
        return (
          <Tooltip title={day.date} key={index}>
            <div className={classes}></div>
          </Tooltip>
        );
      })}
    </div>
  ) : (
    <div className="comp-tracker">
      <div
        className="comp-tracker__bar"
        style={{ backgroundColor: color, width: `${percentage}%` }}
      ></div>
      <div className="comp-tracker__label">
        {obtained}/{total}
      </div>
    </div>
  );
};

const { arrayOf, object } = PropTypes;
CompletionTracker.propTypes = {
  data: arrayOf(object)
};

export default CompletionTracker;
