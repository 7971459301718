import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Tooltip,
} from "@mui/material";
import styled from "styled-components";
import { BsFlag, BsFlagFill } from "react-icons/bs";
import {
  fetchJobProgress,
  getJobProgressSelector,
  getJobProgressLoadingSelector
} from "features/JobProgress/jobProgressSlice";
import {
  getRightAsideOpenSelector,
  setRightAsideOpen,
  getRightAsideDrawerOpenSelector,
  setRightAsideDrawerOpen,
} from "common/Layout/layoutSlice";
import { getDivisionDataSelector, } from "features/Job/jobSlice";

const ProgressIndicator = ({
  required,
  completed,
  colorScheme,
  inJobListing,
}) => {
  const dispatch = useDispatch();
  const browser = useSelector((state) => state.browser);
  const division = useSelector(getDivisionDataSelector);
  const progress = useSelector(getJobProgressSelector);
  const loading = useSelector(getJobProgressLoadingSelector);
  const [requiredMilestones, setRequiredMilestones] = useState(Number(required ? required : 0));
  const [completedMilestones, setCompletedMilestones] = useState(Number(completed ? completed : 0));
  const progressOpen = useSelector(getRightAsideOpenSelector);
  const progressDrawerOpen = useSelector(getRightAsideDrawerOpenSelector);

  useEffect(() => {
    if(!inJobListing){
      let promise;
      if (division.nid) {
        promise = dispatch(fetchJobProgress(division.nid));
      }

      return () => {
        if (promise) {
          promise.abort();
        }
      };
    }
  }, [division]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(!inJobListing && !loading){
      const _required = Number(progress?.milestone_progress?.required ? progress?.milestone_progress?.required : 0);
      setRequiredMilestones(_required);
      setCompletedMilestones(Number(progress?.milestone_progress?.completed ? progress?.milestone_progress?.completed : 0));
    //  Close the aside/drawer if it's open from a previous job, but this job has no milestone requirments
      if(!_required){
        if(browser.greaterThan.large){
          if(progressOpen){
            dispatch(setRightAsideOpen(false));
          }
        }
        else{
          if(progressDrawerOpen){
            dispatch(setRightAsideDrawerOpen(false));
          }
        }
      }
    //  Re-open the aside/drawer if it's closed from a previous job having no milestone requirments, but the listing is pinned-open
      else if(localStorage.getItem('right_aside_pinned')){
        if(browser.greaterThan.large){
          if(!progressOpen){
            dispatch(setRightAsideOpen(true));
          }
        }
        else{
          if(!progressDrawerOpen){
            dispatch(setRightAsideDrawerOpen(true));
          }
        }
      }
    }
  }, [progress]); // eslint-disable-line react-hooks/exhaustive-deps


  const percent = Math.round((completedMilestones / requiredMilestones) * 100);
  const completed10s = Math.round(percent / 10) * 10;
  const height = (percent !== 100 ? 10 + (90 * (percent / 100)) : 100);

  const clickDisabled = (inJobListing ? inJobListing : browser.greaterThan.extraLarge2);

  let cursor = 'cursor-pointer';
  if(clickDisabled){
    cursor = 'cursor-default';
  }

  return (
    ((!loading && requiredMilestones) ? (
      <Tooltip title={
        <>
          Milestone Progress - {percent}% Complete
          {!clickDisabled &&
            <><br />(Click for Details)</>
          }
        </>
      } placement="left-end">
        <Indicator
          className={`${cursor} ${colorScheme}`}
          onClick={() => {
            if(!clickDisabled){
              // setProgressOpen(!progressOpen);
              if(browser.greaterThan.large){
                dispatch(
                  setRightAsideOpen(!progressOpen)
                );
              }
              else{
                dispatch(
                  setRightAsideDrawerOpen(!progressDrawerOpen)
                );
              }
            }
          }}
        >
          <div className="indicator" >
            <div className="meter"
              style={{
                backgroundColor: `var(--color-rg-${completed10s})`,
                height: `${height}%`,
              }} />
          </div>
          {colorScheme === 'light' ?
            <BsFlagFill className="flag" /> :
            <BsFlag className="flag" />
          }
        </Indicator>
      </Tooltip>
    ) : (
      <span />
    )
  ));
};

const Indicator = styled.div`
  display: flex;
  margin-left: 0.75rem;
  color: var(--color-gray-lightest);

  .indicator{
    display: flex;
    align-items: flex-end;
    height: 25px;
    width: 10px;
    margin-right: 2px;
    border: 1px solid var(--color-gray);
    border-radius: 5px;
    overflow: hidden;

    .meter{
      width: 100%;
    }
  }
  &.light{
    color: var(--color-gray-medium);
    .indicator{
      border: 1px solid var(--color-gray-medium);
    }
  }

  .flag{
    height: 20px;
    width: auto;
  }
`;

ProgressIndicator.propTypes = {};

export default ProgressIndicator;
