import React from "react";

import { RouteDialog } from "../Dialog";
import { DialogForm } from "../Form";

const Form = ({
  buttonText,
  url,
  path,
  handleSubmit,
  initialValues,
  onExit,
  children,
}) => {
  return (
    <DialogForm
      submitText={buttonText}
      url={url}
      path={path}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      onExit={onExit}
    >
      {({ values, errors, isSubmitting, validateField, setFieldValue }) =>
        children(values, errors, isSubmitting, validateField, setFieldValue)
      }
    </DialogForm>
  );
};

const RouteDialogForm = ({
  url,
  path,
  title,
  maxWidth,
  route,
  formRef,
  onExit,
  ...rest
}) => {
  return (
    <RouteDialog
      route={route}
      path={path}
      url={url}
      maxWidth={maxWidth}
      title={title}
    >
      <Form url={url} path={path} {...rest} />
    </RouteDialog>
  );
};

RouteDialogForm.propTypes = {};

export default RouteDialogForm;
