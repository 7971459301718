export const personnelTypes = [
  { value: "estimator", name: "Estimator" },
  { value: "pm", name: "Project Manager" },
  { value: "supervisor", name: "Supervisor" },
  { value: "coordinator", name: "Coordinator" },
  { value: "accounting", name: "Accounting" },
  { value: "marketing", name: "Marketing" },
  { value: "foreman", name: "Foreman" },
  {
    value: "lead_technician",
    name: "Lead Technician",
  },
  {
    value: "mrp",
    name: "Managed Repair Personnel",
  },
];

export const getPersonnelType = (type) => {
  return personnelTypes.find((personnelType) => personnelType.value === type);
}
