import styled from "styled-components";
import constants from "../constants";

export const Loader = styled.div`
  width: 300px;
  background: white;
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: ${constants.borderRadius};
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 1.25rem;

  & > .MuiCircularProgress-root {
    margin-right: 1.25rem;
  }
`;

export const PageContainer = styled.div`
  width: 100%;
  overflow: auto;
  height: calc(
    var(--vh, 1vh) * 100 - ${constants.pageHeaderHeight} -
      ${constants.pageHeaderHeight}
  );
  background: ${constants.colorGrayLighter};
  position: relative;
`;

export const Legend = styled.div`
  display: flex;
  align-items: center;
  z-index: 1;
  margin-bottom: 1rem;

  & > div {
    display: flex;
    align-items: center;
    margin-right: 1rem;

    & > img {
      margin-right: 0.5rem;
      height: 20px;
      width: auto;
    }
  }
`;

export const ClusterIcon = styled.div`
  background: inherit;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
`;

export const Instruction = styled.div`
  position: absolute;
  left: 5px;
  top: 35px;
  background: ${constants.colorPrimary};
  z-index: 1;
  max-width: 300px;
  padding: 1rem;
  border-radius: ${constants.borderRadius};
  color: white;
`;
