export const contactMethods = {
  phone_call: "Phone Call",
  text: "Text",
  email: "Email",
};

export const causes = {
  freeze: "Freeze",
  sewer_backup: "Sewer Backup",
  plumbing: "Plumbing",
  mold: "Mold",
  storm: "Storm",
  at_fault: "At Fault",
  fire: "Fire",
  pre_existing: "Pre-existing",
  other: "Other",
};
