import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import moment from "moment";

import { FormSkeleton } from "../../Skeletons";
import { RouteDialog } from "../../Dialog";
import { formatCRMEmail } from "../../../utility";
import { getNode } from "../../../actions";

const Content = ({ data, loading, loadParagraph, setTitle }) => {
  const { id } = useParams();
  const {
    field_em_bcc,
    field_em_cc,
    field_em_from,
    field_em_message,
    field_em_recipients,
    field_em_sent_time,
    title,
  } = formatCRMEmail(data);

  useEffect(() => {
    if (id) {
      loadParagraph(id);
    }
  }, [id, loadParagraph]);

  useEffect(() => {
    setTitle(title);
  }, [title, setTitle]);

  return loading ? (
    <FormSkeleton />
  ) : (
    <div style={{ padding: "1.25rem" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="subtitle1">{field_em_from}</Typography>
        <Typography variant="body2" color="textSecondary">
          {moment(field_em_sent_time).format("MMM Do, YYYY h:mm A")}
        </Typography>
      </div>
      <Typography variant="body1" color="textSecondary">
        to {field_em_recipients}
      </Typography>
      <Typography variant="body1" color="textSecondary">
        {field_em_cc ? `cc ${field_em_cc}` : null}
      </Typography>
      <Typography variant="body1" color="textSecondary">
        {field_em_bcc ? `bcc ${field_em_bcc}` : null}
      </Typography>
      <div
        style={{ marginTop: "1rem" }}
        dangerouslySetInnerHTML={{ __html: field_em_message }}
      ></div>
    </div>
  );
};

const Email = ({ url, ...rest }) => {
  const [title, setTitle] = React.useState("loading Email...");
  return (
    <RouteDialog
      route="view/:id"
      path="view"
      url={url}
      maxWidth="sm"
      title={title}
    >
      <Content url={url} setTitle={setTitle} {...rest} />
    </RouteDialog>
  );
};

Email.propTypes = {};

const mapStateToProps = (state) => ({
  data: state.app.node.data,
  loading: state.app.node.loading,
});

const mapDispatchToProps = (dispatch) => ({
  loadParagraph: (pid) => dispatch(getNode(pid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Email);
