import React, { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import moment from "moment";
import { MoreVert } from "@mui/icons-material";
import { IoIosPeople } from "react-icons/io";
import { Action } from "components/Personnel/Styled";
import { MdGroupAdd } from "react-icons/md";

import Create from "./forms/Create";
import { IconButtonDropdown } from "common/ButtonDropdown";
import { DefaultLoader } from "common/Loader";
import UserItem from "common/UserItem";
import IconTextItem from "common/IconTextItem";
import Edit from "./forms/Edit";
import Delete from "./forms/Delete";
import { getDivisionDataSelector } from "features/Job/jobSlice";
import {
  crewsSelectors,
  fetchCrews,
  getCrewsLoadingSelector,
} from "./crewSlice";

const Actions = ({ crew, assignedOnly }) => {
  const dropdownRef = useRef();

  const handleClose = () => {
    if (dropdownRef.current) {
      dropdownRef.current.close();
    }
  };

  return (
    <IconButtonDropdown
      ref={dropdownRef}
      icon={MoreVert}
      tooltip="Actions"
      size="small"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Edit
        id={crew.id}
        handleClose={handleClose}
        assignedOnly={assignedOnly}
      />
      <Delete crew={crew} handleClose={handleClose} />
    </IconButtonDropdown>
  );
};

const Crew = ({ assignedOnly, editable }) => {
  const dispatch = useDispatch();
  const division = useSelector(getDivisionDataSelector);
  const crews = useSelector(crewsSelectors.selectAll);
  const loading = useSelector(getCrewsLoadingSelector);

  useEffect(() => {
    dispatch(fetchCrews(division.nid));
  }, [division, dispatch]);

  if(!editable && !crews?.length){
    return null;
  }

  return (
    <div style={{ padding: "1rem" }}>
      <IconTextItem icon={<IoIosPeople />}>
        <Typography
          variant="subtitle2"
          style={{ textTransform: "uppercase" }}
          color="textSecondary"
        >
          Crews
        </Typography>
        {loading ? (
          <DefaultLoader />
        ) : (
          crews?.map((crew) => (
            <UserItem
              key={crew.id}
              actions={editable && <Actions crew={crew} assignedOnly={assignedOnly} />}
              user={{
                ...crew.field_crew_chief_user,
                field_title_position: "Crew Chief",
                field_phone: crew.field_crew_chief_user.phone,
              }}
            >
              {crew.field_crew_arrival_time_range && (
                <>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    style={{ margin: "0.5rem 0", textTransform: "uppercase" }}
                  >
                    Work Schedule
                  </Typography>
                  <Typography>{`${moment(
                    crew.field_crew_arrival_time_range.from
                  ).format("dddd MMM Do")} - ${moment(
                    crew.field_crew_arrival_time_range.to
                  ).format("dddd MMM Do")}`}</Typography>
                </>
              )}
            </UserItem>
          ))
        )}
        {editable &&
          <Create assignedOnly={assignedOnly}>
            <Action>
              <MdGroupAdd />
              <Typography>Schedule Crew</Typography>
            </Action>
          </Create>
        }
      </IconTextItem>
    </div>
  );
};

Crew.propTypes = {};

export default Crew;
