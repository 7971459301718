import React, { useEffect } from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { RiUserFill } from "react-icons/ri";
import { MdDateRange } from "react-icons/md";
import { AiOutlineDisconnect } from "react-icons/ai";

import NestedTabs from "components/NestedTabs";
import NestedTab from "components/NestedTabs/NestedTab";
import { MuiTable } from "common/Table";
import IconTextItem from "common/IconTextItem";
import { formatMoney, formatPrecision } from "utility";
import { itemsSelectors } from "features/PriceList/priceListSlice";
import { useSelector } from "react-redux";

const columns = [
  { id: "resource", label: "Resource", minWidth: 200 },
  { id: "category", label: "Category", minWidth: 200 },
  { id: "qty", label: "QTY", minWidth: 50 },
  { id: "price", label: "Price", minWidth: 50 },
  { id: "markup", label: "Markup", minWidth: 50 },
  { id: "ntp", label: "NTP", minWidth: 50 },
];

const createData = (resource, category, qty, uom, price, ntp, markup, markup_percent) => {
  return {
    resource,
    category,
    qty: `${qty} ${uom}`,
    price: `${formatMoney(price)}/${uom}`,
    markup: markup ? `${formatMoney(markup)} (${formatPrecision(markup_percent)}%)` : '',
    ntp: formatMoney(ntp),
  };
};

const createCategoryData = (data, cat, items) => {
  const rows = [];
  for (let key in data[cat]) {
    for (let id in data[cat][key]) {
      const { /*tid,*/ name, duration, price, increment, total, markup, markup_percent } =
        data[cat][key][id];
      // const pricelistItem = items.find(
      //   (element) => element.tid === Number(tid)
      // );
      // if (increment.includes("Week") && pricelistItem) {
      //   rows.push(
      //     createData(
      //       name,
      //       key,
      //       duration,
      //       increment,
      //       pricelistItem.w_price,
      //       total
      //     )
      //   );
      // } else if (increment.includes("Month") && pricelistItem) {
      //   rows.push(
      //     createData(
      //       name,
      //       key,
      //       duration,
      //       increment,
      //       pricelistItem.m_price,
      //       total
      //     )
      //   );
      // } else {
        rows.push(createData(name, key, duration, increment, price, total, markup, markup_percent));
      // }
    }
  }

  return rows;
};

const a11yProps = (index) => {
  return {
    id: `equipment-tab-${index}`,
    "aria-controls": `equipment-tab-panel-${index}`,
  };
};

const Equipment = ({ sheet, setTitle }) => {
  const [value, setValue] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const items = useSelector(itemsSelectors.selectAll);

  useEffect(() => {
    setTitle("Equipment");
  }, [setTitle]);

  useEffect(() => {
    if (sheet.resources) {
      const keys = Object.keys(sheet.resources);
      const key = keys[value];
      setRows(createCategoryData(sheet.resources, key, items));
    }
  }, [value, sheet, items]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div style={{ padding: "1.25rem" }}>
        <Typography variant="h6" style={{ marginBottom: "2rem" }}>
          {`Total: ${formatMoney(sheet.field_ds_total)}`}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xxs={12} sm={4}>
            <IconTextItem icon={<RiUserFill />}>
              <Typography
                variant="subtitle2"
                style={{ textTransform: "uppercase" }}
                color="textSecondary"
              >
                User
              </Typography>
              {sheet.field_ds_user.map((user) => (
                <Typography>{`${user.first} ${user.last}`}</Typography>
              ))}
            </IconTextItem>
          </Grid>
          <Grid item xxs={12} sm={4}>
            <IconTextItem icon={<MdDateRange />}>
              <Typography
                variant="subtitle2"
                style={{ textTransform: "uppercase" }}
                color="textSecondary"
              >
                Date
              </Typography>
              <Typography>{sheet.field_ds_date}</Typography>
            </IconTextItem>
          </Grid>
          <Grid item xxs={12} sm={4}>
            <IconTextItem icon={<AiOutlineDisconnect />}>
              <Typography
                variant="subtitle2"
                style={{ textTransform: "uppercase" }}
                color="textSecondary"
              >
                Area
              </Typography>
              <Typography>
                {sheet.field_ds_work_area_allocation?.title}
              </Typography>
            </IconTextItem>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xxs={12} sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={sheet.field_ds_taxable}
                  name="show"
                  color="secondary"
                  disabled
                />
              }
              label="Taxable"
            />
          </Grid>
          {/* {(sheet.field_ds_markup || sheet.field_ds_percent_markup) &&
            <Grid item xxs={12} sm={4}>
              <IconTextItem icon={<div className="p-2" />}>
                <Typography
                  variant="subtitle2"
                  style={{ textTransform: "uppercase" }}
                  color="textSecondary"
                >
                  Markup
                </Typography>
                <Typography>
                  {formatMoney(sheet.field_ds_markup)}
                  {Number(sheet.field_ds_percent_markup) > 0 &&
                    ` (${Number(sheet.field_ds_percent_markup)}%)`
                  }
                </Typography>
              </IconTextItem>
            </Grid>
          } */}
        </Grid>
        <div style={{ marginTop: "2rem" }}>
          <NestedTabs value={value} onChange={handleChange}>
            {sheet.resources &&
              Object.keys(sheet.resources).map((key, i) => (
                <NestedTab value={i} {...a11yProps(i)}>
                  {key}
                </NestedTab>
              ))}
          </NestedTabs>
        </div>
      </div>
      <MuiTable
        rows={rows}
        columns={columns}
        size="medium"
        stickyHeader
        flex
        // sort={sort}
        // setSort={setSort}
        // order={order}
        // setOrder={setOrder}
        // handleChangePage={(e, newPage) => setPage(newPage)}
      />
    </>
  );
};

Equipment.propTypes = {};

export default Equipment;
