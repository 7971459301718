import React from "react";
import { connect } from "react-redux";
import { useRouteMatch, Link } from "react-router-dom";
import { Add, Edit, Delete, MoreHoriz } from "@mui/icons-material";
import {
  Typography,
  Popover,
  IconButton,
  ListItemIcon,
  MenuItem,
} from "@mui/material";
import moment from "moment";

import { CollapsibleTable } from "../../Table";
import { ReportSearch } from "../../Reports";
import { NodeDeleteDialog } from "../../ConfirmationDialog";
import { getCrmCalls } from "../../../actions";
import CallsForm from "./CallsForm";
import Button from "common/Button";
import CallsEditForm from "./CallsEditForm";
import LinkHeader from "common/LinkHeader";
import { generateTempUUID } from "utility";

const Actions = ({ pid, url }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return <>
    <IconButton aria-label="delete" onClick={handleClick} size="large">
      <MoreHoriz />
    </IconButton>
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <MenuItem component={Link} to={`${url}/edit/${pid}`}>
        <ListItemIcon>
          <Edit fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">Edit</Typography>
      </MenuItem>
      <MenuItem component={Link} to={`${url}/remove/${pid}`}>
        <ListItemIcon>
          <Delete fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">Remove</Typography>
      </MenuItem>
    </Popover>
  </>;
};

const columns = [
  { id: "logged_by_name", label: "Caller", width: 200 },
  { id: "created", label: "Created", width: 200 },
  { id: "subject", label: "Subject", width: 200 },
  { id: "status", label: "Status", width: 200 },
  { id: "call_type", label: "Call Type", minWidth: 200, width: 200 },
  { id: "actions", label: "", minWidth: 50, width: 50 },
];

const createData = (
  logged_by_name,
  paths,
  call_type,
  notes,
  status,
  subject,
  time,
  actions
) => {
  return {
    logged_by_name: (
      <LinkHeader size="small" to={paths.logged_by}>
        {logged_by_name}
      </LinkHeader>
    ),
    created: (
      <div>
        <Typography variant="body1">
          {moment(time).format("MMM Do, YYYY")}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {moment(time).format("h:mm A")}
        </Typography>
      </div>
    ),
    call_type,
    status,
    subject,
    actions,
    collapsible: (
      <div>
        <Typography variant="subtitle1">Comment</Typography>
        <Typography variant="body1" style={{ maxWidth: "600px" }}>
          {notes}
        </Typography>
      </div>
    ),
  };
};

const Calls = ({
  calls=[],
  loading,
  pagination={ count: 0, current_page: 0, total_pages: 0 },
  loadCRMCalls,
  parentEntity,
  statuses=[],
  loadingStatus,
  types,
  loadingTypes,
  shortList,
}) => {
  const { url } = useRouteMatch();
  const { nid } = parentEntity;
  const [searchTerm, setSearchTerm] = React.useState("");
  const [refreshPage, setRefreshPage] = React.useState(null);

  const rows = React.useMemo(() => {
    if (loading) return [];
    return calls.map((row) =>
      createData(
        row.logged_by_name,
        row._paths,
        row.call_type,
        row.notes,
        row.status,
        row.subject,
        row.time,
        <Actions pid={row.nid} url={url} />
      )
    );
  }, [calls, loading, url]);

  return (
    <div>
      <div style={{ padding: "1.25rem" }}>
        <Button
          variant="contained"
          color="tertiary"
          disableElevation
          size="small"
          component={Link}
          to={`${url}/add`}
          startIcon={<Add />}
        >
          Add
        </Button>
      </div>
      <ReportSearch setQuery={setSearchTerm} />
      <CollapsibleTable
        columns={columns}
        rows={rows}
        stickyHeader
        loading={loading}
        param={nid}
        hasParam={true}
        pageRequest={loadCRMCalls}
        rowsPerPage={shortList ? 6 : null}
        hideColumns={[]}
        search={searchTerm}
        originalOrderBy="changed"
        originalOrder="desc"
        pagination={pagination}
        size="small"
        refreshPage={refreshPage}
      />

      <CallsForm
        url={url}
        nid={nid}
        loadCRMCalls={() => setRefreshPage(generateTempUUID())}
        statuses={statuses}
        loadingStatus={loadingStatus}
        types={types}
        loadingTypes={loadingTypes}
      />
      <CallsEditForm
        url={url}
        loadCRMCalls={() => setRefreshPage(generateTempUUID())}
        statuses={statuses}
        loadingStatus={loadingStatus}
        types={types}
        loadingTypes={loadingTypes}
      />
      <NodeDeleteDialog
        url={url}
        refreshMethod={() => setRefreshPage(generateTempUUID())}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  calls: state.app.crmCalls.data.data,
  loading: state.app.crmCalls.loading,
  pagination: state.app.crmCalls.data.pagination,
  statuses: state.app.memberNoteStatus.data,
});

const mapDispatchToProps = (dispatch) => ({
  loadCRMCalls: (nid, params) => dispatch(getCrmCalls(nid, params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Calls);
