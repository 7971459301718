import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Checkbox,
  Typography,
  Grid,
  FormControlLabel
} from "@mui/material";

import { TextFormField } from "common/Fields";
import { DialogForm } from "common/Dialog";
// import { Editor } from "react-draft-wysiwyg";
// import { EditorState, ContentState, convertFromHTML, convertToRaw } from "draft-js";
// import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { postJobCustomerWelcome } from "../jobSlice";
import Alert from "common/Alert";

const CustomerWelcome = ({ job, customer, user, children }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  // const [sendMessage, setSendMessage] = useState(true);
  // const [createUser, setCreateUser] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);


  const custFirstName = customer.title.slice(0, customer.title.indexOf(" "));
  const custLastName = customer.title.slice(customer.title.indexOf(" ") + 1);
  // const messageHTML = `<p>Hello ${custFirstName},</p>
  //   <p>I'm ${user.first} ${user.last} with Helix, and I wanted to thank you for choosing us to help with your home improvements. We have received your information, and will begin assigning service providers to build estimates for your project. We will keep you updated as the estimates are scheduled.</p>
  //   <p>Thank you,<br/>
  //         -${user.first} ${user.last}</p>`;
  // const [markup, setMarkup] = React.useState(messageHTML);
  // const [editorState, setEditorState] = React.useState(
  //   EditorState.createWithContent(
  //     ContentState.createFromBlockArray(
  //       convertFromHTML(messageHTML)
  //     )
  //   )
  // );

  const [error, setMessage] = useState(null);

  // const handleEditorStateChange = (editorState) => {
  //   const contentState = editorState.getCurrentContent();
  //   const rawContentState = convertToRaw(contentState);

  //   const markup = draftToHtml(rawContentState);

  //   setMarkup(markup);
  //   setEditorState(editorState);
  // };

  // const handleSendMessage = (e) => {
  //   setSendMessage(e.target.checked);
  // };

  // const handleCreateUser = (e) => {
  //   setCreateUser(e.target.checked);
  // };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = (data) => {
    const params = {
      job_nid: job.nid,
      customer_nid: customer.nid,
      send_message: data.send_message,
      // message: markup,
      create_user: data.create_user,
      username: data.username,
      field_first_name: data.field_first_name,
      field_last_name: data.field_last_name,
      email: data.email,
      field_phone: data.field_phone,
    };

    if(data.send_message || data.create_user){
      setIsSubmitting(true);
      dispatch(postJobCustomerWelcome(params))
        .then(response => {
          setIsSubmitting(false);
          if(!response.error){
            onClose();
          }
          else{
            setMessage({
              id: "negative",
              msg: response.payload.message,
            })
          }
        })
    }
    else{
      onClose();
    }
  };

  const onClose = () => {
    setMessage(null);
    setOpen(false);
  };

  return (
    <>
      {children && (
        React.cloneElement(children, {
          onClick: handleOpen,
        })
      )}
      <DialogForm
        open={open}
        onClose={onClose}
        maxWidth="md"
        title="Welcome Customer"
        initialValues={{
          send_message: false,
          // message: markup,
          create_user: true,
          username: customer.field_customer_email,
          field_first_name: custFirstName,
          field_last_name: custLastName,
          email: customer.field_customer_email,
          field_phone: customer.field_phone,
        }}
        disablePadding
        onSubmit={handleSubmit}
        disableActions={isSubmitting}
        // disableOverlay
        overrideDirty
      >
        {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
          <div className="p-5">
            {error && <Alert kind={error.id}>{error.msg}</Alert>}
            {/* <Typography
              variant="subtitle1"
              color="textSecondary"
            >
              Welcome Message
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  name="send_message"
                  checked={values.send_message}
                  onChange={(e) => {
                    setFieldValue('send_message', e.target.checked);
                  }}
                />
              }
              label="Send Welcome Message"
            />
            {(values.send_message &&
              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="border border-gray-middle min-h-[100px] rounded-sm px-4"
                toolbar={{
                  options: [
                    "inline",
                    "blockType",
                    "fontSize",
                    "fontFamily",
                    "list",
                    "textAlign",
                    "colorPicker",
                    "link",
                    "history",
                  ],
                  inline: {
                    options: ["bold", "italic", "underline"],
                  },
                }}
                onEditorStateChange={handleEditorStateChange}
              />
            )}
            <hr /> */}
            <Typography
              variant="subtitle1"
              color="textSecondary"
              className="mt-4"
            >
              User Account Information
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  name="create_user"
                  checked={values.create_user}
                  onChange={(e) => {
                    setFieldValue('create_user', e.target.checked);
                  }}
                />
              }
              label="Create Helix User Account"
            />
            {(values.create_user &&
              <>
              <Grid container spacing={3}>
                <Grid item xxs={6} md={6}>
                  <TextFormField
                    name="username"
                    label="Username"
                    fullWidth
                    variant="filled"
                    size="small"
                    required={true}
                  />
                </Grid>
                <Grid item xxs={6} md={6}>
                  <TextFormField
                    name="email"
                    label="Email"
                    fullWidth
                    variant="filled"
                    size="small"
                    required={true}
                  />
                </Grid>
                <Grid item xxs={6} md={4}>
                  <TextFormField
                    name="field_first_name"
                    label="First Name"
                    fullWidth
                    variant="filled"
                    size="small"
                    required={true}
                  />
                </Grid>
                <Grid item xxs={6} md={4}>
                  <TextFormField
                    name="field_last_name"
                    label="Last Name"
                    fullWidth
                    variant="filled"
                    size="small"
                    required={true}
                  />
                </Grid>
                <Grid item xxs={6} md={4}>
                  <TextFormField
                    name="field_phone"
                    label="Phone"
                    fullWidth
                    variant="filled"
                    size="small"
                    isPhone
                  />
                </Grid>
              </Grid>
              </>
            )}
          </div>
        )}
      </DialogForm>
    </>
  );
};

CustomerWelcome.propTypes = {};

export default CustomerWelcome;
