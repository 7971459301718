
import React, { useRef, useEffect, useState/*, useCallback*/ } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  // DialogActions,
  IconButton,
  Button,
  Tooltip,
  // Typography,
} from "@mui/material";
import "./Camera.scss";
import CloseIcon from "@mui/icons-material/Close";
import { DefaultLoader } from "common/Loader";
import { IoCamera, IoCameraReverseSharp } from "react-icons/io5";
import { FiCheck } from "react-icons/fi";
import { IoMdUndo } from "react-icons/io";


const Camera = ({
  open,
  title,
  onSubmit,
  onError,
  onClose,
  fieldName,
  setFieldValue,
  omitOpenButton,
  openButtonTooltip,
  submitButton,
  ...props
}) => {
  const facingMode = localStorage.getItem("camera_facing_mode_default");
  const [facingModeChange, setFacingModeChange] = useState(facingMode ? facingMode : "user");
  const [openButton, setOpenButton] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [imgData, setImgData] = useState(null);
  const videoRef = useRef();
  const canvasRef = useRef();

  useEffect(() => {
    if(open || openButton){
      navigator.mediaDevices.getUserMedia({
        video: {facingMode: facingModeChange}
      }).then(function (stream) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
        localStorage.setItem("camera_facing_mode_default", facingModeChange);
        videoRef.current.addEventListener('loadeddata', (e) => {
          //Video should now be loaded but we can add a second check

          if(videoRef.current.readyState >= 3){
            setLoaded(true);
          }

       });
      })
      .catch((err) => {
        if(onError){
          onError(err);
        }
      });
    }
    else{
      stopVideo();
    }
  }, [open, openButton, facingModeChange]); // eslint-disable-line react-hooks/exhaustive-deps

  const stopVideo = () => {
    if(videoRef?.current && videoRef.current?.srcObject){
      const stream = videoRef.current.srcObject;
      const tracks = stream.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });

      videoRef.current.srcObject = null;
    }
    setLoaded(false);
    setImgData(null);
  }

  const takeSnapshot = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const vidDims = videoRef.current.getBoundingClientRect();
    canvas.style.display = "block";
    canvas.style.width = vidDims.width + "px";
    canvas.style.height =  vidDims.height + "px";
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const context = canvasRef.current.getContext('2d');
    context.drawImage(videoRef.current, 0, 0, video.videoWidth, video.videoHeight);
    const _imgData =  canvasRef.current.toDataURL("image/jpeg");
    setImgData(_imgData);
    if(setFieldValue){
      setFieldValue(fieldName, _imgData);
    }
  }

  const retake = () => {
    const canvas = canvasRef.current;
    canvas.style.display = null;
    setImgData(null);
    if(setFieldValue){
      setFieldValue(fieldName, null);
    }
  }

  return (
    <>
    {!omitOpenButton &&
      <Tooltip
        title={openButtonTooltip}
      >
        <span>
          <IconButton
            onClick={() => {
              setOpenButton(true);
            }}
            size="large"
            {...props}
          >
            <IoCamera color="inherit" />
          </IconButton>
        </span>
      </Tooltip>
    }
    <Dialog
      open={open || openButton}
      // onClose={}
      aria-labelledby="camera-dialog-dialog-title"
      aria-describedby="camera-dialog-dialog-description"
      className="camera-dialog"
    >
      <DialogTitle id="camera-dialog-dialog-title">
        {title}
        <CloseIcon className="close-buttom" onClick={() => {
          stopVideo();
          setOpenButton(false);
          if(onClose){
            onClose();
          }
        }} />
      </DialogTitle>
      <DialogContent>
        {/* {message && <Alert kind={message.id}>{message.msg}</Alert>} */}
        <div  id="camera-dialog-dialog-description">
          <div className="image-wrapper" id="camera-dialog-dialog-description">
            <video className="video" ref={videoRef} playsInline />
            <div className="loading"><DefaultLoader /></div>
            <canvas className="canvas" ref={canvasRef} />
          </div>
            <div className="button-wrapper">
            {loaded &&
              <>
              {!Boolean(imgData) ? (
                <>
                <IoCameraReverseSharp
                  className="switch-button"
                  onClick={() => setFacingModeChange(facingModeChange === "user" ? "environment" : "user")}
                />
                <Button
                  className="snapshot-button"
                  onClick={takeSnapshot}
                ></Button>
                </>
              ) : (
                <>
                <Button
                  variant="outlined"
                  color="secondary"
                  className="snapshot-retake"
                  onClick={retake}
                >
                  <IoMdUndo className="icon" /> Retake
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  className="snapshot-submit"
                  onClick={() => {
                    stopVideo();
                    onSubmit(imgData);
                    setOpenButton(false);
                  }}
                >
                  {submitButton ? (
                    submitButton
                  ) : (
                    <><FiCheck className="icon" /> Use Photo</>
                  )}
                </Button>
                </>
              )}
            </>
          }
          </div>
        </div>
      </DialogContent>
    </Dialog>
    </>
  );
};

Camera.propTypes = {};

export default Camera;
