import React from "react";
import { Typography, Grid } from "@mui/material";
import styled from "styled-components";

import IconTextItem from "common/IconTextItem";
import AddDate from "../forms/AddDate";
import AddDateRange from "../forms/AddDateRange";
import AddDateTime from "../forms/AddDateTime";

const Button = styled.div`
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 0.25s;

  &:hover {
    background-color: var(--color-gray-background);
  }
`;

const DateItem = ({
  value,
  nid,
  icon,
  field,
  label,
  buttonText,
  format,
  children,
  range,
  initialValue,
  disableBorder,
  dateTime,
  timeFields,
  editable,
}) => {
  if(timeFields
    && !timeFields.includes(field)
    && !timeFields.includes(`field_jd_progress_data::${field}`)){
    return null;
  }

  if(!editable && !value){
    return null;
  }

  const iconItem = <IconTextItem icon={icon}>
                    <Typography
                      variant="subtitle2"
                      style={{ textTransform: "uppercase" }}
                      color="textSecondary"
                    >
                      {label}
                    </Typography>
                    {children}
                  </IconTextItem>;

  return (
    <Grid item xxs={12} md={4} /*style={{"order": (value ? 1 : 6)}}*/>
      {!editable ? (
        iconItem
      ) : range ? (
        <AddDateRange
          title={label ? label : 'Add Dates'}
          buttonText={buttonText}
          nid={nid}
          field={field}
          format={format}
          initialValue={value}
        >
          {value && (
            <Button>
              {iconItem}
            </Button>
          )}
        </AddDateRange>
      ) : dateTime ? (
        <AddDateTime
          title={label ? label : 'Add Date'}
          buttonText={buttonText}
          nid={nid}
          field={field}
          format={format}
          initialValue={value}
        >
          {value && (
            <Button>
              {iconItem}
            </Button>
          )}
        </AddDateTime>
      ) : (
        <AddDate
          title={label ? label : 'Add Date'}
          buttonText={buttonText}
          nid={nid}
          field={field}
          format={format}
          initialValue={value}
        >
          {value && (
            <Button>
              {iconItem}
            </Button>
          )}
        </AddDate>
      )}
    </Grid>
  );
};

DateItem.propTypes = {};

export default DateItem;
