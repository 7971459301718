import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "hooks";
import {
  FormControlLabel,
  Checkbox,
  // IconButton,
  // Tooltip,
} from "@mui/material";
import Dialog from "common/Dialog";
import TimeCardLineItem from "./components/TimeCardLineItem";
import { ClockIcon } from "common/ClockIcon";
import {
  fetchTimeCards,
  timeCardsOpenSelectors,
  getTimeCardsOpenLoadingSelector,
} from "./timeClockSlice";
import { getDivisionDataSelector, } from "features/Job/jobSlice";
import { DefaultLoader } from "common/Loader";
import { FilterMenu } from "common/Table";
import { generateTempUUID } from "utility";
import TimeCardBulkEditAction from "./components/TimeCardBulkEditAction";
import "./styles/TimeCardBulkEdit.scss";

const TimeCardBulkEdit = ({disabled, title, onClose, }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState('');
  const [checked, setChecked] = useState([]);
  const division = useSelector(getDivisionDataSelector);
  const loading = useSelector(getTimeCardsOpenLoadingSelector);
  const _openCards = useSelector(timeCardsOpenSelectors.selectAll);
  const openCards = _openCards.map((card, j) => ({
    ...card,
    ...card.time_cards[0],
  }));
  const [query, setQuery] = useState("");
  const searchQuery = useDebounce(query, 500);

  useEffect(() => {
    if(open){
      let promise = null;
      const params = {open_time_cards: true};
      if(searchQuery !== ''){
        params.keywords = searchQuery;
      }
      promise = dispatch(
        fetchTimeCards({
          id: division?.nid,
          params,
        })
      );
    //  Remove checks for cards that are gone from the list
      promise.then((resp) => {
        const _cards = resp.payload.cards;
        const newChecked = checked.filter((nid) => _cards.find((card) => card.daily_sheet_nid === nid));
        setChecked([...newChecked])
      })

      return () => {
        promise.abort();
      };
    }
  }, [open, searchQuery, refresh]); // eslint-disable-line

  const handleRefresh = () => {
    setRefresh(generateTempUUID());
  }

  const handleClose = () => {
    if(onClose){
      onClose();
    }
    setOpen(false)
  }

  const _title = (
    title ? (title) : (
      <span className="flex">{<ClockIcon run={true} className="mr-2" />} Manage Open Time Cards</span>
    )
  );

  const handleCheck = (nid) => {
    if(!checked.includes(nid)){
      setChecked([...checked, nid]);
    }
    else{
      setChecked([...checked.filter((_nid) => _nid !== nid)]);
    }
  }

  const allChecked = openCards.filter((card) => checked.includes(card.daily_sheet_nid)).length === openCards.length;

  const buttonsDisabled = loading || checked.length === 0;

  const checkedCards = openCards.filter((card) => checked.includes(card.daily_sheet_nid));

  return (
    <>
    <ClockIcon
      run={!disabled}
      title="Manage Open Time Cards"
      editIcon
      className={`button ${disabled ? 'disabled' : ''}`}
      onClick={() => {
        if(!disabled){
          setOpen(true)
        }
      }}
    />
    <Dialog
      open={open}
      onClose={handleClose}
      title={_title}
      paperProps={{
        className: "time-clock dialog",
        id: "time-card-bulk-edit",
      }}
      fullHeight

    >
      <div className="bulk-edit">
        <div className="heading">
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => {
                  if(allChecked){
                    setChecked([]);
                  }
                  else{
                    setChecked([...openCards.map((card) => card.daily_sheet_nid)]);
                  }
                }}
                checked={allChecked}
                disabled={loading || !openCards.length}
              />
            }
            label="Select All"
          />
          <div className="actions">
            {[
              "start_time",
              "break_start",
              "break_end",
              "end_time",
              "ignore_signature",
              "delete",
                ].map((type, i) =>
                  <TimeCardBulkEditAction
                    type={type}
                    key={i}
                    cards={checkedCards}
                    checked={checked}
                    disabled={buttonsDisabled}
                    handleRefresh={handleRefresh}
                  />
            )}
          </div>
        </div>
        <FilterMenu
          className="filters"
          search={{
            query,
            setQuery,
          }}
        />
        {loading ? (
          <DefaultLoader />
        ) : (
        openCards.map((card, j) => {
          const isChecked = checked.includes(card.daily_sheet_nid);
          return (
            <div key={j} className={`edit-item ${isChecked ? 'checked' : ''}`}>
              <div className="check">
                <Checkbox
                  onChange={() => handleCheck(card.daily_sheet_nid)}
                  checked={isChecked}
                />
              </div>
              <TimeCardLineItem
                card={card}
                isBulkEditItem
                onClick={() => handleCheck(card.daily_sheet_nid)}
                handleRefresh={handleRefresh}
              />
            </div>
          );
        })
      )}
      </div>
    </Dialog>
    </>
  );
};

TimeCardBulkEdit.propTypes = {};

export default TimeCardBulkEdit;
