import { EditorState, AtomicBlockUtils } from "draft-js";
import linkifyIt from "linkify-it";
import tlds from "tlds";

import { isYoutube, isVimeo, getYoutubeSrc, getVimeoSrc } from "../../utils";

const YOUTUBE_PREFIX = "https://www.youtube.com/embed/";
const VIMEO_PREFIX = "https://player.vimeo.com/video/";

const linkify = linkifyIt();
linkify.tlds(tlds);

export const extractLinks = (text) => {
  return linkify.match(text);
};

export const linkStrategy = (contentBlock, callback) => {
  const links = extractLinks(contentBlock.getText());
  if (links) {
    for (const link of links) {
      callback(link.index, link.lastIndex);
    }
  }
};

export const extractVideoLink = (src) => {
  if (isYoutube(src)) {
    const { srcID } = getYoutubeSrc(src);
    return `${YOUTUBE_PREFIX}${srcID}`;
  }
  if (isVimeo(src)) {
    const { srcID } = getVimeoSrc(src);
    return `${VIMEO_PREFIX}${srcID}`;
  }
  return undefined;
};

export const addVideoLinkEntities = (editorState) => {
  let currentState = editorState;
  const contentState = currentState.getCurrentContent();
  const blockMap = contentState.getBlockMap();
  blockMap.forEach((block) => {
    const links = extractLinks(block.getText());
    if (links) {
      for (const link of links) {
        const src = extractVideoLink(link.url);
        if (src) {
          const urlType = "draft-js-video-plugin-video";
          const contentState = editorState.getCurrentContent();
          const contentStateWithEntity = contentState.createEntity(
            urlType,
            "IMMUTABLE",
            { src }
          );
          const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
          const newEditorState = AtomicBlockUtils.insertAtomicBlock(
            editorState,
            entityKey,
            " "
          );

          currentState = EditorState.forceSelection(
            newEditorState,
            newEditorState.getCurrentContent().getSelectionAfter()
          );
        }
      }
    }
  });

  return currentState;
};
