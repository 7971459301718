import React, { useState } from "react";
import styled from "styled-components";
import { FiMaximize2, FiMinimize2 } from "react-icons/fi";
import constants from "components/constants";

const Styles = styled.div`
  position: relative;
  transition: all 0.25s ease-out;

  .maximize,
  .minimize{ display: none; }

  @media (max-width: ${constants.breakpoints.sm}) {
    .maximize,
    .minimize{
      position: absolute;
      top: 0.75rem;
      right: 0.75rem;
      z-index: 10000;
    }
    .maximize{ display: block; }
  }

  &.maximized {
    position: fixed !important;
    z-index: 99999;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100) !important;
    left: 0;
    background-color: rgba(0,0,0,0.3);

    &.from-top{
      top: 0;
    }
    &.from-bottom{
      bottom: 0;
    }
    &.from-right{
      right: 0;
    }

    .maximize{ display: none; }
    .minimize{ display: block; }
  }
`;

const Maximize = ({ onMaximize, onMinimize, expandFrom, children, ...props }) => {
  const [maximized, setMaximized] = useState(false);
  let className = props.className;
  if(maximized){
    className += ' maximized';
  }
  if(!expandFrom){
    expandFrom = 'top';
  }
  className += ' from-' + expandFrom;


  return (
    <Styles className={className}>
      {/* <Backdrop className="backdrop" open={maximized} /> */}
      {children}
      <FiMaximize2
        className="maximize icon-circle-button"
        onClick={() => {
          setMaximized(true);
          onMaximize();
        }}
      />
      <FiMinimize2
        className="minimize icon-circle-button"
        onClick={() => {
          setMaximized(false);
          onMinimize();
        }}
      />
    </Styles>
  );
};

Maximize.propTypes = {};

export default Maximize;
