import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Helmet from "react-helmet";
import { Typography, TextField, Grid } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Autocomplete } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { FilterList } from "@mui/icons-material";
import { GetApp } from "@mui/icons-material";
import moment from "moment";

import ReportSearch from "components/Reports/ReportSearch";
import Alert from "common/Alert";
import ButtonDropdown from "common/ButtonDropdown";
import LinkHeader from "common/LinkHeader";
import Paper from "components/Paper";
import {
  ActionGroup,
  Actions,
  FilterMenu,
  ButtonGroup,
} from "components/Reports/Styled";
import { formatFilters, removeColumns } from "utility";
import {
  countiesSelectors,
  jobDivisionsStatusSelectors,
  jobDivisionTypesSelectors,
  sourceOfLossSelectors,
  statesSelectors,
} from "features/Taxonomies/taxonomiesSlice";
import {
  fetchAgingUserMinutesReport,
  getReportPaginationSelector,
  getReportDataSelector,
  getReportLoadingSelector,
  getReporErrorSelector,
} from "../reportsSlice";
import { MuiTable } from "common/Table";
import { exportBatch } from "actions";
import Button from "common/Button";
import constants from "components/constants";

const useStyles = makeStyles((theme) => ({
  button: {
    background: constants.gradientBlack,
    paddingRight: "10px",
    color: constants.colorWhite,
    "&:hover": {
      background: constants.gradientBlack,
      color: constants.colorWhite,
    },
  },
}));

const columnSettings = {
  title: { minWidth: 150 },
  customer_name: { minWidth: 400 },
  customer_location_name: { minWidth: 400 },
  customer_location_state: { minWidth: 400 },
  customer_location_county: { minWidth: 400 },
  member_name: { minWidth: 400 },
  category_proper: { minWidth: 150 },
  status_proper: { minWidth: 200 },
  sources_of_loss: { minWidth: 200 },
  days_between_date_received_and_review: { minWidth: 400 },
  days_between_date_received_and_review_complete: { minWidth: 400 },
  days_between_review_and_review_complete: { minWidth: 400 },
  days_between_date_received_and_ar: { minWidth: 400 },
  days_between_ar_and_closed: { minWidth: 400 },
  days_between_date_received_and_closed: { minWidth: 400 },
  user_time_for_date_received: { minWidth: 400 },
  user_time_for_created: { minWidth: 400 },
  user_time_for_request_sent: { minWidth: 400 },
  user_time_for_accepted: { minWidth: 400 },
  user_time_for_assigned: { minWidth: 400 },
  user_time_for_en_route: { minWidth: 400 },
  user_time_for_arrived: { minWidth: 400 },
  user_time_for_inspection: { minWidth: 400 },
  user_time_for_wait_approval: { minWidth: 400 },
  user_time_for_wait_client_approval: { minWidth: 400 },
  user_time_for_scheduled_prod: { minWidth: 400 },
  user_time_for_in_progress: { minWidth: 400 },
  user_time_for_work_complete: { minWidth: 400 },
  user_time_for_review: { minWidth: 400 },
  user_time_for_review_complete: { minWidth: 400 },
  user_time_for_qc: { minWidth: 400 },
  user_time_for_ar: { minWidth: 400 },
  user_time_for_complete: { minWidth: 400 },
  user_time_for_closed: { minWidth: 400 },
  user_time_for_draft: { minWidth: 400 },
  user_time_for_awaiting_claim: { minWidth: 400 },
  user_time_for_awaiting_po: { minWidth: 400 },
  user_time_for_bid_declined: { minWidth: 400 },
  user_time_for_invalid: { minWidth: 400 },
  user_time_for_archived: { minWidth: 400 },
};

const createColumns = (columns) => {
  if (!columns) return [];

  return columns.map((c) => {
    let column = { ...c };
    column.id = column.index;
    column = {
      ...column,
      notSortable: !column.sortable,
      ...columnSettings[column.index],
    };
    return column;
  });
};

const createData = (
  title,
  customer_name,
  customer_location_name,
  customer_location_state,
  customer_location_county,
  member_name,
  category_proper,
  status_proper,
  sources_of_loss,
  days_between_date_received_and_review,
  days_between_date_received_and_review_complete,
  days_between_review_and_review_complete,
  days_between_date_received_and_ar,
  days_between_ar_and_closed,
  days_between_date_received_and_closed,
  user_time_for_date_received,
  user_time_for_created,
  user_time_for_request_sent,
  user_time_for_accepted,
  user_time_for_assigned,
  user_time_for_en_route,
  user_time_for_arrived,
  user_time_for_inspection,
  user_time_for_wait_approval,
  user_time_for_wait_client_approval,
  user_time_for_scheduled_prod,
  user_time_for_in_progress,
  user_time_for_work_complete,
  user_time_for_review,
  user_time_for_review_complete,
  user_time_for_qc,
  user_time_for_ar,
  user_time_for_complete,
  user_time_for_closed,
  user_time_for_draft,
  user_time_for_awaiting_claim,
  user_time_for_awaiting_po,
  user_time_for_bid_declined,
  user_time_for_invalid,
  user_time_for_archived,
  paths
) => {
  return {
    title: title ? (
      <LinkHeader to={paths.division} size="small" color="default">
        {title}
      </LinkHeader>
    ) : null,
    customer_name: customer_name ? (
      <LinkHeader to={paths.customer} size="small" color="default">
        {customer_name}
      </LinkHeader>
    ) : null,
    customer_location_name: customer_location_name ? (
      <LinkHeader to={paths.customer_location} size="small" color="default">
        {customer_location_name}
      </LinkHeader>
    ) : null,
    customer_location_state: customer_location_state,
    customer_location_county: customer_location_county,
    member_name: member_name ? (
      <LinkHeader to={paths.member} size="small" color="default">
        {member_name}
      </LinkHeader>
    ) : null,
    category_proper,
    status_proper,
    sources_of_loss,
    days_between_date_received_and_review:
      days_between_date_received_and_review,
    days_between_date_received_and_review_complete:
      days_between_date_received_and_review_complete,
    days_between_review_and_review_complete:
      days_between_review_and_review_complete,
    days_between_date_received_and_ar: days_between_date_received_and_ar,
    days_between_ar_and_closed: days_between_ar_and_closed,
    days_between_date_received_and_closed:
      days_between_date_received_and_closed,
    user_time_for_date_received: user_time_for_date_received,
    user_time_for_created: user_time_for_created,
    user_time_for_request_sent: user_time_for_request_sent,
    user_time_for_accepted: user_time_for_accepted,
    user_time_for_assigned: user_time_for_assigned,
    user_time_for_en_route: user_time_for_en_route,
    user_time_for_arrived: user_time_for_arrived,
    user_time_for_inspection: user_time_for_inspection,
    user_time_for_wait_approval: user_time_for_wait_approval,
    user_time_for_wait_client_approval: user_time_for_wait_client_approval,
    user_time_for_scheduled_prod: user_time_for_scheduled_prod,
    user_time_for_in_progress: user_time_for_in_progress,
    user_time_for_work_complete: user_time_for_work_complete,
    user_time_for_review: user_time_for_review,
    user_time_for_review_complete: user_time_for_review_complete,
    user_time_for_qc: user_time_for_qc,
    user_time_for_ar: user_time_for_ar,
    user_time_for_complete: user_time_for_complete,
    user_time_for_closed: user_time_for_closed,
    user_time_for_draft: user_time_for_draft,
    user_time_for_awaiting_claim: user_time_for_awaiting_claim,
    user_time_for_awaiting_po: user_time_for_awaiting_po,
    user_time_for_bid_declined: user_time_for_bid_declined,
    user_time_for_invalid: user_time_for_invalid,
    user_time_for_archived: user_time_for_archived,
  };
};

const initialFormData = {
  customer_location_state: [],
  customer_location_county_tid: [],
  status: [],
  job_division_type_tid: [],
  sources_of_loss_tid: [],
  date_received: {
    type: "date-range",
    value: { from: null, through: null },
  },
  start_date: { value: null },
  completion_date: { value: null },
};

const AgingUserMinutes = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const counties = useSelector(countiesSelectors.selectAll);
  const states = useSelector(statesSelectors.selectAll);
  const statuses = useSelector(jobDivisionsStatusSelectors.selectAll);
  const sources = useSelector(sourceOfLossSelectors.selectAll);
  const divisions = useSelector(jobDivisionTypesSelectors.selectAll);
  const pagination = useSelector(getReportPaginationSelector);
  const report = useSelector(getReportDataSelector);
  const loading = useSelector(getReportLoadingSelector);
  const error = useSelector(getReporErrorSelector);
  const org = useSelector((state) => state.auth.current_client);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filters, setFilters] = React.useState({});
  const [formData, setFormData] = React.useState(initialFormData);

  useEffect(() => {
    let filterSettings = formatFilters(filters);
    if (org) {
      filterSettings = {
        ...filterSettings,
        "filter[phx_client_nid]": org,
      };
    }

    const params = {
      keywords: searchTerm,
      ...filterSettings,
    };

    const promise = dispatch(fetchAgingUserMinutesReport(params));
    return () => {
      promise.abort();
    };
  }, [dispatch, searchTerm, filters, org]);

  const handleExportReport = async () => {
    let filterSettings = formatFilters(filters);

    if (org) {
      filterSettings = {
        ...filterSettings,
        "filter[phx_client_nid]": org,
      };
    }

    await dispatch(
      exportBatch("/rest/report/jobs/aging/user-minutes", {
        _export: "csv",
        keywords: searchTerm,
        ...filterSettings,
      })
    );
  };

  const columns = React.useMemo(() => {
    if (loading) return [];
    const temp = createColumns(pagination.display_columns);
    removeColumns(
      [
        "customer_location_state",
        "customer_location_county",
        "sources_of_loss",
      ],
      temp
    );
    return temp;
  }, [pagination, loading]);

  const rows = React.useMemo(() => {
    if (loading) return [];
    return report.map((row) =>
      createData(
        row.title,
        row.customer_name,
        row.customer_location_name,
        row.customer_location_state,
        row.customer_location_county,
        row.member_name,
        row.category_proper,
        row.status_proper,
        row.sources_of_loss,
        row.days_between_date_received_and_review,
        row.days_between_date_received_and_review_complete,
        row.days_between_review_and_review_complete,
        row.days_between_date_received_and_ar,
        row.days_between_ar_and_closed,
        row.days_between_date_received_and_closed,
        row.user_time_for_date_received,
        row.user_time_for_created,
        row.user_time_for_request_sent,
        row.user_time_for_accepted,
        row.user_time_for_assigned,
        row.user_time_for_en_route,
        row.user_time_for_arrived,
        row.user_time_for_inspection,
        row.user_time_for_wait_approval,
        row.user_time_for_wait_client_approval,
        row.user_time_for_scheduled_prod,
        row.user_time_for_in_progress,
        row.user_time_for_work_complete,
        row.user_time_for_review,
        row.user_time_for_review_complete,
        row.user_time_for_qc,
        row.user_time_for_ar,
        row.user_time_for_complete,
        row.user_time_for_closed,
        row.user_time_for_draft,
        row.user_time_for_awaiting_claim,
        row.user_time_for_awaiting_po,
        row.user_time_for_bid_declined,
        row.user_time_for_invalid,
        row.user_time_for_archived,
        row._paths
      )
    );
  }, [report, loading]);

  const handleChange = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateRangeChange = (value, name, index) => {
    const dateValue = formData[name].value;
    dateValue[index] = value;
    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value: { ...dateValue },
      },
    });
  };

  const applyFilters = () => {
    setFilters({ ...formData });
  };

  const resetFilters = () => {
    setFilters({});
    setFormData({
      ...initialFormData,
      date_received: {
        type: "date-range",
        value: { from: null, through: null },
      },
    });
  };

  return (
    <div>
      <Helmet>
        <title>Aging Report | Helix</title>
        <meta name="description" content="Reports" />
      </Helmet>
      {error && <Alert kind="negative">{error.message}</Alert>}
      <LinkHeader to="/reports" color="default" size="small" back>
        Back to reports
      </LinkHeader>
      <Paper margin="none" style={{ marginTop: "1rem" }}>
        <Actions>
          <Typography variant="h6">Aging Report User Minutes</Typography>
          <ActionGroup>
            <ButtonDropdown
              icon={FilterList}
              label="Filters"
              style={{ marginLeft: "1rem" }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              color="tertiary"
            >
              <FilterMenu>
                <Autocomplete
                  options={states.map((state) => ({
                    label: state.label,
                    value: state.value,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "customer_location_state");
                  }}
                  value={formData.customer_location_state}
                />
                <Autocomplete
                  options={counties.map((county) => ({
                    label: county.name,
                    value: county.tid,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="County"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "customer_location_county_tid");
                  }}
                  value={formData.customer_location_county_tid}
                />
                <Autocomplete
                  options={statuses.map((status) => ({
                    label: status.name,
                    value: status.machine_name,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Status"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "status");
                  }}
                  value={formData.status}
                />
                <Autocomplete
                  options={divisions.map((division) => ({
                    label: division.name,
                    value: division.tid,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Division"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "job_division_type_tid");
                  }}
                  value={formData.job_division_type_tid}
                />
                <Autocomplete
                  options={sources.map((source) => ({
                    label: source.name,
                    value: source.tid,
                  }))}
                  fullWidth
                  size="small"
                  multiple
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Source"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "sources_of_loss_tid");
                  }}
                  value={formData.sources_of_loss_tid}
                />
                <Typography variant="body1" style={{ marginTop: "0.5rem" }}>
                  Date Received
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.date_received.value.from}
                        onChange={(value) =>
                          handleDateRangeChange(value, "date_received", "from")
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="From"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        InputAdornmentProps={{ size: "small" }}
                        KeyboardButtonProps={{
                          "aria-label": "change from date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.date_received.value.through}
                        onChange={(value) =>
                          handleDateRangeChange(
                            value,
                            "date_received",
                            "through"
                          )
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="Through"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        KeyboardButtonProps={{
                          "aria-label": "change through date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={formData.start_date.value}
                    onChange={(value) =>
                      handleChange(
                        {
                          value: value
                            ? moment(value)
                            : null,
                        },
                        "start_date"
                      )
                    }
                    fullWidth
                    disableToolbar
                    variant="inline"
                    format="MM/DD/YYYY"
                    label="Start Date"
                    margin="normal"
                    inputVariant="outlined"
                    size="small"
                    KeyboardButtonProps={{
                      "aria-label": "change start date",
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={formData.completion_date.value}
                    onChange={(value) =>
                      handleChange(
                        {
                          value: value
                            ? moment(value)
                            : null,
                        },
                        "completion_date"
                      )
                    }
                    fullWidth
                    disableToolbar
                    variant="inline"
                    format="MM/DD/YYYY"
                    label="Completion Date"
                    margin="normal"
                    inputVariant="outlined"
                    size="small"
                    KeyboardButtonProps={{
                      "aria-label": "change completion date",
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                  />
                </LocalizationProvider>
                <ButtonGroup>
                  <Button disableElevation size="small" onClick={resetFilters}>
                    Reset
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    size="small"
                    style={{ marginRight: "1rem" }}
                    onClick={applyFilters}
                  >
                    Apply
                  </Button>
                </ButtonGroup>
              </FilterMenu>
            </ButtonDropdown>
            <Button
              className={classes.button}
              size="small"
              startIcon={<GetApp />}
              onClick={handleExportReport}
            >
              Export
            </Button>
          </ActionGroup>
        </Actions>
        <ReportSearch setQuery={setSearchTerm} />
        <MuiTable
          rows={rows}
          columns={columns}
          loading={loading}
          size="medium"
          stickyHeader
          flex
          pagination={pagination}
          //   sort={sort}
          //   setSort={setSort}
          //   order={order}
          //   setOrder={setOrder}
          //   handleChangePage={(e, newPage) => setPage(newPage)}
          //   handleChangeRowsPerPage={(e) => setRowsPerPage(e.target.value)}
        />
      </Paper>
    </div>
  );
};

AgingUserMinutes.propTypes = {};

export default AgingUserMinutes;
