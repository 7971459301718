import styled from "styled-components";
import constants from "../../components/constants";

export const Wrapper = styled.div`
  display: flex;
  justify-content: ${({ side }) =>
    side === "right" ? "flex-end" : "flex-start"};
`;

export const Actions = styled.div`
  border-radius: ${constants.borderRadius};
  background: white;
  border: 1px solid ${constants.colorGray};
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  padding: 2px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s;
  z-index: 2;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  margin: 0.5rem 0;
  background: ${({ invertColor }) =>
    invertColor ? "var(--color-gray-background)" : "white"};
  border-left: ${({ side }) =>
    side === "right" ? "4px solid var(--color-blue-dark)" : "none"};
  padding: 0.625rem;
  border-radius: 6px;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "90%")};

  &:hover > ${Actions} {
    opacity: 1;
    pointer-events: all;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: ${({ side }) =>
    side === "right" ? "column-reverse" : "column"};
  flex: 1;
  padding-right: ${({ side }) => (side === "right" ? "1rem" : "0")};
  padding-left: ${({ side }) => (side === "right" ? "0" : "1rem")};
  color: ${constants.colorBlack};

  & > p {
    color: ${({ side }) => (side === "right" ? "white" : constants.colorBlack)};
  }

  & > div > div > p {
    margin: 0.25rem 0;
  }

  & .mention-link {
    background: ${({ owner }) => (owner ? "var(--color-blue-dark)" : "var(--color-blue-lightest)")};
    color: ${({ owner }) => (owner ? "white" : "var(--color-blue-dark)")};
    text-decoration: none;
    padding: 0 1px;
    border-radius: 2px;
    font-weight: 600;
  }

  & a {
    text-decoration: none !important;
  }

  & strong {
    font-weight: 600;
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: ${({ side }) => (side === "right" ? "flex-end" : "flex-start")};
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 0;
  height: 30px;
  flex-direction: ${({ side }) => (side === "right" ? "row-reverse" : "row")};
  justify-content: ${({ side }) =>
    side === "right" ? "flex-start" : "flex-start"};

  & p:first-of-type {
    color: ${constants.colorBlack};
    font-weight: 600;
    margin-left: ${({ side }) => (side === "right" ? "0.25rem" : "0")};
    margin-right: ${({ side }) => (side === "right" ? "0" : "0.25rem")};
  }

  & p:last-of-type {
    color: ${constants.colorGrayMedium};
    font-size: 0.75rem;
    margin-left: ${({ side }) => (side === "right" ? "0" : "0.25rem")};
    margin-right: ${({ side }) => (side === "right" ? "0.25rem" : "0")};
  }
`;

export const Error = styled.div`
  display: flex;
  align-items: center;
  color: var(--color-red);
  padding: 0 1rem;

  & > div {
    margin-right: 1rem;
  }
`;

export const CommentSubject = styled.div`
  width: 100%;
  padding: 0.25rem 0;
`;

export const CommentBody = styled.div`
  width: 100%;
  padding: 0.25rem 0;

  &:hover {
    background: var(--color-gray-background);
  }
`;
