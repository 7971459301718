import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Snapshot from "common/Snapshot";
import { default as Division } from "features/Job/D2D/components/Information";

// import { fetchWorkArea } from "./workAreaSlice";
import {
  fetchDivisionSnapshot,
  getDivisionDataSelector,
  getJobDataSelector,
  getJobLoadingSelector,
} from "features/Job/jobSlice";
import { getLocationDataSelector } from "features/Location/locationSlice";

const Information = ({ onClose }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const loading = useSelector(getJobLoadingSelector);
  const division = useSelector(getDivisionDataSelector);
  const location = useSelector(getLocationDataSelector);
  const job = useSelector(getJobDataSelector);

  useEffect(() => {
    let promise;

    if (id) {
      promise = dispatch(fetchDivisionSnapshot(id));
    }

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [dispatch, id]);

  return (
    <Snapshot title={division.title} onClick={onClose} loading={loading}>
      <Division
        job={job}
        division={division}
        location={location}
        onNavigate={() => {}}
      />
    </Snapshot>
  );
};

Information.propTypes = {};

export default Information;
