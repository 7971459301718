import React from "react";
import { useSelector } from "react-redux";
import { Typography, Grid } from "@mui/material";
import styled from "styled-components";
import { FaRegAddressBook,
  // FaUserTie,
  FaUser } from "react-icons/fa";
import {
  // BiCheckShield,
  BiWater } from "react-icons/bi";
// import { CgNotes } from "react-icons/cg";
import {
  RiTimerLine,
  // RiMoneyDollarCircleLine,
  // RiInformationLine,
  RiUser2Fill,
} from "react-icons/ri";
import {
  BsClockHistory,
  // BsShieldShaded
} from "react-icons/bs";
import { TiInfoOutline } from "react-icons/ti";
import { Gi3DStairs } from "react-icons/gi";

import LinkHeader from "common/LinkHeader";
import { contactMethods, causes } from "../utils";
import IconTextItem from "common/IconTextItem";
// import Button from "common/Button";
// import Empty from "common/Empty";
// import { default as AddInsurance } from "features/Insurance/forms/Create";
import Card, { CardHeader } from "common/Card";
// import { formatMoney } from "utility";

import { getDivisionDataSelector } from "../../jobSlice";
import { getLocationDataSelector } from "features/Location/locationSlice";
// import { getInsuranceDataSelector } from "features/Insurance/insuranceSlice";
import { getCustomerDataSelector } from "features/Customer/customerSlice";
import LocationJobHistory from "../../components/LocationJobHistory";
import CustomerCommunication from "../../components/CustomerCommunication";
import EditJobDetailNotes from "../../forms/EditJobDetailNotes";
import JobSource from "../../forms/JobSource";

const StyledGridContainer = styled(Grid)`
 &.MuiGrid-container {
  margin: 0;
  padding: 0 0.5rem 0 0;
  width: auto;

  .MuiGrid-item {
    padding: 0.25em 0 0;

    &.label{
      text-align: right;
      padding-right: 0.5rem;
    }
  }
 }
`;

const Information = ({ tab }) => {
  const division = useSelector(getDivisionDataSelector);
  const customer = useSelector(getCustomerDataSelector);
  const location = useSelector(getLocationDataSelector);
  const browser = useSelector((state) => state.browser);

  let dividerStyles = [];
  if (browser.greaterThan.medium) {
    dividerStyles = [
      { borderRight: "1px solid var(--color-gray)", paddingBottom: "1rem" },
      { borderRight: "1px solid var(--color-gray)", paddingLeft: "0.5rem", paddingBottom: "1rem" },
      { paddingLeft: "0.5rem", paddingBottom: "1rem"},
    ];
  }

  return (
    <div>
      <div style={{ padding: "0 1.25rem", fontSize: "0.9em" }}>
        <Grid container spacing={3} className="mt-0">
          <Grid
            item
            xxs={12}
            md={4}
            style={dividerStyles[0]}
          >
            <IconTextItem icon={<RiUser2Fill />} gutterBottom>
              <Typography
                variant="subtitle2"
                style={{ textTransform: "uppercase" }}
                color="textSecondary"
              >
                Customer
              </Typography>
              <LinkHeader to={customer.self} color="secondary" size="small">
                {customer.title}
              </LinkHeader>
            </IconTextItem>
            <IconTextItem icon={<FaUser />}>
              <Typography
                variant="subtitle2"
                style={{ textTransform: "uppercase" }}
                color="textSecondary"
              >
                Caller
              </Typography>
              <StyledGridContainer container spacing={3}>
                <Grid item xxs={4} className="label">
                  Name:
                </Grid>
                <Grid item xxs={8} style={{wordWrap: "break-word"}}>
                  {division.field_jd_mf_caller}
                </Grid>
                <Grid item xxs={4} className="label">
                  Phone:
                </Grid>
                <Grid item xxs={8} style={{wordWrap: "break-word"}}>
                  {division.field_jd_mf_caller_phone}
                </Grid>
              </StyledGridContainer>
              {/* <div style={{ display: "flex" }}>
                <div style={{ marginRight: "1rem" }}>
                  <Typography variant="body1" align="right">
                    Name:
                  </Typography>
                  <Typography variant="body1" align="right">
                    Phone:
                  </Typography>
                </div>
                <div style={{ flex: 1 }}>
                  <Typography variant="body1">
                    {division.field_jd_mf_caller}
                  </Typography>
                  <Typography variant="body1">
                    {division.field_jd_mf_caller_phone}
                  </Typography>
                </div>
              </div> */}
            </IconTextItem>
          </Grid>
          <Grid
            item
            xxs={12}
            md={4}
            style={dividerStyles[1]}
          >
            <IconTextItem icon={<FaUser />}>
              <Typography
                variant="subtitle2"
                style={{ textTransform: "uppercase" }}
                color="textSecondary"
              >
                Point of Contact
              </Typography>

              <StyledGridContainer container spacing={3}>
                {(customer.field_res_com !== 'residential' && location.title !== customer.title) && (
                  <>
                  <Grid item xxs={4} className="label">
                    Name:
                  </Grid>
                  <Grid item xxs={8} style={{wordWrap: "break-word"}}>
                    {location.title}
                  </Grid>
                  </>
                )}
                <Grid item xxs={4} className="label">
                  Phone:
                </Grid>
                <Grid item xxs={8} style={{wordWrap: "break-word"}}>
                  {location.field_phone}
                </Grid>
                <Grid item xxs={4} className="label">
                  Email:
                </Grid>
                <Grid item xxs={8} style={{wordWrap: "break-word"}}>
                  {location.field_cl_email}
                </Grid>
                {(contactMethods[location.field_pref_contact_method] &&
                  <>
                    <Grid item xxs={4} className="label">
                    Method:
                    </Grid>
                    <Grid item xxs={8} style={{wordWrap: "break-word"}}>
                      {contactMethods[location.field_pref_contact_method]}
                    </Grid>
                  </>
                )}
                <Grid item xxs={12}>
                  <Typography variant="body1">
                    <LocationJobHistory />
                  </Typography>
                </Grid>
              </StyledGridContainer>
              {/* <div style={{ display: "flex" }}>
                <div style={{ marginRight: "1rem" }}>
                  <Typography variant="body1" align="right">
                    Name:
                  </Typography>
                  <Typography variant="body1" align="right">
                    Phone:
                  </Typography>
                  <Typography variant="body1" align="right">
                    Email:
                  </Typography>
                  {(contactMethods[location.field_pref_contact_method] &&
                    <Typography variant="body1" align="right">
                      Method:
                    </Typography>
                  )}
                </div>
                <div style={{ flex: 1 }}>
                  <LinkHeader to={location.self} color="secondary" size="small">
                    <Typography variant="body1">
                        {location.title}
                    </Typography>
                  </LinkHeader>
                  <Typography variant="body1">
                    {location.field_phone}
                  </Typography>
                  <Typography variant="body1" style={{wordWrap: "break-word"}}>
                    {location.field_cl_email}
                  </Typography>
                  {(contactMethods[location.field_pref_contact_method] &&
                    <Typography variant="body1">
                      {contactMethods[location.field_pref_contact_method]}
                    </Typography>
                  )}
                  <div>
                    <Typography variant="body1">
                      <LocationJobHistory />
                    </Typography>
                  </div>
                </div>
              </div> */}
            </IconTextItem>
          </Grid>
          {location.field_street_address && (
            <Grid item xxs={12} md={4} style={dividerStyles[2]}>
              <IconTextItem icon={<FaRegAddressBook />}>
                <Typography
                  variant="subtitle2"
                  style={{ textTransform: "uppercase" }}
                  color="textSecondary"
                >
                  Incident Address
                </Typography>
                <StyledGridContainer container spacing={3}>
                  <Grid item xxs={4} className="label">
                    Address:
                  </Grid>
                  <Grid item xxs={8} style={{wordWrap: "break-word"}}>
                    {location.field_street_address.address_line1}
                  </Grid>
                  {location.field_street_address.address_line2 && (
                    <>
                      <Grid item xxs={4} className="label">
                        Address 2:
                      </Grid>
                      <Grid item xxs={8} style={{wordWrap: "break-word"}}>
                        {location.field_street_address.address_line2}
                      </Grid>
                    </>
                  )}
                  <Grid item xxs={4} className="label">
                    City:
                  </Grid>
                  <Grid item xxs={8} style={{wordWrap: "break-word"}}>
                    {location.field_street_address.locality}
                  </Grid>
                  <Grid item xxs={4} className="label">
                    State:
                  </Grid>
                  <Grid item xxs={8} style={{wordWrap: "break-word"}}>
                    {location.field_street_address.administrative_area}
                  </Grid>
                  <Grid item xxs={4} className="label">
                    Zip:
                  </Grid>
                  <Grid item xxs={8} style={{wordWrap: "break-word"}}>
                    {location.field_street_address.postal_code}
                  </Grid>
                </StyledGridContainer>

                {/* <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "1rem" }}>
                    <Typography variant="body1" align="right">
                      Address:
                    </Typography>
                    {location.field_street_address.address_line2 && (
                      <Typography variant="body1" align="right">
                        Address 2:
                      </Typography>
                    )}
                    <Typography variant="body1" align="right">
                      City:
                    </Typography>
                    <Typography variant="body1" align="right">
                      State:
                    </Typography>
                    <Typography variant="body1" align="right">
                      Zip Code:
                    </Typography>
                  </div>
                  <div style={{ flex: 1 }}>
                    <Typography variant="body1">
                      {location.field_street_address.address_line1}
                    </Typography>
                    <Typography variant="body1">
                      {location.field_street_address.address_line2}
                    </Typography>
                    <Typography variant="body1">
                      {location.field_street_address.locality}
                    </Typography>
                    <Typography variant="body1">
                      {location.field_street_address.administrative_area}
                    </Typography>
                    <Typography variant="body1">
                      {location.field_street_address.postal_code}
                    </Typography>
                  </div>
                </div> */}
              </IconTextItem>
            </Grid>
          )}
          </Grid>
          </div>
          <div
              style={{
                marginBottom: "2rem",
                background: "var(--color-gray-light)",
                borderTop: "1px solid var(--color-gray)",
                borderBottom: "1px solid var(--color-gray)",
                padding: "1.25rem",
              }}
            >
            <Grid container spacing={3}>
              {browser.lessThan.medium &&
                <Grid item xxs={6}>
                  <JobSource newLine={true}/>
                </Grid>
              }
              {location.field_cl_year_built &&
                <Grid item xxs={6}>
                  <IconTextItem icon={<BsClockHistory />}>
                    <Typography
                      variant="subtitle2"
                      style={{ textTransform: "uppercase" }}
                      color="textSecondary"
                    >
                      Built
                    </Typography>
                    <Typography variant="body1">
                      {location.field_cl_year_built}
                    </Typography>
                  </IconTextItem>
                </Grid>
              }
              <Grid item xxs={6}>
                <IconTextItem icon={<TiInfoOutline />}>
                  <Typography
                    variant="subtitle2"
                    style={{ textTransform: "uppercase" }}
                    color="textSecondary"
                  >
                    Cause
                  </Typography>
                  <Typography variant="body1">
                    {division.field_cause_select !== "other"
                      ? causes[division.field_cause_select]
                      : division.field_cause}
                  </Typography>
                </IconTextItem>
              </Grid>
              <Grid item xxs={6}>
                <IconTextItem icon={<RiTimerLine />}>
                  <Typography
                    variant="subtitle2"
                    style={{ textTransform: "uppercase" }}
                    color="textSecondary"
                  >
                    Runtime
                  </Typography>
                  <Typography variant="body1">{division.field_run_time}</Typography>
                </IconTextItem>
              </Grid>
              <Grid item xxs={6}>
                <IconTextItem icon={<Gi3DStairs />}>
                  <Typography
                    variant="subtitle2"
                    style={{ textTransform: "uppercase" }}
                    color="textSecondary"
                  >
                    Levels
                  </Typography>
                  <Typography variant="body1">
                    {division.field_mf_effected_level_qty}
                  </Typography>
                </IconTextItem>
              </Grid>
              <Grid item xxs={6}>
                <IconTextItem icon={<BiWater />}>
                  <Typography
                    variant="subtitle2"
                    style={{ textTransform: "uppercase" }}
                    color="textSecondary"
                  >
                    Hardwood Wet
                  </Typography>
                  <Typography variant="body1">
                    {division.field_mf_hw_wet ? "Yes" : "No"}
                  </Typography>
                </IconTextItem>
              </Grid>
            </Grid>
          </div>
          <div className="px-5">
            <EditJobDetailNotes
              entity={division}
              nid={division.nid}
              field="field_details"
            />
          </div>
          {/* {division.field_details && (
            <Grid item xxs={12}>
              <IconTextItem icon={<CgNotes />}>
                <Typography
                  variant="subtitle2"
                  style={{ textTransform: "uppercase" }}
                  color="textSecondary"
                >
                  Details
                </Typography>
                <Typography variant="body1" className="whitespace-pre-wrap">
                  {division.field_details}
                </Typography>
              </IconTextItem>
            </Grid>
          )} */}
      </div>
  );
};

// const Insurance = ({ tab }) => {
//   const insurance = useSelector(getInsuranceDataSelector);

//   return insurance.nid ? (
//     <div>
//       <div
//         style={{
//           marginBottom: "2rem",
//           background: "var(--color-gray-light)",
//           borderBottom: "1px solid var(--color-gray-medium)",
//           padding: "1.25rem",
//         }}
//       >
//         <Grid container spacing={3}>
//           <Grid item xxs={12} md={4}>
//             <IconTextItem icon={<RiInformationLine />}>
//               <Typography
//                 variant="subtitle2"
//                 style={{ textTransform: "uppercase" }}
//                 color="textSecondary"
//               >
//                 Claim
//               </Typography>
//               <Typography variant="body1">
//                 {insurance.field_jdi_claim_number}
//               </Typography>
//             </IconTextItem>
//           </Grid>
//           <Grid item xxs={12} md={4}>
//             <IconTextItem icon={<RiInformationLine />}>
//               <Typography
//                 variant="subtitle2"
//                 style={{ textTransform: "uppercase" }}
//                 color="textSecondary"
//               >
//                 Policy
//               </Typography>
//               <Typography variant="body1">
//                   {insurance.field_jdi_policy_number}
//               </Typography>
//             </IconTextItem>
//           </Grid>
//           <Grid item xxs={12} md={4}>
//             <IconTextItem icon={<RiMoneyDollarCircleLine />}>
//               <Typography
//                 variant="subtitle2"
//                 style={{ textTransform: "uppercase" }}
//                 color="textSecondary"
//               >
//                 Deductible
//               </Typography>
//               <Typography variant="body1">
//                 {formatMoney(insurance.field_jdi_deductible)}
//               </Typography>
//             </IconTextItem>
//           </Grid>
//         </Grid>
//       </div>
//       <div style={{ padding: "1.25rem" }}>
//         <Grid container spacing={3}>
//           <Grid
//             item
//             xxs={12}
//             md={4}
//             style={{ borderRight: "1px solid var(--color-gray-lighter)" }}
//           >
//             <IconTextItem icon={<FaUserTie />}>
//               <Typography
//                 variant="subtitle2"
//                 style={{ textTransform: "uppercase" }}
//                 color="textSecondary"
//               >
//                 Adjustor
//               </Typography>
//               <div style={{ display: "flex" }}>
//                 <div style={{ marginRight: "1rem" }}>
//                   <Typography variant="body1" align="right">
//                     Name:
//                   </Typography>
//                   <Typography variant="body1" align="right">
//                     Phone:
//                   </Typography>
//                   <Typography variant="body1" align="right">
//                     Email:
//                   </Typography>
//                   <Typography variant="body1" align="right">
//                     Fax:
//                   </Typography>
//                 </div>
//                 <div style={{ flex: 1 }}>
//                   <Typography variant="body1">
//                     {insurance.field_jdi_adjuster_name}
//                   </Typography>
//                   <Typography variant="body1">
//                     {insurance.field_jdi_adjuster_phone}
//                   </Typography>
//                   <Typography variant="body1">
//                     {insurance.field_jdi_adjuster_email}
//                   </Typography>
//                   <Typography variant="body1">
//                     {insurance.field_jdi_adjuster_fax}
//                   </Typography>
//                 </div>
//               </div>
//             </IconTextItem>
//           </Grid>
//           <Grid
//             item
//             xxs={12}
//             md={4}
//             style={{ borderRight: "1px solid var(--color-gray-lighter)" }}
//           >
//             <IconTextItem icon={<FaUserTie />}>
//               <Typography
//                 variant="subtitle2"
//                 style={{ textTransform: "uppercase" }}
//                 color="textSecondary"
//               >
//                 Agent
//               </Typography>
//               <div style={{ display: "flex" }}>
//                 <div style={{ marginRight: "1rem" }}>
//                   <Typography variant="body1" align="right">
//                     Name:
//                   </Typography>
//                   <Typography variant="body1" align="right">
//                     Phone:
//                   </Typography>
//                   <Typography variant="body1" align="right">
//                     Email:
//                   </Typography>
//                   <Typography variant="body1" align="right">
//                     Fax:
//                   </Typography>
//                 </div>
//                 <div style={{ flex: 1 }}>
//                   <Typography variant="body1">
//                     {insurance.field_jdi_agent_name}
//                   </Typography>
//                   <Typography variant="body1">
//                     {insurance.field_jdi_agent_phone}
//                   </Typography>
//                   <Typography variant="body1">
//                     {insurance.field_jdi_agent_email}
//                   </Typography>
//                   <Typography variant="body1">
//                     {insurance.field_jdi_agent_fax}
//                   </Typography>
//                 </div>
//               </div>
//             </IconTextItem>
//           </Grid>
//           <Grid item xxs={12} md={4}>
//             <IconTextItem icon={<BiCheckShield />} gutterBottom>
//               <Typography
//                 variant="subtitle2"
//                 style={{ textTransform: "uppercase" }}
//                 color="textSecondary"
//               >
//                 Carrier
//               </Typography>
//               <div>
//                 <Typography variant="body1">
//                   {insurance.field_jdi_carrier}
//                 </Typography>
//               </div>
//             </IconTextItem>
//           </Grid>
//         </Grid>
//       </div>
//     </div>
//   ) : (
//     <Empty
//       message="No Insurance has been added"
//       action={
//         <AddInsurance>
//           <Button
//             color="tertiary"
//             size="small"
//             variant="contained"
//             disableElevation
//             style={{ marginTop: "2rem" }}
//           >
//             Add Insurance
//           </Button>
//         </AddInsurance>
//       }
//     >
//       <BsShieldShaded />
//     </Empty>
//   );
// };

const CustomerInfo = (props) => {
  const browser = useSelector((state) => state.browser);
  return (
    <>
      <Card>
        <CardHeader
          title="Information"
          rightActions={browser.greaterThan.medium && <JobSource />}
        />
        <Information />
      </Card>
      <CustomerCommunication job={props.job} />
      {/* <Card>
        <CardHeader title="Insurance" />
        <Insurance />
      </Card> */}
    </>
  );
};

CustomerInfo.propTypes = {};

export default CustomerInfo;
