import React, { useState, useEffect } from "react";
import { TextField, DialogContent, DialogActions } from "@mui/material";
import getFragmentFromSelection from "draft-js/lib/getFragmentFromSelection";
import { SelectionState, Modifier, RichUtils, EditorState } from "draft-js";
import linkifyIt from "linkify-it";
import tlds from "tlds";

import Button from "common/Button";
import Dialog from "../../../../../components/Dialog";
import { size } from "lodash";

const linkify = linkifyIt();
linkify.tlds(tlds);

const LinkForm = ({ open, onClose, store }) => {
  const { getEditorState, setEditorState } = store;
  const [formData, setFormData] = useState({
    text: "",
    url: "",
  });

  useEffect(() => {
    if (open) {
      const editorState = getEditorState();
      const selected = getFragmentFromSelection(editorState);
      const defaultText = selected
        ? selected.map((x) => x.getText()).join("\n")
        : "";
      setFormData((f) => ({ ...f, text: defaultText }));
    }
  }, [getEditorState, open]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    const { url, text } = formData;
    if (!url || !text) return;
    const editorState = getEditorState();
    const selection = editorState.getSelection();
    const links = linkify.match(url);
    const href = links && links[0] ? links[0].url : "";

    const content = Modifier.replaceText(
      editorState.getCurrentContent(),
      selection,
      text
    );

    const blockKey = selection.getAnchorKey();
    const start = selection.getStartOffset();

    const updateSelection = new SelectionState({
      anchorKey: blockKey,
      anchorOffset: start,
      focusKey: blockKey,
      focusOffset: start + size(text),
      isBackward: false,
    });

    const contentWithEntity = content.createEntity("LINK", "MUTABLE", {
      url: href,
    });
    const newEditorState = EditorState.push(
      editorState,
      contentWithEntity,
      "create-entity"
    );
    const entityKey = contentWithEntity.getLastCreatedEntityKey();
    setEditorState(
      RichUtils.toggleLink(newEditorState, updateSelection, entityKey)
    );
    onClose();
  };

  const handleClose = () => {
    setFormData({ text: "", url: "" });
    onClose();
  };

  return (
    <Dialog open={open} maxWidth="xs" onClose={handleClose} title="Add Link">
      <DialogContent>
        <TextField
          size="small"
          name="text"
          label="Text"
          variant="outlined"
          value={formData.text}
          onChange={handleChange}
          margin="normal"
          fullWidth
        />
        <TextField
          size="small"
          name="url"
          label="URL"
          variant="outlined"
          value={formData.url}
          onChange={handleChange}
          margin="normal"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" size="small" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          size="small"
          onClick={handleSubmit}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

LinkForm.propTypes = {};

export default LinkForm;
