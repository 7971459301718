import chroma from "chroma-js";
import variables from '../styles/base/_variables.scss';

const earlyScale = chroma.scale([variables.colorBlueAlt, variables.colorPurple]).mode("lch").colors(6);

const midScale = chroma.scale([variables.colorBlueAlt2, variables.colorGreen]).mode("lch").colors(6);

const waitScale = chroma.scale([variables.colorYellowBright, variables.colorOrangeBright]).mode("lch").colors(7);

const styles = {
  ...variables,
  borderRadius: "3px",
  pageHeaderHeight: variables.pageHeaderHeight,
  pageHeaderHeight2Line: variables.pageHeaderHeight2Line,
  formStepperHeight: "72px",
  pageSidebarWidth: "200px",
  pageSidebarCollapsedWidth: "50px",
  tablePaginationHeight: "50px",
  siteHeaderZIndex: 6,
  filterMenuDesktopHeight: "100px",
  phoneRegExp:
  /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}(\s*(x|ext)\s*\d+)?$/, // eslint-disable-line
  iso8601: 'YYYY-MM-DD[T]HH:mm:00Z',

  colorStatus: {
    audit: variables.colorRed,
    accepted: variables.colorGreen,
  },
  breakpoints: {
    xxs: variables.breakPointXXS,
    xs: variables.breakPointXS,
    sm: variables.breakPointSM,
    md: variables.breakPointMD,
    lg: variables.breakPointLG,
    xl: variables.breakPointXL,
    xxl: variables.breakPointXXL,
    short: variables.breakPointShort,
    mediumHeight: variables.breakPointMediumHeight,
    tall: variables.breakPointTall,
  },
  statusColors: {
    open: earlyScale[0],
    created: earlyScale[0],
    mf_dispatch: earlyScale[0],
    active: earlyScale[0],
    partial: earlyScale[0],
    request_sent: earlyScale[1],
    accepted: earlyScale[2],
    assigned: earlyScale[3],
    en_route: earlyScale[4],
    arrived: midScale[0],
    in: midScale[0],
    inspection: midScale[1],
    adjust: waitScale[0],
    wait_approval: waitScale[0],
    wait_client_approval: waitScale[1],
    scheduled_prod: midScale[4],
    in_progress: waitScale[2],
    work_complete: midScale[2],
    monitoring: waitScale[5],
    review: waitScale[5],
    review_complete: midScale[4],
    qc: waitScale[3],
    ar: waitScale[4],
    approved: midScale[5],
    complete: midScale[5],
    closed: variables.colorBlueDarker,
    draft: variables.colorBlueAlt,
    awaiting_claim: waitScale[5],
    awaiting_po: waitScale[6],
    bid_declined: variables.colorRed,
    invalid: variables.colorRed,
    denied: variables.colorRed,
    archived: variables.colorBlack,
    warning: waitScale[0],
    black: variables.colorBlueDark,
    out: waitScale[6],
    suspended: variables.colorRed,
  },
};

export default styles;
