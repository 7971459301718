import action from "../action";
import config from "../../config";

export const getMemberCapabilities = (nid, params) =>
  action(
    "memberCapabilities",
    config.api_url,
    `entity/node/field_member_capabilities/${nid}`,
    params
  );

export const getMemberTerminations = (nid, params) =>
  action(
    "memberTerminations",
    config.api_url,
    `entity/node/field_default_termination/${nid}`,
    params
  );

export const getMemberTerminationDocs = (pid, params) =>
  action(
    "memberTerminationDocs",
    config.api_url,
    `entity/paragraph/field_mdt_documents/${pid}`,
    params
  );

export const getMemberAgreementDocs = (pid, params) =>
  action(
    "memberAgreementDocs",
    config.api_url,
    `entity/node/field_member_agreement_docs/${pid}`,
    params
  );

export const getMemberEntityDetails = (nid, params) =>
  action(
    "memberEntityDetails",
    config.api_url,
    `entity/node/field_member_entity_details/${nid}`,
    params
  );

export const getMemberEmployees = (nid, params) =>
  action(
    "memberEmployees",
    config.api_url,
    `rest/employees/member/${nid}`,
    params
  );

export const getEntityEmployees = (nid, entity, params) =>
  action(
    "employees",
    config.api_url,
    `rest/employees/${entity}/${nid}`,
    params
  );

export const getMemberEmployeeEducation = (pid, params) =>
  action(
    "memberEducation",
    config.api_url,
    `/rest/member/employee/dki-education/${pid}`,
    params
  );

export const getMemberFinancials = (nid, params) =>
  action(
    "memberFinancials",
    config.api_url,
    `entity/node/field_member_financials/${nid}`,
    params
  );

export const getMemberRenewals = (nid, params) =>
  action(
    "memberRenewals",
    config.api_url,
    `rest/member/renewals/${nid}`,
    params
  );

export const getCrmCalls = (nid, params) =>
  action("crmCalls", config.api_url, `rest/crm/calls/${nid}`, params);

export const getCRMTasks = (view, start, end, omit = 0, params) =>
  action(
    "tasks",
    config.api_url,
    `rest/tasks/${view}/${start}/${end}/${omit}`,
    params
  );

export const getMemberTerritories = (nid, params, dki) =>
  action(
    "memberTerritories",
    config.api_url,
    `rest/member/territories/${nid}${dki ? '/1' : ''}`,
    params
  );

export const getCRMEmails = (nid, params) =>
  action("crmEmails", config.api_url, `rest/email/listing/${nid}`, params);

export const getMemberGenDocuments = (nid, tid, params) =>
  action(
    "memberGeneralDocuments",
    config.api_url,
    `rest/member/ops/general-docs/${nid}${tid ? `/${tid}` : ""}`,
    params
  );
