import React, { useState } from "react";
import { Tune } from "@mui/icons-material";
import { Grid, Typography, InputAdornment } from "@mui/material";
import styled from "styled-components";

import Dialog from "common/Dialog";
import { CheckboxFormField } from "common/Fields";
import { TextFormField } from "common/Fields";
import Button from "common/Button";

const Styles = styled.div`
  padding: 1.25rem;
`;

const Overrides = ({
  setFieldValue,
  setTotalHours,
  isSubmitting,
  values,
  memberHours,
  userHours,
  date,
  nid,
  rates,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  if (values.entry !== "hourly") return null;
  return (
    <>
      <Button
        color="tertiary"
        variant="contained"
        disableElevation
        startIcon={<Tune />}
        size="small"
        onClick={() => setOpen(true)}
        style={{ marginRight: "1rem" }}
        {...props}
      >
        Overrides
      </Button>
      <Dialog
        title="Overrides"
        maxWidth="xs"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Styles>
          <Typography style={{ marginTop: "1rem" }} variant="subtitle1">
            Labor
          </Typography>
          <Grid container spacing={3}>
            <Grid item xxs={6}>
              <TextFormField
                fullWidth
                htmlFor="service-billable"
                name="service-billable"
                label="Billable (HRS)"
                margin="normal"
                disabled={isSubmitting || !values["billable-service-override"]}
                labelwidth={70}
                shrink
              />
              <CheckboxFormField
                name="billable-service-override"
                label="Override"
                onChange={(e) => {
                  setFieldValue("billable-service-override", e.target.checked);
                  if (!e.target.checked) {
                    setTotalHours(
                      date,
                      values.start,
                      values.end,
                      values.breakstart,
                      values.breakend,
                      memberHours,
                      setFieldValue,
                      {
                        ...values,
                        "billable-service-override": e.target.checked,
                      },
                      userHours,
                      nid
                    );
                  }
                }}
              />
            </Grid>
            <Grid item xxs={6}>
              <TextFormField
                fullWidth
                htmlFor="service-rate"
                name="service-rate"
                label="Rate"
                margin="normal"
                disabled={isSubmitting || !values["rate-service-override"]}
                labelwidth={70}
                shrink
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
              <CheckboxFormField
                name="rate-service-override"
                label="Override"
                onChange={(e) => {
                  setFieldValue("rate-service-override", e.target.checked);
                  if (rates && !e.target.checked) {
                    for (let key in rates) {
                      if (rates[key].name === values.type) {
                        setFieldValue("service-rate", rates[key].s);
                      }
                    }
                  }
                }}
              />
            </Grid>
          </Grid>
          <Typography style={{ marginTop: "1rem" }} variant="subtitle1">
            Travel
          </Typography>
          <Grid container spacing={3}>
            <Grid item xxs={6}>
              <TextFormField
                fullWidth
                htmlFor="travel-billable"
                name="travel-billable"
                label="Billable (HRS)"
                margin="normal"
                disabled={isSubmitting || !values["billable-travel-override"]}
                shrink
                labelwidth={70}
              />
              <CheckboxFormField
                name="billable-travel-override"
                label="Override"
                onChange={(e) => {
                  setFieldValue("billable-travel-override", e.target.checked);
                  if (!e.target.checked) {
                    setTotalHours(
                      date,
                      values.start,
                      values.end,
                      values.breakstart,
                      values.breakend,
                      memberHours,
                      setFieldValue,
                      {
                        ...values,
                        "billable-travel-override": e.target.checked,
                      },
                      userHours,
                      nid
                    );
                  }
                }}
              />
            </Grid>
            <Grid item xxs={6}>
              <TextFormField
                fullWidth
                htmlFor="travel-rate"
                name="travel-rate"
                label="Rate"
                margin="normal"
                disabled={isSubmitting || !values["rate-travel-override"]}
                labelwidth={70}
                shrink
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
              <CheckboxFormField
                name="rate-travel-override"
                label="Override"
                onChange={(e) => {
                  setFieldValue("rate-travel-override", e.target.checked);
                  if (rates && !e.target.checked) {
                    for (let key in rates) {
                      if (rates[key].name === values.type) {
                        setFieldValue("travel-rate", rates[key].t);
                      }
                    }
                  }
                }}
              />
            </Grid>
          </Grid>
          <Typography style={{ marginTop: "1rem" }} variant="subtitle1">
            Overtime
          </Typography>
          <Grid container spacing={3}>
            <Grid item xxs={6}>
              <TextFormField
                fullWidth
                htmlFor="overtime-billable"
                name="overtime-billable"
                label="Billable (HRS)"
                margin="normal"
                disabled={isSubmitting || !values["billable-overtime-override"]}
                shrink
                labelwidth={70}
              />
              <CheckboxFormField
                name="billable-overtime-override"
                label="Override"
                onChange={(e) => {
                  setFieldValue("billable-overtime-override", e.target.checked);
                  if (!e.target.checked) {
                    setTotalHours(
                      date,
                      values.start,
                      values.end,
                      values.breakstart,
                      values.breakend,
                      memberHours,
                      setFieldValue,
                      {
                        ...values,
                        "billable-overtime-override": e.target.checked,
                      },
                      userHours,
                      nid
                    );
                  }
                }}
              />
            </Grid>
            <Grid item xxs={6}>
              <TextFormField
                fullWidth
                htmlFor="overtime-rate"
                name="overtime-rate"
                label="Rate"
                margin="normal"
                disabled={isSubmitting || !values["rate-overtime-override"]}
                labelwidth={70}
                shrink
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
              <CheckboxFormField
                name="rate-overtime-override"
                label="Override"
                onChange={(e) => {
                  setFieldValue("rate-overtime-override", e.target.checked);
                  if (rates && !e.target.checked) {
                    for (let key in rates) {
                      if (rates[key].name === values.type) {
                        setFieldValue("overtime-rate", rates[key].o);
                      }
                    }
                  }
                }}
              />
            </Grid>
          </Grid>
          <Typography style={{ marginTop: "1rem" }} variant="subtitle1">
            After Hours
          </Typography>
          <Grid container spacing={3}>
            <Grid item xxs={6}>
              <TextFormField
                fullWidth
                htmlFor="after-billable"
                name="after-billable"
                label="Billable (HRS)"
                margin="normal"
                disabled={isSubmitting || !values["billable-after-override"]}
                shrink
                labelwidth={70}
              />
              <CheckboxFormField
                name="billable-after-override"
                label="Override"
                onChange={(e) => {
                  setFieldValue("billable-after-override", e.target.checked);
                  if (!e.target.checked) {
                    setTotalHours(
                      date,
                      values.start,
                      values.end,
                      values.breakstart,
                      values.breakend,
                      memberHours,
                      setFieldValue,
                      {
                        ...values,
                        "billable-after-override": e.target.checked,
                      },
                      userHours,
                      nid
                    );
                  }
                }}
              />
            </Grid>
            <Grid item xxs={6}>
              <TextFormField
                fullWidth
                htmlFor="after-rate"
                name="after-rate"
                label="Rate"
                margin="normal"
                disabled={isSubmitting || !values["rate-after-override"]}
                shrink
                labelwidth={70}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
              <CheckboxFormField
                name="rate-after-override"
                label="Override"
                onChange={(e) => {
                  setFieldValue("rate-after-override", e.target.checked);
                  if (rates && !e.target.checked) {
                    for (let key in rates) {
                      if (rates[key].name === values.type) {
                        setFieldValue("after-rate", rates[key].e);
                      }
                    }
                  }
                }}
              />
            </Grid>
          </Grid>
        </Styles>
      </Dialog>
    </>
  );
};

Overrides.propTypes = {};

export default Overrides;
