import React from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import PunchClockIcon from '@mui/icons-material/PunchClock';
import { FiCoffee } from "react-icons/fi";
import { BsCalendar4Event, BsClock, BsPencilSquare } from "react-icons/bs";
import moment from "moment-timezone";
import Alert from "common/Alert";
import { DateFormField, TimeFormField } from "common/Fields";
import ButtonLoader from "common/ButtonLoader";
import { FieldArray } from "formik";
import { size, last } from "lodash";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { parseHours } from "../utils";
import { Camera } from "common/Camera";
import { checkDiffDate, getParamTime, getTimeLocation } from "../utils";
import constants from "components/constants";
import { FiSend } from "react-icons/fi";
import { formatSecondsToDaysHoursMinutes } from "utility/format";
import { ImEnlarge2 } from "react-icons/im";
import { TbWalk } from "react-icons/tb";
import { Lightroom } from "components/Lightroom";
import ConfirmationDialog from "components/ConfirmationDialog";

// const running = `${process.env.PUBLIC_URL}/images/icons/icon-animated-clock.gif`;

const TimeClockTimer = ({timeClock, isSubmitting, setFieldValue, submitForm, message, values, setMessage, errors, validateForm}) => {
  const [open, setOpen] = React.useState(false);
  const [startTime, setStartTime] = React.useState(null);
  const [endTime, setEndTime] = React.useState(null);
  const [earlyClockOut, setEarlyClockOut] = React.useState(null);
  const [cameraSettings, setCameraSettings] = React.useState({
    open: false,
    fieldName: '',
    title: '',
  });

  if(!timeClock?.clock_status){
    return;
  }

  const dateDiff = checkDiffDate(values);

  const checkEarlyClockOut = (values) => {
    const startTime = getParamTime(values, "service_start");
    const endTime = moment();
    const duration = moment.duration(endTime.diff(startTime));
    const hours = duration.hours();
    if(hours < 7){
      if(hours < 1){
        return 'after less than an hour';
      }
      return `after ${hours} hours`;
    }
    const dateAt5PM = moment(`${moment(values?.service_date).format('YYYY-MM-DD')} 4:45 PM`).unix()
    if(moment(endTime).unix() < dateAt5PM){
      return 'before 5:00pm';
    }
    return false;
  }

  const clockIn = () => {
    if(!values.clock_in_photo){
      validateForm().then((_errors) => {
        if(!size(_errors)){
          setStartTime(moment().format(constants.iso8601));
          setCameraSettings({
            open: true,
            fieldName: 'clock_in_photo',
            title: "Clock-In Photo",
          });
        }
      });
    }
    else{
      setCameraSettings({
        ...cameraSettings,
        open: false,
      });
      if(startTime){
        setFieldValue('service_start', moment(startTime));
      }
      else{
        setFieldValue('service_start', moment());
      }
      submitForm('clock_in');
    }
  }

  const clockOut = () => {
    if(!values.clock_out_photo){
      if(!values.service_end || values.service_end === ''){
        // let lastTime = values.service_start;
        // values.service_breaks.forEach((breakTime, i) => {
        //   lastTime = breakTime.start;
        //   if(breakTime.end && breakTime.end !== ''){
        //     lastTime = breakTime.end;
        //   }
        // })
        const _time = getParamTime(values, "service_end");
        setEndTime(_time);
      }
      setCameraSettings({
        open: true,
        fieldName: 'clock_out_photo',
        title: "Clock-Out Photo",
      });
    }
    else{
      setCameraSettings({
        ...cameraSettings,
        open: false,
      });
      if(!values.service_end || values.service_end === ''){
        if(endTime){
          setFieldValue('service_end', moment(endTime));
        }
        else{
          setFieldValue('service_end', moment());
        }
      }
      submitForm('clock_out');
    }
  }

  const onCameraError = (err) => {
    setCameraSettings({
      ...cameraSettings,
      open: false,
    });
    if(timeClock.require_photo){
      setMessage({
        id: 'negative',
        msg: <>A camera photo is required to submit a time card.<br />
            Please check you device's camera permissions and try again,<br />
            or contact your administrator.</>,
      })
    }
    else{
      const dir = cameraSettings.fieldName === "clock_in_photo" ? 'clock_in' : 'clock_out';
      setFieldValue('log_entries', [
        ...values.log_entries,
        'Unable to get ' + cameraSettings.fieldName.replace(/_/g, ' ') + ' - ' + err.message,
      ]);
      if(dir === 'clock_in'){
        setFieldValue('service_start', startTime);
      }
      else{
        setFieldValue('service_end', endTime);
      }
      submitForm(dir);
    }
  }

  return (
    <>
    <Camera
      open={cameraSettings.open}
      fieldName={cameraSettings.fieldName}
      setFieldValue={setFieldValue}
      title={cameraSettings.title}
      onSubmit={() => {
        if(cameraSettings.fieldName === "clock_in_photo"){
          clockIn();
        }
        else{
          clockOut();
        }
      }}
      onError={onCameraError}
      onClose={() => {
        setCameraSettings({
          ...cameraSettings,
          open: false,
        });
        setStartTime(null);
        setEndTime(null);
      }}
      omitOpenButton
      submitButton={
        <><FiSend className="icon" /> Submit</>
      }
    />
    <div className="timer">
      <>
      <div className="buttons-wrapper">
      {timeClock.clock_status !== 'clocked_out' ?
        (timeClock?.clock_status === 'no_clock' ? (
          <div className="buttons">
            <div
              className={`large-round-button clock-in ${timeClock?.read_only || isSubmitting ? 'disabled' : ''}`}
              onClick={() => { if(!timeClock?.read_only) clockIn() }}
            >
              <div className="info">
                <PunchClockIcon className="icon" />
                <span>Clock In</span>
              </div>
            </div>
          </div>
        ) : (
          <>
          {/* <img className="clock-icon" src={running} alt="Animating clock" /> */}
          <div className="buttons">
          {timeClock.clock_status === 'on_break' ? (
            <div
              className={`large-round-button break-end ${timeClock?.read_only || isSubmitting ? 'disabled' : ''}`}
              onClick={() => { if(!timeClock?.read_only) submitForm('break_end')}}
            >
              <div className="info">
                <FiCoffee className="icon" />
                <span>End Break</span>
              </div>
            </div>
          ) : (
            <>
            <div
              className={`large-round-button break-start ${timeClock?.read_only || isSubmitting ? 'disabled' : ''}`}
              onClick={() => { if(!timeClock?.read_only) submitForm('break_start')}}
            >
              <div className="info">
                <FiCoffee className="icon" />
                <span>Start Break</span>
              </div>
            </div>
            <div
              className={`large-round-button clock-out ${timeClock?.read_only || isSubmitting ? 'disabled' : ''}`}
              onClick={() => {
                if(!timeClock?.read_only){
                  const check = checkEarlyClockOut(values);
                  if(check){
                    setEarlyClockOut(check);
                  }
                  else{
                    clockOut();
                  }
                }
              }}
            >
              <div className="info">
                <div className="flex"><PunchClockIcon className="icon" /><TbWalk  className="icon -ml-1" /></div>
                <span>End Shift</span>
              </div>
            </div>
            </>
          )}
          </div>
          </>
        )
      ) : (
        (Boolean(timeClock?.service_is_time_card) && timeClock.service_status === 'complete') &&
          <div className="buttons">
            <Typography variant="h6" className="text-green-alt">Time Card Complete</Typography>
          </div>
      )}
      </div>
      <div className="time-info-header">
        <span><BsCalendar4Event className="edit-icon medium" />
          {values.service_date.format('MM/DD/YY')}
          {Boolean(dateDiff) &&
            ` - ${dateDiff.format('MM/DD/YY')}`
          }
        </span>
        {!timeClock.read_only && timeClock.service_status !== 'complete' &&
          <div className="update-times" onClick={() => setOpen(true)}>
            <BsPencilSquare className="edit-icon medium" />
            <span className="text">{`${timeClock?.clock_status === 'no_clock' ? 'Set' : 'Update' } Date & Times`}</span>
          </div>
        }
      </div>
      <TimeInfo timeClock={timeClock} />
      </>
    </div>
    <HoursInfo timeClock={timeClock} />

    <ConfirmationDialog
      open={Boolean(earlyClockOut)}
      onClose={() => setEarlyClockOut(false)}
      onSubmit={() => {setEarlyClockOut(false); clockOut(); }}
      title="Are you sure?"
      body={`Are you sure you want end your shift ${earlyClockOut}?`}
      submitButton="End Shift"
    />
    <Dialog
      open={open}
      // onClose={onClose}
      aria-labelledby="time-clock-times-update-dialog-title"
      aria-describedby="time-clock-times-update-dialog-description"
      fullWidth
      maxWidth="xs"
      className="time-clock dialog"
    >
      <DialogTitle id="time-clock-times-update-dialog-title" className="dialog-title"><BsClock className="edit-icon large" /> Update Times</DialogTitle>
      <DialogContent>
        {message && <Alert kind={message.id}>{message.msg}</Alert>}
        <div id="time-clock-times-update-dialog-description">
          <DateFormField
            fullWidth
            disableToolbar
            variant="inline"
            format="MM/DD/YYYY"
            id="service_date"
            label="Date"
            name="service_date"
            inputVariant="filled"
            size="small"
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            disabled={isSubmitting}
            required
          />
          <TimeFormField
            fullWidth
            disableToolbar
            variant="inline"
            id="service_start"
            label="Clock In"
            name="service_start"
            inputVariant="filled"
            size="small"
            KeyboardButtonProps={{
              "aria-label": "Set Clock-In Time",
            }}
            disabled={isSubmitting}
            nullable
          />
          <div className="service-breaks">
            <FieldArray
              name="service_breaks"
              render={(arrayHelpers) => (
                <>
                {values.service_breaks && values.service_breaks.map((breakTimes, i) => (
                  <div className="break-wrapper" key={`break_time${i}`}>
                    <div className="label">Break</div>
                    <div className="break-times">
                      <TimeFormField
                        fullWidth
                        disableToolbar
                        variant="inline"
                        htmlFor={`service_breaks.${i}.start`}
                        name={`service_breaks.${i}.start`}
                        id={`service_breaks.${i}.start`}
                        label="Break Start"
                        inputVariant="filled"
                        size="small"
                        KeyboardButtonProps={{
                          "aria-label": "Set Break Start Time",
                        }}
                        disabled={isSubmitting}
                        required
                      />
                      <TimeFormField
                        fullWidth
                        disableToolbar
                        variant="inline"
                        htmlFor={`service_breaks.${i}.end`}
                        name={`service_breaks.${i}.end`}
                        id={`service_breaks.${i}.end`}
                        label="Break End"
                        inputVariant="filled"
                        size="small"
                        KeyboardButtonProps={{
                          "aria-label": "Set Break End Time",
                        }}
                        disabled={isSubmitting}
                        nullable
                      />
                      <IconButton
                        onClick={() => arrayHelpers.remove(i)}
                        size="large"
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </div>
                ))}
                <Button
                  component="a"
                  startIcon={<AddIcon />}
                  variant="contained"
                  disableElevation
                  size="small"
                  style={{ marginTop: "1rem", marginBottom: "1rem" }}
                  fullWidth
                  onClick={() =>
                    arrayHelpers.push({
                      hash: '',
                      start: '',
                      end: null,
                    })
                  }
                >
                  Add Break Times
                </Button>
                </>
              )}
            />
          </div>
          <TimeFormField
            fullWidth
            disableToolbar
            variant="inline"
            id="service_end"
            label="Shift End"
            name="service_end"
            inputVariant="filled"
            size="small"
            KeyboardButtonProps={{
              "aria-label": "Set Shift End Time",
            }}
            disabled={isSubmitting}
            nullable
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} disabled={isSubmitting}>
          Cancel
        </Button>
        <ButtonLoader
          variant="contained"
          color="primary"
          type="submit"
          isSubmitting={isSubmitting}
          disabled={isSubmitting}
          disableElevation
          size="small"
          onClick={() => {
            // formRef.current.validateForm();
            if(!size(errors)){
            //  Trigger the camera if setting a clock-out time.
              if(!timeClock.service_end && values.service_end){
                clockOut();
              }
              else{
                submitForm('update_times');
              }
              setOpen(false);
            }
          }}
        >
          Update
        </ButtonLoader>
      </DialogActions>
    </Dialog>
    </>
  );
};

TimeClockTimer.propTypes = {};

export default TimeClockTimer;

export const TimeInfo = ({timeClock, hideIcon, simple}) => {
  const { url } = useRouteMatch();
  const history = useHistory();

  const dateDiff = Boolean(checkDiffDate(timeClock));

  const parseTime = (time, diff) => {
    return (
      <>
      {moment(time).format('h:mm a')}
      {diff && ` (${moment(time).format('dddd')})`}
      </>
    );
  }

  let breakInfo;
  if(simple){
    if(timeClock.clock_status === 'on_break'){
      let lastBreak = last(timeClock.service_breaks);
      breakInfo = <>{`(on break since: `} {parseTime(lastBreak?.start, dateDiff)}{`)`}</>;
    }
    else{
      let breakSeconds = 0;
      timeClock.service_breaks.forEach((brk, i) => {
        if(brk.end){
          breakSeconds += moment.duration(moment(brk.end).diff(brk.start)).asMinutes();
        }
      });
      if(breakSeconds){
        breakInfo = `Break Time: ${formatSecondsToDaysHoursMinutes(breakSeconds * 60)}`;
      }
    }
  }
  if(!timeClock?.clock_status || timeClock.clock_status === 'no_clock'){
    return <></>;
  }

  const photos = [];
  ['in', 'out'].forEach((dir) => {
    const index = `service_clock_${dir}_photo`;
    const timeDir = dir === 'in' ? 'start' : 'end';
    if(timeClock?.[index] && timeClock?.[`service_${timeDir}`]){
      const photo = timeClock?.[index + '_full'];
      const hash = timeClock?.[index + '_hash'];
      photos.push({
        id: hash,
        photo_filename: hash/*photo.slice(photo.lastIndexOf('/') + 1)*/,
        photo_title: `Clock-${dir} Photo`,
        _files: {photo: photo},
      })
    }
  });

  return (
    simple ? (
      <div className="times">
        <span>
          {parseTime(timeClock?.service_start, dateDiff)} - {timeClock.service_end && parseTime(timeClock.service_end, dateDiff)}
        </span>
        <span>{breakInfo}</span>
      </div>
    ) : (
      <div className="time-info">
        {!hideIcon && <BsClock className="edit-icon medium" />}
        <div className="list">
          <div className="item clocked-in">
            <span>Clocked-in: {parseTime(timeClock?.service_start, dateDiff)}
              {getTimeLocation(timeClock, ['clock_in'])}
            </span>
            {timeClock?.service_clock_in_photo &&
              <span
                className="snapshot-thumb-wrapper"
                onClick={() => history.push(`${url}/clock-snapshot/${timeClock.service_clock_in_photo_hash}`)}
                title="Click to Expand"
              >
                <img
                  className="snapshot-thumb"
                  src={timeClock.service_clock_in_photo} alt="Clock-In Snapshot"
                />
                <span className="icon-circle-button"><ImEnlarge2 /></span>
              </span>
            }
          </div>
          {timeClock.service_breaks.map((brk, i) => {
            const breakDiff = brk.end && moment(brk.start).format('YY-MM-DD') !== moment(brk.end).format('YY-MM-DD');
            return (
              <div key={`break_item${i}`} className="item break">
                <span>
                  Break: {
                    <>
                    {parseTime(brk.start, breakDiff)}
                    {` - `}
                    </>
                  }
                  {(brk.end) &&
                    parseTime(brk.end, breakDiff)
                  }
                  {(!breakDiff && dateDiff) && ` (${moment(brk.start).format('dddd')})`}
                  {getTimeLocation(timeClock, ['break_start', 'break_end'], i)}
                </span>
              </div>
            );
          })}
          {timeClock.service_end &&
            <div className="item clocked-in">
              <span>Clocked-out: {parseTime(timeClock.service_end, dateDiff)}
                {getTimeLocation(timeClock, ['clock_out'])}
              </span>
              {timeClock?.service_clock_out_photo &&
                <span
                  className="snapshot-thumb-wrapper"
                  onClick={() => history.push(`${url}/clock-snapshot/${timeClock.service_clock_out_photo_hash}`)}
                  title="Click to Expand"
                >
                  <img
                    className="snapshot-thumb"
                    src={timeClock.service_clock_out_photo} alt="Clock-Out Snapshot"
                  />
                  <span className="icon-circle-button"><ImEnlarge2 /></span>
                 </span>
              }
            </div>
          }
        </div>
        {size(photos) > 0 &&
          <Lightroom
            route={`clock-snapshot/:id`}
            path={`clock-snapshot`}
            url={url}
            photos={photos}
            ids={photos.map((photo) => photo.id)}
          />
        }
      </div>
    )
  );
};

export const HoursInfo = ({timeClock,}) => {

  let qty = 1;
  const showStandard = Number(timeClock.service_hours_standard) > 0
                      && (Number(timeClock.service_hours_after_hours) > 0
                          || Number(timeClock.service_hours_overtime) > 0
                          || Number(timeClock.service_hours_travel) > 0);
  const showAfterHours = Number(timeClock.service_hours_after_hours) > 0;
  const showOvertime = Number(timeClock.service_hours_overtime) > 0;
  const showTravel = Number(timeClock.service_hours_travel) > 0;
  if(showStandard){
    qty++;
  }
  if(showAfterHours){
    qty++;
  }
  if(showOvertime){
    qty++;
  }
  if(showTravel){
    qty++;
  }

  return (
    (timeClock.clock_status === 'clocked_out' && timeClock?.service_hours_total) &&
      <div className={`time-hour-info ${qty > 2 ? 'use-heading' : ''}`}>
        <div><label>Hours: </label><span>{parseHours(timeClock.service_hours_total)}</span></div>
        {showStandard ? (
          <div><label>Standard: </label><span>{parseHours(timeClock.service_hours_standard)}</span></div>
        ) : (<></>)}
        {showAfterHours ? (
          <div><label>After-Hours: </label><span>{parseHours(timeClock.service_hours_after_hours)}</span></div>
        ) : (<></>)}
        {showOvertime ? (
          <div><label>Overtime: </label><span>{parseHours(timeClock.service_hours_overtime)}</span></div>
        ) : (<></>)}
        {showTravel ? (
          <div><label>Travel: </label><span>{parseHours(timeClock.service_hours_travel)}</span></div>
        ) : (<></>)}
      </div>
  );
};

export const timeClockValidateTimer = (errors, timeClock, values, setSubMessage, setFieldError) => {
  let startTime = null;
  let endTime = null;

  if(!values?.service_date || values?.service_date === ''){
    errors.service_date = 'Please enter a date.';
    setSubMessage({
      id: "negative",
      msg: errors.service_date,
    });
  }
  else {
    if(values?.service_start && values.service_start !== ''){
      startTime = getParamTime(values, "service_start");
      values.service_start = startTime; // this helps w/ validation
    //  Make sure it's a valid date
      if(startTime === 'Invalid date'){
        errors.service_start = 'Invalid Start Time.';
        setSubMessage({
          id: "negative",
          msg: errors.service_start,
        });
      }
    }
    if(values?.service_end && values.service_end !== ''){
    //  Make sure start time is set if end time is set
      if(!startTime){
        errors.service_start = 'Enter a Start Time.';
        setSubMessage({
          id: "negative",
          msg: errors.service_start,
        });
      }
      else{
        endTime = getParamTime(values, "service_end");
        values.service_end = endTime; // this helps w/ validation
      //  Make sure it's a valid date
        if(endTime === 'Invalid date'){
          errors.service_end = 'Invalid End Time.';
          setSubMessage({
            id: "negative",
            msg: errors.service_end,
          });
        }
      //  Make sure end time is after the start time
        else if(moment(startTime).unix() >= moment(endTime).unix()){
          errors.service_end = 'Start time must be before the end time.';
          setSubMessage({
            id: "negative",
            msg: errors.service_end,
          });
        }
      }
    }
    if(values?.service_breaks){
      let serviceBreaks = [...values.service_breaks];
      serviceBreaks.forEach((breakTime, i) => {
      //  Make sure start time is set if any break times are set
        if(!startTime && !errors?.service_start){
          errors.service_start = 'Enter a Start Time.';
          setSubMessage({
            id: "negative",
            msg: errors.service_start,
          });
        }
        else{
          const _startTime = getParamTime(values, "service_break_start", i);
          values.service_breaks[i] = {...values.service_breaks[i], start: _startTime}; // this helps w/ validation
          const _endTime = (breakTime.end ? getParamTime(values, "service_break_end", i) : null);
          values.service_breaks[i] = {...values.service_breaks[i], end: _endTime}; // this helps w/ validation
        //  Make sure break end time is after the start time
          if(moment(_startTime).unix() >= moment(_endTime).unix()){
            errors.service_breaks = 'Break start time must be before the break end time.';
            setSubMessage({
              id: "negative",
              msg: errors.service_breaks,
            });
          }
        //  Make sure the break times are inside the start and end times
          else if((startTime !== 'Invalid date' && moment(_startTime).unix() <= moment(startTime).unix())
            || (endTime !== 'Invalid date' && !_endTime && moment(_startTime).unix() >= moment(endTime).unix())
            || (endTime !== 'Invalid date' && _endTime && moment(_endTime).unix() >= moment(endTime).unix())){
            errors.service_breaks = 'Break times must be within time card times';
            setSubMessage({
              id: "negative",
              msg: errors.service_breaks,
            });
          }
        }
      });
    }
  }
  return errors;
}
