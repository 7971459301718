import React from "react";
import { CSSTransition } from "react-transition-group";
import Snapshot from "../Snapshot";
import {
  MemberSnapshot,
  JobSnapshot,
  CustomerSnapshot,
  LocationSnapshot,
} from "../Snapshots";

const getSnapshot = (snapshot) => {
  switch (snapshot.bundle) {
    case "member":
    case "affiliate":
    case "parent":
    case "child":
    case "other":
      return <MemberSnapshot title={snapshot.title} nid={snapshot.nid} />;
    case "division":
      return <JobSnapshot title={snapshot.title} nid={snapshot.nid} />;
    case "customer":
      return <CustomerSnapshot title={snapshot.title} nid={snapshot.nid} />;
    case "customer_location":
      return <LocationSnapshot title={snapshot.title} nid={snapshot.nid} />;
    default:
      return <div></div>;
  }
};

const MapSnapshot = ({ snapshotOpen, snapshot, closeSnapshot }) => {
  return (
    <CSSTransition
      timeout={500}
      classNames="slide-up"
      unmountOnExit
      in={snapshotOpen}
    >
      <Snapshot
        onClose={closeSnapshot}
        title={snapshot.title}
        status={snapshot.status}
        open={snapshotOpen}
      >
        {getSnapshot(snapshot)}
      </Snapshot>
    </CSSTransition>
  );
};

MapSnapshot.propTypes = {};

export default MapSnapshot;
