import React, { useState } from "react";
import { IconButton, Popover, Tooltip } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import "./PageHeader.scss";

const PageHeaderNav = ({ children, media }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const toggleMenuClose = () => {
    setAnchorEl(null);
  };

  return media ? (
    <React.Fragment>
      <Tooltip title="Mobile Navigation">
        <IconButton onClick={handleClick} size="large">
          <MoreHorizIcon />
        </IconButton>
      </Tooltip>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={toggleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {children}
      </Popover>
    </React.Fragment>
  ) : (
    <nav className="page-header__nav-wrapper">{children}</nav>
  );
};

export default PageHeaderNav;
