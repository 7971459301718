import styled from "styled-components";

export const Wrapper = styled.main`
  height: calc((var(--vh, 1vh) * 100) - var(--page-header-height));
  width: 100%;
  overflow: auto;
  display: flex;
  position: relative;

  @media print{
    height: auto;
    min-height: 0;
  }
`;
