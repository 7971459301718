import React, { useEffect } from "react";
import { useSelector, connect } from "react-redux";
import Helmet from "react-helmet";
import { Typography, TextField, Grid } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import makeStyles from "@mui/styles/makeStyles";
import { GetApp } from "@mui/icons-material";
import { FilterList } from "@mui/icons-material";
import { Autocomplete } from "@mui/material";
import moment from "moment";

import config from "../../config";
import Alert from "common/Alert";
import { downloadFile } from "../../actions/files/fileActions";
import ReportSearch from "./ReportSearch";
import Table, { TableConfigure } from "../Table";
import LinkHeader from "common/LinkHeader";
import {
  getMemberFileCategories,
  exportBatch,
  getReportComplianceDocs,
  clear,
} from "../../actions";
import Paper from "../Paper";
import { formatFilters } from "../../utility";
import { Actions, ActionGroup, FilterMenu, ButtonGroup } from "./Styled";
import ButtonDropdown from "common/ButtonDropdown";
import { memberStatusSelectors } from "../../features/Taxonomies/taxonomiesSlice";
import Button from "common/Button";
import constants from "components/constants";

const useStyles = makeStyles((theme) => ({
  button: {
    background: constants.gradientBlack,
    paddingRight: "10px",
    color: constants.colorWhite,
    "&:hover": {
      background: constants.gradientBlack,
      color: constants.colorWhite,
    },
  },
}));

const formatCategories = (data) => {
  const categories = [];
  if (!data) return categories;
  data.forEach((category) => {
    category.children.forEach((option) => {
      categories.push({
        category: category.name,
        label: option.name,
        value: option.tid,
      });
    });
  });

  return categories;
};

const columnSettings = {
  office_id: { minWidth: 125 },
  member_name: { minWidth: 400 },
  compliance_file_filename: { minWidth: 300 },
  compliance_file_uploaded: { minWidth: 150 },
  compliance_file_uploader_name: { minWidth: 200 },
  compliance_file_category_proper: { minWidth: 200 },
  compliance_file_expiration: { minWidth: 150 },
  status_proper: { minWidth: 150 },
};

const createColumns = (columns) =>
  columns.map((column) => {
    column.id = column.index;
    column = {
      ...column,
      notSortable: !column.sortable,
      ...columnSettings[column.index],
    };
    return column;
  });

const createData = (
  office_id,
  member_name,
  compliance_file_filename,
  compliance_file_uploaded,
  compliance_file_uploader_name,
  compliance_file_category_proper,
  compliance_file_expiration,
  status_proper,
  paths,
  files,
  download
) => {
  return {
    office_id,
    member_name: member_name ? (
      <LinkHeader to={paths.member} size="small" color="default">
        {member_name}
      </LinkHeader>
    ) : null,
    compliance_file_filename: (
      <Typography
        variant="body1"
        style={{ textDecoration: "underline", cursor: "pointer" }}
        onClick={(e) =>
          download(e, files.compliance_file, compliance_file_filename)
        }
      >
        {compliance_file_filename}
      </Typography>
    ),
    compliance_file_uploaded: (
      <Typography variant="body1">
        {compliance_file_uploaded
          ? moment.unix(compliance_file_uploaded).format("MMM Do, YYYY")
          : ""}
      </Typography>
    ),
    compliance_file_uploader_name: compliance_file_uploader_name ? (
      <LinkHeader
        to={paths.compliance_file_uploader}
        size="small"
        color="default"
      >
        {compliance_file_uploader_name}
      </LinkHeader>
    ) : null,
    compliance_file_category_proper,
    compliance_file_expiration: (
      <Typography variant="body1">
        {compliance_file_expiration
          ? moment.unix(compliance_file_expiration).format("MMM Do, YYYY")
          : ""}
      </Typography>
    ),
    status_proper,
  };
};

const initialFormData = {
  compliance_file_category_tid: [],
  compliance_file_expiration: {
    type: "date-range",
    value: { from: null, through: null },
  },
  status: [
    { label: "In Compliance", value: "in" },
    { label: "Partial Hold", value: "partial" },
    { label: "Warning", value: "warning" },
    { label: "Unavailable", value: "black" },
    { label: "Out of compliance", value: "out" },
    { label: "Suspended", value: "suspended" },
  ],
};

const ReportComplianceDocs = ({
  report=[],
  clear,
  pagination={
    count: 0,
    current_page: 0,
    total_pages: 0,
    display_columns: [],
  },
  loading,
  error,
  loadReport,
  exportReport,
  cancelSource,
  getFile,
  loadingCategories,
  categories=[],
  loadFileCategories,
  org,
}) => {
  const classes = useStyles();
  const statuses = useSelector(memberStatusSelectors.selectAll);
  const [hiddenColumns, setHiddenColumns] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filters, setFilters] = React.useState({});
  const [formData, setFormData] = React.useState(initialFormData);

  useEffect(() => {
    loadFileCategories();

    return () => {
      clear();
    };
  }, [clear, loadFileCategories]);

  const handleExportReport = async () => {
    let filterSettings = formatFilters(filters);

    if (org) {
      filterSettings = {
        ...filterSettings,
        "filter[phx_client_nids]": org,
      };
    }

    await exportReport("/rest/report/members/compliance-documents", {
      _export: "csv",
      keywords: searchTerm,
      ...filterSettings,
    });
  };

  const handleDownload = (e, url, name) => {
    e.preventDefault();
    getFile(`${config.api_url}${url}`, name, "blob");
  };

  const columns = React.useMemo(() => {
    if (loading) return [];
    return createColumns(pagination.display_columns);
  }, [pagination, loading]);

  const rows = React.useMemo(() => {
    if (loading) return [];
    return report.map((row) =>
      createData(
        row.office_id,
        row.member_name,
        row.compliance_file_filename,
        row.compliance_file_uploaded,
        row.compliance_file_uploader_name,
        row.compliance_file_category_proper,
        row.compliance_file_expiration,
        row.status_proper,
        row._paths,
        row._files,
        handleDownload
      )
    );
  }, [report, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const hideColumns = React.useMemo(() => {
    if (loading) return [];
    return hiddenColumns;
  }, [hiddenColumns, loading]);

  const handleChange = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateRangeChange = (value, name, index) => {
    const dateValue = formData[name].value;
    dateValue[index] = value;
    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value: { ...dateValue },
      },
    });
  };

  const applyFilters = () => {
    setFilters({ ...formData });
  };

  const resetFilters = () => {
    setFilters({});
    setFormData({
      ...initialFormData,
      compliance_file_expiration: {
        type: "date-range",
        value: { from: null, through: null },
      },
    });
  };

  return (
    <div>
      <Helmet>
        <title>Compliance Docs Report | Helix</title>
        <meta name="description" content="Reports" />
      </Helmet>
      {error && <Alert kind="negative">{error.message}</Alert>}
      <LinkHeader to="/reports" color="default" size="small" back>
        Back to reports
      </LinkHeader>
      <Paper margin="none" style={{ marginTop: "1rem" }}>
        <Actions>
          <Typography variant="h6">Compliance Documents</Typography>
          <ActionGroup>
            <TableConfigure
              columns={columns}
              hideColumns={setHiddenColumns}
              currentColumns={hiddenColumns}
            />
            <ButtonDropdown
              icon={FilterList}
              label="Filters"
              style={{ marginLeft: "1rem" }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              color="tertiary"
            >
              <FilterMenu>
                <Autocomplete
                  options={formatCategories(categories)}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  working={loadingCategories}
                  groupBy={(option) => option.category}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Category"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "compliance_file_category_tid");
                  }}
                  value={formData.compliance_file_category_tid}
                />
                <Typography variant="body1" style={{ marginTop: "0.5rem" }}>
                  Expiration Date
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.compliance_file_expiration.value.from}
                        onChange={(value) =>
                          handleDateRangeChange(
                            value,
                            "compliance_file_expiration",
                            "from"
                          )
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="From"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        InputAdornmentProps={{ size: "small" }}
                        KeyboardButtonProps={{
                          "aria-label": "change from date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={
                          formData.compliance_file_expiration.value.through
                        }
                        onChange={(value) =>
                          handleDateRangeChange(
                            value,
                            "compliance_file_expiration",
                            "through"
                          )
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="Through"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        KeyboardButtonProps={{
                          "aria-label": "change through date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Autocomplete
                  options={statuses.map((status) => ({
                    label: status.label,
                    value: status.machine_name,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Status"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "status");
                  }}
                  value={formData.status}
                />
                <ButtonGroup>
                  <Button disableElevation size="small" onClick={resetFilters}>
                    Reset
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    size="small"
                    style={{ marginRight: "1rem" }}
                    onClick={applyFilters}
                  >
                    Apply
                  </Button>
                </ButtonGroup>
              </FilterMenu>
            </ButtonDropdown>
            <Button
              className={classes.button}
              size="small"
              startIcon={<GetApp />}
              onClick={handleExportReport}
            >
              Export
            </Button>
          </ActionGroup>
        </Actions>
        <ReportSearch setQuery={setSearchTerm} />
        <Table
          clientFilter={"phx_client_nids"}
          columns={columns}
          rows={rows}
          stickyHeader
          loading={loading}
          hideColumns={hideColumns}
          pageRequest={loadReport}
          originalOrderBy="compliance_file_expiration"
          originalOrder="desc"
          search={searchTerm}
          filters={filters}
          pagination={pagination}
          size="small"
          source={cancelSource}
        />
      </Paper>
    </div>
  );
};

ReportComplianceDocs.propTypes = {};

const mapStateToProps = (state) => ({
  loading: state.app.report.loading,
  report: state.app.report.data.data,
  pagination: state.app.report.data.pagination,
  error: state.app.report.error,
  cancelSource: state.app.report.cancel,
  categories: state.app.memberFileCategories.data,
  loadingCategories: state.app.memberFileCategories.loading,
});

const mapDispatchToProps = (dispatch) => ({
  loadReport: (params) => dispatch(getReportComplianceDocs(params)),
  exportReport: (url, params) => dispatch(exportBatch(url, params)),
  loadFileCategories: () => dispatch(getMemberFileCategories()),
  getFile: (path, name, responseType) =>
    dispatch(downloadFile(path, name, responseType)),
  clear: () => dispatch(clear("report")),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportComplianceDocs);
