import React from "react";
import ReactMarkdown from "react-markdown";
// import { reactMarkdownDefaults, reactMarkdownTruncate } from "utility/format";
import {renderToStaticMarkup} from 'react-dom/server';
import TruncateMarkup from 'react-truncate-markup';
import parse from 'html-react-parser';


const TruncateReactMarkdown = ({children, noMarkdown, markDownProps, lines=4, ...props}) => {
  const ellipsis = (!props?.ellipsis ? <span className="inline-block">...</span> : props.ellipsis);
  let content = children;
  if (!noMarkdown) {
    content = parse(renderToStaticMarkup(<ReactMarkdown {...markDownProps} >{children}</ReactMarkdown>));
  }
  return (
    <TruncateMarkup ellipsis={ellipsis} lines={lines} {...props}>
      <div className="truncate-markup">{content}</div>
    </TruncateMarkup>
  );
};

export default TruncateReactMarkdown;
