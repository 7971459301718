import styled from "styled-components";
import constants from "../../components/constants";

export const ChatFieldContainer = styled.div`
  padding: 0 1.25rem 1.25rem 1.25rem;
  background: ${constants.colorGrayLight};
`;

export const ChatBox = styled.div`
  background: ${({ variant }) =>
    variant === "filled" ? "var(--color-gray-lighter)" : "white"};
  border: ${({ theme }) =>
    theme.disableBorder ? "none" : `1px solid ${constants.colorGray}`};
  border-bottom: ${({ variant, theme }) =>
    variant === "filled"
      ? `1px solid ${constants.colorGray}`
      : theme.disableBorder
      ? "none"
      : `1px solid ${constants.colorGray}`};
  box-shadow: ${({ focused }) =>
    focused ? "0 0 12px 0 rgba(0, 0, 0, 0.1)" : "none"};
  border-radius: ${({ theme }) => theme.borderRadius};
  border-bottom-left-radius: ${({ variant, theme }) =>
    variant === "filled" ? "0px" : theme.borderRadius};
  border-bottom-right-radius: ${({ variant, theme }) =>
    variant === "filled" ? "0px" : theme.borderRadius};
  // height: ${({ focused }) => (focused ? "240px" : "104px")};
  max-height: 240px;
  // min-height: 84px;
  position: relative;
`;

export const ImageUploads = styled.div`
  padding: 0.5rem 1rem;
  display: flex;
`;

export const Disabler = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${constants.colorGray};
  opacity: 0.5;
`;
