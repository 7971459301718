import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { size } from "lodash";
import { GoCommentDiscussion } from "react-icons/go";
import { Add } from "@mui/icons-material";

import Comments from "features/Comments";
import { DefaultLoader } from "common/Loader";
import Empty from "common/Empty";
import AddComment from "../forms/AddComment";
import Button from "common/Button";

import {
  commentsSelectors,
  getCommentsLoadingSelector,
  fetchCustomerComments,
  fetchMoreCustomerComments,
} from "features/Comments/commentsSlice";

const Notes = ({ nid, shortList }) => {
  const dispatch = useDispatch();
  const allComments = useSelector(commentsSelectors.selectAll);
  const loading = useSelector(getCommentsLoadingSelector);

  const [page, setPage] = useState(0);

  const handleLoad = useCallback(() => {
    setPage(0);
    dispatch(fetchCustomerComments({ id: nid }));
  }, [dispatch, nid]);

  useEffect(() => {
    let promise;
    if (nid) {
      promise = dispatch(fetchCustomerComments({ id: nid }));
    }

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [dispatch, nid]);

  const handleLoadMore = useCallback(async () => {
    const params = {
      page: page + 1,
    };

    await dispatch(
      fetchMoreCustomerComments({
        id: nid,
        params,
      })
    );
    setPage(page + 1);
  }, [dispatch, nid, page]);

  return (
    <div className={`bg-gray-lighter ${shortList ? 'min-h-[100%]' : '' } p-5`}>
      {loading ? (
        <DefaultLoader />
      ) : (
        <>
        <div style={{ paddingBottom: "1.25rem" }}>
          <AddComment>
            <Button
              variant="contained"
              color="tertiary"
              disableElevation
              size="small"
              // component={Div}
              startIcon={<Add />}
            >
              Add
            </Button>
          </AddComment>
        </div>
        {size(allComments) > 0 ? (
          <Comments
            comments={allComments}
            onLoadMore={handleLoadMore}
            page={page}
            onRefresh={handleLoad}
            descendingOrder
            scrollerHeight={shortList ? 400 : null}
          />
        ) : (
          <Empty message="There are no comments added yet">
            <GoCommentDiscussion />
          </Empty>
        )}
        </>
      )}
    </div>
  );
};

Notes.propTypes = {};

export default Notes;
