export const ROUTES = [
  { alias: "accounting", path: "/reports/accounting", label: "Accounting" },
  { alias: "aging_days", path: "/reports/aging-days", label: "Aging Days" },
  {
    alias: "aging_users",
    path: "/reports/aging-users",
    label: "Aging Users Minutes",
  },
  { alias: "aging_report", path: "/reports/jobs-aging", label: "Aging" },
  // {
  //   alias: "aging_report_span",
  //   path: "/reports/jobs-aging-span",
  //   label: "Aging Span",
  // },
  {
    alias: "compliance_documents",
    path: "/reports/compliance-documents",
    label: "Compliance Documents",
  },
  { alias: "customers", path: "/reports/customers", label: "Customers" },
  {
    alias: "customer_locations",
    path: "/reports/customer-locations",
    label: "Customer Locations",
  },
  {
    alias: "customer_notes",
    path: "/reports/customer-notes",
    label: "Customer Notes",
  },
  {
    alias: "customer_users",
    path: "/reports/customer-users",
    label: "Customer Users",
  },
  {
    alias: "job_divisions",
    path: "/reports/job-divisions",
    label: "Job Divisions",
  },
  { alias: "members", path: "/reports/members", label: "Members" },
  {
    alias: "member_notes",
    path: "/reports/member-notes",
    label: "Member Notes",
  },
  {
    alias: "member_users",
    path: "/reports/member-users",
    label: "Member Users",
  },
  {
    alias: "override_members",
    path: "/reports/override-members",
    label: "Override Report - Member Status",
  },
  {
    alias: "override_duplicate_jobs",
    path: "/reports/override-jobs",
    label: "Override Report - Duplicate Jobs",
  },
  { alias: "hpn_select", path: "/reports/hpn-select", label: "HPN Select" },
  {
    alias: "time_entries",
    path: "/reports/time-entries",
    label: "Time Entries",
  },
  { alias: "wip_detail", path: "/reports/wip-detail", label: "WIP Detail" },
  {
    alias: "wip_detail_parent_customer",
    path: "/reports/wip-detail-parent-customer",
    label: "WIP Detail (Parent Customer)",
  },
  {
    alias: "wip_overview",
    path: "/reports/wip-overview",
    label: "WIP Overview",
  },
  {
    alias: "territories",
    path: "/reports/territories",
    label: "Territories",
  },
  {
    alias: "agreements",
    path: "/reports/agreements",
    label: "Agreements",
  },
  {
    alias: "terminations",
    path: "/reports/terminations",
    label: "Terminations",
  },
  {
    alias: "owners",
    path: "/reports/Owners",
    label: "Owners",
  },
  {
    alias: "employees",
    path: "/reports/employees",
    label: "Employees",
  },
  {
    alias: "addresses",
    path: "/reports/addresses",
    label: "Member Billing Addresses",
  },
  {
    alias: "fulllist",
    path: "/reports/full-listing",
    label: "Member Full Listing",
  },
  {
    alias: "entityname",
    path: "/reports/entity-name",
    label: "Member Entity Name",
  },
  {
    alias: "xactimate",
    path: "/reports/xactimate",
    label: "Xactimate Bulk Buy Program",
  },
  {
    alias: "member-coverage",
    path: "/reports/member/coverage",
    label: "Member Coverage",
  },
  {
    alias: "member-coverage-gaps",
    path: "/reports/member/coverage/gaps",
    label: "Member Coverage Gaps",
  },
  {
    alias: "member-proximity",
    path: "/reports/member-proximity",
    label: "Member Proximity",
  },
  {
    alias: "member-declined",
    path: "/reports/member-declined",
    label: "Member Declined Assignments",
  },
  {
    alias: "member-assignment-appearance",
    path: "/reports/member-assignment-appearance",
    label: "Member Assignment Appearances",
  },
  { alias: "accounting_dki", path: "/reports/accounting-dki", label: "Accounting (DKI)" },
  {
    alias: "weekly_accounting_dki",
    path: "/reports/weekly-accounting-dki",
    label: "Weekly Accounting (DKI)",
  },
];
