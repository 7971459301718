import styled from "styled-components";
import constants from "../constants";

export const Container = styled.form`
  padding: 0 1.25rem;
  border: 1px solid ${constants.colorGray};
  border-radius: ${constants.borderRadius};
  background: ${constants.colorGrayLightest};
  position: ${(props) => (props.stickBottom ? "absolute" : "relative")};
  bottom: ${(props) => (props.stickBottom ? "1.25rem" : "unset")};
  left: ${(props) => (props.stickBottom ? "1.25rem" : "unset")};
  right: ${(props) => (props.stickBottom ? "1.25rem" : "unset")};
`;

export const TextArea = styled.textarea`
  width: 100%;
  outline: none;
  resize: none;
  border: none;
  padding: 1rem 0;
  background: ${constants.colorGrayLightest};
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;

  & > div {
    &:first-child {
      flex: 1;
    }
  }
`;

export const UsersForm = styled.div`
  padding: 1rem;
`;

export const UsersStaging = styled.div`
  padding: 1.25rem 0;
`;

export const Message = styled.div`
  padding: 1.25rem 2rem;
`;
