import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";

import { patchMilestoneCustomReq } from "features/JobProgress/jobProgressSlice";
import { getProgressValue } from "features/JobProgress/utils";

const Submitting = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(245, 242, 247, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2,
`;

const CustomReqToggle = ({
  title,
  buttonText,
  nid,
  type,
  field,
  initialValue,
  subID,
  children,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.jobProgress.custom_loading);
  const progress = useSelector((state) => state.jobProgress.data);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = (toggle) => {
    setSubmitting(true);
    dispatch(
      patchMilestoneCustomReq({
        id: nid,
        params: {
          type: type,
          index: field,
          sub_id: subID,
          toggle: Number(toggle),
        }
      })
    ).then((resp) => {
      setSubmitting(false);
    });
  };

  return (
    <div
      className="relative"
    >
      {submitting &&
        <Submitting>
          <CircularProgress />
        </Submitting>
      }
      { React.cloneElement(children, {
          onClick: (e) => {
            if(!loading){
              const isSet = Boolean(getProgressValue({
                progress: progress.milestone_progress.data,
                type,
                field,
                subID
              }));
              handleSubmit(!isSet)
            }
          },
          disabled: loading,
        }
      )}
    </div>
  );
};

CustomReqToggle.propTypes = {};

export default CustomReqToggle;
