import React from "react";

import { RecentDivisions } from "features/Divisions";

import { fetchCustomerJobs } from "features/Divisions/divisionsSlice";

const WeeksJobs = ({ nid }) => {
  return <RecentDivisions nid={nid} action={fetchCustomerJobs} />;
};

WeeksJobs.propTypes = {};

export default WeeksJobs;
