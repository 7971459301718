import React, { useEffect } from "react";
import { useSelector, connect } from "react-redux";
import moment from "moment";
import { RRule } from "rrule";
import {
  Button,
  Popper,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  NotificationsActive,
  GroupAdd,
  PostAdd,
  Subject,
} from "@mui/icons-material";

import { useDebounce } from "../../hooks";
import AutocompleteFormField from "../AutocompleteFormField";
import { SelectFormField } from "common/Fields";
import { TextFormField } from "common/Fields";
import { DateFormField } from "common/Fields";
import { times } from "../../utility";
import config from "../../config";
import { DialogForm } from "../Form";
import { RouteDialog } from "../Dialog";
import {
  formatUserForTask,
  getReminderValues,
  reminderHours,
  repeats,
} from "./utils";
import { patchTask, getUsers, getJobs } from "../../actions";
import { Dates, Times } from "./Styled";

const CustomPopper = function (props) {
  return <Popper {...props} style={{ width: 150 }} placement="bottom-start" />;
};

const TaskEdit = ({
  url,
  task,
  updateTask,
  loadingUsers,
  userError,
  users=[],
  loadUsers,
  loadingJobs,
  jobs=[],
  loadJobs,
  jobError,
  handleRefetchEvents,
}) => {
  const {
    nid,
    body,
    end,
    start,
    rrule,
    title,
    users: taskUsers,
    job,
    allDay,
    reminder,
  } = task;
  const userFields = useSelector((state) => state.auth.user.data);
  const [hasReminder, setHasReminder] = React.useState(Boolean(reminder));
  const [hasTimes, setHasTimes] = React.useState(!allDay);
  const [hasUsers, setHasUsers] = React.useState(true);
  const [hasJob, setHasJob] = React.useState(false);
  const [hasDesc, setHasDesc] = React.useState(false);
  const [openUser, setOpenUser] = React.useState(false);
  const [userOptions, setUserOptions] = React.useState([]);
  const [userQuery, setUserQuery] = React.useState("");
  const [openJob, setOpenJob] = React.useState(false);
  const [jobOptions, setJobOptions] = React.useState([]);
  const [jobQuery, setJobQuery] = React.useState("");
  const [untilValue, setUntilValue] = React.useState(moment());
  const debouncedUser = useDebounce(userQuery, 250);
  const debouncedJob = useDebounce(jobQuery, 250);
  const userWorking = openUser && loadingUsers;
  const jobWorking = openJob && loadingJobs;

  let repeatValue = "no-repeat";

  if (rrule) {
    repeatValue = repeats[RRule.fromString(rrule).options.freq];
  }

  useEffect(() => {
    if (rrule) {
      setUntilValue(RRule.fromString(rrule).options.until);
    }
  }, [rrule]);

  useEffect(() => {
    if (!userError) {
      const u = users.map((obj) => ({
        name: obj.name,
        uid: obj.uid,
      }));
      setUserOptions([...u]);
    }
  }, [userError, users]);

  useEffect(() => {
    if (!jobError) {
      const u = jobs.map((obj) => ({
        name: obj.title,
        nid: obj.division_nid,
      }));
      setJobOptions([...u]);
    }
  }, [jobError, jobs]);

  useEffect(() => {
    if (debouncedUser && openUser) {
      loadUsers({ keywords: debouncedUser });
    }
  }, [debouncedUser, openUser, loadUsers]);

  useEffect(() => {
    if (debouncedJob && openJob) {
      loadJobs({ keywords: debouncedJob });
    }
  }, [debouncedJob, openJob, loadJobs]);

  const getUserOptions = () => {
    setUserOptions([]);
    setOpenUser(true);
  };

  const clearUserOptions = () => {
    setOpenUser(false);
    setUserOptions([]);
  };

  const getJobOptions = () => {
    setJobOptions([]);
    setOpenJob(true);
  };

  const clearJobOptions = () => {
    setOpenJob(false);
    setJobOptions([]);
  };

  const handleUserInputChange = (e) => {
    if (e) {
      setUserQuery(e.target.value);
    }
  };

  const handleJobInputChange = (e) => {
    if (e) {
      setJobQuery(e.target.value);
    }
  };

  const handleSubmit = async (
    data,
    setSubmitting,
    setMessage,
    successCallback,
    errorCallback
  ) => {
    setSubmitting(true);

    const start = moment(
      moment(data["start-date"]).format("YYYY-MM-DD") +
        "T" +
        data["start-time"].value
    ).format();
    const end = moment(
      moment(data["end-date"]).format("YYYY-MM-DD") +
        "T" +
        data["end-time"].value
    ).format();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const allDay = !hasTimes;
    let rrule = "";
    if (data.repeats !== "no-repeat") {
      const rule = new RRule({
        freq: RRule[data.repeats],
        interval: 1,
        until: moment(data.until).format(),
      });

      rrule = rule.toString().substring(6);
    }

    const params = {
      _links: {
        type: {
          href: `${config.api_url}/rest/type/node/task`,
        },
      },
      title: [{ value: data.title }],
      field_task_all_day: [{ value: allDay }],
      body: [{ value: data.description }],
      uid: [{ target_id: userFields.uid }],
      field_task_users: data.user.map((u) => ({ target_id: u.uid })),
      field_task_date: [
        {
          value: start,
          end_value: end,
          rrule,
          timezone,
          infinite: true,
        },
      ],
    };

    if (data.job) {
      params.field_task_job_number = [{ target_id: data.job.nid }];
    }

    if (hasReminder) {
      params.field_task_reminder = [
        { value: `${data["reminder-time"]} ${data["reminder-type"]}` },
      ];
    }

    const response = await updateTask(nid, params);
    if (response.status === 200) {
      handleRefetchEvents();
      successCallback("Task updated successfully.");
    } else {
      errorCallback(setMessage, response, []);
    }
  };

  return (
    <RouteDialog
      route="edit"
      path="edit"
      url={url}
      maxWidth="xs"
      title="Edit Task"
    >
      <DialogForm
        submitText="Update"
        url={url}
        path="edit"
        initialValues={{
          title,
          "start-date": moment(start),
          "end-date": moment(end),
          "start-time": {
            name: moment(start).format("h:mm A"),
            value: moment(start).format("HH:mm"),
          },
          "end-time": {
            name: moment(end).format("h:mm A"),
            value: moment(end).format("HH:mm"),
          },
          repeats: repeatValue,
          description: body ? body : "",
          user: formatUserForTask(taskUsers),
          job: job.nid ? { nid: job.nid, name: job.title } : null,
          "reminder-type": getReminderValues(reminder).type,
          "reminder-time": getReminderValues(reminder).time,
          until: untilValue,
        }}
        onSubmit={handleSubmit}
      >
        {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
          <>
            <TextFormField
              fullWidth
              htmlFor="title"
              name="title"
              label="Title"
              margin="normal"
              disabled={isSubmitting}
              type="text"
              labelwidth={70}
            />
            <Dates>
              <DateFormField
                disableToolbar
                variant="inline"
                format="MMM D, YYYY"
                id="start-date"
                margin="normal"
                name="start-date"
                size="small"
                datePicker
              />
              {!hasTimes && <span>-</span>}
              {hasTimes && (
                <Times>
                  <AutocompleteFormField
                    name="start-time"
                    htmlFor="start-time"
                    margin="normal"
                    disabled={isSubmitting}
                    disableClearable
                    options={times}
                    PopperComponent={CustomPopper}
                  />
                  <span>-</span>
                  <AutocompleteFormField
                    name="end-time"
                    htmlFor="end-time"
                    margin="normal"
                    disabled={isSubmitting}
                    disableClearable
                    PopperComponent={CustomPopper}
                    options={times}
                  />
                </Times>
              )}
              <DateFormField
                disableToolbar
                variant="inline"
                format="MMM D, YYYY"
                id="end-date"
                margin="normal"
                name="end-date"
                size="small"
                datePicker
              />
            </Dates>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!hasTimes}
                  onChange={() => setHasTimes(!hasTimes)}
                  color="secondary"
                />
              }
              label="All Day"
            />
            <Grid container spacing={3} style={{ marginTop: "1rem" }}>
              <Grid item xxs={6}>
                <SelectFormField
                  fullWidth
                  name="repeats"
                  label="Repeats"
                  margin="normal"
                  disabled={isSubmitting}
                  options={[
                    { value: "no-repeat", label: "Does not repeat" },
                    { value: "DAILY", label: "Daily" },
                    { value: "WEEKLY", label: "Weekly" },
                    { value: "MONTHLY", label: "Monthly" },
                    { value: "YEARLY", label: "Annually" },
                  ]}
                />
              </Grid>
              <Grid item xxs={6}>
                {values.repeats !== "no-repeat" && (
                  <DateFormField
                    label="Repeat To"
                    fullWidth
                    disableToolbar
                    variant="inline"
                    format="MMM D, YYYY"
                    id="until"
                    margin="normal"
                    name="until"
                    size="small"
                    datePicker
                  />
                )}
              </Grid>
            </Grid>
            {hasReminder || reminder ? (
              <>
                <Grid container spacing={3} style={{ marginTop: "1rem" }}>
                  <Grid item xxs={3}>
                    <SelectFormField
                      fullWidth
                      name="reminder-time"
                      label=""
                      disabled={isSubmitting}
                      options={reminderHours()}
                    />
                  </Grid>
                  <Grid item xxs={9}>
                    <SelectFormField
                      fullWidth
                      name="reminder-type"
                      label=""
                      disabled={isSubmitting}
                      options={[
                        { value: "hours", label: "Hour(s) before" },
                        { value: "days", label: "Day(s) before" },
                        { value: "weeks", label: "Week(s) before" },
                      ]}
                    />
                  </Grid>
                </Grid>
                <Typography
                  onClick={() => setHasReminder(false)}
                  style={{
                    marginTop: "0.5rem",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Remove Reminder
                </Typography>
              </>
            ) : (
              <Button
                fullWidth
                size="small"
                component="a"
                onClick={() => setHasReminder(true)}
                style={{ justifyContent: "flex-start", marginTop: "1rem" }}
                startIcon={<NotificationsActive />}
              >
                Add Reminder
              </Button>
            )}
            {hasUsers || (taskUsers && taskUsers.length > 0) ? (
              <AutocompleteFormField
                multiple
                name="user"
                label="User"
                htmlFor="user"
                margin="normal"
                disabled={isSubmitting}
                onInputChange={handleUserInputChange}
                open={openUser}
                onOpen={getUserOptions}
                onClose={clearUserOptions}
                options={userOptions}
                loading={userWorking}
              />
            ) : (
              <Button
                fullWidth
                size="small"
                component="a"
                onClick={() => setHasUsers(true)}
                style={{ justifyContent: "flex-start", marginTop: "1rem" }}
                startIcon={<GroupAdd />}
              >
                Add Users
              </Button>
            )}
            {hasJob || job.nid ? (
              <AutocompleteFormField
                name="job"
                label="Job"
                htmlFor="job"
                margin="normal"
                disabled={isSubmitting}
                onInputChange={handleJobInputChange}
                open={openJob}
                onOpen={getJobOptions}
                onClose={clearJobOptions}
                options={jobOptions}
                loading={jobWorking}
              />
            ) : (
              <Button
                fullWidth
                size="small"
                component="a"
                onClick={() => setHasJob(true)}
                style={{ justifyContent: "flex-start", marginTop: "1rem" }}
                startIcon={<PostAdd />}
              >
                Add Job
              </Button>
            )}
            {hasDesc || body ? (
              <TextFormField
                fullWidth
                htmlFor="description"
                name="description"
                label="Description"
                margin="normal"
                multiline
                disabled={isSubmitting}
                type="text"
                labelwidth={70}
              />
            ) : (
              <Button
                fullWidth
                size="small"
                component="a"
                onClick={() => setHasDesc(true)}
                style={{ justifyContent: "flex-start", marginTop: "1rem" }}
                startIcon={<Subject />}
              >
                Add Description
              </Button>
            )}
          </>
        )}
      </DialogForm>
    </RouteDialog>
  );
};

TaskEdit.propTypes = {};

const mapStateToProps = (state) => ({
  users: state.app.users.data.data,
  loadingUsers: state.app.users.loading,
  userError: state.app.users.error,
  jobs: state.app.jobs.data.data,
  loadingJobs: state.app.jobs.loading,
  jobError: state.app.jobs.error,
});

const mapDispatchToProps = (dispatch) => ({
  updateTask: (nid, params) => dispatch(patchTask(nid, params)),
  loadUsers: (params) => dispatch(getUsers(params)),
  loadJobs: (params) => dispatch(getJobs(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskEdit);
