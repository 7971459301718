import React from "react";
import { useSelector } from "react-redux";
import { Divider, Typography } from "@mui/material";
import { FaRegAddressBook } from "react-icons/fa";
import { RiContactsBookLine } from "react-icons/ri";
import { ReactComponent as IconProvider } from "assets/images/icons/icon-provider.svg";

import CollapsibleCard from "common/CollapsibleCard";
import Drawer from "common/Drawer";
import IconTextItem from "common/IconTextItem";
import Empty from "common/Empty";
import LinkHeader from "common/LinkHeader";
import UserItem from "common/UserItem";

import { getMemberDataSelector } from "features/Member/memberSlice";
import { size } from "lodash";

const Member = (props) => {
  const _member = useSelector(getMemberDataSelector);
  const inListing = (props?.member ? true : false);
  const member = (inListing ? props.member : _member);

  if (!member) return null;

  return (
    <Drawer
      anchor="right"
      primary="Provider"
      secondary={member?.title ? member.title : "No Provider data"}
      icon={<IconProvider />}
      className="z-[1400]"
      action={(
        !inListing ? (
          <CollapsibleCard
            label="Provider"
            value={member?.title ? member.title : "No Provider data"}
            collapse={false}
            className="m-0"
          >
            <IconProvider />
          </CollapsibleCard>
        ) : (
          <Typography
            variant="subtitle"
            style={{ textTransform: "uppercase", cursor: "pointer" }}
            color="secondary"
          >
            {member?.title ? member.title : "No Provider data"}
          </Typography>
        )
      )}
    >
      {member.title ? (
        <div style={{ padding: "1.25rem" }}>
          <div style={{ marginBottom: "2rem" }}>
            <LinkHeader to={member?.self} color="secondary">
              {member?.title}
            </LinkHeader>
          </div>
          <IconTextItem icon={<FaRegAddressBook />} gutterBottom>
            <Typography
              variant="subtitle2"
              style={{ textTransform: "uppercase" }}
              color="textSecondary"
            >
              Address
            </Typography>
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "1rem" }}>
                <Typography variant="body1" align="right">
                  Address:
                </Typography>
                {member?.field_street_address?.address_line2 && (
                  <Typography variant="body1" align="right">
                    Address 2:
                  </Typography>
                )}
                <Typography variant="body1" align="right">
                  City:
                </Typography>
                <Typography variant="body1" align="right">
                  State:
                </Typography>
                <Typography variant="body1" align="right">
                  Zip Code:
                </Typography>
              </div>
              <div style={{ flex: 1 }}>
                <Typography variant="body1">
                  {member?.field_street_address?.address_line1}
                </Typography>
                <Typography variant="body1">
                  {member?.field_street_address?.address_line2}
                </Typography>
                <Typography variant="body1">
                  {member?.field_street_address?.locality}
                </Typography>
                <Typography variant="body1">
                  {member?.field_street_address?.administrative_area}
                </Typography>
                <Typography variant="body1">
                  {member?.field_street_address?.postal_code}
                </Typography>
              </div>
            </div>
          </IconTextItem>
          {member?.field_phone && (
            <IconTextItem icon={<FaRegAddressBook />} gutterBottom>
              <Typography
                variant="subtitle2"
                style={{ textTransform: "uppercase" }}
                color="textSecondary"
              >
                Office Phone
              </Typography>
              <Typography variant="body1">{member?.field_phone}</Typography>
            </IconTextItem>
          )}
          {member?.field_phone_after_hours && (
            <IconTextItem icon={<FaRegAddressBook />} gutterBottom>
              <Typography
                variant="subtitle2"
                style={{ textTransform: "uppercase" }}
                color="textSecondary"
              >
                After Hours Phone
              </Typography>
              <Typography variant="body1">
                {member?.field_phone_after_hours}
              </Typography>
            </IconTextItem>
          )}
          <Divider style={{ margin: "1rem 0" }} />
          <IconTextItem icon={<RiContactsBookLine />} gutterBottom>
            <Typography
              variant="subtitle2"
              style={{ textTransform: "uppercase" }}
              color="textSecondary"
            >
              Primary Contact
            </Typography>
            {size(member?.field_primary_poc) > 0 ? (
              member?.field_primary_poc.map((user, i) => (
                <UserItem key={`${user.uid}-primary-${i}`} user={user} />
              ))
            ) : (
              <Typography>No contact set</Typography>
            )}
          </IconTextItem>
          <Divider style={{ margin: "1rem 0" }} />
          <IconTextItem icon={<RiContactsBookLine />} gutterBottom>
            <Typography
              variant="subtitle2"
              style={{ textTransform: "uppercase" }}
              color="textSecondary"
            >
              Secondary Contacts
            </Typography>
            {size(member?.field_secondary_pocs) > 0 ? (
              member?.field_secondary_pocs.map((user, i) => (
                <UserItem key={`${user.uid}-secondary-${i}`} user={user} />
              ))
            ) : (
              <Typography>No contact set</Typography>
            )}
          </IconTextItem>
          <Divider style={{ margin: "1rem 0" }} />
          <IconTextItem icon={<RiContactsBookLine />} gutterBottom>
            <Typography
              variant="subtitle2"
              style={{ textTransform: "uppercase" }}
              color="textSecondary"
            >
              After Hours Contacts
            </Typography>
            {size(member?.field_after_hours_pocs) > 0 ? (
              member?.field_after_hours_pocs.map((user, i) => (
                <UserItem key={`${user.uid}-ah-${i}`} user={user} />
              ))
            ) : (
              <Typography>No contact set</Typography>
            )}
          </IconTextItem>
        </div>
      ) : (
        <Empty message="No Provider data">
          <IconProvider />
        </Empty>
      )}
    </Drawer>
  );
};

Member.propTypes = {};

export default Member;
