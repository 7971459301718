import React from "react";
import { Typography, InputAdornment } from "@mui/material";
import { TextFormField, RadioFormField } from "common/Fields";

const TMTermsDailySheetMarkupComponent = ({
  namePrefix,
  values,
  isSubmitting,
  dsType,
  ...props
}) => {
  const type = values.markup_type !== "flat" ? 'percent' : 'flat';

  return (
    <div className="flex">
      <Typography variant="subtitle2" className="pt-2 pr-2">Markup: </Typography>
      {dsType !== 'equipment' &&
        <RadioFormField
          name={`${namePrefix}markup_type`}
          // aria="Who is receiving this notification?"
          // label="Who is receiving this notification?"
          margin="normal"
          required={true}
          disabled={isSubmitting}
          wrapperStyle={{ flex: "1 0 auto" }}
          defaultValue="percent"
          options={[
            {value: 'percent', label: "Percent"},
            {value: 'flat', label: "Amount"}
          ]}
          orientation="horizontal"
        />
      }
      <TextFormField
        fullWidth
        name={`${namePrefix}field_ds_${type === 'percent' ? 'percent_' : ''}markup`}
        label={type === 'percent' ? 'Percent' : 'Amount'}
        variant="outlined"
        size="small"
        disabled={isSubmitting}
        startAdornment={
          (type === 'flat'
            ? <InputAdornment position="start">$</InputAdornment>
            : null
          )
        }
        endAdornment={
          (type === 'percent' ? <InputAdornment position="end">%</InputAdornment> : null)
        }
      />
    </div>
  );
};

export default TMTermsDailySheetMarkupComponent;
