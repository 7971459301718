import React from "react";
import { useSelector, connect } from "react-redux";
import Helmet from "react-helmet";
import SimpleBar from "simplebar-react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import { HiOutlineDocumentReport } from "react-icons/hi";

import ReportsList from "./ReportsList";
import { Wrapper } from "./Styled";
import { Page, PageContainer } from "../Styled";
import PageHeader from "../PageHeader";
import ReportCustomers from "./ReportCustomers";
import ReportMembers from "./ReportMembers";
import ReportAccounting from "./ReportAccounting";
import ReportAccountingDKI from "./ReportAccountingDKI";
import ReportWeeklyAccountingDKI from "./ReportWeeklyAccountingDKI";
// import ReportAging from "./ReportAging";
import ReportAgingSpan from "./ReportAgingSpan";
import ReportCustomerLocations from "./ReportCustomerLocations";
import ReportCustomerNotes from "./ReportCustomerNotes";
import ReportComplianceDocs from "./ReportComplianceDocs";
import ReportJobDivisions from "./ReportJobDivisions";
import ReportMemberNotes from "./ReportMemberNotes";
import ReportCustomerUsers from "./ReportCustomerUsers";
import ReportMemberUsers from "./ReportMemberUsers";
import ReportMembersOverride from "./ReportMembersOverride";
import ReportJobsOverride from "./ReportJobsOverride";
import ReportWipDetail from "./ReportWipDetail";
import ReportWipDetailParent from "./ReportWipDetailParent";
import ReportWipOverview from "./ReportWipOverview";
import ReportHpnSelect from "./ReportHpnSelect";
import ReportTimeEntries from "./ReportTimeEntries";
import ReportTerritories from "./ReportTerritories";
import ReportAgreements from "./ReportAgreements";
import ReportTerminations from "./ReportTerminations";
import ReportOwners from "./ReportOwners";
import ReportEmployees from "./ReportEmployees";
import ReportAddresses from "./ReportAddresses";
import ReportFullList from "./ReportFullList";
import ReportEntityName from "./ReportEntityName";
import ReportXactimate from "./ReportXactimate";
import ReportMemberProximity from "./ReportMemberProximity";
import ReportMemberCoverage from "./ReportMemberCoverage";
import ReportMemberCoverageGaps from "./ReportMemberCoverageGaps";
import ReportMemberDeclined from "./ReportMemberDeclined";
import ReportMemberAssignmentAppearances from "./ReportMemberAssignmentAppearances";
import AgingDaysBetween from "features/Reports/components/AgingDaysBetween";
import AgingUserMinutes from "features/Reports/components/AgingUserMinutes";

const Reports = ({ org }) => {
  const reportPermissions = useSelector(
    (state) => state.auth.user.data._processed.reports
  );
  const { url } = useRouteMatch();

  return (
    <Page>
      <Helmet>
        <title>Helix | Reports </title>
        <meta name="description" content="Reports" />
      </Helmet>
      <PageHeader title="Reports" icon={<HiOutlineDocumentReport className="!mr-0 -mb-1" />} />
      <SimpleBar>
        <PageContainer>
          <Wrapper>
            <Switch>
              {reportPermissions.accounting && (
                <Route path={`${url}/accounting`}>
                  <ReportAccounting org={org} />
                </Route>
              )}
              {reportPermissions.accounting_dki && (
                <Route path={`${url}/accounting-dki`}>
                  <ReportAccountingDKI org={org} />
                </Route>
              )}
              {reportPermissions.weekly_accounting_dki && (
                <Route path={`${url}/weekly-accounting-dki`}>
                  <ReportWeeklyAccountingDKI org={org} />
                </Route>
              )}
              {/* {reportPermissions.aging_report && (
                <Route path={`${url}/jobs-aging`}>
                  <ReportAging org={org} />
                </Route>
              )} */}
              {reportPermissions.aging_report && (
                <Route path={`${url}/aging-days`}>
                  <AgingDaysBetween />
                </Route>
              )}
              {reportPermissions.aging_report && (
                <Route path={`${url}/aging-users`}>
                  <AgingUserMinutes />
                </Route>
              )}
              {reportPermissions.aging_report && (
                <Route path={`${url}/jobs-aging`}>
                  <ReportAgingSpan org={org} />
                </Route>
              )}
              {reportPermissions.compliance_documents && (
                <Route path={`${url}/compliance-documents`}>
                  <ReportComplianceDocs org={org} />
                </Route>
              )}
              {reportPermissions.job_divisions && (
                <Route path={`${url}/job-divisions`}>
                  <ReportJobDivisions org={org} />
                </Route>
              )}
              {reportPermissions.members && (
                <Route path={`${url}/members`}>
                  <ReportMembers org={org} />
                </Route>
              )}
              {reportPermissions.customers && (
                <Route path={`${url}/customers`}>
                  <ReportCustomers org={org} />
                </Route>
              )}
              {reportPermissions.customer_locations && (
                <Route path={`${url}/customer-locations`}>
                  <ReportCustomerLocations org={org} />
                </Route>
              )}
              {reportPermissions.customer_notes && (
                <Route path={`${url}/customer-notes`}>
                  <ReportCustomerNotes org={org} />
                </Route>
              )}
              {reportPermissions.customer_users && (
                <Route path={`${url}/customer-users`}>
                  <ReportCustomerUsers org={org} />
                </Route>
              )}
              {reportPermissions.member_notes && (
                <Route path={`${url}/member-notes`}>
                  <ReportMemberNotes org={org} />
                </Route>
              )}
              {reportPermissions.member_users && (
                <Route path={`${url}/member-users`}>
                  <ReportMemberUsers org={org} />
                </Route>
              )}
              {reportPermissions.override_members && (
                <Route path={`${url}/override-members`}>
                  <ReportMembersOverride org={org} />
                </Route>
              )}
              {reportPermissions.override_duplicate_jobs && (
                <Route path={`${url}/override-jobs`}>
                  <ReportJobsOverride org={org} />
                </Route>
              )}
              {reportPermissions.wip_detail && (
                <Route path={`${url}/wip-detail`}>
                  <ReportWipDetail org={org} />
                </Route>
              )}
              {reportPermissions.wip_detail_parent_customer && (
                <Route path={`${url}/wip-detail-parent-customer`}>
                  <ReportWipDetailParent org={org} />
                </Route>
              )}
              {reportPermissions.wip_overview && (
                <Route path={`${url}/wip-overview`}>
                  <ReportWipOverview org={org} />
                </Route>
              )}
              {reportPermissions.hpn_select && (
                <Route path={`${url}/hpn-select`}>
                  <ReportHpnSelect org={org} />
                </Route>
              )}
              {reportPermissions.time_entries && (
                <Route path={`${url}/time-entries`}>
                  <ReportTimeEntries org={org} />
                </Route>
              )}
              <Route path={`${url}/territories`}>
                <ReportTerritories org={org} />
              </Route>
              <Route path={`${url}/agreements`}>
                <ReportAgreements org={org} />
              </Route>
              <Route path={`${url}/terminations`}>
                <ReportTerminations org={org} />
              </Route>
              <Route path={`${url}/owners`}>
                <ReportOwners org={org} />
              </Route>
              <Route path={`${url}/employees`}>
                <ReportEmployees org={org} />
              </Route>
              <Route path={`${url}/addresses`}>
                <ReportAddresses org={org} />
              </Route>
              <Route path={`${url}/full-listing`}>
                <ReportFullList org={org} />
              </Route>
              <Route path={`${url}/entity-name`}>
                <ReportEntityName org={org} />
              </Route>
              <Route path={`${url}/xactimate`}>
                <ReportXactimate org={org} />
              </Route>
              <Route path={`${url}/member/coverage/gaps`}>
                <ReportMemberCoverageGaps org={org} />
              </Route>
              <Route path={`${url}/member/coverage`}>
                <ReportMemberCoverage org={org} />
              </Route>
              <Route path={`${url}/member-proximity`}>
                <ReportMemberProximity org={org} />
              </Route>
              <Route path={`${url}/member-declined`}>
                <ReportMemberDeclined org={org} />
              </Route>
              <Route path={`${url}/member-assignment-appearance`}>
                <ReportMemberAssignmentAppearances org={org} />
              </Route>
              <Route path={`${url}`}>
                <ReportsList permissions={reportPermissions} />
              </Route>
            </Switch>
          </Wrapper>
        </PageContainer>
      </SimpleBar>
    </Page>
  );
};

Reports.propTypes = {};

const mapStateToProps = (state) => ({
  org: state.auth.current_client,
});

export default connect(mapStateToProps)(Reports);
