import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RiFileUploadLine } from "react-icons/ri";
import {
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { first } from "lodash";

import Dialog from "common/Dialog";
import { MultiUploader } from "common/Uploader";

import { postCustomerFile } from "features/Files/filesSlice";
import { customerFileCategoriesSelectors } from "features/Taxonomies/taxonomiesSlice";
import { getCustomerDataSelector } from "../customerSlice";

const Form = ({ setFormData, formData, id }) => {
  const categories = useSelector(customerFileCategoriesSelectors.selectAll);
  const roleType = useSelector((state) => state.auth.user.data.roleType);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [id]: { ...formData[id], [e.target.name]: e.target.value },
    });
  };

  const handleChecked = (e) => {
    setFormData({
      ...formData,
      [id]: { ...formData[id], [e.target.name]: e.target.checked },
    });
  };

  return (
    <div>
      <FormControl variant="filled" size="small" fullWidth margin="normal">
        <InputLabel id="div-category">Category</InputLabel>
        <Select
          name="category"
          labelId="div-category"
          id="div-category-select"
          value={formData[id].category}
          onChange={handleChange}
          label="Category"
        >
          {categories.map((option) => (
            <MenuItem value={option.tid}>{option.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {roleType === 'phx_client' && (
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData[id].phx_client}
                onChange={handleChecked}
                name="phx_client"
              />
            }
            label="Admin"
          />
        </div>
      )}
      {(roleType === 'phx_client' || roleType === 'member') && (
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData[id].member}
                onChange={handleChecked}
                name="member"
              />
            }
            label="Member"
          />
        </div>
      )}
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData[id].customer}
              onChange={handleChecked}
              name="customer"
            />
          }
          label="Customer"
        />
      </div>
    </div>
  );
};

const AddDocument = ({
  referenceNid,
  timeOverride,
  categoryNameOverride,
  children,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const customer = useSelector(getCustomerDataSelector);
  const categories = useSelector(customerFileCategoriesSelectors.selectAll);

  const handleSubmit = async (meta, fid, setComplete) => {
    const params = {
      ...meta,
      fid,
    };

    await dispatch(
      postCustomerFile({
        id: customer.nid,
        params,
      })
    );

    setComplete((complete) => complete + 1);
  };

  const handleComplete = () => {
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {children ? (
        React.cloneElement(children, {
          onClick: () => setOpen(true),
        })
      ) : (
        <ListItem button onClick={() => setOpen(true)}>
          <ListItemIcon>
            <RiFileUploadLine />
          </ListItemIcon>
          <ListItemText primary="File" />
        </ListItem>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        title="Add Documents"
        initialValues={{}}
        onSubmit={handleSubmit}
        disablePadding
      >
        <>
          <MultiUploader
            // entity="paragraph"
            // bundle="job_division_file"
            entity="node"
            bundle="general_file"
            field="field_file"
            initialValues={{
              category: first(categories)?.tid,
              phx_client: true,
              member: true,
              customer: true,
            }}
            onUploadSuccess={handleSubmit}
            onUploadStart={() => {}}
            onUploadFail={() => {}}
            onRemove={() => {}}
            onComplete={handleComplete}
            form={(props) => <Form {...props} />}
            onClose={handleClose}
          />
        </>
      </Dialog>
    </>
  );
};

AddDocument.propTypes = {};

export default AddDocument;
