import React, { useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import {
  Button,
  Typography,
  IconButton,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { NavigateNext, NavigateBefore } from "@mui/icons-material";
import constants from "components/constants";

import {
  Toolbar,
  Container,
  ToolbarActionsLeft,
  ToolbarActionsRight,
} from "./Styled";

const MemoCal = React.memo(FullCalendar, (prevProps, nextProps) => {
  const isDiff = JSON.stringify(prevProps) === JSON.stringify(nextProps);
  return isDiff;
});

const Calendar = ({
  actions: Actions,
  onEventRequest,
  eventClick,
  dateClick,
  select,
  setRef,
}) => {
  const calendar = React.useRef();
  const [title, setTitle] = React.useState("");
  const [display, setDisplay] = React.useState("dayGridMonth");

  useEffect(() => {
    if (calendar) {
      setTitle(calendar.current.getApi().view.title);
    }
  }, [calendar]);

  useEffect(() => {
    if (setRef) {
      setRef.current = calendar.current;
    }
  }, [calendar, setRef]);

  const handleNext = (e) => {
    calendar.current.getApi().next();
    setTitle(calendar.current.getApi().view.title);
  };

  const handlePrev = (e) => {
    calendar.current.getApi().prev();
    setTitle(calendar.current.getApi().view.title);
  };

  const handleToday = (e) => {
    calendar.current.getApi().today();
    setTitle(calendar.current.getApi().view.title);
  };

  const handleChange = (event) => {
    setDisplay(event.target.value);
    calendar.current.getApi().changeView(event.target.value);
    setTitle(calendar.current.getApi().view.title);
  };

  return (
    <Container>
      <Toolbar>
        <ToolbarActionsLeft>
          <Button variant="outlined" onClick={handleToday}>
            Today
          </Button>
          <Tooltip title="Previous">
            <IconButton size="small" onClick={handlePrev}>
              <NavigateBefore />
            </IconButton>
          </Tooltip>
          <Tooltip title="Next">
            <IconButton size="small" onClick={handleNext}>
              <NavigateNext />
            </IconButton>
          </Tooltip>
          <Typography paragraph={false} gutterBottom={false}>
            {title}
          </Typography>
        </ToolbarActionsLeft>
        <ToolbarActionsRight>
          <FormControl variant="outlined" size="small">
            <Select value={display} onChange={handleChange}>
              <MenuItem value="dayGridMonth">Month</MenuItem>
              <MenuItem value="timeGridWeek">Week</MenuItem>
              <MenuItem value="timeGridDay">Day</MenuItem>
            </Select>
          </FormControl>
          {Actions && <Actions />}
        </ToolbarActionsRight>
      </Toolbar>
      <MemoCal
        ref={calendar}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        defaultView="dayGridMonth"
        showNonCurrentDates={false}
        nowIndicator
        selectable
        unselectAuto
        eventColor={constants.colorBlueAlt}
        eventTextColor={constants.colorWhite}
        height="auto"
        slotDuration="00:30:00"
        header={{
          left: "",
          center: "",
          right: "",
        }}
        views={{
          timeGridWeek: {
            columnHeaderHtml: (date) => {
              const weekday = moment(date).format("ddd");
              const day = moment(date).format("D");
              return `
                      <div class="calendar__toolbar-week-view">
                          <div class="calendar__toolbar-today-badge">
                              <div class="weekday">${weekday}</div>
                              <div class="day">${day}</div>
                          </div>
                      </div>
                  `;
            },
          },
          timeGridDay: {
            columnHeaderHtml: (date) => {
              const weekday = moment(date).format("ddd");
              const day = moment(date).format("D");
              return `
                    <div class="calendar__toolbar-week-view">
                        <div class="calendar__toolbar-today-badge">
                            <div class="weekday">${weekday}</div>
                            <div class="day">${day}</div>
                        </div>
                    </div>
                `;
            },
          },
        }}
        eventSources={
          onEventRequest
            ? [
                {
                  events: async (info, successCallback, failureCallback) => {
                    await onEventRequest(
                      info,
                      successCallback,
                      failureCallback
                    );
                  },
                  id: "base",
                },
              ]
            : []
        }
        eventClick={(info) => (eventClick ? eventClick(info) : null)}
        dateClick={(info) => (dateClick ? dateClick(info) : null)}
        select={(info) => (select ? select(info) : null)}
        eventTimeFormat={{
          hour: "numeric",
          minute: "2-digit",
          meridiem: "short",
        }}
      />
    </Container>
  );
};

Calendar.propTypes = {};

export default Calendar;
