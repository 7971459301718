// TODO: Need to add better error handling and messaging if some items in the batch fail to post

import React, { useCallback, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from "react-redux";
import { FieldArray } from "formik";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import moment from "moment";
import { Add } from "@mui/icons-material";
import { AiOutlineFileAdd } from "react-icons/ai";

import BatchItem from "./BatchItem";
import Button from "common/Button";
import { DialogForm } from "common/Dialog";
import { DateFormField } from "common/Fields";
import { BatchContainer } from "./Styled";
import { serviceBatchMilitaryValidationSchema } from "schemas/forms";
import { calcTotal, calcServiceHours, parseFormServiceTimes, calcSmallTools, calcMarkup } from "../utils";
import { size } from "lodash";
import { getMemberDataSelector } from "features/Member/memberSlice";
import { getDivisionDataSelector } from "features/Job/jobSlice";
import {
  fetchMemberUsersTemp,
  getMemberUsersLoadingSelector,
  memberUsersSelector,
} from "features/Users/usersSlice";
import {
  fetchWorkAreas,
  getAreasLoadingSelector,
  workAreasSelectors,
} from "features/WorkAreas/workAreasSlice";
import { postBreak, postService } from "features/DailySheets/dailysheetsSlice";
import { laborTypesSelectors } from "features/PriceList/priceListSlice";
import TempUsersQuickAdd from "features/Users/forms/TempUsersQuickAdd";
import { fetchDivisionSnapshot } from "features/Job/jobSlice";
import { isDivisionStatusPast } from "utility";

const calcOverallHours = (values) => {
  const times = parseFormServiceTimes(values);

  const service = calcServiceHours(times.start, times.end);
  const travel = values.field_ds_travel_billable_hrs;
  const overtime = values.field_ds_overtime_billable_hrs;
  const after = values.field_ds_after_hours_bill_hrs;
  let breakHours = 0;
  if (times.breakStart && times.breakEnd) {
    breakHours = calcServiceHours(times.breakStart, times.breakEnd);
  }

  const total =
    Math.round(
      (Number(service) +
        Number(travel) +
        Number(overtime) +
        Number(after) -
        Number(breakHours)) *
        100
    ) / 100;
  return isNaN(total) ? 0 : total < 0 ? 0 : total;
};

const calcOverallTotal = (values) => {
  const times = parseFormServiceTimes(values);

  const service = calcServiceHours(times.start, times.end);
  const travel = values.field_ds_travel_billable_hrs;
  const overtime = values.field_ds_overtime_billable_hrs;
  const after = values.field_ds_after_hours_bill_hrs;
  let breakHours = 0;
  if (times.breakStart && times.breakEnd) {
    breakHours = calcServiceHours(times.breakStart, times.breakEnd);
  }

  const serviceTotal = calcTotal(service - breakHours, values["service-rate"]);
  const travelTotal = calcTotal(travel, values["travel-rate"]);
  const overtimeTotal = calcTotal(overtime, values["overtime-rate"]);
  const afterTotal = calcTotal(after, values["after-rate"]);
  const subTotal = (
    Number(serviceTotal) +
    Number(travelTotal) +
    Number(overtimeTotal) +
    Number(afterTotal)
  );
  const smallTools = calcSmallTools(values, subTotal);
  const markup = calcMarkup(values, subTotal);
  const total = subTotal + Number(values.field_ds_perdiem_total) + smallTools + markup;

  return {total, smallTools, markup};
};

const Batch = ({
  nid,
  loadAvailableUsers,
  error,
  memberNid,
  errorAreas,
  loadWorkareas,
  handleRefresh,
  ...props
}) => {
  const dispatch = useDispatch();
  const member = useSelector(getMemberDataSelector);
  const division = useSelector(getDivisionDataSelector);
  const users = useSelector(memberUsersSelector.selectAll);
  const loading = useSelector(getMemberUsersLoadingSelector);
  const types = useSelector(laborTypesSelectors.selectAll);
  const areas = useSelector(workAreasSelectors.selectAll);
  const loadingAreas = useSelector(getAreasLoadingSelector);
  const user = useSelector((state) => state.auth.user.data)
  const divisionStatuses = useSelector((state) => state.taxonomies.jobDivisionStatus.entities)
  const memberHours = member.field_hours;

  const [submissions, setSubmissions] = useState({});
  const [failedSubmissions, setFailedSubmissions] = useState(null); // eslint-disable-line
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState({
    total: 0,
    obtained: 0,
    show: false,
  });

  const getUsers = useCallback(
    (query) => {
      return dispatch(
        fetchMemberUsersTemp({
          id: member?.nid,
          params: {
            keywords: query,
          },
        })
      );
    },
    [member, dispatch]
  );

  const getAreas = useCallback(
    (query) => {
      return dispatch(
        fetchWorkAreas({
          id: division?.nid,
          params: {
            keywords: query,
          },
        })
      );
    },
    [division, dispatch]
  );

  const handleCreateBreak = async (data, nid) => {
    const times = parseFormServiceTimes(data);
    if (times.breakStart && times.breakEnd) {
      const params = {
        field_ds_breaks_start_time: [
          {
            value: times.breakStart.format(`YYYY-MM-DD[T]HH:mm:00Z`),
          },
        ],
        field_ds_breaks_end_time: [
          {
            value: times.breakEnd.format(`YYYY-MM-DD[T]HH:mm:00Z`),
          },
        ],
      };

      const resultAction = await dispatch(postBreak({ id: nid, params }));
      return resultAction;
    } else {
      return null;
    }
  };

  const handleCreateDailySheet = async (data, date) => {
    data.field_ds_date = date;
    const times = parseFormServiceTimes(data);
    const serviceHours = calcServiceHours( times.start, times.end );
    let breakHours = 0;
    if(times.breakStart && times.breakEnd){
      breakHours = calcServiceHours(times.breakStart, times.breakEnd);
    }
    const {total, markup} = calcOverallTotal(data, memberHours);

    const params = {
      title: [{ value: `Daily Sheet for ${division.title}` }],
      field_ds_service_status: [{ value: 'complete' }],
      field_ds_date: [
        { value: moment(data.field_ds_date).format("YYYY-MM-DD") },
      ],
      field_ds_labor_type_text: [{ value: data.field_ds_labor_type_text }],
      field_ds_start_time: [
        {
          value: times.start.format(`YYYY-MM-DD[T]HH:mm:00Z`),
        },
      ],
      field_ds_end_time: [
        {
          value: times.end.format(`YYYY-MM-DD[T]HH:mm:00Z`),
        },
      ],
      // Hours
      field_ds_billable_hrs: [
        {
          value: serviceHours - breakHours,
        },
      ],
      field_ds_travel_billable_hrs: [
        { value: data.field_ds_travel_billable_hrs },
      ],
      field_ds_after_hours_bill_hrs: [
        {
          value: data.field_ds_after_hours_bill_hrs,
        },
      ],
      field_ds_overtime_billable_hrs: [
        { value: data.field_ds_overtime_billable_hrs },
      ],
      field_ds_break_hrs: [
        {
          value: breakHours,
        },
      ],
      // Rates
      field_ds_rate: [{ value: data["service-rate"] }],
      field_ds_overtime_rate: [{ value: data["overtime-rate"] }],
      field_ds_travel_rate: [{ value: data["travel-rate"] }],
      field_ds_after_hours_rate: [{ value: data["after-rate"] }],
      // Overrides
      field_ds_overtime_bill_ovr_hrs: [
        { value: data.field_ds_overtime_billable_hrs },
      ],

      // Perdium
      field_ds_has_perdiem: [
        { value: data.field_ds_perdiem_total ? true : false },
      ],
      field_ds_perdiem_total: [{ value: data.field_ds_perdiem_total }],
      // PPE
      field_ds_service_ppe_total: [{ value: data.field_ds_service_ppe_total }],
    //  Small Tools Charge
      field_ds_service_stc_prct: [{ value: (data?.field_ds_service_stc_prct ? data.field_ds_service_stc_prct / 100 : 0) }],
      // Lodging
      field_ds_service_lodging_total: [{ value: data.field_ds_service_lodging_total }],

    //  Markup
      field_ds_markup: markup ? [{ value: markup}] : null,
      field_ds_percent_markup: null,

      //Visibility
      ...(typeof data?.field_visibility != 'undefined') && { field_visibility: [{ value: data.field_visibility ? 'phx_client' : null }]},

      //Total
      field_ds_total_hours: [{ value: calcOverallHours(data) }],
      field_ds_total: [{ value: total }],
    };

    // Workarea Reference
    if (data.field_ds_work_area_allocation) {
      params.field_ds_work_area_allocation = [
        { target_id: data.field_ds_work_area_allocation.entity_id },
      ];
    } else {
      params.field_ds_work_area_allocation = [];
    }

    // User Reference
    if (data.field_ds_user.type === "user") {
      params.field_ds_user = [{ target_id: data.field_ds_user.entity_id }];
      params.field_ds_user_temp = [];
    } else if (data.field_ds_user.type === "temporary_user") {
      params.field_ds_user_temp = [{ target_id: data.field_ds_user.entity_id }];
      params.field_ds_user = [];
    }

    if(data.markup_type === 'percent' && Number(data.field_ds_percent_markup) > 0){
      params.field_ds_percent_markup = [{ value: Number(data.field_ds_percent_markup) }];
    }

    try {
      const resultAction = await dispatch(
        postService({ id: division.nid, params })
      );
      unwrapResult(resultAction);
      const sheetNid = resultAction.payload.nid;
      try {
        const breakResultAction = await handleCreateBreak(data, sheetNid);
        unwrapResult(breakResultAction);
      } catch (error) {
        console.error(error.message);
      }

      return { status: "fulfilled", payload: resultAction.payload };
    } catch (error) {
      return { status: "rejected", message: error.message };
    }
  };

  const handleSubmit = async (
    data,
    setSubmitting,
    setMessage,
    successCallback,
    errorCallback,
    setFieldValue
  ) => {
    const responses = [];
    let failed = [];
    let tempSubmissions = {};
    let success = 0;
    setProgress({ total: size(data.entries), obtained: 0, show: true });
    for (let [i, entry] of data.entries.entries()) {
      tempSubmissions = {
        ...tempSubmissions,
        [i]: {
          loading: true,
          response: null,
        },
      };
      setSubmissions(tempSubmissions);
      const response = await handleCreateDailySheet(entry, data.field_ds_date);
      tempSubmissions = {
        ...tempSubmissions,
        [i]: {
          loading: false,
          response: response,
        },
      };
      setSubmissions(tempSubmissions);
      if (response.status === "fulfilled") {
        responses.push(response);
        success += 1;
        setProgress({
          total: size(data.entries),
          obtained: success,
          show: true,
        });
      } else {
        failed.push(entry);
      }
    }

    setProgress({
      total: 0,
      obtained: 0,
      show: false,
    });

    if (size(responses) === size(data.entries)) {
      setSubmissions({});
      successCallback("Added labor successfully.");

      if(props?.fromSummaryCard){
        dispatch(fetchDivisionSnapshot(division.nid));
      }
      if(handleRefresh){
        handleRefresh();
      }
    } else if (size(responses) > 0) {
      errorCallback(
        setMessage,
        { message: "There was an error saving some entries." },
        []
      );
      setFailedSubmissions(failed);
    } else {
      errorCallback(setMessage, { message: "There was an error saving." }, []);
      setFailedSubmissions(failed);
    }
  };

  const handleOnClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListItem
        button
        style={{ paddingLeft: "3rem" }}
        onClick={() => setOpen(true)}
        className={props?.className}
      >
        <ListItemIcon>
          <AiOutlineFileAdd />
        </ListItemIcon>
        <ListItemText primary="Batch Labor" />
      </ListItem>
      <DialogForm
        open={open}
        onClose={handleOnClose}
        maxWidth="lg"
        title="Add Labor"
        progress={progress}
        initialValues={{
          field_ds_date: moment(),
          entries: [
            {
              field_ds_user: null,
              field_ds_date: moment(),
              field_ds_labor_type_text: "",
              field_ds_start_time: "",
              field_ds_breaks_start_time: "",
              field_ds_breaks_end_time: "",
              field_ds_end_time: "",
              field_ds_travel_billable_hrs: 0,
              field_ds_after_hours_bill_hrs: 0,
              field_ds_overtime_billable_hrs: 0,
              field_ds_total_hours: 0,
              field_ds_break_hrs: 0,
              field_ds_after_hours_hrs: 0,
              "service-rate": 0,
              "travel-rate": 0,
              "overtime-rate": 0,
              "after-rate": 0,
              field_ds_has_perdiem: false,
              field_ds_perdiem_total: "",
              field_ds_work_area_allocation: null,
              field_ds_service_ppe_total: 0,
              field_ds_service_stc_prct: 0,
              field_ds_service_lodging_total: 0,
              field_ds_markup: '',
              field_ds_percent_markup: '',
              markup_type: '',
              ...(user.roleType === 'phx_client') && {field_visibility: isDivisionStatusPast(division, divisionStatuses, 'review')},
            },
          ],
        }}
        onSubmit={handleSubmit}
        validationSchema={serviceBatchMilitaryValidationSchema}
      >
        {({ values, errors, isSubmitting, setFieldValue }) => (
          <>
            <BatchContainer>
              <div className="header">
                <DateFormField
                  disableToolbar
                  variant="inline"
                  format="MM/DD/YYYY"
                  id="field_ds_date"
                  label="Date"
                  margin="normal"
                  name="field_ds_date"
                  inputVariant="outlined"
                  size="small"
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
                <div className="button">
                  <TempUsersQuickAdd />
                </div>
              </div>
              <FieldArray
                name="entries"
                render={(arrayHelpers) => (
                  <div>
                    {values.entries.map((entry, i) => (
                      <BatchItem
                        key={i}
                        values={values}
                        arrayHelpers={arrayHelpers}
                        isSubmitting={isSubmitting}
                        loading={loading}
                        error={error}
                        memberNid={memberNid}
                        loadAvailableUsers={loadAvailableUsers}
                        i={i}
                        setFieldValue={setFieldValue}
                        laborTypes={types}
                        rates={types}
                        areas={areas}
                        loadingAreas={loadingAreas}
                        errorAreas={errorAreas}
                        loadWorkareas={loadWorkareas}
                        calcOverallHours={calcOverallHours}
                        entry={entry}
                        nid={nid}
                        submissions={submissions}
                        getUsers={getUsers}
                        getAreas={getAreas}
                        users={users}
                        tmTerms={division?.field_tm_terms?.labor}
                      />
                    ))}
                    <Button
                      component="a"
                      startIcon={<Add />}
                      variant="contained"
                      disableElevation
                      size="small"
                      style={{ marginTop: "1rem", marginBottom: "1rem" }}
                      onClick={() =>
                        arrayHelpers.push({
                          field_ds_user: null,
                          field_ds_date: moment(),
                          field_ds_labor_type_text: "",
                          field_ds_start_time: "",
                          field_ds_breaks_start_time: "",
                          field_ds_breaks_end_time: "",
                          field_ds_end_time: "",
                          field_ds_travel_billable_hrs: 0,
                          field_ds_after_hours_bill_hrs: 0,
                          field_ds_overtime_billable_hrs: 0,
                          field_ds_total_hours: 0,
                          field_ds_break_hrs: 0,
                          field_ds_after_hours_hrs: 0,
                          "service-rate": 0,
                          "travel-rate": 0,
                          "overtime-rate": 0,
                          "after-rate": 0,
                          field_ds_has_perdiem: false,
                          field_ds_perdiem_total: "",
                          field_ds_work_area_allocation: null,
                          field_ds_service_ppe_total: 0,
                          field_ds_service_stc_prct: 0,
                          field_ds_service_lodging_total: 0,
                          field_ds_markup: '',
                          field_ds_percent_markup: '',
                          markup_type: '',
                        })
                      }
                    >
                      Labor
                    </Button>
                  </div>
                )}
              />
            </BatchContainer>
          </>
        )}
      </DialogForm>
    </>
  );
};

Batch.propTypes = {};

export default Batch;
