import React from "react";
import PropTypes from "prop-types";
import { Compliance } from "./Styled";
import { getStatusColor } from "../../utility";

const ComplianceStatus = ({ compliance, compliances }) => {
  const [color, backgroundColor, fontColor] = getStatusColor(compliance);
  const theme = {
    bkg: backgroundColor,
    font: fontColor,
    border: color
  };
  return (
    <Compliance theme={theme}>
      {compliances.find(s => s.machine_name === compliance)
        ? compliances.find(s => s.machine_name === compliance).label
        : ""}
    </Compliance>
  );
};

const { string, array } = PropTypes;
ComplianceStatus.propTypes = {
  compliance: string,
  compliances: array
};

export default ComplianceStatus;
