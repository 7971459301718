import styled from "styled-components";
import constants from "../constants";

export const Actions = styled.div`
  position: absolute;
  right: 2rem;
  top: -1rem;
  background: white;
  border-radius: ${constants.borderRadius};
  border: 1px solid ${constants.colorGray};
  transition: opacity 0.25s;
  opacity: 0;
  pointer-events: none;
  padding: 0.125rem 0.5rem;
  z-index: 1;

  & > button {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 0.25rem 1.25rem;
  transition: background-color 0.25s;
  margin-bottom: 1rem;
  border-left: 4px solid
    ${({ commentType }) =>
      commentType && commentType === "activity_log"
        ? constants.colorSecondary
        : "transparent"};
  background-color: ${({ commentType }) =>
    commentType && commentType === "activity_log"
      ? constants.colorGrayLighter
      : "white"};

  color: ${({ commentType }) =>
    commentType && commentType === "activity_log"
      ? "#636066"
      : constants.colorGrayDark};

  &:hover {
    background-color: ${constants.colorGrayLighter};

    ${Actions} {
      opacity: 1;
      pointer-events: all;
    }
  }
`;

export const Content = styled.div`
  margin-left: 1rem;
  width: 100%;
  position: relative;

  & > span {
    font-size: 0.875rem;
  }
`;

export const Heading = styled.h6`
  font-size: 1.25rem;
  font-weight: 600;
  position: relative;
  margin: 0 0 0.25rem 0;
  padding: 0;
`;

export const Subject = styled.h6`
  font-weight: 600;
  font-size: 1rem;
  margin: 0 0 0.5rem 0;
  padding: 0;
`;
export const Clients = styled.h5`
  font-size: 0.9rem;
  margin: 0 0 0.5rem 0.5rem;
  padding: 0;
`;

export const Body = styled.p`
  font-size: 1.125rem;
  line-height: 1.25;
  padding: 0;
  margin: 0;
`;

export const Subtext = styled.span`
  margin-left: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${constants.colorGrayMedium};
`;

export const Chips = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.33rem;

  & > a {
    margin-right: 0.33rem;
    margin-bottom: 0.33rem;

    &:hover {
      text-decoration: none;
    }
  }
`;

export const Image = styled.div`
  display: inline-flex;
  width: 200px;
  height: auto;
  margin-top: 1rem;
  margin-right: 1rem;

  & > img {
    max-width: 200px;
    width: 100%;
    height: auto;
  }
`;
