import styled from "styled-components";
import constants from "../constants";

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
`;

export const Priority = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  color: ${constants.colorRed};
`;

export const Actions = styled.div`
  display: flex;
  transition: opacity 0.25s;
  opacity: 1;
  pointer-events: all;
  padding: 0.125rem 0.5rem;
`;

export const Container = styled.div`
  min-width: 358px;
  max-width: 485px;
  padding: 1rem;

  &:hover {
    ${Actions} {
      opacity: 1;
      pointer-events: all;
    }
  }
`;

export const Chips = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.33rem;

  & > .MuiChip-root {
    margin-right: 0.33rem;
    margin-bottom: 0.33rem;

    &:hover {
      text-decoration: none;
    }
  }
`;

export const Dates = styled.div`
  display: flex;
  align-items: flex-end;

  & > .MuiFormControl-root {
    width: 95px;
    margin-right: 0.75rem;
  }

  & > span {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 52px;
    margin-left: 0.25rem;
    margin-right: 1rem;
  }
`;

export const Times = styled.div`
  display: flex;
  align-items: flex-end;

  & > .MuiAutocomplete-root {
    width: 75px;
    margin-right: 0.75rem;

    & .MuiInputBase-root {
      padding-right: 0 !important;

      & .MuiAutocomplete-endAdornment {
        display: none;
      }
    }
  }

  & > span {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 52px;
    margin-left: 0.25rem;
    margin-right: 1rem;
  }
`;

export const Reminder = styled.div`
  padding-top: 1rem;
  display: flex;
  align-items: center;
  color: ${constants.colorGrayMedium};
`;

export const FormActions = styled.div`
  padding-top: 2rem;
  display: flex;

  & > .MuiButtonBase-root {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  background: ${constants.colorGrayLighter};
  border-top: 1px solid ${constants.colorGrayLight};
  padding: 0.5rem 0;
`;
