import React from "react";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";
import { Delete } from "@mui/icons-material";

import constants from "../../components/constants";

const OnHover = styled.div`
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${constants.colorGrayDark};
`;

const Container = styled.div`
  width: 100px;
  height: 100px;
  border-radius: ${constants.borderRadius};
  border: 1px solid ${constants.colorGray};
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${({ imgSrc }) => imgSrc});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 0.5rem;
  cursor: pointer;
  position: relative;

  &:hover ${OnHover} {
    opacity: 1;
  }
`;

const Spinner = styled.div`
  border-radius: 50%;
  background: white;
  padding: 3px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
`;

const PlaceholderFile = ({ data, removeFile }) => {
  const { status, file, id } = data;
  const url = URL.createObjectURL(file);

  const handleRemoveFile = () => {
    removeFile(id);
  };

  return (
    <Container imgSrc={url} onClick={handleRemoveFile}>
      <OnHover>
        <Delete />
      </OnHover>
      {status === 1 && (
        <Spinner>
          <CircularProgress size={18} />
        </Spinner>
      )}
    </Container>
  );
};

PlaceholderFile.propTypes = {};

export default PlaceholderFile;
