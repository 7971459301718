
import React, { useState } from "react";
import { useSelector } from "react-redux";

import Avatar from "common/Avatar";
import {
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import { getDivisionDataSelector, } from "features/Job/jobSlice";
import TimeClockDialog from "../TimeClockDialog";
import TimeCardLineItem from "./TimeCardLineItem";
import { BsArrowRight } from "react-icons/bs";
import { size } from "lodash";
import Dialog from "common/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import ServiceAddEdit from "features/Service/forms/ServiceAddEdit";
import { ServiceTotalRow } from "features/DailySheets/components/SummaryCard";
import { AiOutlineFileAdd } from "react-icons/ai";


const TimeCardUser = ({
  user,
  summary,
  onUserClose,
  isUserSummary,
  handleRefresh,
}) => {
  const division = useSelector(getDivisionDataSelector);
  const [open, setOpen] = useState(false);
  const timeClockActive = (size(division) ? division?._processed?.time_clock_active : false);
  const timeClockManager = (timeClockActive && division?._processed?.time_clock_manage);
  const browser = useSelector((state) => state.browser);

  const userDetailClose = () => {
    setOpen(false);
    handleRefresh();
  };

  return (
    <>
      <div className={`time-clock card-user ${!summary ? 'detail' : ''}`}>
        {!isUserSummary &&
        <div
          className={`user-heading ${summary ? 'summary' : ''}`}
          onClick={() => {
            if(summary){
              setOpen(true);
            }
          }}
        >
          <div className="avatar">
            <Avatar
              title={`${user?.ds_user_full_name}`}
              link={user._files?.user_profile_pic_medium ? user._files?.user_profile_pic_medium : null}
              size={browser.is.medium || browser.lessThan.medium ? "medium" : "large"}
            />
          </div>
          <div className="info">
            <Typography variant="h5">{user.ds_user_full_name}</Typography>
            <div className="sub-info">
              <Typography variant="subtitle2">{user.ds_user_uid ? `Account User` : `Offline User`}</Typography>
              {size(user.totals) > 0 &&
                <div className="totals"><ServiceTotalRow serviceTotal={user.totals} totalsOnly /></div>
              }
            </div>
          </div>

          {timeClockManager &&
            <div
              className="manual-entry-add"
              onClick={(e) => e.stopPropagation()}
            >
              <ServiceAddEdit
                handleRefresh={handleRefresh}
                icon={
                  <Tooltip title="Add Labor Entry">
                    <IconButton size="medium" edge={false}>
                      <AiOutlineFileAdd />
                    </IconButton>
                  </Tooltip>
                }
                initUser={{
                  name: user.ds_user_full_name,
                  entity_id: user.ds_user_uid ? user.ds_user_uid : user?.ds_user_temp_nid,
                  type: user.ds_user_uid ? "user" : "temporary_user",
                  uid: user.ds_user_uid,
                  service_types: (user?.user_service_types ? user.user_service_types : []),
                }}
              />
            </div>
          }
          {!summary &&
            <div className="detail-close">
              <IconButton
                onClick={onUserClose}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </div>
          }
        </div>
        }

        <div className="cards">
          {user.time_cards
            .filter((card, j) => !summary || j < 4)
            .map((card, j) =>
              <TimeClockDialog
                divisionHash={division.field_public_hash}
                serviceHash={card.hash}
                onClose={() => {
                  if(summary && card.status !== 'complete'){
                    handleRefresh();
                  }
                }}
                key={j}
              >
                <TimeCardLineItem
                  card={card}
                  summary={summary}
                  handleRefresh={handleRefresh}
                  showAdminButtons={timeClockManager}
                />
              </TimeClockDialog>
          )}
        </div>
        {(summary && size(user.time_cards) > 4) &&
          <div
            className="more-cards"
            onClick={() => setOpen(true)}
          >
            <span className="text">
              More Time Cards <BsArrowRight className="icon" />
            </span>
          </div>
        }
      </div>
      {summary &&
        <Dialog
          open={open}
          onClose={userDetailClose}
        >
          <TimeCardUser
            user={user}
            onUserClose={userDetailClose}
            timeClockManager={timeClockManager}
            handleRefresh={handleRefresh}
          />
        </Dialog>
      }
    </>
  );
};

TimeCardUser.propTypes = {};

export default TimeCardUser;
