import styled from "styled-components";

export const Container = styled.header`
  background-color: white;
  border-bottom: 1px solid var(--color-gray-medium);
  transition: padding 0.25s;
  padding: ${({ collapse }) => (collapse ? "0.5rem 1.25rem" : "1.25rem")};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
