import React from "react";
import chroma from "chroma-js";
import "chartjs-plugin-annotation";
import "chartjs-plugin-datalabels";
import { Doughnut } from "react-chartjs-2";
import { Typography } from "@mui/material";
import constants from "components/constants";

import { ChartSkeleton } from "../Skeletons";
import { Loader } from "./Styled";

const analyticsIcon = `${process.env.PUBLIC_URL}/images/icons/icon-analytics--large.svg`;

const options = {
  responsive: true,
  maintainAspectRatio: false,
  circumference: 1 * Math.PI,
  rotation: -1 * Math.PI,
  legend: {
    position: "bottom",
  },
  layout: {
    padding: {
      top: 20,
      bottom: 10,
      right: 20,
      left: 20,
    },
  },
  cutoutPercentage: 60,
  tooltips: {
    backgroundColor: constants.colorGrayLightest,
    titleFontColor: constants.colorGrayDarker,
    bodyFontColor: constants.colorGrayDark,
    bodySpacing: 4,
    xPadding: 12,
    mode: "nearest",
    intersect: 0,
    position: "nearest",
  },
  scales: {
    yAxes: [
      {
        display: 0,
        ticks: {
          display: false,
        },
        gridLines: {
          drawBorder: false,
          zeroLineColor: "transparent",
          color: constants.colorWhite + "80",
        },
      },
    ],
    xAxes: [
      {
        display: 0,
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: constants.colorWhite + "1a",
          zeroLineColor: "transparent",
        },
        ticks: {
          display: false,
        },
      },
    ],
  },
};

const SemiDonutChart = ({ chartData, customColors, loading }) => {
  const data = (canvas) => {
    const numTotals = chartData.labels.length;
    let gradient = chroma
      .scale([constants.colorBlueLight, constants.colorBlueDarker])
      .mode("lch")
      .colors(numTotals);

    if (customColors) gradient = customColors;

    return {
      datasets: [
        {
          datalabels: {
            display: false,
          },
          data: chartData.data,
          backgroundColor: chartData.labels.map((label, i) => gradient[i]),
        },
      ],
      labels: chartData.labels,
    };
  };

  return loading ? (
    <Loader>
      <ChartSkeleton />
    </Loader>
  ) : !chartData.data.length ? (
    <Loader>
      <img src={analyticsIcon} alt="No Data to display" />
      <Typography variant="subtitle1" color="textSecondary">
        No Data to Display
      </Typography>
    </Loader>
  ) : (
    <Doughnut data={data} options={options} />
  );
};

SemiDonutChart.propTypes = {};

export default SemiDonutChart;
