import React from "react";
import PropTypes from "prop-types";

import { Container } from "./Styled";

const NestedTabs = ({ children, onChange, value }) => {
  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, { onClick: onChange, active: value })
  );

  return <Container>{childrenWithProps}</Container>;
};

const { oneOfType, node, arrayOf, number, func } = PropTypes;
NestedTabs.propTypes = {
  children: oneOfType([arrayOf(node), node]),
  onChange: func,
  value: number
};

export default NestedTabs;
