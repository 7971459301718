import React, { useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { ListItem, ListItemIcon, ListItemText, Hidden } from "@mui/material";
import { Link } from "react-router-dom";
import { MdAdd } from "react-icons/md";

import Search from "features/Search";
import User from "./components/User";
import HubIndicator from "features/Hub/components/HubIndicator";
import RecentActivity from "features/RecentActivity";
import { Container, Logo } from "./Styled";
import { IconButtonDropdown } from "common/ButtonDropdown";
import MobileNav from "./components/MobileNav";
import { ReactComponent as IconJob } from "assets/images/icons/icon-job.svg";
import { ReactComponent as IconProvider } from "assets/images/icons/icon-provider.svg";
import { ReactComponent as IconCustomer } from "assets/images/icons/icon-customer.svg";

const icon = `${process.env.PUBLIC_URL}/images/helix_icon.svg`;
const logo = `${process.env.PUBLIC_URL}/images/helix_logo--white.svg`;
const logoBlack = `${process.env.PUBLIC_URL}/images/helix_logo--black.svg`;

const Header = ({ isAuthenticated, openMainDrawer, onCloseMainDrawer, isPublic }) => {
  const dropdownRef = useRef();
  const permissions = useSelector((state) => state.auth.user.data._processed);

  const handleCloseDropdown = () => {
    if (dropdownRef.current) {
      dropdownRef.current.close();
    }
  };

  const canCreate = useMemo(() => {
    return (
      permissions?.job_create_permission ||
      permissions?.member_create_permission ||
      permissions?.customer_create_permission
    );
  }, [permissions]);

  return (
    <Container>
      <div className="left">
        <Logo to="/">
          <img className={`mark ${isPublic ? 'is-public' : ''}`} src={icon} alt="Helix" data-test="logoMark" />
          <img className={`copy ${isPublic ? 'is-public' : ''}`} src={logo} alt="Helix" data-test="logoCopy" />
          <img className={`print ${isPublic ? 'is-public' : ''}`} src={logoBlack} alt="Helix" data-test="logoCopy" />
        </Logo>
      </div>
      {isAuthenticated && (
        <div className="right">
          <Search />
          {canCreate && (
            <IconButtonDropdown
              ref={dropdownRef}
              icon={MdAdd}
              tooltip="Actions"
              color="inherit"
              className="text-white"
              showArrow
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <div>
                {permissions?.job_create_permission && (
                  <ListItem
                    button
                    component={Link}
                    to="/job/add"
                    onClick={handleCloseDropdown}
                  >
                    <ListItemIcon>
                      <MdAdd />
                    </ListItemIcon>
                    <ListItemText primary={<><IconJob className="item-icon" /> Job Division</>} />
                  </ListItem>
                )}
                {permissions?.customer_create_permission && (
                  <ListItem
                    button
                    component={Link}
                    to="/customer/add"
                    onClick={handleCloseDropdown}
                  >
                    <ListItemIcon>
                      <MdAdd />
                    </ListItemIcon>
                    <ListItemText primary={<><IconCustomer className="item-icon" /> Customer</>} />
                  </ListItem>
                )}
                {permissions?.member_create_permission && (
                  <ListItem
                    button
                    component={Link}
                    to="/member/add"
                    onClick={handleCloseDropdown}
                  >
                    <ListItemIcon>
                      <MdAdd />
                    </ListItemIcon>
                    <ListItemText primary={<><IconProvider className="item-icon" /> Provider</>} />
                  </ListItem>
                )}
              </div>
            </IconButtonDropdown>
          )}
          <HubIndicator open={openMainDrawer} onClose={onCloseMainDrawer}  />
          <RecentActivity open={openMainDrawer} onClose={onCloseMainDrawer} />
          <User />
          <Hidden mdUp implementation="css">
            <MobileNav />
          </Hidden>
        </div>
      )}
    </Container>
  );
};

Header.propTypes = {};

export default Header;
