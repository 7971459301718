import React, { useRef, useEffect } from "react";
import { connect } from "react-redux";
import {
  Typography,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

import Form from "./Form";
import { Actions } from "../Styled";
import ButtonLoader from "common/ButtonLoader";
import { removePathFromUrl } from "../../utility";
import { snackbarEnter } from "../../actions/snackbarActions";
import { formatError, replaceErrorResponseString } from "../../utility";

const DialogForm = ({
  url,
  path,
  onSubmit,
  submitText,
  submitDisabled,
  initialValues,
  validationSchema,
  children,
  extraAction,
  showSnackbar,
  loading,
  onExit,
  validate,
  ...rest
}) => {
  const history = useHistory();
  const formRef = useRef();
  const _isMounted = useRef(true);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [isSubmitting, setSubmitting] = React.useState(false);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  const handleSubmit = () => {
    setSubmitting(true);
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleClose = () => {
    if (_isMounted.current) {
      setSubmitting(false);
      const parsedUrl = removePathFromUrl(url, path, true);
      if (onExit) {
        onExit();
      }
      history.push({
        pathname: parsedUrl,
        search: history.location.search,
      });
    }
  };

  const handleSuccess = (msg, response, cb) => {
    if (_isMounted.current) {
      setSubmitting(false);
      if (response && response.data && response.data._warning_messages && msg) {
        showSnackbar({
          msg: response.data._warning_messages.map((m, i) => (
            <Typography key={i}>{m}</Typography>
          )),
          kind: "warning",
        });
        handleClose();
      } else if (msg) {
        showSnackbar({
          msg,
          kind: "positive",
        });
        handleClose();
      }
    }
  };

  const handleError = (setMessage, response, fields) => {
    if (_isMounted.current) {
      setSubmitting(false);
      if (response) {
        const errorMessage = formatError(response);
        errorMessage.msg = replaceErrorResponseString(errorMessage.msg, fields);
        setMessage(errorMessage);
      }
    }
  };

  const handleSubmitError = () => {
    if (_isMounted.current) {
      setSubmitting(false);
    }
  };

  return (
    <>
      <DialogContent>
        <Form
          innerRef={formRef}
          onSubmit={onSubmit}
          disablePadding
          validationSchema={validationSchema}
          initialValues={initialValues}
          successCallback={handleSuccess}
          errorCallback={handleError}
          onSubmitError={handleSubmitError}
          onSubmitting={(isFormSubmitting) => setSubmitting(isFormSubmitting)}
          validate={validate}
          {...rest}
        >
          {children}
        </Form>
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: isMobile ? "flex-end" : "flex-start",
          padding: "0 1.25rem 1.25rem 1.25rem",
        }}
      >
        <Actions>
          <Button onClick={handleClose} size="small" component="a">
            Cancel
          </Button>
          {extraAction && extraAction}
          <ButtonLoader
            variant="contained"
            color="primary"
            type="submit"
            isSubmitting={isSubmitting}
            disableElevation
            disabled={loading || submitDisabled}
            size="small"
            onClick={handleSubmit}
          >
            {submitText}
          </ButtonLoader>
        </Actions>
      </DialogActions>
    </>
  );
};

DialogForm.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
  showSnackbar: (data) => dispatch(snackbarEnter(data)),
});

export default connect(null, mapDispatchToProps)(DialogForm);
