import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { MenuItem, ListItemIcon, Typography } from "@mui/material";
import { FaUserTimes } from "react-icons/fa";

import ConfirmationDialog from "components/ConfirmationDialog";
import { deleteMemberInsuranceAgent } from "../memberSlice";

const Delete = ({ agent, handleClose }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleDelete = () => {
    dispatch(deleteMemberInsuranceAgent({ id: agent.nid, agent }));
    setOpen(false);
  };

  const onClose = () => {
    setOpen(false);
    handleClose();
  };

  return (
    <>
      <MenuItem
        onClick={() => setOpen(true)}
        style={{ color: "var(--color-red)" }}
      >
        <ListItemIcon>
          <FaUserTimes />
        </ListItemIcon>
        <Typography variant="inherit">Remove</Typography>
      </MenuItem>
      <ConfirmationDialog
        open={open}
        onClose={onClose}
        onSubmit={handleDelete}
        title="Are you sure?"
        body="Are you sure you want to delete this item?"
        submitButton="Delete"
      />
    </>
  );
};

Delete.propTypes = {};

export default Delete;
