import { createSlice, createDraftSafeSelector } from "@reduxjs/toolkit";

const namespace = "alert";

const layoutSlice = createSlice({
  name: namespace,
  initialState: {
    rightAsideOpen: localStorage.getItem('right_aside_pinned') === "true",
    rightAsideDrawerOpen: false,
  },
  reducers: {
    setRightAsideOpen: (state, action) => {
      state.rightAsideOpen = action.payload;
    },
    setRightAsideDrawerOpen: (state, action) => {
      state.rightAsideDrawerOpen = action.payload;
    },
  },
  extraReducers: {},
});

// Custom selectors
const selectSelf = (state) => state;
export const getRightAsideOpenSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.layout.rightAsideOpen
);
export const getRightAsideDrawerOpenSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.layout.rightAsideDrawerOpen
);

export const { setRightAsideOpen, setRightAsideDrawerOpen, } = layoutSlice.actions;

export default layoutSlice.reducer;
