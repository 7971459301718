import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchCustomerByNid,
  getCustomerDataSelector,
  getCustomerLoadingSelector,
} from "features/Customer/customerSlice";
import Information from "features/Customer/components/Information";
import { DefaultLoader } from "common/Loader";

const CustomerSnapshot = ({ nid, name }) => {
  const dispatch = useDispatch();
  const customer = useSelector(getCustomerDataSelector);
  const loading = useSelector(getCustomerLoadingSelector);

  useEffect(() => {
    const promise = dispatch(fetchCustomerByNid(nid));

    return () => {
      promise.abort();
    };
  }, [dispatch, nid]);

  return (
    <div>
      {loading ? <DefaultLoader /> : <Information customer={customer} inSnapshot />}
    </div>
  );
};

export default CustomerSnapshot;
