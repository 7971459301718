import * as Yup from "yup";

export const addAccountingSchema = Yup.object().shape({
  "provider-fee-base": Yup.number().typeError("Must be a number"),
  tax: Yup.number().typeError("Must be a number"),
  travel: Yup.number().typeError("Must be a number"),
  mobilization: Yup.number().typeError("Must be a number"),
  "markup-fee": Yup.number().typeError("Must be a number"),
  "markup-percent": Yup.number().typeError("Must be a number").nullable(),
  "provider-override": Yup.number().typeError("Must be a number"),
  "technology-override": Yup.number().typeError("Must be a number"),
  "deposit": Yup.number().typeError("Must be a number"),
  "file-override": Yup.number().typeError("Must be a number"),
  subcontractor: Yup.number().typeError("Must be a number"),
  "subcontractor-markup": Yup.number().typeError("Must be a number"),
  "subcontractor-markup-perc": Yup.number()
    .typeError("Must be a number")
    .nullable(),
  "third-party": Yup.array().of(
    Yup.object().shape({
      vendor: Yup.string(),
      amount: Yup.number().typeError("Must be a number"),
    })
  ),
  revenues: Yup.array().of(
    Yup.object().shape({
      title: Yup.string(),
      amount: Yup.number().typeError("Must be a number"),
      account: Yup.string(),
      type: Yup.string(),
    })
  ),
});
