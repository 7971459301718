import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  border: 1px solid ${({ border }) => border};
  background: ${({ bkg }) => bkg};
  color: ${({ color }) => color};
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 600;
  padding: ${({ size }) => (size === "small" ? "0.5rem" : "0.125rem 0.5rem")};
`;

export const Indicator = styled.span`
  width: 1rem;
  height: 1rem;
  margin-right: 0.75rem;
  border-radius: 50%;
  border: 1px solid ${({ border }) => border};
  background: ${({ bkg }) => bkg};
  display: block;
`;

export const IndicatorWrapper = styled.div`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 1.125rem;
  padding: ${({ size }) => (size === "small" ? "0.5rem" : "0")};
`;

export const Compliance = styled.div`
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
  color: ${({ theme }) => theme.font};
  border: 1px solid ${({ theme }) => theme.border};
  background: ${({ theme }) => theme.bkg};
  padding: 0.25rem 0.5rem;
  border-radius: 24px;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 600;
`;
