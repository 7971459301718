import styled from "styled-components";
import { Link } from "react-router-dom";
import { mediaUp } from "../../components/mixins";

export const Container = styled.header`
  height: var(--page-header-height);
  background: var(--gradient-black);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 0px 2px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: var(--site-header-z-index);
  display: flex;
  align-items: center;

  .left {
    display: flex;
    justify-content: flex-start;
    padding: 0 0 0 1.25rem;
    align-items: center;
  }

  .center {
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 0 1.25rem;
    align-items: center;
  }

  .right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding: 0 1.25rem;
    align-items: center;
    color: white;
  }

  // .MuiButtonBase-root {
  //   margin: 0 0.5rem;
  // }
`;

export const Logo = styled(Link)`
  // width: 260px;
  display: flex;
  align-items: center;

  .mark {
    height: 30px;

    ${mediaUp.md`display: none;`}

    &.is-public{ display: none; }

    @media print{
      display: none;
      &.is-public{ display: none; }
    }
  }

  .copy {
    height: 24px;
    // margin-left: 10px;
    display: none;

    ${mediaUp.md`display: block;`}

    &.is-public{ display: block; }

    @media print{
      display: none;
      ${mediaUp.md`display: none;`}
      &.is-public{ display: none; }
    }
  }

  .print {
    height: 30px;
    display: none;

    @media print{
      display: block;
    }
  }
`;
