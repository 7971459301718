import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import {
  RadioGroup,
  FormLabel,
  FormHelperText,
  FormControl,
  FormControlLabel,
  Radio,
} from "@mui/material";

const RadioFormField = ({
  aria,
  label,
  options,
  margin,
  disabled,
  style,
  wrapperStyle,
  labelStyle,
  orientation="vertical",
  helperText,
  componentType,
  componentLabelType,
  ...props
}) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <FormControl component={componentType ? componentType : "fieldset"} error={!!errorText} margin={margin} style={wrapperStyle}>
      <FormLabel component={componentLabelType ? componentLabelType : "legend"} style={labelStyle}>{label}</FormLabel>
      <RadioGroup
        aria-label={aria}
        {...field}
        {...props}
        style={{
          flexDirection:
            orientation === "vertical"
              ? "column"
              : orientation === "horizontal"
              ? "row"
              : "column",
          ...style,
        }}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
            disabled={(disabled || option.disabled ? true : false)}
          />
        ))}
      </RadioGroup>
      <FormHelperText error={!!errorText} id="filled-weight-helper-text">
        {errorText || helperText}
      </FormHelperText>
    </FormControl>
  );
};

const { string, array, bool, oneOf } = PropTypes;
RadioFormField.propTypes = {
  aria: string,
  label: string,
  options: array,
  margin: string,
  disabled: bool,
  orientation: oneOf(["horizontal", "vertical"]),
};

export default RadioFormField;
