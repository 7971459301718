import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 20px;
  right: 0;
  bottom: 0;
  z-index: 1000;
`;

export const Container = styled.div`
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
  overflow: hidden;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.5);
  background: var(--color-gray-lightest);
  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  background: var(--gradient-black);
  height: 51px;
  width: 100%;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
`;
