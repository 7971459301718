import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./Paper.scss";

const Paper = ({
  children,
  squareTop=false,
  variant="elevation",
  square=false,
  color="default",
  margin="normal",
  className,
  hidden=false,
  ...rest
}) => {
  const classes = {
    root: classNames(
      "paper",
      { [className]: className },
      { "paper--no-gutter": margin === "none" },
      { "paper--square": square },
      { "paper--square-top": squareTop },
      { "paper--outlined": variant === "outlined" },
      `paper--${color}`
    ),
  };
  return (
    <div hidden={hidden} className={classes.root} {...rest}>
      {children}
    </div>
  );
};

const { bool, oneOf } = PropTypes;
Paper.propTypes = {
  squareTop: bool,
  square: bool,
  fullWidth: bool,
  variant: oneOf(["outlined", "elevation"]),
  color: oneOf(["primary", "secondary", "default", "tertiary"]),
  margin: oneOf(["normal", "none", "dense"]),
  hidden: bool,
};

export default Paper;
