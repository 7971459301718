import React from "react";
import { connect } from "react-redux";
import { IconButton, Tooltip } from "@mui/material";
import styled from "styled-components";
import GetAppIcon from "@mui/icons-material/GetApp";

import FileIcon from "../FileIcon";
import { Details, Heading } from "./Styled";
import { downloadFile } from "../../actions/files/fileActions";

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 1rem 0;
`;

const ReceiptFile = ({ name, url, getFile }) => {
  return (
    <Container>
      <FileIcon filename={name} />
      <Details>
        <Heading>{name}</Heading>
      </Details>
      {url && (
        <Tooltip title="Download File">
          <IconButton onClick={() => getFile(url, name)} size="large">
            <GetAppIcon />
          </IconButton>
        </Tooltip>
      )}
    </Container>
  );
};

ReceiptFile.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
  getFile: (path, name, responseType) =>
    dispatch(downloadFile(path, name, responseType)),
});

export default connect(null, mapDispatchToProps)(ReceiptFile);
