import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import { VscCalendar } from "react-icons/vsc";
import { Link, useRouteMatch } from "react-router-dom";
import moment from "moment";
import styled from "styled-components";
import { size } from "lodash";

import Card, { CardContent, CardHeader } from "common/Card";

import { fetchJobTasks, tasksSelectors } from "features/Tasks/tasksSlice";

// const Card = styled.div`
//   box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
//   border-radius: 6px;
//   background: white;
//   margin-bottom: 2rem;
//   padding: 1rem;
// `;

const Container = styled(Link)`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  background-color: var(--color-blue);
  background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  border-radius: 4px;
  padding: 0.5rem;
  color: white;
  cursor: pointer;
  text-decoration: none;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Icon = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 15px;
  background: white;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-blue);
  margin-right: 1rem;
`;

const isToday = (start, end) => {
  const startDate = moment(Number(start));
  return moment().isSame(startDate, "day");
};

const Task = ({ task }) => {
  const { url } = useRouteMatch();

  return (
    <Container to={`${url}/tasks`}>
      <Icon>
        <VscCalendar />
      </Icon>
      <Typography>{task.title}</Typography>
    </Container>
  );
};

const TodaysTasks = ({ nid }) => {
  const dispatch = useDispatch();
  const tasks = useSelector(tasksSelectors.selectAll);

  useEffect(() => {
    let promise;
    if (nid) {
      promise = dispatch(fetchJobTasks({ id: nid }));
    }

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [dispatch, nid]);

  const todaysTasks = tasks.filter((t) => isToday(t.start_time, t.end_time));
  if (!size(todaysTasks) > 0) return null;
  return (
    <Card>
      <CardHeader title="Todays Tasks" />
      <CardContent>
        {todaysTasks.map((task) => (
          <Task task={task} />
        ))}
      </CardContent>
    </Card>
  );
};

TodaysTasks.propTypes = {};

export default TodaysTasks;
