import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FieldArray } from "formik";
import { Add, HighlightOff } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { size } from "lodash";
import styled from "styled-components";

import { DialogForm } from "common/Dialog";
import { TextFormField } from "common/Fields";
import Button from "common/Button";
import { getMemberDataSelector } from "features/Member/memberSlice";
import { postTemporaryUser } from "../usersSlice";
import { unwrapResult } from "@reduxjs/toolkit";

export const FormRow = styled.div`
  display: flex;
  align-items: center;
`;

const TempUsersQuickAdd = ({...props}) => {
  const dispatch = useDispatch();
  const member = useSelector(getMemberDataSelector);
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState({
    total: 0,
    obtained: 0,
    show: false,
  });

  const handleSubmit = async (
    data,
    setSubmitting,
    setMessage,
    successCallback,
    errorCallback
  ) => {
    setSubmitting(true);
    let successes = 0;
    for (let user in data.users) {
      const params = {
        title: [
          {
            value: data.users[user].first_name.trim() + (data.users[user].last_name.trim() !== '' ? ' ' + data.users[user].last_name.trim() : ''),
          },
        ],
        field_first_name: [
          {
            value: data.users[user].first_name.trim(),
          },
        ],
        field_temp_user_members: [
          {
            target_id: member.nid,
          },
        ],
      };
      if(data.users[user].last_name.trim() !== ''){
        params.field_last_name = [{value: data.users[user].last_name.trim()}];
      }

      if (size(data.users[user])) {
        try {
          const resultAction = await dispatch(postTemporaryUser(params));
          unwrapResult(resultAction);
          setProgress({
            total: size(data.users),
            obtained: progress.obtained + 1,
            show: true,
          });
          successes += 1;
        } catch (err) {
          setMessage({ id: "negative", msg: "Error adding user" });
        }
      }
    }

    setSubmitting(false);
    if (successes === size(data.users)) {
      setProgress({
        total: 0,
        obtained: 0,
        show: false,
      });
      successCallback("Users Added");
    }
  };

  return (
    <>
      <Button
        startIcon={<Add />}
        size="small"
        onClick={() => setOpen(true)}
        style={{ marginRight: "1rem" }}
        color="tertiary"
        variant="contained"
        disableElevation
        {...props}
      >
        Temp Users
      </Button>
      <DialogForm
        maxWidth="xs"
        open={open}
        onClose={() => setOpen(false)}
        title="Add Temporary Users"
        initialValues={{
          users: [""],
        }}
        onSubmit={handleSubmit}
        progress={progress}
      >
        {({ values, errors, isSubmitting, setFieldValue }) => {
          return (
            <FieldArray
              name="users"
              render={(arrayHelpers) => (
                <>
                  {values.users.map((revenue, i) => (
                    <FormRow key={i}>
                      <div className="flex">
                        <div className="flex-[0_1_50%]">
                          <TextFormField
                            fullWidth
                            htmlFor={`users.${i}.first_name`}
                            name={`users.${i}.first_name`}
                            label="First Name"
                            margin="normal"
                            disabled={isSubmitting}
                            size="small"
                            variant="outlined"
                          />
                        </div>
                        <div className="ml-4 flex-[0_1_50%]">
                          <TextFormField
                            fullWidth
                            htmlFor={`users.${i}.last_name`}
                            name={`users.${i}.last_name`}
                            label="Last Name"
                            margin="normal"
                            disabled={isSubmitting}
                            size="small"
                            variant="outlined"
                          />
                        </div>
                      </div>
                      {/* <TextFormField
                        fullWidth
                        htmlFor={`users.${i}`}
                        name={`users.${i}`}
                        label="Name"
                        margin="normal"
                        disabled={isSubmitting}
                        size="small"
                        variant="outlined"
                      /> */}
                      <IconButton
                        onClick={() => arrayHelpers.remove(i)}
                        size="small"
                        style={{ marginLeft: "1rem" }}
                        disabled={isSubmitting}
                      >
                        <HighlightOff />
                      </IconButton>
                    </FormRow>
                  ))}
                  <Button
                    component="a"
                    startIcon={<Add />}
                    variant="contained"
                    disableElevation
                    size="small"
                    disabled={isSubmitting}
                    style={{ marginTop: "1rem", marginBottom: "1rem" }}
                    onClick={() => arrayHelpers.push("")}
                  >
                    User
                  </Button>
                </>
              )}
            />
          );
        }}
      </DialogForm>
    </>
  );
};

TempUsersQuickAdd.propTypes = {};

export default TempUsersQuickAdd;
