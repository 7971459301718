import React from "react";
import PropTypes from "prop-types";
import { ClickAwayListener, Typography } from "@mui/material";

class Marker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.lat !== nextProps.lat ||
      this.props.lng !== nextProps.lng ||
      this.state.show !== nextState.show
    );
  }

  handleClick() {
    this.setState({ show: true });
  }

  handleClose() {
    this.setState({ show: false });
  }

  render() {
    const { color, onClick, children, info } = this.props;

    return (
      <ClickAwayListener onClickAway={this.handleClose}>
        <div
          style={{
            cursor: "pointer",
            color: color,
          }}
          onClick={onClick ? onClick : this.handleClick}
        >
          {React.cloneElement(children, {
            style: {
              position: "absolute",
              transform: "translate(-50%, -75%)",
            },
          })}

          {info && (
            <div
              hidden={!this.state.show}
              style={{
                position: "absolute",
                transform: "translate(-50%, -126%)",
                left: "50%",
                top: 0,
                padding: "0.5rem",
                borderRadius: "3px",
                background: "white",
                minWidth: "100px",
                boxShadow: "0 0 12px 0 rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography color="textPrimary" variant="subtitle1">
                {info.name}
              </Typography>
              <Typography color="textSecondary" variant="caption">
                {info.group}
              </Typography>
            </div>
          )}
        </div>
      </ClickAwayListener>
    );
  }
}

const { func, string } = PropTypes;
Marker.propTypes = {
  onClick: func,
  color: string,
};

export default Marker;
