import React, { useState } from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
} from "@mui/material";
import { BsFileEarmarkSpreadsheet } from "react-icons/bs";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ServiceAddEdit from "./ServiceAddEdit";
import { TimeClockQRCode, TimeClockMenuItem } from "components/TimeClock";
import Batch from "./Batch";

const ActionsMenu = (props) => {
  const [expand, setExpand] = useState(false);

  const handleExpand = (type) => (e) => {
    if (type === expand) {
      setExpand(null);
    } else {
      setExpand(type);
    }
  };

  return (
    <>
      <ListItem
        button
        onClick={handleExpand("dailysheets")}
        style={{ paddingLeft: "2rem" }}
        className={`${props.className} ${expand === "dailysheets" ? 'expanded' : ''}`}
      >
        <ListItemIcon>
          <BsFileEarmarkSpreadsheet />
        </ListItemIcon>
        <ListItemText primary="Labor" />
        {expand === "dailysheets" ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={expand === "dailysheets"} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ServiceAddEdit {...props} />
          <Batch {...props} />
          <TimeClockQRCode menuProps={props} />
          <TimeClockMenuItem menuProps={props} />
        </List>
      </Collapse>
    </>
  );
};

ActionsMenu.propTypes = {};

export default ActionsMenu;
