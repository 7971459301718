import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  // DialogContentText,
  DialogActions,
  Button,
  Tooltip,
} from "@mui/material";

import ButtonLoader from "common/ButtonLoader";
import Alert from "common/Alert";
import { SelectFormField } from "common/Fields";
import { BsPerson } from "react-icons/bs";
import { BsPencilSquare } from "react-icons/bs";

const TimeClockLaborType = ({
  // open,
  onClose,
  message,
  body,
  submitForm,
  isSubmitting,
  submitButton,
  serviceTypes,
  timeClock,
  values,
  errors,
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
    {timeClock?.service_type &&
      <div className="service-type">
        <BsPerson className="edit-icon medium" />
        <span className="label">Labor Type:</span> {timeClock?.service_type}
        {(!timeClock.read_only && timeClock.service_status !== 'complete') &&
          <Tooltip title="Update Labor Type">
            <><BsPencilSquare className="edit-icon" onClick={() => setOpen(true)} /></>
          </Tooltip>
        }
      </div>
    }
    <Dialog
      open={open || (!timeClock.read_only && Boolean(timeClock?.labor_type_needed))}
      onClose={onClose}
      aria-labelledby="time-clock-labor-type-dialog-title"
      aria-describedby="time-clock-labor-type-dialog-description"
      fullWidth
      maxWidth="sm"
      className="time-clock dialog"
    >
      <DialogTitle id="time-clock-labor-type-dialog-title" className="dialog-title"><BsPerson className="edit-icon large" /> Please select a Labor Type</DialogTitle>
      <DialogContent>
        {message && <Alert kind={message.id}>{message.msg}</Alert>}
        <div id="time-clock-labor-type-dialog-description" className="dialog-content">
          <TimeClockLaborTypeInline
            timeClock={timeClock}
            isSubmitting={isSubmitting}
            errors={errors}
          />
        </div>
      </DialogContent>
      <DialogActions>
        {open &&
          <Button onClick={() => setOpen(false)} disabled={isSubmitting}>
            Cancel
          </Button>
        }
        <ButtonLoader
          variant="contained"
          color="primary"
          type="submit"
          isSubmitting={isSubmitting}
          disabled={isSubmitting}
          disableElevation
          size="small"
          onClick={() => {
            submitForm('select_labor_type');
            setOpen(false)
          }}
        >
          Select
        </ButtonLoader>
      </DialogActions>
    </Dialog>
    </>
  );
};

export const TimeClockLaborTypeInline = ({
  // open,
  onClose,
  message,
  body,
  submitForm,
  isSubmitting,
  submitButton,
  serviceTypes,
  timeClock,
  values,
  errors,
  inTimeClock
}) => {

  return (
    (!inTimeClock || (!timeClock?.service_type && timeClock.service_status !== "complete")) &&
      <div className="service-type inline-field">
        <BsPerson className="edit-icon large" />
        <SelectFormField
          fullWidth
          name="service_type"
          label="Labor Type"
          disabled={isSubmitting}
          options={timeClock?.labor_type_options ? timeClock.labor_type_options.map((name, i) => ({value: name, label: name})) : []}
          variant="filled"
          // size="small"
          required={!inTimeClock}
          error={errors?.service_type}
        />
      </div>
  );
};

TimeClockLaborType.propTypes = {};

export default TimeClockLaborType;

export const timeClockValidateLaborType = (errors, timeClock, values, setSubMessage) => {
  if(timeClock?.labor_type_needed){
    if(!values.service_type || values.service_type === ''){
      errors.service_type = 'Please select a labor type';
      setSubMessage({
        id: "negative",
        msg: errors.service_type,
      });
    }
  }

  return errors;
}
