
export const createColumns = (columns, columnSettings) =>
  columns.map((column) => {
    column.id = column.index;
    column = {
      ...column,
      notSortable: !column.sortable,
      ...columnSettings[column.index],
    };
    return column;
  }
);
