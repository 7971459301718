import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Popover,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  TextField,
  CircularProgress,
} from "@mui/material";
import { Autocomplete } from '@mui/material';
import DeleteIcon from "@mui/icons-material/Delete";
import { isArray, size } from "lodash";

import Avatar from "common/Avatar";
import { useDebounce } from "../../hooks";
import { UsersForm, UsersStaging, Message } from "./Styled";
import { getAvailableUsers } from "../../actions";

const ChatAddUsers = ({
  anchorEl,
  onClose,
  nid,
  onChange,
  stagedUsers,
  assignedUsers,
}) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.app.availableUsers.data.data);
  const loading = useSelector((state) => state.app.availableUsers.loading);
  const error = useSelector((state) => state.app.availableUsers.error);
  const [userQuery, setUserQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const debouncedUser = useDebounce(userQuery, 250);
  const working = loading && open;

  useEffect(() => {
    if (debouncedUser && open) {
      dispatch(getAvailableUsers(nid, 0, { keywords: debouncedUser }));
    } else if (!debouncedUser && open) {
      if (isArray(assignedUsers)) {
        const defaultUsers = [...assignedUsers].filter((user) => user.status);
        setOptions([...defaultUsers]);
      }
    }
  }, [debouncedUser, open, nid, dispatch, assignedUsers]);

  useEffect(() => {
    if (!error && size(users)) {
      const u = users.map((obj) => ({
        name: obj.name,
        uid: obj.uid,
      }));
      setOptions([...u]);
    }
  }, [error, users]);

  const handleRemoveUser = (value) => {
    const u = [...stagedUsers];
    const index = u.findIndex((obj) => obj.uid === value);
    u.splice(index, 1);

    onChange(u);
  };

  const getOptions = () => {
    setOpen(true);
    if (isArray(assignedUsers)) {
      const defaultUsers = [...assignedUsers].filter((user) => user.status);
      setOptions([...defaultUsers]);
    }
  };

  const clearOptions = () => {
    setOpen(false);
    setUserQuery("");
  };

  const handleChange = (event, value) => {
    if (!value) return;
    const index = stagedUsers.findIndex((obj) => obj.uid === value.uid);
    if (index < 0) {
      onChange([...stagedUsers, value]);
    }
  };

  const handleSearch = (e) => {
    if (e) {
      setUserQuery(e.target.value);
    }
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <UsersForm>
        <Autocomplete
          id="assign-users-field"
          autoComplete={false}
          style={{ width: 300 }}
          open={open}
          onChange={handleChange}
          onOpen={() => getOptions()}
          onClose={clearOptions}
          onInputChange={handleSearch}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          getOptionLabel={(option) => option.name}
          options={options}
          loading={working}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Tag Users"
              variant="filled"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
        <UsersStaging>
          <List dense style={{ width: "300px" }}>
            {stagedUsers.length === 0 && (
              <Message>
                <Typography variant="body1" align="center">
                  Type a Users name to select and tag them.
                </Typography>
              </Message>
            )}
            {stagedUsers.map((user) => {
              return (
                <ListItem key={user.uid} disableGutters>
                  <ListItemAvatar>
                    <Avatar title={user.name} link={user.icon} size="small" />
                  </ListItemAvatar>
                  <ListItemText primary={user.name} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleRemoveUser(user.uid)}
                      size="large">
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </UsersStaging>
      </UsersForm>
    </Popover>
  );
};

ChatAddUsers.propTypes = {};

export default ChatAddUsers;
