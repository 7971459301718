import React, { useState } from "react";
import { TablePagination } from "@mui/material";

const Pagination = ({
  pagination,
  onPageChange,
  handleChangeRowsPerPage,
  action,
}) => {
  const [settings, setSettings] = useState(pagination);
  const { count, current_page, per_page_total } = settings;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        background: "white",
        justifyContent: "space-between",
      }}
    >
      <div>{action}</div>
      <TablePagination
        rowsPerPageOptions={(handleChangeRowsPerPage ? [25, 50, 100, 200] : [])}
        labelRowsPerPage="Rows:"
        component="div"
        count={Number(count)}
        rowsPerPage={per_page_total ? Number(per_page_total) : 25}
        page={current_page}
        SelectProps={{
          inputProps: { "aria-label": "rows per page" },
          native: true,
        }}
        onPageChange={(e, newPage) => {
          setSettings({
            ...settings,
            current_page: newPage}
          );
          onPageChange(e, newPage);
        }}
        onRowsPerPageChange={(e) => {
          setSettings({
            ...settings,
            per_page_total: e.target.value}
          );
          handleChangeRowsPerPage(e);
        }}
        // ActionsComponent={TablePaginationActions}
      />
    </div>
  );
};

Pagination.propTypes = {};

export default Pagination;
