import React from "react";
import AddProjectManager from "features/Job/forms/AddProjectManager";
import AddDate from "features/Job/forms/AddDate";
import AddDateTime from "features/Job/forms/AddDateTime";
import AddDocument from "features/Job/forms/AddDocument";
import Personnel from "features/Job/forms/Personnel";
import AddArea from "features/WorkAreas/forms/Create";
import PaymentReceived from "features/Job/forms/PaymentReceived";
import { Link } from "react-router-dom";
import { getPersonnelType } from "components/Personnel/utils";
import FileFollowUpDate from "features/Job/forms/FileFollowUpDate";
import CustomReqToggle from "features/Milestones/forms/CustomReqToggle";
import { first, toArray, size, isNull } from "lodash";
import moment from "moment";

const Default = ({ children }) => {
  return <div>{children}</div>;
};

export const getRequirement = (
  type,
  field,
  categories,
  division,
  job,
  requirements,
  progress,
  subID
) => {
  let items = {};

  if(type === 'personnel_fields'){
    switch(field){
      case "field_jd_personnel::estimator":
        items = {
          label: "Estimator",
          component: <Personnel
            key="estimator"
            personnelType={getPersonnelType('estimator')}
          />,
          subLabel: parsePersonnelSubLabel({
            value: division.field_jd_personnel,
            type: "estimator",
          }),
        };
      break;

      case "field_jd_personnel::pm":
      //  Use the PM Assignment Dialog if PM not assigned, otherwise, use the standard Personnel component
        let pmComponent = null;
        if(division && division?.field_jd_personnel){
          division.field_jd_personnel.forEach((user) => {
            if (user?.uid && user?.type && user?.type === 'pm') {
              pmComponent = <Personnel
                              key="pm"
                              personnelType={getPersonnelType('pm')}
                            />;
              return false;
            }
          });
        }
        if(!pmComponent){
          pmComponent = <AddProjectManager />;
        }

        items = {
          label: "Project Manager",
          component: pmComponent,
          subLabel: parsePersonnelSubLabel({
            value: division.field_jd_personnel,
            type: "pm",
          }),
        };
      break;

      case "field_jd_personnel::supervisor":
        items = {
          label: "Supervisor",
          component: <Personnel
            key="supervisor"
            personnelType={getPersonnelType('supervisor')}
          />,
          subLabel: parsePersonnelSubLabel({
            value: division.field_jd_personnel,
            type: "supervisor",
          }),
        };
      break;

      case "field_jd_personnel::coordinator":
        items = {
          label: "Project Coordinator",
          component: <Personnel
            key="coordinator"
            personnelType={getPersonnelType('coordinator')}
          />,
          subLabel: parsePersonnelSubLabel({
            value: division.field_jd_personnel,
            type: "coordinator",
          }),
        };
      break;

      case "field_jd_personnel::accounting":
        items = {
          label: "Accounting Personnel",
          component: <Personnel
            key="accounting"
            personnelType={getPersonnelType('accounting')}
          />,
          subLabel: parsePersonnelSubLabel({
            value: division.field_jd_personnel,
            type: "accounting",
          }),
        };
      break;

      case "field_jd_personnel::marketing":
        items = {
          label: "Marketing Personnel",
          component: <Personnel
            key="marketing"
            personnelType={getPersonnelType('marketing')}
          />,
          subLabel: parsePersonnelSubLabel({
            value: division.field_jd_personnel,
            type: "marketing",
          }),
        };
      break;

      case "field_jd_personnel::foreman":
        items = {
          label: "Foreman",
          component: <Personnel
            key="foreman"
            personnelType={getPersonnelType('foreman')}
          />,
          subLabel: parsePersonnelSubLabel({
            value: division.field_jd_personnel,
            type: "foreman",
          }),
        };
      break;

      case "field_jd_personnel::lead_technician":
        items = {
          label: "Lead Technician",
          component: <Personnel
            key="lead_technician"
            personnelType={getPersonnelType('lead_technician')}
          />,
          subLabel: parsePersonnelSubLabel({
            value: division.field_jd_personnel,
            type: "lead_technician",
          }),
        };
      break;

      case "field_jd_crews":
        items = {
          label: "Crews",
          component: <Default />,
          staticItem: true,
        };
      break;

      default:
      break;
    }
  }
  else if(type === 'time_fields'){
    switch(field){
      case "created":
        items = {
          label: "Created",
          component: <Default />,
          subLabel: parseSubLabel({
            progress: progress,
            field: "created",
            prefix: "Created",
            value: division.created
          }),
          staticItem: true,
        };
      break;

      case "field_date":
        items = {
          label: "Received",
          component: (
            <AddDate
              title="Received"
              buttonText="Received"
              nid={job.nid}
              field="field_date"
              format=""
              initialValue={job.field_date}
            />
          ),
          subLabel: parseSubLabel({
            progress: progress,
            field: "field_date",
            prefix: "Marked Received",
            value: division.field_date
          }),
        };
      break;

      case "field_eta_datetime":
        items = {
          label: "ETA",
          component: (
            <AddDateTime
              title="ETA"
              buttonText="ETA"
              nid={division.nid}
              field="field_eta_datetime"
              format=""
              initialValue={division.field_eta_datetime}
            />
          ),
          subLabel: parseSubLabel({
            value: division.field_eta_datetime
          }),
        };
      break;

      case "field_loss_date":
        items = {
          label: "Loss Date",
          component: (
            <AddDate
              title="Loss Date"
              buttonText="Loss Date"
              nid={division.nid}
              field="field_loss_date"
              format=""
              initialValue={division.field_loss_date}
            />
          ),
          subLabel: parseSubLabel({
            value: division.field_loss_date
          }),
        };
      break;

      case "field_jd_inspection":
        items = {
          label: "Inspection",
          component: (
            <AddDate
              title="Inspection"
              buttonText="Inspection"
              nid={division.nid}
              field="field_jd_inspection"
              format=""
              initialValue={division.field_jd_inspection}
            />
          ),
          subLabel: parseSubLabel({
            value: division.field_jd_inspection
          }),
        };
      break;

      case "field_start_date":
        items = {
          label: "Start Date",
          component: (
            <AddDate
              title="Start Date"
              buttonText="Start Date"
              nid={division.nid}
              field="field_start_date"
              format="YYYY-MM-DD"
              initialValue={division.field_start_date}
            />
          ),
          subLabel: parseSubLabel({
            value: division.field_start_date
          }),
        };
      break;

      case "field_completion_date":
        items = {
          label: "Target Completion",
          component: (
            <AddDate
              title="Target Completion"
              buttonText="Target Completion"
              nid={division.nid}
              field="field_completion_date"
              format="YYYY-MM-DD"
              initialValue={division.field_completion_date}
            />
          ),
          subLabel: parseSubLabel({
            value: division.field_completion_date
          }),
        };
      break;

      case "field_jd_incident_date":
        items = {
          label: "Incident",
          component: (
            <AddDate
              title="Incident"
              buttonText="Incident"
              nid={division.nid}
              field="field_jd_incident_date"
              format="YYYY-MM-DD"
              initialValue={division.field_jd_incident_date}
            />
          ),
          subLabel: parseSubLabel({
            value: division.field_jd_incident_date
          }),
        };
      break;

      case "field_jd_contacted_date":
        items = {
          label: "Contacted",
          component: (
            <AddDate
              title="Contacted"
              buttonText="Contacted"
              nid={division.nid}
              field="field_jd_contacted_date"
              format="YYYY-MM-DD"
              initialValue={division.field_jd_contacted_date}
            />
          ),
          subLabel: parseSubLabel({
            value: division.field_jd_contacted_date
          }),
        };
      break;

      case "field_crew_arrival_time_range":
        items = {
          label: "Crew ETA",
          component: <Default />,
          subLabel: parseSubLabel({
            value: division.field_crew_arrival_time_range
          }),
          staticItem: true,
        };
      break;

      case "field_jd_progress_data::estimate_sent_timestamp":
        items = {
          label: "Estimate Sent",
          component: <Default />,
          subLabel: parseSubLabel({
            progress: progress,
            field: "field_jd_progress_data::estimate_sent_timestamp",
            prefix: "Estimate Sent",
          }),
          staticItem: true,
        };
      break;

      case "field_jd_progress_data::estimate_approved_timestamp":
        items = {
          label: "Estimate Approved",
          component: <Default />,
          subLabel: parseSubLabel({
            progress: progress,
            field: "field_jd_progress_data::estimate_approved_timestamp",
            prefix: "Estimate Approved"
          }),
          staticItem: true,
        };
      break;

      case "field_jd_trgt_start_date":
        items = {
          label: "Target Start",
          component: (
            <AddDate
              title="Target Start"
              buttonText="Target Start"
              nid={division.nid}
              field="field_jd_trgt_start_date"
              format="YYYY-MM-DD"
              initialValue={division.field_jd_trgt_start_date}
            />
          ),
          subLabel: parseSubLabel({
            value: division.field_jd_trgt_start_date
          }),
        };
      break;

      case "field_jd_intro_prod_date":
        items = {
          label: "Intro Production",
          component: (
            <AddDate
              title="Intro Production"
              buttonText="Intro Production"
              nid={division.nid}
              field="field_jd_intro_prod_date"
              format="YYYY-MM-DD"
              initialValue={division.field_jd_intro_prod_date}
            />
          ),
          subLabel: parseSubLabel({
            value: division.field_jd_intro_prod_date
          }),
        };
      break;

      case "field_jd_mjrty_completion_date":
        items = {
          label: "Majority Completion",
          component: (
            <AddDate
              title="Majority Completion"
              buttonText="Majority Completion"
              nid={division.nid}
              field="field_jd_mjrty_completion_date"
              format="YYYY-MM-DD"
              initialValue={division.field_jd_mjrty_completion_date}
            />
          ),
          subLabel: parseSubLabel({
            value: division.field_jd_mjrty_completion_date
          }),
        };
      break;

      case "field_jd_work_complete":
        items = {
          label: "Work Complete",
          component: (
            <AddDate
              title="Work Complete"
              buttonText="Work Complete"
              nid={division.nid}
              field="field_jd_work_complete"
              format=""
              initialValue={division.field_jd_work_complete}
            />
          ),
          subLabel: parseSubLabel({
            value: division.field_jd_work_complete
          }),
        };
      break;

      case "field_jd_progress_data::invoiced_timestamp":
        items = {
          label: "Invoiced",
          component: <Default />,
          subLabel: parseSubLabel({
            progress: progress,
            field: "field_jd_progress_data::invoiced_timestamp",
            prefix: "Marked as Invoiced"
          }),
          staticItem: true,
        };
      break;

      case "field_jd_progress_data::paid_timestamp":
        items = {
          label: "Paid",
          component: <PaymentReceived />,
          subLabel: parseSubLabel({
            progress: progress,
            field: "field_jd_progress_data::paid_timestamp",
            prefix: "Marked as Paid"
          }),
        };
      break;

      case "field_file_followup_date":
        items = {
          label: "File Follow Up",
          component:  <FileFollowUpDate />,
          subLabel: parseSubLabel({
            progress: progress,
            field: "field_file_followup_date",
            value: division.field_file_followup_date,
            date_only: true,
            user_prefix: '-',
          }),
        };
      break;

      case "field_jd_closed":
        items = {
          label: "Closed",
          component: <Default />,
          subLabel: parseSubLabel({
            progress: progress,
            field: "field_jd_closed",
            value: division.field_jd_closed,
            prefix: "Marked as Closed"
          }),
          staticItem: true,
        };
      break;

      default:
      break;
    }
  }
  else if(type === 'files'){
    categories.forEach((category) => {
      if(category.tid === field){
      // files[category.tid] = {
        items = {
          label: category.name,
          component: <AddDocument categoryNameOverride={category.name} />,
          subLabel: parseSubLabel({
            progress: progress,
            type: "files",
            field: category.tid,
            prefix: "Uploaded"
          }),
        };
        return false;
      }
    });
  }
  else if(type === 'require_damage_area'){
    items = {
      label: "Report Work Area",
      component: (
        <AddArea />
      ),
    };
  }
  else if(type === 'photos'){
    const proper = field.charAt(0).toUpperCase() + field.slice(1);
    let subLabel = null;
    let status = false;
    let completed = 0;
    let required = progress ? Number(progress.photos[`${field}_required`]) : 0;
    let label = `${proper} Photos`;
    if(!progress?.photos?.wa_dist){
      completed = progress ? Number(progress.photos[`${field}_completed`]) : 0;
      if(completed === null){
        completed = 0;
      }
      if (completed >= required) {
        status = true;
      }
      subLabel = `${completed} of ${required} ${label} uploaded`;
    }
    else{
      const wa_qty = size(progress?.photos.wa_dist_photos);
      if(progress?.photos?.wa_dist_photos){
        progress?.photos?.wa_dist_photos.forEach((item) => {
          let _completed = Number(item[`${field}_completed`]);
          if(_completed >= required){
            completed ++;
          }
        });
      }
      subLabel = `${completed} of ${wa_qty} Work Area`;
      if(completed >= wa_qty){
        status = true;
        subLabel = `All Work Area`;
      }
      if(wa_qty > 1){
        subLabel += `s have`;
      }
      else{
        subLabel = `The Work Area has`;
      }
      subLabel += ` ${required} or more required ${label}`;
    }

    items =  {
      label: label,
      component: (
        <AddDocument timeOverride={field} categoryNameOverride="Photos" />
      ),
      subLabel: subLabel,
      status: status,
    };
  }
  else if(type === 'custom' || type === 'wa_custom_item'){
    const obj = division?._processed?.milestone_requirements;
    const reqs = first(toArray(obj));
    type = (type === 'wa_custom_item' ? 'wa_custom' : type);
    if(reqs && size(reqs?.[type])){
      reqs?.[type].forEach((item, index) => {
        if(index === field){
          const value = getProgressValue({progress, type, field, subID});
          items = {
            label: item,
            component: <CustomReqToggle
                        title={item}
                        buttonText={item}
                        nid={division.nid}
                        type={type}
                        field={field}
                        initialValue={false}
                        subID={subID}
                      />,
            subLabel: parseSubLabel({
              progress: progress,
              type: type,
              field: field,
              subID: subID,
              prefix: "Marked Complete",
            }),
            status: Boolean(value),
          };
          return false;
        }
      });
    }
  }
  else if(type === 'wa_custom'){
    const obj = division?._processed?.milestone_requirements;
    const reqs = first(toArray(obj));
    if(reqs && size(reqs?.[type])){
      reqs?.[type].forEach((item, index) => {
        if(index === field){
          const {completed:waCompleted, total:waTotal} = getWAProgress(reqs, progress, field);
          items = {
            label: item,
            component:  <Link to={`/job/${division.title}/areas`} className="text-black" />,
            subLabel:`Completed on ${waCompleted} of ${waTotal} Work Areas`,
            status: Boolean(waCompleted >= waTotal),
          };
          return false;
        }
      });
    }
  }

  if(!items.hasOwnProperty("status")){
    items.status =
      progress && progress?.[type]?.[field]
        ? size(progress?.[type]?.[field]) > 0
        : null;
  }

  return items;
};

function parsePersonnelSubLabel({
  type,
  value,
}){
  let subLabel = null;
  if(value){
    subLabel = value
      .filter((item) => item.type === type)
      .map((item) => item.full_name)
      .join(', ');
  }
  return subLabel;
}

function parseSubLabel({
  progress,
  type,
  field,
  prefix,
  value,
  subID,
  date_only,
  user_prefix
}){
  let subLabel = null;
  let _moment = null;
  let userName = null;
  if(value && value !== ''){
    _moment = moment(value);
  }
  let _type = (type ? type : "time_fields")
  if(!isNull(field) && typeof field !== 'undefined' && progress && progress?.[_type]){
    const info = getProgressValue({progress, type: _type, field, subID});
    if(info){
      if(!_moment && info.timestamp && info.timestamp !== ''){
        _moment = moment.unix(info.timestamp);
      }
      if(info?.user_name && info.user_name !== ''){
        userName = info.user_name;
      }
      else if(info?.name && info.name !== ''){
        userName = info.name;
      }
    }
  }
  if(_moment){
    const date = _moment.format("MM/DD/YYYY");
    const time = _moment.format("hh:mm A");
    subLabel = '';
    if(prefix){
      subLabel = `${prefix} on `;
    }

    subLabel += `${date}`;

    if(!date_only && time !== '12:00 AM'){
      subLabel += ` at ${time}`;
    }
  }
  if(subLabel && userName){
    if(user_prefix){
      subLabel += ` ${user_prefix}`;
    }
    else{
      subLabel += ` by`;
    }
    subLabel += ` ${userName}`;
  }
  return subLabel;
}

export function getProgressValue({
  progress,
  type,
  field,
  subID,
}){
  let info = null;
  if( progress ){
    if(!subID && progress?.[type]?.[field]){
      info = progress[type][field];
    }
    else if(subID){
      const subVals = progress[type].filter((sub) => Number(sub.nid) === Number(subID));
      if(size(subVals) > 0){
        info = first(subVals).items?.[field];
      }
    }
  }
  return info;
};

export function getWAProgress(requirements, progress, item){
  const info = {
    completed: 0,
    total: 0,
  };
  if(requirements?.wa_custom && size(requirements.wa_custom ) > 0){
    if(progress?.wa_custom){
      info.total = size(progress.wa_custom);
      progress.wa_custom.forEach((areaReqs) => {
        if(isNull(item) || typeof item === 'undefined'){
          if(size(areaReqs.items) === size(requirements.wa_custom )){
            info.completed++;
          }
        }
        else{
          if(areaReqs.items?.[item]){
            info.completed++;
          }
        }
      });
    }
  }
  return info;
};
