import styled from "styled-components";
import constants from "components/constants";

export const MapOverlay = styled.div`
  height: 325px;
  padding: 1.25rem;
  position: relative;

  @media (max-height: ${constants.breakpoints.tall}) {
    height: 250px;
  }
  @media (max-height: ${constants.breakpoints.mediumHeight}) {
    height: 175px;
  }
`;

export const Container = styled.div`
  // flex: 1;
  // overflow: auto;
  background: white;
  position: relative;
  z-index: 0;

  &.compact ${MapOverlay}{
    height: 150px;
  }

  &.compact + *{
    position: relative;
    z-index: 1;
  }
`;

export const MapOverlayContainer = styled.div`
  background: transparent;
  z-index: 2;
  position: relative;
`;

export const MapAddress = styled.div`
  padding: 0.5rem;
  background: white;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  border-radius: 3px;
  position: absolute;
  bottom: 10px;
  left: 10px;

  & > img {
    border-radius: 3px;
  }
  & > img + * {
    margin-left: 1rem;
    margin-right: 0.5rem;
  }
`;

export const MapScrollButton = styled.div`
  height: 50px;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  background: linear-gradient(
    var(--color-gray-background),
    var(--color-gray-background)
  );
  // background: linear-gradient(white, white);
  box-shadow: 10px 0 12px -2px #888;
  display: flex;
  justify-content: center;
  padding-top: 0.5rem;
  cursor: pointer;
  transition: all 0.25s;
  color: var(--color-gray);

  & > svg {
    width: 50px;

    & > rect {
      width: 50px;
    }
  }

  &:hover {
    background: linear-gradient(white, var(--color-gray-background));
  }
`;

export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Address = styled.div`
  flex: 1;
`;
