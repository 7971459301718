import React, { useEffect } from "react";
import { TextField } from "@mui/material";
import { useHistory } from "react-router-dom";

import { useDebounce } from "../../hooks";
import { useQuery } from "../../utility";
import { Actions } from "./Styled";

const ReportSearch = ({ setQuery }) => {
  const query = useQuery();
  const history = useHistory();
  const [searchTerm, setSearchTerm] = React.useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    checkFragment();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!debouncedSearchTerm) {
      query.delete("keywords");
    } else if (query.has("keywords")) {
      query.set("keywords", debouncedSearchTerm);
    } else {
      query.append("keywords", debouncedSearchTerm);
    }
    history.push(`?${query.toString()}`);

    setQuery(debouncedSearchTerm);
  }, [debouncedSearchTerm]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkFragment = () => {
    const searchQuery = query.get("keywords");
    if (searchQuery) {
      setSearchTerm(searchQuery);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <Actions>
      <TextField
        variant="outlined"
        size="small"
        label="Search"
        name="report-search"
        onChange={handleSearch}
        fullWidth
        value={searchTerm}
      />
    </Actions>
  );
};

ReportSearch.propTypes = {};

export default ReportSearch;
