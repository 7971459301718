import React from "react";
import { SvgIcon } from "@mui/material";

export const LocationIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 22 30" {...props}>
      <path
        d="M10.6438 29.3414L10.6437 29.3414C10.4438 29.1188 7.9273 26.2949 5.46375 22.6075C4.23198 20.7639 3.02015 18.7142 2.11774 16.6735C1.21259 14.6266 0.634766 12.624 0.634766 10.8648C0.634766 5.15009 5.28485 0.5 10.9996 0.5C16.7143 0.5 21.3644 5.15008 21.3645 10.8648C21.3645 12.624 20.7867 14.6266 19.8815 16.6735C18.9791 18.7142 17.7673 20.7639 16.5355 22.6075C14.072 26.2949 11.5554 29.1188 11.3555 29.3414L11.3555 29.3414C11.1658 29.5527 10.8337 29.5529 10.6438 29.3414Z"
        stroke="white"
      />
    </SvgIcon>
  );
};
