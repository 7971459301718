import * as Yup from "yup";
import constants from "components/constants";

export const addInsuranceSchema = Yup.object().shape({
  carrier: Yup.string("Must by a string").required("Required"),
  agentName: Yup.string("Must by a string"),
  agentPhone: Yup.string().matches(constants.phoneRegExp, "Invalid ex. 555-867-5309"),
  agentFax: Yup.string().matches(constants.phoneRegExp, "Invalid ex. 555-867-5309"),
  agentEmail: Yup.string().email("Must be a valid email"),
  adjusterName: Yup.string("Must by a string"),
  adjusterPhone: Yup.string().matches(constants.phoneRegExp, "Invalid ex. 555-867-5309"),
  adjusterFax: Yup.string().matches(constants.phoneRegExp, "Invalid ex. 555-867-5309"),
  adjusterEmail: Yup.string().email("Must be a valid email"),
  claim: Yup.string("Must by a string").required("Required"),
  policyNumber: Yup.string("Must by a string"),
  deductible: Yup.number().typeError("Must be a number"),
});
