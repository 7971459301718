import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { BiCommentAdd } from "react-icons/bi";
import { EditorState } from "draft-js";
import { first } from "lodash";

import TextEditor, { getContentAsHTML } from "common/TextEditor";
import { TextFormField } from "common/Fields";
import { SelectFormField } from "common/Fields";
import { DialogForm } from "common/Dialog";

import { getCustomerDataSelector } from "../customerSlice";
import {
  commentsSelectors,
  postCustomerComment,
} from "features/Comments/commentsSlice";
import {
  customerNoteStatusSelectors,
  customerNoteTypesSelectors,
} from "features/Taxonomies/taxonomiesSlice";

const EditContact = ({children, ...props}) => {
  const dispatch = useDispatch();
  const customer = useSelector(getCustomerDataSelector);
  const currentUser = useSelector((state) => state.auth.user.data);
  const statuses = useSelector(customerNoteStatusSelectors.selectAll);
  const types = useSelector(customerNoteTypesSelectors.selectAll);
  const commentIds = useSelector(commentsSelectors.selectIds);

  const sortedIds = [...commentIds].sort((a, b) => b - a);

  const [open, setOpen] = useState(false);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = (data) => {
    const html = getContentAsHTML(editorState);
    if (!editorState.getCurrentContent().hasText()) return;
    const params = {
      id: `${Number(first(sortedIds)) + 1}`,
      field_customer_note_comment: html,
      subject: data.subject,
      field_customer_note_type: data.field_customer_note_type,
      field_customer_note_status: data.field_customer_note_status,
      user: currentUser,
    };
    dispatch(postCustomerComment({ id: customer.nid, params }));
    setEditorState(EditorState.createEmpty());

    onClose();
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
      <>
      {children ? (
        React.cloneElement(children, {
          onClick: handleOpen,
        })
      ) : (
        <ListItem button onClick={handleOpen}>
          <ListItemIcon>
            <BiCommentAdd />
          </ListItemIcon>
          <ListItemText primary="Note" />
        </ListItem>
      )}
      <DialogForm
        open={open}
        onClose={onClose}
        maxWidth="xs"
        title="Add Note"
        initialValues={{
          subject: "",
          field_customer_note_type: "",
          field_customer_note_status: "",
        }}
        disablePadding
        onSubmit={handleSubmit}
        disableOverlay={props.disableOverlay}
      >
        {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
          <div style={{ padding: "1.25rem" }}>
            <TextFormField
              fullWidth
              htmlFor="subject"
              name="subject"
              label="Subject"
              margin="normal"
              required
              disabled={isSubmitting}
              variant="filled"
              size="small"
            />
            <SelectFormField
              fullWidth
              name="field_customer_note_type"
              label="Communication Type"
              margin="normal"
              required
              disabled={isSubmitting}
              options={types.map((type) => ({
                label: type.name,
                value: type.tid,
              }))}
              variant="filled"
              size="small"
            />
            <SelectFormField
              fullWidth
              name="field_customer_note_status"
              label="Status"
              margin="normal"
              required
              disabled={isSubmitting}
              options={statuses.map((status) => ({
                label: status.name,
                value: status.tid,
              }))}
              variant="filled"
              size="small"
            />
            <TextEditor
              isSubmitting={isSubmitting}
              placeholder={`Comment`}
              editorState={editorState}
              setEditorState={setEditorState}
            />
          </div>
        )}
      </DialogForm>
    </>
  );
};

EditContact.propTypes = {};

export default EditContact;
