import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import { size } from "lodash";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import { formatCustInfoItems } from "./utils";
import { defaltTMTermsAdapter } from "components/TMTerms/TMTermsSlice";
import getQueryParams from "utility/getQueryParams";

const namespace = "taxonomies";

const getTaxonomyRequest = async (
  sort,
  type,
  { getState, signal, rejectWithValue }
) => {
  try {
    const source = axios.CancelToken.source();

    signal.addEventListener("abort", () => {
      source.cancel();
    });

    const response = await axios.get(
      `${config.api_url}/rest/taxonomy-listing/${type}/${sort ? sort : ""}`,
      {
        ...tokenConfig(getState),
        cancelToken: source.token,
      }
    );

    return response.data;
  } catch (err) {
    let error = err; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return rejectWithValue(error.response.data);
  }
};

const getFieldListTextRequest = async (
  field,
  { getState, signal, rejectWithValue }
) => {
  try {
    const source = axios.CancelToken.source();

    signal.addEventListener("abort", () => {
      source.cancel();
    });

    const response = await axios.get(
      `${config.api_url}/rest/field/list-text-options/node/${field}`,
      {
        ...tokenConfig(getState),
        cancelToken: source.token,
      }
    );

    return response.data;
  } catch (err) {
    let error = err; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return rejectWithValue(error.response.data);
  }
};

export const fetchJobDivisionTypes = createAsyncThunk(
  `${namespace}/fetchJobDivisionTypes`,
  async (sort, params) => getTaxonomyRequest(sort, "job_division_types", params)
);

export const fetchMemberAffiliations = createAsyncThunk(
  `${namespace}/fetchMemberAffiliations`,
  async (sort, params) => getTaxonomyRequest(sort, "affiliations", params)
);

export const fetchServices = createAsyncThunk(
  `${namespace}/fetchServices`,
  async (sort, params) => getTaxonomyRequest(sort, "services", params)
);

export const fetchCertifications = createAsyncThunk(
  `${namespace}/fetchCertifications`,
  async (sort, params) => getTaxonomyRequest(sort, "certifications", params)
);

export const fetchMemberNoteTypes = createAsyncThunk(
  `${namespace}/fetchMemberNoteTypes`,
  async (sort, params) => getTaxonomyRequest(sort, "member_note_types", params)
);

export const fetchMemberNoteStatus = createAsyncThunk(
  `${namespace}/fetchMemberNoteStatus`,
  async (sort, params) => getTaxonomyRequest(sort, "member_note_status", params)
);

export const fetchMemberNoteSubjects = createAsyncThunk(
  `${namespace}/fetchMemberNoteSubjects`,
  async (sort, params) =>
    getTaxonomyRequest(sort, "member_note_subjects", params)
);

export const fetchCustomerNoteStatus = createAsyncThunk(
  `${namespace}/fetchCustomerNoteStatus`,
  async (sort, params) =>
    getTaxonomyRequest(sort, "customer_note_status", params)
);

export const fetchCustomerNoteTypes = createAsyncThunk(
  `${namespace}/fetchCustomerNoteTypes`,
  async (sort, params) =>
    getTaxonomyRequest(sort, "customer_note_types", params)
);

export const fetchSourceOfLoss = createAsyncThunk(
  `${namespace}/fetchSourceOfLoss`,
  async (sort, params) => getTaxonomyRequest(sort, "sources_of_loss", params)
);

export const fetchResources = createAsyncThunk(
  `${namespace}/fetchResources`,
  async (sort, params) => getTaxonomyRequest(sort, "resources", params)
);

export const fetchRegions = createAsyncThunk(
  `${namespace}/fetchRegions`,
  async (sort, params) => getTaxonomyRequest(sort, "regions", params)
);

export const fetchServiceActivities = createAsyncThunk(
  `${namespace}/fetchServiceActivities`,
  async (sort, params) => getTaxonomyRequest(sort, "service_activities", params)
);

export const fetchServiceLaborTypes = createAsyncThunk(
  `${namespace}/fetchServiceLaborTypes`,
  async (sort, params) =>
    getTaxonomyRequest(sort, "service_labor_types", params)
);

export const fetchExpenseTypes = createAsyncThunk(
  `${namespace}/fetchExpenseTypes`,
  async (sort, params) => getTaxonomyRequest(sort, "expense_types", params)
);

export const fetchComplianceChangeReasons = createAsyncThunk(
  `${namespace}/fetchComplianceChangeReasons`,
  async (sort, params) =>
    getTaxonomyRequest(sort, "compliance_change_reasons", params)
);

export const fetchJobDivisionFileCategories = createAsyncThunk(
  `${namespace}/fetchJobDivisionFileCategories`,
  async (sort, params) =>
    getTaxonomyRequest(sort, "job_division_file_categories", params)
);

export const fetchMemberFileCategories = createAsyncThunk(
  `${namespace}/fetchMemberFileCategories`,
  async (sort, params) =>
    getTaxonomyRequest(sort, "member_file_categories", params)
);

export const fetchCustomerFileCategories = createAsyncThunk(
  `${namespace}/fetchCustomerFileCategories`,
  async (sort, params) =>
    getTaxonomyRequest(sort, "customer_file_categories", params)
);

export const fetchNationalContracts = createAsyncThunk(
  `${namespace}/fetchNationalContracts`,
  async (sort, params) => getTaxonomyRequest(sort, "national_contracts", params)
);

export const fetchMoreFloodsLevels = createAsyncThunk(
  `${namespace}/fetchMoreFloodsLevels`,
  async (sort, params) => getTaxonomyRequest(sort, "more_floods_levels", params)
);

export const fetchMoreFloodsFlooring = createAsyncThunk(
  `${namespace}/fetchMoreFloodsFlooring`,
  async (sort, params) =>
    getTaxonomyRequest(sort, "mf_flooring_options", params)
);

export const fetchMoreFloodsJobCategories = createAsyncThunk(
  `${namespace}/fetchMoreFloodsJobCategories`,
  async (sort, params) => getTaxonomyRequest(sort, "mf_job_categories", params)
);

export const fetchMoreFloodsJobNotSecuredDetails = createAsyncThunk(
  `${namespace}/fetchMoreFloodsJobNotSecuredDetails`,
  async (sort, params) =>
    getTaxonomyRequest(sort, "mf_job_not_secured_details", params)
);

export const fetchMoreFloodsReferringPartyFollowup = createAsyncThunk(
  `${namespace}/fetchMoreFloodsReferringPartyFollowup`,
  async (sort, params) =>
    getTaxonomyRequest(sort, "mf_referring_party_follow_up", params)
);

export const fetchInventoryItemTypes = createAsyncThunk(
  `${namespace}/fetchInventoryItemTypes`,
  async (sort, params) =>
    getTaxonomyRequest(sort, "inventory_item_types", params)
);

export const fetchTaskTypes = createAsyncThunk(
  `${namespace}/fetchTaskTypes`,
  async (sort, params) => getTaxonomyRequest(sort, "task_types", params)
);

export const fetchMonitoringChecklistItems = createAsyncThunk(
  `${namespace}/fetchMonitoringChecklistItems`,
  async (sort, params) =>
    getTaxonomyRequest(sort, "mf_monitoring_checklist_items", params)
);

export const fetchMemberStatus = createAsyncThunk(
  `${namespace}/fetchMemberStatus`,
  async (_, params) => getFieldListTextRequest("field_status", params)
);

export const fetchJobDivisionStatus = createAsyncThunk(
  `${namespace}/fetchJobDivisionStatus`,
  async (sort, params) =>
    getTaxonomyRequest(sort, "job_division_statuses", params)
);

export const fetchInsuranceCoverageCategories = createAsyncThunk(
  `${namespace}/fetchInsuranceCoverageCategories`,
  async (sort, params) =>
    getTaxonomyRequest(sort, "insurance_coverage_categories", params)
);

export const fetchCustomerStatus = createAsyncThunk(
  `${namespace}/fetchCustomerStatus`,
  async (_, params) => getFieldListTextRequest("field_customer_status", params)
);

export const fetchLossCategories = createAsyncThunk(
  `${namespace}/fetchLossCategories`,
  async (_, params) => getFieldListTextRequest("field_loss_category", params)
);

export const fetchJobSources = createAsyncThunk(
  `${namespace}/fetchJobSources`,
  async (_, params) => getFieldListTextRequest("field_job_source", params)
);

export const fetchCustomerInvoiceTerms = createAsyncThunk(
  `${namespace}/fetchCustomerInvoiceTerms`,
  async (_, params) =>
    getFieldListTextRequest("field_customer_invoice_terms", params)
);

export const fetchCustomerInvoiceDiscounts = createAsyncThunk(
  `${namespace}/fetchCustomerInvoiceDiscounts`,
  async (_, params) =>
    getFieldListTextRequest("field_customer_discount", params)
);

export const fetchStates = createAsyncThunk(
  `${namespace}/fetchStates`,
  async (country, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/options/states/${country}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      const keys = Object.keys(response.data);
      const states = keys.map((key) => ({
        value: key,
        label: response.data[key],
      }));

      return states;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchProvinces = createAsyncThunk(
  `${namespace}/fetchProvinces`,
  async (_, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/options/states/CA`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      const keys = Object.keys(response.data);
      const states = keys.map((key) => ({
        value: key,
        label: response.data[key],
      }));

      return states;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCounties = createAsyncThunk(
  `${namespace}/fetchCounties`,
  async ({params}, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/options/counties`,
        params,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCountyZips = createAsyncThunk(
  `${namespace}/fetchCountyZips`,
  async ({params}, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const queryParams = getQueryParams(params);
      const response = await axios.get(
        `${config.api_url}/rest/options/counties/1${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchExpenseTypesOverrides = createAsyncThunk(
  `${namespace}/fetchExpenseTypesOverrides`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/daily-sheets/overrides/${id}/expense_types`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchServiceActivitiesOverrides = createAsyncThunk(
  `${namespace}/fetchServiceActivitiesOverrides`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/daily-sheets/overrides/${id}/service_activities`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchServiceLaborTypesOverrides = createAsyncThunk(
  `${namespace}/fetchServiceLaborTypesOverrides`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/daily-sheets/overrides/${id}/service_labor_types`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchWorkAreaResources = createAsyncThunk(
  `${namespace}/fetchWorkAreaResources`,
  async ({ division, workarea }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/resources/damage_area/${division}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAccountingTemplateOptions = createAsyncThunk(
  `${namespace}/fetchAccountingTemplateOptions`,
  async (_, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job-accounting-invoice/template/options`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      const data = [];
      for (let key in response.data) {
        data.push({ machine_name: key, label: response.data[key] });
      }

      return data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMoreFloodsInfoSheetItems = createAsyncThunk(
  `${namespace}/fetchMoreFloodsInfoSheetItems`,
  async (_, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/mf/customer-info-sheet-items`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return formatCustInfoItems(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchTaxonomies = createAsyncThunk(
  `${namespace}/fetchTaxonomies`,
  async (_, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/taxonomy-listing`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchFieldLists = createAsyncThunk(
  `${namespace}/fetchFieldLists`,
  async (_, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/field/list-text-options`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchGlobalData = createAsyncThunk(
  `${namespace}/fetchGlobalData`,
  async (_, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(`${config.api_url}/rest/global-data`, {
        ...tokenConfig(getState),
        cancelToken: source.token,
      });

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchNationalContracts = createAsyncThunk(
  `${namespace}/patchNationalContracts`,
  async ({ phxClientNid, params }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.patch(
        `${config.api_url}/rest/taxonony-terms/update/national_contracts/${phxClientNid}`,
        params,
        {
          ...tokenConfig(getState),
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const jobDivisionTypesAdapter = createEntityAdapter({
  selectId: (type) => type.tid,
});

const memberAffiliationsAdapter = createEntityAdapter({
  selectId: (type) => type.tid,
});

const servicesAdapter = createEntityAdapter({
  selectId: (type) => type.tid,
});

const certificationsAdapter = createEntityAdapter({
  selectId: (type) => type.tid,
});

const memberNoteTypesAdapter = createEntityAdapter({
  selectId: (type) => type.tid,
});

const memberNoteStatusAdapter = createEntityAdapter({
  selectId: (type) => type.tid,
});

const memberNoteSubjectsAdapter = createEntityAdapter({
  selectId: (type) => type.tid,
});

const customerNoteStatusAdapter = createEntityAdapter({
  selectId: (type) => type.tid,
});

const customerNoteTypesAdapter = createEntityAdapter({
  selectId: (type) => type.tid,
});

const sourceOfLossAdapter = createEntityAdapter({
  selectId: (type) => type.tid,
});

const resourcesAdapter = createEntityAdapter({
  selectId: (type) => type.tid,
});

const regionsAdapter = createEntityAdapter({
  selectId: (type) => type.tid,
});

const serviceActivitiesAdapter = createEntityAdapter({
  selectId: (type) => type.name,
});

const serviceLaborTypesAdapter = createEntityAdapter({
  selectId: (type) => type.name,
});

const expenseTypesAdapter = createEntityAdapter({
  selectId: (type) => type.name,
});

const complianceChangeReasonsAdapter = createEntityAdapter({
  selectId: (type) => type.tid,
});

const jobDivisionFileCategoriesAdapter = createEntityAdapter({
  selectId: (type) => type.tid,
});

const memberFileCategoriesAdapter = createEntityAdapter({
  selectId: (type) => type.tid,
});

const customerFileCategoriesAdapter = createEntityAdapter({
  selectId: (type) => type.tid,
});

const nationalContractsAdapter = createEntityAdapter({
  selectId: (type) => type.tid,
});

const memberStatusAdapter = createEntityAdapter({
  selectId: (type) => type.machine_name,
});

const jobDivisionStatusAdapter = createEntityAdapter({
  selectId: (type) => type.tid,
});

const inventoryItemTypeAdapter = createEntityAdapter({
  selectId: (type) => type.tid,
});

const taskTypesAdapter = createEntityAdapter({
  selectId: (type) => type.tid,
});

const monitoringChecklistItemsAdapter = createEntityAdapter({
  selectId: (type) => type.tid,
});

const customerStatusAdapter = createEntityAdapter({
  selectId: (type) => type.machine_name,
});

const lossCategoriesAdapter = createEntityAdapter({
  selectId: (type) => type.machine_name,
});

const jobSourcesAdapter = createEntityAdapter({
  selectId: (type) => type.machine_name,
});

const dsOverridesAdapter = createEntityAdapter({
  selectId: (type) => type.machine_name,
});

const paymentTermsAdapter = createEntityAdapter({
  selectId: (type) => type.machine_name,
});

const jdaStatusOptionsAdapter = createEntityAdapter({
  selectId: (type) => type.machine_name,
});

const smsSettingsOptionsAdapter = createEntityAdapter({
  selectId: (type) => type.machine_name,
});

const customerTermsAdapter = createEntityAdapter({
  selectId: (type) => type.machine_name,
});

const customerDiscountsAdapter = createEntityAdapter({
  selectId: (type) => type.machine_name,
});

const statesAdapter = createEntityAdapter({
  selectId: (state) => state.value,
});

const countiesAdapter = createEntityAdapter({
  selectId: (county) => county.tid,
});

const moreFloodsLevelsAdapter = createEntityAdapter({
  selectId: (level) => level.tid,
});

const moreFloodsFlooringAdapter = createEntityAdapter({
  selectId: (floor) => floor.tid,
});

const moreFloodsJobCategoriesAdapter = createEntityAdapter({
  selectId: (tax) => tax.tid,
});

const moreFloodsJobNotSecuredDetailsAdapter = createEntityAdapter({
  selectId: (tax) => tax.tid,
});

const moreFloodsReferringPartyFollowupAdapter = createEntityAdapter({
  selectId: (tax) => tax.tid,
});

const accountingTemplateAdapter = createEntityAdapter({
  selectId: (template) => template.machine_name,
});

const moreFloodsInfoItemsAdapter = createEntityAdapter({
  selectId: (tax) => tax.tid,
});

const insuranceCoverageCategoriesAdapter = createEntityAdapter({
  selectId: (tax) => tax.tid,
});

const taxonomiesSlice = createSlice({
  name: namespace,
  initialState: {
    error: null,
    jobDivisionTypes: jobDivisionTypesAdapter.getInitialState({
      loading: false,
    }),
    memberAffiliations: memberAffiliationsAdapter.getInitialState({
      loading: false,
    }),
    services: servicesAdapter.getInitialState({
      loading: false,
    }),
    certifications: certificationsAdapter.getInitialState({
      loading: false,
    }),
    memberNoteTypes: memberNoteTypesAdapter.getInitialState({
      loading: false,
    }),
    memberNoteStatus: memberNoteStatusAdapter.getInitialState({
      loading: false,
    }),
    memberNoteSubjects: memberNoteSubjectsAdapter.getInitialState({
      loading: false,
    }),
    customerNoteStatus: customerNoteStatusAdapter.getInitialState({
      loading: false,
    }),
    customerNoteTypes: customerNoteTypesAdapter.getInitialState({
      loading: false,
    }),
    sourceOfLoss: sourceOfLossAdapter.getInitialState({
      loading: false,
    }),
    resources: resourcesAdapter.getInitialState({
      loading: false,
    }),
    regions: regionsAdapter.getInitialState({
      loading: false,
    }),
    serviceActivities: serviceActivitiesAdapter.getInitialState({
      loading: false,
    }),
    serviceLaborTypes: serviceLaborTypesAdapter.getInitialState({
      loading: false,
    }),
    expenseTypes: expenseTypesAdapter.getInitialState({
      loading: false,
    }),
    complianceChangeReasons: complianceChangeReasonsAdapter.getInitialState({
      loading: false,
    }),
    jobDivisionFileCategories: jobDivisionFileCategoriesAdapter.getInitialState(
      {
        loading: false,
      }
    ),
    memberFileCategories: memberFileCategoriesAdapter.getInitialState({
      loading: false,
    }),
    customerFileCategories: customerFileCategoriesAdapter.getInitialState({
      loading: false,
    }),
    nationalContracts: nationalContractsAdapter.getInitialState({
      loading: false,
    }),
    memberStatus: memberStatusAdapter.getInitialState({
      loading: false,
    }),
    jobDivisionStatus: jobDivisionStatusAdapter.getInitialState({
      loading: false,
    }),
    customerStatus: customerStatusAdapter.getInitialState({
      loading: false,
    }),
    lossCategories: lossCategoriesAdapter.getInitialState({
      loading: false,
    }),
    jobSources: jobSourcesAdapter.getInitialState({
      loading: false,
    }),
    dsOverrides: dsOverridesAdapter.getInitialState({
      loading: false,
    }),
    paymentTerms: paymentTermsAdapter.getInitialState({
      loading: false,
    }),
    jdaStatusOptions: jdaStatusOptionsAdapter.getInitialState({
      loading: false,
    }),
    smsSettingsOptions: smsSettingsOptionsAdapter.getInitialState({
      loading: false,
    }),
    customerTerms: customerTermsAdapter.getInitialState({
      loading: false,
    }),
    customerDiscounts: customerDiscountsAdapter.getInitialState({
      loading: false,
    }),
    states: statesAdapter.getInitialState({
      loading: false,
    }),
    provinces: statesAdapter.getInitialState({
      loading: false,
    }),
    counties: countiesAdapter.getInitialState({
      loading: false,
    }),
    accountingTemplates: accountingTemplateAdapter.getInitialState({
      loading: false,
    }),
    inventoryItemTypes: inventoryItemTypeAdapter.getInitialState({
      loading: false,
    }),
    taskTypes: taskTypesAdapter.getInitialState({
      loading: false,
    }),
    monitoringChecklistItems: monitoringChecklistItemsAdapter.getInitialState({
      loading: false,
    }),
    moreFloodsLevels: moreFloodsLevelsAdapter.getInitialState({
      loading: false,
    }),
    moreFloodsFlooring: moreFloodsFlooringAdapter.getInitialState({
      loading: false,
    }),
    moreFloodsJobCategories: moreFloodsJobCategoriesAdapter.getInitialState({
      loading: false,
    }),
    moreFloodsJobNotSecuredDetails:
      moreFloodsJobNotSecuredDetailsAdapter.getInitialState({
        loading: false,
      }),
    moreFloodsReferringPartyFollowup:
      moreFloodsReferringPartyFollowupAdapter.getInitialState({
        loading: false,
      }),
    moreFloodsInfoItems: moreFloodsInfoItemsAdapter.getInitialState({
      loading: false,
    }),
    insuranceCoverageCategories:
      insuranceCoverageCategoriesAdapter.getInitialState({
        loading: false,
      }),
    workAreaResources: {
      loading: false,
      data: {},
    },
    defaultTMTerms: defaltTMTermsAdapter.getInitialState({
      loading: false,
    }),
    separate_category_options: [],
    customer_zip_codes: [],
  },
  reducers: {},
  extraReducers: {
    [fetchTaxonomies.pending](state) {
      state.loading = true;
    },
    [fetchTaxonomies.fulfilled](
      state,
      {
        payload: {
          affiliations,
          certifications,
          compliance_change_reasons,
          customer_file_categories,
          customer_note_status,
          customer_note_types,
          insurance_coverage_categories,
          job_division_file_categories,
          job_division_statuses,
          job_division_types,
          member_file_categories,
          member_note_status,
          member_note_subjects,
          member_note_types,
          mf_flooring_options,
          mf_job_categories,
          mf_job_not_secured_details,
          mf_referring_party_follow_up,
          more_floods_levels,
          national_contracts,
          regions,
          sources_of_loss,
          task_types,
          inventory_item_types,
          mf_monitoring_checklist_items,
          mf_customer_info_sheet_items,
        },
      }
    ) {
      state.loading = false;
      memberAffiliationsAdapter.setAll(state.memberAffiliations, affiliations);
      certificationsAdapter.setAll(state.certifications, certifications);
      complianceChangeReasonsAdapter.setAll(
        state.complianceChangeReasons,
        compliance_change_reasons
      );
      customerFileCategoriesAdapter.setAll(
        state.customerFileCategories,
        customer_file_categories
      );
      customerNoteStatusAdapter.setAll(
        state.customerNoteStatus,
        customer_note_status
      );
      customerNoteTypesAdapter.setAll(
        state.customerNoteTypes,
        customer_note_types
      );
      insuranceCoverageCategoriesAdapter.setAll(
        state.insuranceCoverageCategories,
        insurance_coverage_categories
      );
      jobDivisionFileCategoriesAdapter.setAll(
        state.jobDivisionFileCategories,
        job_division_file_categories
      );
      jobDivisionStatusAdapter.setAll(
        state.jobDivisionStatus,
        job_division_statuses
      );
      jobDivisionTypesAdapter.setAll(
        state.jobDivisionTypes,
        job_division_types
      );
      memberFileCategoriesAdapter.setAll(
        state.memberFileCategories,
        member_file_categories
      );
      memberNoteStatusAdapter.setAll(
        state.memberNoteStatus,
        member_note_status
      );
      memberNoteSubjectsAdapter.setAll(
        state.memberNoteSubjects,
        member_note_subjects
      );
      memberNoteTypesAdapter.setAll(state.memberNoteTypes, member_note_types);
      moreFloodsFlooringAdapter.setAll(
        state.moreFloodsFlooring,
        mf_flooring_options
      );
      moreFloodsJobCategoriesAdapter.setAll(
        state.moreFloodsJobCategories,
        mf_job_categories
      );
      moreFloodsJobNotSecuredDetailsAdapter.setAll(
        state.moreFloodsJobNotSecuredDetails,
        mf_job_not_secured_details
      );
      moreFloodsReferringPartyFollowupAdapter.setAll(
        state.moreFloodsReferringPartyFollowup,
        mf_referring_party_follow_up
      );
      moreFloodsLevelsAdapter.setAll(
        state.moreFloodsLevels,
        more_floods_levels
      );
      nationalContractsAdapter.setAll(
        state.nationalContracts,
        national_contracts
      );
      regionsAdapter.setAll(state.regions, regions);
      sourceOfLossAdapter.setAll(state.sourceOfLoss, sources_of_loss);
      taskTypesAdapter.setAll(state.taskTypes, task_types);
      monitoringChecklistItemsAdapter.setAll(
        state.monitoringChecklistItems,
        mf_monitoring_checklist_items
      );
      inventoryItemTypeAdapter.setAll(
        state.inventoryItemTypes,
        inventory_item_types
      );
      moreFloodsInfoItemsAdapter.setAll(
        state.moreFloodsInfoItems,
        formatCustInfoItems(mf_customer_info_sheet_items)
      );
    },
    [fetchTaxonomies.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchFieldLists.pending](state) {
      state.loading = true;
    },
    [fetchFieldLists.fulfilled](
      state,
      {
        payload: {
          field_customer_discount,
          field_customer_invoice_terms,
          field_customer_status,
          field_loss_category,
          field_job_source,
          field_ds_override,
          field_payment_terms,
          field_status,
          field_jda_status,
          user__field_sms_settings,
        },
      }
    ) {
      state.loading = false;
      customerDiscountsAdapter.setAll(
        state.customerDiscounts,
        field_customer_discount
      );
      customerTermsAdapter.setAll(
        state.customerTerms,
        field_customer_invoice_terms
      );
      customerStatusAdapter.setAll(state.customerStatus, field_customer_status);
      lossCategoriesAdapter.setAll(state.lossCategories, field_loss_category);
      jobSourcesAdapter.setAll(state.jobSources, field_job_source);
      dsOverridesAdapter.setAll(state.dsOverrides, field_ds_override);
      paymentTermsAdapter.setAll(state.paymentTerms, field_payment_terms);
      jdaStatusOptionsAdapter.setAll(state.jdaStatusOptions, field_jda_status);
      smsSettingsOptionsAdapter.setAll(state.smsSettingsOptions, user__field_sms_settings);
      memberStatusAdapter.setAll(state.memberStatus, field_status);
    },
    [fetchFieldLists.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchGlobalData.pending](state) {
      state.loading = true;
    },
    [fetchGlobalData.fulfilled](
      state,
      {
        payload: {
          states,
          counties,
          job_accounting_invoice_options,
          customer_location_zip_codes,
          default_tm_terms,
          separate_category_options
        },
      }
    ) {
      state.loading = false;
      const keys = Object.keys(states);
      const formattedStates = keys.map((key) => ({
        value: key,
        label: states[key],
      }));

      const templates = [];
      for (let key in job_accounting_invoice_options) {
        templates.push({
          machine_name: key,
          label: job_accounting_invoice_options[key],
        });
      }

      const zips = customer_location_zip_codes.filter(
        (zip) => size(zip) > 0 && !isNaN(zip)
      );

      statesAdapter.setAll(state.states, formattedStates);
      countiesAdapter.setAll(state.counties, counties);
      accountingTemplateAdapter.setAll(state.accountingTemplates, templates);
      state.customer_zip_codes = zips;
      defaltTMTermsAdapter.setAll(state.defaultTMTerms, [default_tm_terms]);
      state.separate_category_options = separate_category_options;
    },
    [fetchGlobalData.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchJobDivisionTypes.pending](state) {
      state.jobDivisionTypes.loading = true;
    },
    [fetchJobDivisionTypes.fulfilled](state, { payload: types }) {
      state.jobDivisionTypes.loading = false;
      jobDivisionTypesAdapter.setAll(state.jobDivisionTypes, types);
    },
    [fetchJobDivisionTypes.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchMemberAffiliations.pending](state) {
      state.memberAffiliations.loading = true;
    },
    [fetchMemberAffiliations.fulfilled](state, { payload: types }) {
      state.memberAffiliations.loading = false;
      memberAffiliationsAdapter.setAll(state.memberAffiliations, types);
    },
    [fetchMemberAffiliations.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchServices.pending](state) {
      state.services.loading = true;
    },
    [fetchServices.fulfilled](state, { payload: types }) {
      state.services.loading = false;
      servicesAdapter.setAll(state.services, types);
    },
    [fetchServices.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchCertifications.pending](state) {
      state.certifications.loading = true;
    },
    [fetchCertifications.fulfilled](state, { payload: types }) {
      state.certifications.loading = false;
      certificationsAdapter.setAll(state.certifications, types);
    },
    [fetchCertifications.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchMemberNoteTypes.pending](state) {
      state.memberNoteTypes.loading = true;
    },
    [fetchMemberNoteTypes.fulfilled](state, { payload: types }) {
      state.memberNoteTypes.loading = false;
      memberNoteTypesAdapter.setAll(state.memberNoteTypes, types);
    },
    [fetchMemberNoteTypes.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchMemberNoteStatus.pending](state) {
      state.memberNoteStatus.loading = true;
    },
    [fetchMemberNoteStatus.fulfilled](state, { payload: types }) {
      state.memberNoteStatus.loading = false;
      memberNoteStatusAdapter.setAll(state.memberNoteStatus, types);
    },
    [fetchMemberNoteStatus.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchMemberNoteSubjects.pending](state) {
      state.memberNoteSubjects.loading = true;
    },
    [fetchMemberNoteSubjects.fulfilled](state, { payload: types }) {
      state.memberNoteSubjects.loading = false;
      memberNoteSubjectsAdapter.setAll(state.memberNoteSubjects, types);
    },
    [fetchMemberNoteSubjects.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchCustomerNoteStatus.pending](state) {
      state.customerNoteStatus.loading = true;
    },
    [fetchCustomerNoteStatus.fulfilled](state, { payload: types }) {
      state.customerNoteStatus.loading = false;
      customerNoteStatusAdapter.setAll(state.customerNoteStatus, types);
    },
    [fetchCustomerNoteStatus.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchCustomerNoteTypes.pending](state) {
      state.customerNoteTypes.loading = true;
    },
    [fetchCustomerNoteTypes.fulfilled](state, { payload: types }) {
      state.customerNoteTypes.loading = false;
      customerNoteTypesAdapter.setAll(state.customerNoteTypes, types);
    },
    [fetchCustomerNoteTypes.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchSourceOfLoss.pending](state) {
      state.sourceOfLoss.loading = true;
    },
    [fetchSourceOfLoss.fulfilled](state, { payload: types }) {
      state.sourceOfLoss.loading = false;
      sourceOfLossAdapter.setAll(state.sourceOfLoss, types);
    },
    [fetchSourceOfLoss.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchResources.pending](state) {
      state.resources.loading = true;
    },
    [fetchResources.fulfilled](state, { payload: types }) {
      state.resources.loading = false;
      resourcesAdapter.setAll(state.resources, types);
    },
    [fetchResources.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchRegions.pending](state) {
      state.regions.loading = true;
    },
    [fetchRegions.fulfilled](state, { payload: types }) {
      state.regions.loading = false;
      regionsAdapter.setAll(state.regions, types);
    },
    [fetchRegions.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchServiceActivities.pending](state) {
      state.serviceActivities.loading = true;
    },
    [fetchServiceActivities.fulfilled](state, { payload: types }) {
      state.serviceActivities.loading = false;
      serviceActivitiesAdapter.setAll(state.serviceActivities, types);
    },
    [fetchServiceActivities.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchServiceActivitiesOverrides.pending](state) {
      state.serviceActivities.loading = true;
    },
    [fetchServiceActivitiesOverrides.fulfilled](state, { payload: types }) {
      state.serviceActivities.loading = false;
      serviceActivitiesAdapter.setAll(state.serviceActivities, types);
    },
    [fetchServiceActivitiesOverrides.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchServiceLaborTypes.pending](state) {
      state.serviceLaborTypes.loading = true;
    },
    [fetchServiceLaborTypes.fulfilled](state, { payload: types }) {
      state.serviceLaborTypes.loading = false;
      serviceLaborTypesAdapter.setAll(state.serviceLaborTypes, types);
    },
    [fetchServiceLaborTypes.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchServiceLaborTypesOverrides.pending](state) {
      state.serviceLaborTypes.loading = true;
    },
    [fetchServiceLaborTypesOverrides.fulfilled](state, { payload: types }) {
      state.serviceLaborTypes.loading = false;
      serviceLaborTypesAdapter.setAll(state.serviceLaborTypes, types);
    },
    [fetchServiceLaborTypesOverrides.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchInventoryItemTypes.pending](state) {
      state.inventoryItemTypes.loading = true;
    },
    [fetchInventoryItemTypes.fulfilled](state, { payload: types }) {
      state.inventoryItemTypes.loading = false;
      inventoryItemTypeAdapter.setAll(state.inventoryItemTypes, types);
    },
    [fetchInventoryItemTypes.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchTaskTypes.pending](state) {
      state.taskTypes.loading = true;
    },
    [fetchTaskTypes.fulfilled](state, { payload: types }) {
      state.taskTypes.loading = false;
      taskTypesAdapter.setAll(state.taskTypes, types);
    },
    [fetchTaskTypes.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchMonitoringChecklistItems.pending](state) {
      state.monitoringChecklistItems.loading = true;
    },
    [fetchMonitoringChecklistItems.fulfilled](state, { payload: types }) {
      state.monitoringChecklistItems.loading = false;
      monitoringChecklistItemsAdapter.setAll(
        state.monitoringChecklistItems,
        types
      );
    },
    [fetchMonitoringChecklistItems.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchExpenseTypes.pending](state) {
      state.expenseTypes.loading = true;
    },
    [fetchExpenseTypes.fulfilled](state, { payload: types }) {
      state.expenseTypes.loading = false;
      expenseTypesAdapter.setAll(state.expenseTypes, types);
    },
    [fetchExpenseTypes.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchExpenseTypesOverrides.pending](state) {
      state.expenseTypes.loading = true;
    },
    [fetchExpenseTypesOverrides.fulfilled](state, { payload: types }) {
      state.expenseTypes.loading = false;
      expenseTypesAdapter.setAll(state.expenseTypes, types);
    },
    [fetchExpenseTypesOverrides.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchComplianceChangeReasons.pending](state) {
      state.complianceChangeReasons.loading = true;
    },
    [fetchComplianceChangeReasons.fulfilled](state, { payload: types }) {
      state.complianceChangeReasons.loading = false;
      complianceChangeReasonsAdapter.setAll(
        state.complianceChangeReasons,
        types
      );
    },
    [fetchComplianceChangeReasons.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchJobDivisionFileCategories.pending](state) {
      state.jobDivisionFileCategories.loading = true;
    },
    [fetchJobDivisionFileCategories.fulfilled](state, { payload: types }) {
      state.jobDivisionFileCategories.loading = false;
      jobDivisionFileCategoriesAdapter.setAll(
        state.jobDivisionFileCategories,
        types
      );
    },
    [fetchJobDivisionFileCategories.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchMemberFileCategories.pending](state) {
      state.memberFileCategories.loading = true;
    },
    [fetchMemberFileCategories.fulfilled](state, { payload: types }) {
      state.memberFileCategories.loading = false;
      memberFileCategoriesAdapter.setAll(state.memberFileCategories, types);
    },
    [fetchMemberFileCategories.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchCustomerFileCategories.pending](state) {
      state.customerFileCategories.loading = true;
    },
    [fetchCustomerFileCategories.fulfilled](state, { payload: types }) {
      state.customerFileCategories.loading = false;
      customerFileCategoriesAdapter.setAll(state.customerFileCategories, types);
    },
    [fetchCustomerFileCategories.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchNationalContracts.pending](state) {
      state.nationalContracts.loading = true;
    },
    [fetchNationalContracts.fulfilled](state, { payload: types }) {
      state.nationalContracts.loading = false;
      nationalContractsAdapter.setAll(state.nationalContracts, types);
    },
    [fetchNationalContracts.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [patchNationalContracts.pending](state) {
      state.nationalContracts.loading = true;
    },
    [patchNationalContracts.fulfilled](state, { payload: types }) {
      state.nationalContracts.loading = false;
      nationalContractsAdapter.setAll(state.nationalContracts, types);
    },
    [patchNationalContracts.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchMemberStatus.pending](state) {
      state.memberStatus.loading = true;
    },
    [fetchMemberStatus.fulfilled](state, { payload: types }) {
      state.memberStatus.loading = false;
      memberStatusAdapter.setAll(state.memberStatus, types);
    },
    [fetchMemberStatus.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchJobDivisionStatus.pending](state) {
      state.jobDivisionStatus.loading = true;
    },
    [fetchJobDivisionStatus.fulfilled](state, { payload: types }) {
      state.jobDivisionStatus.loading = false;
      jobDivisionStatusAdapter.setAll(state.jobDivisionStatus, types);
    },
    [fetchJobDivisionStatus.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchCustomerStatus.pending](state) {
      state.customerStatus.loading = true;
    },
    [fetchCustomerStatus.fulfilled](state, { payload: types }) {
      state.customerStatus.loading = false;
      customerStatusAdapter.setAll(state.customerStatus, types);
    },
    [fetchCustomerStatus.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchLossCategories.pending](state) {
      state.lossCategories.loading = true;
    },
    [fetchLossCategories.fulfilled](state, { payload: types }) {
      state.lossCategories.loading = false;
      lossCategoriesAdapter.setAll(state.lossCategories, types);
    },
    [fetchLossCategories.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchJobSources.pending](state) {
      state.jobSources.loading = true;
    },
    [fetchJobSources.fulfilled](state, { payload: types }) {
      state.jobSources.loading = false;
      jobSourcesAdapter.setAll(state.jobSources, types);
    },
    [fetchJobSources.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchCustomerInvoiceTerms.pending](state) {
      state.customerTerms.loading = true;
    },
    [fetchCustomerInvoiceTerms.fulfilled](state, { payload: types }) {
      state.customerTerms.loading = false;
      customerTermsAdapter.setAll(state.customerTerms, types);
    },
    [fetchCustomerInvoiceTerms.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchCustomerInvoiceDiscounts.pending](state) {
      state.customerDiscounts.loading = true;
    },
    [fetchCustomerInvoiceDiscounts.fulfilled](state, { payload: types }) {
      state.customerDiscounts.loading = false;
      customerDiscountsAdapter.setAll(state.customerDiscounts, types);
    },
    [fetchCustomerInvoiceDiscounts.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchStates.pending](state) {
      state.states.loading = true;
    },
    [fetchStates.fulfilled](state, { payload: states }) {
      state.states.loading = false;
      statesAdapter.setAll(state.states, states);
    },
    [fetchStates.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchProvinces.pending](state) {
      state.provinces.loading = true;
    },
    [fetchProvinces.fulfilled](state, { payload: states }) {
      state.provinces.loading = false;
      statesAdapter.setAll(state.provinces, states);
    },
    [fetchProvinces.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchCounties.pending](state) {
      state.counties.loading = true;
    },
    [fetchCounties.fulfilled](state, { payload: counties }) {
      state.counties.loading = false;
      countiesAdapter.setAll(state.counties, counties);
    },
    [fetchCounties.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchCountyZips.pending](state) {
      state.counties.loading = true;
    },
    [fetchCountyZips.fulfilled](state, { payload: counties }) {
      state.counties.loading = false;
    },
    [fetchCountyZips.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchAccountingTemplateOptions.pending](state) {
      state.accountingTemplates.loading = true;
    },
    [fetchAccountingTemplateOptions.fulfilled](state, { payload: templates }) {
      state.accountingTemplates.loading = false;
      accountingTemplateAdapter.setAll(state.accountingTemplates, templates);
    },
    [fetchAccountingTemplateOptions.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchWorkAreaResources.pending](state) {
      state.workAreaResources.loading = true;
    },
    [fetchWorkAreaResources.fulfilled](state, { payload: resources }) {
      state.workAreaResources.loading = false;
      state.workAreaResources.data = resources;
    },
    [fetchWorkAreaResources.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchMoreFloodsLevels.pending](state) {
      state.moreFloodsLevels.loading = true;
    },
    [fetchMoreFloodsLevels.fulfilled](state, { payload: levels }) {
      state.moreFloodsLevels.loading = false;
      moreFloodsLevelsAdapter.setAll(state.moreFloodsLevels, levels);
    },
    [fetchMoreFloodsLevels.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchMoreFloodsFlooring.pending](state) {
      state.moreFloodsFlooring.loading = true;
    },
    [fetchMoreFloodsFlooring.fulfilled](state, { payload: levels }) {
      state.moreFloodsFlooring.loading = false;
      moreFloodsFlooringAdapter.setAll(state.moreFloodsFlooring, levels);
    },
    [fetchMoreFloodsFlooring.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchMoreFloodsJobCategories.pending](state) {
      state.moreFloodsJobCategories.loading = true;
    },
    [fetchMoreFloodsJobCategories.fulfilled](state, { payload: categories }) {
      state.moreFloodsJobCategories.loading = false;
      moreFloodsJobCategoriesAdapter.setAll(
        state.moreFloodsJobCategories,
        categories
      );
    },
    [fetchMoreFloodsJobCategories.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchMoreFloodsJobNotSecuredDetails.pending](state) {
      state.moreFloodsJobNotSecuredDetails.loading = true;
    },
    [fetchMoreFloodsJobNotSecuredDetails.fulfilled](
      state,
      { payload: categories }
    ) {
      state.moreFloodsJobNotSecuredDetails.loading = false;
      moreFloodsJobNotSecuredDetailsAdapter.setAll(
        state.moreFloodsJobNotSecuredDetails,
        categories
      );
    },
    [fetchMoreFloodsJobNotSecuredDetails.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchMoreFloodsReferringPartyFollowup.pending](state) {
      state.moreFloodsReferringPartyFollowup.loading = true;
    },
    [fetchMoreFloodsReferringPartyFollowup.fulfilled](
      state,
      { payload: categories }
    ) {
      state.moreFloodsReferringPartyFollowup.loading = false;
      moreFloodsReferringPartyFollowupAdapter.setAll(
        state.moreFloodsReferringPartyFollowup,
        categories
      );
    },
    [fetchMoreFloodsReferringPartyFollowup.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchMoreFloodsInfoSheetItems.pending](state) {
      state.moreFloodsInfoItems.loading = true;
    },
    [fetchMoreFloodsInfoSheetItems.fulfilled](state, { payload: categories }) {
      state.moreFloodsInfoItems.loading = false;
      moreFloodsInfoItemsAdapter.setAll(state.moreFloodsInfoItems, categories);
    },
    [fetchMoreFloodsInfoSheetItems.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchInsuranceCoverageCategories.pending](state) {
      state.insuranceCoverageCategories.loading = true;
    },
    [fetchInsuranceCoverageCategories.fulfilled](
      state,
      { payload: categories }
    ) {
      state.insuranceCoverageCategories.loading = false;
      insuranceCoverageCategoriesAdapter.setAll(
        state.insuranceCoverageCategories,
        categories
      );
    },
    [fetchInsuranceCoverageCategories.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
  },
});

export const jobDivisionTypesSelectors = jobDivisionTypesAdapter.getSelectors(
  (state) => state.taxonomies.jobDivisionTypes
);

export const memberAffiliationsSelectors =
  memberAffiliationsAdapter.getSelectors(
    (state) => state.taxonomies.memberAffiliations
  );

export const certificationsSelectors = certificationsAdapter.getSelectors(
  (state) => state.taxonomies.certifications
);

export const servicesSelectors = servicesAdapter.getSelectors(
  (state) => state.taxonomies.services
);

export const memberNoteTypesSelectors = memberNoteTypesAdapter.getSelectors(
  (state) => state.taxonomies.memberNoteTypes
);

export const memberNoteStatusSelectors = memberNoteStatusAdapter.getSelectors(
  (state) => state.taxonomies.memberNoteStatus
);

export const memberNoteSubjectsSelectors =
  memberNoteSubjectsAdapter.getSelectors(
    (state) => state.taxonomies.memberNoteSubjects
  );

export const customerNoteStatusSelectors =
  customerNoteStatusAdapter.getSelectors(
    (state) => state.taxonomies.customerNoteStatus
  );

export const customerNoteTypesSelectors = customerNoteTypesAdapter.getSelectors(
  (state) => state.taxonomies.customerNoteTypes
);

export const sourceOfLossSelectors = sourceOfLossAdapter.getSelectors(
  (state) => state.taxonomies.sourceOfLoss
);

export const resourcesSelectors = resourcesAdapter.getSelectors(
  (state) => state.taxonomies.resources
);

export const regionsSelectors = regionsAdapter.getSelectors(
  (state) => state.taxonomies.regions
);

export const serviceActivitiesSelectors = serviceActivitiesAdapter.getSelectors(
  (state) => state.taxonomies.serviceActivities
);

export const serviceLaborTypesSelectors = serviceLaborTypesAdapter.getSelectors(
  (state) => state.taxonomies.serviceLaborTypes
);

export const expenseTypesSelectors = expenseTypesAdapter.getSelectors(
  (state) => state.taxonomies.expenseTypes
);

export const complianceChangeReasonsSelectors =
  complianceChangeReasonsAdapter.getSelectors(
    (state) => state.taxonomies.complianceChangeReasons
  );

export const jobDivisionFileCategoriesSelectors =
  jobDivisionFileCategoriesAdapter.getSelectors(
    (state) => state.taxonomies.jobDivisionFileCategories
  );

export const memberFileCategoriesSelectors =
  memberFileCategoriesAdapter.getSelectors(
    (state) => state.taxonomies.memberFileCategories
  );

export const customerFileCategoriesSelectors =
  customerFileCategoriesAdapter.getSelectors(
    (state) => state.taxonomies.customerFileCategories
  );

export const nationalContractsSelectors = nationalContractsAdapter.getSelectors(
  (state) => state.taxonomies.nationalContracts
);

export const memberStatusSelectors = memberStatusAdapter.getSelectors(
  (state) => state.taxonomies.memberStatus
);

export const jobDivisionsStatusSelectors =
  jobDivisionStatusAdapter.getSelectors(
    (state) => state.taxonomies.jobDivisionStatus
  );

export const customerStatusSelectors = customerStatusAdapter.getSelectors(
  (state) => state.taxonomies.customerStatus
);

export const lossCategoriesSelectors = lossCategoriesAdapter.getSelectors(
  (state) => state.taxonomies.lossCategories
);

export const jobSourcesSelectors = jobSourcesAdapter.getSelectors(
  (state) => state.taxonomies.jobSources
);

export const dsOverridesSelectors = dsOverridesAdapter.getSelectors(
  (state) => state.taxonomies.dsOverrides
);

export const paymentTermsSelectors = paymentTermsAdapter.getSelectors(
  (state) => state.taxonomies.paymentTerms
);

export const jdaStatusOptionsSelectors = jdaStatusOptionsAdapter.getSelectors(
  (state) => state.taxonomies.jdaStatusOptions
);

export const smsSettingsOptionsSelectors = smsSettingsOptionsAdapter.getSelectors(
  (state) => state.taxonomies.smsSettingsOptions
);

export const customerTermsSelectors = customerTermsAdapter.getSelectors(
  (state) => state.taxonomies.customerTerms
);

export const customerDiscountsSelectors = customerDiscountsAdapter.getSelectors(
  (state) => state.taxonomies.customerDiscounts
);

export const statesSelectors = statesAdapter.getSelectors(
  (state) => state.taxonomies.states
);

export const provincesSelectors = statesAdapter.getSelectors(
  (state) => state.taxonomies.provinces
);

export const countiesSelectors = countiesAdapter.getSelectors(
  (state) => state.taxonomies.counties
);

export const accountingTemplateSelectors =
  accountingTemplateAdapter.getSelectors(
    (state) => state.taxonomies.accountingTemplates
  );

export const inventoryItemTypeSelectors = inventoryItemTypeAdapter.getSelectors(
  (state) => state.taxonomies.inventoryItemTypes
);

export const taskTypesSelectors = taskTypesAdapter.getSelectors(
  (state) => state.taxonomies.taskTypes
);

export const monitoringChecklistItemsSelectors =
  monitoringChecklistItemsAdapter.getSelectors(
    (state) => state.taxonomies.monitoringChecklistItems
  );

export const moreFloodsLevelsSelectors = moreFloodsLevelsAdapter.getSelectors(
  (state) => state.taxonomies.moreFloodsLevels
);

export const moreFloodsFlooringSelectors =
  moreFloodsFlooringAdapter.getSelectors(
    (state) => state.taxonomies.moreFloodsFlooring
  );

export const moreFloodsJobCategoriesSelectors =
  moreFloodsJobCategoriesAdapter.getSelectors(
    (state) => state.taxonomies.moreFloodsJobCategories
  );

export const moreFloodsJobNotSecuredDetailsSelectors =
  moreFloodsJobNotSecuredDetailsAdapter.getSelectors(
    (state) => state.taxonomies.moreFloodsJobNotSecuredDetails
  );

export const moreFloodsReferringPartyFollowupSelectors =
  moreFloodsReferringPartyFollowupAdapter.getSelectors(
    (state) => state.taxonomies.moreFloodsReferringPartyFollowup
  );

export const moreFloodsInfoItemsSelectors =
  moreFloodsInfoItemsAdapter.getSelectors(
    (state) => state.taxonomies.moreFloodsInfoItems
  );

export const insuranceCoverageCategoriesSelectors =
  insuranceCoverageCategoriesAdapter.getSelectors(
    (state) => state.taxonomies.insuranceCoverageCategories
  );

// Custom selectors
const selectSelf = (state) => state;
export const getJobDivisionTypesLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.taxonomies.jobDivisionTypes.loading
);

export const getMemberAffiliationsLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.taxonomies.memberAffiliations.loading
);

export const getServicesLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.taxonomies.services.loading
);

export const getCountiesLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.taxonomies.counties.loading
);

export const getAreaResourcesLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.taxonomies.workAreaResources.loading
);

export const getAccountingTemplatesLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.taxonomies.accountingTemplates.loading
);

export const getAreaResourcesDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.taxonomies.workAreaResources.data
);

export const getJobDivisionStatusLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.taxonomies.jobDivisionStatus.loading
);

//   export const { setAllUsers, addOneUser, updateOneUser } = usersSlice.actions;

export default taxonomiesSlice.reducer;
