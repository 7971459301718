import { createTheme } from "@mui/material/styles";
import variables from './base/_variables.scss';

export const theme = createTheme({
  breakpoints: {
    values: {
      xxs: 0,
      xs: 480,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1800,
      short: 680,
    },
  },
  typography: {
    fontFamily: 'Source Sans Pro,"Helvetica Neue",Arial,sans-serif',
    color: 'var(--color-black)',
  },
  components: {
    MuiInput: {
      styleOverrides: {
        input: {
          padding: "8px 5px 8px 5px",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorDockedLeft: {
          position: "relative",
          width: "240px",
          flex: "1",
          zIndex: "1",
          background: "white",
        },
        paperAnchorRight: {
          width: "420px",
          maxWidth: "90%",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {},
        positionStatic: {},
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          zIndex: 5001,
          border: "1px solid var(--color-gray-dark-alpha)",
          boxShadow: "0 3px 12px var(--color-gray-darker-alpha)",
          borderRadius: 3,
          fontSize: 13,
          color: "var(--color-gray-dark)",
          backgroundColor: "white",
        },
        root: {
          '.sub-heading': {
            fontWeight: "bold",
            paddingLeft: "0.5rem",
          },
        }
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
        },
        stickyHeader: {
          backgroundColor: "white",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        wrapper: {
          fontWeight: "600",
        },
        root: {
          color: "var(--color-gray-darker)",
          '&.Mui-selected': {
            backgroundColor: 'var(--color-gray-background)',
            color: 'var(--color-gray-dark)',
          },
          '> div:first-of-type': {
            position: 'relative',
            zIndex: '1',
          },
          '& .MuiTouchRipple-root span': {
            backgroundColor: "var(--color-gray)",
            borderRadius: '50%',
            opacity: "0.5 !important",
          }
        },
        textColorPrimaryInverse: {
          color: "var(--color-white)",
          '&.Mui-selected': {
            backgroundColor: 'var(--color-gray-background)',
            color: 'var(--color-gray-dark)',
          },
        }
      },
    },
    MuiTabs: {
      styleOverrides: {
        vertical: {
          width: "200px",
        },
        indicator: {
          backgroundColor: "var(--color-blue-dark)",
        },
        root: {
          '&[color="primaryInverse"] .MuiTabs-indicator': {
            backgroundColor: 'var(--color-blue-lighter)',
            height: '4px',
          },
          '&[color="primaryInverse"] .MuiTabs-scrollButtons': {
            color: 'var(--color-white)',
          }
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primaryBold: {
          fontWeight: "bold",
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          background: "var(--color-blue-dark)",
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          "&.Mui-active": {
            color: "var(--color-white) !important",
          },
          "&.Mui-completed": {
            color: "var(--color-white) !important",
          },
          ".MuiStepIcon-root": {
            color: "var(--color-secondary-faded)",
          },
          ".MuiStepIcon-root.Mui-active": {
            color: "var(--color-secondary)",
          },
        },
        label: {
          color: "rgba(0,0,0,0.8)",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h6: {
          textTransform: "uppercase",
        },
        subtitle1: {
          textTransform: "uppercase",
          fontWeight: 600,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          fontSize: 13,
        },
        paper: {
          border: "1px solid var(--color-gray-dark-alpha)",
          boxShadow: "0 3px 12px var(--color-gray-darker-alpha)",
          borderRadius: 3,
          zIndex: 1,
          fontSize: 13,
          color: "var(--color-gray-dark)",
          backgroundColor: "white",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: "1rem",

          "&.MuiTextField-root": {
            marginTop: 0,
            marginBottom: "1rem",
          },

          ".Mui-disabled, .Mui-disabled .MuiInputAdornment-root, .Mui-disabled .MuiInputAdornment-root .MuiTypography-root": {
            color: variables.colorGrayAlphaLower,
          },
          ".MuiInputLabel-root:has(+ .Mui-disabled), .MuiInputLabel-root:has(+ .Mui-disabled) .MuiTypography-root": {
            color: variables.colorGrayAlphaLower,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          border: "1px solid var(--color-gray-dark-alpha)",
          boxShadow: "0 3px 12px var(--color-gray-darker-alpha)",
          borderRadius: 3,
          zIndex: 1,
          fontSize: 13,
          color: "var(--color-gray-dark)",
          backgroundColor: "white",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        colorPrimary: {
          ".MuiSwitch-thumb": {
            color: "var(--color-gray-lighter)",
          },
          "&.Mui-checked .MuiSwitch-thumb": {
            color: "var(--color-gray-lightest)",
          },
          '+ .MuiSwitch-track': {
            backgroundColor: "var(--color-gray)",
          },
          '&.Mui-checked+ .MuiSwitch-track': {
            backgroundColor: "var(--color-blue-alt)",
          },
        },
        colorPrimaryInverse: {
          ".MuiSwitch-thumb": {
            color: "var(--color-gray-light)",
          },
          "&.Mui-checked .MuiSwitch-thumb": {
            color: "var(--color-blue-alt)"
          },
          '+ .MuiSwitch-track': {
            backgroundColor: "var(--color-gray)",
          }
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: "0.5rem",
        },
        outlined: {
          border: "1px solid var(--color-gray-dark-alpha)",
          boxShadow: "0 3px 12px var(--color-gray-darker-alpha)",
          fontSize: 13,
          color: "var(--color-gray-dark)",
          backgroundColor: "var(--color-white)",
        },
        elevation2: {
          boxShadow: "0 3px 12px var(--color-gray-darker-alpha)",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: "var(--color-gray-lightest)",
          ".MuiOutlinedInput-input": {
            paddingTop: '1.15em',
            paddingBottom: '1em',
          },
          "&.MuiInputBase-multiline .MuiOutlinedInput-input": {
            padding: '0',
          },
          ".MuiOutlinedInput-input.MuiInputBase-inputSizeSmall": {
            paddingBottom: '6px',
          },
          "&.MuiInputBase-sizeSmall.MuiAutocomplete-inputRoot": {
            paddingBottom: '6px',
            paddingTop: '12px',
          },
          ".MuiOutlinedInput-notchedOutline": {
            top: "0",
          },
          ".MuiOutlinedInput-notchedOutline legend": {
            display: "none",
          },
          ".MuiInputAdornment-positionStart ": {
            marginTop: '1em',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "var(--color-gray-light)",
          borderBottomRightRadius: "4px",
          borderBottomLeftRadius: "4px",
          "&:hover": {
            backgroundColor: "var(--color-gray-lighter)",
            borderBottomRightRadius: "0",
            borderBottomLeftRadius: "0",
          },
          "&.Mui-focused": {
            backgroundColor: "var(--color-gray-light)",
            borderBottomRightRadius: "0",
            borderBottomLeftRadius: "0",
          },
        },
        underline: {
          "&:before": {
            borderBottom: "1px solid transparent",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          "&.Mui-focused, &.MuiFormLabel-filled, &.MuiInputLabel-shrink": {
            transform: "translate(14px, 1px) scale(0.7)",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ".MuiListItemIcon-root": {
            minWidth: "0px",
            ".MuiCheckbox-root": {
              minWidth: "36px",
            }
          }
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "0px",
          marginRight: "1rem",
          width: "20px",
          color: 'var(--color-gray-dark)',
          'svg': {
            width: "100%",
            height: "auto",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: 'var(--color-gray-dark)',
        },
        colorPrimaryInverse: {
          color: 'var(--color-gray-lighter)',
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          flexDirection: "row",
          paddingTop: "0",
        },
        label: {
          display: "none",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "0.5rem 1.25rem",
          borderTop: '1px solid var(--color-gray-medium)',
        },
      },
    },
  },
  mixins: {},
  palette: {
    text: {
      hint: variables.colorGrayAlphaLow,
    },
    mode: "light",
    type: "light",
    primary: {
      light: variables.colorGray,
      main: variables.colorGrayDark,
      dark: variables.colorGrayDarkest,
      contrastText: variables.colorWhite,
    },
    primaryInverse: {
      light: variables.colorWhite,
      main: variables.colorGrayLighter,
      dark: variables.colorGray,
      contrastText: variables.colorGrayDarkest,
    },
    secondary: {
      // light: "#AD19CF",
      // main: "#8b5cf6",
      // dark: "#6D1082",
      light: variables.colorBlueLight,
      main: variables.colorBlue,
      dark: variables.colorBlueDark,
      contrastText: variables.colorWhite,
    },
    secondaryInverse: {
      main: variables.colorBlueLight,
      light: variables.colorBlueLighter,
      dark: variables.colorBlueDark,
      contrastText: variables.colorWhite,
    },
    tertiary: {
      light: variables.colorYellow,
      main: variables.colorOrange,
      dark: variables.colorRed,
      contrastText: variables.colorWhite,
    },
    default: {
      light: variables.colorGrayLightest,
      main: variables.colorGrayAlphaMid,
      dark: variables.colorGrayDark,
      contrastText: variables.colorWhite,
    },
    action: {
      active: variables.colorGrayAlphaMid,
      hover: variables.colorGrayAlphaLowest,
      selected: variables.colorGrayAlphaLower,
      disabled: variables.colorGrayAlphaLow,
      disabledBackground: variables.colorGrayAlphaLower,
      focus: variables.colorGrayAlphaLower,
    },
    grey: {
      50: variables.colorGrayLightest,
      100: variables.colorGrayLightest,
      200: variables.colorGrayLighter,
      300: variables.colorGrayLight,
      400: variables.colorGray,
      500: variables.colorGrayMiddle,
      600: variables.colorGrayMedium,
      700: variables.colorGrayDark,
      800: variables.colorGrayDarker,
      900: variables.colorGrayDarkest,
      A100: variables.colorGrayMiddle,
      A200: variables.colorGrayMedium,
      A400: variables.colorGrayDarkest,
      A700: variables.colorGrayDark,
    },
  },
});
