import React from "react";
import chroma from "chroma-js";
import "chartjs-plugin-annotation";
import "chartjs-plugin-datalabels";
import { Doughnut } from "react-chartjs-2";
import { Typography } from "@mui/material";
import styled from "styled-components";
import constants from "components/constants";

const analyticsIcon = `${process.env.PUBLIC_URL}/images/icons/icon-analytics--large.svg`;

const Container = styled.div`
  // border-radius: 3px;
  // background: white;
  // padding: 1rem;
  // box-shadow: 0 0 6px ${constants.colorBlack}1a;
`;

const Loader = styled.div`
  width: 100%;
  height: 186px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const options = {
  responsive: true,
  maintainAspectRatio: true,
  legend: {
    position: "left",
  },
  tooltips: {
    backgroundColor: constants.colorGrayLightest,
    titleFontColor: constants.colorGrayDarker,
    bodyFontColor: constants.colorGrayDark,
    bodySpacing: 4,
    xPadding: 12,
    mode: "nearest",
    intersect: 0,
    position: "nearest",
    callbacks: {
      label: function (tooltipItems, data) {
        const value = Number(data.datasets[0].data[tooltipItems.index]).toFixed(
          2
        );
        const label = data.labels[tooltipItems.index];
        return `${label}: $${value}`;
      },
    },
  },
};

const DonutChart = ({ chartData }) => {
  const data = (canvas) => {
    const numTotals = chartData.labels.length;
    const gradient = chroma
      .scale([constants.colorBlueLight, constants.colorBlueDarker])
      .mode("lch")
      .colors(numTotals);

    return {
      datasets: [
        {
          datalabels: {
            display: false,
          },
          data: chartData.data,
          backgroundColor: chartData.labels.map((label, i) => gradient[i]),
        },
      ],
      labels: chartData.labels,
    };
  };

  return (
    <Container>
      {chartData?.data?.length > 0 ? (
        <Doughnut data={data} options={options} />
      ) : (
        <Loader>
          <img src={analyticsIcon} alt="No Data to display" />
          <Typography variant="subtitle1" color="textSecondary">
            No Data to Display
          </Typography>
        </Loader>
      )}
    </Container>
  );
};

DonutChart.propTypes = {};

export default DonutChart;
