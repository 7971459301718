import React, { useEffect, lazy, Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import axios from "axios";
import {
  Switch,
  Redirect,
  Route,
  useLocation,
  useHistory,
} from "react-router-dom";
import { Snackbar } from "@mui/material";
import { size, first } from "lodash";
import ReactGA from "react-ga";
import Echo from "laravel-echo";

import PermissionsProvider from "../../providers/PermissionsProvider";

import Alert from "common/Alert";

// Routing
import GuestRoute from "../Routes/GuestRoute";
import UserRoute from "../Routes/UserRoute";
import SSORoute from "../Routes/SSORoute";
import MBYSSORoute from "../Routes/MBYSSORoute";
import FullScreenUserRoute from "../Routes/FullScreenUserRoute";
import { Terms, Privacy } from "../../features/Pages";

import { theme } from "../../styles/muiTheme";

import { snackbarExiting, snackbarExited } from "../../actions/snackbarActions";

import { Loader } from "./Styled";
import { hasPermission } from "../../utility";

import {
  fetchTaxonomies,
  fetchFieldLists,
  fetchGlobalData,
  fetchProvinces,
} from "../../features/Taxonomies/taxonomiesSlice";
import {
  checkIsValidToken,
  fetchCurrentUser,
  getIsAuthenticatedSelector,
  postHelixApiToken,
} from "../../features/Auth/authSlice";
import Elocal from "features/Elocal";
import { getHubEnvironments } from "features/Hub/utils.js";
// import { clientSelectorSet } from "actions/clientSelectorActions";
import { setEnvironments, setCurrentEnvironment, clearEnvironments } from "features/Hub/hubSlice.js";
import PublicRoute from "components/Routes/PublicRoute";
import { fetchAPIUserCurrent } from "features/User/userSlice";
import AddJob from "features/Job/forms/AddJob";
import { default as AddCustomer } from "features/Customer/forms/Add";
import { default as AddMember } from "features/Member/forms/Add";
import { updateFromWebSocket } from "utility/webSocketDataUpdates";
import conf from "config";
import { TimeClock } from "components/TimeClock";
import TimeClockQRCode from "components/TimeClock/TimeClockQRCode";
import TimeClockAlias from "components/TimeClock/TimeClockAlias";

const baseUrl = conf.helix_api_url;
const instance = axios.create({
  baseURL: `${baseUrl}/api/`,
});

const Login = lazy(() => import("../Login"));
const EntityRedirect = lazy(() => import("components/EntityRedirect"));
const User = lazy(() => import("../User"));
const Jobs = lazy(() => import("../../features/Divisions"));
const Job = lazy(() => import("../../features/Job"));
const Members = lazy(() => import("../../features/Members"));
const Member = lazy(() => import("../../features/Member"));
const Customers = lazy(() => import("../../features/Customers"));
const Customer = lazy(() => import("../../features/Customer"));
const Location = lazy(() => import("../../features/Location"));
const AddLocation = lazy(() => import("../../features/Location/forms/Add"));
const Tasks = lazy(() => import("../../features/Tasks/components/GlobalTasks"));
const ComplianceManager = lazy(() => import("../ComplianceManager"));
const Mapping = lazy(() => import("../Mapping"));
const NotFound = lazy(() => import("../NotFound"));
const UserInfo = lazy(() => import("../UserInfo"));
const Users = lazy(() => import("features/Users"));
const PasswordReset = lazy(() => import("../PasswordReset"));
const Reports = lazy(() => import("../Reports"));
const Inventory = lazy(() =>
  import("../../features/Inventory/components/Dashboard")
);
const FormBuilder = lazy(() => import("../../features/FormBuilder"));
const FormShare = lazy(() =>
  import("../../features/FormBuilder/components/FormShare")
);
const ImageInteractionLandingPage = lazy(() =>
  import("components/ImageInteraction/ImageInteractionLandingPage")
);
const Floorplan = lazy(() => import("../../features/Floorplanner/Floorplan"));
const Hub = lazy(() => import("../../features/Hub"));
const Settings = lazy(() => import("../../features/Settings"));

// const logoMark = `${process.env.PUBLIC_URL}/images/logo-mark.svg`;

export const App = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const snackbar = useSelector((state) => state.app.snackbar);
  const userProcessed = useSelector((state) => state.auth.user.data._processed);
  const user = useSelector((state) => state.auth.user.data);
  const token = useSelector(
    (state) => state.auth.user?.data?._processed?.phx_offsite_api_token
  );
  const isAuthenticated = useSelector(getIsAuthenticatedSelector);


  React.useEffect(() => {
    trackPageView(user); // To track the first pageview upon load
    history.listen(trackPageView, [user]); // To track the subsequent pageviews
  }, [history, user]);

  const trackPageView = (user) => {
    ReactGA.set({
      user_id: user?.uid,
      hlx_id: user?.uid,
      name: user?.first + " " + user?.last,
    });
    ReactGA.pageview(window.location.pathname);
  };

// Check the main token, and set the CSRF token
  useEffect(() => {
    const promise = dispatch(checkIsValidToken());

    return () => {
      promise.abort();
    };
  }, [dispatch]);

//  Load the current user's data
  useEffect(() => {
    let promise = null;
    if (isAuthenticated) {
      promise = dispatch(fetchCurrentUser());
    }

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [dispatch, isAuthenticated]); //

//  Get Helix API (Laravel) token
  useEffect(() => {
    if (token && isAuthenticated) {
      dispatch(fetchAPIUserCurrent());
    }
  }, [token, dispatch, isAuthenticated]);

//  Connect to Pusher
  useEffect(() => {
    if (token && isAuthenticated && user?.field_clients) {
    // Set Hub Environments and Selected PHX Client
      const envs = getHubEnvironments(user);
      dispatch(setEnvironments(envs));
      let env = first(envs);
      let selEnv = null;
      const sel = Number(localStorage.getItem('hub_environment'));
      if(sel){
        selEnv = first(envs.filter((_env) => _env.nid === sel));
      }
      if(selEnv){
        env = selEnv;
      }
      if(env){
        dispatch(setCurrentEnvironment(env));
      }

      if(!window.Echo){
        window.Echo = new Echo({
          broadcaster: "pusher",
          key: conf.pusher_key,
          cluster: "us2",
          encrypted: true,
          authorizer: (channel, options) => {
            return {
              authorize: (socketId, callback) => {
                instance
                  .post(
                    `broadcasting/auth`,
                    {
                      socket_id: socketId,
                      channel_name: channel.name,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((response) => {
                    callback(false, response.data);

                    if(!window.Echo.connector.channels[`presence-data-updates.${user.uid}`]){
                      window.Echo.join(`data-updates.${user.uid}`).listen("DataUpdatesUser", (e) => {
                        updateFromWebSocket(e);
                      });
                    }

                    // user.field_clients.forEach((client) => {
                    //   if(!window.Echo.connector.channels[`presence-data-updates.client.${client.nid}`]){
                    //     window.Echo.join(`data-updates.client.${client.nid}`).listen("DataUpdatesClient", (e) => {
                    //       updateFromWebSocket(e);
                    //     });
                    //   }
                    // });
                    envs.forEach((env) => {
                      if(!window.Echo.connector.channels[`presence-data-updates.environment.${env.nid}`]){
                        window.Echo.join(`data-updates.environment.${env.nid}`).listen("DataUpdatesEnvironment", (e) => {
                          updateFromWebSocket(e);
                        });
                      }
                    });
                  })
                  .catch((error) => {
                    callback(true, error);
                  });
              },
            };
          },
        });
      }
      else{
        console.log('reconnect');
        window.Echo.connect();
      }

      return () => {
        // window.Echo.leave(`data-updates.${user.uid}`);
        window.Echo.disconnect();
        dispatch(clearEnvironments());
      };
    }
  }, [token, dispatch, isAuthenticated, user.field_clients]); //  eslint-disable-line react-hooks/exhaustive-deps

//  Load the Helix API (Laravel) token
  useEffect(() => {
    if (
      Boolean(user?.uid) &&
      !Boolean(userProcessed.phx_offsite_api_token) &&
      isAuthenticated
    ) {
      dispatch(postHelixApiToken());
    }
  }, [dispatch, userProcessed, user, isAuthenticated]);

//  Load global field options data
  useEffect(() => {
    let promise;
    if (isAuthenticated) {
      promise = dispatch(fetchGlobalData());
    }

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [dispatch, isAuthenticated]);

//  Load taxonomies
  useEffect(() => {
    let promise;
    if (isAuthenticated) {
      promise = dispatch(fetchTaxonomies());
    }

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    let promise;
    if (isAuthenticated) {
      promise = dispatch(fetchFieldLists());
    }

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    let promise;
    if (isAuthenticated) {
      promise = dispatch(fetchProvinces());
    }

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [dispatch, isAuthenticated]);

// Set Hub Environments and Selected PHX Client
  // useEffect(() => {
  //   let promise;
  //   if (isAuthenticated && user?.field_clients) {
  //     const envs = getHubEnvironments(user);
  //     dispatch(setEnvironments(envs));
  //     let env = first(envs);
  //     let selEnv = null;
  //     // let selClient = localStorage.getItem('selected_phx_client');
  //   //  Set previously selected
  //     const sel = Number(localStorage.getItem('hub_environment'));
  //     if(sel){
  //       selEnv = first(envs.filter((_env) => _env.nid === sel));
  //     }
  //   //  or set the previously selected PHX Client
  //     // else if(selClient){
  //     //   selClient = JSON.parse(selClient);
  //     //   selEnv = first(envs.filter((_env) => _env.nid === selClient.nid));
  //     // }
  //     if(selEnv){
  //       env = selEnv;
  //     }
  //     if(env){
  //       dispatch(setCurrentEnvironment(env));
  //     }
  // // //  Set the client state too
  // //     if(!selClient){
  // //       const { nid, title } = first(user?.field_clients);
  // //       selClient = { nid, name: title };
  // //     }
  // //     dispatch(clientSelectorSet(selClient));
  //   }

  //   return () => {
  //     if (promise) {
  //       dispatch(clearEnvironments());
  //       promise.abort();
  //     }
  //   };
  // }, [dispatch, isAuthenticated, user.field_clients]); //  eslint-disable-line react-hooks/exhaustive-deps

  let location = useLocation();

  const closeSnackbar = () => {
    dispatch(snackbarExiting());
  };

  const clearSnackbar = () => {
    dispatch(snackbarExited());
  };

  return isAuthenticated != null ? (
    <PermissionsProvider permissions={userProcessed.roles}>
      <div data-test="app">
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Suspense fallback={<AppLoading />}>
              <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={snackbar?.show ? true : false}
                autoHideDuration={snackbar?.kind === "warning" ? null : 6000}
                onClose={closeSnackbar}
                TransitionProps={{
                  onExited: clearSnackbar,
                }}
              >
                <div> {/* Prevents a fatal error where Snackbar wants an element that can hold a ref */}
                  <Alert kind={snackbar.kind} onClose={closeSnackbar}>
                    {snackbar.msg}
                  </Alert>
                </div>
              </Snackbar>
              <Switch location={location}>
                <Route exact path="/">
                  <Redirect to="/login" />
                </Route>
                <GuestRoute path="/login">
                  <Login />
                </GuestRoute>
                <FullScreenUserRoute
                  exact
                  path="/job/:jobName/floor-builder/:id"
                >
                  <Floorplan />
                </FullScreenUserRoute>
                <PublicRoute exact path="/forms-public-submission/:token">
                  <FormShare />
                </PublicRoute>
                <Route exact path="/terms">
                  <Terms />
                </Route>
                <Route exact path="/privacy">
                  <Privacy />
                </Route>
                <Route exact path="/user/activate/:uid/:timestamp/:hash">
                  <PasswordReset activate={true} />
                </Route>
                <Route exact path="/user/reset/:uid/:timestamp/:hash">
                  <PasswordReset />
                </Route>
                <PublicRoute exact path="/public/photo-upload/:hash">
                  <Elocal />
                </PublicRoute>
                <UserRoute path="/node/:id">
                  <EntityRedirect entityType="node" />
                </UserRoute>
                <UserRoute path="/user-redirect/:id">
                  <EntityRedirect entityType="user" />
                </UserRoute>
                <UserRoute path="/forms">
                  <FormBuilder />
                </UserRoute>
                <UserRoute path="/inventory">
                  <Inventory />
                </UserRoute>
                <SSORoute path="/wp-sso" />
                <MBYSSORoute path="/mby-sso" />
                <UserRoute path="/user">
                  <User />
                </UserRoute>
                {(isAuthenticated && size(userProcessed.roles)) && (
                  <UserRoute path="/hub">
                    {/* <Intranet /> */}
                    {!hasPermission(userProcessed.roles, [
                      "customer",
                      "mby_agent",
                    ]) ? (
                      <Hub />
                    ) : (
                      <Redirect to="/user/profile" />
                    )}
                  </UserRoute>
                )}
                <UserRoute path="/jobs">
                  <Jobs />
                </UserRoute>
                <UserRoute path="/job/add">
                  <AddJob />
                </UserRoute>
                <UserRoute path="/job/:jobName">
                  <Job />
                </UserRoute>
                {(!isAuthenticated /* Use UserRoute's redirect if not logged-in */
                  || !userProcessed.is_affiliate_member || userProcessed.is_d2d
                ) && (
                  <UserRoute path="/members">
                    <Members />
                  </UserRoute>
                )}
                <UserRoute path="/member/add/:id">
                  <AddMember />
                </UserRoute>
                <UserRoute path="/member/add">
                  <AddMember />
                </UserRoute>
                <UserRoute path="/member/:name">
                  <Member />
                </UserRoute>
                {(!isAuthenticated /* Use UserRoute's redirect if not logged-in */
                  || hasPermission(userProcessed.roles, [
                  "admin",
                  "phx_sub_admin",
                  "phx_client_admin",
                  "operations",
                  "accounting",
                  "compliance_admin",
                  "member_admin_agent",
                  "member_estimator",
                  "mby_agent",
                ])) && (
                  <UserRoute path="/customers">
                    <Customers />
                  </UserRoute>
                )}
                <UserRoute path="/customer/add/:id">
                  <AddCustomer />
                </UserRoute>
                <UserRoute path="/customer/add">
                  <AddCustomer />
                </UserRoute>
                {(!isAuthenticated /* Use UserRoute's redirect if not logged-in */
                  || hasPermission(userProcessed.roles, [
                  "admin",
                  "phx_sub_admin",
                  "phx_client_admin",
                  "operations",
                  "accounting",
                  "compliance_admin",
                  "member_admin_agent",
                  "member_estimator",
                  "member_field_agent",
                  "customer",
                  "mby_agent",
                ])) && (
                  <UserRoute path="/customer/:name">
                    <Customer />
                  </UserRoute>
                )}
                <UserRoute path="/location/add/:id">
                  <AddLocation />
                </UserRoute>
                <UserRoute path="/location/add">
                  <AddLocation />
                </UserRoute>
                {(!isAuthenticated /* Use UserRoute's redirect if not logged-in */
                  || hasPermission(userProcessed.roles, [
                  "admin",
                  "phx_sub_admin",
                  "phx_client_admin",
                  "operations",
                  "accounting",
                  "compliance_admin",
                  "member_admin_agent",
                  "member_estimator",
                  "member_field_agent",
                  "customer",
                  "mby_agent",
                ])) && (
                  <UserRoute path="/location/:name">
                    <Location />
                  </UserRoute>
                )}
                <UserRoute path="/tasks">
                  <Tasks />
                </UserRoute>
                {(!isAuthenticated /* Use UserRoute's redirect if not logged-in */
                  || hasPermission(userProcessed.roles, [
                  "admin",
                  "phx_sub_admin",
                  "phx_client_admin",
                  "operations",
                  "accounting",
                  "compliance_admin",
                ])) && (
                  <UserRoute path="/compliance-manager">
                    <ComplianceManager />
                  </UserRoute>
                )}
                <UserRoute path="/map">
                  <Mapping />
                </UserRoute>
                {(!isAuthenticated /* Use UserRoute's redirect if not logged-in */
                  || hasPermission(userProcessed.roles, [
                  "admin",
                  "phx_sub_admin",
                  "phx_client_admin",
                  "operations",
                  "accounting",
                  "compliance_admin",
                ])) && (
                  <UserRoute path="/reports">
                    <Reports />
                  </UserRoute>
                )}
                <UserRoute path="/users/:name">
                  <UserInfo />
                </UserRoute>
                <UserRoute path="/settings">
                  <Settings />
                </UserRoute>
                {(!isAuthenticated /* Use UserRoute's redirect if not logged-in */
                  || hasPermission(userProcessed.roles, [
                    "admin",
                    "phx_sub_admin",
                    "phx_client_admin",
                    "operations",
                    "member_admin_agent",
                ])) && (
                  <UserRoute path="/users">
                    <Users />
                  </UserRoute>
                )}
                <PublicRoute exact path="/file-share/:hash">
                  <ImageInteractionLandingPage />
                </PublicRoute>
                <UserRoute simpleLayout path="/time-clock/qr-code/:jobName">
                  <TimeClockQRCode inWindow />
                </UserRoute>
                <PublicRoute exact path={["/time-clock/:hash", "/time-clock/:hash/:service_hash"]}>
                  <TimeClock inWindow />
                </PublicRoute>
                <PublicRoute exact path={["/tc/:job_name"]}>
                  <TimeClockAlias />
                </PublicRoute>
                {(!isAuthenticated /* Use UserRoute's redirect if not logged-in */
                  || size(userProcessed.roles)) && (
                  <UserRoute path="*">
                    <NotFound />
                  </UserRoute>
                )}
              </Switch>
            </Suspense>
          </ThemeProvider>
        </StyledEngineProvider>
      </div>
    </PermissionsProvider>
  ) : (
    <AppLoading />
  );
};

const AppLoading = () => {
  return (
    <Loader>
      <div style={{ position: "relative", width: "50px", height: "50px" }}>
        <svg
          width="50"
          height="50"
          viewBox="0 0 105 105"
          xmlns="http://www.w3.org/2000/svg"
          fill="var(--color-blue)"
        >
          <circle cx="12.5" cy="12.5" r="12.5">
            <animate
              attributeName="fill-opacity"
              begin="0s"
              dur="1s"
              values="1;.2;1"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </circle>
          <circle cx="12.5" cy="52.5" r="12.5" fillOpacity=".5">
            <animate
              attributeName="fill-opacity"
              begin="100ms"
              dur="1s"
              values="1;.2;1"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </circle>
          <circle cx="52.5" cy="12.5" r="12.5">
            <animate
              attributeName="fill-opacity"
              begin="300ms"
              dur="1s"
              values="1;.2;1"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </circle>
          <circle cx="52.5" cy="52.5" r="12.5">
            <animate
              attributeName="fill-opacity"
              begin="600ms"
              dur="1s"
              values="1;.2;1"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </circle>
          <circle cx="92.5" cy="12.5" r="12.5">
            <animate
              attributeName="fill-opacity"
              begin="800ms"
              dur="1s"
              values="1;.2;1"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </circle>
          <circle cx="92.5" cy="52.5" r="12.5">
            <animate
              attributeName="fill-opacity"
              begin="400ms"
              dur="1s"
              values="1;.2;1"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </circle>
          <circle cx="12.5" cy="92.5" r="12.5">
            <animate
              attributeName="fill-opacity"
              begin="700ms"
              dur="1s"
              values="1;.2;1"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </circle>
          <circle cx="52.5" cy="92.5" r="12.5">
            <animate
              attributeName="fill-opacity"
              begin="500ms"
              dur="1s"
              values="1;.2;1"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </circle>
          <circle cx="92.5" cy="92.5" r="12.5">
            <animate
              attributeName="fill-opacity"
              begin="200ms"
              dur="1s"
              values="1;.2;1"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </circle>
        </svg>
      </div>
    </Loader>
  );
};

export default App;
