import React, { useCallback, /*useMemo,*/ useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Popper,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import moment from "moment";
import { AiOutlineUserAdd } from "react-icons/ai";

import {
  AutocompleteFormField,
  AutocompleteFormFieldStatic,
} from "common/Fields";
import { DateFormField } from "common/Fields";
import { times } from "utility";
import { DialogForm } from "common/Dialog";

import {
  memberUsersSelector,
  getMemberUsersLoadingSelector,
  fetchMemberUsers,
} from "features/Users/usersSlice";
import { getDivisionDataSelector, patchJobDivsionAssignPM } from "../jobSlice";
import { getMemberDataSelector } from "features/Member/memberSlice";
import { toArray } from "lodash";
import { TextFormField } from "common/Fields";

const round = (date, duration, method) => {
  return moment(Math[method](+date / +duration) * +duration);
};

const CustomPopper = function (props) {
  return <Popper {...props} style={{ width: 150 }} placement="bottom-start" />;
};

const AddProjectManager = ({ children, assignedOnly }) => {
  const dispatch = useDispatch();
  const division = useSelector(getDivisionDataSelector);
  const users = useSelector(memberUsersSelector.selectAll);
  const member = useSelector(getMemberDataSelector);
  const loading = useSelector(getMemberUsersLoadingSelector);

  const [open, setOpen] = useState(false);

  const getUsers = useCallback(
    (query) => {
      return dispatch(
        fetchMemberUsers({
          id: member.nid,
          onlyAssigned: assignedOnly ? 1 : 0,
          params: {
            keywords: query,
          },
        })
      );
    },
    [member, dispatch, assignedOnly]
  );

  // const currentPM = useMemo(() => {
  //   const coordinator = division.field_coordinator;
  //   if (coordinator) {
  //     return {
  //       ...coordinator,
  //       name: coordinator?.name,
  //       uid: coordinator?.uid,
  //     };
  //   } else {
  //     return null;
  //   }
  // }, [division]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async (data) => {
    const arrivalStart = moment(
      moment(data.arrival).format("YYYY-MM-DD") + "T" + data.arrivalFrom.value
    ).format();
    const arrivalEnd = moment(
      moment(data.arrival).format("YYYY-MM-DD") + "T" + data.arrivalTo.value
    ).format();

    await dispatch(
      patchJobDivsionAssignPM({
        user: data.pm,
        params: {
          job_division_nid: division.nid,
          pm_uid: data.pm.uid,
          notes: data.notes,
          arrival_time: arrivalStart,
          arrival_end_time: arrivalEnd,
        },
        division,
      })
    );

    onClose();
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      {children ? (
        React.cloneElement(children, {
          onClick: handleOpen,
        })
      ) : (
        <ListItem button onClick={handleOpen} style={{ paddingLeft: "2rem" }}>
          <ListItemIcon>
            <AiOutlineUserAdd />
          </ListItemIcon>
          <ListItemText primary="Project Manager" />
        </ListItem>
      )}
      <DialogForm
        open={open}
        onClose={onClose}
        maxWidth="xs"
        title="Project Manager"
        initialValues={{
          pm: null,
          notes: "",
          arrival: moment(),
          arrivalFrom: {
            name: round(
              moment(),
              moment.duration(15, "minutes"),
              "ceil"
            ).format("h:mm A"),
            value: round(
              moment(),
              moment.duration(15, "minutes"),
              "ceil"
            ).format("HH:mm"),
          },
          arrivalTo: {
            name: round(
              moment(),
              moment.duration(15, "minutes"),
              "ceil"
            ).format("h:mm A"),
            value: round(
              moment(),
              moment.duration(15, "minutes"),
              "ceil"
            ).format("HH:mm"),
          },
        }}
        disablePadding
        onSubmit={handleSubmit}
        overrideDirty
      >
        {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
          <div style={{ padding: "1.25rem" }}>
            <AutocompleteFormField
              name="pm"
              label="Project Manager"
              margin="normal"
              disabled={isSubmitting}
              options={toArray(users).map((user) => ({
                ...user,
                name: user.name,
                uid: user.uid,
              }))}
              loading={loading}
              fetchData={getUsers}
              variant="filled"
              size="small"
            />
            <Grid container spacing={3}>
              <Grid item xxs={4}>
                <DateFormField
                  fullWidth
                  disableToolbar
                  variant="inline"
                  format="MM/DD/YYYY"
                  id="arrival"
                  label="Arrival Date"
                  margin="normal"
                  name="arrival"
                  KeyboardButtonProps={{
                    "aria-label": "change arrival date",
                  }}
                  inputVariant="filled"
                  size="small"
                  datePicker
                />
              </Grid>
              <Grid item xxs={4}>
                <AutocompleteFormFieldStatic
                  name="arrivalFrom"
                  htmlFor="arrivalFrom"
                  margin="normal"
                  label="From"
                  disabled={isSubmitting}
                  disableClearable
                  options={times}
                  PopperComponent={CustomPopper}
                  variant="filled"
                  size="small"
                />
              </Grid>
              <Grid item xxs={4}>
                <AutocompleteFormFieldStatic
                  name="arrivalTo"
                  htmlFor="arrivalTo"
                  margin="normal"
                  label="To"
                  disabled={isSubmitting}
                  disableClearable
                  PopperComponent={CustomPopper}
                  options={times}
                  variant="filled"
                  size="small"
                />
              </Grid>
              <Grid item xxs={12}>
                <TextFormField
                  fullWidth
                  name="notes"
                  label="Notes"
                  variant="filled"
                  size="small"
                  disabled={isSubmitting}
                  multiline
                />
              </Grid>
            </Grid>
          </div>
        )}
      </DialogForm>
    </>
  );
};

AddProjectManager.propTypes = {};

export default AddProjectManager;
