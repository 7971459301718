import {
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import styled from "styled-components";
import { FiberManualRecord, } from "@mui/icons-material";

/* Override <ListItemText> so that it's font-size can be adjusted */
export const ListItemTextOverride = styled(ListItemText)`
  .MuiTypography-root{
    font-size: inherit;
    line-height: inherit;
  }
`;

export const AccountingStatus = ({accounting, ignoreNull, ...props}) => {
  let _status = accounting?.status;
  if(!_status){
    if(ignoreNull){
      return null;
    }
    _status = "invoicing";
  }
  const statusInfo = accountingStatusOptions.find((info) => info.type === _status);
  let _label = statusInfo.label;
  let _color = statusInfo.color;
  let overdue = false;
  let overdueBalance = false;
  if(_status === 'awaiting_payment'){
    if(accounting?.payment_overdue){
      overdue = true;
    }
    if(accounting?.partial_payment){
      _label = 'Partial Payment';
      _color = "var(--color-orange)";
      if(overdue){
        overdueBalance = true;
        _color = "var(--color-red)";
      }
    }
    if(accounting?.over_payment){
      _label = 'Over Payment';
      _color = "var(--color-orange)";
    }
  }

  return (
    <div className={`flex items-center text-base ${props.className}`}>
      <ListItemIcon
        style={{
          color: _color,
        }}
        className="min-w-0 mr-1"
      >
        <FiberManualRecord fontSize="medium" />
      </ListItemIcon>
      <ListItemTextOverride
        primary={_label + (overdue ? (overdueBalance ? ' (BALANCE OVERDUE)' : ' (OVERDUE)') : '')}
        className="flex-initial text-emerald-50"
        style={{
          color: "var(--color-black)",
        }}
      />
    </div>
  );
}

export const accountingStatusOptions = [
  {
    type: "invoicing",
    label: "Invoicing",
    color: "var(--color-yellow-bright)",
  },
  {
    type: "voided",
    label: "Voided",
    color: "var(--color-gray-medium)",
  },
  {
    type: "awaiting_payment",
    label: "Awaiting Payment",
    color: "var(--color-red)",
  },
  {
    type: "member_awaiting_payment",
    label: "Provider Awaiting Payment",
    color: "var(--color-orange)",
  },
  {
    type: "payables_awaiting_payment",
    label: "Provider/Third Parties Awaiting Payment",
    color: "var(--color-orange)",
  },
  {
    type: "billing_complete",
    label: "Billing Complete",
    color: "var(--color-green)",
  },
];

export default AccountingStatus;
