import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Tabs, Tab } from "@mui/material";
import { hubEnvironmentSelectors, setCurrentEnvironment } from "features/Hub/hubSlice.js";
import { getHubEnvironmentIndicatorInfo } from "../utils.js";
import Indicators from "common/Indicators";
import Avatar from "common/Avatar";
import { ReactComponent as IconHub } from "assets/images/icons/icon-hub.svg";
import "./HubEnvironments.scss";


const HubEnvironments = ({omitHubIcon}) => {
  const dispatch = useDispatch();
  const envs = useSelector(hubEnvironmentSelectors.selectAll);
  const current = useSelector((state) => state?.hub?.currentEnvironment);
  const helixAPICurrent = useSelector((state) => state.user.helixAPICurrent.data);

  const switchEnvironments = (env) => {
    dispatch(setCurrentEnvironment(env));
  }

  return (
    <>
    <Typography className="hub-environment-title" variant="h6">
      {!omitHubIcon && (<><IconHub className="header-icon" /> {`Hub${envs.length > 1 ? ' | ' : ''}`}</>)}
      {envs.length > 1 ? current.name : ''}
    </Typography>
    {envs.length > 1 && (
      <Tabs
        className="hub-environments"
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        value={current.nid}
      >
        {envs.map((env) => {
          let indicatorInfo = {};
          if(helixAPICurrent?.hub_updates?.[env.nid]){
            indicatorInfo = getHubEnvironmentIndicatorInfo([helixAPICurrent?.hub_updates?.[env.nid]]);
          }
          return (
            <Tab
              key={env.nid}
              disableRipple
              value={env.nid}
              icon={
                <Indicators
                  {...indicatorInfo}
                  marginRightStart={0.5}
                >
                  <div className={`environment ${current.nid === env.nid ? 'current' : ''}`}>
                    <Avatar
                      title={`${env.name}${indicatorInfo.description ? ' | ' + indicatorInfo.description : ''}`}
                      link={env.logo}
                      type="manual"
                      size="small"
                      tooltip
                      styles={{
                        background: env.color,
                        color: env.textColor,
                      }}
                      onClick={() => switchEnvironments(env)}
                    />
                  </div>
                </Indicators>
              }
            />
          );
        })}
      </Tabs>
    )}
    </>
  );
};

HubEnvironments.propTypes = {};

export default HubEnvironments;
