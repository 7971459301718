import React from "react";
import { useSelector } from "react-redux";
import { Typography, Grid } from "@mui/material";
import { FaUserTie } from "react-icons/fa";
import { BiCheckShield } from "react-icons/bi";
import { RiMoneyDollarCircleLine, RiInformationLine } from "react-icons/ri";
import { BsShieldShaded } from "react-icons/bs";
import IconTextItem from "common/IconTextItem";
import Button from "common/Button";
import Empty from "common/Empty";
import { default as AddInsurance } from "features/Insurance/forms/Create";
import Card, { CardHeader } from "common/Card";
import { formatMoney } from "utility";
import { getInsuranceDataSelector } from "features/Insurance/insuranceSlice";

const Insurance = (props) => {
  const division = props.division;
  const insurance = useSelector(getInsuranceDataSelector);

  return (
    <Card>
      <CardHeader title="Insurance" />
      {insurance.nid ? (
        <div>
          <div
            style={{
              marginBottom: "2rem",
              background: "var(--color-gray-light)",
              borderBottom: "1px solid var(--color-gray-medium)",
              padding: "1.25rem",
            }}
          >
            <Grid container spacing={3}>
              <Grid item xxs={12} md={4}>
                <IconTextItem icon={<RiInformationLine />}>
                  <Typography
                    variant="subtitle2"
                    style={{ textTransform: "uppercase" }}
                    color="textSecondary"
                  >
                    Claim
                  </Typography>
                  <Typography variant="body1">
                    {insurance.field_jdi_claim_number}
                  </Typography>
                </IconTextItem>
              </Grid>
              <Grid item xxs={12} md={4}>
                <IconTextItem icon={<RiInformationLine />}>
                  <Typography
                    variant="subtitle2"
                    style={{ textTransform: "uppercase" }}
                    color="textSecondary"
                  >
                    Policy
                  </Typography>
                  <Typography variant="body1">{insurance.field_jdi_policy_number}</Typography>
                </IconTextItem>
              </Grid>
              <Grid item xxs={12} md={4}>
                <IconTextItem icon={<RiMoneyDollarCircleLine />}>
                  <Typography
                    variant="subtitle2"
                    style={{ textTransform: "uppercase" }}
                    color="textSecondary"
                  >
                    Deductible
                  </Typography>
                  <Typography variant="body1">
                    {formatMoney(insurance.field_jdi_deductible)}
                  </Typography>
                </IconTextItem>
              </Grid>
            </Grid>
          </div>
          <div style={{ padding: "1.25rem" }}>
            <Grid container spacing={3}>
              <Grid
                item
                xxs={12}
                md={4}
                style={{ borderRight: "1px solid var(--color-gray-lighter)" }}
              >
                <IconTextItem icon={<FaUserTie />}>
                  <Typography
                    variant="subtitle2"
                    style={{ textTransform: "uppercase" }}
                    color="textSecondary"
                  >
                    Adjustor
                  </Typography>
                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "1rem" }}>
                      <Typography variant="body1" align="right">
                        Name:
                      </Typography>
                      <Typography variant="body1" align="right">
                        Phone:
                      </Typography>
                      <Typography variant="body1" align="right">
                        Email:
                      </Typography>
                      <Typography variant="body1" align="right">
                        Fax:
                      </Typography>
                    </div>
                    <div style={{ flex: 1 }}>
                      <Typography variant="body1">
                        {insurance.field_jdi_adjuster_name}
                      </Typography>
                      <Typography variant="body1">
                        {insurance.field_jdi_adjuster_phone}
                      </Typography>
                      <Typography variant="body1">
                        {insurance.field_jdi_adjuster_email}
                      </Typography>
                      <Typography variant="body1">
                        {insurance.field_jdi_adjuster_fax}
                      </Typography>
                    </div>
                  </div>
                </IconTextItem>
              </Grid>
              <Grid
                item
                xxs={12}
                md={4}
                style={{ borderRight: "1px solid var(--color-gray-lighter)" }}
              >
                <IconTextItem icon={<FaUserTie />}>
                  <Typography
                    variant="subtitle2"
                    style={{ textTransform: "uppercase" }}
                    color="textSecondary"
                  >
                    Agent
                  </Typography>
                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "1rem" }}>
                      <Typography variant="body1" align="right">
                        Name:
                      </Typography>
                      <Typography variant="body1" align="right">
                        Phone:
                      </Typography>
                      <Typography variant="body1" align="right">
                        Email:
                      </Typography>
                      <Typography variant="body1" align="right">
                        Fax:
                      </Typography>
                    </div>
                    <div style={{ flex: 1 }}>
                      <Typography variant="body1">
                        {insurance.field_jdi_agent_name}
                      </Typography>
                      <Typography variant="body1">
                        {insurance.field_jdi_agent_phone}
                      </Typography>
                      <Typography variant="body1">
                        {insurance.field_jdi_agent_email}
                      </Typography>
                      <Typography variant="body1">
                        {insurance.field_jdi_agent_fax}
                      </Typography>
                    </div>
                  </div>
                </IconTextItem>
              </Grid>
              <Grid item xxs={12} md={4}>
                <IconTextItem icon={<BiCheckShield />} gutterBottom>
                  <Typography
                    variant="subtitle2"
                    style={{ textTransform: "uppercase" }}
                    color="textSecondary"
                  >
                    Carrier
                  </Typography>
                  <div>
                    <Typography variant="body1">
                      {insurance.field_jdi_carrier}
                    </Typography>
                  </div>
                </IconTextItem>
              </Grid>
            </Grid>
          </div>
        </div>
      ) : (
        <Empty
          message="No Insurance has been added"
          action={division?._processed?.job_access_type !== 'customer' && (
            <AddInsurance>
              <Button
                color="tertiary"
                size="small"
                variant="contained"
                disableElevation
                style={{ marginTop: "2rem" }}
              >
                Add Insurance
              </Button>
            </AddInsurance>
          )}
        >
          <BsShieldShaded />
        </Empty>
      )}
    </Card>
  );
};

Insurance.propTypes = {};

export default Insurance;
