import React, { useRef, useState, useEffect } from "react";
import {IconButton, Tooltip } from "@mui/material";
import { HiOutlineZoomIn, HiOutlineZoomOut } from "react-icons/hi";
import { TiArrowSync } from "react-icons/ti";
import {
  TransformWrapper,
  TransformComponent,
  useControls,
  useTransformEffect,
} from "react-zoom-pan-pinch";
import "components/Lightroom/ImageZoom.scss";
import { useWindowSize } from "hooks";

const ImageZoom = ({altText, image, imageWidth, imageHeight, refresh, ...rest}) => {
  let wrapper = useRef(null);
  const windowResized = useWindowSize(true);
  const [containerWidth, setContainerWidth] = useState(null);
  const [containerHeight, setContainerHeight] = useState(null);
  let container = useRef(node => {
    if (node !== null) {
      setContainerWidth(node.getBoundingClientRect().width);
      setContainerHeight(node.getBoundingClientRect().height);
    }
  }, []);

// Recenter on Window resize or maximize
  useEffect(() => {
    if(container?.current){
      const newDim = container?.current.getBoundingClientRect()
      setContainerWidth(newDim.width);
      setContainerHeight(newDim.height);
      if(wrapper.current){
        let _scale = getScale(newDim.width, newDim.height, imageHeight, imageWidth);
        wrapper.current.centerView(_scale);
      }
    }
  }, [windowResized, refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  let scale = getScale(containerWidth, containerHeight, imageHeight, imageWidth);
  return (
    <div ref={container} className="image-zoom-wrapper">
      {(containerWidth && containerHeight) &&
        <TransformWrapper
          ref={wrapper}
          centerZoomedOut={true}
          centerOnInit={true}
          limitToBounds={true}
          initialScale={scale}
          minScale={scale}
        >
        {/* TransformWrapper requires context to be callled from within the wrapper */}
          <ZoomContent
            altText={altText}
            image={image}
            wrapper={wrapper}
            scale={scale}
          />
        </TransformWrapper>
      }
    </div>
  );
};

const ZoomContent = ({altText, image, scale, wrapper}) => {
  let img = useRef(null);

  const { zoomIn, zoomOut, centerView } = useControls();

  useTransformEffect(({ state, instance }) => {
    const isPanning = instance?.isPanning;
    img.current.className = `${isPanning ? 'cursor-grabbing' : 'cursor-grab'}`;

    return () => {
      // unmount
    };
  });

  return (
    <div className="content-wrapper">
      <TransformComponent>
        <img
          alt={altText}
          src={image}
          ref={img}
          style={{pointerEvents: 'auto'}}
          className="cursor-grab max-w-full max-h-full m-auto"
        />
      </TransformComponent>
      <div className="absolute top-0 left-0 p-2 flex">
        <Tooltip title="Zoom In">
          <IconButton
            onClick={() => zoomIn()}
            className="icon-circle-button"
          >
            <HiOutlineZoomIn />
          </IconButton>
        </Tooltip>
        <Tooltip title="Zoom Out">
          <IconButton
            onClick={() => zoomOut()}
            className="ml-2 icon-circle-button"
          >
            <HiOutlineZoomOut />
          </IconButton>
        </Tooltip>
        <Tooltip title="Reset Image Position">
          <IconButton
              onClick={() => centerView(scale)}
              className="ml-2 icon-circle-button"
            >
            <TiArrowSync />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

const getScale = (containerWidth, containerHeight, imageHeight, imageWidth) => {
  let scale = 1;
  if(containerWidth && containerHeight && imageHeight > containerHeight){
    const imgProp = imageWidth / imageHeight;
    const frameProp = containerWidth / containerHeight;
  //  Image Taller than Frame
    if(imgProp < frameProp){
      scale = (imageWidth * (containerHeight / imageHeight)) / containerWidth;
    }
  }
  return scale;
}

ImageZoom.propTypes = {};

export default ImageZoom;
