import React from "react";
import {
  Popover,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import * as Yup from "yup";

import { UsersForm, UsersStaging, Message } from "./Styled";

const ChatAddEmails = ({ anchorEl, onClose, onChange, stagedEmails }) => {
  const [value, setValue] = React.useState("");
  const [error, setError] = React.useState(null);

  const handleRemoveUser = (value) => {
    const u = [...stagedEmails];
    const index = u.findIndex((obj) => obj === value);
    u.splice(index, 1);

    onChange(u);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    setError(null);
  };

  const onEnterPress = async (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      const schema = Yup.string().email();
      const isValid = await schema.isValid(value);

      if (isValid) {
        onChange([...stagedEmails, value]);
        setValue("");
        setError(null);
      } else {
        setError("Must be a valid email");
      }
    }
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <UsersForm>
        <TextField
          id="add-notify-emails"
          label="Email"
          type="email"
          variant="filled"
          onKeyDown={onEnterPress}
          onChange={handleChange}
          style={{ width: 300 }}
          value={value}
          error={!!error}
          helperText={error}
        />
        <UsersStaging>
          <List dense style={{ width: "300px" }}>
            {stagedEmails.length === 0 && (
              <Message>
                <Typography variant="body1" align="center">
                  Type an email and hit enter to add.
                </Typography>
              </Message>
            )}
            {stagedEmails.map((email, i) => {
              return (
                <ListItem key={i} disableGutters>
                  <ListItemText primary={email} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleRemoveUser(email)}
                      size="large">
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </UsersStaging>
      </UsersForm>
    </Popover>
  );
};

ChatAddEmails.propTypes = {};

export default ChatAddEmails;
