import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Tooltip,
  useMediaQuery,
  MenuItem,
  ListItemIcon,
  Typography,
  IconButton,
} from "@mui/material";
import { OpenInBrowser } from "@mui/icons-material";

import { DefaultLoader } from "common/Loader";
import Alert from "common/Alert";
import Dialog from "common/Dialog";
import Equipment from "features/Equipment";
import Expense from "features/Expense";
import Service from "features/Service";

import {
  fetchDailysheet,
  getDailySheetDataSelector,
  getDailySheetErrorSelector,
  getDailySheetLoadingSelector,
} from "./dailySheetSlice";

const DailySheet = ({ nid, typeLabel }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("Loading...");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const loading = useSelector(getDailySheetLoadingSelector);
  const sheet = useSelector(getDailySheetDataSelector);
  const error = useSelector(getDailySheetErrorSelector);

  useEffect(() => {
    let promise;
    if (open && nid) {
      promise = dispatch(fetchDailysheet(nid));
    }

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [dispatch, nid, open]);

  useEffect(() => {
    if (error) {
      setTitle("Error");
    }
  }, [error]);

  const getSheetContent = useCallback(
    (type) => {
      switch (type) {
        case "ds_equipment":
          return <Equipment sheet={sheet} setTitle={setTitle} />;
        case "ds_expense":
          return <Expense sheet={sheet} setTitle={setTitle} />;
        case "ds_service":
          return <Service sheet={sheet} setTitle={setTitle} />;
        default:
          return null;
      }
    },
    [sheet]
  );

  return (
    <>
      {isMobile ? (
        <MenuItem onClick={() => setOpen(true)}>
          <ListItemIcon>
            <OpenInBrowser />
          </ListItemIcon>
          <Typography variant="inherit">View</Typography>
        </MenuItem>
      ) : (
        <Tooltip title={`View ${typeLabel ? typeLabel : ''}`}>
          <IconButton
            size="small"
            onClick={() => setOpen(true)}
            style={{ marginRight: "0.5rem" }}
          >
            <OpenInBrowser />
          </IconButton>
        </Tooltip>
      )}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth={
          sheet.type === "ds_equipment"
            ? "md"
            : sheet.type === "ds_service"
            ? "sm"
            : "xs"
        }
        title={title}
        initialValues={{}}
      >
        {loading ? (
          <div>
            <DefaultLoader />
          </div>
        ) : (
          <>
            {error && (
              <div style={{ padding: "0 1.25rem" }}>
                <Alert kind="negative">{error}</Alert>
              </div>
            )}
            {getSheetContent(sheet.type)}
          </>
        )}
      </Dialog>
    </>
  );
};

DailySheet.propTypes = {};

export default DailySheet;
