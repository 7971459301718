import React from "react";
import { Grid, Typography } from "@mui/material";
import styled from "styled-components";

import { formatMoney, formatPrecision } from "utility";
import {
  calcOverallTotal,
  calcTotalAmt,
  calcOverallHours,
  calcFlatHours,
  calcFlatTotal,
  calcSmallTools,
  calcMarkup,
} from "../utils";

export const Styles = styled.div`
  display: flex;
  background: var(--color-gray-background);
  border-bottom: 1px solid var(--color-gray-medium);
  border-top: 1px solid var(--color-gray-medium);
  padding: 1.25rem;
`;

const Totals = ({ data }) => {
  return (
    <div>
      {data.entry === "hourly" ? (
        <Styles>
          <Grid container spacing={3}>
            <Grid item xxs={6} md={3}>
              <Typography variant="subtitle1" color="textSecondary">
                Labor Total
              </Typography>
              <Typography variant="body1">
                {formatMoney(
                  calcTotalAmt(data["service-rate"], data["service-billable"])
                )}
              </Typography>
            </Grid>
            <Grid item xxs={6} md={3}>
              <Typography variant="subtitle1" color="textSecondary">
                Travel Total
              </Typography>
              <Typography variant="body1">
                {formatMoney(
                  calcTotalAmt(data["travel-rate"], data["travel-billable"])
                )}
              </Typography>
            </Grid>
            <Grid item xxs={6} md={3}>
              <Typography variant="subtitle1" color="textSecondary">
                Overtime Total
              </Typography>
              <Typography variant="body1">
                {formatMoney(
                  calcTotalAmt(data["overtime-rate"], data["overtime-billable"])
                )}
              </Typography>
            </Grid>
            <Grid item xxs={6} md={3}>
              <Typography variant="subtitle1" color="textSecondary">
                After Hours Total
              </Typography>
              <Typography variant="body1">
                {formatMoney(
                  calcTotalAmt(data["after-rate"], data["after-billable"])
                )}
              </Typography>
            </Grid>
            {data["has_perdiem"] && (
              <Grid item xxs={6} md={3}>
                <Typography variant="subtitle1" color="textSecondary">
                  Per Diem
                </Typography>
                <Typography variant="body1">
                  {formatMoney(data.perdiem)}
                </Typography>
              </Grid>
            )}
            {data["has_ppe"] && (
              <Grid item xxs={6} md={3}>
                <Typography variant="subtitle1" color="textSecondary">
                  PPE
                </Typography>
                <Typography variant="body1">{formatMoney(data.ppe)}</Typography>
              </Grid>
            )}
            {data["has_stc"] && (
              <Grid item xxs={6} md={3}>
                <Typography variant="subtitle1" color="textSecondary">
                  Small Tools Charge
                </Typography>
                <Typography variant="body1">{formatMoney(calcSmallTools(data))} {`(${data["small_tools"] ? Number(data["small_tools"]) : 0}%)`}</Typography>
              </Grid>
            )}
            {data["has_lodging"] && (
              <Grid item xxs={6} md={3}>
                <Typography variant="subtitle1" color="textSecondary">
                  Lodging
                </Typography>
                <Typography variant="body1">{formatMoney(data.lodging)}</Typography>
              </Grid>
            )}
            {(Number(data.field_ds_markup) > 0 || Number(data.field_ds_percent_markup) > 0) &&
              <Grid item xxs={6} md={3}>
                <Typography variant="subtitle1" color="textSecondary">
                  Markup
                </Typography>
                <Typography variant="body1">
                  {formatMoney(calcMarkup(data))}
                  {Number(data.field_ds_percent_markup) > 0 &&
                    ` (${formatPrecision(data.field_ds_percent_markup)}%)`
                  }
                </Typography>
              </Grid>
            }
            <Grid item xxs={6} md={3}>
              <Typography variant="subtitle1" color="textSecondary">
                Total Hours
              </Typography>
              <Typography variant="body1">{calcOverallHours(data)}</Typography>
            </Grid>
            <Grid item xxs={6} md={3}>
              <Typography variant="subtitle1" color="textSecondary">
                Total Cost
              </Typography>
              <Typography variant="body1">
                {formatMoney(calcOverallTotal(data))}
              </Typography>
            </Grid>
          </Grid>
        </Styles>
      ) : (
        <Styles>
          <Grid container spacing={3}>
            <Grid item xxs={6} md={3}>
              <Typography variant="subtitle1" color="textSecondary">
                Total Hours
              </Typography>
              <Typography variant="body1">{calcFlatHours(data)}</Typography>
            </Grid>
            <Grid item xxs={6} md={3}>
              <Typography variant="subtitle1" color="textSecondary">
                Total Cost
              </Typography>
              <Typography variant="body1">
                {formatMoney(calcFlatTotal(data))}
              </Typography>
            </Grid>
          </Grid>
        </Styles>
      )}
    </div>
  );
};

Totals.propTypes = {};

export default Totals;
