import React from "react";

import buttonStyles from "./css/button.module.css";

const AttachButton = ({ handleClick }) => {
  const onClick = (e) => {
    e.preventDefault();
    handleClick();
  };

  return (
    <div className={buttonStyles.buttonWrapper}>
      <button onClick={onClick} className={buttonStyles.button}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.7798 2.3877C14.9836 2.3877 14.2199 2.704 13.6569 3.26703L4.46691 12.457C3.5286 13.3953 3.00146 14.668 3.00146 15.9949C3.00146 17.3219 3.5286 18.5945 4.46691 19.5328C5.40522 20.4711 6.67783 20.9983 8.0048 20.9983C9.33177 20.9983 10.6044 20.4711 11.5427 19.5328L20.7327 10.3428C21.1232 9.95229 21.7564 9.95229 22.1469 10.3428C22.5374 10.7333 22.5374 11.3665 22.1469 11.757L12.9569 20.947C11.6435 22.2604 9.8622 22.9983 8.0048 22.9983C6.1474 22.9983 4.36607 22.2604 3.05269 20.947C1.73931 19.6336 1.00146 17.8523 1.00146 15.9949C1.00146 14.1375 1.73931 12.3562 3.05269 11.0428L12.2427 1.85281C13.1808 0.914714 14.4531 0.387695 15.7798 0.387695C17.1065 0.387695 18.3788 0.914714 19.3169 1.85281C20.255 2.79091 20.782 4.06325 20.782 5.38992C20.782 6.71659 20.255 7.98893 19.3169 8.92703L10.1169 18.117C9.55409 18.6798 8.79075 18.996 7.9948 18.996C7.19886 18.996 6.43551 18.6798 5.87269 18.117C5.30988 17.5542 4.99369 16.7909 4.99369 15.9949C4.99369 15.199 5.30988 14.4356 5.87269 13.8728L14.3631 5.3924C14.7539 5.0021 15.387 5.00248 15.7773 5.39323C16.1676 5.78398 16.1672 6.41715 15.7765 6.80744L7.28691 15.287C7.09942 15.4747 6.99369 15.7296 6.99369 15.9949C6.99369 16.2604 7.09916 16.5151 7.28691 16.7028C7.47465 16.8906 7.72929 16.996 7.9948 16.996C8.26031 16.996 8.51495 16.8906 8.70269 16.7028L17.9027 7.51281C18.4655 6.94982 18.782 6.18598 18.782 5.38992C18.782 4.59368 18.4657 3.83005 17.9027 3.26703C17.3397 2.704 16.576 2.3877 15.7798 2.3877Z"
            fill="black"
          />
        </svg>
      </button>
    </div>
  );
};

export default AttachButton;
