import React, { useEffect, useState, } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, } from "react-redux";
import {
  checkTimeClockAlias,
} from "./timeClockSlice";
import Alert from "common/Alert";
import Empty from "@phx/common/Empty";

const TimeClockAlias = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { job_name } = useParams();
  const [message, setMessage] = useState({
    id: 'warning',
    msg: 'Finding Time Clock...',
  });

  useEffect(() => {
    if(job_name){
      dispatch(checkTimeClockAlias({job_name}))
        .then((resp) => {
          if(resp.payload?.hash){
            setMessage({
              id: 'positive',
              msg: 'Redirecting...',
            })
            history.push(`/time-clock/${resp.payload.hash}`);
          }
          else{
            setMessage({
              id: 'negative',
              msg: (resp.payload?.error ? resp.payload.error : 'Time Clock not found...'),
            })
          }
        })
    }
  }, [job_name]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Empty>
      <Alert kind={message.id}>{message.msg}</Alert>
    </Empty>
  );
}

TimeClockAlias.propTypes = {};

export default TimeClockAlias;
