import React from "react";
import {
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
  Paper,
  LinearProgress,
  Grow,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import CloseIcon from "@mui/icons-material/Close";
import Draggable from "react-draggable";
import styled from "styled-components";

import constants from "../constants";
import { slugify, getPercentage } from "../../utility";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    color: "var(--color-gray-lighter)",
    background: "var(--gradient-black)",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, id, progress, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} id={id} {...other}>
      <div style={{ position: "absolute", left: 0, right: 0, top: 0 }}>
        <Grow in={(progress?.show ? true : false)}>
          <LinearProgress
            color="secondary"
            variant="determinate"
            value={getPercentage(progress?.obtained, progress?.total)}
          />
        </Grow>
      </div>
      <Typography variant="subtitle" sx={{ fontWeight: "bold" }}>{children}</Typography>

      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const PaperComponent = (props) => {
  const { handleId, ...rest } = props;
  return (
    <Draggable
      handle={`#${handleId}`}
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...rest} />
    </Draggable>
  );
};

const getPaperProps = (draggable, title, noWhite, fullHeight) => {
  const paperProps = {};

  if (draggable && title) {
    paperProps.handleId = `draggable-${slugify(title)}`;
  }

  if (noWhite) {
    paperProps.style = { background: constants.colorGrayLightest };
  }

  if (fullHeight) {
    paperProps.style = { ...paperProps.style, minHeight: "90vh" };
  }

  return paperProps;
};

const StyledDialog = styled(MuiDialog)`
  .MuiBackdrop-root {
    background-color: ${({ theme }) =>
      theme.disableOverlay ? "transparent" : "rgba(0,0,0,0.5)"};
  }
`;

const Dialog = ({
  title,
  open,
  onClose,
  onExited,
  maxWidth,
  children,
  fullWidth=true,
  draggable=true,
  fillScreen,
  noWhite,
  fullHeight=false,
  disableOverlay,
  disableFullscreen,
  progress,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const showFullScreen = (fullScreen || fillScreen) && !disableFullscreen;
  const disableDrag = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledDialog
      fullScreen={showFullScreen}
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={() => {
        if (onExited) {
          onExited();
        }
        onClose();
      }}
      PaperComponent={
        draggable && !fullScreen && !disableDrag ? PaperComponent : Paper
      }
      PaperProps={getPaperProps(draggable, title, noWhite, fullHeight)}
      theme={{ disableOverlay: disableOverlay }}
      disablebackdropclick="true"
    >
      <DialogTitle
        style={{
          cursor: draggable && !fullScreen && !disableDrag ? "move" : "default",
        }}
        id={draggable && title ? `draggable-${slugify(title)}` : null}
        onClose={onClose}
        progress={progress}
      >
        {title}
      </DialogTitle>
      {children}
    </StyledDialog>
  );
};

Dialog.propTypes = {};

export default Dialog;
