import styled from "styled-components";
import { Form } from "formik";

export const FormContainer = styled(Form)`
  padding: 1.25rem;
  position: relative;
`;

export const Container = styled.div`
  padding: 1.25rem 1.25rem 3rem 1.25rem;
  position: relative;
`;

export const PopupForm = styled.div`
  padding: 1rem;
`;

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Overrides = styled.div`
  padding: 1.25rem;
`;

export const Totals = styled.div`
  display: flex;
  margin: 1.25rem -1.25rem;
  background: var(--color-gray-lighter);
  padding: 1.25rem;
`;

export const FooterTotals = styled.div`
  margin: 2rem -1.25rem -1.25rem -1.25rem;
  background: var(--color-gray-lighter);
  padding: 1.25rem;
`;

export const FormActions = styled.div`
  padding-top: 2rem;

  & > .MuiButtonBase-root {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const BatchRow = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  align-items: center;
  padding: 1rem 1rem 0.25rem;
  background: var(--color-gray-lightest);
  border-radius: var(--border-radius);
  border: 1px solid
    ${({ status }) =>
      status === "positive"
        ? "var(--color-green)"
        : status === "negative"
        ? "var(--color-red)"
        : "var(--color-gray)"};
  margin-bottom: 0.5rem;

  & .MuiOutlinedInput-root {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: white;
  }
`;

export const BatchCell = styled.div`
  border: 1px solid var(--color-gray);
  flex: 1;
`;

export const BatchContainer = styled.div`
  > .header{
    display: flex;
    align-items: center;
    justify-content: flex-start;

    > .button{
      margin-left: 1rem;
    }
  }
`;
