import React, { useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Typography,
  Chip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";

import config from "../../config";
import Chatbar from "../ChatBar";
import Avatar from "common/Avatar";
import {
  Container,
  Actions,
  Content,
  Heading,
  Subject,
  Body,
  Subtext,
  Chips,
} from "./Styled";
import { ImageSkeleton } from "../Skeletons";
import { Image } from "./Styled";
import { convertBlobToBase64 } from "../../utility";

const formatUserLinks = (names, paths) => {
  const formattedUsers = [];
  if (!names || !paths) return formattedUsers;
  const userNames = names.split(",");
  userNames.forEach((name, index) => {
    formattedUsers.push({ name, path: paths.notify_users[index] });
  });

  return formattedUsers;
};

const formatSubText = (date, changed, status, type, sentToPilot) => {
  return `${moment.unix(date).format("MM/DD/YY - h:mm A")}${
    status ? ` | Status: ${status}` : ""
  }${type ? `| Type: ${type}` : ""}${sentToPilot ? " Sent to FM Pilot" : ""}${
    changed && date !== changed ? ` (edited)` : ""
  }`;
};

const formatVisibleTo = (data, divisionJob) => {
  const visibleTos = data.split(",");
  visibleTos.forEach((visible, index) => {
    if (visible === "phx_client") {
      const clientName = divisionJob.field_phoenix_client[0].title[0].value;
      visibleTos[index] = clientName /*'DKI'*/ /*visible.toUpperCase()*/;
    } else {
      visibleTos[index] = visible.charAt(0).toUpperCase() + visible.slice(1);
    }
  });

  return visibleTos.join(", ");
};

const Note = ({
  editable,
  visibleTo,
  name,
  timestamp,
  changed,
  status,
  type,
  subject,
  body,
  avatar,
  users,
  paths,
  handleEdit,
  handleDelete,
  notifyUserNames,
  notifyUserIds,
  notifyEmails,
  noteImages,
  cid,
  token,
  roleType,
  assignedUsers,
  commentType,
  divisionJob,
  sentToPilot,
  isFMPilot,
}) => {
  const notifyUsers = formatUserLinks(users, paths);
  const [editing, setEditing] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [images, setImages] = React.useState(null);
  const [loadingImages, setLoadingImages] = React.useState(false);

  useEffect(() => {
    if (!noteImages) return;
    let isSubscribed = true;
    setLoadingImages(true);
    const promises = [];
    noteImages.forEach((photo) => {
      const url = `${config.api_url}/${photo}`;
      promises.push(
        axios
          .get(url, {
            withCredentials: true,
            responseType: "blob",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => res.data)
          .then(convertBlobToBase64)
      );
    });

    Promise.all(promises).then((res) => {
      if (isSubscribed) {
        setImages(res);
        setLoadingImages(false);
      }
    });

    return () => {
      isSubscribed = false;
    };
  }, [noteImages]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container commentType={commentType}>
      <Avatar title={name} link={avatar} />
      <Content>
        {editable && (
          <Actions>
            <IconButton size="small" onClick={() => setEditing(true)}>
              <Edit />
            </IconButton>
            {roleType === "phx_client" && (
              <IconButton size="small" onClick={() => setOpenDialog(true)}>
                <Delete />
              </IconButton>
            )}
          </Actions>
        )}
        {commentType && commentType === "activity_log" && (
          <div>Automated Message</div>
        )}
        {visibleTo && (
          <span>Only visible to {formatVisibleTo(visibleTo, divisionJob)}</span>
        )}
        <Heading>
          {name}
          <Subtext>
            {formatSubText(timestamp, changed, status, type, sentToPilot)}
          </Subtext>
        </Heading>
        {editing ? (
          <Chatbar
            comment={{
              visibleTo,
              notifyUserNames,
              notifyUserIds,
              notifyEmails,
              name,
              timestamp,
              status,
              type,
              subject,
              body,
              avatar,
              users,
              paths,
              cid,
            }}
            assignedUsers={assignedUsers}
            roleType={roleType}
            divisionJob={divisionJob}
            handleSubmit={handleEdit}
            onCancel={setEditing}
            sentToPilot={sentToPilot}
            isFMPilot={isFMPilot}
          />
        ) : (
          <>
            {notifyEmails && (
              <Typography variant="subtitle2" gutterBottom>
                {notifyEmails}
              </Typography>
            )}
            {users && (
              <Chips>
                {notifyUsers.map((user, index) => (
                  <Chip
                    clickable
                    key={index}
                    label={user.name}
                    size="small"
                    component={Link}
                    to={user.path}
                  />
                ))}
              </Chips>
            )}
            {subject && subject !== "(No subject)" && (
              <Subject>{subject}</Subject>
            )}
            <Body>
              {body && typeof body === "string"
                ? body.split("\n").map((paragraph, i) => (
                    <React.Fragment key={i}>
                      {paragraph}
                      <br />
                    </React.Fragment>
                  ))
                : body}
            </Body>
            {loadingImages ? (
              <>
                <Image>
                  <ImageSkeleton />
                </Image>
              </>
            ) : images ? (
              images.map((image) => {
                return (
                  <Image>
                    <img src={image} alt="Note" />
                  </Image>
                );
              })
            ) : null}
          </>
        )}
      </Content>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="note-confirmation-dialog-title"
        aria-describedby="note-confirmation-dialog-description"
      >
        <DialogTitle id="note-confirmation-dialog-title">
          Are you sure?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="note-confirmation-dialog-description">
            Are you sure you want to delete this comment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>
            Cancel
          </Button>
          <Button
            onClick={() => handleDelete({ cid: cid })}
            color="primary"
            variant="contained"
            disableElevation
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

Note.propTypes = {};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  divisionJob: state.app.divisionJob.data,
});

export default connect(mapStateToProps)(Note);
