import React from "react";
import PropTypes from "prop-types";
import { IconButton, Fade, TableRow, TableCell, Chip } from "@mui/material";
import { InsertDriveFile, Delete } from "@mui/icons-material";

import { SelectFormField } from "common/Fields";
import { formatDateTime, formatFileSize } from "../../utility";
import {
  Container,
  Icon,
  Details,
  Heading,
  Subheading,
  ActionsHover,
} from "./Styled";

const File = ({
  title,
  datetime,
  size,
  onDelete,
  index,
  editable,
  visibility,
  category,
  divisionJob,
}) => {
  const [hover, setHover] = React.useState(false);

  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };

  return (
    <TableRow onMouseEnter={onHover} onMouseLeave={onLeave}>
      <TableCell style={{ minWidth: 170 }}>
        <Container>
          <Icon>
            <InsertDriveFile />
          </Icon>
          <Details>
            <Heading>{title}</Heading>
            <Subheading>
              {size
                ? formatFileSize(size, true)
                : formatDateTime(datetime, "MMM Do, YYYY - h:mm A")}
            </Subheading>
          </Details>
        </Container>
      </TableCell>
      <TableCell style={{ minWidth: 100 }}>
        <Container>
          {visibility.map((visibleTo, index) => (
            <Chip
              size="small"
              key={index}
              label={visibleTo}
              style={{ marginRight: "5px" }}
            />
          ))}
        </Container>
      </TableCell>
      <TableCell style={{ minWidth: 100 }}>
        <Container>{category}</Container>
      </TableCell>
      <TableCell style={{ minWidth: 50 }} align="right">
        <Container flexEnd>
          <div>
            {onDelete && (
              <IconButton aria-label="More" onClick={onDelete} size="large">
                <Delete />
              </IconButton>
            )}
            {/* <IconButton aria-label="More">
          <MoreHoriz />
        </IconButton> */}
          </div>
          {editable && (
            <Fade in={hover}>
              <ActionsHover>
                <SelectFormField
                  name={`files[${index}].visibility`}
                  label="Visibility"
                  labelId="visibility-select-label"
                  id="visibility-select"
                  multiple
                  variant="outlined"
                  labelwidth={60}
                  renderValue={(selected) => (
                    <div>
                      {selected.map((value) => (
                        <Chip key={value} label={value} size="small" />
                      ))}
                    </div>
                  )}
                  options={[
                    {
                      value: "phx_client",
                      label: divisionJob.field_phoenix_client[0].title[0].value,
                    },
                    { value: "member", label: "Member" },
                  ]}
                />

                <SelectFormField
                  name={`files[${index}].category`}
                  label="Category"
                  labelId="category-select-label"
                  id="category-select"
                  variant="outlined"
                  labelwidth={60}
                  options={[{ value: "uncategorized", label: "Uncategorized" }]}
                />
              </ActionsHover>
            </Fade>
          )}
        </Container>
      </TableCell>
    </TableRow>
  );
};

const { string, number, func, bool } = PropTypes;
File.propTypes = {
  title: string,
  datetime: number,
  size: number,
  onDelete: func,
  index: number,
  editable: bool,
};

export default File;
