import React from "react";
import {
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
  Paper,
  LinearProgress,
  Grow,
  Slide,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import CloseIcon from "@mui/icons-material/Close";
import Draggable from "react-draggable";
import styled from "styled-components";

import constants from "../../components/constants";
import { slugify, getPercentage } from "../../utility";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    color: "var(--color-gray-lighter)",
    background: "var(--gradient-black)",
  },
  closeButton: {
    position: "absolute",
    right: "8px",
    top: "4px",
    // color: "white",
  },
  // rootLight: {
  //   margin: 0,
  //   padding: theme.spacing(2),
  // },
  // closeButtonLight: {
  //   position: "absolute",
  //   right: theme.spacing(1),
  //   top: theme.spacing(1),
  // },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, id, progress, fullScreen, ...other } =
    props;
  return (
    <MuiDialogTitle
      className={/*fullScreen ? classes.rootLight :*/ classes.root}
      id={id}
      {...other}
    >
      <div style={{ position: "absolute", left: 0, right: 0, top: 0 }}>
        <Grow in={(progress?.show ? true : false)}>
          <LinearProgress
            color="secondary"
            variant="determinate"
            value={getPercentage(progress?.obtained, progress?.total)}
          />
        </Grow>
      </div>
      <Typography color="primaryInverse" sx={{ fontWeight: "bold" }}>
        {children}
      </Typography>

      {onClose ? (
        <IconButton
          color="primaryInverse"
          aria-label="close"
          className={
            /*fullScreen ? classes.closeButtonLight :*/ classes.closeButton
          }
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const PaperComponent = (props) => {
  const { handleId, ...rest } = props;
  return (
    <Draggable
      handle={`#${handleId}`}
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...rest} />
    </Draggable>
  );
};

const getPaperProps = (
  draggable,
  title,
  noWhite,
  fullHeight,
  showFullScreen
) => {
  const paperProps = {};

  if (draggable && title && !showFullScreen) {
    paperProps.handleId = `draggable-${slugify(String(title))}`;
  }

  if (noWhite) {
    paperProps.style = { ...paperProps.style,  background: constants.colorGrayLightest };
  }

  if (fullHeight && !showFullScreen) {
    paperProps.style = { ...paperProps.style, minHeight: "90vh" };
  }

  return paperProps;
};

const StyledDialog = styled(MuiDialog)`
  .MuiBackdrop-root {
    background-color: ${({ theme }) =>
      theme.disableOverlay ? "transparent" : "var(--color-gray-alpha-mid)"};
  }
`;

const StyledDialogFullscreen = styled(MuiDialog)`
  .MuiDialog-root {
    background-color: ${({ theme }) =>
      theme.disableOverlay ? "transparent" : "var(--color-gray-alpha-mid)"};
  }
  .MuiDialog-container {
    height: 95%;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    overflow: hidden;
  }
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Dialog = ({
  title,
  open,
  onClose,
  onExited,
  maxWidth,
  children,
  fullWidth,
  draggable,
  fillScreen,
  noWhite,
  fullHeight,
  disableOverlay,
  disableFullscreen,
  progress,
  hideBackdrop,
  enableCloseOnBackdropClick,
  paperProps,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const showFullScreen = (fullScreen || fillScreen) && !disableFullscreen;

  return showFullScreen ? (
    <StyledDialogFullscreen
      fullScreen={showFullScreen}
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={() => {
        if (onExited) {
          onExited();
        }
        onClose();
      }}
      PaperComponent={draggable && !showFullScreen ? PaperComponent : Paper}
      PaperProps={{
        ...getPaperProps(
          draggable,
          title,
          noWhite,
          fullHeight,
          showFullScreen
        ),
        ...paperProps,
      }}
      theme={{ disableOverlay: disableOverlay }}
      disableEscapeKeyDown={enableCloseOnBackdropClick ? false : true}
      hideBackdrop={hideBackdrop}
      disableEnforceFocus={hideBackdrop}
      TransitionComponent={Transition}
    >
      {title && (
        <DialogTitle
          style={{ cursor: draggable && !showFullScreen ? "move" : "default" }}
          id={draggable && title ? `draggable-${slugify(String(title))}` : null}
          onClose={onClose}
          progress={progress}
          fullScreen={showFullScreen}
        >
          {title}
        </DialogTitle>
      )}
      {children}
    </StyledDialogFullscreen>
  ) : (
    <StyledDialog
      fullScreen={showFullScreen}
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={() => {
        if (onExited) {
          onExited();
        }
        onClose();
      }}
      PaperComponent={draggable && !showFullScreen ? PaperComponent : Paper}
      PaperProps={{
        ...getPaperProps(
          draggable,
          title,
          noWhite,
          fullHeight,
          showFullScreen
        ),
        ...paperProps,
      }}
      theme={{ disableOverlay: disableOverlay }}
      disableEscapeKeyDown={enableCloseOnBackdropClick ? false : true}
      hideBackdrop={hideBackdrop}
      disableEnforceFocus={hideBackdrop}
    >
      {title && (
        <DialogTitle
          style={{ cursor: draggable && !showFullScreen ? "move" : "default" }}
          id={draggable && title ? `draggable-${slugify(String(title))}` : null}
          onClose={onClose}
          progress={progress}
        >
          {title}
        </DialogTitle>
      )}
      {children}
    </StyledDialog>
  );
};

Dialog.propTypes = {};

Dialog.defaultProps = {
  fullWidth: true,
  fullHeight: false,
  draggable: true,
};

export default Dialog;
