import React from "react";
import { useSelector } from "react-redux";
import { TextField, Button, Typography, Grid } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { uniq, size } from "lodash";
import { Edit } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";

import Paper from "../Paper";
import { slugify, getCounties } from "../../utility";
import { Container, ActionBar, Actions, Filters } from "./Styled";
import MemberCountiesForm from "./MemberCountiesForm";
import Role from "../Role";
import { MemberNotes } from "components/Member";
import { memberNoteTypesSelectors } from "features/Taxonomies/taxonomiesSlice";
import constants from "components/constants";

const useStyles = makeStyles((theme) => ({
  button: {
    background: constants.gradientBlack,
    paddingRight: "10px",
    color: constants.colorWhite,
    "&:hover": {
      background: constants.gradientBlack,
      color: constants.colorWhite,
    },
  },
}));

function createData(name, owned, state) {
  return {
    name,
    state,
    owned: owned ? "Yes" : "No",
  };
}

const createCategoryData = (owned, served) => {
  const counties = getCounties(served, owned);
  return counties.map((county) =>
    createData(county.name, county.owned, county.state)
  );
};

const createOptionsData = (owned, served) => {
  const counties = getCounties(served, owned);
  return counties.map((county) => ({ name: county.name, value: county.name }));
};

const getStates = (owned, served) => {
  const counties = getCounties(served, owned);
  const states = counties.map((county) => county.state);

  return uniq(states);
};

const MemberCounties = ({ served, owned, media, showSnackbar, nid, roles, refreshMember }) => {
  const classes = useStyles();
  const [rows, setRows] = React.useState([]);
  const [options, setOptions] = React.useState([]);
  const [search, setSearch] = React.useState(null);
  const [filters, setFilters] = React.useState([]);
  const [editCounties, setEditCounties] = React.useState(false);
  const states = getStates(owned, served);

  const noteTypes = useSelector(memberNoteTypesSelectors.selectAll);

  React.useEffect(() => {
    setRows(createCategoryData(owned, served));
    setOptions(createOptionsData(owned, served));
  }, [owned, served]);

  React.useEffect(() => {
    let currentRows = createCategoryData(owned, served);
    if (size(filters)) {
      const tempArray = [];
      for (let i = 0; i < currentRows.length; i += 1) {
        // eslint-disable-next-line no-loop-func
        if (filters.find(({ label }) => currentRows[i].state === label)) {
          tempArray.push(currentRows[i]);
        }
      }

      currentRows = tempArray;
    }

    if (search) {
      const index = currentRows.findIndex((element) => {
        return element.name === search.name;
      });

      if (currentRows && currentRows[index]) {
        setRows([currentRows[index]]);
      } else {
        setRows([]);
      }
    } else {
      setRows([...currentRows]);
    }
  }, [search, filters]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEditCounties = (e) => {
    setEditCounties(true);
  };

  const handleSearch = (event, query) => {
    if (query) {
      setSearch(query);
    } else {
      setSearch(null);
    }
  };

  const handleFilter = (event, query) => {
    if (size(query)) {
      setFilters(query);
    } else {
      setFilters([]);
    }
  };

  return (
    <Paper margin="none" squareTop>
      <Container>
        <ActionBar>
          <Filters>
            <Autocomplete
              options={options}
              autoHighlight
              onChange={handleSearch}
              size="small"
              getOptionLabel={(option) => option.name}
              style={{
                width: media.medium ? "100%" : 200,
                marginRight: media.medium ? 0 : "1rem",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="Search"
                  variant="outlined"
                />
              )}
            />
            <Autocomplete
              options={states.map((state) => ({
                label: state,
                value: state,
              }))}
              autoHighlight
              multiple
              disableCloseOnSelect
              size="small"
              onChange={handleFilter}
              getOptionLabel={(option) => option.label}
              style={{
                width: media.medium ? "100%" : 200,
                marginTop: media.medium ? "1rem" : 0,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="State"
                  variant="outlined"
                />
              )}
            />
          </Filters>
          <Actions
            style={{
              order: media.medium ? -1 : 1,
              marginBottom: media.medium ? "1rem" : 0,
            }}
          >
            <Role
              userRoles={roles}
              intendedRoles={[
                "admin",
                "phx_sub_admin",
                "phx_client_admin",
                "operations",
                "accounting",
                "compliance_admin",
              ]}
            >
              <Button
                startIcon={<Edit />}
                size="small"
                className={classes.button}
                onClick={handleEditCounties}
              >
                Edit
              </Button>
            </Role>
          </Actions>
        </ActionBar>
        <Typography variant="caption" color="textSecondary">
          Bold indicates owned counties
        </Typography>
        {states.map((state) => {
          return (
            <div key={state} style={{ marginBottom: "2rem" }}>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                style={{ marginBottom: "1rem" }}
              >
                {state}
              </Typography>
              <Grid container spacing={3}>
                {rows
                  .filter((item) => item.state === state)
                  .map((row) => (
                    <Grid
                      key={slugify(row.name)}
                      item
                      xxs={12}
                      sm={6}
                      md={3}
                      lg={2}
                    >
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: row.owned === "Yes" ? "bold" : "normal",
                        }}
                      >
                        {row.name}
                      </Typography>
                    </Grid>
                  ))}
              </Grid>
            </div>
          );
        })}
      </Container>
      {/* <InlineTable columns={columns} rows={rows} defaultSort="state" /> */}
      <MemberCountiesForm
        open={editCounties}
        anchorEl={editCounties}
        onClose={() => setEditCounties(false)}
        nid={nid}
        showSnackbar={showSnackbar}
        refreshMember={refreshMember}
        served={served}
        owned={owned}
      />
      <MemberNotes
        memberLoading={false}
        nid={nid}
        showSnackbar={showSnackbar}
        media={media}
        roles={roles}
        typeTid={noteTypes.find(( type ) => type.name === 'Coverage')?.tid}
      />
    </Paper>
  );
};

MemberCounties.propTypes = {};

export default MemberCounties;
