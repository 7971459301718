import React from "react";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";

import Button from "common/Button";
import { TextFormField } from "common/Fields";
import { SelectFormField } from "common/Fields";
import { CheckboxFormField } from "common/Fields";

import { statesSelectors } from "features/Taxonomies/taxonomiesSlice";

const Location = ({
  handlePrev,
  values,
  isSubmitting,
  value,
  handleSubmit,
  setFieldValue,
  uploading,
}) => {
  const states = useSelector(statesSelectors.selectAll);

  if (value !== 1) return null;
  return (
    <div>
      <Typography variant="h6">Location</Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        style={{ marginBottom: "2rem" }}
      >
        Location and Billing based information for this Customer.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xxs={12}>
          <CheckboxFormField
            name="field_is_headquarters"
            label="Is Headquarters/Primary Residence"
          />
        </Grid>
        <Grid item xxs={12}>
          <TextFormField
            fullWidth
            name="field_street_address.address_line1"
            label="Address"
            variant="outlined"
            size="small"
            required
            disabled={isSubmitting}
            onChange={(e) => {
              setFieldValue(
                "field_street_address.address_line1",
                e.target.value
              );
              if (values.billing_same_as_street) {
                setFieldValue(
                  "field_billing_address.address_line1",
                  e.target.value
                );
              }
            }}
          />
        </Grid>
        <Grid item xxs={12}>
          <TextFormField
            fullWidth
            name="field_street_address.address_line2"
            label="Address 2"
            variant="outlined"
            size="small"
            disabled={isSubmitting}
            onChange={(e) => {
              setFieldValue(
                "field_street_address.address_line2",
                e.target.value
              );
              if (values.billing_same_as_street) {
                setFieldValue(
                  "field_billing_address.address_line2",
                  e.target.value
                );
              }
            }}
          />
        </Grid>
        <Grid item xxs={4}>
          <TextFormField
            fullWidth
            name="field_street_address.locality"
            label="City"
            required
            disabled={isSubmitting}
            variant="outlined"
            size="small"
            onChange={(e) => {
              setFieldValue("field_street_address.locality", e.target.value);
              if (values.billing_same_as_street) {
                setFieldValue("field_billing_address.locality", e.target.value);
              }
            }}
          />
        </Grid>
        <Grid item xxs={4}>
          <SelectFormField
            fullWidth
            name="field_street_address.administrative_area"
            label="State"
            variant="outlined"
            size="small"
            required
            disabled={isSubmitting}
            options={states}
            onChange={(e) => {
              setFieldValue(
                "field_street_address.administrative_area",
                e.target.value
              );
              if (values.billing_same_as_street) {
                setFieldValue(
                  "field_billing_address.administrative_area",
                  e.target.value
                );
              }
            }}
          />
        </Grid>
        <Grid item xxs={4}>
          <TextFormField
            fullWidth
            name="field_street_address.postal_code"
            label="Zip Code"
            required
            variant="outlined"
            size="small"
            disabled={isSubmitting}
            onChange={(e) => {
              setFieldValue("field_street_address.postal_code", e.target.value);
              if (values.billing_same_as_street) {
                setFieldValue(
                  "field_billing_address.postal_code",
                  e.target.value
                );
              }
            }}
          />
        </Grid>
        <Grid item xxs={12}>
          <CheckboxFormField
            name="billing_same_as_street"
            label="Billing same as street?"
            disabled={isSubmitting}
            onChange={(e) => {
              setFieldValue("billing_same_as_street", e.target.checked);
              if (e.target.checked) {
                setFieldValue(
                  "field_billing_address.address_line1",
                  values.field_street_address.address_line1,
                  false
                );
                setFieldValue(
                  "field_billing_address.address_line2",
                  values.field_street_address.address_line2,
                  false
                );
                setFieldValue(
                  "field_billing_address.administrative_area",
                  values.field_street_address.administrative_area,
                  false
                );
                setFieldValue(
                  "field_billing_address.locality",
                  values.field_street_address.locality,
                  false
                );
                setFieldValue(
                  "field_billing_address.postal_code",
                  values.field_street_address.postal_code,
                  false
                );
              }
            }}
          />
        </Grid>
        <Grid item xxs={12}>
          <TextFormField
            fullWidth
            name="field_billing_address.address_line1"
            label="Address"
            variant="outlined"
            size="small"
            required
            disabled={isSubmitting || values.billing_same_as_street}
          />
        </Grid>
        <Grid item xxs={12}>
          <TextFormField
            fullWidth
            name="field_billing_address.address_line2"
            label="Address 2"
            variant="outlined"
            size="small"
            disabled={isSubmitting || values.billing_same_as_street}
          />
        </Grid>
        <Grid item xxs={4}>
          <TextFormField
            fullWidth
            name="field_billing_address.locality"
            label="City"
            required
            disabled={isSubmitting || values.billing_same_as_street}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xxs={4}>
          <SelectFormField
            fullWidth
            name="field_billing_address.administrative_area"
            label="State"
            variant="outlined"
            size="small"
            required
            disabled={isSubmitting || values.billing_same_as_street}
            options={states}
          />
        </Grid>
        <Grid item xxs={4}>
          <TextFormField
            fullWidth
            name="field_billing_address.postal_code"
            label="Zip Code"
            required
            variant="outlined"
            size="small"
            disabled={isSubmitting || values.billing_same_as_street}
          />
        </Grid>
        <Grid item xxs={12}>
          <div
            style={{
              marginTop: "2rem",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              isSubmitting={isSubmitting}
              disableElevation
              size="small"
              onClick={handlePrev}
              style={{ marginRight: "1rem" }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              isSubmitting={isSubmitting}
              disabled={uploading || isSubmitting}
              disableElevation
              size="small"
            >
              Submit
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Location.propTypes = {};

export default Location;
