import React, { useEffect , useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { size } from "lodash";

import Button from "common/Button";
import { TextFormField } from "common/Fields";
import { SelectFormField } from "common/Fields";
import { CheckboxFormField } from "common/Fields";
import { SingleUploader } from "common/Uploader";

import {
  accountingTemplateSelectors,
  customerStatusSelectors,
} from "features/Taxonomies/taxonomiesSlice";
import { fetchPhxClientUsers, accountRepsSelectors } from "features/Users/usersSlice";
import { RadioFormField } from "common/Fields";
import TMTermsFormComponent from "components/TMTerms/forms/components/TMTermsFormComponent";
import { nationalContractsSelectors } from "features/Taxonomies/taxonomiesSlice";
import { filterNationalContracts, TaxonomyChip } from "features/Taxonomies/utils";

const Information = ({
  values,
  isSubmitting,
  value,
  handleNext,
  setUploading,
  setFileId,
  isParent,
  setFieldValue,
  validateField,
}) => {
  const dispatch = useDispatch();
  const roleType = useSelector((state) => state.auth.user.data.roleType);
  const statuses = useSelector(customerStatusSelectors.selectAll);
  const reps = useSelector(accountRepsSelectors.selectAll);
  const templateOptions = useSelector(accountingTemplateSelectors.selectAll);
  const user = useSelector((state) => state.auth.user.data);
  const clientNid = values.field_phoenix_client;
  const allNationalContracts = useSelector(nationalContractsSelectors.selectAll);
  const [nationalContracts, setNationalContracts] = useState(filterNationalContracts(allNationalContracts, clientNid));

  useEffect(() => {
    setNationalContracts(filterNationalContracts(allNationalContracts, clientNid));
    setFieldValue("field_national_contracts", []);
    const promise = dispatch(
      fetchPhxClientUsers({ "filter[phx_client_nids]": clientNid })
    );

    return () => {
      promise.abort();
    };
  }, [dispatch, clientNid, allNationalContracts, setFieldValue]);

  const handleUploadStart = () => {
    setUploading(true);
    setFileId(null);
  };

  const handleUploadSuccess = (fid) => {
    setUploading(false);
    setFileId(fid);
  };

  const handleUploadFail = () => {
    setUploading(false);
    setFileId(null);
  };

  const handleRemoveFile = () => {
    setFileId(null);
  };

  // const handleClientChange = (evt) => {
  //   console.log(evt.target.value);
  // };

  return (
    <div hidden={value !== 0}>
      <Typography variant="h6">Information</Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        style={{ marginBottom: "2rem" }}
      >
        Create a new Customer to grant access to job assignment, compliance
        tracking, and other system based benefits.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xxs={12}>
          <SingleUploader
            entity="node"
            bundle="customer"
            field="field_logo"
            inputLabel="Logo"
            label="Select Logo"
            onUploadSuccess={handleUploadSuccess}
            onUploadStart={handleUploadStart}
            onUploadFail={handleUploadFail}
            onRemove={handleRemoveFile}
          />
        </Grid>
        <Grid item xxs={12}>
          <TextFormField
            fullWidth
            name="title"
            label="Name"
            variant="outlined"
            size="small"
            required
            disabled={isSubmitting}
          />
        </Grid>
        {size(user.field_clients) > 1 && (
          <Grid item xxs={12}>
            <SelectFormField
              fullWidth
              name="field_phoenix_client"
              label="Client"
              variant="outlined"
              size="small"
              required
              disabled={isSubmitting}
              // onChange={handleClientChange}
              options={user.field_clients.map((obj) => ({
                value: obj.nid,
                label: obj.field_pc_proper_name,
              }))}
            />
          </Grid>
        )}
        <Grid item xxs={12}>
          <SelectFormField
            fullWidth
            name="field_customer_status"
            label="Status"
            variant="outlined"
            size="small"
            required
            disabled={isSubmitting}
            options={statuses.map((obj) => ({
              value: obj.machine_name,
              label: obj.label,
            }))}
          />
        </Grid>
        <Grid item xxs={12} md={4}>
          <TextFormField
            fullWidth
            name="field_phone"
            label="Phone"
            disabled={isSubmitting}
            isPhone
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xxs={12} md={4}>
          <TextFormField
            fullWidth
            name="field_phone_after_hours"
            label="Phone After Hours"
            disabled={isSubmitting}
            isPhone
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xxs={12} md={4}>
          <TextFormField
            fullWidth
            name="field_fax"
            label="Fax"
            disabled={isSubmitting}
            isPhone
            variant="outlined"
            size="small"
          />
        </Grid>
        {roleType === "phx_client" &&
        <Grid item xxs={12}>
          <SelectFormField
            fullWidth
            name="field_phx_client_rep"
            label="Account Rep"
            variant="outlined"
            size="small"
            disabled={isSubmitting || size(reps) <= 0}
            options={reps.map((obj) => ({
              value: obj.uid,
              label: obj.name,
            }))}
            helperText={
              size(reps) <= 0
                ? "Must select a client before adding a rep"
                : null
            }
          />
        </Grid>
        }
        {(roleType === "phx_client" && size(nationalContracts) > 0) && (
          <Grid item xxs={12}>
            <SelectFormField
              fullWidth
              name="field_national_contracts"
              label="National Contracts"
              variant="outlined"
              size="small"
              disabled={isSubmitting}
              multiple
              options={nationalContracts.map((obj) => ({
                value: obj.tid,
                label: obj.name,
              }))}
              renderValue={(selected) => (
                <div
                  style={{
                    minHeight: "10px",
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {selected.map((value) => (
                    <TaxonomyChip
                      key={value}
                      tid={value}
                      selectors={nationalContractsSelectors}
                    />
                  ))}
                </div>
              )}
            />
          </Grid>
        )}
        {size(templateOptions) > 0 && (
          <Grid item xxs={12}>
            <SelectFormField
              fullWidth
              name="field_jda_invoice_template"
              label="Accounting Template"
              variant="outlined"
              size="small"
              disabled={isSubmitting}
              options={templateOptions.map((obj) => ({
                value: obj.machine_name,
                label: obj.label,
              }))}
            />
          </Grid>
        )}
        <Grid item xxs={12}>
          <TextFormField
            fullWidth
            name="field_note"
            label="Notes"
            variant="outlined"
            size="small"
            multiline
            disabled={isSubmitting}
          />
        </Grid>
        <Grid item xxs={12}>
          <RadioFormField
            name="field_res_com"
            aria="field_res_com-radio"
            label="Type"
            disabled={isSubmitting}
            style={{ flexDirection: "row" }}
            options={[
              { label: "Commercial", value: "commercial" },
              { label: "Residential", value: "residential" },
            ]}
            orientation="horizontal"
          />
        </Grid>
        {!isParent && (
          <Grid item xxs={12}>
            <CheckboxFormField
              name="field_is_parent_customer"
              label="Is A Parent Customer"
            />
          </Grid>
        )}
        <Grid item xxs={12}>
          <Grid container spacing={3}>
            <TMTermsFormComponent
              setFieldValue={setFieldValue}
              isSubmitting={isSubmitting}
            />
          </Grid>
        </Grid>
        <Grid item xxs={12}>
          <div
            style={{
              marginTop: "2rem",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              isSubmitting={isSubmitting}
              disableElevation
              size="small"
              onClick={handleNext}
            >
              Next
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Information.propTypes = {};

export default Information;
