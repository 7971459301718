import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Typography,
} from "@mui/material";

import { DialogForm } from "common/Dialog";

import { postJobCustomerWelcome } from "../jobSlice";
import Alert from "common/Alert";

const CustomerResendInvite = ({ job, customer, custUser, children }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [error, setMessage] = useState(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = (data) => {
    setIsSubmitting(true);
    const params = {
      job_nid: job.nid,
      customer_nid: customer.nid,
      resend_invite: true,
      cust_user_id: custUser.entity_id,
    };

    dispatch(postJobCustomerWelcome(params))
      .then(response => {
        setIsSubmitting(false);
        if(!response.error){
          onClose();
        }
        else{
          setMessage({
            id: "negative",
            msg: response.payload.message,
          })
        }
      })
  };

  const onClose = () => {
    setMessage(null);
    setOpen(false);
  };

  return (
    <>
      {children && (
        React.cloneElement(children, {
          onClick: handleOpen,
        })
      )}
      <DialogForm
        open={open}
        onClose={onClose}
        maxWidth="xs"
        title="Resend Invite"
        disablePadding
        onSubmit={handleSubmit}
        disableActions={isSubmitting}
        initialValues ={{}}
        // disableOverlay
        overrideDirty
        submitText="Resend Invite"
      >
        {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
          <div className="p-5">
            {error && <Alert kind={error.id}>{error.msg}</Alert>}
            <Typography
              variant="subtitle1"
              color="textSecondary"
            >
              Are you sure you want to resend the invite to {custUser.full_name}?
            </Typography>
          </div>
        )}
      </DialogForm>
    </>
  );
};

CustomerResendInvite.propTypes = {};

export default CustomerResendInvite;
