import React from "react";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import { Typography } from "@mui/material";
import constants from "components/constants";

import { ChartSkeleton } from "../Skeletons";
import { Loader } from "./Styled";
const analyticsIcon = `${process.env.PUBLIC_URL}/images/icons/icon-analytics--large.svg`;

const gradientChartOptions = {
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  layout: {
    padding: {
      top: 20
    }
  },
  tooltips: {
    backgroundColor: constants.colorGrayLightest,
    titleFontColor: constants.colorGrayDarker,
    bodyFontColor: constants.colorGrayDark,
    bodySpacing: 4,
    xPadding: 12,
    mode: "nearest",
    intersect: 0,
    position: "nearest",
    callbacks: {
      label: function(tooltipItems, data) {
        return `$${tooltipItems.value}`;
      }
    }
  },
  responsive: true,
  scales: {
    yAxes: [
      {
        display: false,
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color : constants.colorGrayMedium + "00",
          zeroLineColor: "transparent"
        },
        ticks: {
          padding: 20,
          fontColor: constants.colorGrayMedium
        }
      }
    ],

    xAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: constants.colorBlueLight + "1a",
          zeroLineColor: "transparent"
        },
        ticks: {
          padding: 20,
          fontColor: constants.colorGrayMedium
        }
      }
    ]
  }
};

const getLabels = data => {
  const labels = [];
  data.forEach(object => {
    if (object.label) {
      labels.push(object.label);
    }
  });

  return labels;
};

const getValues = data => {
  const values = [];
  data.forEach(object => {
    if (object.value) {
      values.push(object.value);
    }
  });

  return values;
};

const getPointColors = data => {
  const colors = [];
  data.forEach(object => {
    if (object.validated) {
      colors.push(constants.colorGreen);
    } else {
      colors.push(constants.colorOrange);
    }
  });

  return colors;
};

const LineGraph = ({ data: newData, loading }) => {
  const labels = getLabels(newData);
  const values = getValues(newData);
  const colors = getPointColors(newData);

  const data = canvas => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 230, 0, 50);
    gradient.addColorStop(1, constants.colorBlueDarker + "33");
    gradient.addColorStop(0.8, constants.colorBlueDarker + "1a");
    gradient.addColorStop(0, constants.colorBlueDark + "00");

    return {
      labels: labels,
      datasets: [
        {
          label: "",
          fill: true,
          backgroundColor: gradient,
          borderColor: constants.colorBlueAlt,
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: colors,
          pointBorderColor: constants.colorBlueDarker + "00",
          pointHoverBackgroundColor: constants.colorBlueAlt,
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          datalabels: {
            display: false
          },
          data: values
        }
      ]
    };
  };

  return loading ? (
    <Loader>
      <ChartSkeleton />
    </Loader>
  ) : !newData.length ? (
    <Loader>
      <img src={analyticsIcon} alt="No Data to display" />
      <Typography variant="subtitle1" color="textSecondary">
        No Data to Display
      </Typography>
    </Loader>
  ) : (
    <Line data={data} options={gradientChartOptions} />
  );
};

const { arrayOf, object } = PropTypes;
LineGraph.propTypes = {
  data: arrayOf(object)
};

export default LineGraph;
