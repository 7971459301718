import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import ContentLoader from "react-content-loader";
import constants from "components/constants";

const Styles = styled.header`
  width: inherit;
  height: var(--page-header-height);
  position: relative;
  z-index: calc(var(--site-header-z-index) - 1);
  border-top: 1px solid var(--color-black);

  @media print{
    border-top: 1px solid var(--color-gray-darkest);
    border-bottom: 1px solid var(--color-gray-darkest);
  }

  .header {
    display: flex;
    align-items: center;
    height: var(--page-header-height);
    justify-content: space-between;
    padding: 0 20px;
  }

  .title-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  }

  &.no-wrap .title-wrapper{ flex-wrap: nowrap; }
  &.justify-between .title-wrapper{ justify-content: space-between; }

  // .icon {
  //   margin-right: 10px;
  //   min-width: 30px;
  //   height: 30px;
  // }
  svg.icon{
    fill: var(--color-gray-lightest);

    @media print{
      fill: var(--color-gray-darkest);
    }
  }

  .loader {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    &__left {
      svg {
        margin-right: 1rem;
      }
    }

    &__right {
    }
  }

  @media (max-width: ${constants.breakpoints.sm}) {
    height: var(--page-header-height-2-line);

    .header {
      height: var(--page-header-height-2-line);
      display: block;
      padding: 0.5rem;

      .title-wrapper {
        margin-bottom: 0.75rem;

        h6{ font-size: 1rem; }
      }
    }
  }

  &.slim{
    @media (max-width: ${constants.breakpoints.sm}) {
      height: var(--page-header-height);

      .header {
        height: var(--page-header-height);
      }
    }
    // @media (max-width: ${constants.breakpoints.xs}) {
    //   height: var(--page-header-height-2-line);

    //   .header {
    //     height: var(--page-header-height-2-line);
    //   }
    // }
  }
`;

const Loader = ({ height, width, radius }) => {
  return (
    <ContentLoader
      speed={2}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      foregroundColor="var(--color-gray-medium)"
      backgroundColor="var(--color-gray-lighter)"
    >
      <rect x="0" y="0" rx={radius} ry={radius} width={width} height={height} />
    </ContentLoader>
  );
};

const PageHeader = ({
  title,
  hasTitleMenu,
  titleMenu,
  nav: Nav = null,
  icon = null,
  children,
  hamburger: Hamburger,
  loading,
  pageHeaderStyles,
  pageHeaderProps,
}) => {
  const _icon = icon && (
    icon?.src ? (
      <img src={icon.src} alt={icon.alt} className="icon header-icon" />
    ) : (
      React.cloneElement(icon, {
        ...icon.props,
        className: `icon header-icon ${icon.props.className || ""}`,
      })
    )
  );

  return (
    <Styles {...pageHeaderProps} className={`${pageHeaderProps?.slim ? 'slim' : ''} ${pageHeaderProps?.className ? pageHeaderProps.className : ''}`}>
      <div className="header bg-banner-black" style={pageHeaderStyles}>
        {loading ? (
          <div className="loader">
            <div className="loader__left">
              <Loader height={20} width={150} radius={3} />
            </div>
            <div className="loader__right">
              <Loader height={30} width={100} radius={3} />
            </div>
          </div>
        ) : (
          <>
            <div className="title-wrapper">
              {Hamburger && <Hamburger />}
              {hasTitleMenu ? (
                <>
                {_icon}
                {titleMenu}
                </>
              ) : (
                <Typography variant="h6" className="flex content-wrapper">{_icon} {title}</Typography>
              )}
            </div>
            <div className="buttons">{children}</div>
          </>
        )}
      </div>
    </Styles>
  );
};

const { string, element, object } = PropTypes;
PageHeader.propTypes = {
  title: PropTypes.oneOfType([string.isRequired, object.isRequired]),
  Nav: element,
  icon: object,
};

export default PageHeader;
