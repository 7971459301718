import React from "react";
import styled from "styled-components";
import { TextField, LinearProgress, Typography } from "@mui/material";

import FileIcon from "../FileIcon";
import { formatFileSize } from "../../utility";

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;

const Details = styled.div`
  flex: 1;
  margin-bottom: 0.5rem;
`;

const Progress = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 2px;
  overflow: hidden;
`;

const Controls = styled.div`
  display: flex;
  margin-top: 1rem;
  margin-bottom: 0.5rem;

  & > .MuiFormControl-root {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const getStatusMessage = (res) => {
  if (res && res.status !== 201) {
    return res.message;
  } else {
    return null;
  }
};

const TerminationFile = ({ file, formData, setFormData, isSubmitting }) => {
  const handleTitleChange = (e) => {
    setFormData({
      ...formData,
      [file.id]: { ...formData[file.id], title: e.target.value },
    });
  };

  const error = getStatusMessage(file.response);
  return (
    <Container>
      <FileIcon filename={file.file.name} status={file.status} />
      <Details>
        <Typography
          variant="subtitle1"
          noWrap
          style={{ maxWidth: "215px", lineHeight: "1rem" }}
        >
          {file.file.name}
        </Typography>
        <Typography variant="caption">
          {formatFileSize(file.file.size)}
        </Typography>
        {error && (
          <div>
            <Typography variant="caption" color="error">
              {error}
            </Typography>
          </div>
        )}
      </Details>
      <Controls>
        <TextField
          name="Title"
          variant="outlined"
          value={formData[file.id] ? formData[file.id].title : ""}
          onChange={handleTitleChange}
          size="small"
          label="Title"
          disabled={isSubmitting}
        />
      </Controls>
      {file.status === 1 && (
        <Progress>
          <LinearProgress color="secondary" variant="determinate" value={file.progress} />
        </Progress>
      )}
    </Container>
  );
};

TerminationFile.propTypes = {};

export default TerminationFile;
