import React from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

import Alert from "common/Alert";
import ReportSearch from "./ReportSearch";
import { CollapsibleTable, TableConfigure } from "../Table";
import LinkHeader from "common/LinkHeader";
import { exportBatch, clear } from "actions";
import { getRegions } from "../../actions/taxonomies";
import Paper from "../Paper";
import { Actions, ActionGroup } from "./Styled";
import { formatFilters, removeColumns } from "utility";
import { getReportMemberDeclined } from "actions/reports";
import { GetApp } from "@mui/icons-material";
import constants from "components/constants";

const useStyles = makeStyles((theme) => ({
  button: {
    background: constants.gradientBlack,
    paddingRight: "10px",
    color: constants.colorWhite,
    "&:hover": {
      background: constants.gradientBlack,
      color: constants.colorWhite,
    },
  },
}));

const columnSettings = {
  title: { minWidth: 400 },
  timestamp: { minWidth: 400 },
  assigned_name: { minWidth: 400 },
  answering_name: { minWidth: 400 },
  notes: { minWidth: 400 },
};

const createColumns = (columns) =>
  columns.map((column) => {
    column.id = column.index;
    column = {
      ...column,
      notSortable: !column.sortable,
      ...columnSettings[column.index],
    };
    return column;
  });

const createData = (title, timestamp, assigned_name, answering_name, notes) => {
  return {
    title,
    timestamp,
    assigned_name,
    answering_name,
    notes,
  };
};

const ReportMembers = ({
  loadMembers,
  report=[],
  exportReport,
  clear,
  loading,
  pagination={
    count: 0,
    current_page: 0,
    total_pages: 0,
    display_columns: [],
  },
  statuses=[],
  regions=[],
  affiliations=[],
  states=[],
  error,
  org,
}) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filters, setFilters] = React.useState({}); //eslint-disable-line
  const [hiddenColumns, setHiddenColumns] = React.useState([]);

  const handleExportReport = async () => {
    let filterSettings = formatFilters(filters);

    if (org) {
      filterSettings = {
        ...filterSettings,
        "filter[phx_client_nids]": org,
      };
    }

    await exportReport("/rest/report/assignment-answers/member/declined", {
      _export: "csv",
      keywords: searchTerm,
      ...filterSettings,
    });
  };

  const columns = React.useMemo(() => {
    if (loading) return [];
    const temp = createColumns(pagination.display_columns);
    removeColumns(
      [
        "counties_owned",
        "counties_served",
        "certification_names",
        "division_types_json",
        "national_contracts",
      ],
      temp
    );
    return temp;
  }, [pagination, loading]);

  const rows = React.useMemo(() => {
    if (loading) return [];
    return report.map((row) =>
      createData(
        row.title,
        row.timestamp,
        row.assigned_name,
        row.answering_name,
        row.notes
      )
    );
  }, [report, loading]);

  const hideColumns = React.useMemo(() => {
    if (loading) return [];
    return hiddenColumns;
  }, [hiddenColumns, loading]);

  return (
    <div>
      <Helmet>
        <title>Members Report | Phoenix Solutions</title>
        <meta name="description" content="Reports" />
      </Helmet>
      {error && <Alert kind="negative">{error.message}</Alert>}
      <LinkHeader to="/reports" color="default" size="small" back>
        Back to reports
      </LinkHeader>
      <Paper margin="none" style={{ marginTop: "1rem" }}>
        <Actions>
          <Typography variant="h6">Members</Typography>
          <ActionGroup>
            <TableConfigure
              columns={columns}
              hideColumns={setHiddenColumns}
              currentColumns={hiddenColumns}
            />
            <Button
              className={classes.button}
              size="small"
              startIcon={<GetApp />}
              onClick={handleExportReport}
            >
              Export
            </Button>
          </ActionGroup>
        </Actions>
        <ReportSearch setQuery={setSearchTerm} />
        <CollapsibleTable
          clientFilter={"phx_client_nids"}
          columns={columns}
          rows={rows}
          stickyHeader
          loading={loading}
          hideColumns={hideColumns}
          pageRequest={loadMembers}
          originalOrderBy="member_name"
          originalOrder="asc"
          filters={filters}
          pagination={pagination}
          search={searchTerm}
          size="small"
        />
      </Paper>
    </div>
  );
};

ReportMembers.propTypes = {};

const mapStateToProps = (state) => ({
  loading: state.app.report.loading,
  report: state.app.report.data.data,
  pagination: state.app.report.data.pagination,
  error: state.app.report.error,
  statuses: state.app.memberStatus.data,
  regions: state.app.regions.data,
  affiliations: state.app.affiliations.data,
  states: state.app.states.data,
  services: state.app.jobDivisionTypes.data,
});

const mapDispatchToProps = (dispatch) => ({
  loadMembers: (params) => dispatch(getReportMemberDeclined(params)),
  loadRegions: () => dispatch(getRegions()),
  exportReport: (url, params) => dispatch(exportBatch(url, params)),
  clear: () => dispatch(clear("report")),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportMembers);
