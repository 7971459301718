import constants from "components/constants";
import chroma from "chroma-js";
import { uniq } from "lodash";

export const getHubEnvironments = (user) => {
  const colorRange = [
    constants.colorBlack,
    constants.colorBlue,
    constants.colorOrange,
    constants.colorGreenAlt,
    constants.colorRed,
    constants.colorYellow,
    constants.colorTeal,
    constants.colorGray,
    constants.colorBlueLight,
    constants.colorWhite,
    constants.colorGrayMedium,
  ];

  const envs = [];
  let inc = 0;
  user.field_clients.forEach((client) => {
    const bgColor = client.field_highlight_color ? client.field_highlight_color : colorRange[inc];
    const bkgIsDark = chroma(bgColor).get("lab.l") < 80 ? true : false;
    const textColor = bkgIsDark ? "var(--color-white)" : chroma(bgColor).darken(5);
    // const altColor = inc === 0 ? "var(--color-gray-light)" : chroma(bgColor).brighten(1.75);
    envs.push({
      type: 'client',
      nid: client.nid,
      name: client.title,
      logo: client.icon,
      className: 'App\\Models\\Client',
      wsToken: client.field_web_socket_token,
      color: bgColor,
      textColor: textColor,
      // altColor: altColor,
    });
    inc = (inc < colorRange.length - 1 ? inc + 1 : 0);
  });
  user.field_members.forEach((member) => {
    const bgColor = member.field_highlight_color ? member.field_highlight_color : colorRange[inc];
    const bkgIsDark = chroma(bgColor).get("lab.l") < 80 ? true : false;
    const textColor = bkgIsDark ? "var(--color-white)" : chroma(bgColor).darken(5);
    // const altColor = inc === 0 ? "var(--color-gray-light)" : chroma(bgColor).brighten(1.75);
    envs.push({
      type: 'member',
      nid: member.nid,
      name: member.title,
      logo: member.logo,
      className: 'App\\Models\\Member',
      wsToken: member.field_web_socket_token,
      color: bgColor,
      textColor: textColor,
      // altColor: altColor,
    })
    inc = (inc < colorRange.length - 1 ? inc + 1 : 0);
  });

  return envs;
}

export const getHubEnvironmentHeadingStyles = (env) => {
  let pageHeaderStyles = {};
  if(env && env.color !== constants.colorBlack){
    pageHeaderStyles = {
      background: env.color,
      color: env.textColor,
      boxShadow: constants.shadowInnerTop + ', ' + constants.dropShadow,
    }
  }
  return pageHeaderStyles;
}

export const getHubEnvironmentIndicatorInfo = (hubUpdates, indexes) => {
  const indicatorInfo = {
    orange: 0,
    green: 0,
    red: 0,
    description: null,
  };
  const description = [];
  if(hubUpdates){
    hubUpdates.forEach((env) =>
      Object.entries(env).forEach(([key, value]) => {
        if(!indexes || indexes.includes(key)){
          const s = (value > 1 ? 's' : '');
          if(key.indexOf('current') === 0){
            if(value > 0){
              indicatorInfo.green = 1;
              description.push(`Event${s} Happening Now`);
            }
          }
          else if(value > 0){
            if(key === 'unread_thread_messages'){
              indicatorInfo.red = 1;
              description.push(`Unread Message${s}`);
            }
            else{
              indicatorInfo.orange = 1;
              if(key === 'new_groups'){
                description.push(`New Group${s}`);
              }
              else if(key === 'new_events'){
                description.push(`New Event${s}`);
              }
              else{
                description.push(`Unread Post${s}`);
              }
            }
          }
        }
      })
    );
  }
  if(description.length > 0){
    indicatorInfo.description = uniq(description).join(' - ');
    // uniq(description).forEach((item, i) => {
    //   if(i > 0){
    //     if(i === uniq(description).length - 1){
    //       indicatorInfo.description += ', and ';
    //     }
    //     else{
    //       indicatorInfo.description += ', ';
    //     }
    //   }
    //   indicatorInfo.description += item;
    // })
  }
  return indicatorInfo;
}
