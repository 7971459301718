import React from "react";
import styled from "styled-components";
import {
  Select,
  FormControl,
  InputLabel,
  Typography,
  MenuItem,
} from "@mui/material";

import FileIcon from "../FileIcon";
import { formatFileSize } from "../../utility";
import constants from "../constants";

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0 1rem 0;
  flex-wrap: wrap;
`;

const Details = styled.div`
  flex: 1;
`;

const Progress = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.5rem;
  background: ${constants.colorGrayLight};
  border-radius: 2px;
  overflow: hidden;
`;

const Bar = styled.div`
  height: 100%;
  background: ${constants.colorGreen};
  transition: width 0.25s;
`;

const Controls = styled.div`
  display: flex;
  margin-top: 1rem;
  margin-bottom: 0.5rem;

  & > .MuiFormControl-root {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const types = [
  { value: "general", label: "General" },
  { value: "xactimate_draft", label: "Xactimate Draft" },
  { value: "lead_asbestos_receipt", label: "Lead/Asbestos Receipt" },
];

const MoreFloodsDocument = ({ file, isSubmitting, formData, setFormData }) => {
  const handleCategoryChange = (e) => {
    setFormData({
      ...formData,
      [file.id]: { ...formData[file.id], category: e.target.value },
    });
  };

  return (
    <Container>
      <FileIcon filename={file.file.name} status={file.status} />
      <Details>
        <Typography
          variant="subtitle1"
          noWrap
          style={{ maxWidth: "245px", lineHeight: "1rem" }}
        >
          {file.file.name}
        </Typography>
        <Typography variant="caption">
          {formatFileSize(file.file.size)}
        </Typography>
      </Details>
      {!isSubmitting && (
        <Controls>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel id={`file-category`}>Category</InputLabel>
            <Select
              labelId={`file-category`}
              displayEmpty
              variant="outlined"
              value={formData[file.id] ? formData[file.id].category : ""}
              onChange={handleCategoryChange}
              labelwidth={70}
              disabled={isSubmitting}
            >
              {types.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Controls>
      )}
      {file.status === 1 && (
        <Progress>
          <Bar style={{ width: `${file.progress}%` }}></Bar>
        </Progress>
      )}
    </Container>
  );
};

MoreFloodsDocument.propTypes = {};

export default MoreFloodsDocument;
