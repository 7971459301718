import React from "react";
import {
  Grid,
  Typography,
} from "@mui/material";
import { DialogForm } from "common/Dialog";
import { TextFormField } from "common/Fields";
import * as Yup from "yup";
import { VscAccount } from "react-icons/vsc";
import buttonStyles from "common/TextEditor/css/button.module.css";
import { getLocalStorage } from "utility";
import { useLocalStorage } from "hooks";

const PublicCommenterUpdate = ({ open, setOpen, onSubmit, onClose }) => {
  const commenter = getLocalStorage('public_commenter');
  const [publicCommenter, setPublicCommenter] = useLocalStorage('public_commenter', {}); // eslint-disable-line no-unused-vars

  const handleSubmit = (data) => {
    setPublicCommenter({
      name: data.name.trim(),
      email: data.email.trim().toLowerCase(),
    })
    onSubmit();
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    // onClose();
  };

  return (
    <>
    <div className={buttonStyles.buttonWrapper}>
      <button onClick={() => setOpen(true)} className={buttonStyles.button}>
        <VscAccount />
      </button>
    </div>
    <DialogForm
      open={open}
      onSubmit={handleSubmit}
      onClose={handleClose}
      maxWidth="xs"
      initialValues={{
        name: commenter?.name ? commenter.name : "",
        email: commenter?.email ? commenter.email : "",
        captcha: false,
      }}
      validationSchema={Yup.object({
        name: Yup.string().required("Required"),
        email: Yup.string().email().required("Required"),
      })}
    >
      {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
        <>
        <Typography variant="h6">
          Please tell us who you are.
        </Typography>
        <Grid container spacing={3}>
          <Grid item xxs={12}>
            <TextFormField
              fullWidth
              htmlFor="name"
              name="name"
              label="Name"
              margin="normal"
              disabled={isSubmitting}
              size="small"
              variant="outlined"
              labelwidth={40}
              required
            />
          </Grid>
          <Grid item xxs={12}>
            <TextFormField
              fullWidth
              htmlFor="email"
              name="email"
              label="Email"
              margin="normal"
              disabled={isSubmitting}
              size="small"
              variant="outlined"
              labelwidth={40}
              required
            />
          </Grid>
        </Grid>
        </>
      )}
    </DialogForm>
    </>
  );
};

PublicCommenterUpdate.propTypes = {};

export default PublicCommenterUpdate;
