import React from "react";
import { connect } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Typography, Link, Grid } from "@mui/material";
import moment from "moment";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { RouteDialog } from "../../Dialog";
import config from "../../../config";
import { DialogForm } from "../../Form";
import { TextFormField } from "common/Fields";
import { DateFormField } from "common/Fields";
import { postNode } from "../../../actions/paragraphs";

const Form = ({ url, addParagraph, loadCRMEmails, nid }) => {
  const [markup, setMarkup] = React.useState(null);
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );

  const handleEditorStateChange = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);

    const markup = draftToHtml(rawContentState);

    setMarkup(markup);
    setEditorState(editorState);
  };

  const handleSubmit = async (
    data,
    setSubmitting,
    setMessage,
    successCallback,
    errorCallback
  ) => {
    setSubmitting(true);
    setMessage(null);

    const recipients = data.field_em_recipients.split(",");
    const cc = data.field_em_cc.split(",");
    const bcc = data.field_em_bcc.split(",");

    const params = {
      _links: {
        type: {
          href: `${config.api_url}/rest/type/node/email_message`,
        },
      },
      title: [{ value: data.field_em_subject }],
      field_em_bcc: bcc.map((r) => ({ value: r.trim() })),
      field_em_cc: cc.map((r) => ({ value: r.trim() })),
      field_em_message: [{ value: markup }],
      field_em_recipients: recipients.map((r) => ({ value: r.trim() })),
      field_em_from: [{ value: data.field_em_from }],
      field_em_sent_time: [{ value: moment(data.field_em_sent_time).format() }],
      field_em_parent_node: [{ target_id: nid }],
    };

    const response = await addParagraph(params);
    if (response.status === 201) {
      await loadCRMEmails();
      successCallback("Email sent successfully.");
    } else {
      errorCallback(setMessage, response, []);
    }
  };

  return (
    <DialogForm
      submitText="Archive"
      url={url}
      path="archive"
      initialValues={{
        field_em_bcc: "",
        field_em_cc: "",
        field_em_message: "",
        field_em_recipients: "",
        field_em_subject: "",
        has_cc: false,
        has_bcc: false,
        field_em_from: "",
        field_em_sent_time: moment(),
      }}
      onSubmit={handleSubmit}
    >
      {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
        <>
          <Grid container spacing={2}>
            <Grid item xxs={12} sm={6}>
              <TextFormField
                fullWidth
                htmlFor="field_em_from"
                name="field_em_from"
                label="From"
                disabled={isSubmitting}
                type="text"
                labelwidth={65}
                size="small"
                variant="outlined"
              />
            </Grid>
            <Grid item xxs={12} sm={6}>
              <DateFormField
                fullWidth
                disableToolbar
                inputVariant="outlined"
                size="small"
                variant="inline"
                format="MM/DD/YYYY"
                id="field_em_sent_time"
                label="Sent On"
                name="field_em_sent_time"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "1rem",
            }}
          >
            <Typography variant="subtitle2">
              Separate multiple emails with a comma.
            </Typography>
            <div>
              {!values.has_cc && (
                <Link
                  onClick={() => setFieldValue("has_cc", true)}
                  style={{ marginRight: "1rem" }}
                >
                  CC
                </Link>
              )}
              {!values.has_bcc && (
                <Link onClick={() => setFieldValue("has_bcc", true)}>BCC</Link>
              )}
            </div>
          </div>
          <TextFormField
            fullWidth
            htmlFor="field_em_recipients"
            name="field_em_recipients"
            label="Recipients"
            margin="normal"
            disabled={isSubmitting}
            type="text"
            labelwidth={65}
            size="small"
            variant="outlined"
          />
          {values.has_cc && (
            <TextFormField
              fullWidth
              htmlFor="field_em_cc"
              name="field_em_cc"
              label="CC"
              margin="normal"
              disabled={isSubmitting}
              type="text"
              labelwidth={30}
              size="small"
              variant="outlined"
            />
          )}
          {values.has_bcc && (
            <TextFormField
              fullWidth
              htmlFor="field_em_bcc"
              name="field_em_bcc"
              label="BCC"
              margin="normal"
              disabled={isSubmitting}
              type="text"
              labelwidth={30}
              size="small"
              variant="outlined"
            />
          )}
          <TextFormField
            fullWidth
            htmlFor="field_em_subject"
            name="field_em_subject"
            label="Subject"
            margin="normal"
            disabled={isSubmitting}
            type="text"
            labelwidth={60}
            size="small"
            variant="outlined"
          />
          <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            toolbar={{
              options: [
                "inline",
                "blockType",
                "fontSize",
                "fontFamily",
                "list",
                "textAlign",
                "colorPicker",
                "link",
                "history",
              ],
              inline: {
                options: ["bold", "italic", "underline"],
              },
            }}
            onEditorStateChange={handleEditorStateChange}
          />
        </>
      )}
    </DialogForm>
  );
};

const EmailsForm = ({ url, ...rest }) => {
  return (
    <RouteDialog
      route="archive"
      path="archive"
      url={url}
      maxWidth="md"
      title="Archive Email"
    >
      <Form url={url} {...rest} />
    </RouteDialog>
  );
};

EmailsForm.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
  addParagraph: (params) => dispatch(postNode("paragraph", params)),
});

export default connect(null, mapDispatchToProps)(EmailsForm);
