import React from "react";
import PropTypes from "prop-types";
import { TableCell, TableRow } from "@mui/material";

import "./Table.scss";

const TableTotals = ({ columns, totals, hideColumns }) => {
  return (
    <TableRow>
      {columns.map((column) => {
        const value = totals[column.id];
        return hideColumns.includes(column.id) ? null : (
          <TableCell
            key={column.id}
            align={column.align}
            padding={column.disablePadding ? "none" : "normal"}
          >
            {column.format && typeof value === "number" ? (
              column.format(value)
            ) : (
              <strong>{value}</strong>
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

const { array } = PropTypes;
TableTotals.propTypes = {
  columns: array.isRequired,
};

export default TableTotals;
