import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { size } from "lodash";
import Helmet from "react-helmet";
import { unwrapResult } from "@reduxjs/toolkit";
import { Formik, Form } from "formik";
import {
  Typography,
  CircularProgress,
  Stepper,
  Step,
  StepButton,
  Divider,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { useParams, useHistory } from "react-router-dom";

import Information from "./Information";
import Contacts from "./Contacts";
import Location from "./Location";
import Alert from "features/Alert";
import { DefaultLoader } from "common/Loader";
import { postMember } from "../memberSlice";
import * as Yup from "yup";
import { addMemberFormSchema } from "schemas/forms/member";
import { tmTermsSchema } from "components/TMTerms/forms/schema/TMTerms";
import { clientsSelectors } from "features/Clients/clientsSlice";
import { prepClientAssignedItems, parseClientAssignedFieldSubmitData } from "components/ClientAssignedField/utils";
import { ReactComponent as IconProviderLine } from "assets/images/icons/icon-provider-line.svg";

const StyledStepper = withStyles({
  root: {
    background: "transparent",
  },
})(Stepper);

function getSteps() {
  return ["Information", "Contact", "Location"];
}

const STEP_FIELDS = [
  [
    "title",
    "field_note",
    "field_status",
    "field_region",
    "field_office_id",
    "field_affiliation",
    "field_next_job_number",
    "field_website",
    "field_video_call_url",
    "field_response_time",
  ],
  [],
  ["field_street_address", "field_billing_address"],
];

const hasValidationErrors = (fields, errors, value, index) => {
  if (value > index) {
    for (let i = 0; i < fields.length; i += 1) {
      if (errors[fields[i]]) {
        return true;
      }
    }
  }

  return false;
};

const isComplete = (fields, errors, value, index) => {
  if (value > index) {
    for (let i = 0; i < fields.length; i += 1) {
      if (errors[fields[i]]) {
        return false;
      }
    }

    return true;
  } else {
    return false;
  }
};

const Add = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const loadingUser = useSelector((state) => state.auth.user.loading);
  const clients = useSelector(clientsSelectors.selectAll);

  const [value, setValue] = useState(0);
  const [progress, setProgress] = useState({ show: false, message: "" });
  const [uploading, setUploading] = useState(false);
  const [fileId, setFileId] = useState(null);

  const handleNext = (validateForm) => () => {
    validateForm();
    setValue(value + 1);
  };

  const handlePrev = (validateForm) => () => {
    validateForm();
    setValue(value - 1);
  };

  const handleSubmit = async (data) => {
    try {
      setProgress({ show: true, message: "Creating Provider" });

      let phxClients = clients;
      if(size(data.field_phoenix_clients) > 0){
        phxClients = data.field_phoenix_clients;
      }
      const clientItems = prepClientAssignedItems(phxClients);
      const params = {
        title: [{ value: data.title }],
        field_highlight_color: [{ value: data.field_highlight_color }],
        field_status: [{ value: data.field_status }],
        field_office_id: [{ value: data.field_office_id }],
        field_region: [{ target_id: data.field_region }],
        field_phoenix_clients: phxClients.map((c) => ({
          target_id: c.nid,
        })),
        field_affiliation: [{ target_id: data.field_affiliation }],
        field_jd_types: (data?.field_jd_types ? parseClientAssignedFieldSubmitData(data?.field_jd_types, clientItems) : null),
        field_certifications: (data?.field_certifications ? data?.field_certifications.map((obj) => ({
          target_id: obj,
        })) : null),
        field_next_job_number: [{ value: data.field_next_job_number }],
        field_website: [{ uri: data.field_website }],
        field_video_call_url: [{ value: data.field_video_call_url }],
        field_response_time: [{ value: data.field_response_time }],
        field_note: [{ value: data.field_note }],
        field_phone: [{ value: data.field_phone }],
        field_phone_after_hours: [{ value: data.field_phone_after_hours }],
        field_fax: [{ value: data.field_fax }],
        field_primary_poc: data.field_primary_poc
          ? [{ target_id: data.field_primary_poc.uid }]
          : [],
        field_secondary_pocs: data.field_secondary_pocs.map((u) => ({
          target_id: u.uid,
        })),
        field_after_hours_pocs: data.field_after_hours_pocs.map((u) => ({
          target_id: u.uid,
        })),
        field_phx_certified_poc: data.field_phx_certified_poc
          ? [{ target_id: data.field_phx_certified_poc.uid }]
          : [],
        field_street_address: [data.field_street_address],
        field_is_parent_member: [{ value: data.field_is_parent_member }],
        field_g2: [{ value: data.field_g2 }],
        field_d2d: [{ value: data.field_d2d }],
        field_tm_terms: (data?.field_tm_terms ? [{value: JSON.stringify(data.field_tm_terms)}] : null),
        field_daily_sheets_member: [{ value: data.field_daily_sheets_member }],
      };
      if (data.field_national_contracts) {
        params.field_national_contracts = data.field_national_contracts.map((tid) => ({ target_id: tid }));
      }

      if (fileId) {
        params.field_logo = [{ target_id: fileId }];
      }

      if (id) {
        params._meta = {
          parent_member_nid: id,
        };
      }

      if (data.field_member_code) {
        params.field_member_code = [{ value: data.field_member_code }];
      }

      if (!data.billing_same_as_street) {
        params.field_billing_address = [data.field_billing_address];
      }

      const resultAction = await dispatch(postMember(params));

      unwrapResult(resultAction);
      history.push(resultAction.payload.self);
    } catch (err) {
      console.log(err);
      setProgress({ show: false, message: "" });
    }
  };

  const steps = getSteps();

  return (
    <div
      style={{
        flex: 1,
        overflow: "auto",
        background: "var(--color-gray-background)",
      }}
    >
      <Helmet>
        <title>Add Provider</title>
        <meta name="description" content="Add Provider" />
      </Helmet>
      {loadingUser ? (
        <DefaultLoader />
      ) : (
        <>
          <Formik
            initialValues={{
              title: "",
              field_highlight_color: "",
              field_status: "",
              field_phoenix_clients: [],
              field_office_id: "",
              field_region: "",
              field_affiliation: "",
              field_next_job_number: "",
              field_jd_types: [],
              field_jd_types_same: true,
              field_certifications: [],
              field_website: "",
              field_video_call_url: "",
              field_response_time: "",
              field_note: "",
              field_phone: "",
              field_phone_after_hours: "",
              field_fax: "",
              field_primary_poc: null,
              field_secondary_pocs: [],
              field_after_hours_pocs: [],
              field_phx_certified_poc: null,
              field_national_contracts: [],
              billing_same_as_street: true,
              field_street_address: {
                address_line1: "",
                address_line2: "",
                locality: "",
                administrative_area: "",
                postal_code: "",
                country_code: "US",
              },
              field_billing_address: {
                address_line1: "",
                address_line2: "",
                locality: "",
                administrative_area: "",
                postal_code: "",
                country_code: "US",
              },
              field_is_parent_member: false,
              field_g2: false,
              field_d2d: false,
              field_daily_sheets_member: false,
              field_member_code: "",
              field_tm_terms: null,
            }}
            validationSchema={Yup.object().shape({
              ...addMemberFormSchema.fields,
              ...tmTermsSchema.fields,
            })}
            onSubmit={async (data, { setSubmitting }) => {
              setSubmitting(true);
              await handleSubmit(data);
            }}
          >
            {({
              values,
              errors,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
              validateForm,
              validateField,
            }) => (
              <Form>
                <div
                  style={{
                    padding: "1.25rem",
                    maxWidth: 800,
                    position: "relative",
                    margin: "0 auto",
                  }}
                >
                  {progress.show && (
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        background: "rgba(245, 242, 247, 0.5)",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 2,
                      }}
                    >
                      <CircularProgress />
                      <Typography variant="h6" style={{ marginTop: "2rem" }}>
                        {progress.message}
                      </Typography>
                    </div>
                  )}
                  <div
                    style={{
                      padding: "2rem 0",
                    }}
                  >
                    <Typography variant="h6">{<IconProviderLine className="header-icon -mb-1" />} Add Provider</Typography>
                    <Typography variant="body1" color="textSecondary">
                      Complete the steps below to create a new Provider.
                    </Typography>
                    <Divider style={{ margin: "2rem 0" }} />
                    <StyledStepper nonLinear activeStep={value}>
                      {steps.map((label, index) => {
                        const stepProps = {};
                        const buttonProps = {};
                        const hasErrors = hasValidationErrors(
                          STEP_FIELDS[index],
                          errors,
                          value,
                          index
                        );
                        const stepComplete = isComplete(
                          STEP_FIELDS[index],
                          errors,
                          value,
                          index
                        );

                        if (hasErrors) {
                          buttonProps.error = true;
                        }
                        if (stepComplete) {
                          stepProps.completed = true;
                        }

                        return (
                          <Step key={label} {...stepProps}>
                            <StepButton
                              onClick={() => {
                                validateForm();
                                setValue(index);
                              }}
                            >
                              {label}
                            </StepButton>
                          </Step>
                        );
                      })}
                    </StyledStepper>
                    <Alert
                      margin="none"
                      disableElevation
                      disableRoundedCorners
                    />
                  </div>
                  <Information
                    value={value}
                    handleNext={handleNext(validateForm)}
                    isSubmitting={isSubmitting}
                    values={values}
                    setFileId={setFileId}
                    setUploading={setUploading}
                    setFieldValue={setFieldValue}
                    validateField={validateField}
                    errors={errors}
                  />
                  <Contacts
                    value={value}
                    handleNext={handleNext(validateForm)}
                    handlePrev={handlePrev(validateForm)}
                    isSubmitting={isSubmitting}
                    values={values}
                  />
                  <Location
                    value={value}
                    handlePrev={handlePrev(validateForm)}
                    handleSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                    values={values}
                    setFieldValue={setFieldValue}
                    uploading={uploading}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </div>
  );
};

Add.propTypes = {};

export default Add;
