import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { DialogContent, Button, Chip, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";

import Dialog from "../Dialog";
import AutocompleteFormField from "../AutocompleteFormField";
import { SelectFormField } from "common/Fields";
import { DateFormField } from "common/Fields";
import { TextFormField } from "common/Fields";
import ButtonLoader from "common/ButtonLoader";
import Alert from "common/Alert";
import { FormContainer, FormActions } from "./Styled";
import {
  formatComplianceActivity,
  formatError,
  formatErrorResponseString,
  formatField,
} from "../../utility";
import { FormSkeleton } from "../Skeletons";
import {
  getComplianceActivity,
  patchComplianceActivity,
  getComplianceChangeReasons,
  getLiftMethods,
} from "../../actions";
import { addMemberCompliance } from "../../schemas/forms/member";
import {
  jobDivisionTypesSelectors,
  memberStatusSelectors,
} from "../../features/Taxonomies/taxonomiesSlice";

const useStyles = makeStyles((theme) => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
    minHeight: "16px",
  },
  chip: {
    margin: 2,
  },
}));

const findLabel = (data, tid) => {
  if (!data || !tid) return null;
  const item = data.find((obj) => obj.tid === tid);
  return item.name;
};

const formatDivisionsForSubmit = (d) => {
  const params = {};
  if (!d) return params;
  d.forEach((tid) => {
    params[tid] = tid;
  });

  return params;
};

const formatMembersForSubmit = (c) => {
  const params = {};
  c.forEach((child) => {
    params[child.nid] = child.nid;
  });

  return params;
};

const MemberComplianceEdit = ({
  onClose,
  open,
  nid,
  loadComplianceActivity,
  data,
  loadingData,
  loadChangeReasons,
  loadingReasons,
  changeReasons,
  loadLiftMethods,
  loadingLiftMethods,
  liftMethods,
  updateComplianceActivity,
  loadMemberCompliance,
  showSnackbar,
  refreshMember,
}) => {
  const statuses = useSelector(memberStatusSelectors.selectAll);
  const types = useSelector(jobDivisionTypesSelectors.selectAll);
  const classes = useStyles();
  const loading = loadingData || loadingReasons || loadingLiftMethods;
  const [message, setMessage] = React.useState(null);
  const [openChildAc, setOpenChildAc] = React.useState(false);
  const fields = formatComplianceActivity(data);

  useEffect(() => {
    if (open) {
      loadChangeReasons();
      loadLiftMethods();
    }
  }, [loadChangeReasons, loadLiftMethods, open]);

  useEffect(() => {
    if (open && nid) {
      loadComplianceActivity(nid);
    }
  }, [nid, open, loadComplianceActivity]);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xs"
      onClose={handleClose}
      title="Edit Compliance"
    >
      {loading ? (
        <FormSkeleton />
      ) : (
        <>
          <DialogContent>
            <Formik
              initialValues={{
                "start-date": moment(fields.startDate),
                description: fields.description,
                status: fields.status ? fields.status : "",
                reason: fields.reason ? fields.reason.tid : "",
                divisions: [
                  ...fields.divisions.map((type) => type.tid.toString()),
                ],
                method: fields.method,
                "lift-date": moment(fields.liftDate),
                members:
                  data && data.field_compliance_members
                    ? data.field_compliance_members.map((member) => ({
                        name: formatField(member, "title"),
                        nid: formatField(member, "nid"),
                      }))
                    : [],
              }}
              validationSchema={addMemberCompliance}
              onSubmit={async (data, { setSubmitting, setSuccess }) => {
                setSubmitting(true);

                const params = {
                  compliance_activity_nid: nid,
                  compliance_members: [formatMembersForSubmit(data.members)],
                  compliance_start_date: moment(data["start-date"]).format(
                    "YYYY-MM-DD"
                  ),
                  compliance_change_reason: data.reason,
                  status: data.status,
                  compliance_lift_method: data.method,
                  compliance_lift_date: "",
                  divisions: formatDivisionsForSubmit(data.divisions),
                  compliance_description: data.description,
                };

                if (fields.liftDate || data["lift-date"]) {
                  params.compliance_lift_date = data["lift-date"]
                    ? moment(data["lift-date"]).format("YYYY-MM-DD")
                    : null;
                }

                const response = await updateComplianceActivity(params);
                if (response.status === 201) {
                  await loadMemberCompliance();
                  showSnackbar({
                    msg: "Successfully updated compliance activity.",
                    kind: "positive",
                  });
                  if (refreshMember) {
                    refreshMember();
                  } else {
                    onClose();
                  }
                } else {
                  const errorMessage = formatError(response);
                  const newMessage = formatErrorResponseString(
                    errorMessage.msg
                  );
                  errorMessage.msg = newMessage;
                  setMessage(errorMessage);
                }
              }}
            >
              {({ values, errors, isSubmitting, setFieldValue }) => (
                <FormContainer>
                  {message && <Alert kind={message.id}>{message.msg}</Alert>}
                  <AutocompleteFormField
                    name="members"
                    label="Providers"
                    htmlFor="members"
                    margin="normal"
                    open={openChildAc}
                    onOpen={() => setOpenChildAc(true)}
                    onClose={() => setOpenChildAc(false)}
                    disabled={true}
                    options={
                      data && data.field_compliance_members
                        ? data.field_compliance_members.map((member) => ({
                            name: formatField(member, "title"),
                            nid: formatField(member, "nid"),
                          }))
                        : []
                    }
                    multiple
                    size="small"
                  />
                  <DateFormField
                    fullWidth
                    disableToolbar
                    variant="inline"
                    format="MM/DD/YYYY"
                    id="start-date"
                    label="Start Date"
                    margin="normal"
                    name="start-date"
                    KeyboardButtonProps={{
                      "aria-label": "change start date",
                    }}
                  />
                  <SelectFormField
                    fullWidth
                    name="reason"
                    label="Change Reason"
                    margin="normal"
                    required
                    disabled={isSubmitting}
                    options={changeReasons.map((reason) => ({
                      label: reason.name,
                      value: reason.tid,
                    }))}
                  />
                  <SelectFormField
                    fullWidth
                    name="status"
                    label="Compliance Status"
                    margin="normal"
                    required
                    disabled={isSubmitting}
                    options={statuses
                      .filter((status) => status.label !== "Archived")
                      .map((status) => ({
                        label: status.label,
                        value: status.machine_name,
                      }))}
                  />
                  <Grid container spacing={3}>
                    <Grid item xxs={values.method === "Timed" ? 6 : 12}>
                      <SelectFormField
                        fullWidth
                        name="method"
                        label="Lift Method"
                        margin="normal"
                        required
                        disabled={isSubmitting}
                        options={liftMethods.map((method) => ({
                          label: method.label,
                          value: method.machine_name,
                        }))}
                      />
                    </Grid>
                    {values.method === "Timed" && (
                      <Grid item xxs={6}>
                        <DateFormField
                          fullWidth
                          disableToolbar
                          variant="inline"
                          format="MM/DD/YYYY"
                          clearable="true"
                          id="lift-date"
                          label="Lift Date"
                          margin="normal"
                          name="lift-date"
                          KeyboardButtonProps={{
                            "aria-label": "change lift date",
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <SelectFormField
                    fullWidth
                    name="divisions"
                    label="Affected Divisions"
                    margin="normal"
                    disabled={true}
                    options={types.map((type) => ({
                      label: type.name,
                      value: type.tid,
                    }))}
                    multiple
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((value) => (
                          <Chip
                            size="small"
                            key={value}
                            label={findLabel(types, value)}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )}
                  />
                  <TextFormField
                    fullWidth
                    htmlFor="description"
                    name="description"
                    label="Description"
                    margin="normal"
                    disabled={isSubmitting}
                    labelwidth={70}
                    multiline
                  />
                  <FormActions>
                    <Button size="small" onClick={onClose} component="a">
                      Cancel
                    </Button>
                    <ButtonLoader
                      variant="contained"
                      color="primary"
                      type="submit"
                      isSubmitting={isSubmitting}
                      disableElevation
                      size="small"
                    >
                      Update
                    </ButtonLoader>
                  </FormActions>
                </FormContainer>
              )}
            </Formik>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

const { func, bool } = PropTypes;
MemberComplianceEdit.propTypes = {
  onClose: func,
  open: bool,
};

const mapStateToProps = (state) => ({
  loadingData: state.app.complianceActivity.loading,
  data: state.app.complianceActivity.data,
  changeReasons: state.app.complianceChangeReasons.data,
  loadingReasons: state.app.complianceChangeReasons.loading,
  liftMethods: state.app.liftMethods.data,
  loadingLiftMethods: state.app.liftMethods.loading,
});

const mapDispatchToProps = (dispatch) => ({
  loadComplianceActivity: (nid) => dispatch(getComplianceActivity(nid)),
  loadChangeReasons: () => dispatch(getComplianceChangeReasons()),
  loadLiftMethods: () => dispatch(getLiftMethods()),
  updateComplianceActivity: (nid, params) =>
    dispatch(patchComplianceActivity(nid, params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberComplianceEdit);
