import React from "react";

import { Container, Content } from "./Styled";

const CollapsibleHeader = ({ collapse, children }) => {
  return (
    <Container collapse={collapse}>
      <Content>{children}</Content>
    </Container>
  );
};

CollapsibleHeader.propTypes = {};

export default CollapsibleHeader;
