import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchDivisionSnapshot,
  getDivisionDataSelector,
  getJobDataSelector,
  getJobLoadingSelector,
} from "features/Job/jobSlice";
import { getLocationDataSelector } from "features/Location/locationSlice";
import Information from "features/Job/D2D/components/Information";
import { DefaultLoader } from "common/Loader";

const JobSnapshot = ({ nid }) => {
  const dispatch = useDispatch();
  const loading = useSelector(getJobLoadingSelector);
  const division = useSelector(getDivisionDataSelector);
  const location = useSelector(getLocationDataSelector);
  const job = useSelector(getJobDataSelector);

  useEffect(() => {
    let promise;

    if (nid) {
      promise = dispatch(fetchDivisionSnapshot(nid));
    }

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [dispatch, nid]);

  return (
    <div>
      {loading ? (
        <DefaultLoader />
      ) : (
        <Information
          job={job}
          division={division}
          location={location}
          onNavigate={() => {}}
        />
      )}
    </div>
  );
};

export default JobSnapshot;
