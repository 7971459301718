import React, { useState, useEffect } from "react";
import {
  Clock1,
  Clock2,
  Clock3,
  Clock4,
  Clock5,
  Clock6,
  Clock7,
  Clock8,
  Clock9,
  Clock10,
  Clock11,
  Clock12,
  Pencil,
} from "lucide-react";

import { useDebounce } from "hooks";
import {
  Tooltip,
} from "@mui/material";
import styled from "styled-components";

const Components = {
  1: Clock1,
  2: Clock2,
  3: Clock3,
  4: Clock4,
  5: Clock5,
  6: Clock6,
  7: Clock7,
  8: Clock8,
  9: Clock9,
  10: Clock10,
  11: Clock11,
  12: Clock12,
};


export const Container = styled.span`
  position: relative;

  /* The changing svg animation seems to make the click area unreliable. This seems to help */
  svg{ pointer-events: none; }

  .pencil{
    position: absolute;
    top: 0px;
    right: -2px;
    fill: white;
    width: 45% !important;
    height: 45% !important;
  }

  &.disabled,
	&.disabled:hover{
    opacity: 0.4;
    background-color: transparent;
    cursor: default;
  }
`;

const ClockIcon = ({
  run,
  title,
  editIcon,
  ...props
}) => {
  const [inc, setInc] = useState(1);
  const debouncedInc = useDebounce(inc, 250);
  // if(run){
    // setTimeout(() => {
    //   setInc(inc <= 12 ? inc + 1 : 1);
    // }, 500);
  // }

  useEffect(() => {
    if(run){
      setInc(debouncedInc < 12 ? debouncedInc + 1 : 1);
    }
  }, [run, debouncedInc, setInc]);

  if(editIcon){
    props.className = `${props?.className ? props.className : ''} relative`
  }

  return (
    <Container { ...props }>
      <Tooltip title={title ? title : ''}>
        <>
        {editIcon && <Pencil className="pencil" />}
        {React.createElement(Components[inc])}
        </>
      </Tooltip>
    </Container>
  );
};

ClockIcon.propTypes = {};

export default ClockIcon;
