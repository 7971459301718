import React, { useEffect } from "react";
import { useSelector, connect } from "react-redux";
import Helmet from "react-helmet";
import { Grid, Typography, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import makeStyles from "@mui/styles/makeStyles";
import { GetApp } from "@mui/icons-material";
import { FilterList } from "@mui/icons-material";
import moment from "moment";
import { Autocomplete } from "@mui/material";

import Alert from "common/Alert";
import ButtonDropdown from "common/ButtonDropdown";
import ReportSearch from "./ReportSearch";
import { CollapsibleTable, TableConfigure } from "../Table";
import LinkHeader from "common/LinkHeader";
import { exportBatch, getReportCustomerLocations, clear } from "../../actions";
import Paper from "../Paper";
import {
  ActionGroup,
  Actions,
  FilterMenu,
  Col,
  Cols,
  ButtonGroup,
} from "./Styled";
import { formatFilters, removeColumns, formatProximitySearch } from "../../utility";
import { statesSelectors } from "../../features/Taxonomies/taxonomiesSlice";
import Button from "common/Button";
import constants from "components/constants";

const useStyles = makeStyles((theme) => ({
  button: {
    background: constants.gradientBlack,
    paddingRight: "10px",
    color: constants.colorWhite,
    "&:hover": {
      background: constants.gradientBlack,
      color: constants.colorWhite,
    },
  },
}));

const formatMembersOwningCounties = (names, routes) => {
  const data = [];
  if (names && routes) {
    const namesList = names.split(",");
    namesList.forEach((name, index) => {
      data.push(
        <div key={index}>
          <LinkHeader to={routes[index]} size="small" color="default">
            {name}
          </LinkHeader>
        </div>
      );
    });

    return data;
  } else {
    return names;
  }
};

const columnSettings = {
  proximity_search_distance: { minWidth: 100 },
  customer_name: { minWidth: 300 },
  customer_location_name: { minWidth: 300 },
  customer_parent_name: { minWidth: 300 },
  location_size: { minWidth: 200 },
  address_line1: { minWidth: 200, label: "Street" },
  address_line2: { minWidth: 200, label: "Street 2" },
  locality: { minWidth: 50 },
  administrative_area: { minWidth: 50 },
  postal_code: { minWidth: 50, label: "Zip" },
  county: { minWidth: 150 },
  primary_contact: { minWidth: 200 },
  secondary_contacts: { minWidth: 200 },
  after_hours_contacts: { minWidth: 200 },
  special_instructions: { minWidth: 400 },
  members_owning_county: { minWidth: 400 },
  preferred_member_1_name: { minWidth: 300 },
  preferred_member_2_name: { minWidth: 300 },
  preferred_member_3_name: { minWidth: 300 },
  preferred_member_4_name: { minWidth: 300 },
};

const createColumns = (columns) =>
  columns.map((column) => {
    if (column.index === "state") {
      column.index = "administrative_area";
    }
    if (column.index === "city") {
      column.index = "locality";
    }
    column.id = column.index;
    column = {
      ...column,
      notSortable: !column.sortable,
      ...columnSettings[column.index],
    };
    return column;
  });

const createData = (
  created,
  customer_name,
  customer_location_name,
  customer_parent_name,
  location_size,
  street,
  street2,
  locality,
  administrative_area,
  zip,
  county,
  primary_contact,
  secondary_contacts,
  after_hours_contacts,
  special_instructions,
  members_owning_county,
  preferred_member_1_name,
  preferred_member_2_name,
  preferred_member_3_name,
  preferred_member_4_name,
  paths,
  proximity_search_distance
) => {
  return {
    created: (
      <div>
        <Typography variant="body1">
          {moment.unix(created).format("MMM Do, YYYY")}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {moment.unix(created).format("h:mm A")}
        </Typography>
      </div>
    ),
    customer_name: customer_name ? (
      <LinkHeader to={paths.customer} size="small" color="default">
        {customer_name}
      </LinkHeader>
    ) : null,
    customer_location_name: customer_location_name ? (
      <LinkHeader to={paths.customer_location} size="small" color="default">
        {customer_location_name}
      </LinkHeader>
    ) : null,
    customer_parent_name: customer_parent_name ? (
      <LinkHeader to={paths.customer_parent} size="small" color="default">
        {customer_parent_name}
      </LinkHeader>
    ) : null,
    location_size,
    address_line1: street,
    address_line2: street2,
    locality,
    administrative_area,
    postal_code: zip,
    county,
    primary_contact: primary_contact,
    secondary_contacts: secondary_contacts,
    after_hours_contacts: after_hours_contacts,
    special_instructions,
    members_owning_county,
    preferred_member_1_name: preferred_member_1_name ? (
      <LinkHeader to={paths.preferred_member_1} size="small" color="default">
        {preferred_member_1_name}
      </LinkHeader>
    ) : null,
    preferred_member_2_name: preferred_member_2_name ? (
      <LinkHeader to={paths.preferred_member_2} size="small" color="default">
        {preferred_member_2_name}
      </LinkHeader>
    ) : null,
    preferred_member_3_name: preferred_member_3_name ? (
      <LinkHeader to={paths.preferred_member_3} size="small" color="default">
        {preferred_member_3_name}
      </LinkHeader>
    ) : null,
    preferred_member_4_name: preferred_member_4_name ? (
      <LinkHeader to={paths.preferred_member_4} size="small" color="default">
        {preferred_member_4_name}
      </LinkHeader>
    ) : null,
    proximity_search_distance: proximity_search_distance ? (
      `${Number(proximity_search_distance).toFixed(2)}mi`
    ) : null,
    collapsible: (
      <Cols>
        <Col>
          <Typography variant="subtitle1">Special Instructions</Typography>
          <Typography variant="body1">
            {special_instructions ? special_instructions : "No Value"}
          </Typography>
        </Col>
        <Col>
          <Typography variant="subtitle1">Members Owning County</Typography>
          {members_owning_county
            ? formatMembersOwningCounties(
                members_owning_county,
                paths.members_owning_countys
              )
            : "No Value"}
        </Col>
      </Cols>
    ),
  };
};

const initialFormData = {
  administrative_area: [],
  created: {
    type: "date-range",
    value: { from: null, through: null },
  },
};

const originalOrder = 'desc';
const originalOrderBy = 'created';

const ReportCustomerLocations = ({
  report=[],
  clear,
  pagination={
    count: 0,
    current_page: 0,
    total_pages: 0,
    display_columns: [],
  },
  loading,
  error,
  loadCustomerLocations,
  exportReport,
  cancelSource,
  org,
}) => {
  const classes = useStyles();
  const states = useSelector(statesSelectors.selectAll);
  const [hiddenColumns, setHiddenColumns] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [proximityFormData, setProximityFormData] = React.useState({});
  const [order, setOrder] = React.useState(originalOrder);
  const [orderBy, setOrderBy] = React.useState(originalOrderBy);
  const [filters, setFilters] = React.useState({});
  const [formData, setFormData] = React.useState(initialFormData);

  useEffect(() => {
    return () => {
      clear();
    };
  }, [clear]);

  const handleExportReport = async () => {
    let proximitySearch = formatProximitySearch(filters.proximitySettings);
    let filterSettings = formatFilters(filters);

    if (org) {
      filterSettings = {
        ...filterSettings,
        "filter[phx_client_nid]": org,
      };
    }

    await exportReport("/rest/report/customer_locations", {
      _export: "csv",
      keywords: searchTerm,
      order: filters.adjustedOrderBy,
      sort: filters.adjustedOrder,
      ...proximitySearch,
      ...filterSettings,
    });
  };

  const columns = React.useMemo(() => {
    if (loading) return [];
    const temp = createColumns(pagination.display_columns);
    removeColumns(["special_instructions", "members_owning_county"], temp);
    return temp;
  }, [pagination, loading]);

  const rows = React.useMemo(() => {
    if (loading) return [];
    return report.map((row) =>
      createData(
        row.created,
        row.customer_name,
        row.customer_location_name,
        row.customer_parent_name,
        row.location_size,
        row.address_line1,
        row.address_line2,
        row.locality,
        row.administrative_area,
        row.postal_code,
        row.county,
        row.primary_contact,
        row.secondary_contacts,
        row.after_hours_contacts,
        row.special_instructions,
        row.members_owning_county,
        row.preferred_member_1_name,
        row.preferred_member_2_name,
        row.preferred_member_3_name,
        row.preferred_member_4_name,
        row._paths,
        row.proximity_search_distance
      )
    );
  }, [report, loading]);

  const hideColumns = React.useMemo(() => {
    if (loading) return [];
    return hiddenColumns;
  }, [hiddenColumns, loading]);

  const handleChange = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateRangeChange = (value, name, index) => {
    const dateValue = formData[name].value;
    dateValue[index] = value;
    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value: { ...dateValue },
      },
    });
  };

  const handleProximityChange = (value, name) => {
    setOrder('asc');
    setOrderBy('proximity_search_distance');
    setProximityFormData({
      ...proximityFormData,
      [name]: value,
    });
  };

  const applyFilters = () => {
    setFilters({
      ...formData,
      adjustedOrder: order,
      adjustedOrderBy: orderBy,
      proximitySettings: proximityFormData,
    });
  };

  const resetFilters = () => {
    setOrder(originalOrder);
    setOrderBy(originalOrderBy);
    setProximityFormData({});
    setFilters({});
    setFormData({
      ...initialFormData,
      created: {
        type: "date-range",
        value: { from: null, through: null },
      },
    });
  };

  return (
    <div>
      <Helmet>
        <title>Customer Locations Report | Helix</title>
        <meta name="description" content="Reports" />
      </Helmet>
      {error && <Alert kind="negative">{error.message}</Alert>}
      <LinkHeader to="/reports" color="default" size="small" back>
        Back to reports
      </LinkHeader>
      <Paper margin="none" style={{ marginTop: "1rem" }}>
        <Actions>
          <Typography variant="h6">Customer Locations</Typography>
          <ActionGroup>
            <TableConfigure
              columns={columns}
              hideColumns={setHiddenColumns}
              currentColumns={hiddenColumns}
            />
            <ButtonDropdown
              icon={FilterList}
              label="Filters"
              style={{ marginLeft: "1rem" }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              color="tertiary"
            >
              <FilterMenu>
                <Autocomplete
                  options={states.map((state) => ({
                    label: state.label,
                    value: state.value,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "administrative_area");
                  }}
                  value={formData.administrative_area}
                />
                <Typography variant="body1" style={{ marginTop: "0.5rem" }}>
                  Date Created
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.created.value.from}
                        onChange={(value) =>
                          handleDateRangeChange(value, "created", "from")
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="From"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        InputAdornmentProps={{ size: "small" }}
                        KeyboardButtonProps={{
                          "aria-label": "change from date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xxs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formData.created.value.through}
                        onChange={(value) =>
                          handleDateRangeChange(value, "created", "through")
                        }
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        label="Through"
                        margin="normal"
                        inputVariant="outlined"
                        size="small"
                        KeyboardButtonProps={{
                          "aria-label": "change through date",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Typography variant="body1" style={{ marginTop: "0.5rem" }}>
                  Proximity Search
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  label="Address/Zip"
                  onChange={(e) => {
                    handleProximityChange(e.target.value, "address");
                  }}
                  fullWidth
                  value={proximityFormData.address ?? ""}
                  style={{ marginTop: "1rem", marginBottom: "1rem" }}
                />
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel id="proximity-search-radius-label">Radius</InputLabel>
                  <Select
                    labelId="proximity-search-radius-label"
                    label="Radius"
                    size="small"
                    value={proximityFormData.radius ?? ""}
                    displayEmpty
                    onChange={(e) => {
                      handleProximityChange(e.target.value, "radius");
                    }}
                    >
                      <MenuItem value={10}>10 Miles</MenuItem>
                      <MenuItem value={25}>25 Miles</MenuItem>
                      <MenuItem value={50}>50 Miles</MenuItem>
                      <MenuItem value={100}>100 Miles</MenuItem>
                      <MenuItem value={200}>200 Miles</MenuItem>
                  </Select>
                </FormControl>
                <ButtonGroup>
                  <Button disableElevation size="small" onClick={resetFilters}>
                    Reset
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    size="small"
                    style={{ marginRight: "1rem" }}
                    onClick={applyFilters}
                  >
                    Apply
                  </Button>
                </ButtonGroup>
              </FilterMenu>
            </ButtonDropdown>
            <Button
              className={classes.button}
              size="small"
              startIcon={<GetApp />}
              onClick={handleExportReport}
            >
              Export
            </Button>
          </ActionGroup>
        </Actions>
        <ReportSearch setQuery={setSearchTerm} />
        <CollapsibleTable
          clientFilter={"phx_client_nid"}
          columns={columns}
          rows={rows}
          stickyHeader
          loading={loading}
          pageRequest={loadCustomerLocations}
          hideColumns={hideColumns}
          filters={filters}
          search={searchTerm}
          originalOrderBy={originalOrderBy}
          originalOrder={originalOrder}
          pagination={pagination}
          size="small"
          source={cancelSource}
        />
      </Paper>
    </div>
  );
};

ReportCustomerLocations.propTypes = {};

const mapStateToProps = (state) => ({
  loading: state.app.report.loading,
  report: state.app.report.data.data,
  pagination: state.app.report.data.pagination,
  error: state.app.report.error,
  cancelSource: state.app.report.cancel,
});

const mapDispatchToProps = (dispatch) => ({
  loadCustomerLocations: (params) =>
    dispatch(getReportCustomerLocations(params)),
  exportReport: (url, params) => dispatch(exportBatch(url, params)),
  clear: () => dispatch(clear("report")),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportCustomerLocations);
