import React, { useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

import { RouteDialog } from "../../Dialog";
import config from "../../../config";
import { getNode } from "../../../actions";
import { patchNode } from "../../../actions/paragraphs";
import { DialogForm } from "../../Form";
import { TextFormField } from "common/Fields";
import { DateFormField } from "common/Fields";
import { FormSkeleton } from "../../Skeletons";
import { formatCRMCall } from "../../../utility";
import { SelectFormField } from "common/Fields";

const getFields = (types, statuses) => {
  return [
    {
      label: "Subject",
      name: "field_crmc_subject",
      multiline: false,
      type: "text",
    },
    {
      label: "Date",
      name: "field_crmc_time",
      multiline: false,
      type: "date",
    },
    {
      label: "Status",
      name: "field_crmc_status",
      multiline: false,
      type: "select",
      options: statuses.map((status) => ({
        label: status.name,
        value: status.name,
      })),
    },
    {
      label: "Type of call",
      name: "field_crmc_call_type",
      multiline: false,
      type: "select",
      options: types.map((type) => ({
        label: type.name,
        value: type.name,
      })),
    },
    {
      label: "Comments",
      name: "field_crmc_notes",
      multiline: true,
      type: "text",
    },
  ];
};

const Form = ({
  url,
  updateParagraph,
  loadCRMCalls,
  loadParagraph,
  loading,
  data,
  statuses,
  types,
}) => {
  const fields = getFields(statuses, types);
  const { id } = useParams();
  const {
    title,
    field_crmc_time,
    field_crmc_status,
    field_crmc_call_type,
    field_crmc_notes,
  } = formatCRMCall(data);

  useEffect(() => {
    if (id) {
      loadParagraph(id);
    }
  }, [id, loadParagraph]);

  const handleSubmit = async (
    data,
    setSubmitting,
    setMessage,
    successCallback,
    errorCallback
  ) => {
    const params = {
      _links: {
        type: {
          href: `${config.api_url}/rest/type/node/crm_call`,
        },
      },
      title: [{ value: data.field_crmc_subject }],
      field_crmc_time: [{ value: moment(data.field_crmc_time).format() }],
      field_crmc_status: [{ value: data.field_crmc_status }],
      field_crmc_call_type: [{ value: data.field_crmc_call_type }],
      field_crmc_notes: [{ value: data.field_crmc_notes }],
    };

    const response = await updateParagraph(id, params);
    if (response.status === 200) {
      await loadCRMCalls();
      successCallback("Call updated successfully.");
    } else {
      errorCallback(setMessage, response, []);
    }
  };

  return loading ? (
    <FormSkeleton />
  ) : (
    <DialogForm
      submitText="Edit"
      url={url}
      path="edit"
      initialValues={{
        field_crmc_subject: title ? title : "",
        field_crmc_time: field_crmc_time ? moment(field_crmc_time) : moment(),
        field_crmc_status: field_crmc_status ? field_crmc_status : "",
        field_crmc_call_type: field_crmc_call_type ? field_crmc_call_type : "",
        field_crmc_notes: field_crmc_notes ? field_crmc_notes : "",
      }}
      onSubmit={handleSubmit}
    >
      {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
        <div>
          {fields.map((f) => (
            <React.Fragment key={f.name}>
              {f.type === "text" ? (
                <TextFormField
                  fullWidth
                  htmlFor={f.name}
                  name={f.name}
                  label={f.label}
                  margin="normal"
                  disabled={isSubmitting}
                  type="text"
                  multiline={f.multiline}
                  labelwidth={70}
                />
              ) : f.type === "select" ? (
                <SelectFormField
                  fullWidth
                  name={f.name}
                  label={f.label}
                  margin="normal"
                  size="small"
                  disabled={isSubmitting}
                  options={f.options}
                  multiple={f.multiple}
                />
              ) : (
                <DateFormField
                  fullWidth
                  disableToolbar
                  margin="normal"
                  variant="inline"
                  format="MM/DD/YYYY"
                  id={f.name}
                  label={f.label}
                  name={f.name}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </DialogForm>
  );
};

const CallsEditForm = ({ url, ...rest }) => {
  return (
    <RouteDialog
      route="edit/:id"
      path="edit"
      url={url}
      maxWidth="xs"
      title="Edit Call"
    >
      <Form url={url} {...rest} />
    </RouteDialog>
  );
};

CallsEditForm.propTypes = {};

const mapStateToProps = (state) => ({
  data: state.app.node.data,
  loading: state.app.node.loading,
});

const mapDispatchToProps = (dispatch) => ({
  updateParagraph: (pid, params) => dispatch(patchNode("node", pid, params)),
  loadParagraph: (pid) => dispatch(getNode(pid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CallsEditForm);
