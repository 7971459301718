/**
 * This is a rebuild of node_modules/@syncfusion/ej2-image-editor/src/image-editor/action/export.js exportToCanvas()
 * but we've added logic to include drop shadow
 *
 * @param {*} object
 * @returns
 */
export const exportToCanvas = function (object) {
  var parent = object.parent;
  const _export = parent.exportModule;
  _export.updatePvtVar();
  var width;
  var height;
  if (object.parent.aspectWidth) {
      // if (!isBlazor()) {
          parent.notify('toolbar', { prop: 'resizeClick', value: { bool: false } });
      // }
      // else {
      //     parent.performResizeClick();
      // }
      parent.currentToolbar = 'resize-toolbar';
      parent.okBtn();
      if (parent.transform.degree % 90 === 0 && parent.transform.degree % 180 !== 0) {
          width = object.parent.aspectHeight;
          height = object.parent.aspectWidth;
      }
      else {
          width = object.parent.aspectWidth;
          height = object.parent.aspectHeight;
      }
  }
  else if (parent.currSelectionPoint) {
      width = parent.img.srcWidth;
      height = parent.img.srcHeight;
  }
  else {
      width = parent.baseImgCanvas.width;
      height = parent.baseImgCanvas.height;
  }
  var obj = { width: 0, height: 0 };
  parent.notify('crop', { prop: 'calcRatio', onPropertyChange: false,
      value: { obj: obj, dimension: { width: width, height: height } } });
  var ratio = obj;
  var tempContextFilter = _export.lowerContext.filter;
  // Manipulating blur value
  if (_export.lowerContext.filter !== 'none') {
      var splitWords = _export.lowerContext.filter.split(' ');
      var value = parseFloat(splitWords[5].split('(')[1]);
      value *= ((ratio.width + ratio.height) / 2);
      splitWords[5] = 'blur(' + value + 'px)';
      _export.lowerContext.filter = splitWords.join(' ');
  }
  var tempCanvas = parent.createElement('canvas', {
      id: parent.element.id + '_tempCanvas', attrs: { name: 'canvasImage' }
  });
  var tempContext = tempCanvas.getContext('2d');
  tempCanvas.width = width;
  tempCanvas.height = height;
  var dimObj = { width: 0, height: 0 };
  parent.notify('transform', { prop: 'calcMaxDimension', onPropertyChange: false,
      value: { width: width, height: height, obj: dimObj } });
  var maxDimension = dimObj;
  tempCanvas.style.maxWidth = maxDimension.width + 'px';
  tempCanvas.style.maxHeight = maxDimension.height + 'px';
  var temp = _export.lowerContext.filter;
  tempContext.filter = _export.lowerContext.filter;
  // parent.notify('export', { prop: 'downScaleImgCanvas', value: { ctx: tempContext, width: width, height: height } });
  _export.downScaleImgCanvas(tempContext, width, height);
  // this.downScaleImgCanvas(tempContext, width, height);
  // parent.notify('export', { prop: 'updateFrame', value: { tempContext: tempContext, isAnnotation: false } });
  _export.updateFrame(tempContext);
  // this.updateFrame(tempContext);
  _export.lowerContext.filter = temp;
  if (parent.transform.degree !== 0 || parent.transform.currFlipState !== '') {
      // parent.notify('export', { prop: 'updateSaveContext', value: { tempContext: tempContext } });
      _export.updateSaveContext(tempContext);
      // this.updateSaveContext(tempContext);
      // parent.notify('export', { prop: 'exportTransformedImage', value: { tempContext: tempContext } });
      _export.exportTransformedImage(tempContext);
      // this.exportTransformedImage(tempContext);
  }
  // parent.notify('export', { prop: 'drawAnnotation', value: { context: tempContext, ratio: ratio } });
//  Add Drop Shadow
  const { color, blur, offsetX, offsetY } = getShadowSettings(ratio);
  tempContext.shadowColor = color;
  tempContext.shadowBlur = blur;
  tempContext.shadowOffsetX = offsetX;
  tempContext.shadowOffsetY = offsetY;
  _export.drawAnnotation(tempContext, ratio);
  // this.drawAnnotation(tempContext, ratio);
  if (parent.isCircleCrop) {
      parent.notify('crop', { prop: 'cropCircle', onPropertyChange: false,
          value: { context: tempContext, isSave: true, isFlip: null } });
  }
  // parent.notify('export', { prop: 'updateFrame', value: { tempContext: tempContext, isAnnotation: true } });
  _export.updateFrame(tempContext, true);
  // this.updateFrame(tempContext, true);
  _export.lowerContext.filter = tempContextFilter;
  _export.parent.canvasFilter = tempContextFilter;

  if (object) {
      object['canvas'] = tempCanvas;
  }
  return tempCanvas;
};


export const getShadowSettings = function (ratio) {
  const _ratio = (ratio ? ratio.width : 1);
  return {
    color: "black",
    blur: Math.round(6 * _ratio),
    offsetX: Math.round(2 * _ratio),
    offsetY: Math.round(2 * _ratio),
  }
}
