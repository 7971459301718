import React, { useState, useEffect } from "react";
import { useField } from "formik";
import { TextField, CircularProgress } from "@mui/material";
import { Autocomplete } from "@mui/material";

import { useDebounce } from "../../hooks";

const AutocompleteFormField = ({
  ...props
}) => {
  const [field, meta, helpers] = useField(props);

  return (
    <AutocompleteField
      field={field}
      meta={meta}
      helpers={helpers}
      {...props}
    />
  );
};

export const AutocompleteField = ({
  fullWidth,
  label,
  variant,
  endAdornment,
  labelwidth,
  margin,
  required,
  shrink,
  options,
  loading,
  autoFocus,
  multiple,
  inputProps,
  fetchData,
  helperText,
  field,
  meta,
  helpers,
  onChange,
  setValue,
  ...props
}) => {
  // const [field, meta, helpers] = useField(props);
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 250);
  const [tempValue, setTempValue] = useState(multiple ? [] : null);
  const working = open && loading;

  const errorText = meta && meta.error && meta.touched ? meta.error : "";

  useEffect(() => {
    let promise;
    if (open) {
      promise = fetchData(debouncedQuery);
    }

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [debouncedQuery, fetchData, open]);

  useEffect(() => {
    setTempValue(setValue);
  }, [setValue]);

  const handleInputChange = (e) => {
    if (e) {
      setQuery(e.target.value);
    }
  };

  const clearUsersOptions = () => {
    if(helpers){
      helpers.setTouched(true);
    }
    setOpen(false);
  };

  return (
    <Autocomplete
      autoComplete={false}
      multiple={multiple}
      open={open}
      onChange={(e, value) => {
        if(helpers){
          helpers.setValue(value)
        }
        else{
          setTempValue(value)
        }
        if(onChange){
          onChange(e, value);
        }
      }}
      value={field ? field.value : tempValue}
      onOpen={() => setOpen(true)}
      onClose={clearUsersOptions}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={working}
      disableCloseOnSelect={multiple}
      filterOptions={(x) => x}
      onInputChange={handleInputChange}
      onBlur={field ? field.onBlur : () => {}}
      {...props}
      renderInput={(params) => (
        <TextField
          {...params}
          label={`${label}${required ? " *" : ""}`}
          variant={variant}
          margin={margin}
          error={!!errorText}
          helperText={errorText ? errorText : helperText ? helperText : ""}
          autoFocus={autoFocus}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {working ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          {...inputProps}
        />
      )}
    />
  );
};

export default AutocompleteFormField;
