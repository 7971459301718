import React, { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  DialogContent,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import { GetApp, MoreVert } from "@mui/icons-material";
import {
  RiUserFill,
  RiMoneyDollarCircleFill,
  RiPercentFill,
} from "react-icons/ri";
import { MdDateRange, MdLabel } from "react-icons/md";
import { CgNotes, CgOptions } from "react-icons/cg";

import FileItem from "common/FileItem";
import IconTextItem from "common/IconTextItem";
import { formatMoney } from "utility";
import { IconButtonDropdown } from "common/ButtonDropdown";
import { downloadFile } from "features/Files/filesSlice";

const Receipt = ({ file }) => {
  const dropdownRef = useRef();
  const dispatch = useDispatch();

  const handleDownload = async () => {
    dispatch(
      downloadFile({
        id: file.fid,
        filename: file.filename,
        token: file.file_dl_token,
      })
    );
    if (dropdownRef.current) {
      dropdownRef.current.close();
    }
  };

  return (
    <FileItem
      fid={file.fid}
      subtext="Receipt"
      inline
      popover={
        <IconButtonDropdown
          icon={MoreVert}
          tooltip="Actions"
          size="small"
          ref={dropdownRef}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div style={{ width: 250 }}>
            <Typography
              variant="body1"
              gutterBottom
              style={{ padding: "1.25rem 1.25rem 0 1.25rem" }}
            >
              Actions
            </Typography>
            <MenuItem onClick={handleDownload}>
              <ListItemIcon>
                <GetApp fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">Download</Typography>
            </MenuItem>
          </div>
        </IconButtonDropdown>
      }
    />
  );
};

const Expense = ({ sheet, setTitle }) => {
  useEffect(() => {
    setTitle("Expense");
  }, [setTitle]);

  return (
    <DialogContent>
      <Typography variant="h6" style={{ marginBottom: "2rem" }}>
        {`Total: ${formatMoney(sheet.field_ds_total)}`}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xxs={6}>
          <IconTextItem icon={<RiUserFill />}>
            <Typography
              variant="subtitle2"
              style={{ textTransform: "uppercase" }}
              color="textSecondary"
            >
              User
            </Typography>
            {sheet.field_ds_user.map((user) => (
              <Typography>{`${user.first} ${user.last}`}</Typography>
            ))}
          </IconTextItem>
        </Grid>
        <Grid item xxs={6}>
          <IconTextItem icon={<MdDateRange />}>
            <Typography
              variant="subtitle2"
              style={{ textTransform: "uppercase" }}
              color="textSecondary"
            >
              Date
            </Typography>
            <Typography>{sheet.field_ds_date}</Typography>
          </IconTextItem>
        </Grid>
        <Grid item xxs={6}>
          <IconTextItem icon={<RiMoneyDollarCircleFill />}>
            <Typography
              variant="subtitle2"
              style={{ textTransform: "uppercase" }}
              color="textSecondary"
            >
              Expense Cost
            </Typography>
            <Typography>{formatMoney(sheet.field_ds_expense_cost)}</Typography>
          </IconTextItem>
        </Grid>
        <Grid item xxs={6}>
          <IconTextItem icon={<RiPercentFill />}>
            <Typography
              variant="subtitle2"
              style={{ textTransform: "uppercase" }}
              color="textSecondary"
            >
              Percent Markup
            </Typography>
            <Typography>
              {sheet.field_ds_percent_markup
                ? sheet.field_ds_percent_markup
                : "No Markup"}
            </Typography>
          </IconTextItem>
        </Grid>
        <Grid item xxs={12}>
          <IconTextItem icon={<MdLabel />}>
            <Typography
              variant="subtitle2"
              style={{ textTransform: "uppercase" }}
              color="textSecondary"
            >
              Expense Type
            </Typography>
            <Typography>{sheet.field_ds_expense_type_text}</Typography>
          </IconTextItem>
        </Grid>
        <Grid item xxs={12}>
          <IconTextItem icon={<CgOptions />}>
            <Typography
              variant="subtitle2"
              style={{ textTransform: "uppercase" }}
              color="textSecondary"
            >
              Options
            </Typography>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sheet.field_ds_exclude_invoice}
                    color="secondary"
                  />
                }
                label="Exclude from invoice"
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sheet.field_ds_reimburse}
                    color="secondary"
                  />
                }
                label="Reimburse"
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sheet.field_ds_show_descript_invoice}
                    color="secondary"
                  />
                }
                label="Show description on invoice"
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sheet.field_ds_taxable}
                    color="secondary"
                  />
                }
                label="Taxable"
              />
            </div>
          </IconTextItem>
        </Grid>
        <Grid item xxs={12}>
          <IconTextItem icon={<CgNotes />}>
            <Typography
              variant="subtitle2"
              style={{ textTransform: "uppercase" }}
              color="textSecondary"
            >
              Description
            </Typography>
            <Typography>{sheet.field_ds_description}</Typography>
          </IconTextItem>
        </Grid>
        {sheet.field_ds_receipt?.map((file) => (
          <Grid item xxs={12}>
            <Receipt file={file} />
          </Grid>
        ))}
      </Grid>
    </DialogContent>
  );
};

Expense.propTypes = {};

export default Expense;
