export const formatUserForTask = (users) => {
  const data = [];
  if (!Array.isArray(users)) return data;
  for (var i = 0; i < users.length; i += 1) {
    const userName =
      users[i].first && users[i].last
        ? `${users[i].first} ${users[i].last}`
        : users[i].name
        ? users[i].name
        : "NA";

    data.push({ name: userName, uid: users[i].uid });
  }

  return data;
};

export const getReminderValues = (reminder) => {
  if (reminder) {
    const values = reminder.split(" ");

    return {
      time: values[0],
      type: values[1],
    };
  } else {
    return {
      time: "1",
      type: "hours",
    };
  }
};

export const reminderHours = () => {
  let hours = [];

  for (let i = 0; i < 10; i += 1) {
    hours = [
      ...hours,
      { value: `${i}.25`, label: `${i}.25` },
      { value: `${i}.5`, label: `${i}.50` },
      { value: `${i}.75`, label: `${i}.75` },
      { value: `${i + 1}`, label: `${i + 1}.00` },
    ];
  }

  return hours;
};

export const repeats = ["YEARLY", "MONTHLY", "WEEKLY", "DAILY"];
