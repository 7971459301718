import React, {
  useMemo,
  useEffect,
  useState,
  useCallback,
  useRef,
  memo,
} from "react";
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  CodeButton,
} from "@draft-js-plugins/buttons";
import createToolbarPlugin from "@draft-js-plugins/static-toolbar";
import Editor from "@draft-js-plugins/editor";
import createMentionPlugin from "@draft-js-plugins/mention";
import createEmojiPlugin from "@draft-js-plugins/emoji";
import { EditorState, ContentState, AtomicBlockUtils } from "draft-js";
import { toArray } from "lodash";
import {
  Typography,
  Portal,
  Popover,
  TextField,
  Fade,
} from "@mui/material";
import htmlToDraft from "html-to-draftjs";
import createImagePlugin from "@draft-js-plugins/image";
import { getLocalStorage } from "utility";
// import createVideoPlugin from "@draft-js-plugins/video";
import styled from "styled-components";
import config from "config";

import "@draft-js-plugins/static-toolbar/lib/plugin.css";
import "@draft-js-plugins/mention/lib/plugin.css";
import "@draft-js-plugins/emoji/lib/plugin.css";
import "@draft-js-plugins/image/lib/plugin.css";

import LinkifyPlugin from "./plugins/LinkifyPlugin";
import createLinkPlugin from "./plugins/LinkPlugin";
import AttachButton from "./AttachButton";
import SendButton from "./SendButton";
// import EditButton from "./EditButton";
import ImageButton from "./ImageButton";
import PlaceholderFile from "./PlaceholderFile";
import { useDebounce } from "../../hooks";
import { ChatBox, ImageUploads, Disabler } from "./Styled";
import Button from "common/Button";
import { TextEditorUploader } from "../Uploader";

import "draft-js/dist/Draft.css";
import editorStyles from "./css/editor.module.css";
import buttonStyles from "./css/button.module.css";
import toolbarStyles from "./css/toolbar.module.css";
import { useSelector } from "react-redux";
import { PublicCommenterUpdate } from "common/Comment";
import ReCAPTCHA from "react-google-recaptcha";
import { mediaDown } from "components/mixins";

const RecaptchaWrapper = styled(ReCAPTCHA)`

/* Move reCAPTCHA v3 badge to the left */

  .grecaptcha-badge {
    width: 70px !important;
    overflow: hidden !important;
    transition: all 0.3s ease !important;
    left: 4px !important;

    ${mediaDown.sm`
      display: none !important;
    `}
  }

  .grecaptcha-badge:hover {
    width: 256px !important;
  }
`;

const ImageUploadArea = memo(
  ({ uploadedFileAmount, fileProgress, removeFile }) => {
    return uploadedFileAmount > 0 ? (
      <ImageUploads>
        {toArray(fileProgress).map((file) => (
          <PlaceholderFile data={file} removeFile={removeFile} />
        ))}
      </ImageUploads>
    ) : null;
  }
);

const positionSuggestions = ({ props, decoratorRect }) => {
  let transform;
  let transition;
  let opacity;

  if (props.open && props.suggestions.length > 0) {
    transform = "scaleY(1) translateY(-100%)";
    transition = "opacity 0.125s cubic-bezier(.3,1.2,.2,1)";
    opacity = 1;
  } else if (props.open) {
    transform = "scaleY(0)";
    transition = "opacity 0.125s cubic-bezier(.3,1,.2,1)";
    opacity = 0;
  }

  return {
    transform,
    transition,
    opacity,
    left: decoratorRect.left + "px",
    top: decoratorRect.top - 40 + "px",
    transformOrigin: "bottom",
  };
};

const TextEditor = ({
  handleCreateComment,
  isSubmitting,
  uploadedFileAmount,
  fileProgress,
  removeFile,
  openFile,
  loadUsers,
  availableUsers=[],
  nid,
  hash,
  placeholder,
  currentContent,
  editing,
  cancelEditing,
  disableRoundedCorners,
  disableBorder,
  editorState,
  setEditorState,
  disableTools=false,
  variant="outlined",
  actions,
  defaultMentionOptions=[],
  simpleToolbar,
  refreshComments,
}) => {
  const selfRef = useRef(null);
  const uploaderRef = useRef(null);
  const recaptchaRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [addLinkOpen, setAddLinkOpen] = useState(false);
  const [imageAchorEl, setImageAnchorEl] = useState(null);
  const [userQuery, setUserQuery] = useState("");
  const debouncedUser = useDebounce(userQuery, 250);
  const [mentions, setMentions] = useState([]);
  const [suggestions, setSuggestions] = useState(defaultMentionOptions);
  const [imageSrc, setImageSrc] = useState("");
  const [uploadingImages, setUploadingImages] = useState(false);
  const [files, setFiles] = useState([]);
  const [showWarning, setShowWarning] = useState(false);
  const browser = useSelector((state) => state.browser);
  const [commenterDialog, setCommenterDialog] = useState(false);
  const [submitOnCommenterSet, setSubmitOnCommenterSet] = useState(false);

  useEffect(() => {
    if (currentContent) {
      const blocksFromHtml = htmlToDraft(currentContent);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [currentContent, setEditorState]);

  useEffect(() => {
    if (debouncedUser && open) {
      loadUsers(nid, {
        keywords: debouncedUser,
        rows_per_page: 5,
      });
    } else if (open) {
      loadUsers(nid, { rows_per_page: 5 });
    }
  }, [debouncedUser, loadUsers, nid, open]);

  useEffect(() => {
    setSuggestions([]);
    const users = availableUsers.map((user) => ({
      name: `${user.first_name} ${user.last_name}`,
      link: user.userPath,
      avatar: user.profile_pic_url,
      uid: user.uid,
    }));

    setSuggestions([...users, ...defaultMentionOptions]);
  }, [availableUsers, defaultMentionOptions]);

  useEffect(() => {
    let timer;
    if (showWarning) {
      timer = setTimeout(() => {
        setShowWarning(false);
      }, 3000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [showWarning]);

  const {
    EmojiSuggestions,
    Toolbar,
    MentionSuggestions,
    LinkSelect,
    LinkForm,
    plugins,
  } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      supportWhitespace: true,
      mentionComponent(mentionProps) {
        return (
          <span className={mentionProps.className}>
            @{mentionProps.children}
          </span>
        );
      },
      positionSuggestions,
    });
    const imagePlugin = createImagePlugin({
      imageComponent: ({ block, ...rest }) => {
        const { src } = rest.contentState
          .getEntity(block.getEntityAt(0))
          .getData();
        return <img src={src} alt={src} height="auto" width="100px" />;
      },
    });
    // const videoPlugin = createVideoPlugin();
    const toolbarPlugin = createToolbarPlugin({
      theme: {
        buttonStyles,
        toolbarStyles,
      },
    });
    const emojiPlugin = createEmojiPlugin({
      positionSuggestions: (settings) => {
        return {
          left: settings.decoratorRect.left + "px",
          top: settings.decoratorRect.top - 40 + "px", // change this value (40) for manage the distance between cursor and bottom edge of popover
          display: "block",
          transform: "scale(1) translateY(-100%)", // transition popover on the value of its height
          transformOrigin: "bottom",
          transition: "opacity 0.25s cubic-bezier(0.3, 1.2, 0.2, 1)",
          opacity: 1,
        };
      },
    });

    const LinkPlugin = createLinkPlugin({
      formOpenFunction: setAddLinkOpen,
    });

    const { EmojiSuggestions } = emojiPlugin;
    const { Toolbar } = toolbarPlugin;
    const { MentionSuggestions } = mentionPlugin;
    const { LinkSelect, LinkForm } = LinkPlugin;

    const plugins = [
      mentionPlugin,
      toolbarPlugin,
      emojiPlugin,
      imagePlugin,
      // videoPlugin,
      LinkPlugin,
      LinkifyPlugin,
    ];
    return {
      plugins,
      LinkForm,
      LinkSelect,
      MentionSuggestions,
      Toolbar,
      EmojiSuggestions,
    };
  }, []);

  const onOpenChange = useCallback(
    (_open) => {
      setSuggestions(defaultMentionOptions);
      setOpen(_open);
    },
    [defaultMentionOptions]
  );

  const onSearchChange = useCallback(({ value }) => {
    setUserQuery(value);
  }, []);

  const handleEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const handleToggleImageClosed = () => {
    setImageSrc("");
    setImageAnchorEl(null);
  };

  const insertImage = (extraData, url) => {
    const urlType = "IMAGE";
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      urlType,
      "IMMUTABLE",
      { ...extraData, src: url }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
      editorState,
      entityKey,
      " "
    );

    setEditorState(
      EditorState.forceSelection(
        newEditorState,
        newEditorState.getCurrentContent().getSelectionAfter()
      )
    );
  };

  const handleSubmitImage = () => {
    insertImage(
      {
        alt: imageSrc,
        height: "auto",
        width: "300px",
      },
      imageSrc
    );
  };

  const handleResetUploads = () => {
    if (uploaderRef.current) {
      uploaderRef.current.reset();
    }
  };

  const handleSubmit = async () => {
    const commenter = getLocalStorage('public_commenter');
    if(hash && !commenter?.email){
      setSubmitOnCommenterSet(true);
      setCommenterDialog(true);
    }
    else{
      if (!uploadingImages) {
        let recaptchaToken = null;
        if(hash){
          recaptchaToken = await recaptchaRef.current.executeAsync();
        }
        await handleCreateComment(files, handleResetUploads, recaptchaToken);
        recaptchaRef.current.reset();
      } else {
        setShowWarning(true);
      }
    }
  };

  const handleKeyCommand = (command) => {
    if (command === "myeditor-save" && !open && handleCreateComment) {
      handleSubmit();
      return "handled";
    }
    return "not-handled";
  };

  const handleMentionAdd = (data) => {
    setMentions([...mentions, data.uid]);
  };

  const handleUploadStart = () => {
    setUploadingImages(true);
  };

  const handleUploadSuccess = (fid) => {
    setFiles((files) => [...files, { target_id: fid }]);
  };

  const handleUploadComplete = () => {
    setUploadingImages(false);
  };

  const handlePublicCommenterSubmit = () => {
    // refreshComments();
    setCommenterDialog(false);
    if(submitOnCommenterSet){
      setSubmitOnCommenterSet(false);
      handleSubmit();
    }
  };

  // const handlePublicCommenterClose = () => {
  //   setCommenterDialog(false);
  // };

  return (
    <>
      <div style={{ position: "relative" }}>
        <Fade in={showWarning}>
          <div
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              bottom: 0,
              top: 0,
              background: "rgba(255,255,255,0.5)",
              zIndex: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 3,
              padding: "1rem",
            }}
          >
            <Typography style={{ maxWidth: 400 }} align="center">
              File uploads still processing. Wait until complete before sending
              message.
            </Typography>
          </div>
        </Fade>
        <ChatBox
          // onClick={focus}
          focused={false}
          theme={{
            borderRadius: disableRoundedCorners ? 0 : "3px",
            disableBorder,
          }}
          variant={variant}
        >
          <div className={editorStyles.editor}>
            <Editor
              editorKey={"editor"}
              editorState={editorState}
              onChange={handleEditorStateChange}
              plugins={plugins}
              ref={selfRef}
              placeholder={placeholder}
              readOnly={isSubmitting}
              handleKeyCommand={handleKeyCommand}
              spellCheck={true}
            />
          </div>
          <TextEditorUploader
            entity="comment"
            bundle="job_division"
            field="field_comment_images"
            onUploadSuccess={handleUploadSuccess}
            onUploadStart={handleUploadStart}
            onUploadFail={() => {}}
            onRemove={() => {}}
            onComplete={handleUploadComplete}
            ref={uploaderRef}
          />
          <ImageUploadArea
            uploadedFileAmount={uploadedFileAmount}
            fileProgress={fileProgress}
            removeFile={removeFile}
          />
          {/* {!disableTools && ( */}
            <Toolbar className="relative">
              {(externalProps) => (
                <>
                  <BoldButton {...externalProps} />
                  <ItalicButton {...externalProps} />
                  <UnderlineButton {...externalProps} />
                  {!simpleToolbar &&
                    <CodeButton {...externalProps} />
                  }
                  <LinkSelect
                    onClick={(e) => {
                      e.preventDefault();
                      setAddLinkOpen(true);
                    }}
                  />
                  {!simpleToolbar &&
                    <ImageButton
                      handleClick={(e) => setImageAnchorEl(e.currentTarget)}
                      {...externalProps}
                    />
                  }
                  {!editing && Boolean(openFile) && (
                    <AttachButton handleClick={openFile} {...externalProps} />
                  )}
                  <div style={{ flex: 1 }}></div>
                  {actions}
                  {/* {editing && (
                    <EditButton
                      handleClick={cancelEditing}
                      {...externalProps}
                    />
                  )} */}

                  {hash && (
                    <PublicCommenterUpdate
                      open={commenterDialog}
                      setOpen={setCommenterDialog}
                      onSubmit={handlePublicCommenterSubmit}
                      // onClose={handlePublicCommenterClose}
                      {...externalProps}
                    />
                  )}
                  {Boolean(handleCreateComment) && (
                    <SendButton handleClick={handleSubmit} {...externalProps} />
                  )}
                  {disableTools && (
                    <Disabler></Disabler>
                  )}
                </>
              )}
            </Toolbar>
          {/* )} */}
          <Portal>
            <EmojiSuggestions />
          </Portal>
          {Boolean(loadUsers) && (
            <Portal>
              <MentionSuggestions
                open={open}
                onOpenChange={onOpenChange}
                suggestions={suggestions}
                onSearchChange={onSearchChange}
                onAddMention={handleMentionAdd}
              />
            </Portal>
          )}
        </ChatBox>
        <LinkForm open={addLinkOpen} onClose={() => setAddLinkOpen(false)} />
        <Popover
          open={Boolean(imageAchorEl)}
          anchorEl={imageAchorEl}
          onClose={handleToggleImageClosed}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          keepMounted
          style={{
            pointerEvents: Boolean(imageAchorEl) ? "all" : "none",
          }}
        >
          <div style={{ padding: "1rem", width: "250px", display: "flex" }}>
            <TextField
              label="URL"
              variant="outlined"
              fullWidth
              size="small"
              placeholder="https://source.unsplash.com/random"
              value={imageSrc}
              onChange={(e) => setImageSrc(e.target.value)}
            />
            <Button
              style={{ marginLeft: "1rem" }}
              variant="contained"
              disableElevation
              size="small"
              onClick={handleSubmitImage}
            >
              Add
            </Button>
          </div>
        </Popover>
        {!editing && !disableTools && !browser.is.extraSmall && (
          <div
            style={{ position: "absolute", right: "0.3rem", bottom: "-1rem" }}
          >
            <Typography variant="caption" color="textSecondary">
              Return to send &nbsp; &nbsp; Shift + Return to add new line
            </Typography>
          </div>
        )}
        {hash &&
          <RecaptchaWrapper
            ref={recaptchaRef}
            size="invisible"
            sitekey={config.recaptcha_invisible_site_key}
          />
        }
      </div>
    </>
  );
};

TextEditor.propTypes = {};

export default TextEditor;
