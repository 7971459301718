import React from "react";
import { Button, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import styled from "styled-components";
import constants from "../constants";

const Container = styled.div`
  display: flex;
  padding: 3rem 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${constants.colorGrayLightest};
  border: 3px dashed ${constants.colorGrayLight};
  border-radius: ${constants.borderRadius};
`;

const FileAddMessage = ({ openFile }) => {
  return (
    <Container>
      <Typography
        variant="body1"
        color="textSecondary"
        align="center"
        style={{ maxWidth: "200px" }}
      >
        Drag n' Drop files here or click the button below to select
      </Typography>
      <Button
        variant="contained"
        disableElevation
        style={{ margin: "1rem 0" }}
        startIcon={<CloudUploadIcon />}
        component="a"
        onClick={openFile}>
        Select File
      </Button>
    </Container>
  );
};

FileAddMessage.propTypes = {};

export default FileAddMessage;
