import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  FormGroup,
  FormControlLabel,
  Grid,
  MenuItem,
  ListItemIcon,
  Typography,
  InputLabel,
  FormControl,
  Select,
  Checkbox,
  Divider,
  CircularProgress,
} from "@mui/material";
import { Launch, GetApp, Delete, MoreVert } from "@mui/icons-material";
import { size } from "lodash";

import { useDebounce } from "hooks";
import Alert from "common/Alert";
import { IconButtonDropdown } from "common/ButtonDropdown";
import ConfirmationDialog from "components/ConfirmationDialog";
import { FileSkeleton } from "components/Skeletons";
import FileItem from "common/FileItem";
import { FilterMenu, SelectColumnFilter } from "common/Table";
import config from "config";
// import AddDocument from "../forms/AddDocument";

import {
  deleteCustomerFile,
  getFilesErrorSelector,
  downloadFile,
  patchCustomerFile,
  customerFilesSelectors,
  getCustomerFilesLoadingSelector,
  getCustomerFilesPaginationSelector,
  fetchCustomerFiles,
  fetchMoreCustomerFiles,
} from "features/Files/filesSlice";
import { customerFileCategoriesSelectors } from "features/Taxonomies/taxonomiesSlice";
import { getCustomerDataSelector } from "../customerSlice";
import AddDocument from "../forms/AddDocument";
import Button from "common/Button";
import { CgFileAdd } from "react-icons/cg";

const celebrateIcon = `${process.env.PUBLIC_URL}/images/icons/icon-celebrate.svg`;

const getLabelById = (id, entities, query, field) => {
  const entity = entities.find((g) => g[query] === id);

  return entity?.[field];
};

const CustomerFile = ({ id, readOnly }) => {
  const dropdownRef = useRef();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.token);
  const categories = useSelector(customerFileCategoriesSelectors.selectAll);
  const file = useSelector((state) =>
    customerFilesSelectors.selectById(state, id)
  );
  const customer = useSelector(getCustomerDataSelector);

  const [openDelete, setOpenDelete] = useState(false);
  const [categoryTid, setCategoryTid] = useState(file?.field_cd_category.tid);
  const [visible, setVisible] = useState({
    phx_client: file.field_visible_to.includes("phx_client"),
    customer: file.field_visible_to.includes("customer"),
    member: file.field_visible_to.includes("member"),
    mby_agent: file.field_visible_to.includes("mby_agent"),
  });
  const roleType = useSelector((state) => state.auth.user.data.roleType);

  const toggleDelete = () => {
    if (dropdownRef.current) {
      dropdownRef.current.close();
    }
    setOpenDelete(true);
  };

  const handleDownload = async () => {
    dispatch(
      downloadFile({
        id: file.field_file.fid,
        filename: file.field_file.filename,
        token: file.field_file.file_dl_token,
      })
    );
    if (dropdownRef.current) {
      dropdownRef.current.close();
    }
  };

  const handleDelete = async () => {
    dispatch(deleteCustomerFile({ id, file }));
    setOpenDelete(false);
  };

  const handleChange = (event) => {
    setVisible({ ...visible, [event.target.name]: event.target.checked });
    dispatch(
      patchCustomerFile({
        id,
        params: {
          ...visible,
          [event.target.name]: event.target.checked,
          field_cd_category: categoryTid,
          field_cd_category_name: getLabelById(
            categoryTid,
            categories,
            "tid",
            "name"
          ),
        },
      })
    );
  };

  const handleChangeCategory = (e) => {
    setCategoryTid(e.target.value);
    dispatch(
      patchCustomerFile({
        id,
        params: {
          ...visible,
          field_cd_category: e.target.value,
          field_cd_category_name: getLabelById(
            e.target.value,
            categories,
            "tid",
            "name"
          ),
        },
      })
    );
  };

  const handleOpenExternal = () => {
    const win = window.open(
      `${config.api_url}/rest/file/${file.field_file.fid}/view?token=${file.field_file.file_dl_token}&oauth_token=${auth}`,
      "_blank"
    );
    if (win != null) {
      win.focus();
    }
  };

  if (!file.field_file) return null;

  return (
    <Grid item key={id} xxs={12} sm={6} md={4} lg={3} xl={2}>
      <FileItem
        fid={file.field_file.fid}
        subtext={file.field_cd_category.name}
        created={file.file_created}
        popover={
          <IconButtonDropdown
            icon={MoreVert}
            tooltip="Actions"
            size="small"
            ref={dropdownRef}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div style={{ width: 250 }}>
              <Typography
                variant="body1"
                gutterBottom
                style={{ padding: "1.25rem 1.25rem 0 1.25rem" }}
              >
                Actions
              </Typography>
              {!readOnly && (
                <div style={{ padding: "0 1.25rem 1rem 1.25rem" }}>
                  <FormControl
                    variant="filled"
                    size="small"
                    fullWidth
                    margin="normal"
                  >
                    <InputLabel id="category-select">Category</InputLabel>
                    <Select
                      labelId="category-select"
                      id="category-select-field"
                      value={categoryTid}
                      onChange={handleChangeCategory}
                      label="Category"
                    >
                      {categories.map((category) => (
                        <MenuItem value={category.tid}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormGroup column>
                    {roleType === 'phx_client' && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={visible.phx_client}
                            onChange={handleChange}
                            name="phx_client"
                          />
                        }
                        label="Admin"
                      />
                    )}
                    {(roleType === 'phx_client' || roleType === 'member') && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={visible.member}
                            onChange={handleChange}
                            name="member"
                          />
                        }
                        label="Member"
                      />
                    )}
                    {customer?.field_mby_agents && customer.field_mby_agents.length > 0 && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={visible.member}
                            onChange={handleChange}
                            name="mby_agent"
                          />
                        }
                        label="Agent"
                      />
                    )}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={visible.customer}
                          onChange={handleChange}
                          name="customer"
                        />
                      }
                      label="Customer"
                    />
                  </FormGroup>
                </div>
              )}
              <Divider />
              <MenuItem onClick={handleOpenExternal}>
                <ListItemIcon>
                  <Launch />
                </ListItemIcon>
                <Typography variant="inherit">View</Typography>
              </MenuItem>
              <MenuItem onClick={handleDownload}>
                <ListItemIcon>
                  <GetApp fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Download</Typography>
              </MenuItem>
              {!readOnly && (
                <MenuItem onClick={toggleDelete}>
                  <ListItemIcon>
                    <Delete fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">Remove</Typography>
                </MenuItem>
              )}
            </div>
          </IconButtonDropdown>
        }
      />
      <ConfirmationDialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        onSubmit={handleDelete}
        title="Are you sure?"
        body="Are you sure you want to delete this item?"
        isSubmitting={false}
        submitButton="Delete"
      />
    </Grid>
  );
};

const formatFilterArray = (arr, field) => {
  const filters = {};
  arr.forEach((filter, i) => {
    filters[`filter[${field}][${i}]`] = filter.value;
  });

  return filters;
};

const Documents = ({ nid, readOnly, embedded }) => {
  const dispatch = useDispatch();
  const fileIds = useSelector(customerFilesSelectors.selectIds);
  const loading = useSelector(getCustomerFilesLoadingSelector);
  const error = useSelector(getFilesErrorSelector);
  const categories = useSelector(customerFileCategoriesSelectors.selectAll);
  const pagination = useSelector(getCustomerFilesPaginationSelector);
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 250);

  useEffect(() => {
    let promise;
    if (nid) {
      setPage(0);
      const params = {
        keywords: debouncedQuery,
        page: 0,
        ...formatFilterArray(categoryFilters, "category_tid"),
      };

      promise = dispatch(
        fetchCustomerFiles({
          id: nid,
          params,
        })
      );
    }

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [dispatch, nid, debouncedQuery, categoryFilters]);

  const handleLoadMore = async () => {
    const params = {
      keywords: debouncedQuery,
      page: page + 1,
    };

    await dispatch(
      fetchMoreCustomerFiles({
        id: nid,
        params,
      })
    );
    setPage(page + 1);
  };

  return (
    <>
      {error && (
        <Alert
          kind="negative"
          margin="none"
          disableElevation
          disableRoundedCorners
        >
          {error}
        </Alert>
      )}
      <FilterMenu
        search={{
          query,
          setQuery,
        }}
        filters={[
          {
            component: <SelectColumnFilter />,
            props: {
              filterValue: categoryFilters,
              setFilter: setCategoryFilters,
              label: "Category",
              id: "file-categories",
              filterOptions: categories.map((obj) => ({
                label: obj.name,
                value: obj.tid,
              })),
              multiple: true,
            },
          },
        ]}
        embedded
      />
      <div className="px-4 text-right">
        <AddDocument >
          <Button
            color="tertiary"
            size="small"
            disableElevation
            startIcon={<CgFileAdd />}
          >
            Upload
          </Button>
        </AddDocument>
      </div>
      <div
        style={{
          flex: 1,
          overflow: "auto",
        }}
        id="scrollableDiv"
      >
        {loading ? (
          <div style={{ padding: "1.25rem" }}>
            <Grid container spacing={2}>
              {[0, 1, 2, 3, 4, 5, 6, 7].map((i) => (
                <Grid key={i} item xxs={12} sm={6} md={4} lg={3} xl={2}>
                  <FileSkeleton />
                </Grid>
              ))}
            </Grid>
          </div>
        ) : (
          <InfiniteScroll
            dataLength={size(fileIds)}
            next={handleLoadMore}
            hasMore={page + 1 < pagination.total_pages}
            loader={
              <div
                style={{
                  padding: "1rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress size={24} />
              </div>
            }
            endMessage={
              <div
                style={{
                  padding: "1rem",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  color: "var(--color-gray)",
                }}
              >
                <img src={celebrateIcon} alt="finished" />
                <p>You've made it to the end of the list</p>
              </div>
            }
            scrollableTarget="scrollableDiv"
          >
            <div style={{ padding: "1.25rem" }}>
              <Grid container spacing={2}>
                {fileIds.map((id) => (
                  <CustomerFile id={id} readOnly={readOnly} />
                ))}
              </Grid>
            </div>
          </InfiniteScroll>
        )}
      </div>
    </>
  );
};

Documents.propTypes = {};

export default Documents;
