
import React, { } from "react";
import moment from "moment-timezone";
import { size } from "lodash";
import { BsFileText } from "react-icons/bs";


const TimeClockLog = ({
  timeClock
}) => {

  if(!timeClock?.service_log || !size(timeClock?.service_log)){
    return;
  }

  return (
    <div className="service-log">
      <div className="icon"><BsFileText className="edit-icon medium" /></div>
      <div className="list">
      {timeClock.service_log.map((logEntry, i) =>
        <div className="log-entry" key={`log-${i}`}>
          <div className="datetime">{moment(logEntry.datetime).format('MM/DD/YY hh:mm a')}</div>
          <div className="message">{logEntry.message.map((line, j) => <div key={`log-${i}-line-${j}`}>{line}</div>)}</div>
          {(logEntry?.user_name || logEntry?.location) &&
            <div className="user">
            {`(`}
              {logEntry?.user_name ? logEntry.user_name : ''}
              {logEntry?.location && (
                <>
                {logEntry?.user_name && ` - `}
                {typeof logEntry.location.location === "string" ? (
                  logEntry.location.location
                ) : (
                  logEntry.location.location.locality
                )}
                </>
              )}
            {`)`}
            </div>
          }
          </div>
      )}
      </div>
    </div>
  );
};

TimeClockLog.propTypes = {};

export default TimeClockLog;
