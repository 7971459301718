import React from "react";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { BsInfoCircle, BsCheckCircle } from "react-icons/bs";
import { AiOutlineWarning } from "react-icons/ai";
import { BiErrorAlt } from "react-icons/bi";

import { Wrapper, Icon, Message } from "./Styled";

const icon = {
  info: <BsInfoCircle />,
  positive: <BsCheckCircle />,
  warning: <AiOutlineWarning />,
  negative: <BiErrorAlt />,
};

const color = {
  info: {
    bkg: "rgb(232, 244, 253)",
    font: "rgb(13, 60, 97)",
    icon: "var(--color-blue)",
  },
  positive: {
    bkg: "rgb(237, 247, 237)",
    font: "rgb(30, 70, 32)",
    icon: "var(--color-green)",
  },
  warning: {
    bkg: "rgb(255, 244, 229)",
    font: "rgb(102, 60, 0)",
    icon: "var(--color-orange)",
  },
  negative: {
    bkg: "rgb(253, 236, 234)",
    font: "rgb(97, 26, 21)",
    icon: "var(--color-red)",
  },
};

const Alert = ({
  children,
  kind="default",
  margin="default",
  onClose,
  disableElevation=true,
  disableRoundedCorners,
  ...rest
}) => {
  return (
    <Wrapper
      theme={{
        color: color[kind],
        margin:
          margin === "normal"
            ? "1rem 1.25rem"
            : margin === "none"
            ? "0"
            : "1rem 0",
        disableElevation,
        disableRoundedCorners,
      }}
      {...rest}
    >
      {kind !== "default" && (
        <Icon
          theme={{
            color: color[kind],
          }}
        >
          {icon[kind]}
        </Icon>
      )}
      {typeof children === "string" ? (
        <Message dangerouslySetInnerHTML={{ __html: children }}></Message>
      ) : (
        <Message>{children}</Message>
      )}
      {onClose && (
        <IconButton
          size="small"
          onClick={onClose}
          style={{ marginLeft: "1.25rem", color: color[kind]?.font }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Wrapper>
  );
};

const { oneOf, bool } = PropTypes;
Alert.propTypes = {
  margin: oneOf(["normal", "default", "none"]),
  kind: oneOf(["default", "info", "positive", "negative", "warning"]),
  disableElevation: bool,
};

export default Alert;
