import styled from "styled-components";

export const Card = styled.div`
  margin-left: 1rem;
  background: white;
  padding: ${({ shouldCollapse }) => (shouldCollapse ? "0.5rem" : "1rem")};
  border-radius: 3px;
  display: flex;
  flex-direction: ${({ shouldCollapse }) =>
    shouldCollapse ? "row" : "column"};
  align-items: center;
  &:last-child {
    margin-right: 0;
  }
`;

export const Label = styled.div`
  font-size: ${({ shouldCollapse }) => (shouldCollapse ? "1rem" : "1rem")};
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  text-transform: uppercase;
  text-align: center;
  display: flex;
  align-items: center;
`;

export const Value = styled.div`
  font-size: ${({ shouldCollapse }) =>
    shouldCollapse ? "1.25rem" : "1.25rem"};
  font-weight: 600;
  color: var(--color-blue);
  text-align: center;
  margin-right: ${({ shouldCollapse }) => (shouldCollapse ? "0.5rem" : "0")};
`;
