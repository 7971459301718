import React from "react";
import { useSelector } from "react-redux";
import { first, orderBy, size } from "lodash";
import { checkMatchingClientAssignedValues, parseClientAssignedField } from "components/ClientAssignedField/utils";
import { clientsSelectors } from "features/Clients/clientsSlice";


const ClientAssignedFieldDisplay = ({fieldData, entity, field, emptyMessage,/*values,*/ clientDisplay, display}) => {
  const clients = useSelector(clientsSelectors.selectAll);
  let clientItems = {};
  if(!fieldData){
    clientItems = parseClientAssignedField(entity, field, clients, null, true);
  }
  else{
    clientItems = parseClientAssignedField(null, null, clients, fieldData, true);
  }
  if(size(clientItems) === 0) return null;
  const isDiffClientItems = checkMatchingClientAssignedValues(clientItems);

  const entries = Object.entries(clientItems);
  const clientEntries = (entries.length === 1 || !isDiffClientItems) ? [first(entries)] : entries;

  return (
    size(clientEntries) > 0 && (
      clientEntries.map((_entry) => {
        const [nid, entry] = _entry;
        return (
          <React.Fragment key={`client-assigned-${field}-${nid}`}>
            {clientEntries.length > 1 && (clientDisplay(entry.client.title))}
            {entry.items.length > 0 ?
              orderBy(entry.items, "entity_title", "asc").map((item) => display(item, isDiffClientItems))
              : emptyMessage
            }
          </React.Fragment>
        );
      })
    )
  );
};

ClientAssignedFieldDisplay.propTypes = {};

export default ClientAssignedFieldDisplay;
