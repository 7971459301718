import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { AiOutlineUserAdd } from "react-icons/ai";

import { DialogForm } from "common/Dialog";
import { getDivisionDataSelector, patchSetPaymentReceivedStatus } from "../jobSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { jobDivisionsStatusSelectors } from "features/Taxonomies/taxonomiesSlice";

const PaymentReceived = ({ children, editable }) => {
  const dispatch = useDispatch();
  const division = useSelector(getDivisionDataSelector);
  const statuses = useSelector(jobDivisionsStatusSelectors.selectAll);
  const progress = useSelector((state) => state.jobProgress.data);
  const [processing, setProcessing] = useState(false);
  const [open, setOpen] = useState(false);

  if(!editable) return children;

  const handleSubmit = async (
    data,
    setSubmitting,
    setMessage,
    successCallback,
    errorCallback
  ) => {
    const status = statuses.find((status) => status.name === "Complete");
    const params = {
      job_division_nid: division.nid,
    };
    const direction = (!progress.paid_timestamp ? 'set-paid' : 'set-unpaid');

    try {
      setProcessing(true);
      const resultAction = await dispatch(
        patchSetPaymentReceivedStatus({
          params,
          division,
          direction,
          status: { ...status, machine_name: "complete" },
        })
      );
      setProcessing(false);
      unwrapResult(resultAction);
      handleClose();
    } catch (err) {
      handleClose();
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const title = (processing ? `Processing...` : (!progress.paid_timestamp ? `Mark as Paid` : `Mark as Unpaid`));
  return (
    <>
      {children ? (
        React.cloneElement(children, {
          onClick: handleOpen,
        })
      ) : (
        <ListItem button onClick={handleOpen} style={{ paddingLeft: "2rem" }}>
          <ListItemIcon>
            <AiOutlineUserAdd />
          </ListItemIcon>
          <ListItemText primary="Payment Recieved" />
        </ListItem>
      )}
      <DialogForm
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        title={title}
        onSubmit={handleSubmit}
        initialValues={{}}
        overrideDirty
      >
        {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
          <div style={{ padding: "1.25rem 0" }}>
            <Typography>
              {processing ? (
                `Processing...`
              ) : (
                !progress.paid_timestamp ? (
                  `By clicking the button below you confirm that payment has been recieved.`
                ): (
                  <span>By clicking the button below you confirm that payment has <strong>not</strong> been recieved.</span>
                )
              )}
            </Typography>
          </div>
        )}
      </DialogForm>
    </>
  );
};

PaymentReceived.propTypes = {};

export default PaymentReceived;
