import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import {
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  IconButton,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import { useLocalStorage } from "../../hooks";
import Empty from "../Empty";
import { TableSkeleton } from "../Skeletons";
import { LoaderContainer } from "./Styled";
import { formatFilters } from "../../utility";
import TableTotals from "./TableTotals";
import TableColumnHider from "common/Table/components/TableColumnHider";

import "./Table.scss";

const EnhancedTableHead = ({
  order,
  orderBy,
  onRequestSort,
  headCells,
  hideColumns,
}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) =>
          hideColumns.includes(headCell.id) ? null : (
            <TableColumnHider key={headCell.id} column={headCell} >
              <TableCell
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
                style={{
                  minWidth: headCell.minWidth ? headCell.minWidth : "auto",
                  width: headCell.width ? headCell.width : "auto",
                }}
              >
                {!headCell.notSortable ? (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "desc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <span className="visually-hidden">
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  headCell.label
                )}
              </TableCell>
            </TableColumnHider>
          )
        )}
      </TableRow>
    </TableHead>
  );
};

const { func, oneOf, string, array } = PropTypes;
EnhancedTableHead.propTypes = {
  onRequestSort: func.isRequired,
  order: oneOf(["asc", "desc"]).isRequired,
  orderBy: string.isRequired,
  hideColumns: array,
};

const Table = ({
  columns,
  rows,
  size,
  stickyHeader,
  onRow,
  loading,
  originalOrder="desc",
  originalOrderBy,
  pagination={ count: 0, current_page: 0, total_pages: 0 },
  pageRequest,
  filters,
  search="",
  hideColumns=[],
  valign,
  param,
  hasParam=false,
  noPagination,
  emptyMessage,
  totals,
  clientFilter,
  org,
  rowsPerPage,
}) => {
  const tableContainerRef = useRef(null);
  const tableInnerRef = useRef(null);
  const [isHorizontalScrolled, setIsHorizontalScrolled] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [_rowsPerPage, setRowsPerPage] = useLocalStorage("rowsPerPage", 50);
  rowsPerPage = rowsPerPage ? rowsPerPage : _rowsPerPage;
  const [order, setOrder] = React.useState(originalOrder);
  const [orderBy, setOrderBy] = React.useState(originalOrderBy);
  const [query, setQuery] = React.useState({
    page: 0,
  });
  let perRowOptions = [25, 50, 100, 200];
  if(!perRowOptions.includes(rowsPerPage)){
    perRowOptions.push(rowsPerPage);
  }

  useEffect(() => {
    const filterSettings = formatFilters(filters);

    let querystring = {
      keywords: search,
      order: orderBy,
      sort: order,
      page: page,
      rows_per_page: rowsPerPage,
      ...filterSettings,
    };

    if (org && clientFilter) {
      querystring = {
        ...querystring,
        ...formatFilters({ [clientFilter]: [{ value: org }] }),
      };
    }

    if (pageRequest && hasParam && param) {
      pageRequest(param, querystring);
    } else if (pageRequest && !hasParam) {
      pageRequest(querystring);
    }

    setQuery(querystring);
    setPage(0);
  }, [search, filters, param, org]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangePage = (event, newPage) => {
    const querystring = { ...query, page: newPage, rows_per_page: rowsPerPage };
    if (pageRequest && hasParam && param) {
      pageRequest(param, querystring);
    } else if (pageRequest && !hasParam) {
      pageRequest(querystring);
    }

    setQuery(querystring);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    const querystring = { ...query, rows_per_page: newRowsPerPage };
    if (pageRequest && param) {
      pageRequest(param, querystring);
    } else if (pageRequest) {
      pageRequest(querystring);
    }

    setRowsPerPage(newRowsPerPage);
    setQuery(querystring);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const querystring = {
      ...query,
      order: property,
      sort: isAsc ? "desc" : "asc",
    };
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setQuery(querystring);

    if (pageRequest && hasParam && param) {
      pageRequest(param, querystring);
    } else if (pageRequest && !hasParam) {
      pageRequest(querystring);
    }
  };

  React.useEffect(() => {
    const parent = tableContainerRef.current
      ? tableContainerRef.current.offsetWidth
      : null;
    const child = tableInnerRef.current
      ? tableInnerRef.current.offsetWidth
      : null;

    if (parent && child && child > parent) {
      setIsHorizontalScrolled("right");
    } else {
      setIsHorizontalScrolled(null);
    }
  }, [rows]);

  const handleScrollClick = () => {
    const parent = tableContainerRef.current
      ? tableContainerRef.current.offsetWidth
      : null;
    const child = tableInnerRef.current
      ? tableInnerRef.current.offsetWidth
      : null;

    if (parent && child && child > parent) {
      const offset = child - parent;
      if (isHorizontalScrolled === "right") {
        tableContainerRef.current.scrollLeft += offset;
        setIsHorizontalScrolled("left");
      } else {
        tableContainerRef.current.scrollLeft -= offset;
        setIsHorizontalScrolled("right");
      }
    }
  };

  const { count, current_page } = pagination;
  return (
    <section className="table" style={{ position: "relative" }}>
      {Boolean(isHorizontalScrolled) && (
        <Tooltip title="Scroll available">
          <IconButton
            onClick={handleScrollClick}
            style={{ position: "absolute", right: 10, zIndex: 3 }}
            size="small"
          >
            {isHorizontalScrolled === "left" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </Tooltip>
      )}
      <TableContainer className="table__wrapper" ref={tableContainerRef}>
        {loading ? (
          <LoaderContainer>
            <TableSkeleton />
          </LoaderContainer>
        ) : !rows.length ? (
          <Empty
            large
            msg={emptyMessage ? emptyMessage : "No data to display."}
          />
        ) : (
          <MaterialTable
            aria-label="Table"
            size={size}
            stickyHeader={stickyHeader}
            ref={tableInnerRef}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={columns}
              hideColumns={hideColumns}
            />
            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    className="table__row"
                    onClick={onRow ? () => onRow(row) : null}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      const className = (column.valign ? `align-${column.valign}` : (valign ? `align-${valign}` : ""));
                      return hideColumns.includes(column.id) ? null : (
                        <TableColumnHider key={column.id} column={column} >
                          <TableCell
                            align={column.align}
                            padding={column.disablePadding ? "none" : "normal"}
                            className={className}
                          >
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        </TableColumnHider>
                      );
                    })}
                  </TableRow>
                );
              })}
              {totals && (
                <TableTotals
                  columns={columns}
                  totals={totals}
                  hideColumns={hideColumns}
                />
              )}
            </TableBody>
          </MaterialTable>
        )}
      </TableContainer>
      {!noPagination && (
        <TablePagination
          rowsPerPageOptions={(handleChangeRowsPerPage ? perRowOptions : [])}
          component="div"
          count={Number(count)}
          rowsPerPage={rowsPerPage}
          page={current_page ? current_page : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className="table__pagination"
        />
      )}
    </section>
  );
};

const { bool, object } = PropTypes;
Table.propTypes = {
  columns: array.isRequired,
  rows: array.isRequired,
  onRow: func,
  size: string,
  loading: bool,
  originalOrder: string,
  orginalOrderBy: string,
  pagination: object,
  search: string,
  hideColumns: array,
};

const mapStateToProps = (state) => ({
  org: state.auth.current_client,
});

export default compose(connect(mapStateToProps), React.memo)(Table);
