import React from "react";
import { RichUtils, EditorState, SelectionState } from "draft-js";
import linkifyIt from "linkify-it";
import tlds from "tlds";

const linkify = linkifyIt();
linkify.tlds(tlds);

export const extractLinks = (text) => {
  return linkify.match(text);
};

export const linkStrategy = (contentBlock, callback) => {
  const links = extractLinks(contentBlock.getText());
  if (links) {
    for (const link of links) {
      callback(link.index, link.lastIndex);
    }
  }
};

export const addLinkEntities = (editorState) => {
  let currentState = editorState;
  const contentState = currentState.getCurrentContent();
  const blockMap = contentState.getBlockMap();
  blockMap.forEach((block) => {
    const blockKey = block.getKey();
    const links = extractLinks(block.getText());
    if (links) {
      for (const link of links) {
        const selection = SelectionState.createEmpty(blockKey)
          .set("anchorOffset", link.index)
          .set("focusOffset", link.lastIndex);

        const contentWithEntity = contentState.createEntity(
          "LINK",
          "IMMUTABLE",
          {
            url: link.url,
          }
        );
        const newEditorState = EditorState.push(
          editorState,
          contentWithEntity,
          "create-entity"
        );
        const entityKey = contentWithEntity.getLastCreatedEntityKey();
        currentState = RichUtils.toggleLink(
          newEditorState,
          selection,
          entityKey
        );
      }
    }
  });

  return currentState;
};

export const Link = ({
  decoratedText = "",
  target = "_blank",
  rel = "noreferrer noopener",
  getEditorState,
  setEditorState,
  start,
  end,
  blockKey,
  contentState,
  entityKey,
  offsetKey,
  ...otherProps
}) => {
  const links = linkify.match(decoratedText);
  const href = links && links[0] ? links[0].url : "";

  const linkProps = {
    ...otherProps,
    href,
    target,
    rel,
  };
  return <a {...linkProps} />; //eslint-disable-line
};

const DecoratedLink = (props) => <Link {...props} />;

const addLinkPluginPlugin = {
  decorators: [
    {
      strategy: linkStrategy,
      component: DecoratedLink,
    },
  ],
};

export default addLinkPluginPlugin;
