import styled from "styled-components";
import constants from "../../../constants";

export const TooltipHolder = styled.div`
  position: absolute;
  top: -11px;
  left: 0;
  line-height: 0;
`;

export const Tooltip = styled.div`
  z-index: 10;
  width: 100%;
  height: 100%;
  line-height: normal;
  position: relative;
  display: ${({ theme }) => (theme.tooltip ? theme.tooltip.display : "none")};
  background: ${constants.colorBlack};
  color: white;
  padding: 5px;
  border-radius: 3px;
  box-shadow: 0px 0px 3px #999;
  transform: translate(-50%, -100%);
`;

export const TooltipName = styled.p`
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
  padding: 0;
  margin: 0;
`;

export const TooltipAddress = styled.p`
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const TooltipContent = styled.div`
  padding: 0;
  box-shadow: 0px 0px 3px pink;
  color: green;
`;

export const TooltipOffsetHelper = styled.div`
  // position: absolute;
  // left: 0;
  // top: 0;
  // width: 0;
  // height: 0;
`;
