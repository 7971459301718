import React, { useEffect } from "react";
import { useSelector, connect } from "react-redux";
import Helmet from "react-helmet";
import { Typography, Button, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GetApp } from "@mui/icons-material";
import { FilterList } from "@mui/icons-material";
import { Autocomplete } from "@mui/material";

import Alert from "common/Alert";
import ReportSearch from "./ReportSearch";
import Table, { TableConfigure } from "../Table";
import LinkHeader from "common/LinkHeader";
import { exportBatch, getReportCustomers, clear } from "../../actions";
import Paper from "../Paper";
import { formatFilters } from "../../utility";
import { Actions, ActionGroup, FilterMenu, ButtonGroup } from "./Styled";
import ButtonDropdown from "common/ButtonDropdown";
import {
  customerStatusSelectors,
  statesSelectors,
} from "../../features/Taxonomies/taxonomiesSlice";
import constants from "components/constants";

const useStyles = makeStyles((theme) => ({
  button: {
    background: constants.gradientBlack,
    paddingRight: "10px",
    color: constants.colorWhite,
    "&:hover": {
      background: constants.gradientBlack,
      color: constants.colorWhite,
    },
  },
}));

const columnSettings = {
  customer_name: { minWidth: 400 },
  has_logo: { minWidth: 100, width: 100 },
  assigned_rep_uid: { width: 50 },
  phx_client_rep: { minWidth: 150 },
  is_parent_customer: { minWidth: 50, label: "Parent" },
  address_line1: { minWidth: 200, label: "Street" },
  address_line2: { minWidth: 200, label: "Street 2" },
  city: { minWidth: 150 },
  state: { minWidth: 150 },
  postal_code: { minWidth: 150 },
  billing_address_line1: { minWidth: 200, label: "Mailing Street" },
  billing_address_line2: { minWidth: 200, label: "Mailing Street 2" },
  billing_city: { minWidth: 150 },
  billing_state: { minWidth: 150 },
  billing_postal_code: { minWidth: 150 },
  phone: { minWidth: 150 },
  phone_after_hours: { minWidth: 200 },
  fax: { minWidth: 150 },
  website: { minWidth: 150 },
  primary_contact: { minWidth: 150 },
  secondary_contacts: { minWidth: 250 },
  after_hours_contacts: { minWidth: 250 },
  user_names: { minWidth: 250 },
  status_proper: { minWidth: 150 },
  active_customer_locations: { minWidth: 200, label: "Locations" },
  referred_member_name: { minWidth: 250 },
  referral_recon: { minWidth: 175 },
  referral_mitigation: { minWidth: 200 },
  emergency_service_fee: { minWidth: 250 },
  emergency_service_fee_work: { minWidth: 250 },
  recon_service_fee: { minWidth: 250 },
  recon_service_fee_work: { minWidth: 200 },
  national_contracts: { minWidth: 200 },
  child_customer_qty: { minWidth: 150, label: "Children" },
};

const createColumns = (columns) =>
  columns.map((column) => {
    column.id = column.index;
    column = {
      ...column,
      notSortable: !column.sortable,
      ...columnSettings[column.index],
    };
    return column;
  });

const createData = (
  name,
  hasLogo,
  rep_uid,
  rep_first,
  rep_last,
  isParent,
  street,
  street2,
  city,
  state,
  zip,
  billingStreet,
  billingStreet2,
  billingCity,
  billingState,
  billingZip,
  phone,
  afterHours,
  fax,
  website,
  primary,
  secondary,
  afterHoursContacts,
  userNames,
  status,
  active_customer_locations,
  referred_member_name,
  referral_recon,
  referral_mitigation,
  emergency_service_fee,
  emergency_service_fee_work,
  recon_service_fee,
  recon_service_fee_work,
  national_contracts,
  child_customer_qty,
  paths
) => {
  return {
    customer_name: name ? (
      <LinkHeader to={paths.customer} size="small" color="default">
        {name}
      </LinkHeader>
    ) : null,
    has_logo: hasLogo,
    phx_client_rep: rep_uid && rep_uid > 0 ? `${rep_first} ${rep_last}` : null,
    is_parent_customer: isParent,
    address_line1: street,
    address_line2: street2,
    city,
    state,
    postal_code: zip,
    billing_address_line1: billingStreet,
    billing_address_line2: billingStreet2,
    billing_city: billingCity,
    billing_state: billingState,
    billing_postal_code: billingZip,
    phone: phone,
    phone_after_hours: afterHours,
    fax: fax,
    website: website,
    primary_contact: primary,
    secondary_contacts: secondary,
    after_hours_contacts: afterHoursContacts,
    user_names: userNames,
    status_proper: status,
    active_customer_locations: active_customer_locations
      ? active_customer_locations
      : 0,
    referred_member_name,
    referral_recon: referral_recon
      ? `${Number(referral_recon).toFixed(2)}%`
      : null,
    referral_mitigation: referral_mitigation
      ? `${Number(referral_mitigation).toFixed(2)}%`
      : null,
    emergency_service_fee: emergency_service_fee
      ? `${Number(emergency_service_fee).toFixed(2)}%`
      : null,
    emergency_service_fee_work: emergency_service_fee_work
      ? `${Number(emergency_service_fee_work).toFixed(2)}%`
      : null,
    recon_service_fee: recon_service_fee
      ? `${Number(recon_service_fee).toFixed(2)}%`
      : null,
    recon_service_fee_work: recon_service_fee_work
      ? `${Number(recon_service_fee_work).toFixed(2)}%`
      : null,
    national_contracts,
    child_customer_qty,
  };
};

const initialFormData = {
  state: [],
  status: [],
};

const ReportCustomers = ({
  report=[],
  clear,
  pagination={
    count: 0,
    current_page: 0,
    total_pages: 0,
    display_columns: [],
  },
  loading,
  error,
  loadCustomers,
  exportReport,
  cancelSource,
  org,
}) => {
  const classes = useStyles();
  const states = useSelector(statesSelectors.selectAll);
  const statuses = useSelector(customerStatusSelectors.selectAll);
  const [hiddenColumns, setHiddenColumns] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filters, setFilters] = React.useState({});
  const [formData, setFormData] = React.useState(initialFormData);

  useEffect(() => {
    return () => {
      clear();
    };
  }, [clear]);

  const handleExportReport = async () => {
    let filterSettings = formatFilters(filters);

    if (org) {
      filterSettings = {
        ...filterSettings,
        "filter[phx_client_nid]": org,
      };
    }

    await exportReport("/rest/report/customers", {
      _export: "csv",
      keywords: searchTerm,
      ...filterSettings,
    });
  };

  const columns = React.useMemo(() => {
    if (loading) return [];
    return createColumns(pagination.display_columns);
  }, [pagination, loading]);

  const rows = React.useMemo(() => {
    if (loading) return [];
    return report.map((row) =>
      createData(
        row.customer_name,
        row.has_logo,
        row.assigned_rep_uid,
        row.assigned_rep_first_name,
        row.assigned_rep_last_name,
        row.is_parent_customer,
        row.address_line1,
        row.address_line2,
        row.city,
        row.state,
        row.postal_code,
        row.billing_address_line1,
        row.billing_address_line2,
        row.billing_city,
        row.billing_state,
        row.billing_postal_code,
        row.phone,
        row.phone_after_hours,
        row.fax,
        row.website,
        row.primary_contact,
        row.secondary_contacts,
        row.after_hours_contacts,
        row.user_names,
        row.status_proper,
        row.active_customer_locations,
        row.referred_member_name,
        row.referral_recon,
        row.referral_mitigation,
        row.emergency_service_fee,
        row.emergency_service_fee_work,
        row.recon_service_fee,
        row.recon_service_fee_work,
        row.national_contracts,
        row.child_customer_qty,
        row._paths
      )
    );
  }, [report, loading]);

  const hideColumns = React.useMemo(() => {
    if (loading) return [];
    return hiddenColumns;
  }, [hiddenColumns, loading]);

  const handleChange = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const applyFilters = () => {
    setFilters({ ...formData });
  };

  const resetFilters = () => {
    setFilters({});
    setFormData(initialFormData);
  };

  return (
    <div>
      <Helmet>
        <title>Customers Report | Helix</title>
        <meta name="description" content="Reports" />
      </Helmet>
      {error && <Alert kind="negative">{error.message}</Alert>}
      <LinkHeader to="/reports" color="default" size="small" back>
        Back to reports
      </LinkHeader>
      <Paper margin="none" style={{ marginTop: "1rem" }}>
        <Actions>
          <Typography variant="h6">Customers</Typography>
          <ActionGroup>
            <TableConfigure
              columns={columns}
              hideColumns={setHiddenColumns}
              currentColumns={hiddenColumns}
            />
            <ButtonDropdown
              icon={FilterList}
              label="Filters"
              style={{ marginLeft: "1rem" }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              color="tertiary"
            >
              <FilterMenu>
                <Autocomplete
                  options={states.map((state) => ({
                    label: state.label,
                    value: state.value,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "state");
                  }}
                  value={formData.state}
                />
                <Autocomplete
                  options={statuses.map((status) => ({
                    label: status.label,
                    value: status.machine_name,
                  }))}
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Status"
                      variant="outlined"
                      margin="normal"
                      size="small"
                    />
                  )}
                  onChange={(e, value) => {
                    handleChange(value, "status");
                  }}
                  value={formData.status}
                />
                <ButtonGroup>
                  <Button disableElevation size="small" onClick={resetFilters}>
                    Reset
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    size="small"
                    style={{ marginRight: "1rem" }}
                    onClick={applyFilters}
                  >
                    Apply
                  </Button>
                </ButtonGroup>
              </FilterMenu>
            </ButtonDropdown>
            <Button
              className={classes.button}
              size="small"
              startIcon={<GetApp />}
              onClick={handleExportReport}
            >
              Export
            </Button>
          </ActionGroup>
        </Actions>
        <ReportSearch setQuery={setSearchTerm} />
        <Table
          clientFilter={"phx_client_nid"}
          columns={columns}
          rows={rows}
          stickyHeader
          loading={loading}
          hideColumns={hideColumns}
          pageRequest={loadCustomers}
          originalOrderBy="customer_name"
          originalOrder="asc"
          search={searchTerm}
          filters={filters}
          pagination={pagination}
          size="small"
          source={cancelSource}
        />
      </Paper>
    </div>
  );
};

ReportCustomers.propTypes = {};

const mapStateToProps = (state) => ({
  loading: state.app.report.loading,
  report: state.app.report.data.data,
  pagination: state.app.report.data.pagination,
  error: state.app.report.error,
  cancelSource: state.app.report.cancel,
});

const mapDispatchToProps = (dispatch) => ({
  loadCustomers: (params) => dispatch(getReportCustomers(params)),
  exportReport: (url, params) => dispatch(exportBatch(url, params)),
  clear: () => dispatch(clear("report")),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportCustomers);
