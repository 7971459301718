import React, { useMemo, useEffect } from "react";
import moment from "moment-timezone";
import { Tune } from "@mui/icons-material";
import {
  DialogContent,
  Grid,
  Typography,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import ButtonDropdown from "common/ButtonDropdown";
import { formatDecimalToTime, formatMoney } from "utility";
import { calcTotalAmt } from "./utils";
import { useSelector } from "react-redux";

const Service = ({ sheet, setTitle }) => {
  const userTimeZone = useSelector((state) => state.auth.user.data.timezone);
  let timeZone = moment.tz.guess();
  if(!timeZone) {
    timeZone = userTimeZone;
  }

  useEffect(() => {
    setTitle("Labor");
  }, [setTitle]);

  const timeZoneAbbr = useMemo(() => {
    let timeZoneAbbr = moment().tz(timeZone).format("z");
    if (
      timeZoneAbbr === "EDT" ||
      timeZoneAbbr === "CDT" ||
      timeZoneAbbr === "MDT" ||
      timeZoneAbbr === "PDT"
    ) {
      timeZoneAbbr = timeZoneAbbr.replace(/D/, "S");
    }

    return timeZoneAbbr;
  }, [timeZone]);

  const user = useMemo(() => {
    return sheet.user && sheet.user[0]
      ? sheet.user[0].name
      : sheet.field_ds_user_temp && sheet.field_ds_user_temp[0]
      ? sheet.field_ds_user_temp[0]
      : sheet.tempUser && sheet.tempUser[0]
      ? sheet.tempUser[0].name
      : null;
  }, [sheet.user, sheet.field_ds_user_temp, sheet.tempUser]);

  return (
    <DialogContent>
      <div style={{ padding: "1.25rem 0" }}>
        <ButtonDropdown
          id="daily-sheets-overrides-button"
          label="Overrides"
          color="tertiary"
          style={{ marginRight: "1rem" }}
          icon={Tune}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div style={{ padding: "1.25rem" }}>
            <Typography
              style={{ marginTop: "1rem", marginBottom: "0.5rem" }}
              variant="subtitle1"
              color="textSecondary"
            >
              Labor
            </Typography>
            <Grid container spacing={3}>
              <Grid item xxs={6}>
                <InputLabel shrink>Billable (HRS)</InputLabel>
                <Typography>{sheet.billableHrs}</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sheet.overrideBillHrs}
                      color="secondary"
                      disabled
                    />
                  }
                  label="Override"
                />
              </Grid>
              <Grid item xxs={6}>
                <InputLabel shrink>Rate</InputLabel>
                <Typography>{sheet.rate}</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sheet.overrideRate}
                      color="secondary"
                      disabled
                    />
                  }
                  label="Override"
                />
              </Grid>
            </Grid>
            <Typography
              style={{ marginTop: "1rem", marginBottom: "0.5rem" }}
              variant="subtitle1"
              color="textSecondary"
            >
              Travel
            </Typography>
            <Grid container spacing={3}>
              <Grid item xxs={6}>
                <InputLabel shrink>Billable (HRS)</InputLabel>
                <Typography>{sheet.travelBillHrs}</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sheet.travelBillOvrHrs}
                      color="secondary"
                      disabled
                    />
                  }
                  label="Override"
                />
              </Grid>
              <Grid item xxs={6}>
                <InputLabel shrink>Rate</InputLabel>
                <Typography>{sheet.travelRate}</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sheet.travelOvrRate}
                      color="secondary"
                      disabled
                    />
                  }
                  label="Override"
                />
              </Grid>
            </Grid>
            <Typography
              style={{ marginTop: "1rem", marginBottom: "0.5rem" }}
              variant="subtitle1"
              color="textSecondary"
            >
              Overtime
            </Typography>
            <Grid container spacing={3}>
              <Grid item xxs={6}>
                <InputLabel shrink>Billable (HRS)</InputLabel>
                <Typography>{sheet.overtimeBillHrs}</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sheet.overtimeBillOvrHrs}
                      color="secondary"
                      disabled
                    />
                  }
                  label="Override"
                />
              </Grid>
              <Grid item xxs={6}>
                <InputLabel shrink>Rate</InputLabel>
                <Typography>{sheet.overtimeRate}</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sheet.overtimeOvrRate}
                      color="secondary"
                      disabled
                    />
                  }
                  label="Override"
                />
              </Grid>
            </Grid>
            <Typography
              style={{ marginTop: "1rem", marginBottom: "0.5rem" }}
              variant="subtitle1"
              color="textSecondary"
            >
              Overtime
            </Typography>
            <Grid container spacing={3}>
              <Grid item xxs={6}>
                <InputLabel shrink>Billable (HRS)</InputLabel>
                <Typography>{sheet.overtimeBillHrs}</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sheet.overtimeBillOvrHrs}
                      color="secondary"
                      disabled
                    />
                  }
                  label="Override"
                />
              </Grid>
              <Grid item xxs={6}>
                <InputLabel shrink>Rate</InputLabel>
                <Typography>{sheet.overtimeRate}</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sheet.overtimeOvrRate}
                      color="secondary"
                      disabled
                    />
                  }
                  label="Override"
                />
              </Grid>
            </Grid>
            <Typography
              style={{ marginTop: "1rem", marginBottom: "0.5rem" }}
              variant="subtitle1"
              color="textSecondary"
            >
              After Hours
            </Typography>
            <Grid container spacing={3}>
              <Grid item xxs={6}>
                <InputLabel shrink>Billable (HRS)</InputLabel>
                <Typography>{sheet.afterHoursBillHrs}</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sheet.afterHoursBillOvrHrs}
                      color="secondary"
                      disabled
                    />
                  }
                  label="Override"
                />
              </Grid>
              <Grid item xxs={6}>
                <InputLabel shrink>Rate</InputLabel>
                <Typography>{sheet.afterHoursRate}</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sheet.afterHoursOvrRate}
                      color="secondary"
                      disabled
                    />
                  }
                  label="Override"
                />
              </Grid>
            </Grid>
          </div>
        </ButtonDropdown>
        <ButtonDropdown
          id="daily-sheets-actions-button"
          label="Options"
          color="tertiary"
          icon={Tune}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div style={{ padding: "1.25rem" }}>
            <FormGroup style={{ width: "300px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sheet.smallToolsPercent > 0}
                    name="small_tools"
                    disabled
                    color="secondary"
                  />
                }
                label="3% Small Tools Charge"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sheet.onsite}
                    name="onsite"
                    disabled
                    color="secondary"
                  />
                }
                label="Onsite"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sheet.excludeInvoice}
                    name="exclude"
                    color="secondary"
                    disabled
                  />
                }
                label="Exclude from invoice"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sheet.billable}
                    name="billable"
                    color="secondary"
                    disabled
                  />
                }
                label="Billable"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sheet.showDescription}
                    name="show"
                    color="secondary"
                    disabled
                  />
                }
                label="Show private description on the invoice"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sheet.field_ds_taxable}
                    name="show"
                    color="secondary"
                    disabled
                  />
                }
                label="Taxable"
              />
            </FormGroup>
            {sheet.workarea?.title && (
              <div style={{ marginTop: "1rem" }}>
                <InputLabel shrink>Work Area</InputLabel>
                <Typography style={{ textTransform: "capitalize" }}>
                  {sheet.workarea.title}
                </Typography>
              </div>
            )}
            <div style={{ marginTop: "1rem" }}>
              <InputLabel shrink>Entry Type</InputLabel>
              <Typography style={{ textTransform: "capitalize" }}>
                {sheet.entryType}
              </Typography>
            </div>
          </div>
        </ButtonDropdown>
      </div>
      <div>
        <Grid container spacing={3} className="mt-0">
          <Grid item xxs={6} md={3}>
            <InputLabel shrink>Labor Hours</InputLabel>
            <Typography variant="body1">
              {sheet.entryType === "hourly"
                ? formatDecimalToTime(sheet.billableHrs)
                : formatDecimalToTime(sheet.serviceHrs)}
            </Typography>
          </Grid>
          <Grid item xxs={6} md={3}>
            <InputLabel shrink>Travel Hours</InputLabel>
            <Typography variant="body1">
              {sheet.entryType === "hourly"
                ? formatDecimalToTime(sheet.travelBillHrs)
                : formatDecimalToTime(sheet.travelHrs)}
            </Typography>
          </Grid>
          <Grid item xxs={6} md={3}>
            <InputLabel shrink>Overtime Hours</InputLabel>
            <Typography variant="body1">
              {sheet.entryType === "hourly"
                ? formatDecimalToTime(sheet.overtimeBillHrs)
                : formatDecimalToTime(sheet.overtimeHrs)}
            </Typography>
          </Grid>
          <Grid item xxs={6} md={3}>
            <InputLabel shrink>After Hours Hours</InputLabel>
            <Typography variant="body1">
              {sheet.entryType === "hourly"
                ? formatDecimalToTime(sheet.afterHoursBillHrs)
                : formatDecimalToTime(sheet.afterHoursHrs)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3} className="mt-0">
          <Grid item xxs={6} md={3}>
            <InputLabel shrink>Labor Total</InputLabel>
            <Typography variant="body1">
              {sheet.entryType === "hourly"
                ? formatMoney(calcTotalAmt(sheet.rate, sheet.billableHrs))
                : formatMoney(sheet.serviceTotal)}
            </Typography>
          </Grid>
          <Grid item xxs={6} md={3}>
            <InputLabel shrink>Travel Total</InputLabel>
            <Typography variant="body1">
              {sheet.entryType === "hourly"
                ? formatMoney(
                    calcTotalAmt(sheet.travelRate, sheet.travelBillHrs)
                  )
                : formatMoney(sheet.travelTotal)}
            </Typography>
          </Grid>
          <Grid item xxs={6} md={3}>
            <InputLabel shrink>Overtime Total</InputLabel>
            <Typography variant="body1">
              {sheet.entryType === "hourly"
                ? formatMoney(
                    calcTotalAmt(sheet.overtimeRate, sheet.overtimeBillHrs)
                  )
                : formatMoney(sheet.overtimeTotal)}
            </Typography>
          </Grid>
          <Grid item xxs={6} md={3}>
            <InputLabel shrink>After Hours Total</InputLabel>
            <Typography variant="body1">
              {sheet.entryType === "hourly"
                ? formatMoney(
                    calcTotalAmt(sheet.afterHoursRate, sheet.afterHoursBillHrs)
                  )
                : formatMoney(sheet.afterHoursTotal)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3} className="mt-0">
          {sheet.hasPerdiem && (
            <Grid item xxs={6} md={3}>
              <InputLabel shrink>Per Diem</InputLabel>
              <Typography variant="body1">
                {formatMoney(sheet.perdiem)}
              </Typography>
            </Grid>
          )}
          {sheet.ppe && (
            <Grid item xxs={6} md={3}>
              <InputLabel shrink>PPE</InputLabel>
              <Typography variant="body1">{formatMoney(sheet.ppe)}</Typography>
            </Grid>
          )}
          {sheet.smallTools && (
            <Grid item xxs={6} md={3}>
              <InputLabel shrink>{(sheet.smallToolsPercent * 100)}% Small Tools Charge</InputLabel>
              <Typography variant="body1">{formatMoney(sheet.smallTools)}</Typography>
            </Grid>
          )}
          {sheet.lodging && (
            <Grid item xxs={6} md={3}>
              <InputLabel shrink>Lodging</InputLabel>
              <Typography variant="body1">{formatMoney(sheet.lodging)}</Typography>
            </Grid>
          )}
          {Number(sheet.field_ds_markup) > 0 &&
            <Grid item xxs={6} md={3}>
              <InputLabel shrink>Markup</InputLabel>
              <Typography variant="body1">
                {formatMoney(sheet.field_ds_markup)}
                {Number(sheet.field_ds_percent_markup) > 0 &&
                  ` (${Number(sheet.field_ds_percent_markup)}%)`
                }
              </Typography>
            </Grid>
          }
          <Grid item xxs={6} md={3}>
            <InputLabel shrink>Total Hours</InputLabel>
            <Typography variant="body1">
              {formatDecimalToTime(sheet.totalHrs)}
            </Typography>
          </Grid>
          <Grid item xxs={6} md={3}>
            <InputLabel shrink>Total</InputLabel>
            <Typography variant="body1">{formatMoney(sheet.total)}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: "2rem" }}>
          <Grid item xxs={6} md={3}>
            <InputLabel shrink>User</InputLabel>
            <Typography>{user}</Typography>
          </Grid>
          <Grid item xxs={6} md={3}>
            <InputLabel shrink>Activity</InputLabel>
            <Typography>{sheet.activity}</Typography>
          </Grid>
          <Grid item xxs={6} md={3}>
            <InputLabel shrink>Labor Type</InputLabel>
            <Typography>{sheet.labor}</Typography>
          </Grid>
          <Grid item xxs={6} md={3}>
            <InputLabel shrink>Date</InputLabel>
            <Typography>{moment(sheet.date).format("MM/DD/YYYY")}</Typography>
          </Grid>
          {sheet.entryType === "hourly" && (
            <>
              <Grid item xxs={6} md={3}>
                <InputLabel shrink>Start Time</InputLabel>
                <Typography>
                  {moment(sheet.start).tz(timeZone).format("h:mm A") +
                    " " +
                    timeZoneAbbr}
                </Typography>
              </Grid>
              <Grid item xxs={6} md={3}>
                <InputLabel shrink>Break Start</InputLabel>
                <Typography>
                  {sheet.breaks.start
                    ? moment(sheet.breaks.start).tz(timeZone).format("h:mm A") +
                      " " +
                      timeZoneAbbr
                    : null}
                </Typography>
              </Grid>
              <Grid item xxs={6} md={3}>
                <InputLabel shrink>Break End</InputLabel>
                <Typography>
                  {sheet.breaks.end
                    ? moment(sheet.breaks.end).tz(timeZone).format("h:mm A") +
                      " " +
                      timeZoneAbbr
                    : null}
                </Typography>
              </Grid>
              <Grid item xxs={6} md={3}>
                <InputLabel shrink>End Time</InputLabel>
                <Typography>
                  {sheet.end
                    ? moment(sheet.end).tz(timeZone).format("h:mm A") +
                      " " +
                      timeZoneAbbr
                    : null}
                </Typography>
              </Grid>
            </>
          )}
          {sheet.description && (
            <Grid item xxs={12}>
              <InputLabel shrink>Description</InputLabel>
              <Typography>{sheet.description}</Typography>
            </Grid>
          )}
        </Grid>
      </div>
    </DialogContent>
  );
};

Service.propTypes = {};

export default Service;
