
import React, { useEffect, } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, } from "react-router-dom";
import {
  Typography,
  Card,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Link,
} from "@mui/material";
import { DefaultLoader } from "common/Loader";
import Empty from "@phx/common/Empty";
import { GiArchiveResearch } from "react-icons/gi";
import Layout from "common/Layout";
import {
  fetchDivision,
  getDivisionDataSelector,
  getJobErrorSelector,
  getJobLoadingSelector,
  getCustomerDataSelector,
  getLocationDataSelector,
} from "features/Job/jobSlice";
import { BsClockHistory, BsPrinter, BsX } from "react-icons/bs";
import { AiOutlineQrcode } from "react-icons/ai";
import { size } from "lodash";
import QRCode from "react-qr-code";
import "./styles/TimeClock.scss";
import config from "config";
import TimeClockCustomer from 'components/TimeClock/components/TimeClockCustomer';
import { formatStreetAddress } from "features/FormBuilder/utils.js";

const TimeClockQRCode = ({inWindow, menuProps, iconOnly}) => {
  const dispatch = useDispatch();
  const { jobName } = useParams();
  const loading = useSelector(getJobLoadingSelector);
  const error = useSelector(getJobErrorSelector);
  const division = useSelector(getDivisionDataSelector);
  const customer = useSelector(getCustomerDataSelector);
  const location = useSelector(getLocationDataSelector);
  let timeClockActive = (!loading && size(division) ? division?._processed?.time_clock_active : false);
//  Hide if non job admins no longer have access to time clock
  if(timeClockActive && typeof division?._processed?.time_clock_active_tc_only !== 'undefined'){
    timeClockActive = division._processed.time_clock_active_tc_only;
  }

  useEffect(() => {
    if(inWindow){
      const promise = dispatch(fetchDivision(jobName));

      return () => {
        promise.abort();
      };
    }
  }, [dispatch, jobName, inWindow]);

  if(!inWindow){
    const _jobName = division.path.replace(/\/job\//, '');
    const openWindow = () => {
      window.open(`/time-clock/qr-code/${_jobName}`, '_blank', {popup: true, width: 360, height: 500, innerWidth: 360, innerHeight: 500});
    }
    return (
      !iconOnly ? (
        timeClockActive ? (
          <ListItem
            onClick={openWindow}
            style={{ paddingLeft: "3rem" }}
            {...menuProps}
          >
            <ListItemIcon>
              <AiOutlineQrcode />
            </ListItemIcon>
            <ListItemText primary="Time Clock QR Code" />
          </ListItem>
        ) : (
          <></>
        )
      ) : (
        timeClockActive ? (
          <Tooltip title="Time Clock QR Code">
            <div
              onClick={openWindow}
              {...menuProps}
            >
              <AiOutlineQrcode />
            </div>
          </Tooltip>
        ) : (
          <Tooltip title="Time Clock closed for laborers">
            <div
              disabled
              {...menuProps}
            >
              <AiOutlineQrcode className="disabled" />
            </div>
          </Tooltip>
        )
      )
    );
  }

  let link = null;
  let alias = null;
  if(!loading && size(division)){
    const host = config.rawHostname;
    link = `${host}/time-clock/${division.field_public_hash}`;
    const jobName = division.path.replace('/job/', '');
    const shortHost = host.slice(host.lastIndexOf('/') + 1);
    alias = `${shortHost}/tc/${jobName}`;
  }

  return (
    loading || !size(division) ? (
      <DefaultLoader />
    ) : (
      !error ? (
        <>
        <Layout
          name={(<div className="content ml-2">
            <div>
              <Typography variant="h5">Job - Time Clock</Typography>
              <Typography className="normal-case">
                {division.title}
                {division.field_job_division_type.name && ` (${division.field_job_division_type.name})`}
              </Typography>
            </div>
            <div className="buttons">
              <BsPrinter className="icon print" title="Print the QR Code" onClick={() => window.print()} />
              <BsX className="icon close" title="Close the window" onClick={() => window.close()} />
            </div>
          </div>)}
          icon={<BsClockHistory />}
          pageHeaderProps={{
            slim: true,
            className: "time-clock heading"
          }}
        >
          <div className="time-clock time-clock__qr-code">
            <TimeClockCustomer
              timeClock={{
                division_customer: customer.title,
                division_customer_location: location.title,
                division_customer_location_address_string: formatStreetAddress(location?.field_street_address),
                division_customer_location_address: {
                  address_line1: location?.field_street_address?.address_line1,
                  address_line2: location?.field_street_address?.address_line2,
                  locality: location?.field_street_address?.locality,
                  administrative_area: location?.field_street_address?.administrative_area,
                  postal_code: location?.field_street_address?.postal_code,
                },
                division_customer_location_address_coords: {
                  lat: location?.field_street_address_geo?.lat,
                  lon: location?.field_street_address_geo?.lon,
                },

              }}
            />
            {!timeClockActive ? (
              <div className="heading">
                <Typography variant="h4">Time Clock is closed for this job</Typography>
              </div>
            ) : (
              <>
              <div className="heading">
                <span>Use the QR Code below to launch the time clock.</span>
              </div>
              <Card className="card" variant="outlined">
                <Link href={link} target="_blank">
                  <div className="code">
                    <QRCode
                      size={256}
                      style={{ height: "auto", maxWidth: "100%", width: "300px" }}
                      value={link}
                      viewBox={`0 0 256 256`}
                    />
                  </div>
                </Link>
              </Card>
              <div className="heading">
                <div>OR Visit the link below</div>
                <div className="alias-link"><a href={`http://${alias}`}>{alias}</a></div>
              </div>
              </>
            )}
          </div>
        </Layout>
        </>
      ) : (
        <Empty message={error} style={{"color": "var(--color-primary)"}}>
          <GiArchiveResearch />
        </Empty>
      )
    )
  );
};

TimeClockQRCode.propTypes = {};

export default TimeClockQRCode;
