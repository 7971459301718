import styled from "styled-components";

export const Container = styled.div`
  display: inline-flex;
  cursor: pointer;

  .MuiAvatar-circular {
    color: var(--color-gray-dark);
    background-color: var(--color-gray-light);
    border: 2px solid;
    margin-left: -0.5rem;

    &:first-child {
      margin-left: 0;
    }
  }
`;
