import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DialogContent, Typography } from "@mui/material";
import { CgNotes } from "react-icons/cg";

import { TextFormField } from "common/Fields";
import { DialogForm } from "common/Dialog";

import { getDivisionDataSelector, patchJobDivision, getJobDataSelector, patchJob } from "../jobSlice";
import IconTextItem from "common/IconTextItem";
import { RiEdit2Fill } from "react-icons/ri";

const EditJobDetailNotes = ({
  entity,
  title,
  buttonText,
  nid,
  field,
  format,
  initialValue,
  children,
}) => {
  const dispatch = useDispatch();
  const division = useSelector(getDivisionDataSelector);
  const job = useSelector(getJobDataSelector);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = (data) => {
    if(entity.type === 'node/job_division') {
      dispatch(
        patchJobDivision({
          id: nid,
          params: { [field]: [{ value: data[field] }] },
          division,
        })
      );
    }
    else if(entity.type === 'node/job') {
      dispatch(
        patchJob({
          id: nid,
          params: { [field]: [{ value: data[field] }] },
          job,
        })
      );
    }

    onClose();
  };

  const onClose = () => {
    setOpen(false);
  };

  const fieldLabel = field === "field_details" ? "Details" : "Job Notes";
  const editEnabled = division?._processed?.job_access_type === 'admin';

  if(!editEnabled && !entity[field]) return null;

  return (
    <>
      <IconTextItem icon={<CgNotes />}>
        <Typography
          variant="subtitle2"
          style={{
            textTransform: "uppercase",
            cursor: (editEnabled ? "pointer" : "default"),
            display: "flex",
            alignItems: "center"
          }}
          color="textSecondary"
          onClick={(editEnabled ? handleOpen : null)}
        >
          {fieldLabel} {editEnabled && <RiEdit2Fill className="ml-2" />}
        </Typography>
        {entity[field] && (
          <Typography variant="body1" className="whitespace-pre-wrap">
            {entity[field]}
          </Typography>
        )}
      </IconTextItem>
      <DialogForm
        open={open}
        onClose={onClose}
        maxWidth="sm"
        title={title}
        initialValues={{
          [field]: entity[field],
        }}
        disablePadding
        onSubmit={handleSubmit}
        // overrideDirty
      >
        {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
          <DialogContent>
            <TextFormField
              fullWidth
              htmlFor="title"
              id={field}
              name={field}
              label={fieldLabel}
              margin="normal"
              disabled={isSubmitting}
              size="small"
              variant="outlined"
              labelwidth={40}
              multiline
            />
          </DialogContent>
        )}
      </DialogForm>
    </>
  );
};

EditJobDetailNotes.propTypes = {};

export default EditJobDetailNotes;
