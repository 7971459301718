import React from "react";
import { useSelector, connect } from "react-redux";
import { Button, Typography, Chip, IconButton, Grid } from "@mui/material";
import { Formik, Form, FieldArray } from "formik";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import styled from "styled-components";
import SimpleBar from "simplebar-react";
import { uniqBy, size } from "lodash";

import constants from "../constants";
import Dialog from "../Dialog";
import { CheckboxFormField } from "common/Fields";
import { SelectFormField } from "common/Fields";
import Alert from "common/Alert";
import config from "../../config";
import ButtonLoader from "common/ButtonLoader";
import { patchMember } from "../../actions";
import { formatError, formatField } from "../../utility";
import {
  countiesSelectors,
  statesSelectors,
} from "../../features/Taxonomies/taxonomiesSlice";

const FormContainer = styled(Form)`
  padding: 0 1.25rem 1.25rem 1.25rem;
  min-width: 300px;
`;

const FormActions = styled.div`
  padding-top: 2rem;

  & > .MuiButtonBase-root {
    margin-right: 1rem;
  }
`;

const FormRow = styled.div`
  display: flex;
  align-items: center;
`;

const CountiesContainer = styled.div`
  background: ${constants.colorGrayLightest};
  border-radius: ${constants.borderRadius};
  padding: 1rem;
`;

const CountyActions = styled.div`
  display: flex;
  align-items: flex-end;
`;

const formatCurrentCounties = (owned, served) => {
  const data = [];
  if (!owned && !served) return data;

  served.forEach((serve) => {
    const parent = formatField(serve, "parent");
    const state = formatField(parent, "name");
    const tid = formatField(serve, "tid");
    const name = formatField(serve, "name");
    let countyOwned = false;
    owned.forEach((own) => {
      const ownedName = formatField(own, "name");
      if (ownedName === name) countyOwned = true;
    });
    data.push({ tid, name, served: !countyOwned, owned: countyOwned, state });
  });

  return data;
};

const getStatesFromSelectedCounties = (served) => {
  const states = [];
  served.forEach((county) => {
    const state =
      county.parent && county.parent[0] && county.parent[0]
        ? formatField(county.parent[0], "name")
        : null;
    states.push(state);
  });

  return states;
};

const formatCurrentStateCounties = (served, owned, counties) => {
  const data = [];
  const servedStates = getStatesFromSelectedCounties(served);

  servedStates.forEach((state) => {
    const availableCounties = [];
    const filteredCounties = counties.filter(
      (county) => state === county.state_code
    );
    filteredCounties.forEach((county) => {
      availableCounties.push({
        tid: county.tid,
        name: county.name,
        served: false,
        owned: false,
      });
    });

    const current = formatCurrentCounties(owned, served);
    const c = availableCounties.map((county) => {
      const c = current.find(
        (c) => c.name === county.name && c.state === state
      );

      if (c) {
        county.served = c.served;
        county.owned = c.owned;
      }

      return county;
    });

    data.push({ state, counties: c });
  });

  return uniqBy(data, "state");
};

const formatCountiesForSubmit = (states) => {
  const data = {
    field_counties_owned: [],
    field_counties_served: [],
  };

  states.forEach((state) => {
    state.counties.forEach((county) => {
      if (county.owned) {
        data.field_counties_owned.push({ target_id: county.tid });
      }

      if (county.served || county.owned) {
        data.field_counties_served.push({ target_id: county.tid });
      }
    });
  });

  return data;
};

const MemberCountiesForm = ({
  open,
  onClose,
  updateMember,
  nid,
  showSnackbar,
  served,
  owned,
  refreshMember,
}) => {
  const counties = useSelector(countiesSelectors.selectAll);
  const states = useSelector(statesSelectors.selectAll);
  const [message, setMessage] = React.useState(null);

  const handleSelectStateChange = (value, setFieldValue) => {
    const availableCounties = [];
    const filteredCounties = counties.filter((county) =>
      value.includes(county.state_code)
    );

    filteredCounties.forEach((county) => {
      availableCounties.push({
        tid: county.tid,
        name: county.name,
        served: false,
        owned: false,
      });
    });

    setFieldValue("counties", [
      ...formatCurrentCounties(owned, served),
      ...availableCounties,
    ]);
  };

  const getCountiesByState = (state, counties, index, setFieldValue) => {
    const availableCounties = [];
    const filteredCounties = counties.filter(
      (county) => state === county.state_code
    );

    filteredCounties.forEach((county) => {
      availableCounties.push({
        tid: county.tid,
        name: county.name,
        served: false,
        owned: false,
      });
    });

    const current = formatCurrentCounties(owned, served);
    const c = availableCounties.map((county) => {
      const c = current.find(
        (c) => c.name === county.name && c.state === state
      );

      if (c) {
        county.served = c.served;
        county.owned = c.owned;
      }

      return county;
    });

    setFieldValue(`states.${index}.counties`, [...c]);
  };

  return (
    <Dialog open={open} maxWidth="sm" onClose={onClose} title="Edit Counties">
      <Formik
        validateOnChange={false}
        initialValues={{
          states: formatCurrentStateCounties(served, owned, counties),
        }}
        //   validationSchema={addMemberAddress}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);

          const params = {
            _links: {
              type: {
                href: `${config.api_url}/rest/type/node/member`,
              },
            },
            ...formatCountiesForSubmit(data.states),
          };

          const response = await updateMember(nid, params);
          if (response.status === 200) {
            showSnackbar({
              msg: "Counties updated successfully.",
              kind: "positive",
            });
            refreshMember();
            onClose();
          } else {
            const errorMessage = formatError(response);
            setMessage(errorMessage);
          }
        }}
      >
        {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
          <FormContainer>
            {message && <Alert kind={message.id}>{message.msg}</Alert>}
            <FieldArray
              name="states"
              render={(arrayHelpers) => (
                <>
                {size(values.states) > 0 &&
                  <Grid container spacing={3}>
                    {values.states.map((state, index) => (
                      <Grid item xxs={12} sm={6} key={index}>
                        <CountyActions>
                          <SelectFormField
                            fullWidth
                            name={`states.${index}.state`}
                            label="State"
                            margin="normal"
                            required
                            disabled={isSubmitting}
                            renderValue={(selected) => (
                              <div>
                                {selected.map((value) => (
                                  <Chip
                                    key={value}
                                    label={value}
                                    size="small"
                                  />
                                ))}
                              </div>
                            )}
                            options={states}
                            onChange={(e) => {
                              getCountiesByState(
                                e.target.value,
                                counties,
                                index,
                                setFieldValue
                              );
                              setFieldValue(
                                `states.${index}.state`,
                                e.target.value
                              );
                              handleSelectStateChange(
                                e.target.value,
                                setFieldValue
                              );
                            }}
                          />
                          <IconButton
                            size="small"
                            style={{
                              marginLeft: "1rem",
                              marginBottom: "0.5rem",
                            }}
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <HighlightOffIcon />
                          </IconButton>
                        </CountyActions>
                        <SimpleBar
                          style={{
                            maxHeight: "200px",
                            border: "1px solid var(--color-gray)",
                            borderRadius: "var(--border-radius)",
                          }}
                        >
                          <CountiesContainer>
                            {values.states[index].counties.map(
                              (county, delta) => (
                                <FormRow key={delta}>
                                  <Typography
                                    variant="subtitle1"
                                    style={{ flex: 1 }}
                                  >
                                    {county.name}
                                  </Typography>
                                  <CheckboxFormField
                                    name={`states.${index}.counties.${delta}.served`}
                                    label="Served"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setFieldValue(
                                          `states.${index}.counties.${delta}.owned`,
                                          false
                                        );
                                      }

                                      setFieldValue(
                                        `states.${index}.counties.${delta}.served`,
                                        e.target.checked
                                      );
                                    }}
                                  />
                                  <CheckboxFormField
                                    name={`states.${index}.counties.${delta}.owned`}
                                    label="Owned"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setFieldValue(
                                          `states.${index}.counties.${delta}.served`,
                                          false
                                        );
                                      }

                                      setFieldValue(
                                        `states.${index}.counties.${delta}.owned`,
                                        e.target.checked
                                      );
                                    }}
                                  />
                                </FormRow>
                              )
                            )}
                          </CountiesContainer>
                        </SimpleBar>
                      </Grid>
                    ))}
                  </Grid>
                }
                <Button
                  variant="contained"
                  style={{ marginTop: "1rem" }}
                  disableElevation
                  onClick={() =>
                    arrayHelpers.push({ state: "", counties: [] })
                  }
                >
                  Add State
                </Button>
                </>
              )}
            />
            <FormActions>
              <Button
                disableElevation
                component="a"
                onClick={onClose}
                size="small"
              >
                Cancel
              </Button>
              <ButtonLoader
                variant="contained"
                disableElevation
                color="primary"
                type="submit"
                size="small"
                isSubmitting={isSubmitting}
              >
                Update
              </ButtonLoader>
            </FormActions>
          </FormContainer>
        )}
      </Formik>
    </Dialog>
  );
};

MemberCountiesForm.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
  updateMember: (nid, params) => dispatch(patchMember(nid, params)),
});

export default connect(null, mapDispatchToProps)(MemberCountiesForm);
