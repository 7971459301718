import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Divider,
  Grid,
  Collapse,
  Link,
  Typography,
  Hidden,
} from "@mui/material";
import {
  RiServiceLine,
  RiMapPin2Line,
  RiContactsBookLine,
  RiAwardFill,
  RiVideoChatFill,
} from "react-icons/ri";
import { HiOutlineDocumentText } from "react-icons/hi";
import { AiOutlineSafetyCertificate, AiFillCheckSquare } from "react-icons/ai";
import { FaFax } from "react-icons/fa";
import { MdPhone } from "react-icons/md";
import { GrWorkshop } from "react-icons/gr";
import { FaAmbulance } from "react-icons/fa";
import styled from "styled-components";
import { size/*, orderBy*/ } from "lodash";
import ClientAssignedFieldDisplay from "components/ClientAssignedField/ClientAssignedFieldDisplay";
import { getTotalUiniqueItems } from "components/ClientAssignedField/utils";

import { getDivisionTypeIndicator } from "features/Job/utils";
import UserItem from "common/UserItem";
import Card, { CardHeader, CardContent } from "common/Card";
import IconTextItem from "common/IconTextItem";
import CollapsibleCard from "common/CollapsibleCard";
import CollapsibleHeader from "common/CollapsibleHeader";
import MapBanner from "common/MapBanner";
import Avatar from "common/Avatar";
import LinkHeader from "common/LinkHeader";
// import WeeksJobs from "./WeeksJobs";
import Empty from "common/Empty";
import Hours from "common/Hours";
import CRM from "components/Connect/CRM";

import { getMemberDataSelector, fetchMember, fetchMemberJobsForMap } from "../memberSlice";
import {
  certificationsSelectors,
  jobDivisionTypesSelectors,
} from "features/Taxonomies/taxonomiesSlice";
import Insurance from "./Insurance";
import { formatEquipmentDiscountLine } from "components/TMTerms/utils";

const Details = styled.div`
  margin-left: 1.25rem;
  flex: 1;
`;

const Service = styled.div`
  display: flex;
  align-items: center;

  .icon {
    margin-right: 1rem;
  }
`;

const Certificate = styled.div`
  padding: 0.25rem;
`;

const ServiceType = ({ id }) => {
  const type = useSelector((state) =>
    jobDivisionTypesSelectors.selectById(state, id)
  );
  const { icon: TypeIcon, color } = getDivisionTypeIndicator(type?.name);

  return (
    <Service>
      <div className="icon" style={{ color }}>
        <TypeIcon />
      </div>
      <Typography>{type?.name}</Typography>
    </Service>
  );
};

const Certification = ({ id }) => {
  const certificate = useSelector((state) =>
    certificationsSelectors.selectById(state, id)
  );

  return (
    <Certificate>
      <Typography>{certificate?.name}</Typography>
    </Certificate>
  );
};

const Information = ({ onNavigate, inSnapshot }) => {
  const dispatch = useDispatch();
  const member = useSelector(getMemberDataSelector);
  const [shouldCollapse, setShouldCollapse] = useState(false);
  const user = useSelector((state) => state.auth.user.data);
  const roles = user?._processed?.roles ? user._processed.roles : [];
  const media = useSelector((state) => state.browser.lessThan);

  // const numServices = size(member.field_division_types);
  const numServices = getTotalUiniqueItems(member.field_jd_types);

  const numCerts = size(member.field_certifications);
  const numCounties = size(member._processed?.field_counties_served);

  useEffect(() => {
    onNavigate(false);

    return () => {
      onNavigate(true);
    };
  }, [onNavigate]);

  useEffect(() => {
    const promise = dispatch(fetchMemberJobsForMap(member.nid));

    return () => {
      promise.abort();
    };
  }, [dispatch, member]);

  const getMember = useCallback(() => {
    return dispatch(fetchMember(member.name));
  }, [member, dispatch]);

  const handleOnScroll = (e) => {
    const parent = e.target.parentElement.offsetHeight;
    const container = e.target.offsetHeight;

    if (parent - container > 160) {
      if (e.target.scrollTop > 100) {
        setShouldCollapse(true);
      } else {
        setShouldCollapse(false);
      }
    }

    if (shouldCollapse && e.target.scrollTop < 10) {
      setShouldCollapse(false);
    }
  };

  return (
    <div style={{ flex: 1, overflow: "auto" }} onScroll={handleOnScroll}>
      <CollapsibleHeader collapse={shouldCollapse}>
        <Avatar
          title={member.title}
          link={member?.field_logo?.link}
          size={shouldCollapse ? "small" : "large"}
          outline
          type="org"
        />
        <Details>
          <LinkHeader to={member.path} color="secondary" size="medium">
            {member.title}
          </LinkHeader>
          <Collapse in={!shouldCollapse}>
            <Link
              target="_blank"
              href={member.field_website?.uri}
              variant="body1"
            >
              {member.field_website?.uri}
            </Link>
            {(!member.field_office_id && member?._processed?.limited_view_access) && (
              <Typography>{`Office ID: ${member.field_office_id}`}</Typography>
            )}
          </Collapse>
        </Details>
        <Hidden mdDown>
          <CollapsibleCard
            label="Services"
            value={numServices}
            collapse={shouldCollapse}
          >
            <RiServiceLine />
          </CollapsibleCard>
          {(!member?._processed?.limited_view_access &&
            <>
              <CollapsibleCard
                label="Certs"
                value={numCerts}
                collapse={shouldCollapse}
              >
                <AiOutlineSafetyCertificate />
              </CollapsibleCard>
              <CollapsibleCard
                label="Counties"
                value={numCounties}
                collapse={shouldCollapse}
              >
                <RiMapPin2Line />
              </CollapsibleCard>
            </>
          )}
        </Hidden>
      </CollapsibleHeader>
      <MapBanner
        geo={{
          lat: member?.field_street_address_geo?.lat,
          lng: member?.field_street_address_geo?.lon,
        }}
        onScroll={handleOnScroll}
        address1={member.field_street_address?.address_line1}
        address2={member.field_street_address?.address_line2}
        city={member.field_street_address?.locality}
        state={member.field_street_address?.administrative_area}
        zip={member.field_street_address?.postal_code}
      >
        <Grid container spacing={3}>
          <Grid item xxs={12} md={8} xl={9} className="order-1 grid-md:order-none">
            {!inSnapshot && member?._processed?.member_ops_access && (
              <Card>
                <CardHeader title="Contact History" />
                <CardContent className="!p-0">
                  <CRM
                    memberFields={member}
                    media={media}
                    roles={roles}
                    showSnackbar={() => {}}
                    processed={member._processed}
                    refreshMember={getMember}
                    shortList
                  />
                </CardContent>
              </Card>
            )}
            {/* {!member?._processed?.limited_view_access && (
              <WeeksJobs nid={member.nid} />
            )} */}
            {!member?._processed?.limited_view_access && <Insurance />}
            <Card>
              <CardHeader title="Services" />
              <CardContent>
                <Grid container spacing={3}>
                  {numServices > 0 ? (
                    <ClientAssignedFieldDisplay
                      entity={member}
                      field="field_jd_types"
                      clientDisplay={(client) => (
                        <Grid item xxs={12}>
                          <Typography variant="subtitle">{client}</Typography>
                        </Grid>
                      )}
                      display={(item) => (
                        <Grid key={`field_jd_types-${item.phx_client_nid}-${item.target_id}`} item xxs={12} sm={6} md={4}>
                          <ServiceType id={item.target_id} />
                        </Grid>
                      )}
                      emptyMessage={
                        <Grid item xxs={12} className="!pt-0 !pl-8">
                          <Typography variant="subtitle2">Services not Set</Typography>
                        </Grid>
                      }
                    />
                  ) : (
                    <Empty message="No services added">
                      <GrWorkshop />
                    </Empty>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xxs={12} md={4} xl={3}>
            <Card>
              <CardHeader title="Contact" />
              <CardContent>
                {member.field_phone && (
                  <IconTextItem icon={<MdPhone />}>
                    <Typography
                      variant="subtitle2"
                      style={{ textTransform: "uppercase" }}
                      color="textSecondary"
                    >
                      Phone
                    </Typography>
                    <Typography variant="body1">
                      {member.field_phone}
                    </Typography>
                  </IconTextItem>
                )}
                {member.field_phone_after_hours && (
                  <IconTextItem icon={<MdPhone />}>
                    <Typography
                      variant="subtitle2"
                      style={{ textTransform: "uppercase" }}
                      color="textSecondary"
                    >
                      After Hours
                    </Typography>
                    <Typography variant="body1">
                      {member.field_phone_after_hours}
                    </Typography>
                  </IconTextItem>
                )}
                {member.field_fax && (
                  <IconTextItem icon={<FaFax />}>
                    <Typography
                      variant="subtitle2"
                      style={{ textTransform: "uppercase" }}
                      color="textSecondary"
                    >
                      Fax
                    </Typography>
                    <Typography variant="body1">{member.field_fax}</Typography>
                  </IconTextItem>
                )}
                {(member?._processed?.limited_view_access && member.field_video_call_url?.uri) && (
                  <IconTextItem icon={<RiVideoChatFill />}>
                    <Typography
                      variant="subtitle2"
                      style={{ textTransform: "uppercase" }}
                      color="textSecondary"
                    >
                      Meeting Room
                    </Typography>
                    <LinkHeader
                      to={member.field_video_call_url?.uri}
                      size="small"
                      color="default"
                    >
                      {member.field_video_call_url?.uri}
                    </LinkHeader>
                  </IconTextItem>
                )}
                {member.field_hours && (
                  <>
                  <Hours data={member.field_hours} weekStart={member.field_week_start} />
                  <Divider style={{ margin: "1rem 0" }} />
                  </>
                )}
                <IconTextItem icon={<RiContactsBookLine />}>
                  <Typography
                    variant="subtitle2"
                    style={{ textTransform: "uppercase" }}
                    color="textSecondary"
                  >
                    Primary Contact
                  </Typography>
                  {size(member.field_primary_poc) > 0 ? (
                    member.field_primary_poc.map((user) => (
                      <UserItem user={user} key={`user-${user.uid}`} />
                    ))
                  ) : (
                    <Typography>No contact set</Typography>
                  )}
                </IconTextItem>
                <IconTextItem icon={<RiContactsBookLine />}>
                  <Typography
                    variant="subtitle2"
                    style={{ textTransform: "uppercase" }}
                    color="textSecondary"
                  >
                    Secondary Contacts
                  </Typography>
                  {size(member.field_secondary_pocs) > 0 ? (
                    member.field_secondary_pocs.map((user) => (
                      <UserItem user={user} key={`user-spoc-${user.uid}`} />
                    ))
                  ) : (
                    <Typography>No contact set</Typography>
                  )}
                </IconTextItem>
                <IconTextItem icon={<RiContactsBookLine />}>
                  <Typography
                    variant="subtitle2"
                    style={{ textTransform: "uppercase" }}
                    color="textSecondary"
                  >
                    After Hours Contacts
                  </Typography>
                  {size(member.field_after_hours_pocs) > 0 ? (
                    member.field_after_hours_pocs.map((user) => (
                      <UserItem user={user}  key={`user-ahpoc-${user.uid}`} />
                    ))
                  ) : (
                    <Typography>No contact set</Typography>
                  )}
                </IconTextItem>
                {!member?._processed?.limited_view_access && (
                  <IconTextItem icon={<RiContactsBookLine />}>
                    <Typography
                      variant="subtitle2"
                      style={{ textTransform: "uppercase" }}
                      color="textSecondary"
                    >
                      Helix Certified
                    </Typography>
                    {size(member.field_phx_certified_poc) > 0 ? (
                      member.field_phx_certified_poc.map((user) => (
                        <UserItem user={user} key={`user-cpoc-${user.uid}`} />
                      ))
                    ) : (
                      <Typography>No contact set</Typography>
                    )}
                  </IconTextItem>
                )}
                {member.field_response_time && (
                  <>
                  <Divider style={{ margin: "1rem 0" }} />
                  <IconTextItem icon={<FaAmbulance />}>
                    <Typography
                      variant="subtitle2"
                      style={{ textTransform: "uppercase" }}
                      color="textSecondary"
                    >
                      Response Time
                    </Typography>
                    <Typography variant="body1">{member.field_response_time}</Typography>
                  </IconTextItem>
                  </>
                )}
                <Divider style={{ margin: "1rem 0" }} />
                {!member?._processed?.limited_view_access && (
                  <IconTextItem icon={<RiAwardFill />}>
                    <Typography
                      variant="subtitle2"
                      style={{ textTransform: "uppercase" }}
                      color="textSecondary"
                    >
                      Certifications
                    </Typography>
                    {size(member?.field_certifications) > 0 ? (
                      member?.field_certifications?.map((type, i) => (
                        <Certification id={type.tid} key={`cert-${i}`} />
                      ))
                    ) : (
                      <Typography>No certifications</Typography>
                    )}
                  </IconTextItem>
                )}
                {(!member?._processed?.limited_view_access && member.field_daily_sheets_member) && (
                  <IconTextItem icon={<AiFillCheckSquare />}>
                    <Typography
                      variant="subtitle2"
                      style={{ textTransform: "uppercase" }}
                      color="textSecondary"
                    >
                      Daily Sheets Member
                    </Typography>
                  </IconTextItem>
                )}
                {(!member?._processed?.limited_view_access && member.field_national_contracts && member.field_national_contracts.length > 0 && member?._processed?.update_access) && (
                  <>
                  <Divider style={{ margin: "1rem 0" }} />
                  <IconTextItem icon={<HiOutlineDocumentText />}>
                    <Typography
                      variant="subtitle2"
                      style={{ textTransform: "uppercase" }}
                      color="textSecondary"
                    >
                      National Contracts
                    </Typography>
                    {member?.field_national_contracts?.map((term) => (
                      <Typography key={`cntrct-${term.tid}`}>{term.name}</Typography>
                    ))}
                  </IconTextItem>
                  </>
                )}
                {member?.field_note && (
                  <>
                  <Divider style={{ margin: "1rem 0" }} />
                  <IconTextItem icon={<HiOutlineDocumentText />}>
                    <Typography
                      variant="subtitle2"
                      style={{ textTransform: "uppercase" }}
                      color="textSecondary"
                    >
                      Notes
                    </Typography>
                    <div className="whitespace-pre-line">{member.field_note}</div>
                  </IconTextItem>
                  </>
                )}
              </CardContent>
            </Card>
            {(!member?._processed?.limited_view_access && member.field_tm_terms) && (
              <Card>
                <CardHeader title="T&M Terms" />
                <CardContent>
                  {(member.field_tm_terms?.equipment?.weekly || member.field_tm_terms?.equipment?.monthly) && (
                  <Typography
                    variant="subtitle2"
                  >
                    Equipment Discount Terms
                  </Typography>
                  )}
                  {member.field_tm_terms?.equipment?.weekly && (
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                  >
                    WEEKLY RATE - &#40;{formatEquipmentDiscountLine(member.field_tm_terms.equipment.weekly)}&#41;
                  </Typography>
                  )}
                  {member.field_tm_terms?.equipment?.monthly && (
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                  >
                    MONTHLY RATE - &#40;{formatEquipmentDiscountLine(member.field_tm_terms.equipment.monthly)}&#41;
                  </Typography>
                  )}
                </CardContent>
              </Card>
              )}
          </Grid>
        </Grid>
      </MapBanner>
    </div>
  );
};

Information.propTypes = {};

export default Information;
