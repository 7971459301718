import styled from "styled-components";
import constants from "../constants";

export const Container = styled.div`
  margin-right: 1rem;
  position: relative;
  height: ${({ theme }) => theme.height};
`;

export const Success = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${constants.colorGreen};
  background: rgba(255, 255, 255, 0.7);
`;

export const Negative = styled(Success)`
  color: ${constants.colorRed};
`;
