import React from "react";
import moment from "moment";
import {
  CellSubheading,
  CellHeading,
} from "components/Table/Styled";
import {
  getUtcOffset,
} from "utility";


const TimeDisplayCell = ({
  time,
  dateOnly,
  offSet,
  ...rest
}) => {
  let date = moment.unix(time);
  if(offSet){
    date = date.utcOffset(getUtcOffset()); // Remember, do not use this for times, (date only)
  }

  return (
    <div {...rest}>
      <CellHeading>
        {date.format("MMM Do, YYYY")}
      </CellHeading>
      {!dateOnly && (
      <CellSubheading>
        {moment.unix(time).format("h:mm A")}
      </CellSubheading>
      )}
    </div>
  );
};

TimeDisplayCell.propTypes = {};

export default TimeDisplayCell;
