import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "@mui/material";

import Dialog from "common/Dialog";
import Jobs from "features/Location/components/Jobs";

import { getLocationDataSelector } from "features/Location/locationSlice";

const LocationJobHistory = (props) => {
  const [open, setOpen] = useState(false);
  const location = useSelector(getLocationDataSelector);

  return (
    <>
      <Link onClick={() => setOpen(true)} style={{ cursor: "pointer" }}>
        View Job History
      </Link>
      <Dialog
        title={location.title}
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
      >
        <Jobs nid={location.nid} />
      </Dialog>
    </>
  );
};

LocationJobHistory.propTypes = {};

export default LocationJobHistory;
