import { getRoleType, hasPermission } from "../../utility";
import { useSelector } from "react-redux";

const Guard = ({ intendedRoles, intendedRoleTypes, customOverrideName='default', children }) => {
  const user = useSelector((state) => state.auth.user.data);
  const roles = user?._processed?.roles ? user._processed.roles : [];
  const jobsource = useSelector((state) => state.job.data.field_job_source)
  const hasAccess = intendedRoleTypes ? hasPermission([getRoleType(roles)], intendedRoleTypes) : hasPermission(roles, intendedRoles);

  const overrides = {
    hasAdminAndJobSource: () => { return hasPermission([getRoleType(roles)], ['phx_client']) && (jobsource === 'helix' || jobsource === 'client')},
    default: () => { return true },
  }
  
  if ((!hasAccess) && !(overrides[customOverrideName]())) return null;

  return children;
};

Guard.propTypes = {};

export default Guard;
