import styled from "styled-components";
import constants from "../constants";

export const CellHeading = styled.div`
  font-weight: 600;
  font-size: 1.125rem;
  color: ${constants.colorGrayDark};
  text-transform: ${({ uppercase }) =>
    uppercase ? "uppercase" : "capitalize"};
  text-overflow: ${({ noWrap }) => (noWrap ? "ellipsis" : "unset")};
  overflow: ${({ noWrap }) => (noWrap ? "hidden" : "unset")};
  white-space: ${({ noWrap }) => (noWrap ? "nowrap" : "unset")};
`;

export const CellGroup = styled.div`
  display: flex;
  align-items: center;

  > div {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const CellSubheading = styled.div`
  font-weight: 600;
  font-size: 1rem;
  color: ${constants.colorSecondary}};
  text-overflow: ${({ noWrap }) => (noWrap ? "ellipsis" : "unset")};
  overflow: ${({ noWrap }) => (noWrap ? "hidden" : "unset")};
  white-space: ${({ noWrap }) => (noWrap ? "nowrap" : "unset")};
`;

export const IsNew = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 1rem;
  background: ${({ isNew }) =>
    isNew ? constants.colorPrimary : constants.colorGray};
`;

export const LoaderContainer = styled.div`
  padding: 1rem;
`;
