import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { DialogContent, Paper, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import UpdateIcon from "@mui/icons-material/Update";
import { BiHistory } from "react-icons/bi";

import Dialog from "common/Dialog";
import Empty from "common/Empty";
import Button from "common/Button";
import { DefaultLoader } from "common/Loader";
import { getLogsLoadingSelector, logsSelectors } from "./logsSlice";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const Logs = ({ action, id, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector(getLogsLoadingSelector);
  const data = useSelector(logsSelectors.selectAll);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    let promise;
    if (action && open && id) {
      promise = dispatch(action({ id }));
    }

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [action, open, dispatch, id]);

  const formatBody = useCallback((log) => {
    if (!log) return "";
    if (log.action === "insert") return log._sub_action;

    return log.diff_unserialized && log.diff_unserialized[0]
      ? log.diff_unserialized[0]
      : null;
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        startIcon={<BiHistory />}
        color="primary"
        size="small"
        variant="contained"
        disableElevation
        onClick={() => setOpen(true)}
        {...props}
      >
        History
      </Button>
      <Dialog
        open={open}
        maxWidth="md"
        title="Activity History"
        onClose={handleClose}
      >
        <DialogContent style={{ background: "var(--color-gray-background)" }}>
          {loading ? (
            <DefaultLoader />
          ) : data.length === 0 ? (
            <Empty message="No logs to display">
              <BiHistory />
            </Empty>
          ) : (
            <Timeline position="alternate">
              {data
                .filter((l) => l.type !== "comment")
                .map((log) => (
                  <TimelineItem key={log.pid}>
                    <TimelineOppositeContent>
                      <Typography variant="body2" color="secondary">
                        {moment
                          .unix(log.datetime)
                          .format("h:mm A MMM Do, YYYY")}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot>
                        <UpdateIcon />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Paper elevation={1} className={classes.paper}>
                        {log._action && log._sub_action ? (
                          <>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              style={{ marginBottom: "1rem" }}
                            >
                              {log._sub_action}
                            </Typography>
                            {log.diff_unserialized &&
                              log.diff_unserialized.map((diff, i) => (
                                <Typography variant="body2" key={i}>
                                  {diff}
                                </Typography>
                              ))}
                          </>
                        ) : (
                          <>
                            {log.action !== "insert" && (
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                style={{ marginBottom: "1rem" }}
                              >{`${log.user_first_name} ${log.user_last_name}${
                                log.user_company
                                  ? ` from ${log.user_company}`
                                  : ""
                              } updated`}</Typography>
                            )}
                            <Typography variant="body2">
                              {formatBody(log)}
                            </Typography>
                          </>
                        )}
                      </Paper>
                    </TimelineContent>
                  </TimelineItem>
                ))}
            </Timeline>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

const { array } = PropTypes;
Logs.propTypes = {
  data: array,
};

export default Logs;
