// TODO: breakout form into it's own component and pass through as prop to make this more reusable
import React, { useState, useRef } from "react";
import { useSelector, connect } from "react-redux";
import { Formik, Form } from "formik";
import { Launch, MoreVert, GetApp, Edit, Delete } from "@mui/icons-material";
import { MenuItem, ListItemIcon, Typography } from "@mui/material";
import styled from "styled-components";

import MenuItemLoader from "../MenuItemLoader";
import { CheckboxFormField } from "common/Fields";
import constants from "../constants";
import { SelectFormField } from "common/Fields";
import Button from "common/Button";
import ButtonLoader from "common/ButtonLoader";
import FileIcon from "../FileIcon";
import config from "../../config";
import { downloadFile } from "../../actions/files/fileActions";
import { deleteParagraph, patchParagraph } from "../../actions";
import { IconButtonDropdown } from "common/ButtonDropdown";
import { formatError } from "../../utility";
import ConfirmationDialog from "../ConfirmationDialog";
import { Details, Heading, Subheading, Actions } from "./Styled";

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 1rem 1.25rem;
  border-bottom: ${constants.colorGray};
  transition: background-color 0.25s;

  &:nth-child(odd) {
    background-color: white;
  }

  &:nth-child(even) {
    background-color: ${constants.colorGrayLightest};
  }

  &:hover {
    background-color: ${constants.colorGrayLighter};
  }

  &:last-child {
    border-bottom: none;
  }
`;

const formatVisibleTo = (visibilityString, clientTitle) => {
  const roles = visibilityString.split(",");
  if (!roles) return null;
  const visibleTo = [];
  roles.forEach((role) => {
    if (role === "phx_client") {
      visibleTo.push(clientTitle);
    } else {
      visibleTo.push(role.charAt(0).toUpperCase() + role.slice(1));
    }
  });

  return visibleTo.join(", ");
};

const EditForm = ({
  updateParagraph,
  initialValues,
  clientTitle,
  options,
  onClose,
  pid,
  refreshMethod,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (data, { setSubmitting, setSuccess }) => {
        setSubmitting(true);
        const params = {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/paragraph/customer_documents`,
            },
          },
          field_cd_category: [{ target_id: data.category }],
          field_cd_visibility: [],
        };

        if (data.phx_client)
          params.field_cd_visibility.push({ value: "phx_client" });
        if (data.customer)
          params.field_cd_visibility.push({ value: "customer" });
        if (data.member) params.field_cd_visibility.push({ value: "member" });

        const response = await updateParagraph(pid, params);
        setSubmitting(false);
        if (response.status === 200) {
          if (refreshMethod) {
            refreshMethod();
          }
          onClose();
        }
      }}
    >
      {({ values, errors, isSubmitting, dirty }) => (
        <Form style={{ maxWidth: "300px" }}>
          <SelectFormField
            fullWidth
            name="category"
            margin="normal"
            size="small"
            variant="outlined"
            disabled={isSubmitting}
            options={options.map((option) => ({
              value: option.tid,
              label: option.name,
            }))}
          />
          <CheckboxFormField name="phx_client" label={clientTitle} />
          <CheckboxFormField name="member" label="Member" />
          <CheckboxFormField name="customer" label="Customer" />
          <div>
            <Button
              variant="outlined"
              type="submit"
              disableElevation
              size="small"
              onClick={onClose}
              style={{ marginRight: "1rem" }}
            >
              Cancel
            </Button>
            <ButtonLoader
              variant="contained"
              color="primary"
              type="submit"
              isSubmitting={isSubmitting}
              disabled={!dirty}
              disableElevation
              size="small"
            >
              Update
            </ButtonLoader>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const DefaultFile = ({
  file,
  pid,
  filename,
  visibility,
  subtitle,
  fid,
  getFile,
  removeParagraph,
  refreshMethod,
  clientTitle,
  options,
  categoryTid,
  updateParagraph,
  readOnly,
}) => {
  const dropdownRef = useRef(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [editing, setEditing] = useState(false);
  const [message, setMessage] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const token = useSelector((state) => state.auth.token);

  const handleDownload = async () => {
    setDownloading(true);
    await getFile(`${config.api_url}/file/${fid}`, filename, "base64");
    setDownloading(false);
    if (dropdownRef.current) {
      dropdownRef.current.close();
    }
  };

  const handleDelete = async () => {
    setSubmitting(true);
    const response = await removeParagraph(pid);
    setSubmitting(false);
    if (response.status === 204) {
      if (refreshMethod) {
        refreshMethod();
      }
    } else {
      const errorMessage = formatError(response);
      setMessage(errorMessage);
    }
  };

  const toggleEditing = () => {
    setEditing(!editing);
    if (dropdownRef.current) {
      dropdownRef.current.close();
    }
  };

  const toggleDelete = () => {
    setOpenDelete(true);
    if (dropdownRef.current) {
      dropdownRef.current.close();
    }
  };

  return (
    <Container>
      <FileIcon filename={filename} status={file.status} />
      <Details>
        {editing && !readOnly ? (
          <EditForm
            clientTitle={clientTitle}
            options={options}
            onClose={toggleEditing}
            pid={pid}
            refreshMethod={refreshMethod}
            updateParagraph={updateParagraph}
            initialValues={{
              phx_client: visibility?.indexOf("phx_client") >= 0,
              customer: visibility?.indexOf("customer") >= 0,
              member: visibility?.indexOf("member") >= 0,
              category: categoryTid,
            }}
          />
        ) : (
          <>
            {visibility && (
              <Typography variant="caption" color="textSecondary">
                Visible to {formatVisibleTo(visibility, clientTitle)}
              </Typography>
            )}
            <Heading>{filename}</Heading>
            <Subheading>{subtitle}</Subheading>
          </>
        )}
      </Details>
      <Actions>
        <IconButtonDropdown
          icon={MoreVert}
          tooltip="Actions"
          ref={dropdownRef}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            component="a"
            target="_blank"
            href={`${config.api_url}/rest/file/${fid}/view?token=${file?._file_dl_tokens?.file}&oauth_token=${token}`}
          >
            <ListItemIcon>
              <Launch fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">View</Typography>
          </MenuItem>
          <MenuItemLoader
            text="Download"
            loading={downloading}
            onClick={handleDownload}
          >
            <GetApp fontSize="small" />
          </MenuItemLoader>
          {!readOnly && (
            <>
              <MenuItem onClick={toggleEditing}>
                <ListItemIcon>
                  <Edit fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Edit</Typography>
              </MenuItem>
              <MenuItem onClick={toggleDelete}>
                <ListItemIcon>
                  <Delete fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Delete</Typography>
              </MenuItem>
            </>
          )}
        </IconButtonDropdown>
      </Actions>
      {!readOnly && (
        <ConfirmationDialog
          open={openDelete}
          onClose={() => setOpenDelete(false)}
          message={message}
          title="Are you sure"
          body="Are you sure you want to delete this file?"
          onSubmit={handleDelete}
          isSubmitting={isSubmitting}
          submitButton="Delete"
        />
      )}
    </Container>
  );
};

DefaultFile.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
  getFile: (path, name, responseType) =>
    dispatch(downloadFile(path, name, responseType)),
  removeParagraph: (params) => dispatch(deleteParagraph(params)),
  updateParagraph: (id, params) => dispatch(patchParagraph(id, params)),
});

export default connect(null, mapDispatchToProps)(DefaultFile);
