import React from "react";
import styled from "styled-components";
import { Typography } from "@mui/material";

import FileIcon from "../FileIcon";
import { formatFileSize } from "../../utility";
import constants from "../constants";

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0;
`;

const Details = styled.div`
  flex: 1;
`;

const Progress = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.5rem;
  background: ${constants.colorGrayLight};
  border-radius: 2px;
  overflow: hidden;
`;

const Bar = styled.div`
  height: 100%;
  background: ${constants.colorGreen};
  transition: width 0.25s;
`;

const EstimateFile = ({ file }) => {
  return (
    <Container>
      <FileIcon filename={file.file.name} status={file.status} />
      <Details>
        <Typography
          variant="subtitle1"
          noWrap
          style={{ maxWidth: "245px", lineHeight: "1rem" }}
        >
          {file.file.name}
        </Typography>
        <Typography variant="caption">
          {formatFileSize(file.file.size)}
        </Typography>
      </Details>
      {file.status === 1 && (
        <Progress>
          <Bar style={{ width: `${file.progress}%` }}></Bar>
        </Progress>
      )}
    </Container>
  );
};

EstimateFile.propTypes = {};

export default EstimateFile;
