import {
  createSlice,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
// import querystring from "querystring";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import { formatExpense } from "../Expense/utils";
import { formatEquipment } from "../Equipment/utils";
import { formatField } from "../../utility";
import { setManyFiles } from "../Files/filesSlice";
import { formatService } from "../Service/utils";
// import { setAlert } from "features/Alert/alertSlice";

// const getQueryParams = (params) => {
//   return `?${
//     typeof params === "string"
//       ? params.substring(1)
//       : querystring.stringify(params)
//   }`;
// };

const namespace = "dailysheet";

export const fetchDailysheet = createAsyncThunk(
  `${namespace}/fetchDailysheet`,
  async (id, { getState, signal, rejectWithValue, dispatch }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(`${config.api_url}/node/${id}`, {
        ...tokenConfig(getState),
        cancelToken: source.token,
      });

      const type = formatField(response.data, "type");

      switch (type) {
        case "ds_equipment":
          return formatEquipment(response.data);
        case "ds_service":
          return formatService(response.data);
        case "ds_expense":
          const expense = formatExpense(response.data);
          if (expense.field_ds_receipt) {
            dispatch(setManyFiles(expense.field_ds_receipt));
          }
          return expense;
        default:
          return {};
      }
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUserServiceHours = createAsyncThunk(
  `${namespace}/fetchUserServiceHours`,
  async (
    { date, id, type },
    { getState, signal, rejectWithValue, dispatch }
  ) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/daily-sheets/user/weekly-hours/${date}${(id && typeof id !== 'undefined' ? `/${id}${(type && typeof type !== 'undefined' ? `/${type}` : '/user')}` : '')}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const dailysheetSlice = createSlice({
  name: namespace,
  initialState: {
    loading: false,
    error: null,
    data: {},
    hours: {
      loading: false,
      error: null,
      data: {},
    },
  },
  reducers: {
    clearDailysheet: (state) => {
      state.data = {};
    },
  },
  extraReducers: {
    [fetchDailysheet.pending](state) {
      state.loading = true;
      state.error = null;
    },
    [fetchDailysheet.fulfilled](state, { payload: sheet }) {
      state.loading = false;
      state.error = null;
      state.data = sheet;
    },
    [fetchDailysheet.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchUserServiceHours.pending](state) {
      state.hours.error = null;
      state.hours.loading = true;
      state.hours.data = {};
    },
    [fetchUserServiceHours.fulfilled](state, { payload: hours }) {
      state.hours.loading = false;
      state.hours.data = hours;
    },
    [fetchUserServiceHours.rejected](state, action) {
      if (!action.meta.aborted) {
        state.hours.loading = false;
        if (action.payload) {
          state.hours.error = action.payload.message;
        } else {
          state.hours.error = action.error.message;
        }
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getDailySheetLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.dailysheet.loading
);

export const getDailySheetDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.dailysheet.data
);

export const getDailySheetErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.dailysheet.error
);

export const getUserHoursLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.dailysheet.hours.loading
);

export const getUserHoursDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.dailysheet.hours.data
);

export const getUserHoursErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.dailysheet.hours.error
);

export const { clearDailysheet } =
dailysheetSlice.actions;

export default dailysheetSlice.reducer;
