import {
  createSlice,
  createAsyncThunk,
  createDraftSafeSelector,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import querystring from "querystring";
import moment from "moment";
import { size, first } from "lodash";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import { formatField } from "../../utility";
import {
  formatDivisionFields,
  formatJobFields,
  formatJobDivisions,
  formatCustomerFields,
  formatLocationFields,
  formatUserFields,
  formatMemberFields,
  formatInsuranceFields,
  formatOverrideLogFields,
} from "./utils";

import { setLocationData } from "../Location/locationSlice";
import { setInsuranceData } from "../Insurance/insuranceSlice";
import {
  fetchJobProgress,
  setProgressData,
} from "../JobProgress/jobProgressSlice";
import { setMemberData } from "../Member/memberSlice";
import { setAlert } from "features/Alert/alertSlice";
import { fetchJobTasks } from "../Tasks/tasksSlice";
import {
  clearAllDailysheets,
  clearAllInvoices,
  clearOverviewData,
} from "../DailySheets/dailysheetsSlice";
import { setCustomerData } from "../Customer/customerSlice";
import { updateOneDivision } from "../Divisions/divisionsSlice";

const getQueryParams = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : querystring.stringify(params)
  }`;
};

const namespace = "job";

export const fetchJob = createAsyncThunk(
  `${namespace}/fetchJob`,
  async (nid, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(`${config.api_url}/node/${nid}`, {
        ...tokenConfig(getState),
        cancelToken: source.token,
      });

      return formatJobFields(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAvailableJobStatuses = createAsyncThunk(
  `${namespace}/fetchAvailableJobStatuses`,
  async (nid, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/job/statuses/${nid}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAvailableJobMembers = createAsyncThunk(
  `${namespace}/fetchAvailableJobMembers`,
  async (
    { location, type, closest, track, params },
    { getState, signal, rejectWithValue }
  ) => {
    try {
      const source = axios.CancelToken.source();
      const queryparams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/members/for-job/${location}/${type}${
          closest ? `/${closest}` : ""
        }${
          track ? `/${track}` : ""
        }${queryparams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        members: response.data.data,
        pagination: response.data.pagination,
        meta: response.data.meta,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMoreAvailableJobMembers = createAsyncThunk(
  `${namespace}/fetchMoreAvailableJobMembers`,
  async (
    { location, type, closest, track, params },
    { getState, signal, rejectWithValue }
  ) => {
    try {
      const source = axios.CancelToken.source();
      const queryparams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/members/for-job/${location}/${type}${
          closest ? `/${closest}` : ""
        }${
          track ? `/${track}` : ""
        }${queryparams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        members: response.data.data,
        pagination: response.data.pagination,
        meta: response.data.meta,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchJobDivisions = createAsyncThunk(
  `${namespace}/fetchJobDivisions`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/entity/node/field_job_divisions/${id}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      const divisions = response.data.field_job_divisions.map((division) =>
        formatJobDivisions(division)
      );

      return divisions;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDivision = createAsyncThunk(
  `${namespace}/fetchDivision`,
  async (name, { getState, signal, rejectWithValue, dispatch }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(`${config.api_url}/job/${name}`, {
        ...tokenConfig(getState),
        cancelToken: source.token,
      });

      const division = formatDivisionFields(response.data);
      const assignedUsers = response.data.field_assigned_users
        ? response.data.field_assigned_users.map((user) =>
            formatUserFields(user)
          )
        : [];
      const activeAssignedUsers = assignedUsers.filter((user) => user.status);
      const job = formatJobFields(
        response.data["node.job.field_job_divisions"]
      );
      const member = formatMemberFields(
        response.data.field_assigned_member?.[0]
      );
      const location = formatLocationFields(
        response.data["node.job.field_job_divisions"]
          ?.field_customer_location?.[0]
      );
      const customer = formatCustomerFields(
        response.data["node.job.field_job_divisions"]?.field_customer?.[0]
      );
      const insurance = formatInsuranceFields(
        response.data["node.job_division_insurance.field_jdi_job_division"]
      );
      const afterHoursPocsIds = member.field_after_hours_pocs;
      const primaryPocIds = member.field_primary_poc;
      const secondaryPocIds = member.field_secondary_pocs;

      dispatch(setLocationData(location));
      dispatch(setInsuranceData(insurance));
      dispatch(setMemberData(member));
      dispatch(setCustomerData(customer));

      return {
        division: {
          ...division,
          activeAssignedUsers,
          assignedUserIds: assignedUsers.map((u) => u.uid),
        },
        job,
        customer,
        location,
        insurance,
        member: {
          ...member,
          primaryPocIds: primaryPocIds.map((u) => u.uid),
          afterHoursPocsIds: afterHoursPocsIds.map((u) => u.uid),
          secondaryPocIds: secondaryPocIds.map((u) => u.uid),
        },
        users: [
          ...assignedUsers,
          ...primaryPocIds,
          ...secondaryPocIds,
          ...afterHoursPocsIds,
        ],
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDivisionSnapshot = createAsyncThunk(
  `${namespace}/fetchDivisionSnapshot`,
  async (id, { getState, signal, rejectWithValue, dispatch }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(`${config.api_url}/node/${id}`, {
        ...tokenConfig(getState),
        cancelToken: source.token,
      });

      const division = formatDivisionFields(response.data);
      const assignedUsers = response.data.field_assigned_users
        ? response.data.field_assigned_users.map((user) =>
            formatUserFields(user)
          )
        : [];
      const activeAssignedUsers = assignedUsers.filter((user) => user.status);
      const job = formatJobFields(
        response.data["node.job.field_job_divisions"]
      );
      const member = formatMemberFields(
        response.data.field_assigned_member?.[0]
      );
      const location = formatLocationFields(
        response.data["node.job.field_job_divisions"]
          ?.field_customer_location?.[0]
      );
      const customer = formatCustomerFields(
        response.data["node.job.field_job_divisions"]?.field_customer?.[0]
      );
      const insurance = formatInsuranceFields(
        response.data["node.job_division_insurance.field_jdi_job_division"]
      );
      const afterHoursPocsIds = member.field_after_hours_pocs;
      const primaryPocIds = member.field_primary_poc;
      const secondaryPocIds = member.field_secondary_pocs;

      dispatch(setLocationData(location));
      dispatch(setInsuranceData(insurance));
      dispatch(setMemberData(member));

      return {
        division: {
          ...division,
          activeAssignedUsers,
          assignedUserIds: assignedUsers.map((u) => u.uid),
        },
        job,
        customer,
        location,
        insurance,
        member: {
          ...member,
          primaryPocIds: primaryPocIds.map((u) => u.uid),
          afterHoursPocsIds: afterHoursPocsIds.map((u) => u.uid),
          secondaryPocIds: secondaryPocIds.map((u) => u.uid),
        },
        users: [
          ...assignedUsers,
          ...primaryPocIds,
          ...secondaryPocIds,
          ...afterHoursPocsIds,
        ],
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchJob = createAsyncThunk(
  `${namespace}/patchJob`,
  async ({ id, params }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/node/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/job`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      const location = formatLocationFields(
        response.data.field_customer_location?.[0]
      );
      const customer = formatCustomerFields(response.data.field_customer?.[0]);

      dispatch(setLocationData(location));
      dispatch(setCustomerData(customer));

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully updated job",
        })
      );

      return formatJobFields(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error updating job: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchJobDivision = createAsyncThunk(
  `${namespace}/patchJobDivision`,
  async ({ id, params, division, meta }, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(updateOneDivision({ id, changes: { saving: true } }));
      const formattedParams = {
        ...params,
      };

      if (size(params.field_estimator) > 0) {
        const estimator = first(params.field_estimator);
        formattedParams.field_estimator = [{ target_id: estimator.uid }];
      }

      const _meta = (meta ? meta : {});
      const response = await axios.patch(
        `${config.api_url}/node/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/job_division`,
            },
          },
          ...formattedParams,
          _meta: {
            ..._meta,
          },
        },
        tokenConfig(getState)
      );

      const division = formatDivisionFields(response.data);
      const assignedUsers = response.data.field_assigned_users
        ? response.data.field_assigned_users.map((user) =>
            formatUserFields(user)
          )
        : [];
      const activeAssignedUsers = assignedUsers.filter((user) => user.status);
      const member = formatMemberFields(
        response.data.field_assigned_member?.[0]
      );
      const afterHoursPocsIds = member.field_after_hours_pocs;
      const primaryPocIds = member.field_primary_poc;
      const secondaryPocIds = member.field_secondary_pocs;

      dispatch(setMemberData(member));
      dispatch(fetchJobProgress(id));
      dispatch(updateOneDivision({ id, changes: { saving: false } }));

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully updated division",
        })
      );

      return {
        division: {
          ...division,
          activeAssignedUsers,
          assignedUserIds: assignedUsers.map((u) => u.uid),
        },
        users: [
          ...assignedUsers,
          ...primaryPocIds,
          ...secondaryPocIds,
          ...afterHoursPocsIds,
        ],
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(updateOneDivision({ id, changes: { saving: false } }));
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error updating location: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchJobPriceList = createAsyncThunk(
  `${namespace}/patchJobPriceList`,
  async (params, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/job/price-list/change`,
        params,
        tokenConfig(getState)
      );

      dispatch(clearAllDailysheets());
      dispatch(clearAllInvoices());
      dispatch(clearOverviewData());

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully changed price list",
        })
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error changing price list: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchJobDivsionAssignPM = createAsyncThunk(
  `${namespace}/patchJobDivsionAssignPM`,
  async ({ params, division }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/job/progress/assign-pm`,
        params,
        tokenConfig(getState)
      );

      dispatch(fetchJobProgress(division.nid));
      dispatch(fetchJobTasks({ id: division.nid }));

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully assigned PM",
        })
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error assigning PM: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchProjectManagerConfirmation = createAsyncThunk(
  `${namespace}/patchProjectManagerConfirmation`,
  async ({ params, division }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/job/progress/set-pm-confirmation`,
        params,
        tokenConfig(getState)
      );

      dispatch(fetchJobProgress(division.nid));

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully confirmed PM",
        })
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error confirming PM: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchCrewConfirmation = createAsyncThunk(
  `${namespace}/patchCrewConfirmation`,
  async ({ params, division }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/job/progress/set-crew-confirmation`,
        params,
        tokenConfig(getState)
      );

      dispatch(fetchJobProgress(division.nid));

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully confirmed Crew",
        })
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error confirming Crew: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchProjectManagerDeclination = createAsyncThunk(
  `${namespace}/patchProjectManagerDeclination`,
  async ({ params, division }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/job/progress/set-pm-declined`,
        params,
        tokenConfig(getState)
      );

      dispatch(fetchJobProgress(division.nid));

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully declined PM",
        })
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error declining PM: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchCrewDeclination = createAsyncThunk(
  `${namespace}/patchCrewDeclination`,
  async ({ params, division }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/job/progress/set-crew-declined`,
        params,
        tokenConfig(getState)
      );

      dispatch(fetchJobProgress(division.nid));

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully declined Crew",
        })
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error declining Crew: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchSendCustomerVideo = createAsyncThunk(
  `${namespace}/patchSendCustomerVideo`,
  async (
    { params, division, status },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/job/mf/send-customer-video`,
        params,
        tokenConfig(getState)
      );

      if (status) {
        dispatch(setStatus(status));
      }
      dispatch(fetchJobProgress(division.nid));

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully sent video",
        })
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error sending video: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchMarkWetAreas = createAsyncThunk(
  `${namespace}/patchMarkWetAreas`,
  async ({ params, division }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/job/mf/set-marked-wet-spots`,
        params,
        tokenConfig(getState)
      );

      dispatch(fetchJobProgress(division.nid));

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully marked wet areas",
        })
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error marking wet areas: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchSetJobSecured = createAsyncThunk(
  `${namespace}/patchSetJobSecured`,
  async (
    { params, division, status },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/job/progress/set-job-secured`,
        params,
        tokenConfig(getState)
      );

      if (status) {
        dispatch(setStatus(status));
      }
      dispatch(fetchJobProgress(division.nid));

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully secured job",
        })
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error securing job: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchSetPlatinumPacket = createAsyncThunk(
  `${namespace}/patchSetPlatinumPacket`,
  async ({ params, division }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/job/mf/set-platinum-packet`,
        params,
        tokenConfig(getState)
      );

      dispatch(fetchJobProgress(division.nid));

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully completed",
        })
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error providing packet: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchSetCustomerSurveyCard = createAsyncThunk(
  `${namespace}/patchSetCustomerSurveyCard`,
  async ({ params, division }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/job/mf/set-cust-survey-card`,
        params,
        tokenConfig(getState)
      );

      dispatch(fetchJobProgress(division.nid));

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully completed",
        })
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error completing customer survey card: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchSetFlorSamples = createAsyncThunk(
  `${namespace}/patchSetFloorSamples`,
  async ({ params, division }, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(setProgressData({ floor_samples_timestamp: "1" }));
      const response = await axios.patch(
        `${config.api_url}/rest/job/mf/set-floor-samples`,
        params,
        tokenConfig(getState)
      );

      dispatch(fetchJobProgress(division.nid));

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully completed",
        })
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error completing floor samples: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchSendCustomerSurvey = createAsyncThunk(
  `${namespace}/patchSendCustomerSurvey`,
  async ({ params, division }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/job/mf/send-customer-survey`,
        params,
        tokenConfig(getState)
      );

      dispatch(fetchJobProgress(division.nid));

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully completed",
        })
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error completing customer survey: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchSetReferralFeePaid = createAsyncThunk(
  `${namespace}/patchSetReferralFeePaid`,
  async ({ params, division }, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(setProgressData({ referral_fee_paid_timestamp: "1" }));
      const response = await axios.patch(
        `${config.api_url}/rest/job/mf/set-referral-fee-paid`,
        params,
        tokenConfig(getState)
      );

      dispatch(fetchJobProgress(division.nid));

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully completed",
        })
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error completing customer survey: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchSetEmailInsAgent = createAsyncThunk(
  `${namespace}/patchSetEmailInsAgent`,
  async ({ params, division }, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(setProgressData({ emailed_ins_agent_timestamp: "1" }));
      const response = await axios.patch(
        `${config.api_url}/rest/job/mf/set-emailed-ins-agent`,
        params,
        tokenConfig(getState)
      );

      dispatch(fetchJobProgress(division.nid));

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully completed",
        })
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error completing email insurance agent: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchSetUpdatedCRM = createAsyncThunk(
  `${namespace}/patchSetUpdatedCRM`,
  async ({ params, division }, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(setProgressData({ updated_crm_timestamp: "1" }));
      const response = await axios.patch(
        `${config.api_url}/rest/job/mf/set-updated-crm`,
        params,
        tokenConfig(getState)
      );

      dispatch(fetchJobProgress(division.nid));

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully completed",
        })
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error completing update CRM: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchSetCustomerCall = createAsyncThunk(
  `${namespace}/patchSetCustomerCall`,
  async ({ params, division }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/job/mf/set-customer-call`,
        params,
        tokenConfig(getState)
      );

      dispatch(fetchJobProgress(division.nid));

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully completed",
        })
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error completing customer call: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchSetInvoiceSent = createAsyncThunk(
  `${namespace}/patchSetInvoiceSent`,
  async ({ params, division }, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(setProgressData({ invoiced_timestamp: "1" }));
      const response = await axios.patch(
        `${config.api_url}/rest/job/progress/set-invoiced`,
        params,
        tokenConfig(getState)
      );

      dispatch(fetchJobProgress(division.nid));

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully completed",
        })
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error completing invoice sent: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchSetPaymentReceivedStatus = createAsyncThunk(
  `${namespace}/patchSetPaymentReceivedStatus`,
  async (
    { params, division, direction, status },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(setProgressData({ paid_timestamp: "1" }));
      const response = await axios.patch(
        `${config.api_url}/rest/job/progress/${direction}`,
        params,
        tokenConfig(getState)
      );

      if (status) {
        dispatch(setStatus(status));
      }
      dispatch(fetchJobProgress(division.nid));

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully completed",
        })
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error completing payment received: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteDocument = createAsyncThunk(
  `${namespace}/deleteDocument`,
  async ({ id, key, division }, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(setProgressData({ [key]: null }));
      const response = await axios.delete(
        `${config.api_url}/entity/paragraph/${id}`,
        tokenConfig(getState)
      );

      dispatch(fetchJobProgress(division.nid));

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully removed document",
        })
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error removing document: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postDirectPayForm = createAsyncThunk(
  `${namespace}/postDirectPayForm`,
  async ({ params, division }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/entity/paragraph`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/paragraph/direct_pay_auth`,
            },
          },
          _meta: {
            parent_entity: "node",
            parent_field: "field_jd_direct_pay_auth",
            parent_id: division.nid,
          },
          ...params,
        },
        tokenConfig(getState)
      );

      dispatch(fetchJobProgress(division.nid));

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postCertificateOfSatisfaction = createAsyncThunk(
  `${namespace}/postCertificateOfSatisfaction`,
  async (
    { params, division, status },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axios.post(
        `${config.api_url}/entity/paragraph`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/paragraph/certificate_of_completion`,
            },
          },
          _meta: {
            parent_entity: "node",
            parent_field: "field_jd_coc",
            parent_id: division.nid,
          },
          ...params,
        },
        tokenConfig(getState)
      );

      if (status) {
        dispatch(setStatus(status));
      }
      dispatch(fetchJobProgress(division.nid));

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postWorkAuthorizationForm = createAsyncThunk(
  `${namespace}/postWorkAuthorizationForm`,
  async ({ params, division }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/entity/paragraph`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/paragraph/mf_work_auth`,
            },
          },
          _meta: {
            parent_entity: "node",
            parent_field: "field_mf_work_authorization",
            parent_id: division.nid,
          },
          ...params,
        },
        tokenConfig(getState)
      );

      dispatch(fetchJobProgress(division.nid));

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postCustomerInfoSheet = createAsyncThunk(
  `${namespace}/postCustomerInfoSheet`,
  async ({ params, division }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/entity/paragraph`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/paragraph/mf_customer_info_sheet`,
            },
          },
          _meta: {
            parent_entity: "node",
            parent_field: "field_jd_mf_customer_info_sheet",
            parent_id: division.nid,
          },
          ...params,
        },
        tokenConfig(getState)
      );

      dispatch(fetchJobProgress(division.nid));

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchSetReferralFollowup = createAsyncThunk(
  `${namespace}/patchReferralFollowup`,
  async ({ params, division }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/job/mf/set-referrer-followup`,
        params,
        tokenConfig(getState)
      );

      dispatch(fetchJobProgress(division.nid));

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully completed",
        })
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error completing customer survey card: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchClockIn = createAsyncThunk(
  `${namespace}/patchClockIn`,
  async (params, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/time-entry/clock-in-out`,
        params,
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error clocking in: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchClockOut = createAsyncThunk(
  `${namespace}/patchClockOut`,
  async (params, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/time-entry/clock-in-out`,
        params,
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error clocking out: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchAddHold = createAsyncThunk(
  `${namespace}/patchAddHold`,
  async (
    { params, division, user },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/job/hold`,
        params,
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error adding hold: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchRemoveHold = createAsyncThunk(
  `${namespace}/patchRemoveHold`,
  async ({ params, division }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/job/hold`,
        params,
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error removing hold: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const assignUsers = createAsyncThunk(
  `${namespace}/assignUsers`,
  async (
    { id, currentUsers, newUsers },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      await axios.patch(
        `${config.api_url}/node/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/job_division`,
            },
          },
          _meta: {
            job_division_nid: id,
          },
          field_assigned_users: [
            ...currentUsers.map((user) => ({ target_id: user.uid })),
            ...newUsers.map((user) => ({ target_id: user.uid })),
          ],
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully updated users",
        })
      );

      return [...currentUsers, ...newUsers];
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error updating users: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// export const fetchAuditRequirements = createAsyncThunk(
//   `${namespace}/fetchAuditRequirements`,
//   async (nid, { getState, signal, rejectWithValue }) => {
//     try {
//       const source = axios.CancelToken.source();

//       signal.addEventListener("abort", () => {
//         source.cancel();
//       });

//       const response = await axios.get(
//         `${config.api_url}/rest/job/audit_requirements/${nid}`,
//         {
//           ...tokenConfig(getState),
//           cancelToken: source.token,
//         }
//       );

//       return response.data;
//     } catch (err) {
//       let error = err; // cast the error for access
//       if (!error.response) {
//         throw err;
//       }
//       // We got validation errors, let's return those so we can reference in our component and set form errors
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

export const fetchOverview = createAsyncThunk(
  `${namespace}/fetchOverview`,
  async (id, { getState, signal, dispatch, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/jobs/pdf/${id}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: response?.data?.message,
        })
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error getting overview: ${error.response.data?.message}`,
        })
      );

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchFmPilotStatus = createAsyncThunk(
  `${namespace}/fetchFmPilotStatus`,
  async (id, { getState, signal, dispatch, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/fm-pilot-work-order/${id}?_format=hal_json`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return {
        status: response.data.Status,
        description: response.data.Description,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postJob = createAsyncThunk(
  `${namespace}/postJob`,
  async (params, { dispatch, getState, signal, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/node`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/job`,
            },
          },
          title: [{ value: "temp" }],
          ...params,
        },
        tokenConfig(getState)
      );

      return formatJobFields(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error creating Job: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postJobDivision = createAsyncThunk(
  `${namespace}/postJobDivision`,
  async (
    { id, params, meta },
    { dispatch, getState, signal, rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${config.api_url}/node`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/job_division`,
            },
          },
          _meta: {
            job_nid: id,
            ...meta,
          },
          title: [{ value: "temp" }],
          ...params,
        },
        tokenConfig(getState)
      );

      return formatDivisionFields(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error creating Job Division: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postDuplicateJobOverride = createAsyncThunk(
  `${namespace}/postDuplicateJobOverride`,
  async (params, { dispatch, getState, signal, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/entity/override_log_entry`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/override_log_entry/override_log_entry`,
            },
          },
          name: [
            {
              value: "Log entry: Initialized",
            },
          ],
          ...params,
        },
        tokenConfig(getState)
      );

      return formatOverrideLogFields(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchMemberDecline = createAsyncThunk(
  `${namespace}/patchMemberDecline`,
  async (params, { dispatch, getState, signal, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/job/progress/set-member-declined`,
        params,
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postJobCustomerWelcome = createAsyncThunk(
  `${namespace}/postJobCustomerWelcome`,
  async (params, { dispatch, getState, signal, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/rest/job/customer/welcome`,
        params,
        tokenConfig(getState)
      );

      const job = formatJobFields(
        response.data
      );
      const customer = formatCustomerFields(
        response.data?.field_customer?.[0]
      );

      dispatch(setJobData({job, customer}));
      dispatch(setCustomerData(customer));

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: `<div>
            ${response.data._success_messages.map(
              (msg) => `<div>${msg}</div>`
            ).join('')}
          </div>`,
        })
      );

      return {
        job,
        customer,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const usersAdapter = createEntityAdapter({
  selectId: (user) => user.uid,
});

const statusAdapter = createEntityAdapter({
  selectId: (status) => status.tid,
});

const divisionsAdapter = createEntityAdapter({
  selectId: (division) => division.nid,
});

const membersAdapter = createEntityAdapter({
  selectId: (member) => member.member_nid,
});

const jobSlice = createSlice({
  name: namespace,
  initialState: {
    loading: false,
    data: {},
    division: {
      data: {},
    },
    customer: {
      data: {},
    },
    location: {
      data: {},
    },
    users: usersAdapter.getInitialState(),
    divisions: divisionsAdapter.getInitialState({
      loading: false,
    }),
    auditRequirements: {
      loading: false,
      data: {},
    },
    availableStatuses: statusAdapter.getInitialState({
      loading: false,
    }),
    availableMembers: membersAdapter.getInitialState({
      loading: false,
      pagination: { count: 0, current_page: 0, total_pages: 0 },
    }),
    clockedInState: {
      time_entry_has_access: false,
      time_entry_auto_clock_in: false,
      time_entry_clocked_in: false,
      time_entry_clocked_in_time: 0,
    },
    fmPilot: {
      status: null,
      description: null,
    },
  },
  reducers: {
    setJobData: (state, { payload: {job, customer} }) => {
      state.data = job;
      state.customer.data = customer;
    },
    setStatus: (state, { payload: status }) => {
      state.division.data.field_jd_status = status;
    },
    removeAvailableMember: (state, action) => {
      membersAdapter.removeOne(state.availableMembers, action);
    },
    setPLUpdateReview: (state, { payload: status }) => {
      state.division.data._processed.invoice_pl_update_review = status;
    },
  },
  extraReducers: {
    [fetchJob.pending](state) {
      state.error = null;
      state.loading = true;
    },
    [fetchJob.fulfilled](state, { payload: job }) {
      state.error = null;
      state.loading = false;
      state.data = job;
    },
    [fetchJob.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchDivision.pending](state) {
      state.error = null;
      state.loading = true;
    },
    [fetchDivision.fulfilled](
      state,
      { payload: { division, job, customer, location, member, users } }
    ) {
      state.loading = false;
      state.division.data = division;
      state.division.member = member;
      state.customer.data = customer;
      state.location.data = location;
      state.data = job;
      state.clockedInState = {
        time_entry_has_access: division._processed.time_entry_has_access,
        time_entry_auto_clock_in: division._processed.time_entry_auto_clock_in,
        time_entry_clocked_in: division._processed.time_entry_clocked_in,
        time_entry_clocked_in_time:
          division._processed.time_entry_clocked_in_time,
      };

      usersAdapter.setAll(state.users, users);
    },
    [fetchDivision.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchDivisionSnapshot.pending](state) {
      state.loading = true;
    },
    [fetchDivisionSnapshot.fulfilled](
      state,
      { payload: { division, job, customer, location, member, users } }
    ) {
      state.loading = false;
      state.division.data = division;
      state.division.member = member;
      state.customer.data = customer;
      state.location.data = location;
      state.data = job;

      usersAdapter.setAll(state.users, users);
    },
    [fetchDivisionSnapshot.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchJobDivisions.pending](state) {
      state.divisions.loading = true;
    },
    [fetchJobDivisions.fulfilled](state, { payload: divisions }) {
      state.divisions.loading = false;
      divisionsAdapter.setAll(state.divisions, divisions);
    },
    [fetchJobDivisions.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchAvailableJobMembers.pending](state) {
      state.availableMembers.loading = true;
    },
    [fetchAvailableJobMembers.fulfilled](
      state,
      { payload: { members, pagination } }
    ) {
      state.availableMembers.loading = false;
      state.availableMembers.pagination = pagination;
      membersAdapter.setAll(state.availableMembers, members);
    },
    [fetchAvailableJobMembers.rejected](state, action) {
      if (!action.meta.aborted) {
        state.availableMembers.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchMoreAvailableJobMembers.fulfilled](
      state,
      { payload: { members, pagination } }
    ) {
      state.availableMembers.pagination = pagination;
      membersAdapter.addMany(state.availableMembers, members);
    },
    [fetchMoreAvailableJobMembers.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchFmPilotStatus.fulfilled](
      state,
      { payload: { status, description } }
    ) {
      state.fmPilot.status = status;
      state.fmPilot.description = description;
    },
    // [fetchAuditRequirements.pending](state) {
    //   state.auditRequirements.loading = true;
    // },
    // [fetchAuditRequirements.fulfilled](state, { payload: requirements }) {
    //   state.auditRequirements.loading = false;
    //   state.auditRequirements.data = requirements;
    // },
    // [fetchAuditRequirements.rejected](state, action) {
    //   if (!action.meta.aborted) {
    //     state.auditRequirements.loading = false;
    //     if (action.payload) {
    //       state.error = action.payload.message;
    //     } else {
    //       state.error = action.error.message;
    //     }
    //   }
    // },
    [fetchAvailableJobStatuses.pending](state) {
      state.availableStatuses.loading = true;
    },
    [fetchAvailableJobStatuses.fulfilled](state, { payload: statuses }) {
      state.availableStatuses.loading = false;
      statusAdapter.setAll(state.availableStatuses, statuses);
    },
    [fetchAvailableJobStatuses.rejected](state, action) {
      if (!action.meta.aborted) {
        state.availableStatuses.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [patchJob.pending](state, action) {
      const { params } = action.meta.arg;
      const tempParams = { ...params };
      Object.keys(tempParams).forEach((key) => {
        tempParams[key] = formatField(tempParams, key);
      });

      state.data = {
        ...state.data,
        ...tempParams,
      };
    },
    [patchJob.fulfilled](state, { payload: job }) {
      state.data = job;
    },
    [patchJob.rejected](state, action) {
      const { job } = action.meta.arg;

      state.data = job;
    },
    [patchJobDivision.pending](state, action) {
      const { params } = action.meta.arg;
      const tempParams = { ...params };
      Object.keys(tempParams).forEach((key) => {
        if (
          key !== "field_invoice_package_file_order" ||
          key !== "field_source_loss" ||
          key !== "field_jd_personnel" ||
          key !== "field_ds_override"
        ) {
          tempParams[key] = formatField(tempParams, key);
        }
      });

      if (tempParams.field_invoice_package_file_order) {
        delete tempParams.field_invoice_package_file_order;
      }

      if (tempParams.field_jd_personnel) {
        delete tempParams.field_jd_personnel;
      }

      if (tempParams.field_source_loss) {
        delete tempParams.field_source_loss;
      }

      if (tempParams.field_ds_override) {
        delete tempParams.field_ds_override;
      }

      state.division.data = {
        ...state.division.data,
        ...tempParams,
        field_last_update: moment().format(),
      };
    },
    [patchJobDivision.fulfilled](state, { payload: { division, users } }) {
      state.division.data = division;

      usersAdapter.setAll(state.users, users);
    },
    [patchJobDivision.rejected](state, action) {
      const { division } = action.meta.arg;

      state.division.data = division;
      // if (action.payload) {
      //   state.error = action.payload.message;
      // } else {
      //   state.error = action.error.message;
      // }
    },
    [patchAddHold.pending](state, action) {
      const { params, user } = action.meta.arg;

      state.division.data = {
        ...state.division.data,
        field_hold_timestamp: moment().format("X"),
        field_hold_reason: params.reason,
        field_hold_user: [user],
        field_last_update: moment().format(),
      };
    },
    [patchAddHold.rejected](state, action) {
      const { division } = action.meta.arg;

      state.division.data = division;
    },
    [patchRemoveHold.pending](state, action) {
      state.division.data = {
        ...state.division.data,
        field_hold_timestamp: null,
        field_hold_reason: null,
        field_hold_user: null,
        field_last_update: moment().format(),
      };
    },
    [patchRemoveHold.rejected](state, action) {
      const { division } = action.meta.arg;

      state.division.data = division;
    },
    [patchClockIn.fulfilled](state, { payload: division }) {
      state.clockedInState = {
        time_entry_has_access: division._processed.time_entry_has_access,
        time_entry_auto_clock_in: division._processed.time_entry_auto_clock_in,
        time_entry_clocked_in: division._processed.time_entry_clocked_in,
        time_entry_clocked_in_time:
          division._processed.time_entry_clocked_in_time,
      };
    },
    [patchJobDivsionAssignPM.pending](state, action) {
      const { user } = action.meta.arg;
      state.division.data = {
        ...state.division.data,
        field_jd_personnel: [
          ...state.division.data.field_jd_personnel,
          {
            ...user,
            uid: Number(user.uid),
            type: 'pm',
            full_name: `${user.first_name} ${user.last_name}`,
          },
        ],
        // field_coordinator: [
        //   {
        //     ...user,
        //     field_title_position: user.title_position,
        //     field_phone: user.phone,
        //     first: user.first_name,
        //     last: user.last_name,
        //     name: `${user.first_name} ${user.last_name}`,
        //     uid: user.uid,
        //     phone: user.phone,
        //     fieldProfilePic: user.profile_pic,
        //     path: user.userPath,
        //   },
        // ],
        field_last_update: moment().format(),
      };
    },
    [patchJobDivsionAssignPM.rejected](state, action) {
      const { division } = action.meta.arg;

      state.division.data = division;
      // if (action.payload) {
      //   state.error = action.payload.message;
      // } else {
      //   state.error = action.error.message;
      // }
    },
    [assignUsers.pending](state, action) {
      const { newUsers, currentUsers } = action.meta.arg;
      const users = [...newUsers, ...currentUsers];
      usersAdapter.addMany(
        state.users,
        users.map((user) => ({ ...user, uid: Number(user.uid) }))
      );
      state.division.data.assignedUserIds = users.map((user) =>
        Number(user.uid)
      );
      state.division.data.activeAssignedUsers = users.filter(
        (user) => user.status
      );
    },
    [assignUsers.rejected](state, action) {
      const { currentUsers } = action.meta.arg;

      usersAdapter.addMany(
        state.users,
        currentUsers.map((user) => ({ ...user, uid: Number(user.uid) }))
      );
      state.division.data.assignedUserIds = currentUsers.map((user) =>
        Number(user.uid)
      );
      state.division.data.activeAssignedUsers = currentUsers.filter(
        (user) => user.status
      );
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
  },
});

// Custom selectors
const selectSelf = (state) => state;
export const getJobDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.job.data
);

export const getJobLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.job.loading
);

export const getAvailableStatusLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.job.availableStatuses.loading
);

export const getJobErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.job.error
);

export const getFmPilotSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.job.fmPilot
);

export const getDivisionDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.job.division.data
);

export const getDivisionClockedInStateSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.job.clockedInState
);

export const getDivisionAssignedUsersDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.job.division.data.assignedUserIds
);

export const getMemberDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.job.division.member
);

export const getCustomerDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.job.customer.data
);

export const getAvailableMembersLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.job.availableMembers.loading
);

export const getAvailableMembersPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.job.availableMembers.pagination
);

export const getLocationDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.job.location.data
);

// export const getAuditRequirementsSelector = createDraftSafeSelector(
//   selectSelf,
//   (state) => state.job.auditRequirements
// );

export const usersSelectors = usersAdapter.getSelectors(
  (state) => state.job.users
);

export const jobDivisionsSelectors = divisionsAdapter.getSelectors(
  (state) => state.job.divisions
);

export const availableStatusSelectors = statusAdapter.getSelectors(
  (state) => state.job.availableStatuses
);

export const availableMembersSelectors = membersAdapter.getSelectors(
  (state) => state.job.availableMembers
);

export const getInvoicesPLUpdateReviewSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.job.division?.data?._processed?.invoice_pl_update_review
);

export const { removeAvailableMember, setJobData, setStatus, setPLUpdateReview } = jobSlice.actions;

export default jobSlice.reducer;
