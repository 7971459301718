import React from "react";
import styled from "styled-components";
import { Typography } from "@mui/material";

import { SelectFormField } from "common/Fields";
import { CheckboxFormField } from "common/Fields";
import FileIcon from "../FileIcon";
import { formatFileSize } from "../../utility";
import constants from "../constants";

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid ${constants.colorGray};
  border-radius: ${constants.borderRadius};
`;

const Details = styled.div`
  flex: 1;
`;

const Progress = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.5rem;
  background: ${constants.colorGrayLight};
  border-radius: 2px;
  overflow: hidden;
`;

const Bar = styled.div`
  height: 100%;
  background: ${constants.colorGreen};
  transition: width 0.25s;
`;

const DefaultFileFormField = ({
  index,
  file,
  fileProgress,
  clientTitle,
  isSubmitting,
  options,
}) => {
  const currentFileProgress = fileProgress[file.id]?.progress;
  const currentFileStatus = fileProgress[file.id]?.status;

  return (
    <Container>
      <FileIcon filename={file.file.name} status={file.status} />
      <Details>
        <Typography
          variant="subtitle1"
          noWrap
          style={{ maxWidth: "215px", lineHeight: "1rem" }}
        >
          {file.file.name}
        </Typography>
        <Typography variant="caption">
          {formatFileSize(file.file.size)}
        </Typography>
        <SelectFormField
          fullWidth
          name={`files.${index}.category`}
          margin="normal"
          size="small"
          variant="outlined"
          disabled={isSubmitting || currentFileStatus !== 2}
          options={options.map((option) => ({
            value: option.tid,
            label: option.name,
          }))}
        />
        <div>
          <CheckboxFormField
            disabled={isSubmitting || currentFileStatus !== 2}
            name={`files.${index}.phx_client`}
            label={clientTitle}
          />
          <CheckboxFormField
            name={`files.${index}.member`}
            label="Member"
            disabled={isSubmitting || currentFileStatus !== 2}
          />
          <CheckboxFormField
            name={`files.${index}.customer`}
            disabled={isSubmitting || currentFileStatus !== 2}
            label="Customer"
          />
        </div>
      </Details>
      {currentFileStatus === 1 && (
        <Progress>
          <Bar style={{ width: `${currentFileProgress}%` }}></Bar>
        </Progress>
      )}
    </Container>
  );
};

DefaultFileFormField.propTypes = {};

export default DefaultFileFormField;
