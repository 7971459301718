import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Typography, LinearProgress, IconButton } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import { ItemContainer, ItemBarContainer } from "./Styled";
import { exportBatch, exportBatchClear } from "../../actions";
import { downloadFile } from "../../actions/files/fileActions";
import config from "../../config";

const downloadOctet = (url, filename) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const BatchItem = ({ 
  clear, 
  process, 
  getFile, 
  data={
    url: "",
    percentage: 0,
    title: "",
    filename: "",
    file: "",
  } 
}) => {
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    if (data.url && !data.error) {
      process(data.url);
    }

    if (data.fid) {
      getCompleteFile();
    }

    async function getCompleteFile() {
      if (data.download_url) {
        downloadOctet(
          `${data.download_url}&oauth_token=${token}`,
          data.filename
        );
      } else {
        await getFile(
          `${config.api_url}/file/${data.fid}`,
          data.filename,
          "base64"
        );
      }

      clear(data.id);
    }
  });

  let color = 'transparent';
  if(data.error){
    color = "var(--color-red-fade)";
  }
  if(data.complete){
    color = "var(--color-green-fade)";
  }

  return (
    <ItemContainer style={{background: color}}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {(data.error || data.complete) && (
            <ErrorOutlineIcon color="primary" style={{ marginRight: "1rem" }} />
          )}
          <Typography variant="body1">{`${data.error ? "Error " : ""}${
            data.title
          }`}</Typography>
        </div>
        {(data.error || data.complete) && (
          <IconButton onClick={() => clear(data.id)} size="small">
            <HighlightOffIcon size="small" />
          </IconButton>
        )}
      </div>
      {(!data.error && !data.complete) && (
        <ItemBarContainer>
          <LinearProgress color="secondary" variant="determinate" value={data.percentage} />
        </ItemBarContainer>
      )}
    </ItemContainer>
  );
};

BatchItem.propTypes = {};

const mapStateToProps = (state) => ({
  batch: state.app.batch.data,
  error: state.app.batch.error,
});

const mapDispatchToProps = (dispatch) => ({
  process: (url) => dispatch(exportBatch(url)),
  getFile: (path, name, responseType) =>
    dispatch(downloadFile(path, name, responseType)),
  clear: (id) => dispatch(exportBatchClear(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BatchItem);
