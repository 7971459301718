import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, FieldArray } from "formik";
import {
  Popover,
  Tooltip,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  TextField,
  CircularProgress,
} from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";
import { Autocomplete } from '@mui/material';
import DeleteIcon from "@mui/icons-material/Delete";
import { size } from "lodash";

import AvatarItem from "../components/AvatarItem";
import AvatarGroup from "common/AvatarGroup";
import { useDebounce } from "hooks";
import ButtonLoader from "common/ButtonLoader";
import Avatar from "common/Avatar";

import {
  fetchUsers,
  usersAllSelector,
  getUsersLoadingSelector,
  getUsersErrorSelector,
} from "features/Users/usersSlice";

import {
  usersSelectors as jobUserSelectors,
  assignUsers,
  getDivisionAssignedUsersDataSelector,
  getDivisionDataSelector,
} from "../jobSlice";

const AssignUsers = ({ loadAvailableUsers, nid, small, children }) => {
  const dispatch = useDispatch();
  const allUsers = useSelector(usersAllSelector.selectAll);
  const allJobUsers = useSelector(jobUserSelectors.selectAll);
  const loading = useSelector(getUsersLoadingSelector);
  const error = useSelector(getUsersErrorSelector);
  const userIds = useSelector(getDivisionAssignedUsersDataSelector);
  const division = useSelector(getDivisionDataSelector);
  const processed = division?._processed;

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [userQuery, setUserQuery] = useState("");
  const debouncedUser = useDebounce(userQuery, 250);
  const working = open && loading;

  useEffect(() => {
    if (debouncedUser && open) {
      const promise = dispatch(
        fetchUsers({
          param1: nid,
          assignedOnly: 0,
          params: {
            keywords: debouncedUser,
          },
        })
      );

      return () => {
        promise.abort();
      };
    }
  }, [debouncedUser, open, loadAvailableUsers, nid, dispatch]);

  useEffect(() => {
    if (!error) {
      const u = allUsers.map((obj) => ({
        ...obj,
        field_first_name: obj.first_name,
        field_last_name: obj.last_name,
        name: `${obj.first_name} ${obj.last_name}`,
        uid: obj.uid,
      }));
      setOptions([...u]);
    }
  }, [error, allUsers]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleUserInputChange = (e) => {
    if (e) {
      setUserQuery(e.target.value);
    }
  };

  const handleStageUser = (value, arrayHelpers, values) => {
    if (value) {
      if (
        values.currentUsers.findIndex(
          (u) => Number(u.uid) === Number(value.uid)
        ) < 0 &&
        values.users.findIndex((u) => Number(u.uid) === Number(value.uid)) < 0
      ) {
        arrayHelpers.push(value);
      }
    }
  };

  return <>
    {children ? (
      React.cloneElement(children, {
        onClick: (e) => setAnchorEl(e.currentTarget),
      })
    ) : (
      size(userIds) > 0 ? (
        <AvatarGroup
          max={3}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          size="small"
        >
          {userIds.map((uid) => (
            <AvatarItem key={uid} uid={uid} />
          ))}
        </AvatarGroup>
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant={small ? 'subtitle2' : 'subtitle1'} className="whitespace-nowrap">Assign Users</Typography>
          <Tooltip title="Assign Users" placement="bottom">
            <IconButton
              color="primaryInverse"
              aria-label="Assign user"
              onClick={(e) => setAnchorEl(e.currentTarget)}
              size="large"
              className={small ? 'p-1' : ''}
            >
              <AddCircleOutline />
            </IconButton>
          </Tooltip>
        </div>
      )
    )}
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Formik
        initialValues={{
          currentUsers: allJobUsers.filter(
            (u) => userIds.findIndex((id) => u.uid === id) >= 0
          ),
          users: [],
        }}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          setAnchorEl(null);
          dispatch(
            assignUsers({
              id: nid,
              currentUsers: data.currentUsers,
              newUsers: data.users,
            })
          );
        }}
      >
        {({ values, errors, isSubmitting, setFieldValue }) => (
          <Form style={{ padding: "1.25rem" }}>
            <FieldArray
              name="users"
              render={(arrayHelpers) => (
                <>
                  <Autocomplete
                    id="assign-users-field"
                    autoComplete={false}
                    style={{ width: 300 }}
                    open={open}
                    onChange={(event, value) =>
                      handleStageUser(value, arrayHelpers, values)
                    }
                    onOpen={() => {
                      setOpen(true);
                    }}
                    onClose={() => {
                      setOpen(false);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.name === value.name
                    }
                    getOptionLabel={(option) => option.name}
                    options={options}
                    loading={working}
                    onInputChange={handleUserInputChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Assign Users"
                        variant="filled"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                  <List dense style={{ width: "300px", margin: "1.25rem 0" }}>
                    <FieldArray
                      name="currentUsers"
                      render={(arrayHelpers) =>
                        values.currentUsers.map((user, i) => (
                          <ListItem key={user.uid} disableGutters>
                            <ListItemAvatar>
                              <Avatar
                                title={`${user.field_first_name} ${user.field_last_name}`}
                                link={user.profile_pic}
                                size="small"
                              />
                            </ListItemAvatar>
                            <ListItemText primary={`${user.field_first_name} ${user.field_last_name}`} />
                          {processed.removable_uids.includes(user.uid) &&
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => arrayHelpers.remove(i)}
                                size="large">
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          }
                          </ListItem>
                        ))
                      }
                    />
                    {values.users.map((user, i) => (
                      <ListItem key={user.uid} disableGutters>
                        <ListItemAvatar>
                          <Avatar
                            title={`${user.field_first_name} ${user.field_last_name}`}
                            link={user.profile_pic}
                            size="small"
                          />
                        </ListItemAvatar>
                        <ListItemText primary={`${user.field_first_name} ${user.field_last_name}`} />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => arrayHelpers.remove(i)}
                            size="large">
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                  {!size(values.users) && !size(values.currentUsers) && (
                    <Typography
                      style={{ padding: "2rem 1rem" }}
                      align="center"
                    >
                      Type a Users name to select and assign them.
                    </Typography>
                  )}
                </>
              )}
            />
            <ButtonLoader
              variant="contained"
              color="primary"
              type="submit"
              isSubmitting={isSubmitting}
              disableElevation
              size="small"
              fullWidth
            >
              Save
            </ButtonLoader>
          </Form>
        )}
      </Formik>
    </Popover>
  </>;
};

AssignUsers.propTypes = {};

export default AssignUsers;
