import React from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";

const CircularLoader = ({ height="200px" }) => {
  return (
    <div
      style={{
        height,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </div>
  );
};

const { string } = PropTypes;
CircularLoader.propTypes = {
  height: string,
};

export default CircularLoader;
