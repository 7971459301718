import React from "react";
import { Divider, Grid, Typography } from "@mui/material";
import { RiContactsBookLine } from "react-icons/ri";
import { FaFax } from "react-icons/fa";
import { CgNotes } from "react-icons/cg";
import { MdPhone } from "react-icons/md";
import { HiOutlineDocumentText } from "react-icons/hi";
import { toArray } from "lodash";
import styled from "styled-components";
import { formatEquipmentDiscountLine } from "components/TMTerms/utils";

// import Header from "./Header";
import CollapsibleHeader from "common/CollapsibleHeader";
import IconTextItem from "common/IconTextItem";
import UserItem from "common/UserItem";
import Card, { CardHeader, CardContent } from "common/Card";
import MapBanner from "common/MapBanner";
import Avatar from "common/Avatar";
import LinkHeader from "common/LinkHeader";
import WeeksJobs from "./WeeksJobs";
import CustomerCRM from "./CustomerCRM";
import Hours from "common/Hours";
// import ActiveJobs from "./ActiveJobs";

const Details = styled.div`
  margin-left: 1.25rem;
  flex: 1;
`;

const Information = ({ customer, isCommercial, inSnapshot }) => {
  return (
    <div style={{ flex: 1, overflow: "auto" }}>
      <CollapsibleHeader collapse={false}>
        <Avatar
          title={customer.title}
          link={customer?.field_logo?.link}
          size="large"
          outline
          type="org"
        />
        <Details>
          <LinkHeader to={customer.path} color="secondary" size="medium">
            {customer.title}
          </LinkHeader>
        </Details>
      </CollapsibleHeader>
      <MapBanner
        geo={{
          lat: customer?.field_street_address_geo?.lat,
          lng: customer?.field_street_address_geo?.lon,
        }}
        address1={customer.field_street_address?.address_line1}
        address2={customer.field_street_address?.address_line2}
        city={customer.field_street_address?.locality}
        state={customer.field_street_address?.administrative_area}
        zip={customer.field_street_address?.postal_code}
      >
        <div>
          <Grid container spacing={3}>
            <Grid item xxs={12} md={8} xl={9} className="order-1 grid-md:order-none">
              {!inSnapshot && customer?._meta?.notes_access_permitted ? (
                <Card>
                  <CardHeader title="Contact History" />
                  <CardContent className="!p-0">
                    <CustomerCRM
                      customer={customer}
                      // media={media}
                      // roles={roles}
                      // showSnackbar={() => {}}
                      // processed={member._processed}
                      // refreshMember={getMember}
                      shortList
                    />
                  </CardContent>
                </Card>
              ) : (
                <WeeksJobs nid={customer.nid} />
              )}
            </Grid>
            <Grid item xxs={12} md={4} xl={3}>
              <Card>
                <CardHeader title="Contact" />
                <CardContent>
                  {customer.field_phone && (
                    <IconTextItem icon={<MdPhone />}>
                      <Typography
                        variant="subtitle2"
                        style={{ textTransform: "uppercase" }}
                        color="textSecondary"
                      >
                        Phone
                      </Typography>
                      <Typography variant="body1">
                        {customer.field_phone}
                      </Typography>
                    </IconTextItem>
                  )}
                  {customer.field_phone_after_hours && (
                    <IconTextItem icon={<MdPhone />}>
                      <Typography
                        variant="subtitle2"
                        style={{ textTransform: "uppercase" }}
                        color="textSecondary"
                      >
                        After Hours
                      </Typography>
                      <Typography variant="body1">
                        {customer.field_phone_after_hours}
                      </Typography>
                    </IconTextItem>
                  )}
                  {customer.field_fax && (
                    <IconTextItem icon={<FaFax />}>
                      <Typography
                        variant="subtitle2"
                        style={{ textTransform: "uppercase" }}
                        color="textSecondary"
                      >
                        Fax
                      </Typography>
                      <Typography variant="body1">
                        {customer.field_fax}
                      </Typography>
                    </IconTextItem>
                  )}
                  {isCommercial && customer.field_hours && (
                    <>
                      <Hours data={customer.field_hours} />
                      <Divider style={{ margin: "1rem 0" }} />
                    </>
                  )}
                  {customer.field_phx_client_rep && (
                    <IconTextItem icon={<RiContactsBookLine />}>
                      <Typography
                        variant="subtitle2"
                        style={{ textTransform: "uppercase" }}
                        color="textSecondary"
                      >
                        Account Reps
                      </Typography>
                      {toArray(customer.field_phx_client_rep).map((user, i) => (
                        <UserItem key={i} user={user} />
                      ))}
                    </IconTextItem>
                  )}
                  {customer.field_national_contracts && customer.field_national_contracts.length > 0 && customer?._processed?.update_access && (
                  <>
                  <Divider style={{ margin: "1rem 0" }} />
                  <IconTextItem icon={<HiOutlineDocumentText />}>
                    <Typography
                      variant="subtitle2"
                      style={{ textTransform: "uppercase" }}
                      color="textSecondary"
                    >
                      National Contracts
                    </Typography>
                    {customer?.field_national_contracts?.map((term) => (
                      <Typography>{term.name}</Typography>
                    ))}
                  </IconTextItem>
                  </>
                )}
                {customer.field_note && (
                  <>
                  <Divider style={{ margin: "1rem 0" }} />
                  <IconTextItem icon={<CgNotes />}>
                    <Typography
                      variant="subtitle2"
                      style={{ textTransform: "uppercase" }}
                      color="textSecondary"
                    >
                      Special Instructions
                    </Typography>
                    <Typography variant="body1">
                      {customer.field_note}
                    </Typography>
                  </IconTextItem>
                  </>
                )}
                </CardContent>
              </Card>
              {customer.field_tm_terms && (
              <Card>
                <CardHeader title="T&M Terms" />
                <CardContent>
                  {(customer.field_tm_terms?.equipment?.weekly || customer.field_tm_terms?.equipment?.monthly) && (
                  <Typography
                    variant="subtitle2"
                  >
                    Equipment Discount Terms
                  </Typography>
                  )}
                  {customer.field_tm_terms?.equipment?.weekly && (
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                  >
                    WEEKLY RATE - &#40;{formatEquipmentDiscountLine(customer.field_tm_terms.equipment.weekly)}&#41;
                  </Typography>
                  )}
                  {customer.field_tm_terms?.equipment?.monthly && (
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                  >
                    MONTHLY RATE - &#40;{formatEquipmentDiscountLine(customer.field_tm_terms.equipment.monthly)}&#41;
                  </Typography>
                  )}
                </CardContent>
              </Card>
              )}
            </Grid>
          </Grid>
        </div>
      </MapBanner>
    </div>
  );
};

Information.propTypes = {};

export default Information;
